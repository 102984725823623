import { renderStatus } from 'client/tools';

export const MO_STATUS_NOT_READY = 'необходимо восполнение';
export const MO_STATUS_READY = 'полная готовность';

export const renderMunicipalityStatus = (cities = [], municipalityStatuses = []) => {
	let statusReady = null;
	let statusNotReady = null;

	for (const status of municipalityStatuses) {
		if (status.name.toLowerCase() === MO_STATUS_READY) {
			statusReady = status;
		} else {
			statusNotReady = status;
		}
	}

	if (!statusReady || !statusNotReady) {
		return;
	}

	const isReady = cities.length && cities.every((city) => city.sufficiencyStatusId === statusReady.id);
	return isReady ? renderStatus(statusReady) : renderStatus(statusNotReady);
};

export const renderPopulation = (cities = []) => {
	return cities.reduce((population, city) => city.population + population, 0);
};
