import React from 'react';
import { observable } from 'mobx';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { asArray } from 'ol/color';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { geoJSON, drawGeoMarker } from '@smartplatform/map/client';
import { ColorUtils } from '@smartplatform/ui';
import ForestryQuarterPopup from './Popup';
import store from 'client/store';
import t from 'i18n';

export default class ForestryQuartersLayer {
	
	constructor(mapStore, settings = {}, card) {
		this.mapStore = mapStore;
		this.card = card;
		this.settings = settings;
	}

	init = () => {
		this.source = new VectorSource();
		this.layer = new VectorLayer({
			format: geoJSON,
			source: this.source,
		});

		this.layer.setZIndex(3);
		this.mapStore.addLayer(this.layer);
		this.setVisible();
		this.setOpacity();
	};

	load = async (geo) => {
		if (!geo) {
			this.card.forestryQuarters = [];
			this.source.clear();
			return;
		}

		const features = [];
		
		this.card.forestryQuarters.forEach(record => {
			const [r, g, b] = ColorUtils.hexToArray(record.color || '#888');
			features.push(...drawGeoMarker(record.geo, {
				onlyGeo: true,
				style: new Style({
					fill: new Fill({ color: asArray([r, g, b, 0.7])}),
					stroke: new Stroke({ color: asArray([0, 0, 0, 0.3]), width: 1 }),
					text: new Text({
						textAlign: 'center',
						textBaseline: 'middle',
						// overflow: true,
						font: '11px/14px sans-serif',
						text: record.name,
						fill: new Fill({ color: '#888' }),
					}),
				}),
				data: {
					title: t('forestryQuarter.title'),
					id: record.id,
					record,
					// onFeatureClick: this.onClick,
					render: this.renderPopup,
				},
			}));
		});

		this.source.clear();
		this.source.addFeatures(features);
	};

	update = () => {
		this.layer.getSource().refresh();
	};
	
	setVisible = (value = undefined) => {
		this.layer.setVisible(this.settings.show);
	};
	
	setOpacity = () => {
		this.layer.setOpacity(this.settings.opacity || 0.5);
	};
	
	renderPopup = (record) => {
		return <ForestryQuarterPopup record={record} />;
	}

	onClick = async (id) => {
		try {
			return await store.model.ForestryQuarter.findById(id);
		}
		catch (e) {
			console.error(e);
		}
		return null;
	};

};
