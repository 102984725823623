import { renderStatus } from 'client/tools/renders';
import { fio } from 'client/tools';
import { TechFireAddressSearch } from 'components/techfire-address-search';
import React from 'react';
import { fixVehicleType } from './helpers';
import { ObjectLog } from './registry-object-adpi-log/ObjectLog';
import t from 'i18n';

export const DICTIONARIES_MODELS = [
	{
		title: t('techFire.fires.title'),
		type: 'submenu',
		items: [{ modelName: 'FireAlarmMessageSource' }, { modelName: 'FireAlarmMessageStatus' }, { modelName: 'TechFireReportSource' }],
	},
	{
		title: t('fireDep.personnel'),
		type: 'submenu',
		items: [{ modelName: 'FireDepSection' }, { modelName: 'FireDepRank' }, { modelName: 'FireDepPosition' }, { modelName: 'FireDepPersonnelStatus' }],
	},
	{
		title: t('fireDep.plural'),
		type: 'submenu',
		items: [{ modelName: 'TechFireOrganization' }, { modelName: 'GasMask' }, { modelName: 'TechFireSentry' }],
	},
	{
		title: t('fireDep.vehicles'),
		type: 'submenu',
		items: [
			{ modelName: 'FireDepVehicleBrand' },
			{ modelName: 'FireDepVehicleStatus' },
			{ modelName: 'FireDepVehicleFuel' },
			{
				modelName: 'FireDepVehicleType',
				onEdit: fixVehicleType,
				listRelations: [{ relation: 'specialType', property: 'name' }],
			},
			{ modelName: 'FireDepVehicleCallSign', listRelations: [{ relation: 'fireDep', property: 'name' }] },
			{ modelName: 'Pump' },
			{ modelName: 'FoamCapacity' },
			{ modelName: 'FireDepSpecialVehicleType' },
			{ modelName: 'FireDepPowderCapacity' },
			{ modelName: 'ProtectiveSuit' },
			{ modelName: 'TechFireFoam' },
			{ modelName: 'HydraulicTool' },
			{ modelName: 'CisternBulk' },
		],
	},
	{
		title: t('techFireAddress.objects'),
		type: 'submenu',
		items: [{ modelName: 'TechFireRank' }],
	},
	{
		title: t('system112'),
		type: 'submenu',
		items: [{ modelName: 'TechFireEmergencyCode' }, { modelName: 'FireDepStatus' }],
	},
	{
		title: t('fireAlarm.title'),
		type: 'submenu',
		items: [
			{ modelName: 'FireAlarmOrganizationAdpiService' },
			{ modelName: 'AdpiDeviceStatus' },
			{ modelName: 'AdpiDeviceModel' },
			{ modelName: 'AdpiDeviceType' },
		],
	},
	{
		title: t('waterSupply.plural'),
		type: 'submenu',
		items: [{ modelName: 'WaterSupplyType' }, { modelName: 'WaterSupplyDivision' }],
	},
	{
		title: t('report.plural'),
		type: 'submenu',
		items: [{ modelName: 'TechFireReport', listProperties: ['name', 'code', 'shortName'], editProperties: ['name', 'code', 'shortName'] }],
	},
];

export const REGISTRY_MODELS = [
	{
		title: t('fire.registry.title'),
		type: 'submenu',
		items: [
			{
				modelName: 'AdpiDevice',
				Component: ObjectLog,
				listRelations: [
					{ relation: 'status', computed: renderStatus, properties: ['name', 'color'] },
					{ relation: 'type', property: 'name' },
					{ relation: 'organization', property: 'name' },
					{ relation: 'model', property: 'name' },
				],
				editRelations: [
					{ relation: 'status', computed: renderStatus, properties: ['name', 'color'] },
					{ relation: 'type', property: 'name' },
					{ relation: 'address', children: <TechFireAddressSearch /> },
					{ relation: 'organization', property: 'name' },
					{ relation: 'model', property: 'name' },
				],
			},
			{
				modelName: 'WaterSupply',
				listProperties: ['name', 'number', 'createdAt', 'updatedAt'],
				listRelations: [
					{ relation: 'division', property: 'name' },
					{ relation: 'type', property: 'name' },
				],
				// editRelations: [
				// 	{ relation: 'division', property: 'name' },
				// 	{ relation: 'type', property: 'name' },
				// 	{ relation: 'address', children: <TechFireAddressSearch /> },
				// ],
			},
		],
	},
	{
		title: t('fireDep.title'),
		type: 'submenu',
		items: [
			{
				modelName: 'FireDepPersonnel',
				listProperties: ['lastName', 'firstName', 'middleName', 'dayShift', 'phone', 'address', 'createdAt', 'updatedAt'],
				listRelations: [
					{ relation: 'section', property: 'name' },
					{ relation: 'position', property: 'name' },
					{ relation: 'rank', property: 'name' },
					{ relation: 'status', property: 'name' },
					{ relation: 'vehicle', property: 'stateNumber' },
					{ relation: 'headOrg', property: 'name' },
					{ relation: 'gasMask', property: 'name' },
					{ relation: 'user', computed: fio, properties: ['name'] },
				],
				editProperties: ['lastName', 'firstName', 'middleName', 'dayShift', 'phone', 'address', 'createdAt', 'updatedAt'],
				editRelations: [
					{ relation: 'section', property: 'name' },
					{ relation: 'position', property: 'name' },
					{ relation: 'rank', property: 'name' },
					{ relation: 'status', property: 'name' },
					{ relation: 'vehicle', property: 'stateNumber' },
					{ relation: 'headOrg', property: 'name' },
					{ relation: 'gasMask', property: 'name' },
					{ relation: 'user', computed: fio, properties: ['name'] },
				],
			},
			{
				modelName: 'FireDepVehicle',
				listProperties: ['year', 'stateNumber', 'passport', 'description', 'actualFoamValue', 'endDate', 'actualFuelValue', 'createdAt', 'updatedAt'],
				listRelations: [
					{ relation: 'brand', property: 'name' },
					{ relation: 'cisternBulk', property: 'volume' },
					{ relation: 'type', property: 'name' },
					{ relation: 'pump', property: 'capacity' },
					{ relation: 'callSign', property: 'name' },
					{ relation: 'status', property: 'name' },
					{ relation: 'foamCapacity', property: 'capacity' },
					{ relation: 'fuelValue', property: 'value' },
				],
				editProperties: ['year', 'stateNumber', 'passport', 'description', 'actualFoamValue', 'endDate', 'actualFuelValue', 'createdAt', 'updatedAt'],
				editRelations: [
					{ relation: 'brand', property: 'name' },
					{ relation: 'cisternBulk', property: 'volume' },
					{ relation: 'type', property: 'name' },
					{ relation: 'pump', property: 'capacity' },
					{ relation: 'callSign', property: 'name' },
					{ relation: 'status', property: 'name' },
					{ relation: 'foamCapacity', property: 'capacity' },
					{ relation: 'fuelValue', property: 'value' },
				],
			},
		],
	},
];
