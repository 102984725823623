import React, { Component } from 'react';
import { Row } from '@smartplatform/ui';
import { FormFieldDisabled } from 'delivery/tracking/single-cargo/helper';
import t from 'i18n';
import { formatDate } from 'client/tools';
import { Production } from './production/Production';

class LadingBill extends Component {
	render() {
		const { supplies, sender, consignee, number, date, payer, totalItems, netWeight, grossWeight, totalReleased } = this.props.ladingBill;

		return (
			<>
				<Row>
					<FormFieldDisabled labelName={t('tracking.nameSingle')} value={number} />
					<FormFieldDisabled labelName={t('tracking.documentDate')} value={formatDate(date)} />
				</Row>
				<Row>
					<FormFieldDisabled labelName={t('tracking.organizationFrom')} value={sender?.name} />
					<FormFieldDisabled labelName={t('tracking.inn')} value={sender?.inn ?? ''} />
				</Row>
				<Row>
					<FormFieldDisabled labelName={t('tracking.organizationTo')} value={consignee?.name ?? ''} />
					<FormFieldDisabled labelName={t('tracking.inn')} value={consignee?.inn ?? ''} />
				</Row>
				<Row>
					<FormFieldDisabled labelName={t('tracking.payer')} value={payer?.name ?? ''} />
					<FormFieldDisabled labelName={t('tracking.inn')} value={payer?.inn ?? ''} />
				</Row>
				<Production supplies={supplies()} />
				<Row>
					<FormFieldDisabled labelName={t('trackingProduction.totalProduction')} value={totalItems} />
					<FormFieldDisabled labelName={t('trackingProduction.totalNetto')} value={netWeight} />
					<FormFieldDisabled labelName={t('trackingProduction.totalBrutto')} value={grossWeight} />
				</Row>
				<Row>
					<FormFieldDisabled labelName={t('trackingProduction.totalCost')} value={totalReleased} />
				</Row>
			</>
		);
	}
}

export default LadingBill;
