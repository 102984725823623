import React from 'react';
import { observable, computed } from 'mobx';

import { Checkbox } from '@smartplatform/ui';
import store from 'client/store';

export default class FireDepSentryStore {
	
	@observable record = null;
	@observable fireDep = null;
	@observable changed = false;
	@observable personnel = [];
	@observable vehicles = [];
	@observable updates = 0;
	@observable isSaving = false;
	
	constructor(props) {
		this.props = props;
		this.init();
	}
	
	init = async () => {
		const { id, fireDepId } = this.props.match.params;

		this.fireDep = await store.model.FireDep.findById(fireDepId, {
			fields: ['id', 'name'],
			include: [
				{
					relation: 'personnel',
					scope: {
						include: [
							{ relation: 'vehicle', scope: { include: [ 'brand', 'type' ] }},
							{ relation: 'status', scope: { fields: [ 'id', 'name' ] }},
						],
						order: 'lastName asc, firstName asc, middleName asc'
					}
				},
				{
					relation: 'vehicles',
					scope: {
						include: [
							'personnel',
							'status',
							{
								relation: 'type',
								scope: {
									fields: ['id', 'name', 'specialTypeId'],
									include: [
										{ relation: 'specialType', scope: { fields: ['id', 'name'] }},
									],
								}
							},
						],
						order: 'id asc'
					}
				},
			]
		});

		if (id) {
			await this.loadSentry();
		}
		else {
			this.record = new store.model.FireDepSentry({ fireDepId });
		}
	};
	
	loadSentry = async () => {
		const { id } = this.props.match.params;
		this.record = await store.model.FireDepSentry.findById(id, {
			include: [
				{ relation: 'globalSentry', scope: { fields: ['id', 'name'] }},
				{ relation: 'personnel', scope: { fields: ['id'] }},
				{ relation: 'vehicles', scope: { fields: ['id'] }},
			]
		});
		this.personnel = [...this.record.personnel()];
		this.vehicles = [...this.record.vehicles()];
		console.log('> personnel', this.personnel.length, 'vehicles', this.vehicles.length)
	};
	
	onGlobalSentryChange = (sentry) => {
		this.record.globalSentry = sentry;
		if (sentry) this.record.name = '';
		this.changed = true;
	};
	
	onNameChange = (value) => {
		this.record.globalSentry = null;
		this.changed = true;
	};
	
	onUnitsChange = (value) => {
		this.changed = true;
	};
	
	onSave = (record) => {
		this.changed = false;
		const { id, fireDepId } = this.props.match.params;
		if (!id) store.route.push({ path: `/techfires/firedep/${fireDepId}/sentries/${record.id}` });
	};
	
	goBack = () => {
		const { fireDepId } = this.props.match.params;
		store.route.push({ path: `/techfires/firedep/${fireDepId}/sentries` });
	};
	
	revertChanges = async () => {
		this.updates = 0;
		await this.loadSentry();
	};
	
	savePersonnelAndVehicles = async () => {
		this.updates = 0;
		this.isSaving = true;
		const promises = [];
		console.log('?', this.record.personnel(), this.record.vehicles())
		const oldPersonnel = await this.record.personnel();
		const oldVehicles = await this.record.vehicles();
		for (let person of this.fireDep.personnel()) {
			const newIndex = this.personnel.findIndex(_person => person.id === _person.id);
			const oldIndex = [...oldPersonnel].findIndex(_person => person.id === _person.id);
			if (newIndex !== -1) { // надо добавить
				if (oldIndex === -1) { // если до этого не было - добавляем
					promises.push(this.record.personnel.add(person.id));
				}
			}
			else { // надо удалить
				if (oldIndex !== -1) { // если до этого было - удаляем
					promises.push(this.record.personnel.remove(person.id));
				}
			}
		}
		for (let vehicle of this.fireDep.vehicles()) {
			const newIndex = this.vehicles.findIndex(_vehicle => vehicle.id === _vehicle.id);
			const oldIndex = [...oldVehicles].findIndex(_vehicle => vehicle.id === _vehicle.id);
			if (newIndex !== -1) { // надо добавить
				if (oldIndex === -1) { // если до этого не было - добавляем
					promises.push(this.record.vehicles.add(vehicle.id));
				}
			}
			else { // надо удалить
				if (oldIndex !== -1) { // если до этого было - удаляем
					promises.push(this.record.vehicles.remove(vehicle.id));
				}
			}
		}
		await Promise.all(promises);
		this.isSaving = false;
	};
	
	renderChecked = (relation) => ({ record }) => {
		if (relation === 'personnel') {
			return <Checkbox checked={this.isPersonActive(record)} onChange={this.togglePerson(record)}/>;
		}
		else {
			return <Checkbox checked={this.isVehicleActive(record)} onChange={this.toggleVehicle(record)}/>;
		}
	};
	
	isPersonActive = (person) => {
		return this.personnel.map(r => r.id).includes(person.id);
	}
	
	isVehicleActive = (vehicle) => {
		return this.vehicles.map(r => r.id).includes(vehicle.id);
	}
	
	togglePerson = (person) => (value) => {
		const index = this.personnel.findIndex(_person => person.id === _person.id);
		if (value) {
			if (index === -1) this.personnel.push(person);
		}
		else {
			if (index !== -1) this.personnel.splice(index, 1);
		}
		this.setVehicleForPerson(person, value);
		this.updates++;
	};
	
	toggleVehicle = (vehicle) => async (value) => {
		const index = this.vehicles.findIndex(_vehicle => vehicle.id === _vehicle.id);
		if (value) {
			if (index === -1) this.vehicles.push(vehicle);
		}
		else {
			if (index !== -1) this.vehicles.splice(index, 1);
		}
		this.setPersonnelForVehicle(vehicle, value);
		this.updates++;
	};
	
	setVehicleForPerson = (person, add) => {
		console.log('setVehicleForPerson: vehicle:', person.vehicle);
		if (person.vehicle) {
			const index = this.vehicles.findIndex(_vehicle => person.vehicleId === _vehicle.id);
			if (add) {
				if (index === -1) this.vehicles.push(person.vehicle);
			}
			else {
				const vehiclePersonnelIdArray = person.vehicle.personnel().map(r => r.id);
				console.log('vehiclePersonnelIdArray', [...vehiclePersonnelIdArray])
				const vehiclePersonnelLeft = this.personnel.filter(_person => vehiclePersonnelIdArray.includes(_person.id));
				console.log('vehiclePersonnelLeft', vehiclePersonnelLeft.length)
				if (vehiclePersonnelLeft.length === 0) {
					if (index !== -1) this.vehicles.splice(index, 1);
				}
			}
		}
	};
	
	setPersonnelForVehicle = (vehicle, add) => {
		console.log('setPersonnelForVehicle', vehicle.personnel().length);
		for (let person of vehicle.personnel()) {
			const index = this.personnel.findIndex(_person => person.id === _person.id);
			if (add) {
				if (index === -1) this.personnel.push(person);
			}
			else {
				if (index !== -1) this.personnel.splice(index, 1);
			}
		}
	};
}
