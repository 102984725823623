export const getPeopleValues = (fireLogs) => {
    fireLogs = fireLogs.sort((a, b) => new Date(a.date) - new Date(b.date))
    //* создаем объект, где в ключе будет строка вида c1f1 , куда просуммируем людей в поле current;
    const reducedObject = fireLogs.reduce((acc, next, index) => {
        next.people().forEach((record) => {
            if (!record.crewTypeId && !record.formationId) return acc;
            const accKey = 'c' + record.crewTypeId + 'f' + record.formationId;
            const delta = (+record.delivered || 0) - (+record.evacuated || 0);
            if (!acc[accKey]) {
                acc[accKey] = {
                    crewTypeId: record.crewTypeId,
                    crewTypeName: record.crewType?.name,
                    crewTypeShortName: record.crewType?.shortName,
                    crewTypeCode: record.crewType?.code,
                    formationId: record.formationId,
                    formationName: record.formation?.name,
                    formationShortName: record.formation?.shortName,
                    formationCode: record.formation?.code,
                    required: record.required,
                    plan: record.plan,
                    current: delta,
                };
            } else {
                acc[accKey].current += delta;
                acc[accKey].plan = record.plan;
                acc[accKey].required = record.required;

            }

            //* если нужно будет получить current на начало дня, то нужно будет добавить lastEvacuated к current
            if (index === (fireLogs.length - 1)) {
                acc[accKey].lastEvacuated = record.evacuated || 0;

            }
        });
        return acc;
    }, {});
    //* избавимся от ключей и создаем массив
    return Object.keys(reducedObject).map((key) => reducedObject[key]);
};

// const fireLogs = [
//     {
//         people: () => {
//             return [
//                 {
//                     crewTypeId: 1,
//                     formationId: 1,
//                     delivered: 100,
//                     evacuated: undefined,
//                     crewType: { shortName: 'К1', name: 'Команда1', code: 'crewTypeCode1' },
//                     formation: { shortName: 'Ф1', name: 'Формаирование1', code: 'INTERREGIONAL' },
//                     required: 10,
//                     plan: 10

//                 },
//                 {
//                     crewTypeId: 2,
//                     formationId: 1,
//                     delivered: 100,
//                     crewType: { shortName: 'К2', name: 'Команда2', code: 'creTypeCode2' },
//                     formation: { shortName: 'ДПС', name: 'ДЛсформация' ,code: 'formationCode2' },
//                     required: 20,
//                     plan: 20
//                 },
//             ];
//         },
//     },

//     {
//         people: () => {
//             return [
//                 {
//                     crewTypeId: 1,
//                     formationId: 1,
//                     delivered: 100,
//                     evacuated: 30,
//                     crewType: { shortName: 'К1', name: 'Команда1' },
//                     formation: { shortName: 'Ф1', name: 'Формаирование1', code: 'INTERREGIONAL'  },
//                     required: 30,
//                     plan: 30
//                 },
//             ];
//         },
//     },
// ];
// console.log(getPeopleValues(fireLogs));
