import React from 'react';
import { observer } from 'mobx-react';

import store from '../../store';
import List from './List';

@observer
export default class Cases extends React.Component {
	
	render() {
		const county = store.covid.county !== null ? store.covid.county : undefined;
		return <List county={county} type={store.covid.type} />;
	}
	
}
