import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Loader } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class MunicipalityPopup extends React.Component {
	
	@observable fullRecord = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.fullRecord = await store.model.ViewMunicipality.findById(this.props.record.id, {
			include: [
				{ relation: 'status', scope: { field: ['id', 'name'] }},
			]
		});
		this.isLoading = false;
	};
	
	go = () => store.route.push({ path: `/emergency/municipalities/${this.props.record.id}` });
	
	render() {
		const { record } = this.props;
		
		let fullContent = <Loader size={18} />;
		if (!this.isLoading) {
			fullContent = this.fullRecord ? <>
{/*
					<div className="attr-value"><label>{t('municipality.citiesCount')}</label>
						<div>{this.fullRecord.citiesCount}</div>
					</div>
*/}
					<div className="attr-value"><label>{t('municipality.population')}</label>
						<div>{this.fullRecord.population}</div>
					</div>
					<div className="attr-value"><label>{t('municipality.area')}</label>
						<div>{this.fullRecord.area}</div>
					</div>
					<div className="attr-value"><label>{t('municipality.status')}</label>
						<div>{this.fullRecord.status ? this.fullRecord.status.name : '-'}</div>
					</div>
				</>
				:
				null;
		}
		
		return <div className="municipality-popup">
			<div className="attributes">
				<div className="attr-value"><label>{t('municipality.name')}</label>
					<a href="#" onClick={this.go}>{record.name}</a>
				</div>
				{fullContent}
			</div>
		</div>;
	}
	
}
