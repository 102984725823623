import React from 'react';
import { observer } from 'mobx-react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import { Panel } from 'components';
import { MODELS } from './Models'
import { ModelRoute } from "./ModelRoute";

@observer
export default class Dictionaries extends React.Component {
	constructor(props) {
		super(props);
	};
	
	render() {
		const { path } = this.props.match;

		return <Panel full className="dictionaries">
			{MODELS[0] && MODELS[0].path && <Route path={path} exact><Redirect to={MODELS[0].path} /></Route>}
			<div className="menu">
				{MODELS.map(model => {
					const { title, modelName, path } = model;
					return <NavLink key={modelName} to={path}>{title}</NavLink>
				})}
			</div>
			<div className="model">
				<Switch>
					{MODELS.map(model => {
						const { path, modelName } = model;
						return <Route key={modelName} path={path} component={() => <ModelRoute modelName={modelName} path={path} />} />
					})}
				</Switch>
			</div>
		</Panel>;
	}
};
