import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Content from '../Content';
import store from 'client/store';
import './style.scss';

@observer
export default class CategoriesTree extends React.Component {

	@observable tree = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const records = await store.model.AppealCategory.find({
			where: {
				parentId: this.props.parentId || null,
			},
			include: [
				{ relation: 'children', scope: { fields: ['id'] }},
				{ relation: 'organization', scope: { fields: ['id', 'name'] }},
			],
			order: 'id asc',
		});

		for (let record of records) {
			record.showChildren = observable.box(false);
			// record.childrenCount = observable.box(record.children().length);
			record.childrenCount = record.children().length;
		}

		this.tree = records;
	};

	getChildren = (category) => {
		store.appeals ++;
		if (this.isLast(category)) return;
		category.showChildren.set(!category.showChildren.get());
	};

	isLast = (category) => {
		return category.childrenCount === 0;
	};

	render() {
		const level = this.props.level || 0;

		return <div className="categories-tree">
			{this.tree.map(category => {
				return <div key={category.id} className={'category level-' + level + (this.isLast(category) ? ' last' : '')}>
					<div className="info">
						{/*<span className="code">{category.code}</span>*/}
						<span className="name" onClick={() => this.getChildren(category)}>
							{level > 0 && <span className="dots">{[...new Array(level)].map((v, i) => <em key={i} />)}</span>}
							{category.name}
							{category.childrenCount > 0 && <span> ({category.childrenCount})</span>}
						</span>
						<span className="organization">{category.organization ? category.organization.name : null}</span>
						<span className="period">{category.period ? Math.round(category.period / 1440) : null}</span>
					</div>
					{category.showChildren.get() && <CategoriesTree level={level + 1} parentId={category.id} />}
				</div>;
			})}
		</div>;
	}

}
