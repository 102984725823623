import { inject, observer } from 'mobx-react';
import React from 'react';
import { Pager, Table, Column } from '@smartplatform/ui';
import { AddRowButton } from 'delivery/components';
import { PER_PAGE } from 'client/constants';
import t from 'i18n';
import { observable } from 'mobx';
import { BankAccountEditPopup } from './BankAccountEditPopup';

@inject('store')
@observer
export class BankAccounts extends React.Component {
	@observable showPopup = false;
	@observable selectedRecord = null;
	@observable page = 1;

	onPageChange = (page) => (this.page = page);
	openPopup = () => (this.showPopup = true);
	closePopup = async () => {
		this.showPopup = false;
		this.selectedRecord = null;
	};
	onRowClick = (row) => {
		this.selectedRecord = row;
		this.openPopup();
	};

	render() {
		this.props.store.record.id;
		const { bankAccounts } = this.props.store;

		return (
			<div className='staff'>
				<Pager current={this.page} onChange={this.onPageChange} totalCount={bankAccounts.totalCount || 0} />
				<Table rows={bankAccounts.slice(PER_PAGE * (this.page - 1), PER_PAGE * this.page)} onRowClick={this.onRowClick}>
					<Column property='bank' label={t('bankAccount.bank')} />
					<Column property='bic' label={t('bankAccount.bic')} />
					<Column property='swift' label={t('bankAccount.swift')} />
					<Column property='correspondentAccount' label={t('bankAccount.correspondentAccount')} />
				</Table>
				<AddRowButton onClick={this.openPopup} />
				{this.showPopup && <BankAccountEditPopup onClose={this.closePopup} selectedRecord={this.selectedRecord} />}
			</div>
		);
	}
}
