import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import TruckIcon from '!!svg-react-loader!img/icons/delivery/truck.svg';
import { Card } from 'delivery/components';
import { observable } from 'mobx';
import { Loader, Row } from '@smartplatform/ui';
import './style.scss';
import { FormFieldText } from 'delivery/tracking/single-cargo/helper';

@inject('store')
@observer
export class CardTransporter extends React.Component {
	@observable items = [];
	@observable isLoading = false;

	constructor(props) {
		super(props);
	}

	render() {
		let content;
		if (this.isLoading) {
			content = <Loader />;
		} else {
			content = (
				<div className='transporter-items'>
					<h2>SK 2022</h2>
					<Row>
						<FormFieldText labelName='MMSI:' value='273360670' />
						<FormFieldText labelName='Виды:' value='Other vessels' />
					</Row>
					<Row>
						<FormFieldText labelName='IMO:' value='0' />
						<FormFieldText labelName='Регистрация:' value='Russia' />
					</Row>
					<Row>
						<FormFieldText labelName='Скорость:' value='6,5km' />
						<FormFieldText labelName='AIS Положение:' value='Текст' />
					</Row>
					<Row>
						<FormFieldText labelName='Заголовок:' value='Текст' />
						<FormFieldText labelName='Черновой вариант:' value='1.3m' />
					</Row>
				</div>
			);
		}
		return (
			<Card icon={<TruckIcon />} title={t('trackingProduction.transporter')} className='card-transporter'>
				{content}
			</Card>
		);
	}
}
