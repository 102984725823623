import React from 'react';
import { observer } from 'mobx-react';
import { ReportToolbar } from 'components';
import t from '@smartplatform/map/i18n';
import { DatePicker, Select } from '@smartplatform/ui';
import { ReportStore } from './store';

const CODE = 'FireAreaCount';
const GROUPS = [
	{ value: 1, label: t('aviaDep.title'), field: 'aviaDep', nullLabel: t('fireReport.noAviaDep') },
	{ value: 2, label: t('forestry.title'), field: 'forestry', nullLabel: t('fireReport.noForestry') },
	{ value: 3, label: t('county.title'), field: 'county', nullLabel: t('fireReport.noCounty') },
];

@observer
export class FireAreaCount extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE);
		this.store.fireAreaAction = 1;
	}

	render() {
		const { endDate, onEndDateChange, fireAreaAction, onChange } = this.store;

		return (
			<div>
				<div className='filters'>
					<div className='row'>
						<div className='col-3'>
							<div className='form-field mr-3'>
								<label>{t('date2')}</label>
								<DatePicker value={endDate} onChange={onEndDateChange} showClearButton={false} />
							</div>
						</div>
						<div className='col-3'>
							<div className='form-field'>
								<label>{t('fireReport.groupBy')}</label>
								<Select noTotal items={GROUPS} value={fireAreaAction} onChange={onChange('fireAreaAction')} noSearch isRequired />
							</div>
						</div>
					</div>
				</div>
				<div>
					<ReportToolbar {...this.store.storeParams} />
				</div>
			</div>
		);
	}
}
