import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { FilterLayout } from '..';
import t from 'i18n';
import { RecordSelect } from '@smartplatform/ui';
import store from 'client/store';

@observer
export default class Municipality extends React.Component {
	static propTypes = {
		layer: PropTypes.object,
	};

	render() {
		const { layer } = this.props;
		const { municipality } = layer;

		return (
			<FilterLayout layer={layer} title={t('municipality.plural')}>
				<div>
					<label>{t('municipality.title')}</label>
					<RecordSelect
						itemsPerPage={10}
						model={store.model.Municipality}
						prependItems={[{ label: t('all'), value: 'all' }]}
						property='name'
						searchFields={['name']}
						value={municipality}
						showValue={municipality === 'all' ? t('all') : municipality.name}
						onChange={layer.onMunicipalityChange}
						disabled={!layer.settings.show}
						isRequired
					/>
				</div>
			</FilterLayout>
		);
	}
}
