//* стили для таблиц
exports.HEADER_STYLE = {
    'background': '#DFFBDB',
    'text-align': 'center',
    'vertical-align': 'middle',
};
exports.TOTAL_STYLE = {
    background: '#A9A9A9',
    'text-align': 'center',
    height: '1.5em',
};
const TABLE_BORDER_COLOR = '#D9D9D9';
exports.TABLE_STYLES = `id='report-table' bordercolor = ${TABLE_BORDER_COLOR} border="1" style="border-collapse: collapse; border: 1px solid ${TABLE_BORDER_COLOR};"`
