import React from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from './store';
import Layout from './layout/Layout';
import { DndContext } from '@smartplatform/ui';
import { Test, MapLayers } from './components';
import MODULES from './modules';
import Login from './Login';
import { Notifications } from 'components';
import 'bootstrap-4-grid/scss/grid.scss';
import './styles/main.scss';
import { ERROR } from './constants';

@observer
export default class App extends React.Component {
	render() {
		if (!store.model.isInitialized) return null;
		if (!store.model.isAuthorized)
			return (
				<>
					<Notifications />
					<Login />;
				</>
			);

		let defaultRoute = store.config.defaultRoute;

		const modules = store.config.modules.map((moduleName) => MODULES[moduleName]);
		const routes = [];

		for (const module of modules) {
			if (module.access()) routes.push(module.path);
		}

		if (!routes.length) {
			store.ui.setNotification({ message: 'У пользователя нет доступа к модулям', type: ERROR });
			store.model.logout();
			return (
				<>
					<Notifications />
					<Login />;
				</>
			);
		}

		if (!routes.includes(defaultRoute) && routes.length > 0) {
			defaultRoute = routes[0];
		}

		return (
			<DndContext key={store.appKey}>
				<Router history={store.history} onUpdate={() => window.scrollTo(0, 0)}>
					<Notifications />
					<Switch>
						<Route>
							<Layout>
								<Switch>
									<Route path='/test' component={Test} />
									<Route path='/layers' render={(routeProps) => <MapLayers {...routeProps} />} />
									<Route path='/' exact>
										<Redirect to={defaultRoute} />
									</Route>
									{modules.map((module) => {
										return module.access() ? (
											<Route key={module.path} path={module.path} component={module.component} />
										) : (
											<Route key={module.path} path={module.path}>
												<Redirect to='/' />
											</Route>
										);
									})}
								</Switch>
							</Layout>
						</Route>
					</Switch>
				</Router>
			</DndContext>
		);
	}
}
