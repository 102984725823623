import React from 'react';
import store from 'client/store';
import { Column, Pager, Table } from '@smartplatform/ui';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { fio } from 'client/tools';
import { observable } from 'mobx';
import Edit from '../edit/Edit';
import { PER_PAGE } from '../constant';

@inject('store')
@observer
class List extends React.Component {
	@observable showPopup = false;
	@observable selectedRecordId = null;

	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	openPopup = () => (this.showPopup = true);
	closePopup = async () => {
		this.showPopup = false;
		this.selectedRecordId = null;
		this.store.init();
	};
	onRowClick = (row) => {
		this.selectedRecordId = row.id;
		this.openPopup();
	};

	render() {
		const { onChange, totalCount, page, records, recordsSum } = this.props.store;

		const { plan, ship, deliver } = recordsSum;

		return (
			<div className='requests-list'>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={PER_PAGE} />
				<Table rows={records} onRowClick={this.onRowClick}>
					<Column property='id' label={t('number')} width={30} />
					<Column label={t('county.title')} relation='county' property='name' />
					<Column label={t('city.title')} relation='city' property='name' />
					<Column label={t('requestRiver.title')} relation='river' property='name' />
					<Column label={t('operInfo.organization')} relation='organization' property='name' />
					<Column label={t('requestPeriod.title')} relation='period' property='name' />
					<Column label={t('requestCategory.title')} relation='category' property='name' />
					<Column label={t('operInfo.plan')} property='plan' />
					<Column label={t('operInfo.ship')} property='ship' />
					<Column label={t('operInfo.deliver')} property='deliver' />
					<Column property='date' label={t('date_short')} width={120} />
					<Column property='updatedAt' label={t('operInfo.lastEditDate')} width={120} />
					<Column label={t('request.author')} relation='owner' computed={fio} />
				</Table>
				{/*<AddRowButton onClick={this.openPopup} />*/}
				{this.showPopup && <Edit onClose={this.closePopup} selectedRecordId={this.selectedRecordId} />}
				<div className='mt-3'>
					<h1>План: {plan}</h1>
					<h1>
						Отгружено: {ship} / {plan && deliver ? ((ship / plan) * 100).toFixed(2) : 0}%
					</h1>
					<h1>
						Доставлено: {deliver} / {plan && deliver ? ((deliver / plan) * 100).toFixed(2) : 0}%
					</h1>
				</div>
			</div>
		);
	}
}

export default List;
