import { inject, observer } from 'mobx-react';
import React from 'react';
import { observable } from 'mobx';
import { renderStatus } from 'client/tools';
import t from 'i18n';
import { Column, Pager, Table } from '@smartplatform/ui';
import { ListStore } from 'components';
import { getPerPage } from 'client/tools';
import store from 'client/store';
import { COUNTIES_SCOPE } from '../constants';

@inject('store')
@observer
export class CountyMunicipalities extends React.Component {
	@observable records = [];
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.model = store.model['ViewMunicipality'];
		this.countyId = this.props.store.record.countyId;
		this.store = new ListStore({
			filters: {
				where: { countyId: this.countyId },
				...COUNTIES_SCOPE,
			},
		});
	}

	componentDidMount() {
		this.store.setPerPage(getPerPage());
	}

	onRowClick = (record) => store.route.push({ path: `/emergency/municipalities/${record.id}` });

	renderRisks = ({ risks = [] }) =>
		risks.map(({ categoryName, peopleCount, teenagersCount }) => (
			<div>
				{categoryName} / {peopleCount} / {teenagersCount}
			</div>
		));

	renderRisksSum = ({ risks = [] }) => {
		const { peopleCount, teenagersCount } = risks.reduce((acc, value) => {
			acc.peopleCount = (acc.peopleCount || 0) + (value.peopleCount || 0);
			acc.teenagersCount = (acc.teenagersCount || 0) + (value.teenagersCount || 0);
			return acc;
		}, {});
		return (
			<div>
				{peopleCount} / {teenagersCount}
			</div>
		);
	};

	render() {
		const { onQueryUpdate, onChange, totalCount, page, query, perPage, getInstance, isPerPageSetted } = this.store;
		const { onRowClick } = this;
		const tableProps = isPerPageSetted ? { model: this.model } : { rows: [] };

		return (
			<div className='model-list'>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table query={query} onQueryUpdate={onQueryUpdate} key={tableProps.model} {...tableProps} onRowClick={onRowClick} getInstance={getInstance}>
						<Column property='name' label={t('municipalFormation.name')} />
						<Column relation='cities' computed={(r) => <div>{r.map((i) => i.name).join(', ')}</div>} label={t('city.plural')} />
						<Column property='population' computed={({ population }) => population || '-'} label={t('municipalFormation.population')} width={80} />
						<Column property='statusName' computed={({ status }) => renderStatus(status)} label={t('status')} width={150} />
						<Column computed={this.renderRisks} label={t('municipalFormation.risks')} width={300} />
						<Column computed={this.renderRisksSum} label={t('overall')} width={100} />
					</Table>
				</div>
			</div>
		);
	}
}
