import { DatePicker, Input, RelationSelect, FormContext, Table, Column } from '@smartplatform/ui';
import { Attachments, DeleteButton } from 'client/components';
import React from 'react';
import { LabelField, AddRowButton } from 'delivery/components';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { LadingBillSuppliesEditPopup } from './LadingBillSuppliesEditPopup';

@inject('store')
@observer
export class LadingBillCard extends React.Component {
	static contextType = FormContext;
	@observable showPopup = false;
	@observable selectedRecord = null;
	@observable supplies = [];

	openPopup = () => (this.showPopup = true);
	closePopup = async () => {
		this.showPopup = false;
		this.selectedRecord = null;
	};
	onRowClick = (row) => {
		this.selectedRecord = row;
		this.openPopup();
	};

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.init();
	}

	init = async () => {
		this.supplies = await this.props.bill.supplies();
	};

	onDelete = async () => {
		const { bill } = this.props;
		const index = this.props.store.ladingBills.findIndex((bill) => bill.id === bill.id);
		this.props.store.ladingBills.splice(index, 1);
		this.context.form.removeHasMany('ladingBills', bill);
		this.store.onRelationRecordRemove('ladingBills', bill);
	};
	onChange = (prop) => (value) => (this.props.bill[prop] = value);
	render() {
		const { disableEdit } = this.props.store;
		const record = this.props.bill;
		return (
			<div className='lading-bill-card'>
				<h2>
					{t('ladingBill.title')} {record.number}
				</h2>
				<div className='document-info'>
					<LabelField label={t('ladingBill.number')}>
						<Input value={record.number} onChange={this.onChange('number')} disabled={disableEdit} />
					</LabelField>
					<LabelField label={t('ladingBill.date')}>
						<DatePicker value={record.date} onChange={this.onChange('date')} showClearButton={false} disabled={disableEdit} />
					</LabelField>
				</div>
				{['sender', 'payer', 'consignee'].map((name) => (
					<div key={name} className='organization'>
						<LabelField label={t('ladingBill.' + name)}>
							<RelationSelect disabled={disableEdit} record={record} relation={name} property='name' onChange={this.onChange(name)} className='name' />
						</LabelField>
						<LabelField label={t('organization.inn')}>
							<RelationSelect record={record} relation={name} property='inn' disabled />
						</LabelField>
					</div>
				))}

				<div className='supplies'>
					<Table rows={this.supplies} onRowClick={this.onRowClick}>
						<Column property='nomenclatureCode' label={t('supply.nomenclatureCode')} />
						<Column property='name' label={t('name')} />
						<Column property='price' label={t('price')} />
						<Column relation='measure' property='name' label={t('measure')} />
						<Column property='packageType' label={t('supply.packageType')} />
						<Column property='placeQuantity' label={t('supply.placeQuantity')} />
					</Table>
					{!disableEdit && <AddRowButton onClick={this.openPopup} />}
				</div>
				{this.showPopup && (
					<LadingBillSuppliesEditPopup supplies={this.supplies} bill={record} onClose={this.closePopup} selectedRecord={this.selectedRecord} />
				)}
				<div className='organization'>
					{['totalItems', 'netWeight', 'grossWeight'].map((name) => (
						<LabelField key={name} label={t('ladingBill.' + name)}>
							<Input value={record[name]} onChange={this.onChange(name)} disabled={disableEdit} />
						</LabelField>
					))}
				</div>
				<div className='totalReleased'>
					<LabelField label={t('ladingBill.totalReleased')}>
						<Input value={record.totalReleased} onChange={this.onChange('totalReleased')} disabled={disableEdit} />
					</LabelField>
				</div>
				<Attachments record={record} canUpload={!disableEdit} />
				{!disableEdit && <DeleteButton text={t('ladingBill.delete')} onConfirm={this.onDelete} />}
			</div>
		);
	}
}
