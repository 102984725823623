import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Popup } from '@smartplatform/ui';
import t from 'i18n';
import { SaveButton, CancelButton } from 'components';
import store from 'client/store';
import { FormContext } from '@smartplatform/ui';
import './style.scss';

@inject('store')
@observer
export class ChangedPopup extends React.Component {
	static contextType = FormContext;
	constructor(props) {
		super(props);
		this.store = props.store;
	}
	onCloseChangedPopup = () => {
		this.store.newPath = null;
		this.store.showChangedPopup = false;
	};
	onSave = async () => {
		await this.context.form.save();
		store.route.push({ path: this.store.newPath });
		this.store.dropChanges();
		this.onCloseChangedPopup();
	};
	revertChanges = async () => {
		await this.store.init();
		store.route.push({ path: this.store.newPath });
		this.store.dropChanges();
		this.onCloseChangedPopup();
	};
	render() {
		const { onCloseChangedPopup, revertChanges, onSave } = this;

		return (
			<Popup onClose={onCloseChangedPopup} className='delivery-popup changed-popup'>
				<h2>{t('request.changed')}</h2>
				<div className='action'>
					<SaveButton onClick={onSave} />
					<Button variant='danger' onClick={revertChanges}>
						{t('notSave')}
					</Button>
					<CancelButton onClick={onCloseChangedPopup} />
				</div>
			</Popup>
		);
	}
}
