import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Row, DatePicker, Input, RecordSelect } from '@smartplatform/ui';
import { AddressSearch } from 'components';
import { shortenAddressName } from 'client/tools';
import store from 'client/store';
import './style.scss';

@observer
export default class FireAlarm112 extends React.Component {
	@observable address = null;
	@observable addressStr = '';
	@observable messageDate = new Date();
	@observable operatorNumber = '';
	@observable incidentCode = null;
	@observable incidentCodeStr = '';
	@observable info = '';

	onChange = (prop) => (value) => (this[prop] = value);

	onAddressChange = (address) => {
		this.address = address;
		this.addressStr = shortenAddressName(address.name);
	};

	onInfochange = (e) => (this.info = e.target.value);

	onIncidentCodeChange = (record) => {
		this.incidentCode = record;
		this.incidentCodeStr = record.code;
	};

	onClick = async () => {
		await store.model.FireAlarmMessage.post112Message({
			operatorNumber: this.operatorNumber,
			date: this.messageDate,
			addressText: this.addressStr,
			incidentCode: this.incidentCodeStr,
			information: this.info,
		});
	};

	render() {
		return (
			<div className='alarm-test'>
				<h2>Имитация сообщения о пожаре 112</h2>
				<Row>
					<div className='form-field' style={{ marginRight: 10 }}>
						<label>Поиск адреса</label>
						<AddressSearch value={this.address} onChange={this.onAddressChange} />
					</div>
					<div className='form-field' style={{ marginRight: 20 }}>
						<label>Код происшествия</label>
						<RecordSelect
							model={store.model.TechFireEmergencyCode}
							property='description'
							value={this.incidentCode}
							onChange={this.onIncidentCodeChange}
							itemsPerPage={100}
						/>
					</div>
					<div className='form-field' style={{ marginRight: 20 }}>
						<label>информация</label>
						<textarea value={this.info} onChange={this.onInfochange} rows='5' />
					</div>
				</Row>
				<Row>
					<div className='form-field' style={{ marginRight: 20 }}>
						<label>Строка адреса</label>
						<Input value={this.addressStr} onChange={this.onChange('addressStr')} />
					</div>

					<div className='form-field' style={{ marginRight: 20 }}>
						<label>Код</label>
						<Input value={this.incidentCodeStr} onChange={this.onChange('incidentCodeStr')} />
					</div>

					<div className='form-field' style={{ marginRight: 20 }}>
						<label>Номер оператора</label>
						<Input value={this.operatorNumber} onChange={this.onChange('operatorNumber')} />
					</div>
					<div className='form-field' style={{ marginRight: 20 }}>
						<label>Дата сообщения</label>
						<DatePicker value={this.messageDate} onChange={this.onChange('messageDate')} showTimeSelect />
					</div>
				</Row>
				<div className='mt-2'>
					<Button onClick={this.onClick} variant='primary' disabled={!this.incidentCodeStr.trim() || !this.addressStr.trim()}>
						ТЕСТ СЛУЖБА 112
					</Button>
				</div>
			</div>
		);
	}
}
