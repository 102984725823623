import t from 'i18n';
import './style.scss';
import React from 'react';
import { Button } from '@smartplatform/ui';
import PlusIcon from '!!svg-react-loader!img/icons/plus.svg';

export const AddRowButton = ({ onClick, text = t('add'), icon = <PlusIcon /> }) => {
	return (
		<Button className='add-row-button' onClick={onClick}>
			{text} {icon}
		</Button>
	);
};
