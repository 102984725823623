import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import { Loader, Tabs, Tab } from '@smartplatform/ui';
import { modelsConfig } from './models-config';
import t from 'i18n';

const DEFAULT_PRIORITY = 3;

@observer
export default class MainPopup extends React.Component {
	@observable tab = null;
	@observable tabs = [];
	@observable weatherData = (<Loader size={18} />);

	constructor(props) {
		super(props);
		const { objects, renderPopupComponent } = this.props;

		console.log(
			'[MainPopup]',
			objects.map(({ record }) => record.constructor.name),
			this.props
		);
		for (const object of objects) {
			const { record, render, title } = object;
			const modelName = record.MODEL.INFO.name;

			if (modelName) {
				if (modelsConfig[modelName]) {
					const { title: modelTitle, renderPopup, priority } = modelsConfig[modelName];
					if (renderPopupComponent || renderPopup) {
						const tab = {
							modelName,
							title: title || modelTitle,
							renderPopup: renderPopupComponent || renderPopup,
							record,
							priority: priority || DEFAULT_PRIORITY,
						};

						this.tabs.push(tab);
					}
				} else if (render) {
					// динамические слои модуля @smartplatform/map
					const tab = {
						modelName,
						title,
						renderPopup: render,
						record,
						priority: record.order,
					};
					this.tabs.push(tab);
				}
			}
		}
		this.tabs = this.tabs.sort((a, b) => (a.priority || DEFAULT_PRIORITY) - (b.priority || DEFAULT_PRIORITY));
		if (this.tabs.length > 0) this.tab = this.tabs[0];
	}

	componentDidMount() {
		document.addEventListener('mousemove', this.onMouseMove);
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.onMouseMove);
	}

	onMouseMove = (e) => e.stopImmediatePropagation();

	initWeather = async () => {
		let [lon, lat] = this.props.lonLat;
		if (lon < -180) lon += 360;
		if (lon > 180) lon -= 360;
		try {
			const res = await fetch(`/api/openweather?lon=${lon}&lat=${lat}`);
			const data = await res.json();
			console.log('data', data);
			let icon = null;
			let description = null;
			if (data.weather[0]) {
				description = data.weather[0].description;
				description = (
					<div>
						<em>{description[0].toUpperCase() + description.slice(1)}</em>
					</div>
				);
				icon = (
					<div className='weather-icon'>
						<img src={`http://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png`} width={100} height={100} alt='' />
					</div>
				);
			}
			this.weatherData = (
				<div className='weather-popup'>
					<div>
						{description}
						<div>
							{t('weather.temp')}: <em>{Math.round(data.main['temp'])}</em> <small>C&deg;</small>
						</div>
						<div>
							{t('weather.feeling')}: <em>{Math.round(data.main['feels_like'])}</em> <small>C&deg;</small>
						</div>
						<div>
							{t('weather.pressure')}: <em>{Math.round(data.main['pressure'] / 1.333)}</em> <small>{t('weather.pressureMeasure')}</small>
						</div>
						<div>
							{t('weather.humidity')}: <em>{data.main['humidity']}</em>
							<small>%</small>
						</div>
						<div>
							{t('wind.title')}:
							<span className='wind-dir' style={{ transform: `rotate(${data.wind['deg']}deg` }}>
								<FontAwesomeIcon icon={faArrowDown} />
							</span>
							<em>{data.wind['speed']}</em>
							<small> {t('metersPerSecond')}</small>
						</div>
					</div>
					{icon}
				</div>
			);
		} catch (e) {
			this.weatherData = <div>{e.message}</div>;
		}
		this.isLoading = false;
	};

	setTab = (tab) => {
		this.tab = tab;
	};

	render() {
		return (
			<div className='main-popup'>
				<Tabs type='manual' className='fires-popup-tabs'>
					{this.tabs.map((tab) => {
						const { modelName, record } = tab;
						const config = modelsConfig[modelName];
						let icon;
						if (config && config.icon) icon = <img src={config.icon} alt='' />;
						const title = (
							<div className='tab'>
								{icon} {tab.title}
							</div>
						);
						return <Tab key={modelName + record.id} onClick={() => this.setTab(tab)} title={title} />;
					})}
				</Tabs>
				<div className='fires-popup-content'>
					{this.tabs.map((tab, i) => {
						const { modelName, record } = tab;
						const isActive = this.tab.modelName === modelName && this.tab.record.id === record.id;
						return (
							<div key={i} className={'tab-content' + (isActive ? ' active' : '')}>
								{tab.renderPopup(tab.record)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
