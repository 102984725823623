import React from 'react';
import { Style, Icon } from 'ol/style';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { createXYZ } from 'ol/tilegrid';
import { get as getProj } from 'ol/proj';
import MVT from 'ol/format/MVT';
import store from 'client/store';
import WaterSupplyPopup from './Popup';
import t from 'i18n';
import { ICON_TYPE_MAP } from './icons';

const TILE_SIZE = 512;

export default class Layer {
	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		const renderPopup = this.renderPopup;

		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: createXYZ({
				extent: getProj('EPSG:3857').getExtent(),
				maxZoom: 18,
				tileSize: TILE_SIZE,
			}),
			tileUrlFunction: this.tileUrlFunction,
			tileLoadFunction: this.tileLoadFunction(renderPopup),
		});

		this.layer = new VectorTileLayer({
			source: this.source,
			style: this.getStyle,
		});

		this.layer.setZIndex(3);
		console.log('this.layer', this.layer);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
		// this.load();
	};

	getStyle = (feature) => {
		const { record } = feature.getProperties();
		return new Style({
			image: new Icon({
				src: ICON_TYPE_MAP[record.typecode](record),
				scale: 0.4,
				anchor: [27, 70],
				anchorXUnits: 'pixels',
				anchorYUnits: 'pixels',
			}),
		});
	};

	tileUrlFunction = (coords) => {
		const { selectedTypes, working, division } = this.settings.filter;
		const jsonFilter = {
			and: [
				{ typeId: { neq: null }},
			],
		};
		if (selectedTypes.length) {
			jsonFilter.and.push({ typeId: { inq: selectedTypes }});
		}
		if (![undefined, null].includes(working)) {
			if (working === true) {
				jsonFilter.and.push({ or: [
					{ typeCode: 'GSK' },
					{ typeCode: 'ICEHOLE' },
				]});
			}
		}
		if (division) {
			jsonFilter.and.push({ divisionId: division.id });
		}
		const jsonFilterStr = encodeURIComponent(JSON.stringify(jsonFilter));
		return `/api/mvt?model=ViewWaterSupply&x=${coords[1]}&y=${coords[2]}&z=${coords[0]}&jsonFilter=${jsonFilterStr}&noCache=1&columns=id,number,working,typeCode,divisionId&buffer=${TILE_SIZE}&extent=${TILE_SIZE}`;
	};

	tileLoadFunction = (render) => (tile, url) => {
		tile.setLoader(function (extent, resolution, projection) {
			fetch(url).then(function (response) {
				response.arrayBuffer().then(function (data) {
					const format = tile.getFormat(); // ol/format/MVT configured as source format
					const renderFeatures = format.readFeatures(data, {
						extent: extent,
						featureProjection: projection,
					});
					renderFeatures.forEach((f) => {
						const record = f.getProperties();
						f.properties_ = {
							title: t('waterSupply.title') + ' №' + record.id,
							record,
							render,
						};
					});
					tile.setFeatures(renderFeatures);
				});
			});
		});
	};

	onWorkingChange = (value) => {
		this.settings.filter.working = value;
		store.local.save();
		this.source.refresh();
	};

	onTypeToggle = (id) => {
		const { selectedTypes } = this.settings.filter;
		const index = selectedTypes.findIndex((i) => i === id);
		~index ? selectedTypes.splice(index, 1) : selectedTypes.push(id);
		store.local.save();
		this.source.refresh();
	};
	onDivisionChange = (record) => {
		this.settings.filter.division = record;
		store.local.save();
		this.source.refresh();
	};

	setVisible = () => this.layer.setVisible(this.settings.show);
	renderPopup = (record) => <WaterSupplyPopup record={record} />;
}
