import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form, Popup, RelationSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { Actionbar } from 'components';
import { renderStatus } from 'client/tools';

@inject('store')
@observer
export class TrackEditPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	recIndex;

	constructor(props) {
		super(props);
		this.isNew = !this.props.selectedRecord;
		this.store = this.props.store;
		this.init();
	}

	init = async () => {
		const selectedRecord = this.props.selectedRecord;
		if (!this.isNew) {
			this.record = new store.model.RequestTrack(selectedRecord);

			const loadRelation = async (relation) => {
				delete this.record['_' + relation];
				this.record[relation] = await this.record[relation];
			};
			await Promise.all(['status', 'city'].map((name) => loadRelation(name)));

			this.recIndex = this.store.tracks.findIndex(({ id }) => id === this.record.id);
		} else {
			this.record = new store.model.RequestTrack({ date: new Date() });
		}

		this.isLoading = false;
	};

	beforeSave = async () => {
		this.store.tracksStorehouse = this.record.storehouse;

		if (this.isNew) {
			await this.record.save();
			this.props.addTrack(this.record);
		} else {
			this.store.tracks[this.recIndex] = this.record;
		}
		this.store.onChange('tracks')(this.record);
		this.props.onClose();
		return false;
	};

	onDelete = () => {
		this.props.deleteTrack(this.record, this.recIndex);
		this.store.onRelationRecordRemove('tracks', this.record);
		this.props.onClose();
		return false;
	};

	onCancel = async () => this.props.onClose();
	onChange = (prop) => (value) => (this.record[prop] = value);

	render() {
		if (this.isLoading) return null;
		const title = this.isNew ? t('addRecord') : t('editRecord');

		const idDeliveryStatus = this.record.status?.name === 'Доставлен';
		let disableSave;
		if (idDeliveryStatus) {
			disableSave = !!['date', 'statusId', 'cityId', 'storehouse'].find((prop) => !this.record[prop]);
		} else {
			disableSave = !!['date', 'statusId', 'cityId'].find((prop) => !this.record[prop]);
		}

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton disabled={disableSave} />
				<Actionbar.CancelButton back={this.onCancel} />
				{this.isNew ? <Actionbar.CleanButton /> : <Actionbar.DeleteButton onDelete={this.onDelete} noDelete />}
			</Actionbar>
		);

		return (
			<Popup onClose={this.onCancel} className='delivery-popup'>
				<h2>{title}</h2>
				<Form record={this.record} stay beforeSave={this.beforeSave} controls={controls} noDelete noSave>
					<Field property='date' label={t('date')} isRequired />
					<Field relation='status' computed={renderStatus} label={t('requestTrack.status')} isRequired>
						<RelationSelect isRequired />
					</Field>
					<Field relation='city' property='name' label={t('city.title')} isRequired>
						<RelationSelect isRequired />
					</Field>
					{idDeliveryStatus && (
						<Field relation='storehouse' property='name' label={t('storehouse.title')} isRequired disabled={!this.isNew}>
							<RelationSelect isRequired />
						</Field>
					)}
				</Form>
			</Popup>
		);
	}
}
