import store from 'client/store';
import { observable } from 'mobx';
import debounce from 'lodash/debounce';

const PER_PAGE = 10;

class addressStore {
	@observable rank = null;
	@observable ptp = null;
	@observable ktp = null;
	@observable order = null;
	@observable totalCount = null;
	@observable page = 1;
	@observable addressName = null;
	@observable name = null;
	@observable description = null;
	@observable objectsCount = null;

	@observable objectsCountSearch = null;
	@observable addressSearch = null;
	@observable nameSearch = null;
	@observable descriptionSearch = null;

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	init = () => {
		this.resetFilters();
	};

	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
	};

	doSearch = (prop, searchField) => {
		this[prop] = this[searchField];
	};

	onSearch = (searchField, prop) => (value) => {
		this.page = 1;
		this[searchField] = value;
		this.doSearch(prop, searchField);
	};

	onQueryUpdate = (query) => {
		if (query._totalCount) this.totalCount = query._totalCount;
		this.order = query.order;
		console.log(this.order);
	};

	resetFilters = () => {
		for (let [key, value] of Object.entries(this)) {
			if (typeof value === 'function' || key === 'totalCount') continue;
			if (key === 'page') this[key] = 1;
			else this[key] = null;
		}
	};

	getFilters = () => {
		const filters = {
			where: {
				and: [],
			},
		};

		if (this.rank) filters.where.and.push({ rank: this.rank.name });
		if (this.name) filters.where.and.push({ name: { ilike: `%${this.name}%` } });
		if (this.addressName) filters.where.and.push({ addressName: { ilike: `%${this.addressName}%` } });
		if (this.description) filters.where.and.push({ description: { ilike: `%${this.description}%` } });
		if (this.objectsCount) filters.where.and.push({ objectsCount: { ilike: `%${this.objectsCount}%` } });
		if (this.ptp) filters.where.and.push({ ptp: { neq: null } });
		if (this.ktp) filters.where.and.push({ ktp: { neq: null } });

		filters.order = this.order;
		filters.skip = (this.page - 1) * PER_PAGE;
		filters.limit = PER_PAGE;

		return filters;
	};
}

export default new addressStore();
