import React from 'react';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import CameraPopup from './CameraPopup';
import icon from 'client/img/icons/camera.svg';
import t from 'i18n';

const color = '#0af';

export default {
	modelName: 'Camera',
	title: t('camera.title'),
	filterTitle: t('camera.plural'),
	filter: {
		fields: ['id', 'geo', 'name', 'url'],
	},
	showInFilters: true,
	style: new Style({
		fill: new Fill({ color: asArray([...ColorUtils.hexToArray(color).slice(0, 3), 0.5]) }),
		stroke: new Stroke({ color, width: 1 }),
		image: new Icon({ src: icon, anchor: [9, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
	}),
	icon,
	priority: 3,
	renderPopup: record => <CameraPopup key={record.id} record={record} />,
};
