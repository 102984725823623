import { inject, observer } from 'mobx-react';
import React from 'react';
import { observable } from 'mobx';
import { fio } from 'client/tools';
import t from 'i18n';
import { renderStatus } from 'client/tools';
import { History } from 'components';

const FIELDS = {
	name: t('name'),
	date: t('request.date'),
	typeId: t('request.type'),
	levelId: t('level'),
	organizationId: t('organization.title'),
	riskCategoryId: t('riskCategory.title'),
	countyId: t('county.title'),
};

@inject('store')
@observer
export class Log extends React.Component {
	@observable items = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.record = this.props.store.record;
		this.init();
	}

	getLink = (record, property = 'filename') =>
		record && (
			<a className='file-name' target='_blank' href={record.downloadFile(property)}>
				{record[property]}
			</a>
		);
	init = async () => {
		const getComments = async () =>
			await this.record.comments.find({
				include: [{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } }],
				order: 'createdAt asc',
			});

		const getLogs = async () =>
			await this.record.logs.find({
				include: ['owner', 'memberStatus', 'member', 'status', 'explanatoryNoteFile', 'planFile', 'correctionPlanFile'],
				order: 'createdAt asc',
			});

		const [logs, comments] = await Promise.all([getLogs(), getComments()]);

		comments.forEach((i) => (i.updated = 'comment'));

		const items = [...logs, ...comments].map((record) => {
			let { memberId, updated, ownerId, member, memberStatus, status, explanatoryNoteFile, planFile, correctionPlanFile } = record;

			if (updated === 'comment') updated = ['comment'];
			record.actions = [];
			const actions = record.actions;
			const newFields = [];
			// new request
			if (!updated) {
				actions.push({ title: t('history.requestCreate') });
				return record;
			}

			const map = {
				memberStatusId: {
					title: t('history.updateMemberStatus_short'),
					subject: memberId !== ownerId && fio(member),
					newValue: <span className='ml-2'>{renderStatus(memberStatus)}</span>,
				},
				comment: { title: t('history.leftCommentDiscussion') },
				addMember: { title: t('history.addMember'), subject: fio(member) },
				removeMember: { title: t('history.removeMember'), subject: fio(member) },
				addExplanatoryNoteFile: { title: t('history.addExplanatoryNoteFile'), subject: this.getLink(explanatoryNoteFile) },
				removeExplanatoryNoteFile: { title: t('history.removeExplanatoryNoteFile'), subject: this.getLink(explanatoryNoteFile) },
				addPlanFile: { title: t('history.addPlanFile'), subject: this.getLink(planFile) },
				removePlanFile: { title: t('history.removePlanFile'), subject: this.getLink(planFile) },
				addCorrectionPlanFile: { title: t('history.addCorrectionPlanFile'), subject: this.getLink(correctionPlanFile) },
				removeCorrectionPlanFile: { title: t('history.removeCorrectionPlanFile'), subject: this.getLink(correctionPlanFile) },
			};
			for (const field of updated) {
				if (field === 'statusId') {
					if (record.ownerId) actions.push({ title: t('history.requestStatus'), newValue: <span className='ml-2'>{renderStatus(status)}</span> });
					else if (status?.code === 'approved')
						actions.push({ title: t('history.planApproved'), newValue: <span className='ml-2'>{renderStatus(status)}</span> });
					else if (status?.code === 'agreed') actions.push({ title: t('history.allAgreed'), newValue: <span className='ml-2'>{renderStatus(status)}</span> });
					else actions.push({ title: t('history.notAllAgreed'), newValue: <span className='ml-2'>{renderStatus(status)}</span> });
				} else if (map[field]) {
					actions.push(map[field]);
				} else {
					if (!newFields.length) {
						newFields.push(field);
						actions.push({ title: t('history.requestUpdate') + ':', newFields });
					} else newFields.push(field);
				}
			}

			return record;
		});
		this.items = items.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		return <History items={this.items} manualFields={FIELDS} />;
	}
}
