import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Headers from '@smartplatform/ui/src/components/table/Headers';
import Rows from './Rows';
import './style.scss';

@observer
export default class Table extends React.Component {
	static propTypes = {
		rows: PropTypes.oneOfType([PropTypes.array, PropTypes.func, PropTypes.object]),
		model: PropTypes.func,
		query: PropTypes.object,
		getRef: PropTypes.func,
		className: PropTypes.string,
		multiline: PropTypes.bool,
		filters: PropTypes.bool,

		/**
		 * Путь, по которому будет переход вида {rootPath}/{path}/{id}
		 * если не задан, переход будет по plural модели {rootPath}/{plural}/{id}
		 */
		path: PropTypes.string,

		/**
		 * Корневой путь, по умолчанию '/'
		 */
		rootPath: PropTypes.string,

		/**
		 * Функция, если задана, будет вызвана при клике на строку таблицы.
		 * В аргументах будут переданы:
		 * record - запись в строке
		 * row = номер строки, начиная с 0
		 */
		onRowClick: PropTypes.func,
		clickable: PropTypes.bool,

		/**
		 * Сортировка по одной колонке
		 */
		singleSort: PropTypes.bool,

		/**
		 * Функция, в аргументах будут переданы (record, row) - запись и номер строки.
		 * Если вернёт true - строка таблицы будет подсвечена.
		 */
		hilightRow: PropTypes.func,

		/**
		 * Функция, в аргументах будут переданы (record, row) - запись и номер строки.
		 * Должна возвращать название класса, который будет присвоен строке.
		 */
		rowClassName: PropTypes.func,

		debug: PropTypes.bool,
		noRecordsMsg: PropTypes.any,

		onQueryUpdate: PropTypes.func,
		onLoadStart: PropTypes.func,
		onLoadEnd: PropTypes.func,
		getInstance: PropTypes.func,
		wrapper: PropTypes.any,

		//! +++++++
		infinityScroll: PropTypes.bool,
		infinityScrollPartSize: PropTypes.number,
		//! +++++++
	};

	static defaultProps = {
		rootPath: '/',
		debug: false,
		multiline: false,
		singleSort: true,
		clickable: true,
		wrapper: React.Fragment,
		//! +++++++
		infinityScroll: false,
		infinityScrollPartSize: 10,
		//! +++++++
	};

	onMount = (el) => {
		if (this.props.getRef) this.props.getRef(el);
	};

	render() {
		const { multiline, className, onRowClick, query, infinityScroll, infinityScrollPartSize } = this.props;

		this.props.query.limit = infinityScroll ? infinityScrollPartSize : this.props.query.limit;

		const _className = classNames('sp-table', className, {
			multiline,
			clickable: !!onRowClick,
		});

		const Wrapper = this.props.wrapper;

		return (
			<Wrapper>
				<table className={_className} ref={this.onMount}>
					<thead>
						<Headers {...this.props} queryMode={!!query} />
					</thead>
					<Rows {...this.props} query={query} />
				</table>
			</Wrapper>
		);
	}
}
