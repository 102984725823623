import React from 'react';
import { ColorPicker, Field, Form } from '@smartplatform/ui';
import { StyleEdit } from '@smartplatform/map/client';
import t from 'i18n';
import store from 'client/store';

import { toLowerCamelCase } from 'client/tools';
import GeoEdit from 'components/geo-edit/GeoEdit';
import { MODEL_NAME } from '../constants';
import Image from "./Image";

const EXCLUDED = ['id', 'ownerId', 'createdAt', 'updatedAt', 'deletedAt'];

export default ({ record, path }) => {
	const model = store.model[MODEL_NAME];

	const relations = { ...model.RELATIONS };
	console.log(relations);
	for (let name in relations) {
		console.log(name);
		if (relations[name].type !== 'belongsTo' || name === 'owner' || name === 'foams') delete relations[name];
	}

	const foreignKeys = Object.values(relations).map((r) => r.foreignKey);
	const keys = [];
	for (let name in model.PROPERTIES) {
		if (!EXCLUDED.includes(name) && !foreignKeys.includes(name)) keys.push(name);
	}

	const properties = [];
	keys.forEach((key) => properties.push({ name: key, ...model.PROPERTIES[key] }));

	return (
		<Form record={record} noDelete returnTo={path}>
			{properties.map((property) => {
				const label = property.description || t(`${toLowerCamelCase(MODEL_NAME)}.${property.name}`);
				let content;
				if (property.name === 'color') {
					content = <ColorPicker />;
				} else if (property.type === 'Geography') {
					content = <GeoEdit />;
				} else if (property.type === 'Object' && property.name) {
					content = <StyleEdit noIcon />;
				} else if (property.name === 'icon') {
					content = <Image />;
				}
				return (
					<Field key={property.name} property={property.name} label={label}>
						{content}
					</Field>
				);
			})}
			{Object.keys(relations).map((name) => {
				const { model, description } = relations[name];
				const label = description || t(`${toLowerCamelCase(model)}.title`);
				return <Field key={'relation' + name} relation={name} property='name' label={label} />;
			})}
		</Form>
	);
};
