import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import t from 'i18n';
import { Popconfirm } from '@smartplatform/ui';
import DeleteIcon from '!!svg-react-loader!img/icons/delete.svg';

export const Attachment = observer(({ onDelete, attachment, property = 'filename', isNew, noDelete }) => {
	const _onDelete = () => onDelete(attachment);

	const msg = (
		<>
			{t('deleteFile')}
			<br />
			<em>{attachment[property]}</em> ?
		</>
	);

	return (
		<div className='attachment'>
			{!isNew && !noDelete && (
				<Popconfirm onConfirm={_onDelete} content={msg} portalClassName='confirm-delete'>
					<DeleteIcon />
				</Popconfirm>
			)}
			<div className='info'>
				<a className='file-name' target='_blank' href={attachment.downloadFile(property)}>
					{attachment[property]}
				</a>
			</div>
		</div>
	);
});

Attachment.propTypes = {
	attachment: PropTypes.object,
	property: PropTypes.string,
	onDelete: PropTypes.func,
	noDelete: PropTypes.bool,
};
