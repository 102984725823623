import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import t from 'i18n';

@observer
export default class Statuses extends React.Component {

	render() {
		return <div className="appeal-statuses">
			<h1>{t('appealStatus.plural')}</h1>
			<Switch>
				<Route path="/appeals/dictionaries/statuses" exact component={List} />
				<Route path="/appeals/dictionaries/statuses/create" component={Edit} />
				<Route path="/appeals/dictionaries/statuses/:id" component={Edit} />
			</Switch>
		</div>;
	}

}
