import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import {Input, NumberInput, NumberValue} from "@smartplatform/ui";

@inject('card') @observer
export default class ForestryDamage extends React.Component {
	render() {
		const { card } = this.props;
		const { fire } = card;
        console.log(1,fire)
		return <div style={{ marginTop: '20px' }} className='box1'>
            <h2>{t('fire.forestryDamage')}</h2>
			<table className="forestry-table">
				<thead>
				<tr>
					<th width="70%">Наименование показателя</th>
					<th width="10%">Объем, куб.м</th>
					<th width="10%">Площадь, га</th>
					<th width="10%">Сумма, тыс.</th>
				</tr>
				</thead>
                <tbody>
                    <tr>
                        <td>{t(`fire.woodLoss`)}</td>
                        <td>
                            <NumberInput value={fire.lossOfWoodM3} onChange={card.onChange('lossOfWoodM3')}/>
                        </td>
                        <td></td>
                        <td>
                            <NumberInput value={fire.lossOfWoodRub} onChange={card.onChange('lossOfWoodRub')}/>
                        </td>
                    </tr>
                    <tr>
                        <td>{t(`fire.youngWoodLoss`)}</td>
                        <td></td>
                        <td>
                            <NumberInput value={fire.youngForestLossHa} onChange={card.onChange('youngForestLossHa')}/>
                        </td>
                        <td>
                            <NumberInput value={fire.youngForestLossRub} onChange={card.onChange('youngForestLossRub')}/>
                        </td>
                    </tr>
                    <tr>
                        <td>{t(`fire.burnedWood`)}</td>
                        <td></td>
                        <td></td>
                        <td>
                            <NumberInput value={fire.productLossRub} onChange={card.onChange('productLossRub')}/>
                        </td>
                    </tr>
                    <tr>
                        <td>{t(`fire.burnedHarvestedWood`)}</td>
                        <td>
                            <NumberInput value={fire.includingHarvestedWoodM3} onChange={card.onChange('includingHarvestedWoodM3')}/>
                        </td>
                        <td></td>
                        <td>
                            <NumberInput value={fire.includingHarvestedWoodRub} onChange={card.onChange('includingHarvestedWoodRub')}/>
                        </td>
                    </tr>
                </tbody>
			</table>
		</div>
	}
}
