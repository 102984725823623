import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Button, Loader, RecordSelect, Popover } from '@smartplatform/ui';
import { shortenAddressName } from 'client/tools';
import getGeo from '../address-search/getGeo';
import Tracks from './Tracks';
import store from 'client/store';
// import listStore from './store';

@inject('listStore') @observer
export default class AddressTools extends React.Component {

	@observable address = false;
	@observable changed = false;
	@observable picking = false;
	@observable isLoading = false;
	@observable lon = '';
	@observable lat = '';
	
	originalGeo = null;

	constructor(props) {
		super(props);
		this.listStore = props.listStore;
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// console.log('>', this.props.address.id, this.address.id);
		// if (this.props.address.id !== this.address.id) {
		// 	this.init();
		// }
	}
	
	init = async () => {
		console.log('init')
		this.originalGeo = this.listStore.address.geo;
		this.lon = this.listStore.address.geo ? this.listStore.address.geo.coordinates[0] : '';
		this.lat = this.listStore.address.geo ? this.listStore.address.geo.coordinates[1] : '';
	};
	
	yandex = async () => {
		this.isLoading = true;
		this.listStore.error = null;
		try {
			const geo = await getGeo(this.listStore.address.name);
			if (geo) {
				if (this.listStore.address.geo && this.coordsEqual(geo.coordinates, this.listStore.address.geo.coordinates)) {
					this.listStore.error = 'Найденные координаты совпадают с текущими значениями';
				} else {
					this.listStore.address.geo = geo;
					this.listStore.map.addHelperMarker(geo.coordinates);
					this.listStore.map.gotoPoint(geo.coordinates);
					this.changed = true;
					this.listStore.error = null;
				}
			}
			else {
				this.listStore.error = 'Нет результата';
			}
		}
		catch (e) {
			this.listStore.error = 'Ошибка соединения';
		}
		this.isLoading = false;
	};
	
	onTypeChange = type => {
		this.listStore.address.type = type;
		this.changed = true;
	};
	
	coordsEqual = (a, b) => a[0] === b[0] && a[1] === b[1];
	
	onPickPoint = e => {
		if (this.picking && !this.listStore.pickingTrack) {
			console.log('onPickPoint', e.latlng);
			this.listStore.address.geo = {
				type: 'Point',
				coordinates: [e.latlng.lng, e.latlng.lat]
			};
			this.listStore.error = null;
			this.listStore.map.addHelperMarker(this.listStore.address.geo.coordinates);
			this.listStore.map.gotoPoint(this.listStore.address.geo.coordinates);
			this.changed = true;
		}
	};
	
	save = async () => {
		this.originalGeo = this.listStore.address.geo;
		await this.listStore.address.save();
		this.changed = false;
	};
	
	cancel = async () => {
		this.changed = false;
		this.listStore.address.geo = this.originalGeo;
		await this.init();
		if (this.listStore.address && this.listStore.address.geo) {
			this.listStore.map.addHelperMarker(this.listStore.address.geo.coordinates);
			this.listStore.map.gotoPoint(this.listStore.address.geo.coordinates);
		}
	};
	
	pick = () => {
		this.listStore.map.picking = !this.listStore.map.picking;
		this.picking = !this.picking;
	};
	
	onLatChange = e => {
		this.lat = e.target.value;
		this.changed = true;
		this.checkCoords();
	};
	
	onLonChange = e => {
		this.lon = e.target.value;
		this.changed = true;
		this.checkCoords();
	};
	
	checkCoords = () => {
		if (parseFloat(this.lon) && parseFloat(this.lat)) {
			this.listStore.address.geo = {
				type: 'Point',
				coordinates: [parseFloat(this.lon), parseFloat(this.lat)]
			};
			this.listStore.map.addHelperMarker(this.listStore.address.geo.coordinates);
			this.listStore.map.gotoPoint(this.listStore.address.geo.coordinates);
		}
	};
	
	render() {
		return <div className="address-tools">
			<div className="id">ID: {this.listStore.address.id}</div>
			<div className="address-name">{shortenAddressName(this.listStore.address.name)}<small>{this.listStore.addressInfo}</small></div>
			<div className="location-edit">
				<Button onClick={this.yandex} className="btn-sm">Яндекс</Button>
				<Button onClick={this.pick} className="btn-sm" disabled={this.listStore.pickingTrack}>{!this.picking ? 'Указать' : 'Закончить'}</Button>
				{this.isLoading && <Loader size={20} style={{ marginLeft: 10 }} />}
			</div>
			<div className="lon-lat-input">
				<div className="input">
					<label>Широта</label>
					<input value={this.lat} onChange={this.onLatChange} onFocus={e => e.target.select()} />
				</div>
				<div className="input">
					<label>Долгота</label>
					<input value={this.lon} onChange={this.onLonChange} onFocus={e => e.target.select()} />
				</div>
{/*
				<div className="input">
					<label>&nbsp;</label>
					<Button onClick={this.setCoords} className="btn-sm">Ok</Button>
				</div>
*/}
			</div>
			<div className="type form-field">
				<label>Тип здания</label>
				<RecordSelect maxItems={10} itemsPerPage={1000}
					model={store.model.BuildingType}
					property="name"
					value={this.listStore.address.type}
					onChange={this.onTypeChange}
					className="drop-down-sm"
					noSearch
					noTotal
				/>
			</div>
			{this.listStore.case && <Tracks />}
			{(this.changed || !this.listStore.address.id) && <div className="actions">
				<Button onClick={this.save} className="btn-primary btn-sm">Сохранить</Button>
				<Button onClick={this.cancel} className="btn-sm">Отмена</Button>
			</div>}
			{this.listStore.error && <div className="error">{this.listStore.error}</div>}
		</div>;
	}
	
}
