import React from 'react';

export const FormFieldDisabled = ({ labelName, value }) => (
	<div className='form-field'>
		<label>{labelName}:</label>
		<input disabled value={value} />
	</div>
);

export const FormFieldText = ({ labelName, value }) => (
	<div className='form-field'>
		<label>{labelName}</label>
		<span>{value}</span>
	</div>
);

export const mergeStatusesByName = (allStatuses, trackData) => {
	return allStatuses.map((itm) => ({
		...trackData.find((item) => item.name === itm.name && item),
		...itm,
	}));
};

export const getStatusPosition = (index, posCount) => {
	const posLength = 100 / posCount;
	if (index === posCount - 1) {
		return 100;
	}
	return posLength * index + posLength / 2;
};
