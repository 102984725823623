import React from 'react';
import { inject, observer } from 'mobx-react';

import { DatePicker, DateValue, Input } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { fioShort } from '../../../../tools/fio';

@inject('card') @observer
export default class Dates extends React.Component {

	render() {
		const { card } = this.props;
		const disabled = !store.model.Fire.INFO.WRITE;

		const className = 'form-field';

		return <div>
			<h2>{t('date')}</h2>
			<div>
				<div className={className}>
					<label>{t('fire.detectDate')}</label>
					<DatePicker
						value={card.fire.detectDate}
						showClearButton={false}
						onChange={card.onChange('detectDate')}
						showTimeSelect
						autoSwitch={false}
						disabled={disabled}
					/>
				</div>
				<div className={className}>
					<label>{t('fire.reportDate')}</label>
					<DatePicker
						value={card.fire.reportDate}
						onChange={card.onChange('reportDate')}
						showTimeSelect
						disabled={disabled}
					/>
				</div>
				<div className={className}>
					<label>{t('fire.startDate')}</label>
					<DatePicker
						value={card.fire.startDate}
						onChange={card.onChange('startDate')}
						showTimeSelect
						disabled={disabled}
					/>
				</div>
				<div className={className}>
					<label>{t('fire.lastEditDate')}</label>
					<DateValue
						value={card.fire.updatedAt}
						disabled={disabled}
					/>
				</div>
				<div className={className}>
					<label>{t('fire.lastEditPerson')}</label>
					<Input value={fioShort(card.fire.owner || {})} disabled />
				</div>
			</div>
		</div>;
	}
}
