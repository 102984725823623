import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Table, Column, Pager } from '@smartplatform/ui';
import t from 'i18n/index';
import { RowAddButton } from 'fires/components';
import EditPilotPopup from './EditPilotPopup';
import store from 'client/store';
import { POPUP_PER_PAGE } from '../../constants';

@inject('store')
@observer
export default class Pilots extends React.Component {

	@observable records = [];
	@observable showPilotPopup = false;
	@observable page = 1;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.records = this.props.store.pilots;
	}

	onClosePopup = () => this.togglePopup(false);
	onOpenPopup = () => this.togglePopup(true);

	togglePopup = (boolean) => {
		if (!boolean) {
			this.selectedRecord = null;
		}
		this.showPilotPopup = boolean
	};

	onRowClick = (row) => {
		this.selectedRecord = row;
		this.togglePopup(true);
	};

	onPageChange = (page) => {
		this.page = page;
	};

	render() {
		return <div className="pilots">
			<Pager
				current={this.page}
				onChange={this.onPageChange}
				totalCount={this.records.totalCount}
				itemsPerPage={POPUP_PER_PAGE}
			/>
			<Table rows={this.records.slice(POPUP_PER_PAGE * (this.page - 1), POPUP_PER_PAGE * this.page)} onRowClick={this.onRowClick}>
				<Column relation="staff" property='fullName' label={t('staff.fullName')} />
				<Column property="intern" label={t('observerPilot.intern')} className="text-center" />
				<Column property="inspector" label={t('observerPilot.inspector')} className="text-center" />
			</Table>
			<RowAddButton onClick={this.onOpenPopup} model="ObserverPilot" disabled={this.props.store.disabled} />
			{this.showPilotPopup && (
				<EditPilotPopup
					pilot={this.selectedRecord}
					raid={this.raid}
					onClose={this.onClosePopup}
					store={this.props.store}
				/>
			)}
		</div>;
	}

}
