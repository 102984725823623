import React from 'react';
import Root from './Root';
import TechFiresIcon from '!!svg-react-loader!./icons/techfires.svg';
import MapIcon from '!!svg-react-loader!img/icons/sidebar/shared/map.svg';
import FireIcon from '!!svg-react-loader!./icons/fire.svg';
import FireDepIcon from '!!svg-react-loader!./icons/fireDep.svg';
import NoteIcon from '!!svg-react-loader!./icons/note.svg';
import RegistryIcon from '!!svg-react-loader!./icons/registry.svg';
import BellIcon from '!!svg-react-loader!./icons/bell.svg';
import ReportIcon from '!!svg-react-loader!./icons/report.svg';
import TransportData from '!!svg-react-loader!img/icons/sidebar/shared/transport-data.svg';
import SettingsIcon from '!!svg-react-loader!./icons/settings.svg';
import DictionaryIcon from '!!svg-react-loader!img/icons/sidebar/shared/dictionary.svg';
import ShiftIcon from '!!svg-react-loader!img/icons/shift.svg';
import WeatherIcon from '!!svg-react-loader!img/icons/sidebar/shared/registry.svg';
// import SentryIcon from '!!svg-react-loader!img/icons/sentry.svg';
import t from 'i18n';
import tMap from '@smartplatform/map/i18n';
import store from 'client/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faFireExtinguisher, faHomeAlt } from '@fortawesome/free-solid-svg-icons';
import JourneyIcon from '!!svg-react-loader!img/icons/sidebar/shared/journey.svg';

export const path = '/techfires';

export default {
	name: t('module.techfires'),
	code: 'techfires',
	icon: <TechFiresIcon />,
	path,
	component: Root,
	access: () => store.model.FireDep && store.model.FireDep.INFO.WRITE,
	menu: () => [
		{ title: t('techFire.weather.title'), path: `${path}/weather`, icon: <WeatherIcon /> },
		{ title: t('techFire.map.title'), path: `${path}/map`, icon: <MapIcon />, exact: true },
		{ title: t('techFire.fires.title'), path: `${path}/fires`, icon: <FireIcon /> },
		// { title: t('fireDep.plural'), path: `${path}/firedep`, icon: <FontAwesomeIcon icon={faFireExtinguisher} style={{ fontSize: 26, marginRight: -5 }} /> },
		{ title: t('fireDep.plural'), path: `${path}/firedep`, icon: <FireDepIcon /> },
		{ title: t('fireDepNote.title'), path: `${path}/note`, icon: <NoteIcon /> },
		{ title: t('techFireAddress.registry'), path: `${path}/addresses`, icon: <FontAwesomeIcon icon={faHomeAlt} style={{ fontSize: 26 }} /> },
		{ title: t('registries'), path: `${path}/registry`, icon: <RegistryIcon /> },
		{ title: t('fireDepShift.plural'), path: `${path}/shifts`, icon: <ShiftIcon /> },
		{ title: t('fireNotifier'), path: `${path}/notifier`, icon: <BellIcon /> },
		{ title: t('report.plural'), path: `${path}/reports`, icon: <ReportIcon /> },
		{ title: t('transportData.title'), path: `${path}/transport`, icon: <TransportData /> },
		{ title: t('dictionaries.plural'), path: `${path}/dictionaries`, icon: <DictionaryIcon /> },
		{ title: t('audit.title'), path: `${path}/journal`, icon: <JourneyIcon /> },
		store.model.MapLayer && store.model.MapLayer.INFO.WRITE
			? { title: tMap('map.layer.plural'), path: `/layers`, icon: <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 26 }} /> }
			: undefined,
		{ title: t('admin.titleShort'), path: `${path}/admin`, icon: <SettingsIcon /> },
	],
};
