import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Panel } from 'components';
import { CaseList } from './components';

@observer
export default class Cases extends React.Component {

	static propTypes = {};
	static defaultProps = {};

	constructor(props) {
		super(props);
	}

	render() {
		return <>
			{/*<Panel left={0} top={0} width={1300} height={600}>реестр</Panel>*/}
			<CaseList />
		</>;
	}

}
