import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Form, Tab, Tabs } from '@smartplatform/ui';
import { Breadcrumbs } from 'client/components';
import { DeleteButton } from 'components';
import { Actionbar } from 'components';
import { RESERVES_PATH } from '../constants';
import { MainInfo } from './MainInfo';
import { Movement } from './Movement';
import ReserveStore from './store';
import t from 'i18n';
import './style.scss';
import { isAdmin } from 'delivery/helpers/roles';

@observer
export class SinglePage extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReserveStore();
		this.store.id = parseInt(this.props.match.params.id);
		this.store.init();
	}

	render() {
		const { isLoading, record, onSave, onClean, back } = this.store;
		if (isLoading) return null;
		const { id } = record;
		const title = id ? t('reserve.nomenclatureCard') : t('reserve.createNomenclature');
		const rootTitle = t('reserve.title_full');
		const path = `${RESERVES_PATH}/${id}`;
		const disableSave = !!['categoryId', 'nomenclatureId', 'storehouseId'].find((prop) => !record[prop]) || record.startQuantity === null;

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton disabled={disableSave} />
				<Actionbar.CancelButton back={back} />
				{!record.id && <Actionbar.CleanButton onClean={onClean} />}
			</Actionbar>
		);

		return (
			<Provider store={this.store}>
				<div className='reserve'>
					<div className='header'>
						<div>
							<Breadcrumbs crumbs={[{ path: RESERVES_PATH, title: rootTitle }, { title }]} />
							<h2>{id ? record.name : t('reserve.newNomenclature')}</h2>
						</div>
						{isAdmin() && <DeleteButton text={t('reserve.delete')} onConfirm={this.store.onDelete} />}
					</div>
					<Form record={record} controls={controls} stay onSave={onSave} onDelete={back} noDelete noSave returnTo={RESERVES_PATH}>
						{id ? (
							<Tabs>
								<Tab title={t('reserve.mainInfo')} path={path} exact render={() => <MainInfo />} />
								<Tab title={t('reserveMovement.title')} path={`${path}/movement`} render={() => <Movement />} />
							</Tabs>
						) : (
							<Tabs>
								<Tab title={t('reserve.mainInfo')} path={`${RESERVES_PATH}/create`} exact render={() => <MainInfo />} />
							</Tabs>
						)}
					</Form>
				</div>
			</Provider>
		);
	}
}
