import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Select, RecordSelect, SuggestInput } from '@smartplatform/ui';
import { Panel } from 'components';
import store from 'client/store';
import './style.scss';
import AICategory from './AICategory';
import { Notification } from './Notification';
import FireAlarmAdpi from './FireAlarmAdpi';
import FireAlarm112 from './FireAlarm112';
import DateRangePicker from './DateRangePicker';

@observer
export default class Test extends React.Component {
	@observable selected = [];
	@observable selectedRecord = null;
	@observable selectedRecords = [];
	@observable text = '';
	@observable suggestions = [];

	constructor(props) {
		super(props);
	}

	onSelect = (value) => {
		console.log('onSelect', value);
		this.selected = value;
	};

	onRecordSelect = (value) => {
		console.log('onRecordSelect', value);
		this.selectedRecord = value;
	};

	onRecordsSelect = (value) => {
		console.log('onRecordsSelect', value);
		this.selectedRecords = value;
	};

	onChange = async (text) => {
		this.text = text;
		const len = Math.ceil(Math.random() * 15);
		const url = `https://random-data-api.com/api/users/random_user?size=${len}`;
		const res = await fetch(url);
		this.suggestions = await res.json();
	};

	renderSuggestion = (user) => {
		return (
			<div key={user.id} className='test-user-item'>
				<img src={user.avatar} alt='' /> {user.first_name} {user.last_name}
			</div>
		);
	};

	onSuggestionClick = (user) => (this.text = user.first_name + ' ' + user.last_name);

	convert = async () => {
		const fires = await store.model.Fire.find({
			include: [
				{
					relation: 'logs',
					scope: {
						order: 'id asc',
					},
				},
			],
			order: 'id asc',
		});

		const { id, createdAt, updatedAt, updated, fireId, ...properties } = store.model.FireLog.PROPERTIES;

		for (let fire of fires) {
			const logs = fire.logs();
			let prevLog = logs[0];
			for (let i = 1; i < logs.length; i++) {
				const fireLog = logs[i];
				for (let property of Object.keys(properties)) {
					if (!fireLog.updated.includes(property)) {
						fireLog[property] = prevLog[property];
					}
				}
				await fireLog.save();
				prevLog = fireLog;
			}
			console.log('processed logs of fire', fire.id);
		}
		console.log('...done');
	};

	render() {
		let u = Math.round((1.2345 + 128) * 256);
		let v = Math.round((-12.3456 + 128) * 256);

		const u8 = new Uint16Array([u, v]);
		const a = new Uint32Array(u8.buffer);
		// console.log('> Uint32Array', a);

		let _u = (a[0] >>> 16) / 256 - 128;
		let _v = (a[0] % 65536) / 256 - 128;

		// console.log('>', _u, _v);

		return (
			<Panel full header='Test' className='test fires-page'>
				<DateRangePicker />
				<Notification />
				<FireAlarmAdpi />
				<FireAlarm112 />
				{/* <AICategory /> */}
			</Panel>
		);
	}
}
