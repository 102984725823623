import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form, Popup, RelationSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { Actionbar } from 'components';

@inject('store')
@observer
export class LadingBillSuppliesEditPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	recIndex;

	constructor(props) {
		super(props);
		this.isNew = !this.props.selectedRecord;
		this.store = this.props.store;
		this.init();
	}

	init = async () => {
		const selectedRecord = this.props.selectedRecord;
		if (!this.isNew) {
			this.record = new store.model.RequestLadingBillSupply(selectedRecord);
			delete this.record._measure;
			delete this.record.measure;
			const loadRelation = async (relation) => {
				delete this.record['_' + relation];
				this.record[relation] = await this.record[relation];
			};
			await loadRelation('measure');

			this.recIndex = this.props.supplies.findIndex(({ id }) => id === this.record.id);
		} else {
			this.record = await store.model.RequestLadingBillSupply.create({ ladingBillId: this.props.bill.id });
		}

		this.isLoading = false;
	};

	onSave = async () => {
		await this.record.save();
		if (this.isNew) {
			this.props.supplies.push(this.record);
		} else {
			this.props.supplies[this.recIndex] = this.record;
		}

		//todo надо что-то решить с отслуживанием изменений внутри реляции, возможно в сторе сделать массив , который триггернет сохранение нескольких форм при сохрании заявки?
		// this.store.onChange('supplies')(this.record);
		this.props.onClose();
	};

	onDelete = async () => {
		await this.record.delete();
		this.props.supplies.splice(this.recIndex, 1);
		// this.store.onRelationRecordRemove('supplies', this.record);
		this.props.onClose();
	};

	onCancel = async () => {
		if (this.isNew) await this.record.delete();
		this.props.onClose();
	};

	onChange = (prop) => (value) => (this.record[prop] = value);

	render() {
		if (this.isLoading) return null;
		const title = this.isNew ? t('addRecord') : t('editRecord');
		const disableSave = !!['price', 'name'].find((prop) => !this.record[prop]);

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton disabled={disableSave} />
				<Actionbar.CancelButton back={this.onCancel} />
				{this.isNew ? <Actionbar.CleanButton /> : <Actionbar.DeleteButton onDelete={this.onDelete} noDelete />}
			</Actionbar>
		);

		return (
			<Popup onClose={this.onCancel} className='delivery-popup'>
				<h2>{title}</h2>
				<Form record={this.record} stay onSave={this.onSave} controls={controls} noDelete noSave>
					<Field property='nomenclatureCode' label={t('supply.nomenclatureCode')} />
					<Field property='name' label={t('name')} isRequired />
					<Field property='price' label={t('price')} isRequired />
					<Field relation='measure' property='name' label={t('measure')} />
					<Field property='packageType' label={t('supply.packageType')} />
					<Field property='placeQuantity' label={t('supply.placeQuantity')} />
				</Form>
			</Popup>
		);
	}
}
