import React from 'react';
import t from 'i18n';
import { Button } from '@smartplatform/ui';
import { ActionbarContext } from './Actionbar';

export const SaveButton = ({ onSave, title, disabled = false }) => {
	const context = React.useContext(ActionbarContext);
	const _onSave = async () => {
		await context.form.save();
		onSave?.();
	};

	return (
		<Button onClick={_onSave} variant='primary' disabled={disabled}>
			{title || t('save')}
		</Button>
	);
};
