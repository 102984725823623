import { observable } from 'mobx';
import { Tile } from 'ol/layer';
import { XYZ } from 'ol/source';

export default class DzzLayer {

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new Tile({
			source: new XYZ({
				projection: 'EPSG:3857',
				tileUrlFunction: function([z, x, y]) {
					const range = this.tileGrid.getFullTileRange(z);
					y = range.maxY - y;
					return '/dzz/tiles/' + z + '/' + x + '/' + y + '.png';
				},
				transition: 0,
			}),
			opacity: 0.7,
		});

		this.tileGrid = this.layer.getSource().getTileGrid();

		this.layer.setZIndex(4);
		this.mapStore.addLayer(this.layer);
		this.setVisible();
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
	};

};
