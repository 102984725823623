import React from 'react';
import { CancelButton as _CancelButton } from 'components';
import { ActionbarContext } from './Actionbar';

export const CancelButton = ({ back }) => {
	const context = React.useContext(ActionbarContext);
	const onBack = async () => {
		await context.form.back();
		back?.();
	};

	return <_CancelButton onClick={onBack} />;
};
