import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { DottedTextField } from 'fires/components/fields/DottedTextField';
import {Input, NumberInput} from "@smartplatform/ui";

@inject('card')
@observer
export default class Metero extends React.Component {
	render() {
		const { card } = this.props;
		const { fire } = card;
		const className = 'form-field';
		return <div>
			<h2>{t('fire.meteo')}</h2>
			{[
				'KPOdetected',
				'KPOliquidation'
			]
				.map(label =>
					<React.Fragment  key={label}>
						<div className={className}>
							<label>{t(`fire.${label}`)}</label>
							<Input value={fire[label] || t('no')} disabled />
						</div>
					</React.Fragment>
				)}
			<div className={className}>
				<label>{t(`fire.frontalPrecipitation`)}</label>
				<Input value={fire.frontalPrecipitation} onChange={card.onChange('frontalPrecipitation')}/>
			</div>
		</div>
	}
}
