import React from 'react';
import { observer, inject, Provider } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faArrowLeft, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';

import { Panel, HeatPointsFilter, CountiesFilter, GenShtabFilter, WeatherFilter, WindFilter, ModelsFilter, DzzFilter } from 'components';
import ForestryQuarters from '../../../map/filters/ForestryQuarters';
import Fires from '../../../map/filters/Fires';
import store from 'client/store';
import '../../../map/filters/style.scss';
import fireStore from "fires/fireStore";
import Forestries from 'fires/map/filters/Forestries';

@inject('card') @observer
export default class Filters extends React.Component {

	render() {
		const { card } = this.props;
		const { map } = card;
		
		if (!map.isInitialized) return null;
		
		const localSettings = store.local.fires.card;
		const settings = localSettings.layers;

		const additionalControls = <div className={'map-additional-controls' + (localSettings.filtersMaximized ? ' shifted' : '')}>
			<div className="map-additional-btn" onClick={card.toggleMapMaximized}>
				<FontAwesomeIcon icon={card.mapMaximized ? faCompress : faExpand} />
			</div>
		</div>;

		return localSettings.filtersMaximized ?
			<Panel left={-95} top={-70} width={290} bottom={70} height="auto" scrollable={false} wrapperClassName="fires-filters-wrapper" className="fires-filters">
				<div className="minimize-btn" onClick={() => map.switchFilters(false)}>
					<FontAwesomeIcon icon={faArrowLeft} />
				</div>
				{additionalControls}
				<CountiesFilter layer={map.countiesLayer} settings={settings.counties} />
				<Forestries layer={map.forestriesLayer} settings={settings.forestries} />
				<DzzFilter layer={map.dzzLayer} settings={settings.dzz} />
				<GenShtabFilter layer={map.genShtabLayer} settings={settings.genShtab} />
				<ModelsFilter layer={map.modelsLayer} settings={settings.models} />
				<ForestryQuarters inCard layer={map.forestryQuartersLayer} settings={settings.forestryQuarters} />
				<HeatPointsFilter layer={map.heatPointsLayer} settings={settings.heatPoints} />
				<WeatherFilter layer={map.weatherLayer} settings={settings.weather} />
				<WindFilter layer={map.windLayer} settings={settings.wind} />
			</Panel>
			:
			<>
				<div className="fires-filters-minimized" onClick={() => map.switchFilters(true)}>
					<FontAwesomeIcon icon={faFilter} />
				</div>
				{additionalControls}
			</>;
	}
}
