import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form, NumberInput, Popup } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { Actionbar } from 'components';

@inject('store')
@observer
export class BankAccountEditPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	originalValues;
	recIndex;

	constructor(props) {
		super(props);
		this.isNew = !this.props.selectedRecord;
		this.store = this.props.store;
		this.init();
	}

	init = async () => {
		const selectedRecord = this.props.selectedRecord;
		this.record = selectedRecord || new store.model.BankAccount({ organizationId: this.store.record.id });
		if (!this.isNew) {
			this.originalValues = new store.model.RequestSupply(this.record);
			this.recIndex = this.store.bankAccounts.findIndex(({ id }) => id === this.record.id);
		}
		this.isLoading = false;
	};

	onCancel = () => {
		if (!this.isNew) this.store.bankAccounts[this.recIndex] = this.originalValues;
		this.props.onClose();
	};

	onSave = () => {
		if (this.isNew) this.store.bankAccounts.push(this.record);
		this.props.onClose();
	};

	onDelete = () => {
		this.store.bankAccounts.splice(this.recIndex, 1);
		this.props.onClose();
	};

	render() {
		if (this.isLoading) return null;
		const title = this.isNew ? t('editRecord') : t('addRecord');
		const disableSave = !this.record.bank || !this.record.bic || !this.record.correspondentAccount;

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton onSave={this.onSave} disabled={disableSave} />
				<Actionbar.CancelButton back={this.onCancel} />
				{this.isNew ? <Actionbar.CleanButton /> : <Actionbar.DeleteButton onDelete={this.onDelete} noDelete />}
			</Actionbar>
		);

		return (
			<Popup onClose={this.onCancel} className='delivery-popup'>
				<h2>{title}</h2>
				<Form record={this.record} stay controls={controls} noDelete noSave>
					<Field property='bank' label={t('bankAccount.bank')} isRequired />
					<Field property='bic' label={t('bankAccount.bic')} isRequired>
						<NumberInput positiveOnly integerOnly />
					</Field>
					<Field property='swift' label={t('bankAccount.swift')}>
						<NumberInput positiveOnly integerOnly />
					</Field>
					<Field property='correspondentAccount' label={t('bankAccount.correspondentAccount')} isRequired>
						<NumberInput positiveOnly integerOnly />
					</Field>
				</Form>
			</Popup>
		);
	}
}
