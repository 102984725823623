import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Checkbox, DatePicker, Field, Input, RelationSelect } from '@smartplatform/ui';
import t from 'i18n';
import { fio } from 'client/tools';
import { renderStatus } from 'client/tools';
import { REQUEST_CODE } from 'delivery/constants';
import { SecondaryButton } from 'components/buttons';
import { observable } from 'mobx';
import { MessagePopup } from './MessagePopup';

@inject('store')
@observer
export class Main extends React.Component {
	@observable showMessagePopup = false;
	toggleMessagePopup = (value) => () => (this.showMessagePopup = value);

	render() {
		const { record, onChange, code, isOwner } = this.props.store;

		const isBasic = code === REQUEST_CODE;

		return (
			<div className='main'>
				{this.showMessagePopup && <MessagePopup onClose={this.toggleMessagePopup(false)} />}
				<div className='top'>
					<Field label={t('request.type')} relation='type' property='name' disabled isRequired>
						<RelationSelect noSearch noTotal key={record.typeId} isRequired onChange={onChange('typeId')} />
					</Field>
					<Field label={t('level')} relation='level' property='name'>
						<RelationSelect noSearch noTotal onChange={onChange('levelId')} />
					</Field>
					<Field label={t('request.number')} property='id' disabled>
						<Input />
					</Field>
					<Field label={t('request.date')} property='date' isRequired>
						<DatePicker showTimeSelect showClearButton={false} onChange={onChange('date')} />
					</Field>
					{isBasic && (
						<Field label={t('request.status')} className='form-field' relation='status' computed={renderStatus} isRequired>
							<RelationSelect showValue={renderStatus(record.status)} onChange={onChange('statusId')} isRequired noSearch />
						</Field>
					)}
					{isBasic && (
						<Field label={t('request.collective')} className='form-field checkbox' property='isCollective'>
							<Checkbox onChange={onChange('isCollective')} />
						</Field>
					)}
					{!isOwner && (
						<div className='send-message'>
							<Button variant='primary' onClick={this.toggleMessagePopup(true)}>
								{t('request.sendMessageToOwner')}
							</Button>
						</div>
					)}
				</div>
				<div className='bottom'>
					<Field label={t('name')} property='name' isRequired>
						<Input onChange={onChange('name')} />
					</Field>
					<Field label={t('request.author')} relation='owner' computed={fio} disabled />
					<Field label={t('request.customer')} relation='organization' property='name'>
						<RelationSelect isRequired onChange={onChange('organizationId')} />
					</Field>
					<Field label={t('request.contractor')} relation='contractor' property='name'>
						<RelationSelect isRequired onChange={onChange('contractorId')} />
					</Field>
					<Field label={t('request.river')} relation='river' property='name'>
						<RelationSelect onChange={onChange('riverId')} />
					</Field>
				</div>
			</div>
		);
	}
}
