import React from 'react';
import t from 'i18n';
import './style.scss';
import PlanStore from './store';
import { observer, Provider } from 'mobx-react';
import { MainInfo } from './main-info';
import { Form, Tab, Tabs } from '@smartplatform/ui';
import { Log } from './log';
import { DeleteButton } from 'components';
import { Breadcrumbs, Actionbar, ExportButton } from 'components';
import store from 'client/store';
import { Agreement } from './agreement';
import { ChangedPopup } from './ChangedPopup';
import { Map } from '@smartplatform/map/client';
import { Resources } from './resources';
import { CountyMunicipalities } from './county-municipalities';
import ExportPopup from './export';

@observer
export class Plan extends React.Component {
	constructor(props) {
		super(props);
		this.store = new PlanStore();
		const { url, path } = props.match;
		this.store.path = props.match.url;
		this.store.returnToPath = props.path;
		this.getData();
	}

	getData = () => {
		this.store.id = parseInt(this.props.match.params.id);
		this.store.init();
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.store.path = this.props.match.url;
			this.getData();
		}
	}

	onLayerToggle = (layers) => {
		store.local.emergency.planDynamicLayers = layers;
		store.local.save();
	};

	render() {
		const {
			isLoading,
			record,
			onSave,
			onMapInit,
			onClean,
			back,
			disableEdit,
			onLeave,
			path,
			returnToPath,
			isChanged,
			showChangedPopup,
			exportPopup,
			isOperatorChs,
		} = this.store;

		if (isLoading) return null;

		const { id } = record;
		const title = id ? `${t('emergencySituationPlan.title')} №${id}` : t('emergencySituationPlan.create');
		const rootTitle = t('emergencySituationPlan.plural');
		const isRequiredNotFilled = !!['statusId', 'date'].find((prop) => !record[prop]);
		const disableSave = disableEdit || isRequiredNotFilled || (id && !isChanged);
		const tabsWithControls = [path, `${returnToPath}/create`];

		const controls = tabsWithControls.includes(store.route.path) && (
			<Actionbar>
				<Actionbar.SaveButton disabled={disableSave || disableEdit} />
				<Actionbar.CancelButton back={back} />
				{!this.store.record.id && <Actionbar.CleanButton onClean={onClean} />}
			</Actionbar>
		);

		const form = (
			<Form disabled={disableEdit} record={record} controls={controls} stay onSave={onSave} onDelete={back} noDelete noSave>
				<MainInfo />
				{showChangedPopup && <ChangedPopup />}
			</Form>
		);

		let tabs = [{ title: t('mainInfo'), exact: true, path, render: () => form }];

		if (id) {
			const notIsNewTabs = [
				{ title: t('emergencySituationPlanResource.title_short'), path: `${path}/resources`, render: (props) => <Resources {...props} /> },
				{ title: t('agreement'), path: `${path}/agreement`, render: () => <Agreement /> },
				{ title: t('historyLog'), path: `${path}/log`, render: () => <Log /> },
			];

			tabs = [...tabs, ...notIsNewTabs];

			if (record.countyId) {
				tabs.push({ title: t('municipality.plural'), path: `${path}/municipalities`, render: (props) => <CountyMunicipalities {...props} /> });
			}
		}

		const visibleLayers = store.local.emergency.planDynamicLayers;

		return (
			<Provider store={this.store}>
				<div className='emergency-plan'>
					<div className='left'>
						<div className='header'>
							<div className='title'>
								<Breadcrumbs crumbs={[{ path: returnToPath, title: rootTitle }, { title }]} />
								<h2>{title}</h2>
							</div>
							<div className='controls'>
								<ExportButton onClick={this.store.showExportPopup} disabled={!record.county} />
								{!disableEdit && !isOperatorChs && (
									<DeleteButton text={t('emergencySituationPlan.delete')} onConfirm={this.store.onDelete} disabled={disableEdit} />
								)}
							</div>
						</div>

						<Tabs>
							{tabs.map((tab, index) => (
								<Tab {...tab} key={index} onLeave={onLeave} />
							))}
						</Tabs>
					</div>
					<div className='right'>
						<Map
							onInit={onMapInit}
							params={{ center: [129.77809779346003, 61.96721553537944], zoom: 5 }}
							className='full-map'
							modelStore={store.model}
							visibleLayers={visibleLayers}
							onLayerToggle={this.onLayerToggle}
						/>
					</div>
					{exportPopup && <ExportPopup store={this.store} />}
				</div>
			</Provider>
		);
	}
}
