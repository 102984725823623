import React from 'react';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { asArray } from 'ol/color';

import { ColorUtils } from '@smartplatform/ui';
import { geoJSON, drawGeoMarker } from '@smartplatform/map/client';
import { modelsConfig } from '.';
import store from 'client/store';

export const colors = {
	instensifies: '#a00',
	continues: '#EB5757',
	weakens: '#d60',
	notspreading: '#fc0',
	localized: '#aa0',
	resumed: '#a60',
	extinguished: '#0a0',
	abandoned: '#0af',
};

export default class ModelLayer {

	models = {};

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(10);
		this.mapStore.addLayer(this.layer);
		this.load();
	};

	load = async (forceUpdate = false) => {
		const modelsStatuses = this.settings;
		if (this.settings.show) {
			delete this.settings.show;
			store.local.save();
		}

		if (this.layer) this.layer.getSource().clear();

		const promises = [];
		for (const modelName in modelsStatuses) {
			const config = modelsConfig[modelName];
			console.log('?', modelName, config);
			const { filter } = config;
			const { where, ...rest } = filter || {};
			const _where = { geo: { neq: null }, ...where };
			const _rest = { fields: ['id', 'geo'], ...rest };
			if (config.pointGeoField) _rest.fields.push(config.pointGeoField);
			if (!!store.model[modelName] && modelsStatuses[modelName].show) {
				if (this.models[modelName] && !forceUpdate) {
					const promise = await this.models[modelName];
					promises.push(promise);
				} else {
					const promise = store.model[modelName].find({
						where: _where,
						..._rest,
					});
					promises.push(promise);
				}
			}
		}

		const modelsRecords = await Promise.all(promises);
		// console.log('modelsRecords', modelsRecords);

		for (let records of modelsRecords) {
			const features = [];

			if (records.length > 0) {
				const modelName = records[0].MODEL.INFO.name;
				const modelConfig = modelsConfig[modelName];

				if (!this.models[modelName]) this.models[modelName] = records;

				// TODO: iconFunc - функция, принимающая в аргументе запись?
				let { style, marker: markerIcon, markerGeo: markerIconGeo } = modelConfig;

				// console.log('model', modelName, records.length, modelConfig);

				for (let record of records) {
					if (!style) {
						const { background } = ColorUtils.getColorForString(modelName);
						const { r, g, b } = ColorUtils.hexToRgb(background);
						style = new Style({
							fill: new Fill({ color: asArray([r, g, b, 0.5]) }),
							stroke: new Stroke({ color: background, width: 1 }),
						});
					}

					const data = {
						title: modelConfig.title,
						record,
						render: modelConfig.renderPopup,
					};

					if (modelConfig.pointGeoField && record.geo.type !== 'Point' && record.geo.type !== 'MultiPoint') {
						const geo = drawGeoMarker(record.geo, {
							data,
							style,
							onlyGeo: true,
						});
						features.push(...geo);

						const marker = drawGeoMarker(record[modelConfig.pointGeoField], {
							data,
							style,
							icon: markerIconGeo || markerIcon,
							onlyMarker: true,
						});
						features.push(...marker);
					}
					else {
						const marker = drawGeoMarker(record.geo, {
							data,
							style,
						});

						features.push(...marker);
					}
				}
				this.layer.getSource().addFeatures(features);
			}
		}
	};

	update = async (modelName) => {
		const config = modelsConfig[modelName];
		const { filter } = config;
		const { where, ...rest } = filter || {};
		const _where = { geo: { neq: null }, ...where };
		const _rest = { fields: ['id', 'geo'], ...rest };
		await store.model[modelName].find({
			where: _where,
			..._rest,
		});
	};
}
