import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { DatePicker, Popup } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import { Controls } from 'components';

@observer
export class CopyPopup extends React.Component {
	@observable date = new Date();

	onDateChange = (date) => (this.date = date);
	onCopy = async () => {
		const { onClose, record } = this.props;
		await store.model.ForceAvailability.copyRecordRelationsValues({ id: record.id, date: this.date });
		onClose();
	};

	render() {
		const { onClose } = this.props;

		return (
			<Popup className='force-availability-copy-popup fires-popup' onClose={onClose} width={350}>
				<h2>{t('copyRecord')}</h2>
				<div className='form-field'>
					<label>{t('forceAvailability.newDate')}</label>
					<DatePicker showClearButton={false} value={this.date} onChange={this.onDateChange} />
					<div className='mt-2'>
						<strong>{t('forceAvailability.copyWarning')}</strong>
					</div>
				</div>
				<Controls onSave={this.onCopy} onCancel={onClose} saveTitle={t('accept')}>
					<Controls.SaveButton />
					<Controls.CancelButton />
				</Controls>
			</Popup>
		);
	}
}
