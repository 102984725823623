import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Form, Field, ColorPicker } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Edit extends React.Component {

	@observable record = null;
	@observable error = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.error = null;
		this.id = parseInt(this.props.match.params.id);
		if (this.id) {
			try {
				this.record = await store.model.AppealStatus.findById(this.id);
			}
			catch (e) {
				this.error = e.message;
			}
		}
		else {
			this.record = new store.model.AppealStatus();
		}
	};

	back = () => {
		store.route.push({ path: '/appeals/dictionaries/statuses' });
	};

	render() {
		if (this.error) return <div className="error">{this.error}</div>;
		if (!this.record) return null;

		return <Form record={this.record} onSave={this.back} onDelete={this.back} stay>
			<Field property="name" label={t('name')} />
			<Field property="color" label={t('color')}><ColorPicker /></Field>
			<Field property="priority" label={t('priority')}/>
			<Field property="description" label={t('description')}><textarea rows={3} /></Field>
			<Field property="default" label={t('appealStatus.default')}/>
			<Field property="closing" label={t('appealStatus.closing')}/>
		</Form>;
	}

}
