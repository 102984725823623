import React from 'react';

export const RequiredLabel = ({ label }) => (
	<label>
		{label}
		<span className='is-required'>
			<strong>*</strong>
		</span>
	</label>
);
