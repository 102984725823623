import { inject, observer } from 'mobx-react';
import React from 'react';
import { Input, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import { DeleteButton } from 'components';
import t from 'i18n';
import { fio } from 'client/tools';
import { LabelField } from 'delivery/components';

@inject('store')
@observer
export class Member extends React.Component {
	onUserChange = (user) => {
		this.props.member.user = user;
		this.props.onMemberChange(this.props.member);
	};
	render() {
		const { member, onRemoveMember, memberIds } = this.props;
		const { enableForCustomer, enableForContractor } = this.props.store;

		return (
			<div className='member'>
				<LabelField
					label={t('request.selectMember')}
					children={
						<RecordSelect
							model={store.model.User}
							computed={fio}
							onChange={this.onUserChange}
							value={member.user}
							filter={{
								where: {
									id: memberIds.length ? { nin: memberIds } : undefined,
									organizationId: this.organization?.id,
								},
								include: ['organization'],
							}}
							isRequired
							disabled={enableForCustomer && enableForContractor}
						/>
					}
				/>
				<LabelField label={t('user.position')} children={<Input disabled value={member?.user?.position} />} />
				<LabelField
					label={t('organization.title')}
					children={<RecordSelect model={store.model.Organization} disabled property='name' value={member.user?.organization} />}
				/>
				{(!enableForCustomer || !enableForContractor) && <DeleteButton onConfirm={onRemoveMember(member)} />}
			</div>
		);
	}
}
