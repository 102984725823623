import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export default class UIStore {
	@observable notifications = [];
	@observable topBar = true;
	@observable sideBar = true;

	topBarHeight = 80;
	sideBarWidth = 90;
	sideBarBtnSize = 80;

	setNotification = ({ message, type, onClick, sourceCode }) => {
		this.notifications.push({
			onClick,
			message,
			type,
			id: uuidv4(),
			sourceCode,
		});
	};
}
