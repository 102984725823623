import React from 'react';
import { relative } from 'client/tools';
import { Avatar } from '@smartplatform/ui';

export const CommentHeader = ({ comment, subject, action }) => {
	return (
		<div className='comment-header'>
			<Avatar user={comment.owner} size={32} className='avatar' />
			<div>
				<span className='subject'> {subject} </span>
				<span className='action'>{action} </span>
				<span className='date'>{relative(comment.createdAt)}</span>
			</div>
		</div>
	);
};
