import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Field, Popup, DatePicker, RelationSelect, RecordSelect, Tabs, Tab } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n/index';
import Jobs from './jobs';
import Pilots from './pilots';
import RaidEditStore from './store';
import { Form } from 'components';
@inject('store')
@observer
export class EditRaidPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable isNew;
	@observable aircraft = null;
	@observable activeTabIndex = 0;

	constructor(props) {
		super(props);
		this.store = new RaidEditStore();
		this.store.init(this.props.store.selectedRecord);
	}

	onSave = async () => {
		await Promise.all([this.store.saveRelations(), this.store.deleteRelations()]);
		this.props.store.onClosePopup();
	};

	onClose = async () => {
		await this.store.deleteNotSavedRelations();
		this.props.store.onClosePopup();
	};

	render() {
		if (this.props.store.isLoading) return null;
		const title = this.props.record ? t('editRecord') : t('addRecord');
		const tabsArray = [
			<Jobs raid={this.store.raid} store={this.store} calcArrivalDate={this.store.calcArrivalDate} />,
			<Pilots raid={this.store.raid} store={this.store} />,
		];

		const disabled = this.props.store.disabled;

		return (
			<Popup className='raid-popup fires-popup' onClose={this.onClose} width={1400}>
				<Form
					disabled={disabled}
					noDelete={this.store.isNew}
					record={this.store.raid}
					onSave={this.onSave}
					beforeSave={this.store.beforeSave}
					onDelete={this.props.store.onClosePopup}
					stay
					onCancel={this.onClose}
					className='raid-form'
				>
					<div className='form-wrapper'>
						<div className='left'>
							<h2>{title}</h2>
							<div className='section-title'>{t('fire.mainInfo')}</div>
							<div className='form-field'>
								<label>{t('aircraft.title')}</label>
								<RecordSelect
									noTotal
									maxItems={10}
									itemsPerPage={1000}
									disabled={disabled}
									model={store.model.ViewAircraft}
									filter={{ where: { active: true } }}
									searchFields={['aircraftName', 'aircraftNumber', 'contractNumber']}
									computed={this.store.renderAircraft}
									value={this.store.aircraft}
									onChange={this.store.onAircraftChange}
								/>
							</div>
							<Field property='requestNumber' label={t('airRaid.requestNumber')} />
							<Field relation='aviaDep' property='name' label={t('aviaDep.title')}>
								<RelationSelect noTotal maxItems={10} itemsPerPage={1000} />
							</Field>
							<Field relation='paymentGroup' property='name' label={t('paymentGroup.title')} />
							<Field property='requestDate' label={t('airRaid.requestDate')}>
								<DatePicker showTimeSelect showClearButton={false} />
							</Field>
							<Field property='departureDate' label={t('airRaid.departureDate')}>
								<DatePicker onChange={this.store.calcArrivalDate} showTimeSelect showClearButton={false} />
							</Field>
							<Field property='arrivalDate' disabled label={t('airRaid.arrivalDate')}>
								<DatePicker showTimeSelect showClearButton={false} />
							</Field>
						</div>

						<div className='right'>
							<Tabs type='manual'>
								{['airJob.plural', 'observerPilot.plural'].map((title, index) => (
									<Tab title={t(title)} key={index} onClick={() => this.store.setTab(index)} />
								))}
							</Tabs>
							<div className='tab-content' id={this.store.activeTabIndex}>
								{tabsArray[this.store.activeTabIndex]}
							</div>
						</div>
					</div>
				</Form>
			</Popup>
		);
	}
}
