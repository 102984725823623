import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import TrackingIcon from '!!svg-react-loader!img/icons/delivery/search.svg';
import { Card } from 'delivery/components';
import { observable } from 'mobx';
import { Loader } from '@smartplatform/ui';
import { formatDate } from 'client/tools';
import { renderStatus } from 'client/tools';
import './style.scss';

@inject('store')
@observer
export class CardUpdate extends React.Component {
	@observable items = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.record = this.props.store.record;
		this.init();
	}

	init = async () => {
		const logs = this.record.logs();

		let items = [];
		for (const record of logs) {
			const { updated } = record;
			if (!updated) {
				continue;
			}
			if (record.track?.status && updated.includes('addTrack')) {
				record.action = { title: t('history.changeTrackStatus'), value: record.trackId ? renderStatus(record.track?.status) : '' };
				items.push(record);
			}
		}

		this.items = items.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
		this.isLoading = false;
	};

	render() {
		let content;
		if (this.isLoading) {
			content = <Loader />;
		} else {
			content = this.items.map((record) => (
				<div className='update-item' key={record.id}>
					<span className='action'>
						<span className='title'>{record.action.title} </span>
						{record.action.value && <span className='value'>{record.action.value}</span>}
					</span>
					<span className='date'>{formatDate(record.createdAt, 'dd.MM.yyyy HH:mm')}</span>
				</div>
			));
		}
		return (
			<Card icon={<TrackingIcon />} title={t('trackingProduction.update')} className='card-update'>
				{content}
			</Card>
		);
	}
}
