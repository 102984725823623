import { observable } from 'mobx';
import store from 'client/store';
import { MODEL_NAME, RESERVE_INCLUDE, RESERVES_PATH } from '../constants';

export default class ReserveStore {
	@observable record = null;
	@observable movements = [];
	@observable isLoading = true;
	categoryGroups = [];
	id;

	constructor() {}
	get isNew() {
		return !this.id;
	}

	init = async () => {
		await this.getCategoryGroups();
		await this.fetchRecord();
	};
	getCategoryGroups = async () => (this.categoryGroups = await store.model.SupplyCategory.find({ where: { parentId: null }, include: ['children'] }));
	fetchRecord = async () => {
		this.isLoading = true;
		if (this.id) {
			this.record = await store.model[MODEL_NAME].findById(this.id, RESERVE_INCLUDE);
			this.movements = this.record.movements();
		} else this.createNewRecord();
		this.isLoading = false;
	};
	onChange = (prop) => (value) => {
		this.record[prop] = value;
		this.record.nomenclature = null;
	};
	createNewRecord = () => (this.record = new store.model[MODEL_NAME]({ date: new Date(), startQuantity: 0 }));

	onSave = async () => {
		let path = RESERVES_PATH;
		if (this.isNew) {
			this.id = this.record.id;
			path += `/${this.id}`;
			this.fetchRecord();
		}
		const promises = [];
		for (const move of this.movements) {
			promises.push(move.save());
		}
		await Promise.all(promises);
		store.route.push({ path });
	};

	back = () => store.route.push({ path: RESERVES_PATH });
	onClean = () => {};

	onDelete = async () => {
		await this.record.delete();
		this.back();
	};
}
