import { renderStatus } from 'client/tools/renders';
import t from 'i18n';

export const DICTIONARIES_MODELS = [
	{
		title: t('municipality.plural'),
		type: 'submenu',
		items: [
			{
				modelName: 'City',
				listProperties: ['name', 'population', 'createdAt', 'updatedAt'],
				listRelations: [
					{ relation: 'county', property: 'name' },
					{ relation: 'municipality', property: 'name' },
					{ relation: 'sufficiencyStatus', computed: renderStatus, properties: ['color', 'name'] },
				],
				editProperties: ['name', 'population', 'geo', 'importantObjects', 'participatingEnterprises', 'createdAt', 'updatedAt'],
				editRelations: [
					{ relation: 'municipality', property: 'name' },
					{ relation: 'county', property: 'name' },
					{ relation: 'risks', properties: ['peopleCount', 'teenagersCount'], include: [{ relation: 'category', property: 'name' }] },
					{ relation: 'sufficiencyStatus', computed: renderStatus, properties: ['color', 'name'] },
				],
			},
			{
				modelName: 'County',
				listProperties: ['name', 'population'],
				editProperties: ['name', 'population', 'geo'],
				disabled: true,
			},
		],
	},
	{
		title: t('statuses'),
		type: 'submenu',
		items: [
			{ modelName: 'MunicipalSufficiencyStatus' },
			{ modelName: 'MunicipalityStatus' },
			{ modelName: 'EmergencySituationPlanStatus' },
			{ modelName: 'EmergencySituationPlanMemberStatus' },
			{
				modelName: 'EmergencySituationPlanVehicleType',
				listProperties: ['name'],
				editProperties: ['name'],
			},
		],
	},
	{
		title: t('emergencyPlan'),
		type: 'submenu',
		items: [
			{ modelName: 'RegionLevel' },
			{ modelName: 'EmergencySituationPlanMemberRole' },
			{ modelName: 'EmergencySituationRiskCategory' },
			{ modelName: 'EmergencySituationPlanResourceTier' },
			{ modelName: 'EmergencyDivisionGroup' },
			{
				modelName: 'EmergencyDivision',
				listRelations: [{ relation: 'group', property: 'name' }],
			},
			{
				modelName: 'WaterTransportType',
				listProperties: ['name'],
				editProperties: ['name'],
			},
		],
	},
	{
		title: t('dictionaries.group.layersData'),
		type: 'submenu',
		items: [
			{
				modelName: 'MapLayerHelipad',
				listProperties: ['nas_punkt', 'population'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerRoad',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'RoadObject',
				listProperties: ['name'],
				editProperties: ['name', 'geo'],
			},
			{
				modelName: 'MapLayerRailway',
				listProperties: ['name', 'type'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'Airfield',
				listProperties: ['name'],
				editProperties: ['name', 'geo'],
			},
			{
				modelName: 'Aircraft',
				listProperties: ['number'],
				editProperties: ['number'],
				editRelations: [
					{ relation: 'type', property: 'name' },
					{ relation: 'contract', property: 'number' },
				],
			},
			{
				modelName: 'MapLayerPier',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerHydraulicStructure',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerGasPipeline',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerOilPipeline',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerRASCO', // есть только на проде
				listProperties: ['settlement', 'address', 'building', 'type'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
		],
	},
];

/*
Добавить в ЧС

+ Районы/улусы, населенные пункты
+ Службы
+ Вертолетные площадки
+ Автомобильные дороги
- Объекты на автомобильных дорогах ?
- Техника ?
+ Железные дороги
- Воздушные судна
- Типы судов
- Причалы
- Гидротехнические сооружения - нет объектов
- Магистральные газопроводы
- Магистральные нефтепроводы
- Система оповещения
*/
