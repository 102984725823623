import qs from 'qs';
import { createBrowserHistory } from 'history';
import { observable, toJS } from 'mobx';
import i18next from 'i18next';
import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import CovidStore from './modules/covid/store';
import SearchStore from './components/search/store';
import UIStore from './uiStore';
import { getCurrentModule } from 'client/tools';
// import defaultMapConfig from 'components/map/localSettings';
import { io } from 'socket.io-client';
import { ALARM } from './constants';
import t from 'i18n';

/**
 * Глобальный контейнер сторов
 */
export class AppStore {
	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	config; // Конфигурация региона

	subscribers = [];
	reconnectionDelay = 10000;

	fiasApiKey = '842709bce84734ee40403e8ce6ef9bb80e722fa5';

	@observable module = null;
	@observable isPublic = false;
	@observable appKey = 0; // key для обновления приложения

	constructor() {
		this.history = createBrowserHistory();
		this.ui = new UIStore();

		this.module = getCurrentModule();
		this.socket = new io({
			reconnectionDelay: this.reconnectionDelay,
			reconnectionDelayMax: this.reconnectionDelay,
		});
		this.local = new LocalStore();
		this.local.extend({
			covid: {
				clusterMode: false,
			},
			contragents: {},
			emergencySituationPlans: {},
			municipalities: {},
			language: 'ru',
		});
		// this.local.mapTools = defaultsDeep(toJS(this.local.mapTools || {}), defaultMapConfig);
		this.local.save();

		this.rest = new Rest(document.location.hostname === 'stopcovid19.sakha.gov.ru' ? '/covid19/api/' : undefined);
		// this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
			onLogin: this.onLogin,
		});

		this.covid = new CovidStore();
		this.search = new SearchStore();
		// this.map = new MapStore(this);

		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});
	}

	setReconnectionDelay = (value) => {
		this.reconnectionDelay = value;
		this.socket.disconnect();
		this.socket = new io({
			reconnectionDelay: this.reconnectionDelay,
			reconnectionDelayMax: this.reconnectionDelay,
		});
		this.socket.connect();
	};

	setSocketParams = (params = {}) => {
		this.socket.disconnect();
		this.socket = new io(params);
		this.socket.connect();
	};

	onLogin = (user) => this.socket.disconnect().connect();

	init = async (options) => {
		i18next.changeLanguage(this.local.language || 'ru');
		this.config = APP_CONFIG;
		const { isPublic } = options || {};
		this.isPublic = isPublic;
		if (isPublic) {
			this.ui.topBarHeight = 0;
			this.ui.sideBarWidth = 0;
		}

		await this.model.connect();
		// await this.covid.init();

		// если подписок будет много, то надо бы унести в отдельный файл стора
		this.socket.on('FireAlarmMessage.id', ({ id, sourceCode, fireId }) => {
			const map = {
				ADPI: { message: t('fireAlarmMessage.newAdpiAlarm') },
				112: { message: t('fireAlarmMessage.new112Alarm') },
			};
			const onClick = () => this.route.push({ path: `/techfires/fires/create`, params: { notifier: id } });
			const { message } = map[sourceCode];
			this.ui.setNotification({ message, type: ALARM, onClick, sourceCode });
		});

		this.socket.on('TechFireWayBill.fireDepId', ({ fireId, wayBillId, recordId }) => {
			const message = t('fireAlarmMessage.call');
			const onClick = async () => {
				await this.model.TechFireWayBillFireDep.patchOrCreate({
					id: recordId,
					acceptDate: new Date(),
					acceptUserId: this.model.user.id,
				});
				this.route.push({ path: `/techfires/fires/${fireId}/waybills/${wayBillId}` });
			};
			this.ui.setNotification({ message, type: ALARM, onClick });
		});
	};

	subscribeOnHMR = (callback) => {
		this.subscribers.push(callback);
	};

	unsubscribeOnHMR = (callback) => {
		const index = this.subscribers.findIndex((cb) => cb === callback);
		if (index !== -1) this.subscribers.splice(index, 1);
	};

	fireHMR = () => {
		console.log('HMR!');
		this.subscribers.forEach((cb) => cb());
	};
}

window.APP_STORE = new AppStore();

export default window.APP_STORE;
