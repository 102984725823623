import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import FireDep from './FireDep';

@observer
export default class Vehicles extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		const { fireDeps } = this.props.store;
		
		return <div>
			{Object.keys(fireDeps).map(fireDepId => (
				<FireDep key={fireDepId} store={this.props.store} fireDepObj={fireDeps[fireDepId]} />
			))}
		</div>;
	}
}
