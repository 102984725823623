import React from 'react';
import { observer } from 'mobx-react';
import { Toolbar } from './toolbar';
import ContragentsStore from './store';
import { Column, Pager, Table } from '@smartplatform/ui';
import { MODEL_NAME } from './constants';
import store from 'client/store';
import t from 'i18n';
import { getPerPage } from 'client/tools';
@observer
export class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ContragentsStore();
		this.store.path = props.path;
	}
	componentDidMount() {
		this.store.perPage = getPerPage();
	}

	render() {
		const { onQueryUpdate, onChange, totalCount, page, query, onRowClick, perPage } = this.store;
		const model = perPage ? store.model[MODEL_NAME] : undefined;
		let tableProps = model ? { model } : { rows: [] };
		return (
			<div className='model-list'>
				<h2>{t('contragent.title')}</h2>
				<Toolbar store={this.store} />
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table query={query} onQueryUpdate={onQueryUpdate} key={model} {...tableProps} onRowClick={onRowClick}>
						<Column property='name' label={t('name')} />
						<Column property='headName' label={t('organization.headName')} />
						<Column property='headPosition' label={t('organization.headPosition')} />
						<Column property='phone' label={t('phone')} />
						<Column property='email' label={t('email.title')} />
						<Column property='comment' label={t('organization.comment')} />
					</Table>
				</div>
			</div>
		);
	}
}
