import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import './style.scss';

export default () => <div className="firedep-personnel">
	<Switch>
		<Route path="/techfires/firedep/:fireDepId/personnel" exact render={routeProps => <List {...routeProps} />} />
		<Route path="/techfires/firedep/:fireDepId/personnel/create" render={routeProps => <Edit {...routeProps} />} />
		<Route path="/techfires/firedep/:fireDepId/personnel/:id" render={routeProps => <Edit {...routeProps} />} />
	</Switch>
</div>;
