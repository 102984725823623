import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

@observer
export default class OrganizationsTree extends React.Component {
	@observable showTreeValue = [];

	constructor(props) {
		super(props);
	}

	onTitleClick = (record) => () => {
		this.props.onSelect(record);

		if (this.showTreeValue.includes(record.id)) {
			const index = this.showTreeValue.findIndex((el) => el === record.id);
			this.showTreeValue.splice(index, 1);
		} else {
			this.showTreeValue.push(record.id);
		}
	};

	render() {
		const { allRecords, level = 0, parentId = null, selectedId, onSelect } = this.props;

		const records = allRecords.filter((record) => record.parentId === parentId);

		return (
			<div className={classNames('org-tree')}>
				{records.map((record) => {
					const count = record.fireDepartments().length;
					return (
						<React.Fragment key={record.id}>
							<div
								className={classNames('title', { selected: selectedId === record.id, 'hide-org-tree': this.showTreeValue.includes(record.id) })}
								onClick={this.onTitleClick(record)}
							>
								{[...new Array(level)].map((v, i) => (
									<span key={i} className='pad' />
								))}
								<div className='icon-title'>
									{!!record.children().length && <FontAwesomeIcon icon={faChevronUp} />}
									{record.name}
								</div>
								{count > 0 && <span className='count'>[{record.fireDepartments().length}]</span>}
							</div>
							{!this.showTreeValue.includes(record.id) && <OrganizationsTree {...this.props} parentId={record.id} level={level + 1} />}
						</React.Fragment>
					);
				})}
			</div>
		);
	}
}
