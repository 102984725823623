import React from 'react';
import { observer } from 'mobx-react';

import { FilterLayout } from '..';
import t from 'i18n';

@observer
export default class DzzFilter extends React.Component {

	onToggle = () => {
		this.props.layer.setVisible();
	};

	render() {
		return <FilterLayout layer={this.props.layer} title={t('dzz.title')} />;
	}

}
