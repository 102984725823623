import React from 'react';
import { observer } from 'mobx-react';

import { Panel } from 'components';
import store from 'client/store';
import './style.scss';

@observer
export default class ModeSwitch extends React.Component {

	switchRenderMode = e => {
		e.preventDefault();
		store.local.covid.clusterMode = !store.local.covid.clusterMode;
	};

	render() {
		return <Panel left={315} bottom={0} height={25} width={120} top="auto" wrapperClassName="map-mode-switch" scrollable={false}>
			кластеры:&nbsp;
			<a href="#" className={store.local.covid.clusterMode ? 'active' : ''} onClick={this.switchRenderMode}>
				{store.local.clusterMode ? 'вкл' : 'выкл'}
			</a>
		</Panel>;
	}

}
