import { azimuth, distance, area, edge } from 'fires/helpers';
import React from 'react';
import t from 'i18n';

const renderStatus = (record) => (record ? t('fireStatus.codes.' + record.statusCode) : '-');
const renderName = (replace, prop) => (record) => {
	if (replace && record && typeof record[prop] === 'string') return record[prop].replace(replace, '');
	return name;
};

const _COLUMNS = {
	//* numbers
	fireNumber: { property: 'fireNumber', label: 'fire.fireNumber' },
	forestryFireNumber: { property: 'forestryFireNumber', label: 'fire.forestryFireNumber' },
	aviaDepFireNumber: { property: 'aviaDepFireNumber', label: 'fire.aviaDepFireNumber' },
	bigFireNumber: { property: 'bigFireNumber', label: 'fire.bigFireNumber' },
	allotmentNumber: { property: 'allotmentNumber', label: 'fire.allotmentNumber' },
	//* даты
	updatedAt: { property: 'updatedAt', label: 'fire.lastEditDate' },
	reportDate: { property: 'reportDate', label: 'fire.reportDate' },
	detectDate: { property: 'detectDate', label: 'fire.detectDate', noFilter: true },
	startDate: { property: 'startDate', label: 'fire.startDate' },
	extinguishedDate: { property: 'extinguishedDate', label: 'fire.extinguishedDate' },
	localizationDate: { property: 'localizationDate', label: 'fire.localizationDate' },
	//* isdm
	KPOdetected: { property: 'KPOdetected', label: 'fire.KPOdetected' },
	KPOliquidation: { property: 'KPOliquidation', label: 'fire.KPOliquidation' },
	//* aviaDep
	aviaDepName: { property: 'aviaDepName', computed: renderName(/Авиаотделение/gi, 'aviaDepName'), label: 'aviaDep.title' },
	aviaDepAzimuth: { property: 'aviaDepAzimuth', computed: (r) => azimuth(r.aviaDepAzimuth), label: 'fire.aviaDepAzimuth' },
	aviaDepDistance: { property: 'aviaDepDistance', computed: (r) => distance(r.aviaDepDistance), label: 'fire.aviaDepDistance' },
	//* airfield
	airfieldName: { property: 'airfieldName', computed: renderName(/Аэропорт/gi, 'airfieldName'), label: 'airfield.title' },
	airfieldAzimuth: { property: 'airfieldAzimuth', computed: (r) => azimuth(r.airfieldAzimuth), label: 'fire.airfieldAzimuth' },
	airfieldDistance: { property: 'airfieldDistance', computed: (r) => distance(r.airfieldDistance), label: 'fire.airfieldDistance' },
	//* city
	cityName: { property: 'cityName', label: 'city.title' },
	cityAzimuth: { property: 'cityAzimuth', computed: (r) => azimuth(r.cityAzimuth), label: 'fire.cityAzimuth' },
	cityDistance: { property: 'cityDistance', computed: (r) => distance(r.cityDistance), label: 'fire.cityDistance', preFilter: (v) => v * 1000 },
	threat: { property: 'threat', label: 'fire.threat' },
	//*main
	statusCode: { property: 'statusCode', computed: renderStatus, label: 'fireStatus.title', width: 240, noFilter: true },
	causeShortName: { property: 'causeShortName', label: 'fireCause.title' },
	forestryName: { property: 'forestryName', label: 'forestry.title' },
	zoneShortName: { property: 'zoneShortName', label: 'monitoringZone.title' },
	forestryPrecinctName: { property: 'forestryPrecinctName', label: 'forestryPrecinct.title' },
	ownershipName: { property: 'ownershipName', label: 'forestOwnership.title' },
	area: { property: 'area', computed: (r) => area(r.area), label: 'fire.area' },
	edge: { property: 'edge', computed: (r) => edge(r.edge), label: 'fire.edge' },
	textGeo: { property: 'textGeo', label: 'fire.coordinates', noFilter: true, noSort: true },
	landCategoryName: { property: 'landCategoryName', label: 'landCategory.title' },
	countyName: { property: 'countyName', label: 'county.title' },
	tenantName: { property: 'tenantName', label: 'tenant.title' },
};
Object.keys(_COLUMNS).forEach((key) => (_COLUMNS[key].width = _COLUMNS[key].width || 200));
export const COLUMNS = _COLUMNS;
