import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form, Popup, RecordSelect, FormContext, Row, Input } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { fio } from 'client/tools';
import { Actionbar } from 'components';
import { observable } from 'mobx';

@inject('store')
@observer
export class MemberEditPopup extends React.Component {
	@observable record = null;
	@observable role = null;
	@observable user = null;
	@observable position = null;
	@observable organization = null;
	static contextType = FormContext;
	recIndex;

	constructor(props) {
		super(props);
		this.record = this.props.record;
		this.isNew = !this.record.id;

		this.MODEL = this.record.MODEL;
		this.store = this.props.store;
		this.init();
	}
	init = async () => {
		if (!this.isNew) {
			this.recIndex = this.props.members.findIndex(({ id }) => id === this.record.id);
			this.record = this.props.members[this.recIndex];
			this.role = this.record.role;
			this.user = this.record.user;
			this.position = this.record.position;
			this.organization = this.record.organization;
		}
	};

	onRoleChange = (role) => (this.role = role);
	onUserChange = (user) => {
		this.user = user;
		this.position = user?.position || null;
		this.organization = user?.organization || null;
	};

	onSave = async () => {
		this.record.role = this.role;
		this.record.organization = this.organization;
		this.record.user = new store.model.User(this.user);
		this.record.position = this.position;
		if (this.isNew) {
			this.props.members.push(this.record);
		} else {
			this.props.members[this.recIndex] = this.record;
		}

		this.context.form.addHasMany('members', this.record);
		this.store.onChange('members')(this.record);

		this.props.onClose();
	};

	onDelete = async () => {
		this.props.members.splice(this.recIndex, 1);
		this.context.form.removeHasMany('members', this.record);
		if (!this.props.store.changes['members']) this.props.store.changes['members'] = [];
		this.props.store.changes['members'].push(this.record.id);
		this.props.store.isChanged = true;
		this.props.onClose();
	};

	renderUser = (user) => {
		let organization = user.organization?.name;
		if (organization && user.position) organization += ` - ${user.position}`;
		return (
			<span className='render-member'>
				<span>{fio(user)}</span>
				<span className='hint'>{organization}</span>
			</span>
		);
	};

	onClean = () => {
		this.role = null;
		this.user = null;
		this.organization = null;
		this.position = null;
	};

	render() {
		const { onClose } = this.props;
		const title = this.isNew ? t('addRecord') : t('editRecord');
		const disableSave = !this.user || !this.role;
		const memberIds = this.props.store.members.map((i) => i.userId);

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton disabled={disableSave} />
				<Actionbar.CancelButton back={onClose} />
				{this.isNew ? <Actionbar.CleanButton onClean={this.onClean} /> : <Actionbar.DeleteButton onDelete={this.onDelete} noDelete />}
			</Actionbar>
		);

		return (
			<Popup onClose={onClose} className='emergency-popup' width={700}>
				<h2>{title}</h2>
				<Form record={this.record} stay onSave={this.onSave} controls={controls} noDelete noSave>
					<div className='form-field'>
						<label className='is-required'>{t('fullName_full')}</label>
						<RecordSelect
							model={store.model.ViewUser}
							computed={this.renderUser}
							showValue={this.user ? fio(this.user) : <span className='hint'>{t('user.searchFioPositionOrganization')}</span>}
							onChange={this.onUserChange}
							value={this.user}
							filter={{
								where: {
									id: memberIds.length ? { nin: memberIds } : undefined,
								},
								include: ['organization'],
							}}
							isRequired
							className='plan-member-select'
						/>
					</div>

					<div className='form-field'>
						<label className='is-required'>{t('request.agreementRole')}</label>
						<RecordSelect
							model={store.model.EmergencySituationPlanMemberRole}
							property='name'
							onChange={this.onRoleChange}
							value={this.role}
							isRequired
							noSearch
						/>
					</div>
					<Row>
						<div className='form-field'>
							<label>{t('user.position')}</label>
							<Input value={this.position} disabled />
						</div>
						<div className='form-field'>
							<label>{t('organization.title')}</label>
							<RecordSelect model={store.model.Organization} property='name' value={this.organization} disabled />
						</div>
					</Row>
				</Form>
			</Popup>
		);
	}
}
