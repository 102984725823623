import React from 'react';
import Root from './Root';
import store from 'client/store';
import ErrandsIcon from '!!svg-react-loader!img/icons/menu/errands.svg';
import DashboardIcon from '!!svg-react-loader!img/icons/sidebar/shared/dashboard.svg';
import t from 'i18n';

export default {
	name: t('module.errands'),
	code: 'errands',
	icon: <ErrandsIcon />,
	path: '/errands',
	component: Root,
	access: () => store.model.Errand && store.model.Errand.INFO.READ,
	menu: [
		{ title: t('dashboard'), path: '/errands', icon: <DashboardIcon />, exact: true },
		// { title: 'Синхро-низация', path: '/errands/sync', icon: <DashboardIcon />, exact: true },
	],
};
