import { observable, action } from 'mobx';
import { difference } from 'lodash';

import FireCardMap from './map/fireCardMap';
import fireStore from '../fireStore';
import { geoJsonToHex, getMostRepeatedElement } from 'fires/helpers';
import store from 'client/store';
import { path } from '..';
import { exportReport } from 'client/tools';
import { RAID_INCLUDE, FIRE_INCLUDE } from './constants';

export default class FireCardStore {
	//*
	@observable fireLogs = [];
	@observable airJobs = [];

	//*

	@observable fire = null;
	@observable isLoading = true;
	@observable error = null;
	@observable changed = false;
	@observable popup = null;
	@observable counties = [];
	@observable forestryQuarters = [];
	@observable boxColorByGroup = '';

	@observable mapMaximized = false;

	originalValues = {}; // оригинальные значения
	changes = {}; // измененные поля
	map = null; // стор мини-карты
	disabled = !store.model.Fire?.INFO.WRITE;

	constructor() {
		this.map = new FireCardMap(this);
	}

	onMapInit = async (mapStore) => {
		await this.loadFire();
		await this.map.init(mapStore);
		await this.map.initLayers();
		if (!this.fire.id) await this.checkParams();
	};

	getFireCopyValues = (fire) => {
		const {
			id,
			date,
			updated,
			updatedAt,
			isLoading,
			createdAt,
			isDirty,
			isDeleted,
			fireLogId,
			jumpings,
			descents,
			delivered,
			deliveredAvia,
			notLandingReasonId,
			notInspectionReasonId,
			threat,
			...values
		} = fire;
		for (let key in values) {
			if (key.includes('_')) delete values[key];
		}
		if (values.geo) {
			values.geo = geoJsonToHex(values.geo);
		}
		if (values.hqGeo) {
			values.hqGeo = geoJsonToHex(values.hqGeo);
		}
		return values;
	};

	@action loadFire = async () => {
		this.error = null;
		this.isLoading = true;
		this.isNew = !this.id;
		try {
			if (this.isNew) {
				//* создаем лог, а при сохранении создадим пожар и запихнем в лог айди пожара
				//* запись пожара заполнится значениями из динамики из хука FireLog.aftersave
				this.fire = new store.model.FireLog();
				this.fire.detectDate = new Date();
			} else {
				//* если же зашли по :id то значит пожар уже есть, проверим есть ли у него динамики
				this.fireLogs = await store.model.FireLog.find({
					where: { fireId: this.id },
					include: FIRE_INCLUDE,
					order: 'date asc',
				});
				const fires = this.fireLogs.filter((log) => !!log.date).sort((a, b) => new Date(a.date) - new Date(b.date));
				this.fire = fires[fires.length - 1];
				this.airJobs = await store.model.AirJob.find({
					where: { fireId: this.id },
					include: RAID_INCLUDE,
				});
				this.updateForestryQuarters();
			}

			this.getOriginalValues();
		} catch (e) {
			console.log(e);
			this.error = e.message;
		}
		this.isLoading = false;
	};

	setFireLog = (fireLog) => {
		this.fire = fireLog;
		this.getOriginalValues();
		console.log('setFireLog', fireLog.geo);
		this.updateForestryQuarters();
		this.map.setFireLog();
		this.clearChanged();
	};

	dropChanges = async () => {
		this.fire = await store.model.FireLog.findById(this.fire.id, {
			include: FIRE_INCLUDE,
		});
		this.getOriginalValues();
		this.clearChanged();
	};

	clearChanged = () => {
		this.changed = false;
		this.changes = {};
	};

	getOriginalValues = () => {
		const { id, ownerId, createdAt, updatedAt, ...properties } = store.model.FireLog.PROPERTIES;
		Object.keys(properties).forEach((property) => (this.originalValues[property] = this.fire[property] ?? null));
	};

	@action save = async () => {
		const isNew = !this.fire.id;

		if (isNew) {
			//* создаем пожар чтобы получить айди и запихнуть в лог и поменять роут
			const newFire = new store.model.Fire();
			await newFire.save();
			this.id = newFire.id;
			this.fire.fireId = this.id;
			this.fire.date = this.fire.date || new Date();
			const defaultStatus = await store.model.FireStatus.find({ where: { default: true }, fields: ['id'] });
			this.fire.statusId = defaultStatus[0] ? defaultStatus[0].id : null;

			if (this.changes.geo) {
				this.fire.geo = this.changes.geo;
			}
			await this.fire.save();
			await this.saveForestryQuarters();
			this.isLoading = true;
			this.clearChanged();
			store.route.push({ path: `${path}/registry/${this.id}/dynamic` });
			return;
		}
		const payload = { ...this.changes };
		if (payload.geo) {
			payload.geo = geoJsonToHex(payload.geo);
		}
		if (payload.hqGeo) {
			payload.hqGeo = geoJsonToHex(payload.hqGeo);
		}
		console.log('saving... payload:', payload);
		await this.fire.patchAttributes(payload);
		await this.saveForestryQuarters();
		await this.dropChanges();
		fireStore.firesLayer.load();
		this.map.manualMode = false;
	};

	onChange = (property) => (value) => {
		this.fire[property] = value;
		console.log('onChange', property, this.fire[property]);

		let key = property;
		if (store.model.Fire.RELATIONS[property]) {
			key = store.model.Fire.RELATIONS[property].foreignKey;
			value = value ? value.id : null;
		}

		if (this.isEqual(property, value, this.originalValues[key])) {
			delete this.changes[key];
		} else {
			this.changes[key] = value;
		}

		// console.log('changes', Object.keys(this.changes));
		this.changed = Object.keys(this.changes).length > 0;

		this.map.onChange(property, value);
	};

	isEqual = (property, a, b) => {
		// console.log('isEqual', property, a, b);
		if (a === null && b === null) return true; // отсекаем случай с обоими null-ами
		if (a === null || b === null) return false; // отсекаем случай когда хоть один = null

		const _property = store.model.Fire.PROPERTIES[property];

		if (_property) {
			if (_property.type === 'Date') {
				return new Date(a).toISOString() === new Date(b).toISOString();
			}
		}

		return a === b;
	};

	onAviaDepChange = (aviaDep) => {
		this.onChange('aviaDep')(aviaDep);
		this.getAviaDepData();
	};

	getAviaDepData = async () => {
		console.log('getAviaDepData', this.fire.aviaDep);
		if (this.fire.hqGeo && this.fire.aviaDep && this.fire.aviaDep.geo) {
			const { aviaDepAzimuth, aviaDepDistance } = await store.model.Fire.getDataFromGeo(this.fire.hqGeo, this.fire.aviaDep.geo);
			this.onChange('aviaDepAzimuth')(aviaDepAzimuth);
			this.onChange('aviaDepDistance')(aviaDepDistance);
		}
	};

	/**
	 * Обновляет массив текущих кварталов при загрузке и переключении между FireLog-ами пожара
	 */

	updateForestryQuarters = () => {
		this.forestryQuarters = this.fire.forestryQuarters().map((fq) => {
			fq.color = fq.forestCategory ? fq.forestCategory.color : '#888';
			return fq;
		});
		console.log(
			'forestryQuarters ids:',
			this.forestryQuarters.map((fq) => fq.id),
			'codes:',
			this.forestryQuarters.map((fq) => fq.code)
		);
	};

	/**
	 * Обновляет many-to-many реляцию "forestryQuarters" для текущего FireLog из массива текущих кварталов
	 */

	saveForestryQuarters = async () => {
		const fireForestryQuarters = await this.fire.forestryQuarters();
		const oldIds = fireForestryQuarters.map((fq) => fq.id);
		const newIds = this.forestryQuarters.map((fq) => fq.id);
		for (let id of oldIds) {
			await this.fire.forestryQuarters.remove(id);
		}
		for (let id of newIds) {
			await this.fire.forestryQuarters.add(id);
		}
		await this.fire.forestryQuarters();
		console.log('> saveForestryQuarters', oldIds, '->', newIds);
	};

	/**
	 * Обновляет массив при нажатии на "Автозаполнение" или при установке точки возникновения пожара
	 */

	getForestryQuarters = async () => {
		if (!this.fire.geo) return;
		this.forestryQuarters = await store.model.Fire.getForestryQuarters(this.fire.geo);
		const quartersCodes = this.forestryQuarters.map((fq) => fq.code);
		let oldQuartersCodes = this.fire.quartersCodes || [];
		this.map.updateLayers();
		const dif = [...difference(oldQuartersCodes, quartersCodes), ...difference(quartersCodes, oldQuartersCodes)];
		const uniqueCodes = [...new Set(quartersCodes)];
		console.log('quartersCodes', uniqueCodes);
		this.onChange('quartersCodes')(uniqueCodes);
		if (dif.length > 0) {
			this.onChange('quartersCodes')(uniqueCodes);
		}
	};

	updateMainInfo = async () => {
		await this.getForestryQuarters();

		await Promise.all([
			this.setRelation('aviaDep', 'AviaDep', 'aviaDepId'),
			this.setRelation('forestry', 'Forestry', 'forestryId', ['forestOwnership']),
			this.setRelation('zone', 'MonitoringZone', 'monitoringZoneId'),
			this.setRelation('forestryPrecinct', 'ForestryPrecinct', 'forestryPrecinctId'),
		]);

		if (this.fire.forestryId) {
			const forestry = await store.model.Forestry.findById(this.fire.forestryId, { fields: ['id', 'forestOwnershipId'] });
			const forestOwnership = forestry.forestOwnershipId ? await this.fire.forestry.forestOwnership : null;
			if ((this.fire.forestOwnershipId || null) !== (forestOwnership ? forestOwnership.id : null)) {
				this.onChange('forestOwnership')(forestOwnership);
			}
		}

		const county = this.counties.length > 0 ? this.counties[0] : null;
		if ((this.fire.countyId || null) !== (county ? county.id : null)) {
			this.onChange('county')(county);
		}

		this.getAviaDepData();
	};

	setRelation = async (relationName, modelName, foreignKey, include) => {
		if (this.forestryQuarters.length === 0) return;

		const forestryQuarter = getMostRepeatedElement(this.forestryQuarters, foreignKey);
		const id = forestryQuarter ? forestryQuarter[foreignKey] : null;
		console.log('>> forestryQuarter', forestryQuarter, foreignKey, id);

		if (!id || id === (this.fire[foreignKey] || null)) return;
		// console.log('> setRelation', relationName, foreignKey, this.fire[foreignKey], id);
		const record = id ? await store.model[modelName].findById(id, { include }) : null;
		console.log('>', record);
		this.onChange(relationName)(record);
	};

	downloadFireAct = async () => {
		const { content, filename } = await store.model.FireReport.getReport({
			reportCode: 'FireAct',
			reportParams: { id: this.fire.fireId },
		});

		exportReport({ filename, content });
	};

	toggleMapMaximized = () => {
		this.mapMaximized = !this.mapMaximized;
		setTimeout(() => {
			this.map.map.updateSize();
		}, 100);
	};

	/**
	 * Проверяет, не переданы ли широта/долгота (создание пожара из термоточки).
	 * Если переданы, задать точку возникновения и обновить карту.
	 */
	checkParams = async () => {
		const params = new URLSearchParams(document.location.search);
		const hp = params.get('hp');
		console.log('>>> checkParams', hp, document.location.search);
		if (hp) {
			try {
				const heatPoint = await store.model.FireData.findById(hp);
				const [lon, lat] = heatPoint.geo.coordinates;
				this.onChange('date')(heatPoint.date);
				this.map.setDatesForLayers();
				this.map.heatPointsLayer.update();
				this.map.weatherLayer.update();
				this.map.windLayer.update();
				this.map.checkCoords(lon, lat);
				if (this.location) this.location.updateLocationFields();
			} catch (e) {
				console.error(e);
			}
		}
	};

	//• пересчёт площаей при мануальном вводе
	calcManualArea = (label) => (value) => {
		// сначала поменяем значение из текущего инпута площади
		this.onChange(label)(value);
		// далее пересчёт только зависимых от инпута площадей(чтобы никто не пересчитал текущий инпут)
		const coverAreaDeps = ['upperArea', 'lowerArea', 'reserveArea', 'operationalArea', 'protectiveArea', 'soilArea'];
		if (coverAreaDeps.includes(label)) {
			const { upperArea = 0, lowerArea = 0, reserveArea = 0, operationalArea = 0, protectiveArea = 0, soilArea = 0 } = this.fire || {};
			const s1 = upperArea + lowerArea + soilArea;
			const s2 = reserveArea + operationalArea + protectiveArea;
			const coverArea = s1 > s2 ? s1 : s2;
			this.boxColorByGroup = s1 < s2 ? 's1' : 's2';
			this.onChange('coverArea')(coverArea);
		}

		const forestAreaDeps = [...coverAreaDeps, 'coverArea', 'nonCoverArea'];
		if (forestAreaDeps.includes(label)) {
			const { coverArea = 0, nonCoverArea = 0 } = this.fire || {};
			this.onChange('forestArea')(coverArea + nonCoverArea);
		}

		if (label !== 'area') {
			const { forestArea = 0, nonForestArea = 0 } = this.fire || {};
			this.onChange('area')(forestArea + nonForestArea);
		}
	};

	//• пересчёт площаей при мануальном вводе для арендуемых участков
	calcManualAreaAr = (label) => (value) => {
		// сначала поменяем значение из текущего инпута площади
		this.onChange(label)(value);
		// далее пересчёт только зависимых от инпута площадей(чтобы никто не пересчитал текущий инпут)
		const coverAreaDeps = ['upperAreaAr', 'soilAreaAr'];
		if (coverAreaDeps.includes(label)) {
			const { upperAreaAr = 0, soilAreaAr = 0 } = this.fire || {};
			const coverAreaAr = upperAreaAr + soilAreaAr;
			this.onChange('coverAreaAr')(coverAreaAr);
		}

		const forestAreaDeps = [...coverAreaDeps, 'coverAreaAr', 'nonCoverAreaAr'];
		if (forestAreaDeps.includes(label)) {
			const { coverAreaAr = 0, nonCoverAreaAr = 0 } = this.fire || {};
			this.onChange('forestAreaAr')(coverAreaAr + nonCoverAreaAr);
		}
		if (label !== 'areaAr') {
			const { forestAreaAr = 0, nonForestAreaAr = 0 } = this.fire || {};
			this.onChange('areaAr')(forestAreaAr + nonForestAreaAr);
		}
	};
}
