import React from 'react';
import { observer, Provider } from 'mobx-react';
import { DatePicker, Field, Popup, Tab, Tabs } from '@smartplatform/ui';
import t from 'i18n';
import { People } from './people/People';
import { Aircrafts } from './aircrafts/Aircrafts';
import { ExtinguishingAgents } from './agents/ExtinguishingAgents';
import { CopyPopup } from './CopyPopup';
import ForceAvailabilityEditStore from './store';
import { Form, CopyButton } from 'components';

@observer
export class EditPopup extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ForceAvailabilityEditStore();
		this.store.init(this.props.record);
	}

	onSave = async () => {
		await Promise.all([this.store.saveRelations(), this.store.deleteRelations()]);
		this.props.onClose();
	};

	onClose = async () => {
		await this.store.deleteNotSavedRelations();
		this.props.onClose();
	};

	render() {
		const { disabled, onClose } = this.props;
		if (this.store.isLoading) return null;
		const isCopyDisabled = disabled;
		const title = this.store.record.id ? t('editRecord') : t('addRecord');
		const controls = <span className='controls'>{!this.store.isNew && <CopyButton onClick={this.store.openCopyPopup} disabled={isCopyDisabled} />}</span>;
		const tabsArray = [<People />, <ExtinguishingAgents />, <Aircrafts />];
		return (
			<Provider store={this.store}>
				<Popup className='force-availability-popup fires-popup' onClose={this.onClose} width={800} key={this.store.record.id}>
					<Form
						disabled={disabled}
						noDelete={this.store.isNew}
						record={this.store.record}
						onSave={this.onSave}
						onDelete={onClose}
						stay
						className='force-availability-form'
						onCancel={this.onClose}
						controls={controls}
						noClean
					>
						<div className='form-content'>
							<div className='left'>
								<h2>{title}</h2>
								<Field property='date' label={t('date')}>
									<DatePicker showClearButton={false} />
								</Field>
							</div>
							<div className='right'>
								<Tabs type='manual'>
									{['fireFightingPeople.plural', 'fireFightingVehicle.plural', 'aircraft.plural'].map((title, index) => (
										<Tab key={index} title={t(title)} onClick={() => this.store.setTab(index)} />
									))}
								</Tabs>
								<div className='tab-content' id={this.store.activeTabIndex}>
									{tabsArray[this.store.activeTabIndex]}
								</div>
							</div>
						</div>
					</Form>
					{this.store.showCopyPopup && (
						<CopyPopup record={this.store.record} onClose={this.store.closeCopyPopup} fetchRecord={this.store.fetchRecord} onCloseMainPopup={onClose} />
					)}
				</Popup>
			</Provider>
		);
	}
}
