import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import {
	Loader,
	Form,
	Row,
	Field,
	Button,
	Input,
	DatePicker,
	RecordSelect,
	Popconfirm,
	Checkbox,
	RelationSelect
} from '@smartplatform/ui';
import { fio, getRoleNames } from 'client/tools';
import SentryPersonnelAndVehicles from './SentryPersonnelAndVehicles';
import ShiftPersonnelAndVehicles from './ShiftPersonnelAndVehicles';
import store from 'client/store';
import t from 'i18n';

@observer
export default class FireDepShift extends React.Component {
	
	@observable fireDep = null;
	@observable fireDepShift = null;
	@observable dispatcher = null;
	@observable fireDepSentry = null;
	@observable personnel = [];
	@observable vehicles = [];
	@observable positionId = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const { id, fireDepId } = this.props.match.params;
		
		this.fireDep = await store.model.FireDep.findById(fireDepId, {
			include: ['sentries'],
		});

		const position = await store.model.FireDepPosition.findOne({where: {name: 'НК'}});
		this.positionId = position.id;

		const shifts = await store.model.FireDepShift.find({
			where: { shiftId: id, fireDepId },
			include: [
				{
					relation: 'dispatcher',
					scope: {
						include: ['section', 'position']
					}
				},
				{
					relation: 'personnel',
					scope: {
						include: [
							{ relation: 'vehicle', scope: { include: [ 'brand', 'type' ] }},
							{ relation: 'status', scope: { fields: [ 'id', 'name' ] }},
						],
						order: 'lastName asc, firstName asc, middleName asc'
					}
				},
				{
					relation: 'vehicles',
					scope: {
						include: [ 'personnel' ],
						order: 'id asc'
					}
				},
			],
		});

		if (shifts.length > 0) {
			this.fireDepShift = shifts[0];
			this.dispatcher = this.fireDepShift.dispatcher;
		}
		else {
			const personnel = await store.model.FireDepPersonnel.find({
				where: { userId: store.model.user.id },
				include: ['section', 'position']
			});
			this.dispatcher = personnel[0] || null;
			const roleNames = await getRoleNames();

			const fireDepShift = new store.model.FireDepShift();
			fireDepShift.shiftId = id;
			fireDepShift.fireDepId = fireDepId;
			fireDepShift.mainDispatcher = roleNames.includes('tfires_main');
			fireDepShift.dispatcher = this.dispatcher;
			fireDepShift.date = new Date();
			this.fireDepShift = fireDepShift;
		}
		this.isLoading = false;
	};
	
	resetShift = async () => {
		await this.fireDepShift.delete();
		this.fireDepSentry = null;
		this.personnel = [];
		this.vehicles = [];
		store.route.push({ path: `/techfires/shifts/${this.props.match.params.id}` });
	};
	
	renderSentryName = (record) => {
		if (record.name) return record.name;
		if (record.globalSentry) return record.globalSentry.name;
		return '-';
	};

	onFireDepSentryChange = (record) => {
		this.fireDepSentry = record;
		this.fireDepShift.sentry = this.fireDepSentry;
		this.personnel = record.personnel();
		this.vehicles = record.vehicles();
	};
	
	onStandInChange = () => {
		const value = this.fireDepShift.standIn;
		console.log('onStandInChange', value);
		if (value) {
			this.fireDepShift.dispatcher = null;
		}
	};
	
	submit = async () => {
		await this.fireDepShift.save();
		const promises = [
			...this.personnel.map(r => this.fireDepShift.personnel.add(r.id)),
			...this.vehicles.map(r => this.fireDepShift.vehicles.add(r.id)),
		];
		await Promise.all(promises);
		store.route.push({ path: `/techfires/shifts/${this.props.match.params.id}` });
	};
	
	renderSection = (record) => {
		return <Input value={record.section?.name || '-'} disabled />;
	};
	
	renderPosition = (record) => {
		return <Input value={record.position?.name || '-'} disabled />;
	};
	
	render() {
		if (this.isLoading) return <Loader/>;

		const { id, fireDepId } = this.props.match.params;
		
		const disabled = false;//!!this.fireDepShift.id;
		
		return <div className="list">
			{/*<pre>{JSON.stringify(this.props.match.params)}</pre>*/}
			<Form record={this.fireDepShift} disabled={disabled} noDelete stay>
				<Row>
					<Field property="date" label={t('fireDepShift.date')}><DatePicker showTimeSelect isRequired /></Field>
					<div/>
					<div/>
				</Row>
				<Row>
					<Field relation="dispatcher" computed={fio} label={t('fireDepShift.dispatcher')} disabled={!!this.fireDepShift.standIn} />
					<Field relation="dispatcher" label={t('fireDepSection.title')}>{this.renderSection}</Field>
					<Field relation="dispatcher" label={t('fireDepPosition.title')}>{this.renderPosition}</Field>
				</Row>
				<Field property="standIn" label={t('fireDepShift.standIn')}><Checkbox onChange={this.onStandInChange}/></Field>
				<Row>
					<Field property="standInName" label={t('fireDepShift.standInName')} disabled={!this.fireDepShift.standIn} />
					<Field relation="standInSection" property="name" label={t('fireDepShift.standInSection')} disabled={!this.fireDepShift.standIn} />
					<Field relation="standInPosition" property="name" label={t('fireDepShift.standInPosition')} disabled={!this.fireDepShift.standIn} />
				</Row>
				<Row>
					<Field label={t('fireDepShift.guardHead')} relation='guardHead' computed={guard => fio(guard)}  filter={{ where: {positionId: this.positionId} }}>
						<RelationSelect />
					</Field>
				</Row>
			</Form>
			<div className="mt-2">
				{!this.fireDepShift.id && <Row>
					<div className="form-field">
						<label>{t('fireDepSentry.title')}</label>
						<RecordSelect
							model={store.model.FireDepSentry}
							value={this.fireDepSentry}
							filter={{
								where: { fireDepId },
								include: ['globalSentry', 'personnel', 'vehicles'],
							}}
							computed={this.renderSentryName}
							onChange={this.onFireDepSentryChange}
							placeholder={this.fireDep ? t('fireDepSentry.select') : t('fireDep.select')}
							disabled={!this.fireDep || !!this.fireDepShift.id}
							isRequired
						/>
					</div>
					<div />
					<div />
				</Row>}
				{this.fireDepSentry && <SentryPersonnelAndVehicles sentry={this.fireDepSentry} />}
				{this.fireDepShift.id && <ShiftPersonnelAndVehicles shift={this.fireDepShift} />}
			</div>
			{!this.fireDepShift.id && <div className="mt-4">
				<Button variant="primary" onClick={this.submit} disabled={!this.fireDepSentry}>{t('fireDepShift.submit')}</Button>
			</div>}
			{this.fireDepShift.id && this.props.isMain && <div className="mt-4">
				<Popconfirm onConfirm={this.resetShift} content={t('fireDepShift.confirmReset')}>
					<Button variant="danger">{t('fireDepShift.reset')}</Button>
				</Popconfirm>
			</div>}
		
		</div>;
	}
}
