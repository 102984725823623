import React from 'react';
import { observer, inject } from 'mobx-react';

import { Map } from '@smartplatform/map/client';
import AddressTools from './AddressTools';
import store from 'client/store';

const getId = (function () {
	let id = 1;
	return () => id++;
})();

@inject('listStore') @observer
export default class CaseMap extends React.Component {

	render() {
		const { listStore } = this.props;

		return <div className="case-map-div">
			<Map className="case-map" onInit={listStore.onMapInit}>
				<div className="legend">
					<div><span className="disc mobile" /> моб. оператор</div>
					<div><span className="disc medved" /> {store.config.medved || 'облачная поликлиника'}</div>
					<div><span className="disc violation" /> нарушение</div>
				</div>
				{/*<button className="maximize-btn" onClick={listStore.map.toggleMaximized}>max</button>*/}
			</Map>
			{this.props.listStore.address && <AddressTools key={this.props.listStore.address.id || 'new' + getId()} />}
		</div>;
	}
	
}
