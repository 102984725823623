import { observable } from 'mobx';
import store from 'client/store';
import { REQUESTS_INCLUDE } from '../constants';

export default class SingleCargoStore {
	@observable record = null;
	@observable isLoading = true;
	@observable tracks = null;
	@observable trackStatuses = null;
	id;

	init = async () => {
		this.record = await store.model.Request.findById(this.id, REQUESTS_INCLUDE);
		this.tracks = this.record.tracks();
		this.trackStatuses = await store.model.RequestTrackStatus.find();
		this.isLoading = false;
	};
}
