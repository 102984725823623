import React from 'react';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHome, faMapMarker, faLocationArrow } from '@fortawesome/free-solid-svg-icons';

import { Pager, Button } from '@smartplatform/ui';
import { URL, SUGGEST_COUNT } from '../search/store';
import { shortenAddressName } from 'client/tools';
import store from 'client/store';
import getGeo from './getGeo';
import './style.scss';

@observer
export default class AddressSearch extends React.Component {
	
	@observable searchStr = '';
	@observable prevStr = '';
	@observable actualStr = '';
	@observable suggestions = [];
	@observable focus = false;
	@observable active = false;
	@observable isLoading = false;
	@observable selected = null;
	@observable page = 1;
	
	constructor(props) {
		super(props);
		this.doSearch = debounce(this.doSearch, 500, { leading: true, trailing: true });
		this.searchStr = this.prevStr = this.actualStr = props.value ? props.value.name : '';
		this.actualStr = props.value ? props.value.name : '';
	}
	
	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
		// this.checkActive();
		// this.doSearch();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.searchStr = this.prevStr = this.actualStr = this.props.value ? this.props.value.name : '';
			this.actualStr = this.props.value ? this.props.value.name : '';
		}
	}
	
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
	}
	
	onMount = el => {
		if (el) this.el = el;
	};
	
	onMouseDown = e => {
		if (this.el && !this.el.contains(e.target)) {
			this.active = false;
			this.prevStr = this.searchStr;
			// this.searchStr = '';
		}
	};
	
	onListMount = listNode => {
		if (listNode && this.el) {
			const parentRect = this.el.getBoundingClientRect();
			const rect = listNode.getBoundingClientRect();

			if (parentRect.y + parentRect.height + rect.height > document.body.clientHeight) {
				listNode.classList.add('on-top');
				listNode.style.bottom = this.el.offsetHeight - 1 + 'px';
				listNode.style.top = 'auto';
			}
			else {
				listNode.classList.remove('on-top');
				listNode.style.top = this.el.offsetHeight - 1 + 'px';
				listNode.style.bottom = 'auto';
			}
			
		}
	};
	
	@action onChange = e => {
		this.searchStr = e.target.value;
		this.selected = null;
		this.checkActive();
	}
	
	checkActive = () => {
		this.active = this.searchStr.trim().length > 0;
		this.doSearch();
	};
	
	onFocus = () => {
		this.focus = true;
		// this.searchStr = this.prevStr;
		this.props.onFocus && this.props.onFocus();
		this.checkActive();
	};
	
	onBlur = () => this.focus = false;
	
	doSearch = async () => {
		this.isLoading = true;
		const trimmed = this.searchStr.trim();
		if (trimmed.length === 0) return;
		
		this.page = 1;
		const words = trimmed.replace(',', ' ').replace(/\s+/g, ' ').split(' ');
		
		let suggestions = await store.model.Address.find({
			where: {
				name: { ilike: `%${words.join('%')}%` }
			},
			limit: 100,
		});
		suggestions.forEach(s => s.__type = 'db');

		const fromFias = await store.search.getFromFias(store.config.region.searchString + ' ' + trimmed);
		const filtered = fromFias.filter(obj => {
			return suggestions.every(adr => obj['unrestricted_value'] !== adr.name);
		});
		suggestions = [...suggestions, ...filtered];

		// console.log('suggestions', suggestions);
		this.suggestions = suggestions;
		this.isLoading = false;
	};
	
	@action select = async obj => {
		console.log('select', toJS(obj));
		this.isLoading = true;
		if (obj.__type === 'fias') {
			const existing = await store.model.Address.find({
				where: {
					name: obj['unrestricted_value']
				}
			});
			if (existing.length > 0) {
				const address = existing[0];
				console.log('address exists:', address);
				this.searchStr = address.name;
				address.__type = 'db';
				this.props.onChange && this.props.onChange(address);
				this.selected = address;
			}
			else {
				const res = await store.search.getFromFias(obj['unrestricted_value'], 1);
				const fias = res.length > 0 ? res[0] : null;
				console.log('fias', fias);
			
/*
				if (!fias.data.house) {
					this.prevStr = this.searchStr = obj['unrestricted_value'];
					this.doSearch();
					return;
				}
*/
				
				const geo = fias ?
					await getGeo(obj['unrestricted_value'])
					:
					null;
				
				const adr = {
					__type: 'fias',
					name: obj['unrestricted_value'],
					street: fias.data.street,
					building: fias.data.house,
					city: fias.data.city || fias.data.settlement,
					county: fias.data['area_with_type'],
					geo,
				};
				this.searchStr = obj['unrestricted_value'];
				this.props.onChange && this.props.onChange(adr);
				this.selected = adr;
			}
		}
		else {
			this.searchStr = obj.name;
			obj.__type = 'db';
			this.props.onChange && this.props.onChange(obj);
			this.selected = obj;
		}
		this.active = false;
		this.isLoading = false;
		// console.log('fiasAddress', toJS(this.fiasAddress));
		// console.log('currentAddress', toJS(this.currentAddress));
	};
	
	onPageChange = page => this.page = page;
	
	render() {
		const suggestions = this.suggestions
			.slice((this.page - 1) * SUGGEST_COUNT, this.page * SUGGEST_COUNT)
			.map((obj, i) => {
				if (obj.__type === 'db') {
					return <div className="suggestion db" key={i} onClick={() => this.select(obj)}
					            title={`address id: ${obj.id}`}>
						<span className="badge"><FontAwesomeIcon icon={faHome}/></span>
						<span title={obj.name}>{shortenAddressName(obj.name)}</span>
					</div>;
				}
				else if (obj.__type === 'fias') {
					return <div className="suggestion fias" key={i} onClick={() => this.select(obj)}>
						<span className="badge"><FontAwesomeIcon icon={faLocationArrow}/></span>
						<span title={obj['unrestricted_value']}>{shortenAddressName(obj['unrestricted_value'])}</span>
					</div>;
				}
			});
		
		return <div className="address-search" ref={this.onMount}>
			<input
				type="text"
				value={this.searchStr}
				onChange={this.onChange}
				placeholder={this.focus ? 'Поиск адреса' : (this.actualStr || 'Поиск адреса')}
			    onFocus={this.onFocus}
			    onBlur={this.onBlur}
			/>
			{this.active ?
				<div className="dropdown" ref={this.onListMount}>
					{this.suggestions.length > 0 && !this.isLoading ?
						<div className="suggestions">
							<Pager
								totalCount={this.suggestions.length}
								itemsPerPage={SUGGEST_COUNT}
								current={this.page}
								onChange={this.onPageChange}
							/>
							{suggestions}
						</div>
						:
						<div className="empty">{this.isLoading ? 'Поиск...' : 'нет результатов'}</div>
					}
				</div>
				:
				(!this.selected && this.searchStr.trim().length > 0 && !this.props.value &&
					<div className="not-selected">Адрес не выбран</div>
				)
			}
		</div>;
	}
	
}
