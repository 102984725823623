import { observable } from 'mobx';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import MultiPoint from 'ol/geom/MultiPoint';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';

import { geoJSON, getAllPoints } from '@smartplatform/map/client';
import marker from 'img/icons/marker.svg';

const fill = new Fill({ color: 'rgba(0, 80, 255, 0.3)' });
const stroke = new Stroke({ color: 'rgba(0, 80, 255, 0.6)', width: 3 });

const DEFAULT_STYLE = new Style({ fill, stroke, image: new Icon({ src: marker, anchor: [10, 27], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }) });

const DRAW_STYLE = [
	new Style({ fill, stroke }),
	new Style({
		image: new CircleStyle({
			radius: 5,
			fill: new Fill({ color: 'rgba(40, 120, 255, 1)' }),
			stroke: new Stroke({ color: 'rgba(0, 80, 255, 1)', width: 1 }),
		}),
		geometry: function (feature) {
			const coordinates = getAllPoints(feature.getGeometry());
			return new MultiPoint(coordinates);
		},
	}),
];

export default class GeoEditStore {

	@observable mapInitialized = false;
	@observable mapMaximized = false;
	@observable changed = false;
	
	constructor(props) {
		this.props = props;
		this.geo = props.value;
		console.log('geo', this.geo);
	}
	
	onMapInit = async (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;
		
		mapStore.on('modifyend', this.onDrawAction);
		mapStore.on('drawend', this.onDrawAction);
		mapStore.on('modifystart', this.onDrawAction);
		mapStore.on('drawstart', this.onDrawAction);
		
		this.source = new VectorSource();
		this.layer = new VectorLayer({
			format: geoJSON,
			source: this.source,
			style: DEFAULT_STYLE,
		});
		this.mapStore.addLayer(this.layer);
		this.layer.setZIndex(10);
		
		this.addGeo();
		this.mapInitialized = true;
		
		// this.startDrawing();
	};
	
	startDrawing = () => {
		this.origGeo = this.geo;
		this.layer.setStyle(DRAW_STYLE);
		this.mapStore.startDrawing(this.layer);
	};
	
	onDrawAction = async () => {
		this.geo = await this.mapStore.getGeoFromDrawing();
		if (this.props.onChange) this.props.onChange(this.geo);
		this.changed = true;
	};
	
	finishDrawing = async () => {
		this.geo = await this.mapStore.getGeoFromDrawing();
		// console.log('geo', this.geo);
		if (this.props.onChange) this.props.onChange(this.geo);
		this.layer.setStyle(DEFAULT_STYLE);
		this.mapStore.stopDrawing();
		this.changed = false;
	};
	
	cancelDrawing = () => {
		this.geo = this.origGeo;
		this.changed = false;
		this.addGeo();
		this.layer.setStyle(DEFAULT_STYLE);
		this.mapStore.stopDrawing();
	};
	
	addGeo = () => {
		this.source.clear();
		if (!this.geo) return;
		const geometry = geoJSON.readGeometry(this.geo);
		this.feature = new Feature({ geometry });
		this.source.addFeature(this.feature);
		this.mapStore.fitGeo(this.geo, this.props.params.zoom || 14);
	};
	
}
