import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker } from '@smartplatform/ui';
import t from 'i18n';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const className = 'form-field';
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className={className}>
							<label>{t('date')}</label>
							<DatePicker showTimeSelect showClearButton={false} value={report.date} onChange={report.onDateChange} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
