import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { DottedTextField } from 'fires/components/fields/DottedTextField';
import {Input} from "@smartplatform/ui";
@inject('card')
@observer
export default class Numbering extends React.Component {
	render() {
		const { fire } = this.props.card;
		const className = 'form-field';

		return (
			<div>
				<h2>{t('fire.numbers')}</h2>
				{['fireNumber',
					'forestryFireNumber',
					'aviaDepFireNumber',
					'bigFireNumber',
				]
					.map(label =>
						<React.Fragment  key={label}>
					        <div className={className}>
					            <label>{t(`fire.${label}`)}</label>
					            <Input value={fire[label] || t('no')} disabled />
					        </div>
                        </React.Fragment>
					)}
                <div className={className}>
                    <label>{t(`fire.isdmFireNumber`)}</label>
                    <Input value={fire.isdmFire?.fireNumber || t('no')} disabled />
                </div>
                <div className={className}>
                    <label>{t(`fire.id`)}</label>
                    <Input value={fire.fireId || t('no')} disabled />
                </div>
			</div>
		);
	}
}
