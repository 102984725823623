export const LOCAL_STORAGE_FIELDS = ['showFilters', 'page', 'search'];

// include
export const EDIT_INCLUDE = {
	include: [],
};
export const LIST_INCLUDE = {
	include: [{ relation: 'status', scope: { fields: ['id', 'name', 'color'] } }],

	fields: ['id', 'name', 'countyName', 'citiesCount', 'statusId', 'population', 'area', 'risks', 'statusName'],
};

export const MODEL_NAME = 'ViewMunicipality';
export const SEARCH_FIELDS = ['name', 'countyName'];
