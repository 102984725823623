import React from 'react';
import { observer, inject } from 'mobx-react';

import BasicEdit from './BasicEdit';
import './style.scss';

@inject('fireDepStore')
@observer
export default class Edit extends React.Component {

	constructor(props) {
		super(props);
		this.fireDepStore = props.fireDepStore;
	}

	render() {
		const { fireDep, isLoading, path } = this.fireDepStore;
		if (isLoading) return null;
		return (
			<div className='edit'>
				<BasicEdit record={fireDep} path={path} />
			</div>
		);
	}
}
