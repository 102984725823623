import React from 'react';
import { inject, observer } from 'mobx-react';
import MainInfo from './MainInfo';
import Location from './Location';
import Numbering from "./Numbering";
import Dates from "./Dates";
import Characteristics from './Characteristics'
import Meteo from './Meteo';
import store from 'client/store';
import t from 'i18n';
import Distances from './Distances';
import ExtinguishingCosts from "fires/fire-card/main/ExtinguishingCosts";
import ForestFireAct from "fires/fire-card/main/ForestFireAct";
import ForestryDamage from "fires/fire-card/main/ForestryDamage";

@inject('card') @observer
export default class Main extends React.Component {

	render() {
		return <div className="card-form">
			<div className="panel">
				<Dates />
				<MainInfo />
				<Characteristics />
				<Numbering />
			</div>
			<hr/>
            <div className="panel">
                <Location />
                <Distances />
                <Meteo />
                <div className='form-field'>
                    <label>{t('fire.description')}</label>
                    <textarea
                        rows={5}
                        style={{ width: '100%' }}
                        value={this.props.card.fire.fireDescription || ''}
                        onChange={(r) => this.props.card.onChange('fireDescription')(r.target.value)}
                        disabled={!store.model.Fire.INFO.WRITE}
                    />
                </div>
            </div>
            <hr/>
            <div  className="panel">
                <ExtinguishingCosts />
                <ForestryDamage />
                <ForestFireAct />
            </div>
		</div>;
	}
}
