import { observable } from 'mobx';

import store from 'client/store';
import silamLayers from './silamLayers';
import gfsLayers from './gfsLayers';
import WeatherTip from './tip/weatherTip';

const DEFAULT_SETTINGS = {
	layer: 'pm25',
	minimized: false,
	date: new Date(),
};

export const WEATHER_LAYERS = {
	...silamLayers,
	...gfsLayers,
};

export default class WeatherLayer {

	@observable layer = null;
	@observable isLoading = false;

	constructor(mapStore, settings = {}, params = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
		this.onLoad = params.onLoad;
		this.onSetLayer = params.onSetLayer;
	}

	init = async () => {
		this.map = this.mapStore.map;
		this.weatherTip = new WeatherTip(this);
		this.addLayerToMap();
	};

	setLayer = (layerCode) => {
		console.log('setLayer', layerCode);
		this.weatherImage = null;
		this.settings.layer = layerCode;
		this.addLayerToMap();
	};

	addLayerToMap = () => {
		this.isLoading = this.settings.show;
		if (this.layer) this.mapStore.removeLayer(this.layer.getLayer());
		this.layerConfig = WEATHER_LAYERS[this.settings.layer];
		if (this.layerConfig) {
			if (this.onSetLayer) this.onSetLayer(this.layerConfig);
			const { makeLayer, ...rest } = this.layerConfig;
			this.layer = makeLayer(this.mapStore.map, {
				...rest,
				onLoad: this.onWebglImageLoad,
			});
			this.setWeatherLayer(this.settings.layer);
			this.setVisible(this.settings.show);
			this.mapStore.addLayer(this.layer.getLayer());
		}
		else {
			console.error('cannot add layer with code', this.settings.layer);
		}
	};

	updateSettings = (settings) => {
		if (settings.layer !== this.settings.layer) this.setLayer(settings.layer);
		this.settings = settings;
	};
	
	setVisible = (isVisible) => {
		this.layer.getLayer().setVisible(isVisible);
	};

	onWebglImageLoad = (image, colorRamp) => {
		// console.log('onWebglImageLoad (global)', image);
		this.weatherImage = image;
		this.colorRamp = colorRamp;
		if (this.onLoad) this.onLoad(image, colorRamp);
		this.isLoading = false;
	};
	
	onWeatherScaleMount = el => {
		this.weatherScale = el;
	};

	setWeatherLayer = async (weatherLayerConfig) => {
		let { type, code, colors, min, max, measure, convert } = weatherLayerConfig;
		// console.log('weatherLayerConfig', weatherLayerConfig);
		
		if (type === 'silam') {
			const metaUrl = `/api/silammeta?date=${this.settings.date.toISOString()}&layer=${code}`;
			console.log('metaUrl', metaUrl);
			try {
				const res = await fetch(metaUrl);
				const json = await res.json();
				console.log('json', json);
				this.silamMin = min = json.min;
				this.silamMax = max = json.max;
			}
			catch (e) {
				console.warn('/api/silammeta', e);
			}
		}
		
		if (colors && this.weatherScale) {
			this.weatherScale.innerHTML = '';
			this.weatherScale.appendChild(this.getColorRamp(colors));
			if (min !== undefined && max !== undefined) {
				const minDiv = document.createElement('div');
				minDiv.className = 'min-value';
				minDiv.innerHTML = Math.floor(convert ? convert(min) : min) + (measure ? ' ' + measure : '');
				this.weatherScale.appendChild(minDiv);
				
				const maxDiv = document.createElement('div');
				maxDiv.className = 'max-value';
				maxDiv.innerHTML = Math.floor(convert ? convert(max) : max) + (measure ? ' ' + measure : '');
				this.weatherScale.appendChild(maxDiv);
			}
		}
	};

	update = () => {
		// if (!this.store.layersSettings.weather.show) return;
		// if (this.layer) this.layer.update();
		this.addLayerToMap();
	};

	isSilamLayer = (layerCode) => !!silamLayers[layerCode];
	
	getColorRamp = (colors) => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		
		const width = 300;
		const gradient = ctx.createLinearGradient(0, 0, width, 20);
		
		canvas.width = width;
		canvas.height = 20;
		
		if (Array.isArray(colors)) {
			for (let i = 0; i < colors.length; i++) {
				gradient.addColorStop(i / colors.length, colors[i]);
				ctx.fillStyle = gradient;
				ctx.fillRect(0, 0, width, 20);
			}
		}
		else {
			for (const breakpoint of colors.scale) {
				const [ value, pos, [ r, g, b, a ]] = breakpoint;
				gradient.addColorStop(pos, `rgba(${r},${g},${b},${a / 255})`);
			}
			ctx.fillStyle = gradient;
			ctx.fillRect(0, 0, width, 20);
		}
		
		return canvas;
	};
	
	updateTip = async (e) => {
		if (e.dragging) {
			if (this.weatherTip) this.weatherTip.hide();
			return;
		}
		
		if (this.settings.show) {
			if (this.weatherImage && this.layerConfig) {
				const coords = this.map.getCoordinateFromPixel([e.pixel[0], e.pixel[1] - 20]);
				this.weatherTip.update(coords, this.weatherImage, this.layerConfig, this.colorRamp);
			}
		}
		else {
			if (this.weatherTip) this.weatherTip.hide();
		}
	};
	
};
