import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Form, Row, Input, Field, Button, DatePicker, RecordSelect } from '@smartplatform/ui';
import t from 'i18n';

@observer
export default class Alarm112 extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { alarmMessage } = this.props;
		if (!alarmMessage) return 'no alarmMessage!';

		return (
			<>
				<Row>
					<div className='form-field'>
						<label>{t('fireAlarmMessage.operatorNumber')}</label>
						<Input value={alarmMessage.operatorNumber || '-'} disabled />
					</div>
					<div className='form-field'>
						<label>{t('fireAlarmMessage.incidentCode')}</label>
						<Input value={alarmMessage.incidentCode || '-'} disabled />
					</div>
					<div/>
				</Row>
				<Row>
					<div className='form-field'>
						<label>{t('fireAlarmMessage.addressText')}</label>
						<Input value={alarmMessage.addressText || '-'} disabled />
					</div>
				</Row>
				<Row>
					<div className='form-field'>
						<label>{t('fireAlarmMessage.information')}</label>
						<textarea value={alarmMessage.information || ''} rows={10} disabled />
					</div>
				</Row>
			</>
		);
	}
}
