import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Content from '../Content';
import t from 'i18n';

@observer
export default class Statuses extends React.Component {

	render() {
		return <div className="appeal-systems">
			<h1>{t('appealSystem.plural')}</h1>
			<Switch>
				<Route path="/appeals/dictionaries/systems" exact component={List} />
				<Route path="/appeals/dictionaries/systems/create" component={Edit} />
				<Route path="/appeals/dictionaries/systems/:id" component={Edit} />
			</Switch>
		</div>;
	}

}
