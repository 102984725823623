import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { DatePicker, Select, Checkbox, Popconfirm } from '@smartplatform/ui';
import { AddressSearch, makeAddressFromFias } from '../address-search';
import store from 'client/store';
// import listStore from './store';

@inject('listStore') @observer
export default class CaseLog extends React.Component {
	
	@observable status = null;
	@observable date = null;
	@observable isolation = null;
	@observable contacted = false;
	@observable address = null;
	
	__outerAddress = false;
	
	constructor(props) {
		super(props);
		this.listStore = props.listStore;
		this.mapStore = props.listStore.mapStore;
		this.log = props.log;
		this.date = this.log.date || new Date();
		this.status = this.log.status;
		this.isolation = this.log.isolation;
		this.contacted = this.log.contacted;
		this.address = this.log.address || this.log.__address;
		if (props.instance) props.instance({
			setAddress: this.setAddress,
		});
	}
	
	delete = async () => {
		this.props.onDelete();
	};
	
	onDateChange = date => {
		this.date = date;
		this.onChange();
	};
	
	onStatusChange = status => {
		this.status = status;
		this.onChange();
	};

	onIsolationChange = isolation => {
		this.isolation = isolation;
		this.onChange();
	};
	
	onContactedChange = contacted => {
		this.contacted = contacted;
		this.onChange();
	};
	
	setAddress = address => {
		this.listStore.address = address;
		this.__outerAddress = true;
		this.address = address;
		if (address && address.geo) {
			// this.listStore.map.addHelperMarker(address.geo.coordinates);
			this.mapStore.fitGeo(address.geo, this.mapStore.map.getView().getZoom() < 16 ? 16 : undefined);
		}
	};
	
	onAddressChange = async address => {
		if (address.__type === 'db') {
			this.listStore.address = address;
			this.address = this.listStore.address;
		}
		else {
			this.listStore.address = await makeAddressFromFias(address);
			this.address = this.listStore.address;
			console.log('from fias', this.listStore.address);
		}
		this.__outerAddress = false;
		if (this.address && this.address.geo) {
			// this.listStore.map.addHelperMarker(this.address.geo.coordinates);
			this.mapStore.fitGeo(address.geo, this.mapStore.map.getView().getZoom() < 16 ? 16 : undefined);
		}
		this.onChange();
	};
	
	onChange = () => {
		this.props.onChange({
			date: this.date,
			status: this.status,
			isolation: this.isolation,
			contacted: this.contacted,
			address: this.address,
			__outerAddress: this.__outerAddress,
		});
	};
	
	render() {
		const className = classNames('case-log', this.props.type, this.isolation ? this.isolation.code : '');
		
		return <div className={className}>
			{/*<div>{this.props.type}</div>*/}
			<DatePicker
				value={this.date}
				onChange={this.onDateChange}
				showTimeSelect
			/>
			<AddressSearch
				value={this.address}
				onFocus={this.props.onFocus}
				onChange={this.onAddressChange}
			/>
			<Select noTotal maxItems={10} itemsPerPage={1000}
				items={Object.values(store.covid.statuses).map(status => ({ label: status.name, value: status }))}
				value={this.status}
				showValue={this.status ? this.status.name : <span className="hint">Статус</span>}
				onChange={this.onStatusChange}
				className="drop-down-md status"
				noSearch
			/>
			<Select noTotal maxItems={10} itemsPerPage={1000}
				items={Object.values(store.covid.isolationStatuses).map(isolation => ({ label: isolation.name, value: isolation }))}
				value={this.isolation}
				showValue={this.isolation ? this.isolation.name : <span className="hint">Изоляция</span>}
				onChange={this.onIsolationChange}
				className="drop-down-md isolation"
				noSearch
			/>
			<Checkbox
				value={this.contacted}
				onChange={this.onContactedChange}
				label="Контактный"
			/>
			<div className="actions">
				{this.props.logCount > 1 && <Popconfirm content="Удалить?" onConfirm={this.delete}><FontAwesomeIcon icon={faTrash}/></Popconfirm>}
			</div>
		</div>;
	}
	
}
