import React from 'react';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';
import isAfter from 'date-fns/isAfter';
import t from 'i18n'
import { Select, Checkbox, DatePicker, RecordSelect, MaskedInput } from '@smartplatform/ui';
import store from 'client/store';
// import listStore from '../store';
import './style.scss';
import { NOTIFY_COLORS } from "client/tools/notifyStatus";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
@inject('listStore') @observer
export default class Filters extends React.Component {

	constructor(props) {
		super(props);
		this.listStore = props.listStore;
		this.filters = this.listStore.filters;
		this.init();
	}

	@action init = async () => {
		this.listStore.filters.mobileOperators = await store.model.MobileOperator.find({ order: 'id asc' });
		this.listStore.filters.mobileStatuses = await store.model.MobileStatus.find({ order: 'id asc' });
		this.listStore.filters.organizations = await store.model.Organization.find({ order: 'id asc' });
		if (store.model.user.organizationId) {
			this.listStore.filters.organization = this.listStore.filters.organizations.find(org => org.id === store.model.user.organizationId);
		}
	};

	onDateFromChange = date => {
		this.filters.dateFrom = date;
		if (this.filters.dateFrom && this.filters.dateTo && isAfter(new Date(this.filters.dateFrom), new Date(this.filters.dateTo))) this.filters.dateTo = null;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onDateToChange = date => {
		this.filters.dateTo = date;
		if (this.filters.dateFrom && this.filters.dateTo && isAfter(new Date(this.filters.dateFrom), new Date(this.filters.dateTo))) this.filters.dateFrom = null;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onStatusChange = status => {
		this.filters.status = status;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onIsolationChange = isolation => {
		this.filters.isolation = isolation;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onContactedChange = contacted => {
		this.filters.contacted = contacted;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onAbroadChange = abroad => {
		this.filters.abroad = abroad;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onMedvedChange = value => {
		this.filters.medved = value;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onViolatorsChange = value => {
		this.filters.violators = value;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onMaxDistanceChange = value => {
		this.filters.maxDistance = value;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onAgreedChange = agreed => {
		this.filters.agreed = agreed;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onMobileOperatorChange = operator => {
		this.filters.mobileOperator = operator;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onMobileStatusChange = status => {
		this.filters.mobileStatus = status;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onOrganizationChange = record => {
		this.filters.organization = record;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onCityChange = record => {
		this.filters.city = record;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onCountyChange = record => {
		this.filters.county = record;
		if (record && this.filters.city && this.filters.city.countyId !== record.id) this.filters.city = null;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onIsPublicChange = value => {
		this.filters.isPublic = value;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onNotifyStatusChange = notifyStatus => {
		this.filters.notifyPhoneAccepted = null;
		this.filters.notifyStatus = notifyStatus;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onNotifyTypeChange = notifyType => {
		this.filters.notifyPhoneAccepted = null;
		this.filters.notifyType = notifyType;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onNotifyDateFromChange = date => {
		this.filters.notifyDateFrom = date;
		if (this.filters.notifyDateFrom && this.filters.notifyDateTo && isAfter(new Date(this.filters.notifyDateFrom), new Date(this.filters.notifyDateTo))) this.filters.notifyDateTo = null;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onNotifyDateToChange = date => {
		this.filters.notifyDateTo = date;
		if (this.filters.notifyDateFrom && this.filters.notifyDateTo && isAfter(new Date(this.filters.notifyDateFrom), new Date(this.filters.notifyDateTo))) this.filters.notifyDateFrom = null;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onNotifyPhoneAcceptedChange = (notifyPhoneAccepted) => {
		this.filters.notifyPhoneAccepted = notifyPhoneAccepted;
		this.filters.page = 1;
		this.listStore.reloadList();
	};

	onStartAgeChange = e => {
		if (e.target.value !== this.filters.startAge) {
			this.filters.startAge = e.target.value;
			this.filters.page = 1;
			this.listStore.reloadList();
		}
	};
	onEndAgeChange = e => {
		if (e.target.value !== this.filters.endAge) {
			this.filters.endAge = e.target.value;
			this.filters.page = 1;
			this.listStore.reloadList();
		}
	};

	renderCity = city => <div className="city-item" title={`id: ${city.id}, countyId: ${JSON.stringify(city.countyId)}`}>
		<span>{city.name ? city.name : <em>без названия</em>}</span>
		<small>{city.county ? city.county.name : '-'}</small>
	</div>;

	renderNotifyStatus = status => {
		if (status.name) {
			//* если статуса нет,то отображать все иконки
			const notifyType = this.filters.notifyType ? this.filters.notifyType.code : 'all';
			const notifyPhoneAccepted = this.filters.notifyPhoneAccepted;
			return status.name && <div className='flex-row align-items-center'>
				{status.name}
				{['all', 'sms'].includes(notifyType) && status.code &&
					<FontAwesomeIcon style={{ marginLeft: '5px' }} color={NOTIFY_COLORS[status.code]} icon={faEnvelope} />}

				{['all', 'phone'].includes(notifyType) && status.code && <>
					{[null, 'true'].includes(notifyPhoneAccepted) && <FontAwesomeIcon style={{ marginLeft: '5px' }} color={NOTIFY_COLORS[status.code]} icon={faPhone} />}
					{[null, 'false'].includes(notifyPhoneAccepted) && (status.code === 'sended') && <FontAwesomeIcon style={{ marginLeft: '5px' }} color={NOTIFY_COLORS[status.code]} icon={faPhoneSlash} />}
				</>
				}
			</div>
		}
	};

	render() {
		const filters = this.listStore.filters;

		const operators = filters.mobileOperators.map(operator => ({ label: operator.name, value: operator }));
		operators.splice(0, 0, { label: 'не определен', value: 'none' });

		const organizations = filters.organizations.map(record => ({ label: record.name, value: record }));
		organizations.splice(0, 0, { label: 'без организации', value: 'none' });
		organizations.splice(0, 0, { label: 'все', value: 'all' });

		const isPhoneType = filters.notifyType && (filters.notifyType.code === "phone");
		const isSendedStatus = filters.notifyStatus && filters.notifyStatus.code === "sended";

		return <div className="filters">
			<div className="block">
				<div className='date'>
					<DatePicker
						value={filters.dateFrom}
						className="case-date"
						onChange={this.onDateFromChange}
						placeholder="Дата (от)"
					/>

					<DatePicker
						value={filters.dateTo}
						className="case-date"
						onChange={this.onDateToChange}
						placeholder="Дата (до)"
					/>
				</div>
				<div>
					<Select noTotal maxItems={10} itemsPerPage={1000}
						items={Object.values(store.covid.statuses).map(status => ({ label: status.name, value: status }))}
						value={filters.status}
						showValue={filters.status ? filters.status.name : <span className="hint">Статус</span>}
						onChange={this.onStatusChange}
						className="drop-down-md"
						position="bottomLeft"
						noSearch
					/>
				</div>
				<div>
					<Select noTotal maxItems={10} itemsPerPage={1000}
						items={Object.values(store.covid.isolationStatuses).map(isolation => ({ label: isolation.name, value: isolation }))}
						value={filters.isolation}
						showValue={filters.isolation ? filters.isolation.name : <span className="hint">Изоляция</span>}
						onChange={this.onIsolationChange}
						className="drop-down-md"
						position="bottomLeft"
						noSearch
					/>
				</div>
				<div>
					<Select noTotal maxItems={10} itemsPerPage={1000}
						items={organizations}
						value={filters.organization}
						showValue={filters.organization !== 'all' ? (filters.organization ? filters.organization.name : '-') : <span className="hint">Организации: все</span>}
						onChange={this.onOrganizationChange}
						disabled={!!store.model.user.organizationId}
						position="bottomLeft"
						isRequired
						className="drop-down-md"
						noSearch
					/>
				</div>
			</div>
			<div className="block">
				<div>
					<Select noTotal maxItems={10} itemsPerPage={1000}
						items={operators}
						value={filters.mobileOperator}
						showValue={filters.mobileOperator ? filters.mobileOperator.name : <span className="hint">Моб. оператор</span>}
						onChange={this.onMobileOperatorChange}
						className="drop-down-md"
						position="bottomLeft"
						noSearch
					/>
				</div>
				<div>
					<Select noTotal maxItems={10} itemsPerPage={1000}
						items={filters.mobileStatuses.map(status => ({ label: status.name, value: status }))}
						value={filters.mobileStatus}
						showValue={filters.mobileStatus ? filters.mobileStatus.name : <span className="hint">Моб. статус</span>}
						onChange={this.onMobileStatusChange}
						position="bottomLeft"
						className="drop-down-md"
						noSearch
					/>
				</div>
				<div>
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						model={store.model.City}
						computed={this.renderCity}
						filter={{
							order: 'name asc, countyId',
							where: filters.county ? { countyId: filters.county.id } : undefined,
							include: [
								{ relation: 'county', scope: { fields: ['id', 'name'] } }
							]
						}}
						value={filters.city}
						showValue={filters.city ? (filters.city.name || 'без названия') : <span className="hint">Нас. пункт</span>}
						onChange={this.onCityChange}
						searchFields={['name']}
						position="bottomLeft"
						className="drop-down-md"
					/>
				</div>
				<div>
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						model={store.model.County}
						property="name"
						filter={{ order: 'id asc' }}
						value={filters.county}
						showValue={filters.county ? (filters.county.name || 'без названия') : <span className="hint">ГО / Район</span>}
						onChange={this.onCountyChange}
						searchFields={['name']}
						position="bottomLeft"
						className="drop-down-md"
					/>
				</div>
				<div>
					<Select noTotal maxItems={10} itemsPerPage={1000}
						items={[
							{ label: 'Все', value: 'all' },
							{ label: 'Показывать', value: 'show' },
							{ label: 'Не показывать', value: 'hide' },
						]}
						value={filters.isPublic}
						showValue={filters.isPublic !== 'all' ? (filters.isPublic === 'show' ? 'Показывать' : 'Не показывать') : <span className="hint">Публичная карта: все</span>}
						onChange={this.onIsPublicChange}
						position="bottomLeft"
						className="drop-down-md"
						isRequired
						noSearch
					/>
				</div>
				<div className='date'>
					<MaskedInput mask={'99'} maskChar='' value={this.filters.startAge}
								 placeholder={t('age1')} onChange={this.onStartAgeChange}/>
					<MaskedInput mask={'99'} maskChar='' value={this.filters.endAge}
								 placeholder={t('age2')} onChange={this.onEndAgeChange}/>
				</div>
			</div>
			<div className="block">
				<div>
					<Checkbox
						value={filters.contacted}
						onChange={this.onContactedChange}
						label="Контактный"
					/>
				</div>
				<div>
					<Checkbox
						value={filters.abroad}
						onChange={this.onAbroadChange}
						label="Из-за границы"
					/>
				</div>
				<div>
					<Checkbox
						value={filters.medved}
						onChange={this.onMedvedChange}
						label={store.config.medved || "Облачная поликлиника"}
					/>
				</div>
				<div>
					<Checkbox
						value={filters.agreed}
						onChange={this.onAgreedChange}
						label="Персональные данные"
					/>
				</div>
				<div className="big">
					<Checkbox
						value={filters.violators}
						onChange={this.onViolatorsChange}
						label="Нарушения"
					/>
					{filters.violators && <>
						Дистанция больше чем:
						<Select noTotal maxItems={10} itemsPerPage={1000}
							items={[100, 200, 500, 1000, 2000, 5000]}
							value={filters.maxDistance}
							onChange={this.onMaxDistanceChange}
							className="drop-down-sm"
							position="bottomLeft"
							noSearch
							isRequired
						/>
						метров
					</>}
				</div>



				<div className='block'>
					<div className='form-field'>
						<label>{t('case.notifyFilter')}</label>
					</div>
					<div className='date'>
						<DatePicker
							value={filters.notifyDateFrom}
							className="case-date"
							onChange={this.onNotifyDateFromChange}
							placeholder={t('date1')}
						/>
						<DatePicker
							value={filters.notifyDateTo}
							className="case-date"
							onChange={this.onNotifyDateToChange}
							placeholder={t('date2')}
						/>
					</div>
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						model={store.model.NotifyStatus}
						property="name"
						value={filters.notifyStatus}
						prependItems={[{ id: null, name: t('notifyLog.notSent') }]}
						showValue={!filters.notifyStatus && <span className="hint"> {t('notifyStatus.status')} </span>}
						onChange={this.onNotifyStatusChange}
						computed={this.renderNotifyStatus}
						noSearch
						position="bottomLeft"
						className="drop-down-md"
					/>
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						model={store.model.NotifyType}
						property="name"
						value={filters.notifyType}
						showValue={!filters.notifyType && <span className="hint"> {t('notifyType.type')} </span>}
						onChange={this.onNotifyTypeChange}
						computed={this.renderNotifyType}
						noSearch
						position="bottomLeft"
						className="drop-down-md"
					/>
					{isPhoneType && isSendedStatus &&
						<div>
							<Select noTotal maxItems={10} itemsPerPage={1000}
								items={[
									{ label: t('notifyLog.accepted'), value: 'true' },
									{ label: t('notifyLog.notAccepted'), value: 'false' },
								]}
								value={filters.notifyPhoneAccepted}
								onChange={this.onNotifyPhoneAcceptedChange}
								position="bottomLeft"
								className="drop-down-md"
								placeholder={t('notifyLog.acceptedPlaceholder')}
								noSearch
							/>
						</div>
					}
				</div>
			</div>
		</div>;
	}
}
