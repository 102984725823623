import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';
import '../style.scss';
import { Button } from '@smartplatform/ui';
import ChiefSingle from './ChiefSingle';
import ChiefForm from './ChiefForm';

@inject('fireStore')
@observer
export default class Chiefs extends React.Component {
	@observable chiefs = [];
	@observable addingChief = false;
	@observable editingChief = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.chiefs = await store.model.TechFireChief.find({
			where: { fireId: this.props.fireStore.id },
			order: 'id asc',
		});
	};

	addChief = () => {
		this.addingChief = true;
		this.editingChief = null;
	};

	cancelAddingChief = () => (this.addingChief = false);

	deleteChief = async (id) => {
		const chiefNames = await this.props.casualty.TechFire.findById(id);
		await chiefNames.delete();
		this.init();
	};

	onEdit = (record) => (this.editingChief = record)

	onUpdate = () => {
		this.onClose();
		this.init();s
	};

	onClose = () => {
		this.addingChief = false;
		this.editingChief = null;
	};

	render() {
		return (
			<div>
				<div>
					{this.chiefs.map((chief) => {
						const isEdited = this.editingChief && this.editingChief.id === chief.id;
						return isEdited ? (
							<ChiefForm key={chief.id} record={chief} onClose={this.onClose} onUpdate={this.onUpdate} />
						) : (
							<ChiefSingle onEdit={this.onEdit} key={chief.id} record={chief} onUpdate={this.onUpdate} />
						);
					})}
				</div>
				{this.addingChief ? (
					<ChiefForm key='new' onClose={this.onClose} onUpdate={this.onUpdate} />
				) : (
					<div className='form-field'>
						<label>&nbsp;</label>
						<Button onClick={this.addChief} variant='primary'>
							{t('techFireChief.add')}
						</Button>
					</div>
				)}
			</div>
		);
	}
}
