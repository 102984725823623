import React, { Component } from 'react';
import { Column, Field, Row, Table } from '@smartplatform/ui';
import t from 'i18n';
import { AddRowButton } from 'delivery/components';
import { inject, observer } from 'mobx-react';
import { MovementEditPopup } from './MovementEditPopup';
import { observable } from 'mobx';
import { FormContext } from '@smartplatform/ui';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COST, INCOME } from 'client/constants';

@inject('store')
@observer
export class Movement extends Component {
	@observable showPopup = false;
	@observable selectedRecord = null;

	static contextType = FormContext;

	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	renderMoveName = ({ quantity }) => (quantity > 0 ? t('reserve.income') : quantity < 0 ? t('reserve.cost') : null);
	renderQuantity = () => {
		const { record, movements } = this.store;
		let total = record.startQuantity || 0;
		movements.forEach(({ quantity }) => (total += quantity || 0));
		return total === 0 ? '0' : total;
	};

	openPopup = (type) => {
		this.type = type;
		this.showPopup = true;
	};
	closePopup = async () => {
		this.type = null;
		this.showPopup = false;
		this.selectedRecord = null;
	};
	onRowClick = (row) => {
		this.selectedRecord = row;
		this.openPopup();
	};

	addMovement = (movement) => {
		this.store.movements.push(movement);
		this.context.form.addHasMany('movements', movement);
	};
	deleteMovement = (movement, index) => {
		this.store.movements.splice(index, 1);
		this.context.form.removeHasMany('movements', movement);
	};

	render() {
		const { movements } = this.props.store;
		return (
			<div className='movement'>
				<div className='reserve-info'>
					<Field label={t('reserve.nomenclature')} property='name' disabled className='name' />
					<Field label={t('reserve.startQuantity')} property='startQuantity' disabled />
					<Field label={t('reserve.quantity')} computed={this.renderQuantity} disabled />
				</div>
				<Table rows={movements} onRowClick={this.onRowClick}>
					<Column property='date' label={t('date')} />
					<Column relation='storehouse' property='name' label={t('storehouse.title')} />
					<Column computed={this.renderMoveName} label={t('reserve.movement')} />
					<Column computed={(r) => Math.abs(r.quantity)} label={t('reserve.quantity')} />
				</Table>
				<Row>
					<AddRowButton onClick={() => this.openPopup(COST)} text={t('reserve.cost')} icon={<FontAwesomeIcon icon={faMinus} />} />
					<AddRowButton onClick={() => this.openPopup(INCOME)} text={t('reserve.income')} />
				</Row>

				{this.showPopup && (
					<MovementEditPopup
						type={this.type}
						addMovement={this.addMovement}
						deleteMovement={this.deleteMovement}
						onClose={this.closePopup}
						selectedRecord={this.selectedRecord}
					/>
				)}
			</div>
		);
	}
}
