import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Popup } from '@smartplatform/ui';
import AddressInfo from './AddressInfo';
import t from 'i18n';

@observer
export default class AddressPopup extends React.Component {
	
	static propTypes = {
		address: PropTypes.object,
		onClose: PropTypes.func,
	};
	
	render() {
		const { address, onClose } = this.props;
		
		return <Popup className="address-map-popup" header={t('address.title')} width={600} height={400} onClose={onClose}>
			<AddressInfo address={address} />
		</Popup>;
	}
}
