import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
export default class ShipPopup extends React.Component {

	static propTypes = {};
	static defaultProps = {};

	constructor(props) {
		super(props);
	}

	render() {
		const { record } = this.props;
		const { id, type, ...rest} = record;

		return <div>
			<pre>{JSON.stringify(rest, null, 2)}</pre>
		</div>;
	}

}
