import { observable } from 'mobx';
import appStore from 'client/store';

export default class ForceAvailabilityEditStore {
	@observable record = null;
	@observable isLoading = true;
	@observable isNew;
	@observable showCopyPopup = false;
	@observable activeTabIndex = 0;

	@observable people = [];
	@observable agents = [];
	@observable aircrafts = [];
	hasManyToSave = {};
	hasManyToDelete = {};

	init = async (record) => {
		this.record = record;
		this.isNew = !this.record;
		if (this.isNew) {
			this.record = new appStore.model.ForceAvailability();
			this.record.date = new Date();
		} else {
			this.people = this.record.people();
			this.agents = this.record.extinguishingAgents();
			this.aircrafts = this.record.aircrafts();
		}
		this.isLoading = false;
	};

	closeCopyPopup = () => (this.showCopyPopup = false);
	openCopyPopup = () => (this.showCopyPopup = true);
	setTab = (tabIndex) => (this.activeTabIndex = tabIndex);

	checkHasMany = (relation) => {
		if (!this.hasManyToSave[relation]) this.hasManyToSave[relation] = [];
		if (!this.hasManyToDelete[relation]) this.hasManyToDelete[relation] = [];
	};

	addHasMany = (relation, record) => {
		this.checkHasMany(relation);
		// Проверяем массив на наличие добавляемой реляции,есть есть - изменяем, если нет - добавляем
		const addToSaveIndex = this.hasManyToSave[relation].findIndex((i) => i.id === record.id);
		if (addToSaveIndex === -1) {
			this.hasManyToSave[relation].push(record);
		} else this.hasManyToSave[relation][addToSaveIndex] = record;
		// Такая же логика для массива для рендера, totalCount при этом уже меняем вручную
		const addToRelationIndex = this[relation].findIndex((i) => i.id === record.id);
		if (addToRelationIndex === -1) {
			this[relation].push(record);
			if (!this[relation].totalCount) this[relation].totalCount = 0;
			this[relation].totalCount++;
		} else this[relation][addToRelationIndex] = record;

		// console.log('a: ', this.hasManyToSave);
	};

	removeHasMany = (relation, record) => {
		this.checkHasMany(relation);
		// Убираем из добавленных реляций, если там уже удаляемая
		const addToSaveIndex = this.hasManyToSave[relation].findIndex((i) => i.id === record.id);
		if (addToSaveIndex !== -1) {
			this.hasManyToSave[relation].splice(addToSaveIndex, 1);
		}
		// Добавляем в список удаляемых
		this.hasManyToDelete[relation].push(record);
		// Убираем запись из массива для рендера
		const removeFromRelationIndex = this[relation].findIndex((i) => i.id === record.id);
		this[relation].splice(removeFromRelationIndex, 1);
		this[relation].totalCount--;

		// console.log('a: ', this.hasManyToSave, 'r:', this.hasManyToDelete);
	};

	// Когда сохраняем запись, мы должны дать новым и измененным реляцям forceAvailabilityId и сохранить
	// При этом удаленные реляции мы удаляем ( методом deleteRelations )
	saveRelations = async () => {
		for (const key of Object.keys(this.hasManyToSave)) {
			for (const record of this.hasManyToSave[key]) {
				record.forceAvailabilityId = this.record.id;
				await record.save();
			}
		}
	};
	deleteRelations = async () => {
		for (const key of Object.keys(this.hasManyToDelete)) {
			for (const record of this.hasManyToDelete[key]) await record.delete();
		}
	};
	// Когда мы не сохраняем запись, то чистим реляции что мы создали(без forceAvailabilityId)
	// Отредактированные и удаленные реляции просто не сохранятся
	deleteNotSavedRelations = async () => {
		for (const key of Object.keys(this.hasManyToSave)) {
			for (const record of this.hasManyToSave[key]) {
				!record.forceAvailabilityId && (await record.delete());
			}
		}
	};
}
