import { inject, observer } from 'mobx-react';
import React from 'react';
import { Table, Column } from '@smartplatform/ui';
import { SCHEDULE } from 'client/constants';
import { AddRowButton } from 'delivery/components';
import { SuppliesEditPopup } from './SuppliesEditPopup';
import t from 'i18n';
import { observable } from 'mobx';
import { format } from 'date-fns';
import store from 'client/store';
import { LOCALE } from 'client/tools';

@inject('store')
@observer
export class Supplies extends React.Component {
	@observable showPopup = false;
	@observable selectedRecord = null;

	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	openPopup = () => (this.showPopup = true);
	closePopup = async () => {
		this.showPopup = false;
		this.selectedRecord = null;
	};
	onRowClick = (row) => {
		this.selectedRecord = row;
		this.openPopup();
	};

	renderPlans = (plans) =>
		plans.map((plan) => (
			<div>
				{plan.date && format(new Date(plan.date), 'LLL yy', { locale: LOCALE[store.local.language] })} - {t('request.shipment')}: {plan.count}` -{' '}
				{t('countShort')}: {plan.quantity}`
			</div>
		));

	render() {
		const { supplies, disableEdit } = this.props.store;
		const type = this.props.store.record.type?.code;
		if (!type) return null;

		const columns = [
			{ relation: 'county', property: 'name', label: t('county.title') },
			{ relation: 'city', property: 'name', label: t('city.title') },
			{ relation: 'nomenclature', property: 'name', label: t('nomenclature.title') },
			{ relation: 'category', property: 'shortName', label: t('category') },
			{ property: 'quantity', label: t('quantity') },
			{ relation: 'period', property: 'name', label: t('quantity') },
			...(type === SCHEDULE ? [{ relation: 'plans', label: t('period.title'), computed: this.renderPlans, width: 300 }] : []),
		];

		return (
			<div className='supplies'>
				<Table rows={supplies} onRowClick={this.onRowClick}>
					{columns.map((column, index) => (
						<Column {...column} key={index} />
					))}
				</Table>
				{!disableEdit && <AddRowButton onClick={this.openPopup} />}
				{this.showPopup && <SuppliesEditPopup onClose={this.closePopup} type={type} selectedRecord={this.selectedRecord} />}
			</div>
		);
	}
}
