import { defaultsDeep } from 'lodash';
import { toJS } from 'mobx';
import store from 'client/store';
import { modelsConfig } from 'components';
import { PERIODS } from 'client/constants';

const layers = {
	store: 'fires',
	countries: {
		show: false,
	},
	counties: {
		show: false,
	},
	forestries: {
		show: false,
	},
	dzz: {
		show: false,
	},
	genShtab: {
		show: false,
	},
	technoZones: {
		show: false,
	},
	forestryQuarters: {
		show: false,
		minimized: false,
		colorful: false,
		opacity: 0.5,
	},
	fires: {
		show: true,
		minimized: false,
		status: {
			intensifies: true, // Усиливается
			continues: true, // Продолжается
			weakens: true, // Ослабевает
			notspreading: true, // Не распространяется
			localized: true, // Локализован
			resumed: true, // Возобновился
			extinguished: false, // Ликвидирован
			abandoned: false, // Прекращено тушение по решению КЧС
		},
	},
	heatPoints: {
		show: false,
		minimized: false,
		period: 0,
		useMVT: false,
		region: 14,
		selectRadius: 375,
	},
	weather: {
		show: false,
		minimized: false,
		layer: 'pm25',
	},
	wind: {
		show: false,
		minimized: false,
		fadeOpacity: 0.995,
		speedFactor: 5,
		particleLife: 128,
		opacity: 0.8,
		webgl: true,
	},
};

export const defaultLocalStorageConfig = {
	tileSource: '2gis',
	latestData: false,
	startDate: null,
	filtersMaximized: false,
	citiesTest: false,
	showLonLat: false,
	dynamicLayers: [],
	layers,
	card: {
		filtersMaximized: false,
		layers,
	},
	// миникарты в справочниках (пока что только в техногенных зонах)
	miniMap: {
		filtersMaximized: false,
		layers: {
			counties: layers.counties,
			forestryQuarters: layers.forestryQuarters,
			heatPoints: layers.heatPoints,
		},
	},
	raids: {
		page: 1,
		period: null,
		periodDate: null,
		startDate: null,
		endDate: null,
		querySearch: '',
	},
	registry: {
		page: 1,
		period: null,
		periodDate: null,
		startDate: null,
		endDate: null,
		querySearch: '',
		status: null,
		statuses: [],
		visibleFields: [],
	},
	isdm: {
		reportName: 'METEO',
	},
};

export default function initLocal() {
	layers.models = {};
	Object.values(modelsConfig)
		.filter((modelConfig) => !!modelConfig.showInFilters)
		.forEach((modelConfig) => {
			layers.models[modelConfig.modelName] = { show: false };
		});

	store.local.fires = defaultsDeep(toJS(store.local.fires), defaultLocalStorageConfig);
	store.local.save();
}
