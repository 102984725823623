import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { saveAs } from 'file-saver';
import { TextEncoder } from 'text-encoding';
import format from 'date-fns/format';

import { Button, Popover, Checkbox, DatePicker } from '@smartplatform/ui';
import { fioShort } from 'client/tools';
import store from 'client/store';
// import listStore from './store';

@inject('listStore') @observer
export default class Tracks extends React.Component {

	
	constructor(props) {
		super(props);
		this.listStore = props.listStore;
	}
	
	onTracksDateChange = date => {
		this.listStore.tracksDate = date;
		if (this.listStore.onTracksFilterChange) this.listStore.onTracksFilterChange();
	};
	
	onMobileTracksChange = value => {
		this.listStore.mobileTracks = value;
		if (this.listStore.onTracksFilterChange) this.listStore.onTracksFilterChange();
	};
	
	onMedvedTracksChange = value => {
		this.listStore.medvedTracks = value;
		if (this.listStore.onTracksFilterChange) this.listStore.onTracksFilterChange();
	};
	
	exportCSV = async () => {
		let exp = 'Дата; Широта; Долгота \n';
		this.listStore.tracks.forEach(record => {
			if (record.geo) {
				exp += format(new Date(record.date), 'dd.MM.yyyy') + '; '
					+ record.geo.coordinates[1].toFixed(5) + '; '
					+ record.geo.coordinates[0].toFixed(5)
					+ '\n';
			}
		});
		let csv = new TextEncoder('windows-1251', { NONSTANDARD_allowLegacyEncoding: true }).encode([exp]);
		let blob = new Blob([csv], {type: 'text/csv;charset=windows-1251;'});
		
		const date = format(this.listStore.tracksDate, 'dd.MM.yyyy');
		const name = this.listStore.case.lastName ? fioShort(this.listStore.case) : (this.listStore.case.phone || this.listStore.case.id)
		saveAs(blob, `треки-${name}-${date}.csv`);
	};
	
	find = async () => {
		if (!this.listStore.case) return;
		const { error, result } = await this.listStore.case.requestLocation();
		console.log({ error, result });
		if (error) {
			this.listStore.error = error;
		}
		else {
			this.listStore.tracksDate = new Date();
			this.listStore.onTracksFilterChange(false);
			this.listStore.map.gotoPoint(result, 17);
		}
	};
	
	render() {
		return <div className="tracks-filter form-field">
			<label>Треки</label>
			<div>
				<DatePicker value={this.listStore.tracksDate} onChange={this.onTracksDateChange} />
				<Button className="btn-sm btn-primary" onClick={this.exportCSV} disabled={this.listStore.tracks.length === 0}>Экспорт</Button>
{/*
				<Popover content={this.listStore.case.mobileStatusId === 3 ? 'Определить текущее местоположение' : 'Статус не подтвержден'}>
					<Button className="btn-sm btn-primary" onClick={this.find} disabled={!this.listStore.case || this.listStore.case.mobileStatusId !== 3}>Найти</Button>
				</Popover>
*/}
			</div>
			<div>
				<Checkbox value={this.listStore.mobileTracks} onChange={this.onMobileTracksChange}/>моб.оператор &nbsp;
				<Checkbox value={this.listStore.medvedTracks} onChange={this.onMedvedTracksChange}/>
				{store.config.medved || 'облачная поликлиника'}
			</div>
		</div>;
	}
	
}
