// include single

export const COUNTIES_SCOPE = {
	include: [
		{ relation: 'status', scope: { fields: ['id', 'name', 'color'] } },
		{ relation: 'cities', scope: { fields: ['id', 'name'] } },
	],
	fields: ['id', 'name', 'citiesCount', 'statusId', 'population', 'area', 'statusName', 'risks'],
};

export const RESOURCES_SCOPE = {
	include: [
		{ relation: 'tier', scope: { fields: ['id', 'name'] } },
		{ relation: 'vehicles', scope: { fields: ['id'] } },
		{
			relation: 'division',
			scope: { fields: ['id', 'name', 'contacts', 'groupId'], include: [{ relation: 'group', scope: { fields: ['id', 'name'] } }] },
		},
	],
	fields: ['id', 'tierId', 'personnelCount', 'divisionId'],
};

export const EMERGENCY_SITUATION_PLAN_INCLUDE = {
	include: [
		'planFiles',
		'correctionPlanFiles',
		{ relation: 'explanatoryNoteFiles' },
		{ relation: 'status', scope: { fields: ['id', 'name', 'color', 'code'] } },
		{ relation: 'level', scope: { fields: ['id', 'name'] } },
		{ relation: 'county', scope: { fields: ['id', 'name', 'geo'] } },
		{
			relation: 'members',
			scope: {
				include: [
					{ relation: 'status', scope: { fields: ['id', 'color', 'name'] } },
					{ relation: 'role', scope: { fields: ['id', 'name', 'code'] } },
					{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'organization', scope: { fields: ['id', 'name'] } },
					{ relation: 'comment', scope: { include: ['attachments'] } },
				],
			},
		},
	],
	fields: ['id', 'date', 'statusId', 'riskCategoryId', 'levelId', 'countyId', 'explanatoryNote'],
};

export const MODEL_NAME = 'EmergencySituationPlan';
export const RELATIONS = ['members', 'explanatoryNoteFiles'];
