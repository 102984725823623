import React from 'react';
import { observer } from 'mobx-react';

import { Map, Popup } from '@smartplatform/map/client';
import store from 'client/store';
import Filters from './filters';
import map from './store';
import { MainPopup, WeatherScale } from 'components';

const PARAMS = {
    zoom: 5,
    minZoom: 1,
    maxZoom: 20,
    center: [ 128.74929854276843, 63.27207348590292 ], // Якутия
};

@observer
export default class DeliveryMap extends React.Component {

    onLayerToggle = (layers) => {
        store.local.delivery.dynamicLayers = layers;
        store.local.save();
    };
    
    render() {
        const { popup, mapInitialized, onMapInit, layers, onTileSourceChange } = map;

        const visibleLayers = store.local.delivery.dynamicLayers;
    
        const mapParams = {
            ...PARAMS,
            onTileSourceChange,
            defaultTileSource: store.local.delivery.tileSource,
        };
    
        const mapProps = {
            className: 'delivery-map full-map',
            onInit: onMapInit,
            params: mapParams,
            modelStore: store.model,
            visibleLayers,
            onLayerToggle: this.onLayerToggle,
        };

        return <Map {...mapProps}>
            {popup && <Popup {...popup} width={popup.width || 550}>
                <MainPopup {...popup} />
            </Popup>}
            {mapInitialized && <>
                <Filters />
                {layers.weather && <WeatherScale onMount={layers.weather.onWeatherScaleMount} hidden={!store.local.delivery.layers.weather.show} />}
            </>}
        </Map>;
    }
}
