import { DeleteButton as _DeleteButton } from 'components';
import React from 'react';
import { ActionbarContext } from './Actionbar';
import t from 'i18n';

export const DeleteButton = ({ onDelete, noDelete, text }) => {
	const context = React.useContext(ActionbarContext);
	const _onDelete = async () => {
		if (!noDelete) {
			await context.form.delete();
		}
		onDelete?.();
	};

	return <_DeleteButton onConfirm={_onDelete} text={text || t('delete')} />;
};
