import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Button, NumberInput, Row } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';

@inject('fireDepStore')
@observer
export class RenderData extends Component {
	@observable isAdding = false;
	@observable fireDep = null;

	prevValue = null;
	property;

	constructor(props) {
		super(props);
		this.property = props.property;
		this.fireDep = props.fireDepStore.fireDep;
		this.text = this.property.at(0).toUpperCase() + this.property.slice(1);
	}

	onSave = async () => {
		await this.fireDep.save([`${this.property}`]);
		this.isAdding = false;
	};

	addStaffUnit = () => {
		this.prevValue = this.fireDep[this.property];
		this.isAdding = true;
	};

	onPhoneChange = (number) => {
		this.fireDep[this.property] = number;
	};

	cancelAddingPhone = () => {
		this.fireDep[this.property] = this.prevValue;
		this.isAdding = false;
	};

	render() {
		return (
			<div>
				{this.isAdding ? (
					<Row>
						<NumberInput placeholder={t(`fireDep.enter${this.text}`)} onChange={this.onPhoneChange} value={this.fireDep[this.property]} />
						<>
							<Button variant='primary' onClick={this.onSave}>
								{t('save')}
							</Button>
							<Button onClick={this.cancelAddingPhone}>{t('cancel')}</Button>
						</>
					</Row>
				) : (
					<>
						<div>{this.fireDep[this.property]}</div>
						<Button onClick={this.addStaffUnit} variant='primary'>
							{this.fireDep[this.property] ? t('edit') : t(`fireDep.add${this.text}`)}
						</Button>
					</>
				)}
			</div>
		);
	}
}
