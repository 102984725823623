import React from 'react';
import { inject, observer } from 'mobx-react';
import { Toolbar as BaseToolbar } from 'components';
import { Filters } from './Filters';
import './style.scss';
import { RecordSelect } from '@smartplatform/ui';
import appStore from 'client/store';
import t from 'i18n';

@inject('store')
@observer
export default class Toolbar extends React.Component {
	render() {
		const { onSearch, search, showFilters, onShowFiltersChange, period, onChange } = this.props.store;
		return (
			<div className='oper-info-toolbar'>
				<div className='top'>
					<BaseToolbar>
						<BaseToolbar.SearchIconInput value={search} onChange={onSearch} />
						<BaseToolbar.FilterButton showFilters={showFilters} onClick={onShowFiltersChange} />
						<RecordSelect
							model={appStore.model.RequestPeriod}
							value={period}
							property='name'
							onChange={onChange('period')}
							placeholder={t('period')}
							width={200}
						/>
					</BaseToolbar>
				</div>
				{showFilters && <Filters store={this.props.store} />}
			</div>
		);
	}
}
