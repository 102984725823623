import t from 'i18n';

const renderName = (r) => r.name;
const renderShortNames = (r) => r.map((r) => r.shortName).join(', ');

export const DICTIONARIES_MODELS = [
	{
		title: t('dictionaries.group.norm'),
		items: [
			{ modelName: 'ForestUseType' },
			{ modelName: 'CombustionType' },
			{ modelName: 'AirJobType' },
			{ modelName: 'StaffPosition' },
			{ modelName: 'MonitoringZone' },
			{ modelName: 'ForestCategory' },
			{ modelName: 'LandCategory' },
			{ modelName: 'Breed' },
			{ modelName: 'NotLandingReason' },
			{ modelName: 'NotInspectionReason' },
			{ modelName: 'FireStatus' },
			{ modelName: 'FireCause' },
			{ modelName: 'Cover' },
			{ modelName: 'DeterminingMethod' },
		],
	},
	{
		title: t('dictionaries.group.weather'),
		items: [{ modelName: 'WeatherScaleValue' }, { modelName: 'WeatherStation' }],
	},
	{
		title: t('dictionaries.group.region'),
		items: [
			{
				modelName: 'AviaDep',
				listProperties: ['code', 'name', 'shortName', 'area', 'active'],
				listRelations: [{ relation: 'location', computed: renderName, properties: ['name'] }],
				editProperties: ['code', 'name', 'shortName', 'area', 'active'],
				editRelations: [{ relation: 'location', computed: renderName, properties: ['name'] }],
			},
			{
				modelName: 'Forestry',
				listRelations: [{ relation: 'forestOwnership', computed: renderName, properties: ['name'] }],
				editRelations: [{ relation: 'forestOwnership', computed: renderName, properties: ['name'] }],
			},
			{ modelName: 'Tenant' },
			{
				modelName: 'CrewType',
				listRelations: [{ relation: 'formations', computed: renderShortNames, properties: ['shortName'] }],
			},
			{ modelName: 'ExtinguishingAgentsType', editRelations: [] },
			{
				modelName: 'City',
				listProperties: ['okato', 'name', 'population', 'area', 'fireDanger'],
				listRelations: [{ relation: 'county', computed: renderName, properties: ['name'] }],
				editProperties: ['okato', 'name', 'population', 'area', 'fireDanger', 'geo'],
				editRelations: [{ relation: 'county', computed: renderName, properties: ['name'] }],
			},
			{ modelName: 'DiscoveryMessageType' },
			{ modelName: 'FireFightingFormation' },
		],
	},

	{
		title: t('dictionaries.group.other'),
		items: [
			{ modelName: 'ForestryQuarter' },
			{ modelName: 'ForestryPrecinct' },
			{ modelName: 'Unit' },
			{ modelName: 'AircraftType' },
			{ modelName: 'NotifyRecipient' },
			{ modelName: 'NotifyRecipientGroup' },
			{ modelName: 'FireReport' },
			{ modelName: 'Airfield' },
			{ modelName: 'County' },
			{ modelName: 'TechnoZone' },
			{ modelName: 'PaymentGroup' },
			{ modelName: 'EmergencySituationType' },
			{ modelName: 'ForestOwnership' },
			{ modelName: 'CombustionIntensity' },
			{ modelName: 'CombustionPattern' },
			{ modelName: 'Aircraft' },
			{ modelName: 'VehicleType' },
			{ modelName: 'IsdmFire' },
			{ modelName: 'ExtinguishingAgentsGroup' },
		],
	},
	{
		title: t('dictionaries.group.air'),
		items: [
			{ modelName: 'AircraftKind' },
			{ modelName: 'Staff' },
			{
				modelName: 'Organization',
				listProperties: ['name', 'contractor'],
				listRelations: [{ relation: 'type', property: 'name' }],
				editProperties: ['name', 'contractor'],
				editRelations: [{ relation: 'type', property: 'name' }],
			},
			{ modelName: 'Contract' },
		],
	},
];

export const EXCLUDED_PROPERTIES = ['id', 'createdAt', 'updatedAt', 'externalCode'];
