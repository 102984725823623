import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, Route, Switch } from 'react-router-dom';

import { Panel } from 'components';
import Categories from './categories';
import Statuses from './statuses';
import Systems from './systems';

const MENU_WIDTH = 200;
export const MENU_MARGIN = MENU_WIDTH + 10;

@observer
export default class Dictionaries extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <Panel full className="dictionaries-root">
			<div className="dictionaries-menu">
				<Link to="/appeals/dictionaries/categories">Категории</Link>
				<Link to="/appeals/dictionaries/statuses">Состояния обращений</Link>
				<Link to="/appeals/dictionaries/systems">Внешние системы</Link>
			</div>
			<div className="admin-content">
				<Switch>
					<Route path="/appeals/dictionaries" exact component={Categories}/>
					<Route path="/appeals/dictionaries/categories" component={Categories}/>
					<Route path="/appeals/dictionaries/statuses" component={Statuses}/>
					<Route path="/appeals/dictionaries/systems" component={Systems}/>
				</Switch>
			</div>
		</Panel>;
	}

}
