import React from 'react';
import Root from './Root';
import store from 'client/store';
import SystemSelect from './SystemSelect';
import MapIcon from '!!svg-react-loader!img/icons/sidebar/shared/map.svg';
import RegistryIcon from '!!svg-react-loader!img/icons/sidebar/shared/registry.svg';
import DictionaryIcon from '!!svg-react-loader!img/icons/sidebar/shared/dictionary.svg';
import AppealsIcon from '!!svg-react-loader!img/icons/menu/appeals.svg';

export default {
	name: 'Обращения граждан',
	code: 'appeals',
	icon: <AppealsIcon />,
	path: '/appeals',
	component: Root,
	access: () => store.model.Appeal && store.model.Appeal.INFO.WRITE,
	menu: [
		{ title: 'Карта', path: '/appeals', icon: <MapIcon />, exact: true },
		{ title: 'Реестр', path: '/appeals/kanban', icon: <RegistryIcon /> },
		{ title: 'Справочники', path: '/appeals/dictionaries', icon: <DictionaryIcon /> },
	],
	topPanel: <SystemSelect />,
};
