import { Column, RelationSelect, Select, Table } from '@smartplatform/ui';
import React from 'react';
import t from 'i18n';
import { Attachments } from 'components';
import { renderStatus } from 'client/tools';
import store from 'client/store';

export class Cities extends React.Component {
	onRowClick = (row, index) => this.props.moveToCity(index);
	renderFile = (city) =>
		this.props.statusNotReady && city.sufficiencyStatusId === this.props.statusNotReady.id && <Attachments record={city} canUpload={true} />;
	renderRisks = (city) => {
		let risks = new Set(city.risks().map((i) => i.category?.name));
		risks = [...risks].filter(Boolean);
		return (
			<div>
				{risks.map((risk) => {
					return <div>{risk}</div>;
				})}
				<a
					style={{ fontWeight: 'bold', display: 'block', margin: '3px 0' }}
					onClick={() => store.route.push({ path: `/emergency/dictionaries/city/${city.id}/risks` })}
				>
					редактировать риски
				</a>
			</div>
		);
	};
	render() {
		const { onRowClick, props } = this;
		const { cities } = props;

		return (
			<div className='cities'>
				<h2>{t('city.plural')}</h2>
				<Table rows={cities} onRowClick={onRowClick}>
					<Column property='name' label={t('municipalFormation.city')} width={170} />
					<Column relation='sufficiencyStatus' label={t('municipalFormation.sufficiencyStatusFull')} width={230} saveOnChange>
						<RelationSelect noSearch isRequired property='name' position='bottomLeft' noTotal maxItems={10} itemsPerPage={1000} computed={renderStatus} />
					</Column>
					<Column label={t('file.title')} computed={this.renderFile} width={200} />
					<Column label={t('risks')} computed={this.renderRisks} width={200} />
				</Table>
			</div>
		);
	}
}
