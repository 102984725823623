import store from 'client/store';

export const shortenAddressName = (str) => {
	if (!str) return '';
	if (store.config.shortenAddressReplaces) {
		str = str.replace(/^[\d]{6},\s+/, '');
		for (let replacement of store.config.shortenAddressReplaces) {
			const r = new RegExp(replacement + ', ', 'i');
			str = str.replace(r, '');
		}
	}

	return str;
}
