import React from 'react';
import './style.scss';

export const fireDepVehicleItem = (vehicle) => {
	if (!vehicle) return '-';
	return <div className="firedep-vehicle-item">
		{/*<div className="vehicle-number">{vehicle.stateNumber || '---'}<div className="flag"><div/><div/><div/></div></div>*/}
		{vehicle.type && <div className="vehicle-type">{vehicle.type.name}</div>}
		{vehicle.callSign && <div className="call-sign">{vehicle.callSign.name}</div>}
		<div className="state-number">{vehicle.stateNumber || '---'}</div>
	</div>;
};

export const fireDepVehicleTypeItem = (record) => {
	return <div className="firedep-vehicle-type">
		<strong>{record.name}</strong>
		<em>{record.specialType?.name || '-'}</em>
	</div>;
};

export const fireDepSpecialTypeItem = (record) => {
	return record.specialType?.name || '-';
};
