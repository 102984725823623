import React from 'react';
import { FormContext } from '@smartplatform/ui';
import { DeleteButton } from './DeleteButton';
import { SaveButton } from './SaveButton';
import { CancelButton } from './CancelButton';
import { CleanButton } from './CleanButton';
export const ActionbarContext = React.createContext({});

export class Actionbar extends React.Component {
	static contextType = FormContext;
	render() {
		return (
			<ActionbarContext.Provider value={this.context}>
				<div className='custom-action-bar'>{this.props.children}</div>
			</ActionbarContext.Provider>
		);
	}
}

Actionbar.CleanButton = CleanButton;
Actionbar.SaveButton = SaveButton;
Actionbar.CancelButton = CancelButton;
Actionbar.DeleteButton = DeleteButton;
