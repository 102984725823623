/**
 * Возвращает самое часто встречающееся значение свойства в массиве объектов.
 * @param objectsArray массив объектов
 * @param property свойство объекта
 * @return {null|*} значение свойства или null
 */

export const getMostRepeatedElement = (objectsArray, property) => {
	if (objectsArray.length === 0) return null;

	const valuesMap = {};
	let maxEl = objectsArray[0];
	let maxCount = 1;

	for (let i = 0; i < objectsArray.length; i++) {
		const element = objectsArray[i];
		let value = objectsArray[i][property];
		if (!valuesMap[value]) {
			valuesMap[value] = 1;
		}
		else {
			valuesMap[value]++;
		}

		if (valuesMap[value] > maxCount) {
			maxEl = element;
			maxCount = valuesMap[value];
		}
	}
	return maxEl;
};
