export const toDecimal = (str) => {
	const [ hours, minutes, seconds ] = str.replace(/_/g, '0').slice(0, -1).split(/[^0-9-+]/).map(v => parseInt(v));
	// console.log('toDecimal', { hours, minutes, seconds }, 'str', str);
	let decimal = hours || 0;
	if (minutes) decimal += minutes / 60;
	if (seconds) decimal += seconds / 3600;
	return decimal;
};

export const toDegrees = (value, type) => {
	const pad = type === 'longitude' ? 3 : 2;
	const hours = Math.abs(Math.floor(value));
	const x = Math.round((value - hours) * 3600);
	const minutes = Math.floor(x / 60);
	const seconds = Math.round((x / 60 - minutes) * 60);
	// console.log('toDegrees', { hours, minutes, seconds });
	let str = String(hours).padStart(pad, '0') + '°';
	str += String(minutes).padStart(2, '0') + '\'';
	str += String(seconds).padStart(2, '0') + '\'';
	str = (value > 0 ? '+' : '-') + str;
	return str;
};
