export { default as RowAddButton } from './RowAddButton';
export { default as RowDeleteButton } from './RowDeleteButton';
import { IconButton } from './IconButton';
import t from 'i18n';
import './style.scss';
import { faPlus,faCaretLeft, faCaretRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const makeIconButton = (icon, text, className) => (props) => <IconButton icon={icon} text={text} className={className} {...props} />;
export const AddButton = makeIconButton(faPlus, t('add'));
export const CaretLeftButton = makeIconButton(faCaretLeft);
export const CaretRightButton = makeIconButton(faCaretRight);
export const CancelButton = makeIconButton(faTimes, t('cancel'), 'cancel-button');
