import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SingleCargo } from './single-cargo/SingleCargo';
import Tracking from "./Tracking";

export default (props) => {
	const { path } = props.match;
	return (
		<Switch>
			<Route path={path} exact children={() => <Tracking />} />
			<Route path={`${path}/:id`} children={({ match }) => <SingleCargo match={match} path={path} />} />
		</Switch>
	);
};
