import React from 'react';
import { observer } from 'mobx-react';

import t from 'i18n';
import '../style.scss';

import { Button, Popconfirm, Row } from '@smartplatform/ui';
import { DeleteBtn } from 'components';

@observer
export default class ChiefSingle extends React.Component {
	delete = async () => {
		await this.props.record.delete();
		this.props.onUpdate && this.props.onUpdate();
	};

	render() {
		const { record, onEdit } = this.props;
		return (
			<div className='casualty-card'>
				<Row>
					<div className='form-field'>
						<label>{t('fireDep.chief')}</label>
						{record.text}
					</div>
					<div />
					<div />
					<div className='main-actions'>
						<label>&nbsp;</label>
						<Button onClick={() => onEdit(record)} variant='primary'>
							{t('edit')}
						</Button>{' '}
						{record.id && (
							<Popconfirm onConfirm={this.delete} content='Удалить?'>
								<Button>{t('delete')}</Button>
							</Popconfirm>
						)}
					</div>
				</Row>
			</div>
		);
	}
}
