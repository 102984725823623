import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const className = 'form-field';
		const report = this.props.report;
		const selectProps = { property: 'name', noTotal: true, maxItems: 10, itemsPerPage: 1000, filter: { fields: ['id', 'name'] } };
		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className={className}>
							<label>{t('county.title')}</label>
							<RecordSelect x model={store.model.County} value={report.county} onChange={report.onCountyChange} {...selectProps} />
						</div>
					</div>
					<div className='col-3'>
						<div className={className}>
							<label>{t('forestOwnership.title')}</label>
							<RecordSelect model={store.model.ForestOwnership} value={report.ownership} onChange={report.onOwnershipChange} x {...selectProps} />
						</div>
					</div>

					<div className='col-3'>
						<div className={className}>
							<label>{t('date')}</label>
							<DatePicker value={report.date} onChange={report.onDateChange} showTimeSelect={false} showClearButton={false} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
