export const MENU_ITEMS = [
	{
		title: 'Отчетность по лесным пожарам, детальная',
		type: 'submenu',
		items: [
			'FiresOnCountyAndDate',
			'FireCard',
			'ExtinguishedFires',
			'FiresOnDate',
			'CumulativeFires',
			'DetailedFireSituation',
			'FireAct',
			'FireRegistryOnDate',
		],
	},
	{
		title: 'Сведения о лесных пожарах по районам',
		type: 'submenu',
		items: ['FireByArea', 'FireByMonitoringZone', 'FireByCauseAndWorker'],
	},
	{
		title: 'Сведения о пожарной опасности в лесах и лесных пожаров',
		type: 'submenu',
		items: ['LO1Section1', 'LO1Section2', 'LO1Section3', 'LO1Section4', 'LO2', 'LO3'],
	},

	{
		title: 'Другие отчеты',
		type: 'submenu',
		items: [
			'DetectionMethods',
			'ExtinguishingCosts',
			'ExtinguishingVehiclesAndPeoples',
			'CityThreat',
			'FireAreaCount',
			'RequestLog',
			'ForestryHarm',
			'PeoplesAndVehiclesByDate',
			'AviaDepByCause',
		],
	},
];
