import React, { Component } from 'react';
import './style.scss';
import classNames from "classnames";

export class Card extends Component {
	render() {
		const { icon, title, width, children, contentPadding, className } = this.props;
		const withHeader = !!icon || !!title;
		const _className = classNames('delivery-card', className);
		return (
			<div className={_className} style={{ width }}>
				{withHeader && (
					<div className='delivery-card__header'>
						<span className='icon'>{icon}</span>
						<span className='title'>{title}</span>
					</div>
				)}
				<div className='content' style={{ padding: contentPadding, borderRadius: withHeader ? undefined : '8px' }}>
					{children}
				</div>
			</div>
		);
	}
}
