import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Select } from '@smartplatform/ui';
import { Panel } from 'components';
import AvgCategories from './AvgCategories';
import AvgOrganizations from './AvgOrganizations';
import OrganizationsCount from './OrganizationsCount';
import HeatMap from './HeatMap';
import appealsStore, { EVENTS } from '../store';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

const MODE_CAT_AVG = 'catAvg';
const MODE_ORG_AVG = 'orgAvg';
const MODE_ORG_COUNT = 'orgCount';
const MODE_HEATMAP = 'heatmap';

@observer
export default class Statistics extends React.Component {

	@observable mode = MODE_HEATMAP;//MODE_CAT_AVG;

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		appealsStore.subscribe(EVENTS.SYSTEM_CHANGED, this.onSystemChange);
	}

	componentWillUnmount() {
		appealsStore.unsubscribe(EVENTS.SYSTEM_CHANGED, this.onSystemChange);
	}

	onSystemChange = () => {
		if (appealsStore.system.id === 3 && (this.mode === MODE_ORG_AVG || this.mode === MODE_ORG_COUNT)) {
			this.mode = MODE_HEATMAP;
		}
		else if (appealsStore.system.id === 5 && (this.mode === MODE_HEATMAP || this.mode === MODE_CAT_AVG)) {
			this.mode = MODE_ORG_AVG;
		}
	};

	onModeChange = (mode) => this.mode = mode;

	render() {
		let items = [
				{ label: t(`stats.${MODE_CAT_AVG}`), value: MODE_CAT_AVG },
				{ label: t(`stats.${MODE_ORG_AVG}`), value: MODE_ORG_AVG },
				{ label: t(`stats.${MODE_ORG_COUNT}`), value: MODE_ORG_COUNT },
				{ label: t(`stats.${MODE_HEATMAP}`), value: MODE_HEATMAP },
		];

		if (appealsStore.system.id === 3) {
			items = [
				{ label: t(`stats.${MODE_CAT_AVG}`), value: MODE_CAT_AVG },
				{ label: t(`stats.${MODE_HEATMAP}`), value: MODE_HEATMAP },
			];
		}
		else if (appealsStore.system.id === 5) {
			items = [
				{ label: t(`stats.${MODE_ORG_AVG}`), value: MODE_ORG_AVG },
				{ label: t(`stats.${MODE_ORG_COUNT}`), value: MODE_ORG_COUNT },
			];
		}

		return <Panel left={435} top={store.ui.sideBarBtnSize + 345} width={500} bottom={0} height="auto" header={t(`stats.title`)} className="statistics" scrollable={true}>
			<div className="mode-select">
				<Select noTotal maxItems={10} itemsPerPage={1000}
					items={items}
					value={this.mode}
					onChange={this.onModeChange}
					className="drop-down-md"
					// disabled={appealsStore.system.id === 3}
					noSearch
					isRequired
					width="100%"
				/>
			</div>
			<div className="items-list">
				{this.mode === MODE_CAT_AVG && <AvgCategories />}
				{this.mode === MODE_ORG_AVG && <AvgOrganizations />}
				{this.mode === MODE_ORG_COUNT && <OrganizationsCount />}
				{this.mode === MODE_HEATMAP && <HeatMap />}
			</div>
		</Panel>;
	}
	
}
