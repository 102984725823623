import React from 'react';
import { Toolbar, CleanFiltersButton, DateRangePicker, CleanButton } from 'components';
import { Button, RecordSelect } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import appStore from 'client/store';
import t from 'i18n';
import { FieldsEditPopup } from './FieldsEditPopup';
import './style.scss';
@inject('store')
@observer
export class Filters extends React.Component {
	renderStatus = (status) => (
		<div>
			<span className='status-color' style={{ background: status.color || '#808080' }} />
			<span>{t('fireStatus.codes.' + status.code)}</span>
		</div>
	);

	render() {
		const store = this.props.store;
		const { search, onSearch, registyDownloadUrl, downloadLO1, cleanFilters, dateRangeValues, reload, renderFields } = this.props.store;

		const showStatusesValue =
			store.statuses.length > 0 ? t('fireStatus.selected') + ': ' + store.statuses.length : <span className='hint'>{t('fireStatus.plural')}</span>;
		return (
			<>
				<div className='filters'>
					<div className='top'>
						<Toolbar>
							<Toolbar.AddButton />
							<Toolbar.SearchIconInput value={search} onChange={onSearch} />
							<Toolbar.ExportButton text={t('downloadExcel')} exportUrl={registyDownloadUrl} />
							<Toolbar.ExportButton text={t('report.downloadLO1')} onClick={downloadLO1} />
						</Toolbar>
					</div>
					<div className='filters-bottom'>
						<DateRangePicker dateValues={dateRangeValues} onChange={reload} />
						<RecordSelect
							width={280}
							model={appStore.model.FireStatus}
							value={store.statuses}
							onChange={store.onStatusToggle}
							computed={this.renderStatus}
							showValue={showStatusesValue}
							className='statuses-select'
							noSearch
						/>
						<CleanFiltersButton onClick={cleanFilters} />
						<CleanButton onClick={() => store.toggleFieldsPopup(true)} text={`${t('fire.fieldsSelected')}: ${renderFields.length}`} />
					</div>
				</div>
				{store.showFieldsPopup && <FieldsEditPopup />}
			</>
		);
	}
}
