import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, Field, Form, Row, Tab, Tabs } from '@smartplatform/ui';
import t from 'i18n';
import { FormFieldDisabled } from '../helper';
import { formatDate } from 'client/tools';
import { REQUEST_PATH } from 'delivery/tracking/constants';
import LadingBill from 'delivery/tracking/single-cargo/main-info/lading-bill/LadingBill';
import store from 'client/store';

@inject('store')
@observer
export class Main extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	onChange = (value) => (this.store.record.request.actualDeliveryDate = value);

	render() {
		const { record } = this.store;
		const { isLoading, departureCity, destinationCity, deliveryDate } = record;
		const path = `/delivery/tracking/${record.id}/main`;

		if (isLoading) return null;

		return (
			<Form record={record} returnTo={REQUEST_PATH} noDelete>
				<Row>
					<FormFieldDisabled labelName={t('tracking.cityFrom')} value={departureCity?.name} />
					<FormFieldDisabled labelName={t('tracking.cityTo')} value={destinationCity?.name} />
				</Row>
				<Row>
					<FormFieldDisabled labelName={t('tracking.planedDeliveryDate')} value={formatDate(deliveryDate)} />
					<Field label={t('tracking.actualDeliveryDate')} property='actualDeliveryDate'>
						<DatePicker showClearButton={false} />
					</Field>
				</Row>
				<hr />
				{!!record.ladingBills().length && (
					<Tabs>
						{record.ladingBills().map((ladingBill, i) => (
							<Tab
								key={ladingBill.id}
								title={`${t('ladingBill.short')} ${i + 1}`}
								path={`${path}/${i + 1}`}
								render={() => <LadingBill ladingBill={ladingBill} />}
							/>
						))}
					</Tabs>
				)}
			</Form>
		);
	}
}
