import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import { Loader } from '@smartplatform/ui';

/**
 * Пример кастомного попапа для слоя районов.
 */

@observer
export default class CountyPopup extends React.Component {
	
	@observable fetchedData = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.fetchedData = await store.model.County.findById(this.props.county.id);
	};
	
	render() {
		const { county } = this.props;

		return <div>
			<div>{county.name}</div>
			{this.fetchedData ? <div>
					Население: {this.fetchedData.population}
				</div>
				:
				<Loader size={18} />
			}
		</div>;
	}
}
