import React from 'react';
import { observer } from 'mobx-react';
import { Toolbar } from 'components';

import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';
import { Column } from '@smartplatform/ui';
import Filters from './Filters';

const FILTER = {
	include: [
		{
			relation: 'object',
			scope: {
				include: [
					{ relation: 'rank', scope: { fields: ['id', 'name'] } },
					{ relation: 'fireDep', scope: { fields: ['id', 'name'] } },
				],
			},
		},
		{ relation: 'status' },
		{ relation: 'rank' },
		{ relation: 'source' },
	],
};

@observer
export default class Fires extends React.Component {
	constructor(props) {
		super(props);
	}

	addTechFire = () => store.route.push({ path: `/techfires/fires/create` });

	renderObjectRelation =
		(relation) =>
		(property) =>
		({ record }) => {
			return record.object?.[relation]?.[property] || '-';
		};

	render() {
		return (
			<div className='techfires-list'>
				<h1>{t('techFire.fires.title')}</h1>
				<div className='techfires-toolbar'>
					<Toolbar>
						<Toolbar.AddButton onClick={this.addTechFire} />
						<Toolbar.SearchIconInput />
						<Toolbar.ExportButton />
					</Toolbar>
				</div>
				<Filters />

				<ModelList model={store.model.TechFire} noHeader noFilters filter={FILTER} onCreateTemp={this.addTechFire} match={this.props.match}>
					<Column property='id' label={t('id')} width={80} />
					<Column property='reportTime' label={t('techFire.messageFireDep')} width={140} />
					<Column relation='object' property='name' label={t('techFireAddress.title')} />
					<Column relation='source' property='description' label={t('techFireReportSource.title')} width={160} />
					<Column relation='status' property='name' label={t('status')} width={160} />
					<Column relation='rank' property='name' label={t('status')} width={160} />
					<Column label={t('techFireAddress.fireDep')} width={120}>
						{this.renderObjectRelation('fireDep')('name')}
					</Column>
					<Column relation='object' property='ptp' label={t('techFireAddress.ptp')} width={100} />
					<Column relation='object' property='ktp' label={t('techFireAddress.ktp')} width={100} />
					<Column relation='emergencyCode' property='code' label={t('techFireEmergencyCode.title')} width={160} />
					<Column property='destroyedBuildings' label={t('techFire.destroyedBuildings')} />
				</ModelList>
			</div>
		);
	}
}
