import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Button, Row, Field } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class ObjectCard extends React.Component {
	// onKtpFileChange = async (e) => {
	// 	const { object } = this.props;
	// 	const file = e.target.files[0];
	// 	object.ktpFile = file.name;
	// 	await object.save();
	// 	await object.uploadFile('ktpFile', file);
	// };

	render() {
		const { object, value, onEdit, onObjectSelect } = this.props;
		console.log('object.downloadFile', object.ptpFile);
		return (
			<div className='address-object-card'>
				<div className='object-controls'>
					{onObjectSelect && (
						<Button variant='primary' size='sm' disabled={value && value.id === object.id} onClick={() => onObjectSelect(object)}>
							{t('techFireAddress.select')}
						</Button>
					)}
					<Button variant='primary' size='sm' onClick={() => onEdit(object)}>
						{t('edit')}
					</Button>
				</div>
				<div className='form-fields'>
					<div className='form-field'>
						<label>{t('techFireAddress.name')}</label>
						{object.name}
					</div>
					<div className='form-field'>
						<label>{t('techFireAddress.description')}</label>
						{object.description || '-'}
					</div>
					<div className='form-field'>
						<label>{t('techFireRank.title')}</label>
						{object.rank ? object.rank.name : '-'}
					</div>
					<div className='form-field'>
						<label>{t('techFireAddress.ptp')}</label>
						{object.ptp || '-'}
						{object.ptpFile && (
							<>
								{' '}
								<a target='_blank' href={object.downloadFile('ptpFile')}>
									{object.ptpFile}
								</a>{' '}
							</>
						)}
					</div>
					<div className='form-field'>
						<label>{t('techFireAddress.ktp')}</label>
						{object.ktp || '-'}
						{object.ktpFile && (
							<>
								{' '}
								<a target='_blank' href={object.downloadFile('ktpFile')}>
									{object.ktpFile}
								</a>{' '}
							</>
						)}
						{/* <input type='file' onChange={this.onKtpFileChange} accept='application/pdf, image/jpeg, image/png' /> */}
					</div>
				</div>
			</div>
		);
	}
}
