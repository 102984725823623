import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Column, RecordSelect, Table } from '@smartplatform/ui';
import { fio } from 'client/tools';
import { renderStatus } from 'client/tools';
import store from 'client/store';
import { observable } from 'mobx';
import { CommentPopup } from './CommentPopup';

const STATUSES = ['revision', 'disagree'];
@inject('store')
@observer
export class Members extends React.Component {
	@observable status = null;
	@observable selectedMember = null;
	@observable comment = null;

	onClose = () => {
		this.status = null;
		this.selectedMember = null;
		this.comment = null;
	};

	onCommentSave = async (comment) => {
		this.selectedMember.comment = comment;
		await this.onStatusChange(this.status, this.selectedMember, true);
		await this.props.store.record.comments.add(comment.id);
		comment = await store.model.Comment.findById(comment.id, { include: ['owner'] });
		const index = this.props.store.comments.findIndex(({ record }) => record.id === comment.id);
		if (index === -1) {
			this.props.store.comments.push({ type: 'comment', record: comment });
		} else {
			this.props.store.comments[index].record = comment;
		}
		this.onClose();
	};

	onCommentClick = (member) => () => {
		this.comment = member.comment || new store.model.Comment();
		this.selectedMember = member;
	};

	onStatusChange = async (status, member, skip = false) => {
		if (!skip && STATUSES.includes(status.code)) {
			this.comment = new store.model.Comment();
			this.selectedMember = member;
			this.status = status;
			return;
		}

		if (status) {
			member.status = status;
			member.statusId = status.id;
		}

		await member.save();
		if (status) {
			this.props.store.comments.push({
				type: 'status',
				record: new store.model.RequestLog({
					memberStatus: status,
					memberId: member.id,
					member: member.user,
					createdAt: new Date(),
					ownerId: store.model.user.id,
					owner: store.model.user,
				}),
			});
		}
	};

	renderStatus = ({ record }) => {
		const isAllAgreed = !this.props.store.members.find((rec) => rec.status.code !== 'finished');
		const isAdmin = !!store.model.roles.find((role) => role.name === 'admin');
		const isOwner = store.model.user.id === record.user.id;
		return (
			<>
				<RecordSelect
					isRequired
					noSearch
					noTotal
					computed={renderStatus}
					model={store.model.EmergencySituationPlanMemberStatus}
					value={record.status}
					onChange={(value) => this.onStatusChange(value, record)}
					disabled={isAllAgreed || (!isAdmin && !isOwner)}
					width={180}
				/>
			</>
		);
	};

	render() {
		const { members } = this.props.store;

		return (
			<div className='members-list'>
				<Table rows={members} clickable={false}>
					<Column relation='user' computed={fio} label={t('fullName_full')} />
					<Column relation='organization' property='name' label={t('organization.title')} />
					<Column relation='role' property='name' label={t('request.agreementRole')} />
					<Column label={t('status')} width={200}>
						{this.renderStatus}
					</Column>
					<Column label={t('comments.title')} width={150}>
						{({ record }) =>
							STATUSES.includes(record.status?.code) && (
								<div className='member-comment' onClick={this.onCommentClick(record)}>
									{t('comments.title')}
								</div>
							)
						}
					</Column>
				</Table>
				{this.selectedMember && <CommentPopup onClose={this.onClose} comment={this.comment} onSave={this.onCommentSave} />}
			</div>
		);
	}
}
