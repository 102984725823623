import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { Filters } from 'techfires/firedepartments/firedep/schedule/filters';
import { Edit } from './edit/Edit';
import { List } from './list/List';
import ScheduleStore from 'techfires/firedepartments/firedep/schedule/store';

@observer
export class ScheduleList extends Component {
	constructor(props) {
		super(props);
		const { fireDepId, rankId } = props;
		this.store = new ScheduleStore({ fireDepId, rankId });
	}

	render() {
		const { isShowFilters } = this.store;

		const content = isShowFilters ? <Edit /> : <List />;

		return (
			<Provider store={this.store}>
				<div>
					<Filters />
					{content}
				</div>
			</Provider>
		);
	}
}
