import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Column, RecordSelect, Table, Button } from '@smartplatform/ui';
import { Card } from 'delivery/components';
import UsersIcon from '!!svg-react-loader!client/img/icons/users.svg';
import { fio } from 'client/tools';
import MemberFile from './MemberFile';
import MemberComment from './MemberComment';
import { renderStatus } from 'client/tools';
import store from 'client/store';
import { PLAN_PATH } from '../../constants';

@inject('store')
@observer
export class Members extends React.Component {
	@observable popupMember = null;
	@observable popupMemberComment = null;

	renderNumber = (rec, index) => index + 1;

	onStatusChange = async (status, member) => {
		member.status = status;
		member.statusId = status.id;
		const request = this.props.store.record;
		await member.save();
		this.props.store.comments.push({
			type: 'status',
			record: new store.model.RequestLog({
				memberStatus: status,
				memberId: member.id,
				member: member.user,
				createdAt: new Date(),
				ownerId: store.model.user.id,
				owner: store.model.user,
			}),
		});

		if (status.code === 'dorabotka') {
			this.popupMemberComment = request.id;
		}
		await this.props.store.fetchRecord();

		if (this.props.store.record.type.code === 'SCHEDULE') {
			await this.props.store.moveSupplies();
			store.route.push({ path: PLAN_PATH });
		}
	};

	renderActions = ({ record }) => {
		const { enableForCustomer, enableForContractor } = this.props.store;
		return (
			<>
				<RecordSelect
					isRequired
					computed={renderStatus}
					model={store.model.RequestMemberStatus}
					value={record.status}
					onChange={(value) => this.onStatusChange(value, record)}
					disabled={enableForCustomer && enableForContractor}
					width={180}
					noSearch
					noTotal
					maxItems={10}
					itemsPerPage={1000}
				/>
				{'  '}
				{record.status.code === 'dorabotka' ? <Button onClick={() => this.showPopup(record)}>Файлы</Button> : null}
			</>
		);
	};
	showPopupComment = (member) => (this.popupMemberComment = member);

	closePopupComment = () => (this.popupMemberComment = null);

	showPopup = (member) => (this.popupMember = member);

	closePopup = () => (this.popupMember = null);

	render() {
		const { members } = this.props.store;

		return (
			<Card icon={<UsersIcon />} title={t('request.agreementMembers')}>
				<Table rows={members} clickable={false}>
					<Column computed={this.renderNumber} label={t('number')} width={50} />
					<Column relation='user' computed={fio} label={t('fullName_full')} />
					<Column relation='organization' property='name' label={t('organization.title')} />
					<Column property='position' label={t('organization.position')} />
					<Column label={t('status')} width={280}>
						{this.renderActions}
					</Column>
				</Table>
				{this.popupMember && <MemberFile member={this.popupMember} onClose={this.closePopup} />}
				{this.popupMemberComment && <MemberComment onClose={this.closePopupComment} />}
			</Card>
		);
	}
}
