import React, { Component } from 'react';
import { RecordSelect, Button, Input } from '@smartplatform/ui';
import store from 'client/store';
import { inject, observer } from 'mobx-react';
import t from 'i18n';

import './filters.scss';

@inject('store')
@observer
export class Filters extends Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const {
			filter: { fireDep, search },
			onChange,
			resetFilters,
			isShowFilters,
			toggleFilters,
			onConfirm,
			onSearch,
		} = this.store;

		if (!isShowFilters) {
			return (
				<Button variant='primary' onClick={toggleFilters}>
					{t('edit')}
				</Button>
			);
		}

		const isDisabled = false;

		return (
			<>
				<div className='schedule-filters'>
					<Input value={search} onChange={onSearch} placeholder={t('vehicle.callSign')} />
					<RecordSelect
						model={store.model.FireDep}
						property='name'
						value={fireDep}
						onChange={onChange('fireDep')}
						width={150}
						placeholder={t('fireDepVehicle.fireDepShort')}
					/>
					<Button onClick={resetFilters}>{t('reset')}</Button>
				</div>
				<div className='schedule-button-group'>
					<Button variant='primary' onClick={onConfirm} disabled={isDisabled}>
						{t('save')}
					</Button>
					<Button onClick={toggleFilters}>{t('cancel')}</Button>
				</div>
			</>
		);
	}
}
