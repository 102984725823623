import React from 'react';
import { observer } from 'mobx-react';
import RequestsStore from './store';
import Toolbar from './toolbar/Toolbar';
import t from 'i18n';
import { RequestsKanban } from './kanban';
import { RequestsList } from './list';
import { KANBAN, LIST } from 'client/constants';

import './style.scss';

@observer
export default class Requests extends React.Component {
	constructor(props) {
		super(props);
		this.store = new RequestsStore();
		this.store.path = props.path;
		this.store.code = props.code;
		this.store.init();
	}

	render() {
		const { mode, code } = this.store;

		let content;
		switch (mode) {
			case LIST:
				content = <RequestsList store={this.store} />;
				break;
			case KANBAN:
				content = <RequestsKanban store={this.store} />;
				break;
			default:
				content = <RequestsKanban store={this.store} />;
		}

		const title = t(`request.${code}`);
		return (
			<div className='delivery-requests'>
				<h2>{title}</h2>
				<Toolbar store={this.store} />
				{content}
			</div>
		);
	}
}
