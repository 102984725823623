import { observable } from 'mobx';
import store from 'client/store';
import debounce from 'lodash/debounce';
import { RAID_JOBS_INCLUDE } from './constants';
import { defaultLocalStorageConfig } from 'fires/localStore';

export default class RaidsStore {
	@observable records = [];
	@observable showRaidPopup = false;
	@observable page = 1;
	@observable withPagination = false;

	@observable search = '';
	@observable querySearch = '';

	@observable dateRangeValues = {
		period: null,
		periodDate: null,
		startDate: null,
		endDate: null,
	};

	disabled = !store.model.AirRaid?.INFO.WRITE;

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	init = async () => {
		this.loadLocalStorage();
		this.fetchRecords();
	};

	fetchRecords = async () => {
		const dateFilter = { and: [] };
		const where = { or: [] };
		const { startDate, endDate } = this.dateRangeValues;
		if (startDate || endDate) {
			const arr = [];
			startDate && arr.push({ requestDate: { gte: startDate } });
			endDate && arr.push({ requestDate: { lte: endDate } });
			arr.length > 0 && dateFilter.and.push(...arr);
		}

		if (dateFilter.and.length > 0) where.or.push(dateFilter);
		if (where.or.length === 0) delete where.or;

		const withPagination = this.withPagination ? { limit: PER_PAGE, skip: (this.page - 1) * PER_PAGE } : {};

		this.records = await store.model.AirRaid.find({
			where,
			include: [
				RAID_JOBS_INCLUDE,
				{ relation: 'aviaDep' },
				{ relation: 'aircraft', scope: { include: ['contract', 'type'] } },
				{
					relation: 'pilots',
					scope: {
						include: ['staff'],
					},
				},
			],
			...withPagination,
			search: this.querySearch.length > 0 ? this.querySearch : undefined,
			order: 'id desc',
		});
		this.isLoading = false;
		this.isInitialized = true;
	};

	reload = async () => {
		this.saveLocalStorage();
		this.fetchRecords();
	};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		this.querySearch = this.search;
		this.reload();
	};

	//*popup
	onRowClick = (record) => {
		this.selectedRecord = record;
		this.toggleRaidPopup(true);
	};
	toggleRaidPopup = (boolean) => {
		if (!boolean) {
			this.selectedRecord = null;
			this.fetchRecords();
		}
		this.showRaidPopup = boolean;
	};
	onClosePopup = () => this.toggleRaidPopup(false);
	onOpenPopup = () => this.toggleRaidPopup(true);
	onPageChange = (page) => (this.page = page);

	cleanFilters = () => {
		const { page, querySearch, endDate, startDate, period } = defaultLocalStorageConfig.raids;
		this.page = page;
		this.search = querySearch;
		this.querySearch = querySearch;
		this.dateRangeValues.startDate = startDate;
		this.dateRangeValues.endDate = endDate;
		this.dateRangeValues.period = period;
		this.reload();
	};
	saveLocalStorage = () => {
		store.local.fires.raids = {
			page: this.page,
			period: this.dateRangeValues.period,
			periodDate: this.dateRangeValues.periodDate,
			startDate: this.dateRangeValues.startDate,
			endDate: this.dateRangeValues.endDate,
			querySearch: this.querySearch,
		};
		store.local.save();
	};

	loadLocalStorage = () => {
		//* load localstorage data
		const raids = store.local.fires.raids;
		if (Object.keys(raids).length > 0) {
			const { page, querySearch, period, periodDate, startDate, endDate } = raids;
			this.dateRangeValues.period = period;
			this.dateRangeValues.periodDate = periodDate && new Date(periodDate);
			this.dateRangeValues.startDate = startDate && new Date(startDate);
			this.dateRangeValues.endDate = endDate && new Date(endDate);
			this.page = parseInt(page);
			this.search = querySearch;
			this.querySearch = querySearch;
		}
	};
}
