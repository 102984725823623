import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import { Popover } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@inject('card') @observer
export default class RadiusButton extends React.Component {

	getInstance = (instance) => this.popover = instance;

	onClick = () => {
		if (this.popover) this.popover.close();
		store.local.fires.card.layers.heatPoints.selectRadius = this.props.value;
		this.props.onClick && this.props.onClick(this.props.value);
		store.local.save();
	};

	render() {
		const { value } = this.props;

		const props = {
			content: <>{t('monMap.hpSelectRadius')}: {value} {t('map.hpSelectMeters')}</>,
			className: classNames('radius-btn', {
				active: store.local.fires.card.layers.heatPoints.selectRadius === value,
			}),
			onClick: this.onClick,
			position: 'left',
			getInstance: this.getInstance,
		};

		return <Popover {...props}>
			<div>{value} {t('monMap.metersShort')}</div>
		</Popover>;
	}

}
