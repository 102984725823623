import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import t from 'i18n';
import '../style.scss';

import { Button, Input } from '@smartplatform/ui';
import store from 'client/store';

@inject('fireStore')
@observer
export default class ChiefForm extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.record = props.record || new store.model.TechFireChief({ fireId: props.fireStore.id });
	}

	onChiefChange = (value) => {
		this.record.text = value;
	};

	save = async () => {
		await this.record.save();
		this.props.onUpdate && this.props.onUpdate();
	};

	cancel = () => {
		this.props.onClose && this.props.onClose();
	};

	render() {
		return (
			<div>
				<div className='form-field'>
					<label>{t('fireDep.chief')}</label>
					<Input value={this.record.text} onChange={this.onChiefChange} />
				</div>
				<div className='form-field'>
					<label>&nbsp;</label>
					<Button onClick={this.save} variant='primary'>
						{t('save')}
					</Button>
					<Button onClick={this.cancel}>{t('cancel')}</Button>
				</div>
			</div>
		);
	}
}
