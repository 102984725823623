import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Field, Popup, RecordSelect } from '@smartplatform/ui';
import { Form } from 'components';
import store from 'client/store';
import t from 'i18n';

@inject('store')
@observer
export class EditPeoplePopup extends React.Component {
	@observable record = null;
	@observable isNew;
	@observable availableFormations = null;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.init();
	}

	init = async () => {
		this.record = this.props.record;
		// сохраняем оригинальную запись
		this.selectedRecordOriginal = { ...this.record };
		this.isNew = !this.record;
		if (this.isNew) {
			this.record = new store.model.ForceAvailabilityPeople();
		} else this.getRecordRelationData();
	};

	getRecordRelationData = async () => {
		this.record.formation = await this.record.formation;
		this.record.type = await this.record.type;
		this.availableFormations = await this.record.type.formations();
	};

	onCrewTypeChange = (type) => {
		this.record.type = type;
		if (type) {
			this.availableFormations = type.formations();
			this.record.formation = this.availableFormations.length > 0 ? this.availableFormations[0] : null;
		}
	};

	onFormationChange = (formation) => (this.record.formation = formation);

	revertRecord = async () => {
		if (!this.isNew) {
			const { typeId: originalTypeId, formationId: originalFormationId } = this.selectedRecordOriginal;
			const { typeId: selectedTypeId, formationId: selectedFormationId } = this.record;
			// перезаписываем объект, если id было изменено
			if (originalTypeId !== selectedTypeId) {
				this.record.type = await store.model.CrewType.findById(originalTypeId, { fields: ['id', 'name'] });
			}
			if (originalFormationId !== selectedFormationId) {
				this.record.formation = await store.model.FireFightingFormation.findById(originalFormationId, { fields: ['id', 'name'] });
			}
			// получаем все поля модели ForceAvailabilityPeople, проходим по каждому полю и меняем значение на оригинальное значение (объекты не перезаписываются)
			const modelKeys = Object.keys(store.model['ForceAvailabilityPeople'].PROPERTIES);
			for (let key of modelKeys) {
				this.record[key] = this.selectedRecordOriginal[key];
			}
		}
	};

	onClosePopup = async () => {
		await this.revertRecord();
		this.props.onClose();
	};

	beforeSave = () => {
		this.store.addHasMany('people', this.record);
		this.props.onClose();
		// сохраняем только новые реляции, редактируемые сохраним только при сохранении записи
		if (!this.isNew) return false;
	};

	beforeDelete = () => {
		this.store.removeHasMany('people', this.record);
		this.props.onClose();
		return false;
	};

	render() {
		const { onClose } = this.props;
		const title = this.props.record ? t('editRecord') : t('addRecord');
		return (
			<Popup className='force-availability-people-popup fires-popup' onClose={this.onClosePopup}>
				<div className='form-wrapper'>
					<div className='left'>
						<h2>{title}</h2>
						<Form
							beforeSave={this.beforeSave}
							beforeDelete={this.beforeDelete}
							disabled={this.props.store.disabled}
							noDelete={this.isNew}
							record={this.record}
							onDelete={onClose}
							onCancel={this.onClosePopup}
							disableSave={!this.record.typeId || !this.record.formationId || !this.record.count}
							stay
						>
							<div className='form-field'>
								<label className='is-required'>{t('crewType.title_short')}</label>
								<RecordSelect
									noTotal
									maxItems={10}
									itemsPerPage={1000}
									disabled={this.props.store.disabled}
									model={store.model.CrewType}
									property='shortName'
									value={this.record.type}
									onChange={this.onCrewTypeChange}
									filter={{ include: 'formations' }}
									isRequired
									noSearch
								/>
							</div>
							<div className='form-field'>
								<label className='is-required'>{t('fireFightingFormation.title_short')}</label>
								<RecordSelect
									noTotal
									maxItems={10}
									itemsPerPage={1000}
									model={store.model.FireFightingFormation}
									property='shortName'
									value={this.record.formation}
									onChange={this.onFormationChange}
									isRequired
									noSearch
									disabled={this.props.store.disabled || !this.record.type}
									filter={{
										where: {
											id: this.availableFormations ? { inq: this.availableFormations.map((f) => f.id) } : null,
										},
									}}
								/>
							</div>
							<Field property='count' label={t('count')} isRequired />
						</Form>
					</div>
				</div>
			</Popup>
		);
	}
}
