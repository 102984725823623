import React from 'react';
import { observer, inject } from 'mobx-react';

import { Table, Column } from '@smartplatform/ui';
import t from 'i18n';
import { fireDepSpecialTypeItem } from 'techfires/helpers';

@observer
export default class Vehicles extends React.Component {
	
	render() {
		const { record } = this.props;
		
		return (
			<div>
				<Table rows={record.vehicles()}>
					<Column property='stateNumber' label={t('vehicle.stateNumber')} />
					<Column relation='brand' property='name' label={t('fireDepVehicle.brand')} />
					<Column relation='status' property='name' label={t('fireDepVehicle.status')} />
					<Column relation='type' property='name' label={t('fireDepVehicle.type')} />
					<Column relation='type' computed={fireDepSpecialTypeItem} label={t('fireDepVehicle.specialType')} />
					<Column property='pumpCapacity' label={t('pump.title')} />
					<Column property='year' label={t('vehicle.year')} />
					<Column property='passport' label={t('vehicle.passport')} />
					<Column property='capacity' label={t('vehicle.capacity')} />
					{/* <Column property='foamCapacity' label={t('vehicle.foamCapacity')} /> */}
					<Column relation='callSign' property='name' label={t('vehicle.callSign')} />
				</Table>
			</div>
		);
	}
}
