import React from 'react';
import { observer } from 'mobx-react';
import { ReportToolbar } from 'components';
import t from '@smartplatform/map/i18n';
import { DatePicker } from '@smartplatform/ui';
import { ReportStore } from './store';

const CODE = 'AviaDepByCause';

@observer
export class AviaDepByCause extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE);
	}

	render() {
		const { endDate, startDate, onEndDateChange, onStartDateChange } = this.store;

		return (
			<div>
				<div className='filters'>
					<div className='row'>
						<div className='col-3'>
							<div className='form-field'>
								<label>{t('date1')}</label>
								<DatePicker value={startDate} onChange={onStartDateChange} showClearButton={false} />
							</div>
						</div>
						<div className='col-3'>
							<div className='form-field'>
								<label>{t('date2')}</label>
								<DatePicker value={endDate} onChange={onEndDateChange} showClearButton={false} />
							</div>
						</div>
					</div>
				</div>
				<div>
					<ReportToolbar {...this.store.storeParams} isHTML={true} />
				</div>
			</div>
		);
	}
}
