import React from 'react';
import { observer, Provider } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Loader, Tabs, Tab } from '@smartplatform/ui';
import { Panel } from 'components';
import FireMap from './map';
import Main from './main/Main';
import ActionsPanel from './ActionsPanel';
import { Raids } from './raids';
import FireCardStore from './store';
import t from 'i18n';
import './style.scss';
import Sidebar from './Sidebar';
import Dynamic from './dynamic'

@withRouter
@observer
export default class FireCard extends React.Component {

	constructor(props) {
		super(props);
		this.card = new FireCardStore();
		// в первый раз пожар загрузится при инициализации карты
		console.log('this.props.match.params', this.props.match);
		this.card.id = parseInt(this.props.match.params.id);
	}

	componentDidMount() {
		if (!this.card.id) this.card.checkParams();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			// в случае прямого перехода из одного пожара в другой (будет ли такое?) - загружаем пожар здесь

			this.card.id = parseInt(this.props.match.params.id);
			this.card.loadFire();
		}
	}

	render() {
		const card = this.card;

		let content;

		if (card.isLoading) {
			content = <Loader size={50} />;
		} else if (card.error) {
			content = card.error;
		} else {
			content = this.card.id ? <>
				<Sidebar />
				<Tabs before={<ActionsPanel />} className={card.mapMaximized ? 'map-maximized' : ''}>
					<Tab title={t('fire.mainInfo')} path={`/fires/registry/${this.card.id}`} exact>
						<Main />
					</Tab>
					<Tab title={t('fire.dynamic')} path={`/fires/registry/${this.card.id}/dynamic`}>
						<Dynamic />
					</Tab>
					<Tab title={t('airRaid.plural')} path={`/fires/registry/${this.card.id}/raids`}>
						<Raids />
					</Tab>
				</Tabs>
			</> : <>
				<Sidebar />
				<Tabs before={<ActionsPanel />} type="manual" className={card.mapMaximized ? 'map-maximized' : ''}>
					<Tab title={t('fire.mainInfo')} path="/fires/registry/add"/>
					<Main />
				</Tabs>
			</>;
		}

		return (
			<Provider card={card}>
				<Panel full className="fire-card-wrapper" scrollable={false}>
					<div className={'fire-card' + (card.mapMaximized ? ' map-maximized' : '')}>
						<div className="fire-card-content">
							{content}
						</div>
					</div>
					<FireMap />
				</Panel>
			</Provider>
		);
	}
}
