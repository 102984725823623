import React from 'react';
import { isArrayLike } from 'mobx';
import { observer } from 'mobx-react';
import { Link, matchPath } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import store from 'client/store';
import './style.scss';
import { getRoleNames } from 'client/tools';
import { isAdminDelivery, isHozSubject, isMinArct, isMO, isYakutOptTorg } from 'delivery/helpers/roles';

@observer
export default class ModuleMenu extends React.Component {
	constructor(props) {
		super(props);
	}

	get isAdmin() {
		const roleNames = getRoleNames();
		return !!roleNames.includes('admin');
	}

	get isSpecialistChs() {
		const roleNames = getRoleNames();
		return !!roleNames.includes('specialist_chs');
	}

	render() {
		if (!store.module) return null;

		let menu = [];
		if (isArrayLike(store.module.menu)) {
			menu = store.module.menu;
		} else if (typeof store.module.menu === 'function') {
			menu = store.module.menu();
		}

		return (
			<div className='module-menu'>
				{menu.map((item) => {
					if (!item) return null;
					const match = matchPath(store.route.path, { path: item.path, exact: item.exact, strict: false });
					// console.log('>', item.path, store.route.path, match);
					const isActive = item.isActive ? item.isActive(store.route.path) : !!match;
					const className = classNames('sidebar-btn', {
						active: isActive,
					});
					if (!(this.isAdmin || isAdminDelivery()) && (item.path.indexOf('journal') >= 0 || item.path.indexOf('admin') >= 0)) {
						return;
					}
					if (this.isSpecialistChs && item.path.indexOf('map') >= 0) {
						return;
					}
					if (
						(isMinArct() || isYakutOptTorg()) &&
						(item.path.indexOf('reserves') >= 0 ||
							item.path.indexOf('contragents') >= 0 ||
							item.path.indexOf('dictionaries') >= 0 ||
							item.path.indexOf('layers') >= 0)
					) {
						return;
					}
					if (
						isHozSubject() &&
						(item.path.indexOf('map') >= 0 ||
							item.path.indexOf('requests') >= 0 ||
							item.path.indexOf('reserves') >= 0 ||
							item.path.indexOf('contragents') >= 0 ||
							item.path.indexOf('dictionaries') >= 0 ||
							item.path.indexOf('layers') >= 0)
					) {
						return;
					}
					if (
						isMO() &&
						(item.path.indexOf('map') >= 0 ||
							item.path.indexOf('plans') >= 0 ||
							item.path.indexOf('reserves') >= 0 ||
							item.path.indexOf('oper-info') >= 0 ||
							item.path.indexOf('contragents') >= 0 ||
							item.path.indexOf('dictionaries') >= 0 ||
							item.path.indexOf('layers') >= 0)
					) {
						return;
					}
					return (
						<Link key={item.title} to={item.path} className={className}>
							<div className='item-wrapper'>
								<div className='icon'>{item.icon || <FontAwesomeIcon icon={faCircle} />}</div>
								<div className='title'>{item.title}</div>
							</div>
						</Link>
					);
				})}
			</div>
		);
	}
}
