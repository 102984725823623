import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import CategoriesTree from './CategoriesTree';
import t from 'i18n';

@observer
export default class Categories extends React.Component {

	render() {
		return <div className="appeal-categories">
			<h1>{t('appealCategory.plural')}</h1>
			<CategoriesTree />
		</div>;
	}

}
