import { observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Card } from 'delivery/components';
import { PostComment, Comment, CommentHeader } from 'components';
import CommentIcon from '!!svg-react-loader!client/img/icons/comment.svg';
import { fio } from 'client/tools';
import { renderStatus } from 'client/tools';

@observer
export class Discussion extends React.Component {
	constructor(props) {
		super(props);
		this.record = props.record;
		this.fetchComments = props.fetchComments;
		this.comments = props.comments;
	}

	onSubmit = async () => await this.fetchComments();

	onCommentDelete = async (comment) => {
		const attachments = await comment.attachments();
		for (let attachment of attachments) {
			await comment.attachments.remove(attachment.id);
			await attachment.deleteFile('filename');
			await attachment.delete();
		}
		await comment.delete();
		await this.fetchComments();
	};

	render() {
		const { comments } = this;
		const logs = comments.map((item) => {
			const { type, record, index } = item;
			if (type === 'comment') {
				return (
					<Comment
						key={'comment-' + record.id}
						comment={record}
						className='agreement-comment'
						onDelete={this.onCommentDelete}
						noDelete={this.props.noDelete}
						withAttachments={this.props.withAttachments}
					/>
				);
			}
			if (type === 'status') {
				const isChangedByAdmin = record.memberId !== record.ownerId;
				const action = (
					<span className='discussion-item'>
						<span>{t('history.updateMemberStatus_short')}</span>
						{isChangedByAdmin && <span className='member'>{fio(record.member)}</span>}
						{t('history.to')}
						<span className='status'>{renderStatus(record.memberStatus)}</span>
					</span>
				);

				return <CommentHeader key={'comment-header' + record.id} comment={record} subject={fio(record.owner)} action={action} />;
			}
			return (
				<div key={'inknown-' + record.id} className='unknown-item'>
					{type}, id: {record.id}
				</div>
			);
		});

		return (
			<>
				<Card icon={<CommentIcon />} title={t('request.discussion')} contentPadding={20}>
					{logs}
				</Card>
				<Card contentPadding={20}>
					<PostComment record={this.record} onSubmit={this.onSubmit} />
				</Card>
			</>
		);
	}
}
