import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import t from 'i18n';
import '../style.scss';

import { Button, Input, DatePicker, NumberInput, Checkbox } from '@smartplatform/ui';
import store from 'client/store';

@inject('fireStore')
@observer
export default class CasualtyForm extends React.Component {
	@observable record = null;
	@observable selectedStatus = null;

	constructor(props) {
		super(props);
		this.record = props.record || new store.model.TechFireCasualty({ fireId: props.fireStore.id });
	}

	onSocialStatusChange = (socialStatus) => {
		this.record.socialStatus = socialStatus.name;
		this.selectedStatus = socialStatus;
	};

	onChange = (property) => (value) => {
		this.record[property] = value;
	};

	save = async () => {
		await this.record.save();
		this.props.onUpdate && this.props.onUpdate();
	};

	cancel = () => {
		this.props.onClose && this.props.onClose();
	};

	render() {
		return (
			<div>
				<div className='form-field'>
					<label>{t('techFireCasualty.detectTime')}</label>
					<DatePicker value={this.record.detectTime} showTimeSelect onChange={this.onChange('detectTime')} />
				</div>
				<div className='form-field'>
					<label>{t('techFireCasualty.lastName')}</label>
					<Input value={this.record.lastName} onChange={this.onChange('lastName')} />
				</div>
				<div className='form-field'>
					<label>{t('techFireCasualty.firstName')}</label>
					<Input value={this.record.firstName} onChange={this.onChange('firstName')} />
				</div>
				<div className='form-field'>
					<label>{t('techFireCasualty.middleName')}</label>
					<Input value={this.record.middleName} onChange={this.onChange('middleName')} />
				</div>
				<div className='form-field'>
					<label>{t('techFireCasualty.birth')}</label>
					<NumberInput positiveOnly integerOnly value={this.record.yearOfBirth} onChange={this.onChange('yearOfBirth')} />
				</div>
				<div className='form-field'>
					<label>{t('techFireCasualty.deceased')}</label>
					<Checkbox value={this.record.deceased} onChange={this.onChange('deceased')} />
				</div>
				<div className='form-field'>
					<label>{t('techFireCasualty.furtherAction')}</label>
					<Input value={this.record.furtherAction} onChange={this.onChange('furtherAction')} />
				</div>
				<div className='form-field'>
					<label>&nbsp;</label>
					<Button onClick={this.save} variant='primary'>
						{t('save')}
					</Button>
					<Button onClick={this.cancel}>{t('cancel')}</Button>
				</div>
			</div>
		);
	}
}
