import React from 'react';
import { Style, Icon } from 'ol/style';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { createXYZ } from 'ol/tilegrid';
import { get as getProj } from 'ol/proj';
import MVT from 'ol/format/MVT';
import store from 'client/store';
import AddressLayerPopup from './Popup';
import t from 'i18n';
import address from './address.svg';
const TILE_SIZE = 512;

export default class AddressLayer {
	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: createXYZ({
				extent: getProj('EPSG:3857').getExtent(),
				maxZoom: 18,
				tileSize: TILE_SIZE,
			}),
			tileUrlFunction: this.tileUrlFunction,
			tileLoadFunction: this.tileLoadFunction(this.renderPopup),
		});

		this.layer = new VectorTileLayer({
			source: this.source,
			style: new Style({
				image: new Icon({
					src: address,
					scale: 0.5,
					anchor: [17.5, 30],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			}),
		});
		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
	};

	tileUrlFunction = (coords) => {
		const { fields, ranks } = this.settings.filter;
		let statusFilterString = [];
		const selectedRank = ranks.map((r) => `rankId=${r.id}`);
		const selectedStatus = Object.keys(fields).filter((key) => fields[key]);
		if (selectedStatus.length) {
			statusFilterString = selectedStatus.map((status) => `${status}Count=true`);
		}
		const filter = '&filter=' + [...statusFilterString, ...selectedRank].join(' or ');
		return `/api/mvt?model=ViewTechFireAddressLayer&x=${coords[1]}&y=${coords[2]}&z=${coords[0]}${filter}&noCache=1&columns=id,ptp,ktp&buffer=${TILE_SIZE}&extent=${TILE_SIZE}`;
	};

	tileLoadFunction = (render) => (tile, url) => {
		tile.setLoader(function (extent, resolution, projection) {
			fetch(url).then(function (response) {
				response.arrayBuffer().then(function (data) {
					const format = tile.getFormat(); // ol/format/MVT configured as source format
					const renderFeatures = format.readFeatures(data, {
						extent: extent,
						featureProjection: projection,
					});
					renderFeatures.forEach((f) => {
						const record = f.getProperties();
						f.properties_ = {
							title: t('techFireAddress.title') + ' №' + record.id,
							record,
							render,
						};
					});
					tile.setFeatures(renderFeatures);
				});
			});
		});
	};

	onToggle = async (value, id) => {
		const { filter } = this.settings;
		if (id) {
			filter.fields[id] = value;
		} else {
			filter.ranks = value;
		}
		store.local.save();
		this.source.refresh();
	};
	setVisible = () => this.layer.setVisible(this.settings.show);
	renderPopup = (record) => <AddressLayerPopup record={record} />;
}
