import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';

import { Loader, Table, Column, DateValue } from '@smartplatform/ui';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export default class FireDepList extends React.Component {
	
	@observable records = [];
	@observable dispatcher = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.records = await store.model.FireDep.find({
			include: [
				{
					relation: 'shifts',
					scope: {
						where: {
							shiftId: this.props.match.params.id
						},
						include: [
							{
								relation: 'dispatcher',
								scope: {
									include: ['section', 'position']
								}
							}
						],
					}
				},
			],
			order: 'id asc',
		});
		const personnel = await store.model.FireDepPersonnel.find({
			where: { userId: store.model.user.id },
			include: [
				{ relation: 'fireDep', scope: { fields: ['id', 'name' ]}}
			],
		});
		this.dispatcher = personnel[0] || null;
		this.isLoading = false;
	};
	
	rowClassName = (record) => {
		if (!this.props.isMain) {
			if (this.dispatcher && this.dispatcher.fireDepId === record.id) {
				return '';
			}
			return 'disabled';
		}
		return '';
	};
	
	onRowClick = (record) => store.route.push({ path: `/techfires/shifts/${this.props.match.params.id}/firedep/${record.id}` });
	
	renderStatus = ({ record }) => {
		const checked = record.shifts().length > 0;
		return <FontAwesomeIcon icon={checked ? faCheck : faCircle} className={checked ? 'on-shift' : 'not-on-shift'} />;
	};
	
	renderShift = ({ property, relation, computed }) => ({ record }) => {
		const shift = record.shifts().length > 0 ? record.shifts()[0] : null;
		if (!shift) return '-';
		console.log('renderShift', record, shift)
		if (relation) {
			if (computed) return computed(shift[relation]);
			return shift[relation][property];
		}
		if (computed) return computed(shift);
		return shift[property];
	};
	
	renderDispatcher = ({ property, relation, computed }) => ({ record }) => {
		const shift = record.shifts().length > 0 ? record.shifts()[0] : null;
		if (!shift || !shift.dispatcher) return '-';
		console.log('renderDispatcher', shift.dispatcher)
		if (relation) {
			if (computed) return computed(shift.dispatcher[relation]);
			return shift.dispatcher[relation]?.[property];
		}
		if (computed) return computed(shift.dispatcher);
		return shift.dispatcher[property];
		
	}
	renderShiftDate = (shift) => shift ? <DateValue value={shift.date} /> : '-';
	
	render() {
		if (this.isLoading) return <Loader/>;
		
		return <div className="firedep-list">
			<Table rows={this.records} rowClassName={this.rowClassName} onRowClick={this.onRowClick}>
				<Column label={t('status')} className="text-center" width={50}>{this.renderStatus}</Column>
				<Column property="name" label={t('name')} width={140}/>
				<Column label={t('fireDepShift.date')} width={140}>{this.renderShift({ computed: this.renderShiftDate })}</Column>
				<Column label={t('fireDepShift.dispatcher')}>{this.renderDispatcher({ computed: fio })}</Column>
				<Column label={t('fireDepSection.title')}>{this.renderDispatcher({ relation: 'section', property: 'name' })}</Column>
				<Column label={t('fireDepPosition.title')}>{this.renderDispatcher({ relation: 'position', property: 'name' })}</Column>
			</Table>
		</div>;
	}
}
