import React from 'react';
import { observer } from 'mobx-react';

import './style.scss';
import { Button } from '@smartplatform/ui';

@observer
export default class Image extends React.Component {
	constructor(props) {
		super(props);
	}

	getRecord = (props) => props.record || (props.__data ? props.__data.record : null);

	getImageData = async (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = function () {
				const img = new Image();
				img.onload = resolve({ base64: reader.result, width: img.width, height: img.height });
				img.src = reader.result;
			};
			reader.onerror = function (error) {
				reject(error);
			};
			reader.readAsDataURL(file);
		});

	checkSvgSize = async (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = function (e) {
				const hdr = e.target.result.match(/.*(<svg[^>]+>).*/);
				if (hdr) {
					const widthMatch = hdr[1].match(/width=/);
					const heightMatch = hdr[1].match(/height=/);
					if (!widthMatch || !heightMatch) {
						reject(new Error('svg with no size'));
					}
					resolve(e.target.result);
				} else {
					reject(new Error('svg invalid'));
				}
			};
			reader.onerror = function (error) {
				reject(error);
			};
			reader.readAsText(file, 'UTF-8');
		});

	onAttach = async (e) => {
		this.error = null;
		const file = e.target.files[0];
		e.target.value = '';
		console.log('onAttach', file);
		this.icon = file;

		try {
			if (file.type === 'image/svg+xml') {
				await this.checkSvgSize(file);
			}
		} catch (e) {
			console.warn(e);
			this.error = e.message;
		}

		try {
			const { base64, width, height } = await this.getImageData(file);
			console.log('image', { width, height }, base64);
			const record = this.getRecord(this.props);
			record.icon = base64;
		} catch (e) {
			console.warn(e);
			this.error = e.message;
		}
	};

	deleteIcon = () => {
		const record = this.getRecord(this.props);
		record.icon = null;
	};

	render() {
		const { icon } = this.getRecord(this.props);

		return (
			<>
				{icon ? <img src={icon} alt='' /> : <div className='no-icon' />}
				<div className='upload mt-2'>
					<input type='file' id='icon-upload' className='d-none' onChange={this.onAttach} accept='image/svg+xml,image/png' />
					<label htmlFor='icon-upload' className='sp-btn sp-btn-default mb-0'>
						Загрузить картинку
					</label>
					{icon && <Button onClick={this.deleteIcon}>Удалить картинку</Button>}
				</div>
			</>
		);
	}
}
