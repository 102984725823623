import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Select } from '@smartplatform/ui';
import { getYearArray } from '../helper';
import t from 'i18n';
import store from 'client/store';

@inject('store')
@observer
export default class Filters extends React.Component {
	render() {
		const { year, period, onChange, downloadReport, data, periods } = this.props.store;

		return (
			<div className='mb-3'>
				<Select
					className='w-auto mr-2'
					noTotal
					maxItems={10}
					itemsPerPage={1000}
					noSearch
					isRequired
					items={getYearArray()}
					value={year}
					onChange={(year) => onChange('year', year)}
				/>
				<Select
					className='w-auto mr-2'
					noTotal
					maxItems={10}
					itemsPerPage={1000}
					noSearch
					isRequired
					items={periods}
					value={period}
					onChange={(period) => onChange('period', period)}
				/>
				{!!data && !!data.length && (
					<Button variant='primary' onClick={downloadReport}>
						{t('downloadExcel')}
					</Button>
				)}
			</div>
		);
	}
}
