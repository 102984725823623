import React from 'react';
import { observable } from 'mobx';

import { Popover } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import appealsStore, { EVENTS } from '../store';
import store from 'client/store';
import counties from './counties-svg';

@observer
export default class HeatMap extends React.Component {

	@observable counties = [];
	@observable current = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		appealsStore.subscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.subscribe(EVENTS.RELOAD_CATEGORIES, this.init);
	}

	componentWillUnmount() {
		appealsStore.unsubscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.unsubscribe(EVENTS.RELOAD_CATEGORIES, this.init);
	}

	init = async () => {
		console.log('counties', appealsStore.system);
		const counties = await store.model.County.withAppealCount({
			startDate: appealsStore.startDate,
			endDate: appealsStore.endDate,
			parentId: appealsStore.category ? appealsStore.category.id : null,
			systemId: appealsStore.system.id,
		});
		const allIndex = counties.findIndex(county => county.id === null);
		counties.splice(allIndex, 1);

		const jatayIndex = counties.findIndex(county => county.id === 36);
		const jatay = jatayIndex !== -1 ? counties[jatayIndex] : null;
		const ykt = counties.find(county => county.id === 1);
		if (ykt && jatay) {
			ykt.count = ykt.count + jatay.count;
		}
		counties.splice(jatayIndex, 1);
		counties.forEach(county => county.__ratio = county.count / county.population);
		this.counties = counties;
		this.isLoading = false;
	};

	showPopup = (county) => {
		this.current = county;
	};

	hidePopup = () => {
		this.current = null;
	};

	render() {
		const max = Math.max.apply(Math, this.counties.map(c => c.__ratio));

		return <div className="heat-map">
			<svg version="1.2" baseProfile="tiny"
			     xmlns="http://www.w3.org/2000/svg"
			     x="0px" y="0px" width="430px" height="372px" viewBox="0 0 430 372">
				{this.counties.map(county => {
					const ratio = max !== 0 ? county.__ratio / max : 0;
					// console.log(county.id, county.count, ratio, max);
					const props = {
						fill: county.count > 0 ? `rgba(${Math.round(255 - ratio * 128)}, ${Math.round(128 - ratio * 64)}, ${Math.round(128 - ratio * 64)}, 1)` : '#dbb',
						onMouseEnter: () => this.showPopup(county),
						onMouseLeave: () => this.hidePopup(county),
					};
					const County = counties[county.id];
					return County ?
						<County key={county.id} {...props} />
						:
						null;
				})}
			</svg>
			<div className="info">
				{this.current && <div>
					<strong>{this.current.name}</strong>
					<div className="count">Обращений: <em>{this.current.count}</em></div>
					<div className="count">Обращений на 1 тыс. населения: <em>{(this.current.count / this.current.population * 1000).toFixed(2)}</em></div>
				</div>}
			</div>
		</div>;
	}
	
}
