import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { FilterLayout } from 'components';
import t from 'i18n';

@observer
export default class ForestiesFilter extends React.Component {

	static propTypes = {
		layer: PropTypes.object,
		onToggle: PropTypes.func,
	};

	onToggle = (value) => {
		// this.props.onToggle ? this.props.onToggle(value) : this.props.layer.setVisible();
		this.props.layer.setVisible()
	};

	render() {
		return <FilterLayout layer={this.props.layer} title={t('forestry.plural')} />;
	}

}
