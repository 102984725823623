import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Tab, Tabs } from '@smartplatform/ui';
import { ScheduleList } from './schedule-list';
import t from 'i18n';
import store from 'client/store';

@observer
export class Schedule extends Component {
	@observable ranks = [];
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.ranks = await store.model.TechFireRank.find();
	};

	render() {
		const { fireDepId } = this.props;
		if (!this.ranks.length) return <div>{t('loading')}</div>;

		return (
			<Tabs>
				{this.ranks.map((rank, i) => (
					<Tab
						key={rank.id}
						exact={i === 0}
						path={`/techfires/firedep/${fireDepId}/schedule` + (i > 0 ? `/${rank.id}` : '')}
						title={`Ранг ${rank.name}`}
						render={(tabProps) => <ScheduleList {...tabProps} fireDepId={fireDepId} rankId={rank.id} />}
					/>
				))}
			</Tabs>
		);
	}
}
