import React from 'react';
import PropTypes from 'prop-types';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { withRouter, Route } from 'react-router-dom';
import { shortenAddressName } from 'client/tools';

import { Pager } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';

import {
	STATUS_SUSPECTED,
	STATUS_CONFIRMED,
	STATUS_RECOVERED,
	ISOLATION_SELF,
	ISOLATION_OBSERVATOR,
	ISOLATION_INFDEP,
} from '../../store';

const PERIOD_IN_SECONDS = 60;

const PER_PAGE = 20;

@withRouter @observer
export default class Cases extends React.Component {
	
	@observable addresses = [];
	@observable currentCounty = null;
	@observable initial = true;
	@observable page = 1;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.county !== this.props.county || this.type !== this.props.type) {
			this.init();
		}
	}
	
	init = async () => {
		this.county = this.props.county;
		this.type = this.props.type;
		this.currentCounty = this.county ? await store.model.County.findById(this.county) : null;
		await this.load();
		this.initial = false;
		// setTimeout(this.load, PERIOD_IN_SECONDS * 1000)
	};
	
	load = async () => {
		const where = { and: []};
		if (this.county) {
			where.and.push({ countyId: this.county });
		}
		if (this.type) {
			if (this.type === 'confirmed') where.and.push({ confirmed: { gt: 0 }});
			if (this.type === 'isolated') where.and.push({ self: { gt: 0 }});
			if (this.type === 'contacted') where.and.push({ contacted: { gt: 0 } });
		}
		const addresses = await store.model.ViewAddress.find({
			where,
			order: 'confirmed desc, infdep desc, contacted desc, observator desc',
			// skip: (this.page - 1) * PER_PAGE,
			// limit: PER_PAGE,
		});
		this.addresses = addresses.filter(a => parseInt(a.count) > 0);
	};
	
	gotoAddress = address => {
		if (address.geo) {
			store.map.addHelperMarker(address.geo.coordinates);
			store.map.gotoPoint(address.geo.coordinates, 16);
		}
	};
	
	onPageChange = page => {
		this.page = page;
		this.load();
	};
	
	getStatuses = record => {
		let status = [];
		const type = this.props.type;
		
		if (type === 'confirmed') {
			if (parseInt(record.confirmed) > 0) status.push(<span className="confirmed" key="confirmed">Больных: <strong>{record.confirmed}</strong>. </span>);
		}
		else if (type === 'isolated') {
			if (parseInt(record.self) > 0) status.push(<span className="self" key="self">В самоизоляции: <strong>{record.self}</strong>. </span>);
			if (parseInt(record.observator) > 0) status.push(<span className="observator" key="observator">В обсеваторе: <strong>{record.observator}</strong>. </span>);
			if (parseInt(record.infdep) > 0) status.push(<span className="infdep" key="infdep">В инф. отделении: <strong>{record.infdep}</strong>. </span>);
		}
		else if (type === 'contacted') {
			if (parseInt(record.contacted) > 0) status.push(<span className="contacted" key="contacted">Контактных: <strong>{record.contacted}</strong>. </span>);
		}
		// if (status.length === 0) status.push(<div key={record.id}>{JSON.stringify(record)}</div>);
		// if (parseInt(record.recovered) > 0) status.push(<span className="recovered" key="recovered">Выздоровевших: <strong>{record.recovered}</strong>. </span>);
		// if (parseInt(record.suspected) > 0) status.push(<span className="suspected" key="suspected">Подозрение: <strong>{record.suspected}</strong>. </span>);
		
		return status;
	};
	
	render() {
		let content = '...';
		
		if (!this.initial) {
			if (this.addresses.length > 0) {
				content = <>
					<div className="scroller">
						<table className="cases-table">
						<tbody>
						{this.addresses.map(address => {
							return <tr key={address.id} onClick={() => this.gotoAddress(address)}>
								<td className="address">
									{shortenAddressName(address.name)}
									<div className="statuses">{this.getStatuses(address)}</div>
								</td>
							</tr>;
						})}
						</tbody>
						</table>
					</div>
				</>;
			}
			else {
				content = 'Записей не найдено';
			}
		}
		
		return <div className="block cases">
			<div className="block-header">
				{this.currentCounty ? this.currentCounty.name + '. ' : ''}
				{this.props.type === 'confirmed' ? 'Больные' : ''}
				{this.props.type === 'isolated' ? 'В изоляции' : ''}
				{this.props.type === 'contacted' ? 'Контактные' : ''}
			</div>
			{content}
		</div>;
	}
	
}
