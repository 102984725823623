import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import Chart from 'chart.js';

import store from 'client/store';
import './style.scss';

@observer
export default class Graphs extends React.Component {
	
	@observable records = [];
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	componentWillUnmount() {
		if (this.chart) this.chart.destroy();
	}
	
	init = async () => {
		this.records = await store.model.Case.getStats();
		this.isLoading = false;
		this.fillData();
	};
	
	fillData = () => {
		if (!this.el) return;
		
		const style = {
			borderWidth: 2,
			pointRadius: 0.5,
		};
		
		const datasets = [
			{
				data: this.records.map(day => day.total),
				label: 'Всего',
				borderColor: "#888",
				backgroundColor: 'rgba(136,136,136, 0.1)',
				// fill: false,
				...style,
			},
			{
				data: this.records.map(day => day.confirmed),
				label: 'Больные',
				borderColor: "#d00",
				backgroundColor: 'rgba(221,0,0, 0.2)',
				// fill: false,
				...style,
			},
			{
				data: this.records.map(day => day.recovered),
				label: 'Выздоровевшие',
				borderColor: "#3e95cd",
				backgroundColor: 'rgba(0,170,255, 0.2)',
				// fill: false,
				...style,
			},
		];
		
		const todaysDay = new Date().getDay();

		this.chart = new Chart(this.el, {
			type: 'line',
			data: {
				labels: this.records.map((day, i) => {
					const date = new Date(day.day);
					// return date.getDay() === todaysDay ? format(date, 'dd.MM') : '';
					return format(date, 'dd MMM', { locale: ru });
				}),
				datasets,
			},
			options: {
				title: {
					display: true,
					text: 'Количество больных Covid-19'
				},
				responsive: true,
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
				scales: {
					xAxes: [
						{
							ticks: {
								autoSkip: true,
								maxRotation: 0,
								minRotation: 0
							}
						}
					]
				}
			},
		});
	};
	
	onMount = el => {
		if (el) {
			this.el = el;
		}
	}
	
	render() {
		return <canvas id="graph-1" {...this.props} ref={this.onMount} />;
	}
	
}
