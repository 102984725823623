export const MODEL_NAME = 'FireDep';

export const PERSONNEL_SCOPE = {
	include: [
		{ relation: 'section', scope: { fields: ['id', 'name'] } },
		{ relation: 'position', scope: { fields: ['id', 'name'] } },
		{ relation: 'rank', scope: { fields: ['id', 'name'] } },
		{ relation: 'vehicle', scope: { fields: ['id', 'name'] } },
		{ relation: 'gasMask', scope: { fields: ['id', 'name'] } },
		{ relation: 'status', scope: { fields: ['id', 'name'] } },
	],
	order: 'lastName asc, firstName asc, middleName asc',
};

export const VEHICLES_SCOPE = {
	include: [
		{ relation: 'brand', scope: { fields: ['id', 'name'] } },
		{ relation: 'type', scope: { fields: ['id', 'name'] } },
		{ relation: 'foamCapacity' },
		{ relation: 'cisternBulk' },
		{ relation: 'pump' },
		{ relation: 'status' },
		{ relation: 'hydraulicTools' },
	],
	order: 'stateNumber asc, passport asc',
};

export const FIRE_DEP_SCOPE = {
	include: [
		{
			relation: 'personnel',
			scope: PERSONNEL_SCOPE,
		},
		{
			relation: 'vehicles',
			scope: VEHICLES_SCOPE,
		},
		{ relation: 'county', scope: { scope: { fields: ['id', 'name'] } } },
		{ relation: 'city', scope: { scope: { fields: ['id', 'name'] } } },
	],
};
