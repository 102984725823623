import React from 'react';
import { observable } from 'mobx';
import { Column, Pager, Table } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { ListStore, Toolbar } from 'components';
import { EditPopup } from './EditPopup';
import { EMERGENCY_SITUATION_INCLUDE, MODEL_NAME } from './constants';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class List extends React.Component {
	@observable showEditPopup = false;
	@observable selectedRecord = null;

	constructor(props) {
		super(props);
		const model = store.model[MODEL_NAME];
		this.store = new ListStore({ path: props.path, filters: EMERGENCY_SITUATION_INCLUDE, model });
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	toggleEditPopup = (record) => {
		this.selectedRecord = record || null;
		this.showEditPopup = !this.showEditPopup;
	};

	onClosePopup = () => {
		this.toggleEditPopup();
		this.store.reload();
	};

	onClean = () => {
		const popup = document.getElementById('form');
		const textareas = popup.getElementsByClassName('textarea');
		Array.from(textareas).forEach((tag) => (tag.value = null));
	};

	render() {
		const { onChange, totalCount, page, perPage, tableProps, search, onSearch } = this.store;

		return (
			<div className='emergency-situation-page'>
				<h2>{t('emergencySituation.plural')}</h2>
				<Toolbar>
					<Toolbar.AddButton onClick={() => this.toggleEditPopup()} />
					<Toolbar.SearchIconInput value={search} onChange={onSearch} />
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table {...tableProps} onRowClick={this.toggleEditPopup}>
						<Column property='startDate' label={t('emergencySituation.startDate')} />
						<Column property='endDate' label={t('emergencySituation.endDate')} />
						<Column relation='type' computed={(r) => r.name} label={t('emergencySituation.type')} />
						<Column property='republicStatus' label={t('emergencySituation.republicStatus')} />
						<Column property='startAct' label={t('emergencySituation.startAct')} />
						<Column property='endAct' label={t('emergencySituation.endAct')} />
						<Column property='comment' label={t('emergencySituation.comment')} />
					</Table>
				</div>
				{this.showEditPopup && <EditPopup onClose={this.onClosePopup} record={this.selectedRecord} />}
			</div>
		);
	}
}
