import { observable, computed } from 'mobx';
import { Vector as VectorSource } from 'ol/source';
import Feature from 'ol/Feature';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';

import { geoJSON } from '@smartplatform/map/client';
import store from 'client/store';

export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_SELFISOLATED = 'isolated';
export const STATUS_CONTACTED = 'contacted';
export const STATUS_SUSPECTED = 'suspected';
export const STATUS_RECOVERED = 'recovered';
export const STATUS_DESEASED = 'deceased';

const TYPES = [
	STATUS_CONFIRMED,
	STATUS_SELFISOLATED,
	STATUS_CONTACTED,
];

export const ISOLATION_SELF = 'self';
export const ISOLATION_OBSERVATOR = 'observator';
export const ISOLATION_INFDEP = 'infdep';
export const ISOLATION_NONE = 'none';

const getSwitchId = (function () {
	let id = 1;
	return () => id++;
})();

const TYPE_FILTER = {
	[STATUS_CONFIRMED]: { confirmed: { gt: 0 } },
	[STATUS_SELFISOLATED]: { onlySelf: { gt: 0 } },
	[STATUS_CONTACTED]: { contacted: { gt: 0 } }
};

const TYPE_ORDER = {
	[STATUS_CONFIRMED]: 'confirmed asc',
	[STATUS_SELFISOLATED]: 'onlySelf asc',
	[STATUS_CONTACTED]: 'contacted asc',
};

const ADDRESS_FIELDS = {
	[STATUS_CONFIRMED]: 'confirmed',
	[STATUS_SELFISOLATED]: 'onlySelf',
	[STATUS_CONTACTED]: 'contacted',
};

export default class CovidStore {

	@observable map = null;
	@observable isInitialized = false;
	@observable markersLoaded = false;
	@observable county = null;
	@observable type = STATUS_CONFIRMED;
	@observable selectedAddress = null;

	cases = [];

	statuses = {};
	statusesById = {};
	isolationStatuses = {};
	isolationStatusesById = {};
	switchId = 0;
	layer = null;

	init = async () => {
		const statuses = await store.model.Status.find({ order: 'id asc' });
		statuses.forEach(status => this.statuses[status.code] = status);
		statuses.forEach(status => this.statusesById[status.id] = status);
		const isolationStatuses = await store.model.Isolation.find({ order: 'id asc' });
		isolationStatuses.forEach(status => this.isolationStatuses[status.code] = status);
		isolationStatuses.forEach(status => this.isolationStatusesById[status.id] = status);
		this.isInitialized = true;
	};

	switchType = type => {
		this.type = type;
		this.switchId = getSwitchId();
		console.log('switchId', this.switchId);
		this.loadCases();
	};

	onMapInit = async mapStore => {
		this.mapStore = mapStore;
		this.map = mapStore.map;

		// this.countiesLayer = new CountiesLayer();
		// this.countiesLayer.init(mapStore);

		this.source = new VectorSource();

		await this.loadCases();
	}

	loadCases = async () => {
		const and = [ { geo: { neq: null }} ];
		if (this.county) and.push({ countyId: this.county.id });

		console.log('>', this.type, TYPE_FILTER[this.type], TYPE_ORDER[this.type]);

		this.cases = await store.model.ViewAddress.find({
			where: {
				and: [
					...and,
					TYPE_FILTER[this.type],
				]
			},
			fields: ['id', 'geo', 'confirmed', 'contacted', 'onlySelf'],
			order: TYPE_ORDER[this.type],
		});
		this.markersLoaded = true;

		const countVariants = { 1: { index: 0, text: 1 }};
		let index = 1;
		for (let i = 0, count = this.cases.length; i < count; i++) {
			const record = this.cases[i];
			const count = parseInt(record[ADDRESS_FIELDS[this.type]]) || 0;
			if (!countVariants[count] && count > 1) {
				countVariants[count] = { index: index++, text: count };
			}
		}
		console.log('countVariants', countVariants);

		const features = [];
		for (let record of this.cases) {
			const { geo, id, confirmed, contacted, onlySelf } = record;
			const geometry = geoJSON.readGeometry(geo);
			const count = record[ADDRESS_FIELDS[this.type]];

			if (count === 0) continue;
			// TODO: отменять предыдущий вызов при медленном соединении

			const feature = new Feature({
				id,
				geometry,
				confirmed,
				contacted,
				onlySelf,
				record,
				coordsIndex: countVariants[count].index,
				onClick: this.onClick,
			});

			features.push(feature);
		}

		this.confirmedTextureData = this.createTexture(Object.values(countVariants));
		// console.log('confirmedTexture', this.confirmedTextureData);

		this.source.clear();
		this.source.addFeatures(features);

		this.createLayer();
	};

	createLayer = () => {
		const { texture, variantWidth } = this.confirmedTextureData;

		if (this.layer) {
			this.mapStore.removeLayer(this.layer);
		}

		this.layer = new WebGLPointsLayer({
			source: this.source,
			// disableHitDetection: true,
			style: {
				symbol: {
					symbolType: 'image',
					src: texture,
					size: 28,
/*
					size: [
						'interpolate',              // transform operator
						['exponential', 2.5],       // [ type, base ]
						['zoom'],                   // input
						2,                          // stop1
						18,                          // output1
						14,                         // stop2
						28,                         // output2
					],
*/
					rotateWithView: false,
					offset: [0, 0],
					textureCoord: [
						'array',
						['*', ['get', 'coordsIndex'], variantWidth],
						0,
						['*', ['+', ['get', 'coordsIndex'], 1], variantWidth],
						1,
					],
				},
			}
		});

		this.layer.setZIndex(10);
		this.mapStore.addLayer(this.layer);
	};

	onClick = feature => {
		const address = feature.getProperties().record;
		console.log('address', address);
		this.selectedAddress = address;
	};

	createTexture = (variants) => {
		const size = 28;
		const count = variants.length;
		const variantWidth = count > 0 ? 1 / count : 1;

		const canvas = document.createElement('canvas');
		canvas.width = size * count;
		canvas.height = size;
		const ctx = canvas.getContext('2d');

		const data = {
			variantWidth,
			coords: {},
		};

		const { color, stroke, textColor } = this.getColors();

		for (let i = 0; i < count; i++) {
			const { text, index } = variants[i];

			ctx.globalAlpha = 1;

			ctx.fillStyle = color;
			ctx.strokeStyle = stroke;
			ctx.lineWidth = 1;

			ctx.beginPath();
			ctx.arc(size / 2 + index * size, size / 2, size / 2 - 2, 0, 2 * Math.PI);
			ctx.fill();
			ctx.stroke();

			ctx.fillStyle = textColor;
			ctx.font = '12px sans-serif';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(text, size / 2 + index * size, size / 2);

			data.coords[variants[i]] = [variantWidth * i, 0, variantWidth, 1];
		}

		const container = document.getElementsByClassName('main-layout')[0];
		canvas.style.width = size * count + 'px';
		canvas.style.height = size + 'px';
		canvas.style.position = 'absolute';
		canvas.style.left = '100px';
		canvas.style.top = '100px';
		canvas.style.zIndex = 5000;

		if (this.textureCanvas) {
			// container.removeChild(this.textureCanvas);
		}
		// container.appendChild(canvas);
		this.textureCanvas = canvas;

		data.texture = canvas.toDataURL();

		return data;
	};

	getColors = () => {
		if (this.type === 'confirmed') {
			return {
				color: '#d00',
				stroke: '#a00',
				textColor: '#fff',
			};
		}
		else if (this.type === 'isolated') {
			return {
				color: '#acf',
				stroke: '#0af',
				textColor: '#000',
			};
		}
		else if (this.type === 'contacted') {
			return {
				color: '#fc0',
				stroke: '#da0',
				textColor: '#000',
			};
		}
		return {
			color: '#d00',
			stroke: '#a00',
			textColor: '#fff',
		};
	};

	getLayerInstance = instance => {
		console.log('getLayerInstance', instance);
	};

	onMarkerClick = async (markers) => {
		console.log('onMarkerClick', markers);
		// const adr = await store.model.ViewAddress.findById(address.id);
		// console.log('...', adr);
	};

	getStatusId = code => this.statuses[code].id;
	getIsolationId = code => this.isolationStatuses[code].id;
	getStatusById = id => this.statusesById[id];
	getIsolationById = id => this.isolationStatusesById[id];
}
