import React from 'react';
import { Button, Column, Pager, Table } from '@smartplatform/ui';
import t from 'i18n';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NOTIFY_COLORS } from "client/tools/notifyStatus";
import { observable } from "mobx";
import { faEnvelope, faPhone, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import store from 'client/store'
import NotifyPopup from "./notify";

const PER_PAGE = 5;

@observer
export class MessageList extends React.Component {
    @observable messages = null;
    @observable page = 1;
    @observable isInitialized = false;
    @observable showPopup = false;

    constructor(props) {
        super(props);
        this.record = this.props.record;
        this.init();
    }

    init = async () => {
        this.messages = await store.model.NotifyLog.find({
            where : {
                caseId:this.record.id
            },
            include: [
                { relation: 'status', scope: { fields: ['id', 'name', 'code'] } },
                { relation: 'type', scope: { fields: ['id', 'name', 'code'] } }
            ],
            limit: PER_PAGE,
            skip: (this.page - 1) * PER_PAGE,
            fields:['id', 'date', 'statusId', 'phone', 'text', 'response', 'typeId', 'accepted'],
            order: 'date desc'
        });
        this.isInitialized = true
    }

    onPageChange = page => {
        this.page = page;
        this.init();
    };

    renderNotifyStatus = record => {
        const status = record.status;
        let title = '';
        if (record.response) {
            title += record.response;
        }

        let icon;
        if (record.type && record.type.code === 'sms') icon = faEnvelope;
        if (record.type && record.type.code === 'phone') {
            if (record.accepted === false) icon = faPhoneSlash;
            else icon = faPhone;
        }

        return <>
            {status.name && <div title={title}>{status.name} <FontAwesomeIcon color={NOTIFY_COLORS[status.code]} icon={icon}/></div>}
        </>
    }

    onToggleShowPopup = boolean => this.showPopup = boolean;

    onClosePopup = () => {
        this.onToggleShowPopup(false);
        this.page = 1;
        this.init()
    };

    render() {
        if (!this.isInitialized) return <div>Загрузка...</div>;

        return <div className='notify-message-list'>
            <div className='top'>
                <Pager current={this.page} onChange={this.onPageChange} itemsPerPage={PER_PAGE} totalCount={this.messages.totalCount}/>
                <Button onClick={() => this.onToggleShowPopup(true)} variant='primary'>{t('case.sendMessage')}</Button>
                {this.showPopup && <NotifyPopup record={this.record} onClose={this.onClosePopup}/>}
            </div>
            <Table rows={this.messages} multiline>
                <Column width={100} property="date" label={t('notifyLog.date')}/>
                <Column width={80} property="phone" label={t('notifyLog.phone')}/>
                <Column width={100} computed={this.renderNotifyStatus} label={t('notifyStatus.status')}/>
                <Column property="text" label={t('notifyLog.text')}/>
            </Table>
            </div>
    }
}
