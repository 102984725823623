import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import FireDep from './firedep/FireDep';

export default ({ match }) => {
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact render={(routeProps) => <List {...routeProps} path={path} />} />
			<Route path={`${path}/:id`} render={(routeProps) => <FireDep {...routeProps} path={path} />} />
		</Switch>
	);
};
