import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FilterLayout } from '..';
import t from 'i18n';
import './style.scss';
import { Checkbox, RecordSelect } from '@smartplatform/ui';
import { renderStatus } from 'client/tools';
import store from 'client/store';
import { observable } from 'mobx';

@observer
export default class AdpiFilter extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	static propTypes = {
		layer: PropTypes.object,
	};

	init = async () => {
		const { organizationId } = this.props.layer.settings.filter;
		this.record = organizationId ? await store.model.FireAlarmOrganizationAdpiService.findById(organizationId, { fields: ['id', 'name'] }) : null;
	};

	onOrgChange = (record) => {
		this.record = record;
		this.props.layer.onOrgChange(record);
	};

	render() {
		const { layer, statuses } = this.props;
		const { onStatusToggle } = layer;
		const { selectedStatuses } = layer.settings.filter;

		return (
			<FilterLayout layer={layer} title={t('adpiDevice.plural')} className='adpi-filter'>
				<h4>{t('fireAlarmOrganizationAdpiService.title')}</h4>
				<RecordSelect
					model={store.model.FireAlarmOrganizationAdpiService}
					filter={{ fields: ['id', 'name'] }}
					onChange={this.onOrgChange}
					value={this.record}
					property='name'
					empty={t('all')}
				/>
				<h4>{t('adpiDeviceStatus.plural')}</h4>
				{statuses.map((status) => (
					<Checkbox
						key={status.id}
						value={!!selectedStatuses.find((i) => i === status.id)}
						onChange={(v) => onStatusToggle(status.id, v)}
						label={renderStatus(status)}
					/>
				))}
			</FilterLayout>
		);
	}
}
