import { inject, observer } from 'mobx-react';
import React from 'react';
import { Attachments } from 'client/components';
import { Members } from './Members';
import { Supplies } from './Supplies';
import { Main } from './Main';
import './style.scss';
import template from './template.xlsx';

@inject('store')
@observer
export class MainInfo extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { record, enableForCustomer, isNew } = this.props.store;

		return (
			<div className='main-info'>
				<Main />
				{/* для предзащиты, потом сделать нормально */}
				<div className='row'>
					<div style={{ width: 420, paddingLeft: 15 }}>
						<Attachments record={record} canUpload={!enableForCustomer} withFormContext={isNew} />
					</div>
					<div className='col-4'>
						<a href={template} style={{ textDecoration: 'underline' }}>
							Шаблон (template.xlsx)
						</a>
					</div>
				</div>
				{!isNew && <Supplies />}
				<Members />
			</div>
		);
	}
}
