import React from 'react';
import { observable } from 'mobx';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle, Text } from 'ol/style';
import { asArray } from 'ol/color';

import { ColorUtils } from '@smartplatform/ui';
import { geoJSON, getFeatureCenter, drawGeoMarker } from '@smartplatform/map/client';
import icons from './icons';
import store from 'client/store';
import { getStatusColor } from '../../helpers';
import { getFireIconName } from './getFireIconName';
import FirePopup from './FirePopup';
import t from 'i18n';

export default class Fires {

	@observable fires = [];

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(10);
		this.setVisible();
		this.mapStore.addLayer(this.layer);

		this.load();
	};

	load = async () => {
		let visibleStatuses = [];
		const statuseCodes = Object.keys(this.settings.status).filter((statusCode) => !!this.settings.status[statusCode]);

		const modelName = store.isPublic ? 'ViewFireStatus' : 'FireStatus';

		if (this.settings.show && statuseCodes.length > 0) {
			visibleStatuses = await store.model[modelName].find({
				where: { code: { inq: statuseCodes } },
				include: [
					{
						relation: 'fires',
						scope: {
							where: { geo: { neq: null } },
							fields: ['id', 'geo', 'statusId', 'forestOwnershipId', 'zoneId', 'forestryFireNumber'],
							include: [
								{ relation: 'status', scope: { fields: ['id', 'name', 'code', 'color'] } },
								{ relation: 'zone', scope: { fields: ['id', 'code', 'shortName', 'color'] } },
								{ relation: 'forestOwnership', scope: { fields: ['id', 'name'] } },
							],
						},
					},
				],
			});
		}

		// console.log('visibleStatuses', visibleStatuses, this.layer.getRenderer().container);
		const fires = [];
		for (let status of visibleStatuses) {
			fires.push(...status.fires());
		}

		const features = [];
		for (let fire of fires) {
			const { geo, zone, forestryFireNumber } = fire;
			const zoneName = zone?.shortName;
			const color = getStatusColor(fire);
			const iconSrc = icons[getFireIconName(fire)].src || icons.default;
			const { r, g, b } = ColorUtils.hexToRgb(color);
			let styleParams = {
				fill: new Fill({ color: asArray([r, g, b, 0.4]) }),
				stroke: new Stroke({ color, width: 1 }),
			};
			let pointStyle;
			if (geo.type === 'Point') pointStyle = new Style({ image: new CircleStyle({ radius: 10, ...styleParams }) });

			const marker = drawGeoMarker(geo, {
				data: {
					title: t('fire.title'),
					record: fire,
					render: this.renderPopup,
				},
				style: new Style({
					...styleParams,
					image: new Icon({
						src: iconSrc,
					}),
					text: new Text({
						textAlign: 'left',
						font: '11px/14px sans-serif',
						text: `${forestryFireNumber || ''}${zoneName || ''}`,
						offsetX: 6,
						offsetY: -12,
						scale: 1.5,
					}),
				}),
				onlyMarker: store.isPublic,
				// pointStyle,
			});

			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
	};
	
	renderPopup = (record) => {
		console.log('renderPopup', record);
		return <FirePopup record={record} />;
	};
	
	// отключено, см. общий обработчик в fireStore
	onClick = async (feature) => {
		const { id } = feature.getProperties();
		if (!id) return;
		const coordinates = getFeatureCenter(feature);
/*
		this.mapStore.popup = {
			type: 'fire',
			fireId: id,
			coordinates,
		};
*/
	};
}
