import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import './style.scss';

export default () => (
	<div className='vehicles'>
		<Switch>
			<Route path='/techfires/firedep/:fireDepId/vehicles' exact render={(routeProps) => <List {...routeProps} />} />
			<Route path='/techfires/firedep/:fireDepId/vehicles/create' render={(routeProps) => <Edit {...routeProps} />} />
			<Route path='/techfires/firedep/:fireDepId/vehicles/:id' render={(routeProps) => <Edit {...routeProps} />} />
		</Switch>
	</div>
);
