import React from 'react';
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";

import { geoJSON, drawGeoMarker } from '@smartplatform/map/client';
import { Icon, Style } from "ol/style";
import { asArray } from "ol/color";
import icon from './ship.svg';
import ShipPopup from './ShipPopup';
import t from 'i18n';

export default class ShipsLayer {

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(10);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
		this.load();
	};

	load = async () => {
		const features = [];

		const example = {
			COURSE: "8",
			ELAPSED: "7",
			HEADING: null,
			LAT: "73.81834",
			LON: "73.14",
			SHIPNAME: "[SAT-AIS]",
			SHIPTYPE: "8",
			SHIP_ID: "TXpVMk9EUTFNelUyT0RRMU16VTJPQT09LTNTM0FlMG1qdnFjK0IrZzBtRklZdGc9PQ==",
			SPEED: "160",
			STATUS_NAME: "Underway using Engine",
			TYPE_IMG: "8",
			TYPE_NAME: "Tanker",
		};

		try {
			const headers = new Headers();
			headers.append('pragma', 'no-cache');
			headers.append('cache-control', 'no-cache');
			const res = await fetch('/api/ships', { headers });
			const tiles = await res.json();
			console.log('tiles', tiles);

			for (let tile of tiles) {
				for (let ship of tile) {
					const geo = { type: 'Point', coordinates: [ ship.LON, ship.LAT ]};
					const marker = drawGeoMarker(geo, {
						data: {
							title: t('ship.title'),
							record: { type: 'ship', id: ship.SHIP_ID, ...ship },
							render: this.renderPopup,
						},
						style: new Style({
							image: new Icon({
								src: icon,
								rotation: ship.COURSE ? Math.PI * ship.COURSE / 180 : 0,
							}),
						}),
					});

					features.push(...marker);
				}
			}
		}
		catch (e) {
			console.warn('/api/ships', e);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	setVisible = () => {
		// this.layer.setVisible(true);
		this.layer.setVisible(this.settings.show);
	};

	renderPopup = (data) => <ShipPopup record={data} />;

}
