import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import counties from './counties-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { executionPlanReport } from '../../report/execution-plan-report';

@inject('store')
@observer
export class ExecutionPlan extends React.Component {
	@observable current = null;
	@observable currentOver = null;
	@observable currentPosition = null;
	@observable table = null;

	constructor(props) {
		super(props);
		this.store = props.store;
	}

	showPopup = (e, county) => {
		this.currentPosition = e.currentTarget.getBoundingClientRect();
		this.current = county;
		this.table = executionPlanReport(county.cities);
	};

	showOver = (county) => {
		this.currentOver = county;
	};

	hidePopup = () => {
		this.currentPosition = null;
		this.current = null;
		this.table = null;
	};

	hideOver = () => {
		this.currentOver = null;
	};

	render() {
		const { countiesWithExecutionPlan } = this.store;
		const max = Math.max.apply(
			Math,
			countiesWithExecutionPlan.map((c) => c.__ratio)
		);

		return (
			<div className='execution-plan card'>
				<h1>Исполнение плана завоза</h1>
				<hr />
				<div className='plan-map'>
					<div className='text-center' style={{ minHeight: 17 }}>
						{this.currentOver && <h1> {this.currentOver.name}</h1>}
					</div>
					<svg version='1.2' baseProfile='tiny' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='1000px' height='500px' viewBox='0 0 430 372'>
						{countiesWithExecutionPlan.map((county) => {
							const ratio = max !== 0 ? county.__ratio / max : 0;

							const props = {
								fill: `rgba(255, ${Math.round(170 - ratio * 128)}, 75, 0.5)`,
								onMouseEnter: () => this.showOver(county),
								onMouseLeave: () => this.hideOver(county),
								onClick: (e) => this.showPopup(e, county),
							};
							const County = counties[county.id];
							return County ? <County key={county.id} {...props} /> : null;
						})}
					</svg>
					<div className='info'>
						{this.currentOver && (
							<div>
								<strong>{this.currentOver.name}</strong>
								<div>
									Выполнение: <em>{this.currentOver.__ratio ? (this.currentOver.__ratio * 100).toFixed(2) : 0}%</em>
								</div>
								<div>
									План: <em>{this.currentOver.plan || 0}</em>
								</div>
								<div>
									Отгружено: <em>{this.currentOver.ship || 0}</em>
								</div>
								<div>
									Доставлено: <em>{this.currentOver.deliver || 0}</em>
								</div>
							</div>
						)}
					</div>
					{this.currentPosition && (
						<span className='info sp-popup-window position-absolute' style={{ top: this.currentPosition.top, left: this.currentPosition.left }}>
							<div>
								<h1 className='text-center'>{this.current.name}</h1>
								<div>
									Выполнение: <em>{this.current.__ratio ? (this.current.__ratio * 100).toFixed(2) : 0}%</em>
								</div>
								<div className='sp-popup-close-btn text-black-50' onClick={this.hidePopup}>
									<FontAwesomeIcon icon={faTimes} />
								</div>
							</div>
							<div style={{ fontSize: 15 }}>{this.table.content && <div dangerouslySetInnerHTML={{ __html: this.table.content }} />}</div>
						</span>
					)}
				</div>
			</div>
		);
	}
}
