import React from 'react';
import { Form as Form_ } from '@smartplatform/ui';
import { Controls } from './controls';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import './style.scss';

export class Form extends React.Component {
	static propTypes = {
		noSave: PropTypes.bool,
		noDelete: PropTypes.bool,
		noCancel: PropTypes.bool,
		noClean: PropTypes.bool,
		saveTitle: PropTypes.string,
		deleteTitle: PropTypes.string,
		cancelTitle: PropTypes.string,
		cleanTitle: PropTypes.string,
		onCancel: PropTypes.func,
		disableSave: PropTypes.bool,
		disableDelete: PropTypes.bool,
		className: PropTypes.string,
	};

	static defaultProps = {};

	constructor(props) {
		super(props);
		this.formElementRef = React.createRef();
	}

	render() {
		const {
			children,
			noSave,
			noDelete,
			noClean,
			noCancel,
			saveTitle,
			deleteTitle,
			cancelTitle,
			cleanTitle,
			onCancel,
			onClean,
			disableSave,
			disableDelete,
			className,
			controls,
			...formProps
		} = this.props;

		const className_ = classNames(className, { 'no-form-submit': noCancel && noDelete && noSave });

		const controlsProps = {
			noSave,
			noDelete,
			noCancel,
			noClean,
			saveTitle,
			deleteTitle,
			cancelTitle,
			cleanTitle,
			onCancel,
			onClean,
			disableSave,
			disableDelete,
			controls,
			formElementRef: this.formElementRef,
		};

		return (
			<Form_ noDelete noSave stay className={className_} {...formProps} controls={<Controls {...controlsProps} />}>
				<div ref={this.formElementRef}>{children}</div>
			</Form_>
		);
	}
}
