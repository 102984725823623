import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Address } from '../single-page';
import { ObjectsList } from './ObjectsList';

export * from './ObjectsList';

export default (props) => {
	console.log('objects-------->', props);

	return (
		<Switch>
			<Route path='/techfires/addresses-objects' exact render={(routeProps) => <ObjectsList {...routeProps} />} />
			<Route path='/techfires/addresses-objects/page/:page' render={(routeProps) => <ObjectsList {...routeProps} />} />
			<Route path='/techfires/addresses-objects/create' render={(routeProps) => <div>12erwerwe</div>} />
		</Switch>
	);
};
