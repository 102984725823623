import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { Button, Column, RecordSelect, Table, RelationSelect, Input } from '@smartplatform/ui';
import store from 'client/store';
import { fireDepSpecialTypeItem } from 'techfires/helpers';
import t from 'i18n';
import './style.scss';

@inject('fireDepStore')
@observer
export default class Vehicles extends React.Component {
	@observable vehicles = [];
	@observable filter = {};
	@observable brand = null;
	@observable type = null;
	@observable year = null;
	@observable date = null;

	constructor(props) {
		super(props);
		console.log(props);
		this.fireDepStore = props.fireDepStore;
		this.load();
	}

	load = async () => {
		const where = {
			fireDepId: this.fireDepStore.fireDep.id,
		};

		if (this.brand) {
			where.brandId = this.brand.id;
		}
		if (this.date) {
			where.year = this.date.year;
		}
		if (this.type) {
			where.typeId = this.type.id;
		}

		this.vehicles = await store.model.FireDepVehicle.find({
			where,
			include: [
				{ relation: 'brand', scope: { fields: ['id', 'name'] } },
				{ relation: 'cisternBulk', scope: { fields: ['id', 'volume'] } },
				{ relation: 'foamCapacity', scope: { fields: ['id', 'capacity'] } },
				{ relation: 'pump', scope: { fields: ['id', 'capacity'] } },
				{ relation: 'status', scope: { fields: ['id', 'name'] } },
				{ relation: 'callSign', scope: { fields: ['id', 'name'] } },
				{
					relation: 'type',
					scope: {
						fields: ['id', 'name', 'specialTypeId'],
						include: [{ relation: 'specialType', scope: { fields: ['id', 'name'] } }],
					},
				},
			],
		});
	};

	addVehicle = () => {
		const { params } = this.props.match;
		store.route.push({ path: `/techfires/firedep/${params.fireDepId}/vehicles/create` });
	};

	editVehicle = (record) => {
		const { params } = this.props.match;
		store.route.push({ path: `/techfires/firedep/${params.fireDepId}/vehicles/${record.id}` });
	};

	onQueryUpdate = (query) => (this.filter = query);

	onChange = (property) => (value) => {
		this[property] = value;
		this.load();
	};

	render() {
		return (
			<div className='vehicles'>
				<div className='vehicles-filters'>
					<RecordSelect
						model={store.model.FireDepVehicleBrand}
						value={this.brand}
						onChange={this.onChange('brand')}
						property='name'
						itemsPerPage={100}
						noTotal
						noSearch
						placeholder={t('fireDepVehicleBrand.chooseBrand')}
					/>
					<RecordSelect
						model={store.model.FireDepVehicleType}
						value={this.type}
						onChange={this.onChange('type')}
						property='name'
						itemsPerPage={100}
						noTotal
						noSearch
						placeholder={t('fireDepVehicleType.chooseType')}
					/>
					<RecordSelect
						model={store.model.FireDepVehicle}
						value={this.date}
						onChange={this.onChange('date')}
						computed={({ year }) => <>{year ? year : null}</>}
						itemsPerPage={100}
						noTotal
						noSearch
						placeholder={t('fireDepVehicle.chooseType')}
					/>
					<Button onClick={this.addVehicle} variant='primary'>
						{t('create')}
					</Button>
				</div>
				<Table rows={this.vehicles} noHeader onRowClick={this.editVehicle} onCreate={this.addVehicle}>
					<Column property='stateNumber' label={t('vehicle.stateNumber')} />
					<Column relation='brand' property='name' label={t('fireDepVehicle.brand')} saveOnChange>
						<RelationSelect />
					</Column>
					<Column relation='type' property='name' label={t('fireDepVehicle.type')} saveOnChange>
						<RelationSelect />
					</Column>
					<Column relation='type' computed={fireDepSpecialTypeItem} label={t('fireDepVehicle.specialType')} />
					<Column relation='cisternBulk' property='volume' label={t('vehicle.capacity')} />
					<Column property='actualFoamValue' label={t('vehicle.actualFoamValue')} />
					<Column relation='pump' property='capacity' label={t('pump.title')} />
					<Column relation='status' property='name' label={t('fireDepVehicleStatus.title')} />
					<Column property='year' label={t('vehicle.year')} saveOnBlur>
						<Input />
					</Column>
					<Column relation='callSign' property='name' label={t('vehicle.callSign')} saveOnChange>
						<RelationSelect filter={{where: { fireDepId: this.fireDepStore.fireDep.id  } }} />
					</Column>
				</Table>
			</div>
		);
	}
}
