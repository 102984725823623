import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { TextEncoder } from 'text-encoding';
import { saveAs } from 'file-saver';

import { Loader, Kanban } from '@smartplatform/ui';
import { Panel } from 'components';
import AppealCard from './appeal-card';
import store from 'client/store';
import { formatDate, fio } from 'client/tools';
import kanbanStore, { INITIAL_LIMIT } from './store';
import t from 'i18n';
import './style.scss';

const defaultPriority = 10000;

@observer
export default class AppealsKanban extends React.Component {

	constructor(props) {
		super(props);
		if (!kanbanStore.isInitialized) kanbanStore.init();
	}

	renderItem = (appeal, list, options) => <AppealCard appeal={appeal} list={list} {...options} />;
	
	@action onChange = async ({ item, text, prev, next, list, index }) => {
		console.log('onChange', item, prev, next, list, index);
		kanbanStore.saving = true;
		
		const appeal = new store.model.Appeal(item);
		appeal.statusId = list.id;

		if (!item) {
			appeal.title = text;
		}
		
		if (!prev) {
			appeal.priority = next ? next.priority * 2 : defaultPriority;
		}
		else if (!next) {
			appeal.priority = prev ? prev.priority / 2 : defaultPriority;
		}
		else {
			appeal.priority = (prev.priority + next.priority) / 2;
		}
		
		try {
			await appeal.save();
			kanbanStore.saving = false;
		}
		catch (e) {
			kanbanStore.saving = false;
			throw e;
		}
		
		return {
			id: appeal.id,
			listId: appeal.statusId,
			path: `/appeals/${appeal.id}`,
			data: appeal,
		};
	};
	
	gotoItem = path => store.route.push({ path });
	
	fakeDelay = (delay = 1000) => new Promise(resolve => setTimeout(resolve, delay));
	
	getInstance = instance => kanbanStore.kanban = instance;

	exportList = async (list) => {
		const where = kanbanStore.compileFilter();
		const tasks = await store.model.ViewAppeal.find({
			where: {
				and: [
					...where.and,
					{ statusId: list.id },
				],
			},
			include: ['organization', 'category'],
			order: 'priority desc',
		});
		console.log('exportList', list, tasks);
		if (tasks.length > 0) {
			let csvContent = '#;"Номер";"Заголовок";"Категория";"Организация";"Обновлено"\r\n';
			tasks.forEach((appeal, i) => {
				const data = [
					i + 1,
					appeal.id,
					'"' + (appeal.title ? appeal.title.replace(/"/mg, '""') : '') + '"',
					// appeal.user ? '"' + fio(appeal.user) + '"' : '',
					appeal.category ? '"' + appeal.category.name + '"' : '',
					appeal.organization ? '"' + appeal.organization.name + '"' : '',
					formatDate(appeal.updatedAt),
				];
				const row = data.join(';');
				csvContent += row + '\r\n';
			});
			let csv = new TextEncoder('windows-1251', { NONSTANDARD_allowLegacyEncoding: true }).encode([csvContent]);
			let blob = new Blob([csv], { type: 'text/csv;charset=windows-1251' });
			saveAs(blob, `${list.name}.csv`);
		}

	};
	
	render() {
		if (kanbanStore.isLoading) return <Panel full header={t('appeal.kanban')}><Loader /></Panel>;
		if (!kanbanStore.project) return <Panel full header={t('appeal.kanban')}>...</Panel>;

		const project = {
			name: t('appeal.plural'),
			lists: kanbanStore.project.map(list => {
				return {
					id: list.id,
					name: list.name,
					color: list.color,
					totalCount: list.totalCount,
					data: list,
					items: list.appeals().map(appeal => ({
						id: appeal.id,
						listId: appeal.statusId,
						path: `/appeals/${appeal.id}`,
						data: appeal,
					})),
				};
			})
		};
		
		return <Panel full header={t('appeal.kanban')}>
			<div className="appeals-kanban">
				{kanbanStore.project.length > 0 ?
					<Kanban
						key={kanbanStore.project.id}
						project={project}
						renderItem={this.renderItem}
						itemClassName="appeal-card"
						onChange={this.onChange}
						instance={this.getInstance}
						loadMore={kanbanStore.loadMore}
						onError={kanbanStore.onError}
						gotoItem={this.gotoItem}
						itemsLimit={INITIAL_LIMIT}
						exportList={this.exportList}
					/>
					:
					<>
						Не заданы состояния задач
					</>
				}
			</div>
		</Panel>;
	}
	
}
