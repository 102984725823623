import React from 'react';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';

import { Button, Form, Row, Field, Tabs, Tab, Input, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import FireDepSentryStore from './store';
import Personnel from './Personnel';
import Vehicles from './Vehicles';
import t from 'i18n';

@observer
export default class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.store = new FireDepSentryStore(props);
	}

	render() {
		const {
			record,
			changed,
			onGlobalSentryChange,
			onNameChange,
			onUnitsChange,
			onSave,
			savePersonnelAndVehicles,
			revertChanges,
			updates,
			isSaving,
			goBack,
			personnel,
			vehicles,
		} = this.store;

		const { id, fireDepId } = this.props.match.params;
		if (!record) return null;

		const canSave = changed && ((record.name && record.name.trim().length > 0) || record.sentryId);
		const badgeStyle = { background: '#7a7a7a' };

		return (
			<div className='sentry-edit'>
				<div className='actions-bar'>
					<Button variant='primary' onClick={goBack}>
						{t('fireDepSentry.back')}
					</Button>
				</div>
				<Form record={record} disableSave={!canSave} stay onSave={onSave} onDelete={goBack}>
					<Row>
						<div className='form-field'>
							<label>{t('fireDepSentry.selectGlobalSentry')}</label>
							<RecordSelect
								model={store.model.TechFireSentry}
								property='name'
								value={record.globalSentry}
								onChange={onGlobalSentryChange}
								placeholder={t('techFireSentry.title')}
								width={200}
							/>
						</div>
						<Field property='name' label={t('fireDepSentry.setCustomName')}>
							<Input onChange={onNameChange} />
						</Field>
						<Field property='units' label={t('fireDepSentry.units')}>
							<Input onChange={onUnitsChange} />
						</Field>
						<div />
					</Row>
				</Form>

				{id ? (
					<div className={'personnel-and-vehicles' + (isSaving ? ' is-saving' : '')}>
						<Provider sentryStore={this.store}>
							<Tabs>
								<Tab
									path={`/techfires/firedep/${fireDepId}/sentries/${id}`}
									exact
									title={t('fireDep.personnel')}
									badge={personnel.length}
									className='sentry-tab'
								>
									<Personnel key={updates} />
								</Tab>
								<Tab
									path={`/techfires/firedep/${fireDepId}/sentries/${id}/vehicles`}
									title={t('fireDep.vehicles')}
									badge={vehicles.length}
									className='sentry-tab'
								>
									<Vehicles key={updates} />
								</Tab>
							</Tabs>
						</Provider>

						{updates > 0 && (
							<div className='actions'>
								<Button variant='primary' onClick={savePersonnelAndVehicles}>
									{t('save')}
								</Button>
								<Button onClick={revertChanges}>{t('cancel')}</Button>
							</div>
						)}
					</div>
				) : (
					<div className='first-hint'>{t('fireDepSentry.personnelHint')}</div>
				)}
			</div>
		);
	}
}
