export const OPER_INFO_INCLUDE = {
	include: [
		{ relation: 'county', scope: { fields: ['id', 'name'] } },
		{ relation: 'city', scope: { fields: ['id', 'name'] } },
		{ relation: 'river', scope: { fields: ['id', 'name'] } },
		{ relation: 'organization', scope: { fields: ['id', 'name'] } },
		{ relation: 'period', scope: { fields: ['id', 'name'] } },
		{ relation: 'owner', scope: { fields: ['id', 'name'] } },
		{ relation: 'category', scope: { fields: ['id', 'name'] } },
	],
};

export const ARCTIC_REGIONS = [
	'Абыйский улус',
	'Аллаиховский улус',
	'Анабарский улус',
	'Булунский улус',
	'Верхнеколымский улус',
	'Верхоянский улус',
	'Жиганский улус',
	'Момский улус',
	'Нижнеколымский улус',
	'Оленёкский улус',
	'Среднеколымский улус',
	'Усть-Янский улус',
	'Эвено-Бытантайский улус',
];
