import { Geometry } from 'wkx';

export const geoJsonToHex = (geoJson) => {
	if (!geoJson) return null;

	let hex = null;
	try {
		hex = Geometry.parseGeoJSON(geoJson).toEwkb().toString('hex')
	}
	catch (e) {
		console.warn('Invalid geoJSON:', geoJson, 'returning null...');
	}
	return hex;
}

export const hexToGeoJson = (hex) => {
	if (!hex) return null;

	let geoJson = null;
	try {
		geoJson = Geometry.parse(Buffer.from(hex, 'hex')).toGeoJSON();
	}
	catch (e) {
		console.warn('Invalid hex string:', hex, 'returning null...');
	}
	return geoJson;
}
