export const AVATAR_SIZE = 30;

// page
export const PER_PAGE = 10;
// modes
export const KANBAN = 'KANBAN';
export const LIST = 'LIST';
export const DEFAULT_PRIORITY = 10000;

export const SCHEDULE = 'SCHEDULE';
export const COST = 'COST';
export const INCOME = 'INCOME';
export const TABLE_ROW_HEIGHT = 40;
export const ALARM = 'ALARM';
export const ERROR = 'ERROR';
export const ADPI = 'ADPI';
export const TRUE = 'TRUE';
export const FALSE = 'FALSE';
export const CONNECTED = 'CONNECTED';
export const NOT_CONNECTED = 'NOT_CONNECTED';
export const CONNECTION_LOST = 'CONNECTION_LOST';
export const HEAD = 'HEAD';
export const UP = 'UP';
export const DOWN = 'DOWN';

export const PERIODS = {
	DAY: 'day',
	WEEK: 'week',
	MONTH: 'month',
	QUARTER: 'quarter',
	YEAR: 'year',
};
