import React from 'react';
import { Fill, Stroke, Style, Icon, Text } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import IsdmFirePopup from './IsdmFirePopup';
import icon from 'img/icons/shared/isdm.svg';
import t from 'i18n';

const color = '#0af';

export default {
	modelName: 'IsdmFire',
	title: t('isdm.title'),
	filterTitle: t('isdm.title'),
	showInFilters: true,
	style: function (feature, resolution) {
		const record = feature.getProperties().record;
		return new Style({
			fill: new Fill({ color: asArray([...ColorUtils.hexToArray(color).slice(0, 3), 0.3]) }),
			stroke: new Stroke({ color, width: 2 }),
			image: new Icon({ src: icon, anchor: [9, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
			text: new Text({
				textAlign: 'left',
				font: '11px/14px sans-serif',
				text: record.fireNumber,
				offsetX: 12,
				offsetY: -30,
				scale: 1.2,
				fill: new Fill({ color: '#333' }),
				stroke: new Stroke({ color: asArray([ 255,255,255, 0.5]), width: 1 }),
			}),
		});
	},
	icon,
	filter: { fields: ['id', 'geo', 'fireNumber'] },
	priority: 2,
	renderPopup: (record) => <IsdmFirePopup key={record.id} record={record} />,
};
