
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as dictionaries from './dictonaries-list'


export const ModelRoute = ({ path, modelName }) => {
	const { List, Edit } = dictionaries[modelName] ? dictionaries[modelName] : null;

	return <Switch>
		<Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} path={path} />} />
		<Route path={`${path}/page/:page`} children={({ match }) => <List match={match} modelName={modelName} path={path} />} />
		{Edit && <Route path={`${path}/:id`} children={({ match }) => <Edit match={match} modelName={modelName} path={path} />} />}
	</Switch>
};