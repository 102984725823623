import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';

import { Table, Pager, Button, Column, ColorValue } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

const PER_PAGE = 20;

@observer
export default class List extends React.Component {

	@observable records = [];
	@observable page = 1;
	@observable search = '';
	@observable isInitialized = false;

	constructor(props) {
		super(props);
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.init();
	}

	init = async () => {
		const trimmed = this.search.trim();
		const where = trimmed.length > 0 ? { search: `%${trimmed}%` } : undefined;

		this.records = await store.model.AppealSystem.find({
			where,
			order: 'id asc',
			limit: PER_PAGE,
			skip: (this.page - 1) * PER_PAGE,
		});
		this.isInitialized = true;
	};

	onSearch = (e) => {
		this.search = e.target.value;
		this.doSearch();
	}

	doSearch = () => {
		this.page = 1;
		this.init();
	};

	onPageChange = (page) => {
		this.page = page;
		this.init();
	};

	create = () => store.route.push({ path: '/appeals/dictionaries/systems/create' });
	edit = (record) => store.route.push({ path: `/appeals/dictionaries/systems/${record.id}` });

	render() {
		if (!this.isInitialized) return null;

		return <>
			<div>
				<input type="text" value={this.search} onChange={this.onSearch} placeholder={t('search')} />{' '}
				<Button onClick={this.create} className="btn-primary">{t('create')}</Button>
				<Pager current={this.page} onChange={this.onPageChange} itemsPerPage={PER_PAGE} totalCount={this.records.totalCount} />
			</div>
			<Table rows={this.records} onRowClick={this.edit}>
				<Column width={60} property="id" label={t('id')} />
				<Column property="name" label={t('name')} />
			</Table>
		</>;
	}

}
