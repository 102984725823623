import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import './style.scss';

export default () => <div className="sentries">
	<Switch>
		<Route path="/techfires/firedep/:fireDepId/sentries" exact render={props => <List {...props} />}/>
		<Route path="/techfires/firedep/:fireDepId/sentries/create" render={props => <Edit {...props} />}/>
		<Route path="/techfires/firedep/:fireDepId/sentries/:id" render={props => <Edit {...props} />}/>
	</Switch>
</div>;
