export const POPUP_PER_PAGE = 10;

export const PEOPLE_INCLUDE = {
	relation: 'people',
	scope: {
		include: [
			{ relation: 'formation', scope: { fields: ['id', 'shortName'] } },
			{ relation: 'type', scope: { fields: ['id', 'shortName'] } },
		],
		order: 'typeId desc, formationId desc, count desc',
	},
};
export const EXTINGUISHED_AGENTS_INCLUDE = {
	relation: 'extinguishingAgents',
	scope: {
		include: [
			{ relation: 'type', scope: { fields: ['id', 'name', 'shortName'] } },
			{ relation: 'formation', scope: { fields: ['id', 'shortName'] } },
		],
		order: 'typeId desc, formationId desc, count desc',
	},
};

export const AIRCRAFTS_INCLUDE = {
	relation: 'aircrafts',
	scope: {
		include: [{ relation: 'type', scope: { fields: ['id', 'name'] } }],
		order: 'typeId desc, count desc',
	},
};

export const FORCE_AVAILABILITY_INCLUDE = {
	include: [PEOPLE_INCLUDE, EXTINGUISHED_AGENTS_INCLUDE, AIRCRAFTS_INCLUDE],
};
