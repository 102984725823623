import React from 'react';
import './style.scss';
import { Members } from './Members';
import { Discussion } from 'components';
import { inject } from 'mobx-react';
import { RequestInfo } from './RequestInfo';

@inject('store')
export class Agreement extends React.Component {
	render() {
		const { fetchComments, record, comments } = this.props.store;
		const discussionProps = { fetchComments, record, comments };
		return (
			<div className='agreement'>
				<div className='left'>
					<Members />
					<Discussion {...discussionProps} />
				</div>
				<div className='right'>
					<RequestInfo />
				</div>
			</div>
		);
	}
}
