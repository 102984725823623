import React from 'react';
import { Checkbox, Column, Table } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store'

export class List extends React.Component {
	onDefaultChange = async (rec) => {
		const record = rec.record;
		record.default = !record.default;
		await record.save()
	}

	render() {
		return <>
			<h1>{t(`notifyType.plural`)}</h1>
			<Table model={store.model.NotifyType}>
				<Column width={50} property="code" label={t('code')}/>
				<Column property="name" label={t('notifyType.title')}/>
				<Column label={t('notifyType.default')}>
					{(rec) => <Checkbox value={rec.record.default} onChange={() => this.onDefaultChange(rec)}/>}
				</Column>
				<Column property="active" label={t('notifyType.active')}>
					<Checkbox disabled/>
				</Column>
			</Table>
		</>
	}
};

export const NotifyType = { List };