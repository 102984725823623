import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';

@observer
export default class AICategory extends React.Component {
	@observable appealText = '';
	@observable category = null;

	constructor(props) {
		super(props);
	}

	onAppealTextChange = (text) => {
		this.category = null;
		this.appealText = text.target.value;
	};

	getCategory = async () => {
		this.category = await store.model.Appeal.getCategory({ text: this.appealText });
	};

	render() {
		return (
			<>
				<h2 style={{ marginTop: 50 }}>Тест нейронки</h2>
				<textarea style={{ width: 100, display: 'block', margin: '10px 0' }} rows={2} value={this.appealText} onChange={this.onAppealTextChange} />
				<Button style={{}} onClick={this.getCategory}>
					{' '}
					Получить категорию{' '}
				</Button>
				<div style={{ margin: '10px 0' }}>
					<input
						style={{ width: 600 }}
						placeholder='Категория'
						row={1}
						disabled
						value={(this.category && (this.category.error ? this.category.error : this.category.categoryName)) || ''}
					/>
				</div>
			</>
		);
	}
}
