export const getVehiclesValues = (fireLogs) => {
    //* создаем объект, где в ключе будет строка вида c1f1t1 , куда просуммируем людей в поле current;
    const reducedObject = fireLogs.reduce((acc, next) => {
        next.vehicles().forEach((record) => {
            if (!record.crewTypeId && !record.formationId && !record.typeId) return acc;
            const accKey = 'c' + record.crewTypeId + 'f' + record.formationId + 't' + record.typeId;
            if (!acc[accKey]) {
                acc[accKey] = {
                    crewTypeId: record.crewTypeId,
                    crewTypeName: record.crewType?.name,
                    crewTypeShortName: record.crewType?.shortName,
                    crewTypeCode: record.crewType?.code,
                    formationId: record.formationId,
                    formationName: record.formation?.name,
                    formationShortName: record.formation?.shortName,
                    formationCode: record.formation?.code,
                    typeId: record.typeId,
                    typeName: record.type?.name,
                    typeShortName: record.type?.shortName,
                    typeCode: record.type?.code,
                    required: record.required,
                    plan: record.plan,
                    current: record.current,
                };
            } else {
                acc[accKey].current = record.current;
                acc[accKey].plan = record.plan;
                acc[accKey].required = record.required;
            }
        });
        return acc;
    }, {});
    //* избавимся от ключей и создаем массив
    return Object.keys(reducedObject).map((key) => reducedObject[key]);
};

// const fireLogs = [
//     {
//         vehicles: () => {
//             return [
//                 {
//                     crewTypeId: 1,
//                     formationId: 1,
//                     typeId: 1,
//                     current: 30,
//                     crewType: { shortName: 'К1', name: 'Команда1', code: 'MN' },
//                     formation: { shortName: 'Ф1', name: 'Формаирование1', code: 'OTHER' },
//                     type: { shortName: 'O', name: 'Огнетушитель', code: 'RO' },
//                     required: 10,
//                     plan: 10,
//                 }
//             ];
//         },
//     },

//     {
//         vehicles: () => {
//             return [
//                 {
//                     crewTypeId: 1,
//                     formationId: 1,
//                     typeId: 4,
//                     current: 50,
//                     crewType: { shortName: 'К1', name: 'Команда1', code: 'DPS' },
//                     formation: { shortName: 'Ф1', name: 'Формаирование1', code: 'INTEREGIONAL' },
//                     type: { shortName: 'O', name: 'Огнетушитель', code: 'BUL' },
//                     required: 14,
//                     plan: 33,
//                 },
//             ];
//         },
//     },
// ];
// console.log(getVehiclesValues(fireLogs));
