import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import RadiusButton from './RadiusButton';

const VALUES = [
	375,
	500,
	750,
	1000,
];

@inject('card') @observer
export default class RadiusSelector extends React.Component {

	render() {
		return <div className="radius-selector">
			{VALUES.map(value => <RadiusButton key={value} value={value} onClick={this.props.onChange} />)}
		</div>;
	}

}
