import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import HeatPointSelect from './heatPointSelect';

@inject('card') @observer
export default class Selection extends React.Component {

	constructor(props) {
		super(props);
		this.select = new HeatPointSelect(props.card);
	}

	render() {
		const props = {
			className: 'heat-points-selection',
			ref: this.select.onSelectElementMount,
			onMouseDown: this.select.onMouseDown,
			onMouseMove: this.select.onMouseMove,
			onMouseUp: this.select.onMouseUp,
		};

		return <div {...props}>
			<div className="selection-rect" ref={this.select.onSelectionMount} />
		</div>;
	}

}
