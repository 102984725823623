import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Field, Form, Popup, Row, NumberInput, RecordSelect } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import { CancelButton } from 'fires/components';
import { ALREADY_EXIST, INCORRECT_AIR, INCORRECT_EVACUATED, REQUIRED_CREWTYPE } from '../../constants';

@inject('card')
@observer
export default class PeoplePopup extends React.Component {
	@observable record = null;
	@observable availableFormations = null;

	constructor(props) {
		super(props);
		this.record = this.props.record;
		this.isNew = !this.record.id;
		this.records = !this.isNew ? this.props.records.filter((people) => people.id !== this.record.id) : this.props.records;
		if (!this.isNew) {
			this.getRecordRelationData();
		}
	}

	//* init
	getRecordRelationData = async () => {
		this.record.formation = await this.record.formation;
		this.record.crewType = await this.record.crewType;
		this.availableFormations = await this.record.crewType.formations();
	};

	get current() {
		return (+this.record.current || 0) + (+this.record.delivered || 0);
	}

	//* render
	renderCurrent = () => this.current;
	renderFormation = (record) => record?.formationShortName;
	renderCrewType = (record) => record?.crewTypeShortName;

	//* onchange
	onDeliveredChange = (delivered) => (this.record.delivered = delivered);
	onFormationChange = (formation) => (this.record.formation = formation);
	onCrewTypeChange = (crewType) => {
		this.record.crewType = crewType;
		if (crewType) {
			this.availableFormations = crewType.formations();
			this.record.formation = this.availableFormations.length > 0 ? this.availableFormations[0] : null;
		}
	};

	//* validation
	getValidationError() {
		//* число эвакуированных не должно быть больше числа работающих в текущий момент
		if ((+this.record.evacuated || 0) > this.current) return INCORRECT_EVACUATED;
		if (!this.record.crewTypeId) return REQUIRED_CREWTYPE;
		//* добавлено по воздуху не должно быть больше чем доставлено
		if ((+this.record.air || 0) > (+this.record.delivered || 0)) return INCORRECT_AIR;
		//* связки crewtypeid + formationid должны быть уникальны для динамики
		const alreadyExist = this.records.find((people) => people.crewTypeId === this.record.crewType?.id && people.formationId === this.record.formation?.id);
		if (alreadyExist) return ALREADY_EXIST;
	}

	render() {
		if (this.isLoading) return null;
		const title = this.props.record.id ? t('editRecord') : t('addRecord');
		const incorrectValueStyle = { color: '#d00', fontWeight: 'bold' };
		this.validationError = this.getValidationError();

		const controls = (
			<>
				<CancelButton onClick={this.props.onClose} />
				{this.validationError && <span className='validation-error'>{t('validation.' + this.validationError)}</span>}
			</>
		);

		return (
			<Popup onClose={this.props.onClose} className='edit-dynamic-people-popup fires-popup' width={600}>
				<h2>{title}</h2>
				<Form
					disabled={this.props.card.disabled}
					record={this.record}
					beforeSave={this.beforeSave}
					onSave={this.props.onClose}
					disableSave={!!this.validationError}
					stay
					noDelete
					controls={controls}
				>
					{!this.record.crewTypeShortName ? (
						<Row>
							<div className='form-field'>
								<label>{t('crewType.title_short')}</label>
								<RecordSelect
									noTotal
									maxItems={10}
									itemsPerPage={1000}
									model={store.model.CrewType}
									property='shortName'
									value={this.record.crewType}
									onChange={this.onCrewTypeChange}
									filter={{ include: 'formations' }}
									disabled={!this.isNew}
									isRequired
									noSearch
								/>
							</div>
							<div className='form-field'>
								<label>{t('fireFightingFormation.title_short')}</label>
								<RecordSelect
									noTotal
									maxItems={10}
									itemsPerPage={1000}
									model={store.model.FireFightingFormation}
									property='shortName'
									value={this.record.formation}
									onChange={this.onFormationChange}
									isRequired
									noSearch
									disabled={!this.record.crewType || !this.isNew}
									filter={{
										where: {
											id: this.availableFormations ? { inq: this.availableFormations.map((f) => f.id) } : null,
										},
									}}
								/>
							</div>
						</Row>
					) : (
						<Row>
							<Field computed={this.renderCrewType} label={t('crewType.title_short')} disabled />
							<Field computed={this.renderFormation} label={t('fireFightingFormation.title_short')} disabled />
						</Row>
					)}

					<Row>
						<Field property='delivered' label={t('fireFightingPeople.delivered')}>
							<NumberInput integerOnly positiveOnly />
						</Field>
						<Field property='air' label={t('fireFightingPeople.air_short')}>
							<NumberInput integerOnly positiveOnly style={this.validationError === INCORRECT_AIR ? incorrectValueStyle : {}} />
						</Field>
						<Field computed={this.renderCurrent} disabled label={t('fireFightingPeople.current_short')} />
					</Row>
					<Row>
						<Field property='required' label={t('fireFightingPeople.required')}>
							<NumberInput integerOnly positiveOnly />
						</Field>
						<Field property='plan' label={t('fireFightingPeople.plan')}>
							<NumberInput integerOnly positiveOnly />
						</Field>
						<Field property='evacuated' label={t('fireFightingPeople.evacuated_short')}>
							<NumberInput integerOnly positiveOnly style={this.validationError === INCORRECT_EVACUATED ? incorrectValueStyle : {}} />
						</Field>
					</Row>
				</Form>
			</Popup>
		);
	}
}
