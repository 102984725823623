import React from 'react';
import { observable } from 'mobx';

import store from 'client/store';

export const ALARM_TYPE_ADPI = 'ADPI';
export const ALARM_TYPE_112 = '112';
export const ALARM_TYPE_MANUAL = 'MANUAL';
export const ALARM_TYPE_PHONE = 'PHONE';

export default class TechFireStore {
	@observable id;
	@observable fire = null;
	@observable vehicles = [];
	@observable isLoading = true;
	@observable changed = false;

	constructor(id) {
		this.id = id;
		this.init();
	}

	init = async () => {
		console.log('--- init', this.id);
		if (this.id) {
			this.fire = await store.model.TechFire.findById(this.id, {
				include: [
					{
						relation: 'object',
						scope: {
							include: [
								{ relation: 'address', scope: { fields: ['id', 'name', 'geo'] } },
								{ relation: 'fireDep', scope: { fields: ['id', 'name'] } },
							],
						},
					},
					{ relation: 'source' },
					{ relation: 'rank' },
					{
						relation: 'alarmMessage',
						scope: {
							include: ['adpiDevice'],
						},
					},
				],
			});
			await this.updateVehicles();
		} else {
			this.fire = new store.model.TechFire();
		}
	};

	updateVehicles = async () => {
		const wayBills = await store.model.TechFireWayBill.find({
			where: { fireId: this.id },
			include: [{ relation: 'vehicles', scope: { fields: ['id'] } }],
		});
		const vehicles = [];
		for (let wayBill of wayBills) {
			vehicles.push(...wayBill.vehicles());
		}
		const allVehicles = await store.model.ViewFireDepVehicle.find({ where: { id: { inq: vehicles.map((v) => v.id) } } });
		// console.log('?', allVehicles.map(v => v.specialTypeCode));
		this.vehicles = allVehicles;
	};

	saveFire = async () => {
		const isNew = !this.fire.id;
		await this.fire.save();
		store.route.push({ path: isNew ? `/techfires/fires/${this.fire.id}` : '/techfires/fires' });
	};

	deleteFire = async () => {
		await this.fire.delete();
		store.route.push({ path: '/techfires/fires' });
	};

	clearMainInfo = () => {
		this.fire.phoneOperator = null;
	};

	cancelFire = () => {
		store.route.push({ path: '/techfires/fires' });
	};

	onChange = (prop) => (value) => {
		this.fire[prop] = value;
	};
}
