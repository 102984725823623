import React from 'react';
import StorageControlStore from './store';
import { observer, Provider } from 'mobx-react';
import { Toolbar } from './toolbar';
import { CategoryFilter } from './CategoryFilter';
import { Pager, Table, Column } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import { CAPACITY_ABBREVIATE, MODEL_NAME } from './constants';

@observer
export default class extends React.Component {
	constructor(props) {
		super(props);
		this.store = new StorageControlStore();
		this.store.init();
	}

	renderQuantity = ({ startQuantity, movements }) => {
		let total = +startQuantity;
		movements().forEach(({ quantity }) => (total += +quantity));
		return total;
	};
	renderAvailable = ({ startQuantity, movements, storehouse }) => {
		let total = startQuantity || 0;
		movements().forEach(({ quantity }) => (total += quantity || 0));
		const diff = (storehouse?.capacity || 0) - (total || 0);
		return diff > 0 ? diff + this.getMeasure(storehouse) : 0;
	};

	renderCapacity = (storehouse) => {
		return storehouse.capacity + this.getMeasure(storehouse);
	};

	getMeasure = (storehouse) => {
		let measure = '';
		if (storehouse.measure) {
			const { name } = storehouse.measure;
			measure = ` ${CAPACITY_ABBREVIATE[name] ?? name}`;
		}
		return measure;
	};

	render() {
		const { page, totalCount, onRowClick, onQueryUpdate, onChange, filter, isLoading } = this.store;
		if (isLoading) return null;
		return (
			<Provider store={this.store}>
				<div className='reserves'>
					<h2>{t('reserve.title_full')}</h2>
					<Toolbar />
					<div className='content'>
						<CategoryFilter />
						<div className='table'>
							<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} onRowClick={onRowClick} />
							<Table query={filter} onQueryUpdate={onQueryUpdate} model={store.model[MODEL_NAME]} onRowClick={onRowClick}>
								<Column relation='nomenclature' property='name' label={t('reserve.nomenclature')} />
								<Column relation='category' property='shortName' label={t('category')} />
								<Column relation='measure' property='name' label={t('reserve.measure')} />
								<Column computed={this.renderQuantity} label={t('reserve.quantity')} />
								<Column relation='storehouse' property='name' label={t('reserve.storage')} />
								<Column relation='storehouse' computed={this.renderCapacity} label={t('reserve.capacity')} />
								<Column computed={this.renderAvailable} label={t('reserve.available')} />
							</Table>
						</div>
					</div>
				</div>
			</Provider>
		);
	}
}
