import { observable } from 'mobx';
import { endOfDay } from 'date-fns';

export default class FireReportStore {
	@observable date = new Date();
	@observable county = null;
	@observable ownership = null;
	@observable stayedPeople = true;
	@observable withCoordinates = true;

	get storeParams() {
		return {
			reportCode: 'FiresOnCountyAndDate',
			reportParams: {
				endDate: endOfDay(this.date),
				county: this.county || undefined,
				ownership: this.ownership || undefined,
			},
			isHTML: true
		};
	}

	onDateChange = (date) => (this.date = date);
	onCountyChange = (county) => (this.county = county);
	onOwnershipChange = (ownership) => (this.ownership = ownership);
}
