import React from 'react';
import { Toolbar, DateRangePicker, CleanFiltersButton } from 'components';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export default class Filters extends React.Component {
	render() {
		const { search, onSearch, toggleRaidPopup, dateRangeValues, reload, cleanFilters } = this.props.store;

		return (
			<div className='filters'>
				<div className='top'>
					<Toolbar>
						<Toolbar.AddButton onClick={() => toggleRaidPopup(true)} />
						<Toolbar.SearchIconInput value={search} onChange={onSearch} />
					</Toolbar>
				</div>
				<div className='bottom'>
					<DateRangePicker dateValues={dateRangeValues} onChange={reload} />
					<CleanFiltersButton onClick={cleanFilters} />
				</div>
			</div>
		);
	}
}
