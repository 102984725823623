import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import ListIcon from '!!svg-react-loader!client/img/icons/delivery/list.svg';
import { Card } from 'delivery/components/';
import t from 'i18n';

@inject('store')
@observer
export class CategoryFilter extends React.Component {
	render() {
		const { categories, onSelect, selectedCategories, openedCategories, selectedSubCategories } = this.props.store;
		return (
			<Card icon={<ListIcon />} title={t('categories')} width={486}>
				<ul className='categories'>
					{categories.map((category) => {
						const isCategorySelected = selectedCategories.includes(category.id);
						const isSubSelected = !!category.children().find((cat) => selectedSubCategories.includes(cat.id));

						return (
							<div key={category.id} className='category-block'>
								<li className='caterory-item'>
									<span className='checkbox' onClick={() => onSelect(category, 'selectedCategories')}>
										<span className='checkbox-toggle'>{(isCategorySelected || isSubSelected) && <FontAwesomeIcon icon={faCheck} />}</span>
										{category.name}
									</span>
									<div className='filter-arrow' onClick={() => onSelect(category, 'openedCategories')}>
										<FontAwesomeIcon icon={openedCategories.includes(category.id) ? faChevronUp : faChevronDown} />
									</div>
								</li>
								{openedCategories.includes(category.id) &&
									category.children().map((category) => (
										<li className='sub-caterory-item'>
											<span className='checkbox' onClick={() => onSelect(category, 'selectedSubCategories')}>
												<span className='checkbox-toggle'>{selectedSubCategories.includes(category.id) && <FontAwesomeIcon icon={faCheck} />}</span>
												{category.shortName}
											</span>
										</li>
									))}
							</div>
						);
					})}
				</ul>
			</Card>
		);
	}
}
