import React, { Component } from 'react';
import { DatePicker, Field, NumberInput, GroupSelect, RelationSelect } from '@smartplatform/ui';
import t from 'i18n';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export class MainInfo extends Component {
	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	renderQuantity = () => {
		const { record, movements } = this.store;
		let total = record.startQuantity || 0;
		movements.forEach(({ quantity }) => (total += quantity || 0));
		return total === 0 ? '0' : total;
	};

	render() {
		const { categoryGroups, record, onChange } = this.props.store;

		return (
			<div className='main-info'>
				<div>
					<Field label={t('reserve.date')} property='date' disabled className='date'>
						<DatePicker showClearButton={false} />
					</Field>
					<Field label={t('category')} className='category-select' isRequired>
						<GroupSelect
							onChange={onChange('category')}
							selected={record.category}
							computed={({ shortName }) => shortName}
							isRequired
							groups={categoryGroups.map(({ shortName, children }) => ({ title: shortName, items: children() }))}
						/>
					</Field>
				</div>
				<div>
					<Field
						relation='nomenclature'
						property='name'
						filter={{
							where: {
								and: [{ categoryId: record.categoryId ?? null }],
							},
						}}
						label={t('reserve.nomenclature')}
						isRequired
					>
						<RelationSelect noTotal maxItems={10} itemsPerPage={1000} showValue={record.nomenclature ? record.nomenclature.name : '-'} />
					</Field>
				</div>
				<div>
					<Field label={t('reserve.measure')} relation='measure' property='name'>
						<RelationSelect isRequired />
					</Field>
					<Field label={t('reserve.startQuantity')} property='startQuantity' isRequired>
						<NumberInput positiveOnly />
					</Field>
				</div>
				<div>
					<Field label={t('storehouse.title')} relation='storehouse' property='name' isRequired>
						<RelationSelect isRequired />
					</Field>
				</div>
				<div>
					<Field label={t('reserve.capacity')} relation='storehouse' property='capacity' disabled />
					<Field label={t('reserve.quantity')} computed={this.renderQuantity} disabled />
				</div>
			</div>
		);
	}
}
