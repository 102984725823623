import React from 'react';
import { observable } from 'mobx';
import store from 'client/store';
import { EMERGENCY_SITUATION_PLAN_INCLUDE, MODEL_NAME, RELATIONS } from './constants';
import { isEqual } from 'client/tools';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { geoJSON } from '@smartplatform/map/client';
import { ColorUtils } from '@smartplatform/ui';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { Feature } from 'ol';
import { asArray } from 'ol/color';
import PlanMapPopup from './PlanMapPopup';
import t from 'i18n';

export default class PlanStore {
	@observable record = null;
	@observable isLoading = true;
	@observable members = [];
	@observable comments = [];
	@observable resources = [];
	@observable isChanged = false;
	@observable showChangedPopup = false;
	@observable exportPopup = false;
	@observable originalStatusCode;
	originalValues = {};
	changes = {};
	id;
	path;
	returnToPath;

	constructor() {}
	get isNew() {
		return !this.id;
	}
	get disableEdit() {
		const isAdmin = !!store.model.roles.find((role) => role.name === 'admin');
		return !(isAdmin || this.isAdminChs || this.isOperatorChs || this.isOwner);
	}

	get isOperatorChs() {
		return !!store.model.roles.find((role) => role.name === 'operator_chs');
	}

	get isAdminChs() {
		return !!store.model.roles.find((role) => role.name === 'admin_chs');
	}

	get isOwner() {
		return this.isNew || store.model.user.id === this.record?.owner?.id;
	}

	init = async () => {
		await this.fetchRecord();
		if (this.id) await this.fetchComments();
		this.isLoading = false;
	};

	// fetches
	fetchRecord = async () => {
		if (this.id) {
			this.record = await store.model[MODEL_NAME].findById(this.id, EMERGENCY_SITUATION_PLAN_INCLUDE);
			RELATIONS.forEach((relationName) => (this[relationName] = this.record[relationName]()));
			this.getOriginalValues();
		} else await this.createNewRecord();
	};

	createNewRecord = async () => {
		const defaultStatus = await store.model[MODEL_NAME + 'Status'].find({ where: { default: true } });
		this.record = new store.model[MODEL_NAME]({
			date: new Date(),
			ownerId: store.model.user.id,
			organizationId: store.model.user.organizationId,
			statusId: defaultStatus[0]?.id,
			status: defaultStatus[0],
		});
	};

	fetchComments = async () => {
		const [comments, logs] = await Promise.all([
			this.record.comments.find({
				include: [{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } }],
				order: 'createdAt asc',
			}),
			this.record.logs.find({
				where: { memberStatusId: { neq: null } },
				include: [
					{ relation: 'memberStatus', scope: { fields: ['id', 'color', 'name', 'code'] } },
					{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'member', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
				],
				order: 'createdAt asc',
			}),
		]);

		const items = [
			...logs.map((record, index) => ({ type: 'status', record, index })),
			...comments.map((record, index) => ({ type: 'comment', record, index })),
		];
		this.comments.replace(items.sort((a, b) => new Date(a.record.createdAt) - new Date(b.record.createdAt)));
	};

	getOriginalValues = () => {
		const { id, ownerId, createdAt, updatedAt, ...properties } = store.model[MODEL_NAME].PROPERTIES;
		Object.keys(properties).forEach((property) => (this.originalValues[property] = this.record[property] ?? null));
		this.originalStatusCode = this.record.status.code;
		RELATIONS.forEach((relation) => {
			this.originalValues[relation] = [];
			this[relation].forEach((record) => this.originalValues[relation].push({ ...record }));
		});
	};

	// record actions
	onChange = (prop) => (value) => {
		if (this.id && this.originalValues.hasOwnProperty(prop)) {
			const model = store.model[MODEL_NAME];
			const isModelProp = Boolean(model.PROPERTIES[prop]);
			if (isModelProp) {
				const originalValue = this.originalValues[prop];
				const recordValue = this.record[prop];
				const propertyType = store.model[MODEL_NAME].PROPERTIES[prop].type;

				if (isEqual({ propertyType, a: originalValue, b: recordValue })) {
					delete this.changes[prop];
				} else {
					this.changes[prop] = recordValue;
				}
			} else {
				const isModelRelation = Boolean(model.RELATIONS[prop]);
				if (isModelRelation) {
					if (!this.changes[prop]) this.changes[prop] = [];
					const originalRecord = this.originalValues[prop].find(({ id }) => id === value.id);
					if (originalRecord) {
						// если меняем существующую запись
						const relationModel = model.RELATIONS[prop].model;
						const { id, ownerId, createdAt, updatedAt, ...relationProperties } = store.model[relationModel].PROPERTIES;
						const isChangedRecord = Boolean(Object.keys(relationProperties).find((prop) => originalRecord[prop] !== value[prop]));
						if (isChangedRecord) {
							this.changes[prop].push(value.id);
						} else {
							this.changes[prop] = this.changes[prop].filter((id) => id !== value.id);
						}
					} else {
						this.changes[prop].push(value.id);
					}
					if (!this.changes[prop].length) delete this.changes[prop];
				}
			}
			this.isChanged = Object.keys(this.changes).length > 0;
		}
		if (prop === 'countyId') this.setCountyLayer();
		if (prop === 'statusId') this.setLayerStyle();
	};

	onSave = async () => {
		let path = this.returnToPath;
		if (this.isNew) {
			this.id = this.record.id;
			path += `/${this.id}`;
			this.dropChanges();
			this.isLoading = true;
			store.route.push({ path });
		} else {
			this.init();
			this.dropChanges();
		}
	};

	dropChanges = () => {
		this.isChanged = false;
		this.changes = {};
	};

	onClean = () => {
		this.members = [];
		this.record.attachments = [];
	};

	onDelete = async () => {
		await this.record.delete();
		this.back();
	};

	back = () => store.route.push({ path: this.returnToPath });

	onLeave = async ({ path }) => {
		if (this.isChanged) {
			this.showChangedPopup = true;
			this.newPath = path;
			return false;
		}
		if (this.path === path) {
			await this.record.reload({ include: [{ relation: 'status', scope: { fields: ['id', 'name', 'color'] } }] });
			this.getOriginalValues();
		}
		return true;
	};

	onMapInit = (mapStore, isExportPopup = false) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;
		this.isExportPopup = isExportPopup;
		this.source = new VectorSource();
		this.layer = new VectorLayer({
			format: geoJSON,
			source: this.source,
		});
		this.setLayerStyle();
		mapStore.addLayer(this.layer);
		this.setCountyLayer();
	};

	setLayerStyle = () => this.layer.setStyle(this.featureStyle);

	setCountyLayer = async () => {
		if (this.record.countyId) {
			this.record.county = await store.model.County.findById(this.record.countyId);
			const { geo } = this.record.county;
			if (geo) {
				this.mapStore.fitGeo(geo);
				this.addGeo();
				this.setLayerStyle();
				if (!this.isExportPopup) this.toggleInteractions(false);
			}
		}
	};

	addGeo = () => {
		const { geo, id } = this.record.county;
		this.source.clear();

		// пример с кастомным попапом: помимо record передаем render и title
		const featureObj = {
			record: this.record,
			render: this.renderPopup,
			geometry: geoJSON.readGeometry(geo),
			title: t('emergencySituationPlan.title'),
		};

		// без попапа: не передаем record
		// const featureObj = { geometry: geoJSON.readGeometry(geo) };

		const feature = new Feature(featureObj);
		this.source.addFeature(feature);
	};

	toggleInteractions = (active = true) => {
		const { map } = this.mapStore;
		map.getInteractions().forEach((interaction) => interaction.setActive(active));
		const mapElement = map.getTarget();
		if (mapElement) mapElement.classList[active ? 'remove' : 'add']('disabled');
	};

	get featureStyle() {
		const color = this.record.status?.color || '#ff4500';
		const countyName = this.record.county?.name;
		const { r, g, b } = ColorUtils.hexToRgb(color);
		const fillOpacity = 0.2;
		const fillColor = { color: asArray([r, g, b, fillOpacity]) };

		const styleParams = {
			fill: new Fill(fillColor),
			stroke: new Stroke({ color: color }),
		};
		if (countyName) {
			styleParams.text = new Text({
				textAlign: 'center',
				textBaseline: 'middle',

				text: countyName,
				fill: new Fill({ color: '#888' }),
				scale: 5,
			});
		}

		return new Style(styleParams);
	}

	renderPopup = (record) => {
		console.log('renderPopup', record);
		return <PlanMapPopup record={record} />;
	};

	showExportPopup = () => (this.exportPopup = true);

	closeExportPopup = () => (this.exportPopup = false);
}
