import React from 'react';
import { Column, Field, Form } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store'
import { ModelList, ModelEdit } from 'components';

const Edit = ({ modelName, path, match }) => {
	const id = parseInt(match.params.id);
	return <ModelEdit model={store.model[modelName]} id={id} path={path} disabled={!!id} noDelete>
		<div className="row">
			<Field property="name" label={t('notifyStatus.status')} className="col-3" />
			<Field property="code" label={t('code')} />
		</div>
	</ModelEdit>
};

const List = ({ modelName }) => {
	return <ModelList model={store.model[modelName]} >
		<Column width={50} property="code" label={t('code')}/>
		<Column property="name" label={t('notifyStatus.status')} />
	</ModelList >
};

export const NotifyStatus = { Edit, List };