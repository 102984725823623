import React from 'react';
import { observable } from 'mobx';
import { Column, DateValue, Pager, Table } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { ListStore, Toolbar } from 'components';
import { EditPopup } from './single-record';
import { FORCE_AVAILABILITY_INCLUDE } from './constants';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class List extends React.Component {
	@observable showEditPopup = false;
	@observable selectedRecord = null;

	constructor(props) {
		super(props);
		const model = store.model.ForceAvailability;
		this.store = new ListStore({ path: props.path, filters: FORCE_AVAILABILITY_INCLUDE, model });
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	toggleEditPopup = (record) => {
		this.selectedRecord = record || null;
		this.showEditPopup = !this.showEditPopup;
	};

	onClosePopup = () => {
		this.toggleEditPopup();
		this.store.reload();
	};

	renderSum = (records) => {
		if (records.length === 0) return '-';
		const reduceObj = records.reduce((acc, record) => {
			const typeName = record.type?.shortName || record.type?.name;
			if (!typeName) return acc;
			if (!acc[typeName]) {
				acc[typeName] = record.count || 0;
			} else {
				acc[typeName] = acc[typeName] + (record.count || 0);
			}
			return acc;
		}, {});
		return (
			<div>
				{Object.keys(reduceObj)
					.map((key) => (reduceObj[key] > 0 ? `(${key} - ${reduceObj[key]})` : undefined))
					.filter(Boolean)
					.join(',  ')}
			</div>
		);
	};

	render() {
		if (this.error) return <div className='error'>{this.error}</div>;
		const { onChange, totalCount, page, perPage, tableProps } = this.store;

		return (
			<div className='force-availability-page'>
				<h2>{t('forceAvailability.plural')}</h2>
				<Toolbar>
					<Toolbar.AddButton onClick={() => this.toggleEditPopup()} />
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table {...tableProps} onRowClick={this.toggleEditPopup}>
						<Column property='date' label={t('date')}>
							<DateValue format='dd.MM.yyyy' />
						</Column>
						<Column relation='people' computed={this.renderSum} label={t('fireFightingPeople.plural')} />
						<Column relation='extinguishingAgents' computed={this.renderSum} label={t('fireFightingVehicle.plural')} />
						<Column relation='aircrafts' computed={this.renderSum} label={t('aircraft.plural')} />
					</Table>
				</div>
				{this.showEditPopup && <EditPopup onClose={this.onClosePopup} record={this.selectedRecord} />}
			</div>
		);
	}
}
