import t from 'i18n';
import './style.scss';
import React from 'react';
import RecycleIcon from '!!svg-react-loader!img/icons/delete.svg';
import { Popconfirm, Button } from '@smartplatform/ui';

export const DeleteButton = ({ onConfirm, text, disabled }) => {
	return (
		<Popconfirm content={t('delete')} onConfirm={onConfirm} disabled={disabled}>
			<div className='delete-button' style={text ? { padding: '0 15px' } : {}}>
				<RecycleIcon />
				{text && <span>{text}</span>}
			</div>
		</Popconfirm>
	);
};
