import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Tabs, Tab } from '@smartplatform/ui';
import store from 'client/store';
import { Panel } from 'components';
import { STATUS_CONFIRMED, STATUS_RECOVERED, STATUS_SELFISOLATED, STATUS_CONTACTED } from '../../store';
import t from 'i18n';
import './style.scss';

@observer
export default class Counties extends React.Component {

	@observable counties = [];
	@observable noCounty = 0;
	@observable tab = 'confirmed';
	@observable isLoading = true;
	
	cache = {};
	
	constructor(props) {
		super(props);
		this.tabs = [STATUS_CONFIRMED, STATUS_SELFISOLATED, STATUS_CONTACTED];
		this.tab = store.covid.type || STATUS_CONFIRMED;
		this.init();
	}
	
	init = async () => {
		this.isLoading = true;
		const { error, result } = await store.model.County.withCaseCount({ type: this.tab });
		const where = { countyId: null };
		if (this.tab === STATUS_CONFIRMED) {
			where.statusId = 2;
		}
		else if (this.tab === STATUS_SELFISOLATED) {
			where.isolationId = 1;
			where.contacted = false;
		}
		else if (this.tab === STATUS_CONTACTED) {
			where.contacted = true;
		}
		console.log('where', where);
		this.noCounty = await store.model.ViewCase.count(where);
		console.log('noCounty', this.noCounty);
		if (!error) {
			this.counties = result;
		}
		else {
			console.error(error);
		}
		this.isLoading = false;
	};
	
	gotoCounty = async countyId => {
		store.covid.county = await store.model.County.findById(countyId);
		if (store.covid.county.geo) {
			console.log('county', store.covid.county.name, 'has geo');
			// store.covid.map.addGeo(county.geo);
			store.covid.mapStore.fitGeo(store.covid.county.geo);
		}
	};
	
	onScrollerMount = el => {
		if (el) this.scroller = el;
	};
	
	switchTab = async tab => {
		this.tab = tab;
		store.covid.county = null;
		store.covid.switchType(this.tab);
		await this.init();
		if (this.scroller) this.scroller.scrollTo(0, 0);
	};
	
	render() {
		const rows = this.counties.map(county => {
			const className = classNames('total', store.covid.type, {
				zero: county.count === 0,
			});
			const selected = store.covid.county && store.covid.county.id === county.id && store.covid.type === this.tab;
			return <tr key={county.id} onClick={() => this.gotoCounty(county.id)} className={selected ? 'selected' : ''}>
				<td className="name">{county.name}</td>
				<td className={className}>{county.count}</td>
			</tr>;
		});

		if (this.noCounty) {
			const noCounty = <tr key={0}>
				<td className="name">{t('case.noCounty')}</td>
				<td className="total">{this.noCounty}</td>
			</tr>;
			rows.splice(0, 0, noCounty);
		}
		
		return <Panel left={0} top={130} width={300} bottom={90} height="auto" className="block counties">
			<Tabs type="manual">
				<Tab initial onClick={() => this.switchTab(this.tabs[0])} title="Больные"/>
				<Tab onClick={() => this.switchTab(this.tabs[1])} title="Самоизоляция"/>
				<Tab onClick={() => this.switchTab(this.tabs[2])} title="Контактные"/>
				{/*<Tab onClick={() => this.switchTab(this.tabs[1])} active={this.tabs[1] === store.covid.type}>Самоизоляция</Tab>*/}
				{/*<Tab onClick={() => this.switchTab(this.tabs[2])} active={this.tabs[2] === store.covid.type}>Контактные</Tab>*/}
			</Tabs>
			<div className="scroller" ref={this.onScrollerMount}>
				<table className={'counties-table' + (this.isLoading ? ' loading' : '')}>
					<tbody>{rows}</tbody>
				</table>
			</div>
		</Panel>;
	}
	
}
