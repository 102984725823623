import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import t from 'i18n';
import { distance } from '../../helpers';
import { DottedTextField } from '../../components/fields/DottedTextField';
import { azimuth } from '../../helpers/index';

@inject('card') @observer
export default class Distances extends React.Component {
	render() {
		const { fire } = this.props.card;
		return <div style={{width:'300px'}}>
			{['city', 'aviaDep', 'airfield'].map((field, i) => {
				return <div key={field}>
					{/*<DottedTextField label={t(`${field}.title`)} value={fire[field] ? (fire[field].name || t('noName')) : t('no')} />*/}
					<DottedTextField label={t(`fire.${field}Azimuth`)} value={azimuth(fire[field + 'Azimuth'], true) || t('no')} />
					<DottedTextField label={t(`fire.${field}Distance`)} value={distance(fire[field + 'Distance'], true) || t('no')} />
				</div>
			})}
			<DottedTextField label={t(`fire.transportRoutesDistance`)} value={distance(fire.transportRoutesDistance, true) || t('no')} />
		</div>
	}
}
