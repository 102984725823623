import React from 'react';
import { observer } from 'mobx-react';
import { Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import t from 'i18n';
import { observable } from 'mobx';
import store from 'client/store';
import { toLowerCamelCase, renderStatus } from 'client/tools';

const MODEL_NAME = 'AdpiDevice';
@observer
export default class HydrantsPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model[MODEL_NAME].findById(this.props.record.id, {
			include: ['status', 'organization', 'type', { address: ['address'] }],
		});
		this.isLoading = false;
	};

	go = () => store.route.push({ path: `/techfires/registry/${toLowerCamelCase(MODEL_NAME)}/${this.record.id}` });

	render() {
		if (this.isLoading) return null;
		const model = toLowerCamelCase(MODEL_NAME);
		return (
			<div className='map-fire-alarm-object-popup'>
				<div className='title'>
					<h2>
						{t(`${model}.title`)}:{' '}
						<a style={{ cursor: 'pointer' }} onClick={this.go}>
							#{this.record.id}
						</a>
					</h2>
				</div>
				<Row>
					<TextField label={t('name')} value={this.record.name} />
					<TextField label={t('phone')} value={this.record?.organization?.phoneNumber} />
				</Row>
				<Row>
					<TextField label={t('info')} value={this.record.info} />
					<TextField label={t('type')} value={this.record.type?.name} />
				</Row>
				<Row>
					<TextField label={t(`${model}.status`)} value={renderStatus(this.record.status)} />
				</Row>
				<Row>
					<TextField label={t(`${model}.address`)} value={this.record.address?.address?.name} />
				</Row>
			</div>
		);
	}
}
