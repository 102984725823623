import { nodeOuterHeight } from './nodeOuterHeight';
import { TABLE_ROW_HEIGHT } from 'client/constants';

export const getPerPage = (pageWidth = TABLE_ROW_HEIGHT, tableId = 'table', minCount = 10) => {
	const tableElement = document.getElementById(tableId);
	if (!tableElement) return minCount;
	const tableHeight = tableElement.offsetHeight;
	const theadHeight = nodeOuterHeight(tableElement.getElementsByTagName('thead')[0]);
	const rowsHeight = tableHeight - theadHeight;
	const perPage = Math.floor(rowsHeight / pageWidth);
	return perPage > minCount ? perPage : minCount;
};
