import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { RecordSelect } from '@smartplatform/ui';
import appealsStore from './store';
import store from 'client/store';
import { UI_SIZE } from './topbar/TopBar';

@observer
export default class SystemSelect extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		if (!appealsStore.isInitialized) return null;

		return <div>
			<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
				model={store.model.AppealSystem}
				property="name"
				value={appealsStore.system}
				onChange={appealsStore.onSystemChange}
				className="dark"
				filter={{ where: { id: { inq: [1, 3, 5] }}, order: 'id asc' }}
				isRequired
				noSearch
				size={UI_SIZE}
				fontSize={16}
				itemFontSize={14}
			/>
		</div>;
	}

}
