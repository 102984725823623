import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { PERIODS } from 'client/constants';
import t from 'i18n';
import { Button, DatePicker, Select } from '@smartplatform/ui';
import ChevronIcon from '!!svg-react-loader!client/img/icons/shared/chevron-left.svg';
import { endOfDay, startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear, startOfQuarter, endOfQuarter } from 'date-fns';
import { addMonths, addDays } from 'date-fns';
import './style.scss';

@observer
export class DateRangePicker extends React.Component {
	periodDate = new Date();

	static propTypes = {
		dateValues: PropTypes.shape({ startDate: PropTypes.instanceOf(Date), endDate: PropTypes.instanceOf(Date), period: PropTypes.string }).isRequired,
		onChange: PropTypes.func,
		className: PropTypes.string,
	};

	static defaultProps = {};

	onDateChange = (prop) => (date) => {
		const map = {
			startDate: () => startOfDay(new Date(date)),
			endDate: () => endOfDay(new Date(date)),
		};
		this.props.dateValues.period = null;
		this.props.dateValues[prop] = map[prop]?.() || null;
		this.props.onChange?.(this.props.dateValues);
	};

	onPeriodChange = (period) => {
		this.props.dateValues.period = period;
		this.props.dateValues.periodDate = new Date();
		this.setPeriodDates();
		this.props.onChange?.(this.props.dateValues);
	};

	renderPlaceholder = (prop) => t('dateRangePicker.' + prop);

	go = (direction = 1) => {
		const { period, periodDate } = this.props.dateValues;
		if (period) {
			const map = {
				[PERIODS.DAY]: () => addDays(periodDate, direction),
				[PERIODS.WEEK]: () => addDays(periodDate, 7 * direction),
				[PERIODS.MONTH]: () => addMonths(periodDate, direction),
				[PERIODS.QUARTER]: () => addMonths(periodDate, 3 * direction),
				[PERIODS.YEAR]: () => addMonths(periodDate, 12 * direction),
			};
			this.props.dateValues.periodDate = map[period]?.();
		}

		this.setPeriodDates();
	};

	setPeriodDates = () => {
		const { dateValues, onChange } = this.props;
		const { period, periodDate } = dateValues;

		if (period) {
			const map = {
				[PERIODS.DAY]: () => [startOfDay(periodDate), endOfDay(periodDate)],
				[PERIODS.WEEK]: () => [startOfWeek(periodDate, { weekStartsOn: 1 }), endOfWeek(periodDate, { weekStartsOn: 1 })],
				[PERIODS.MONTH]: () => [startOfMonth(periodDate), endOfMonth(periodDate)],
				[PERIODS.QUARTER]: () => [startOfQuarter(periodDate), endOfQuarter(periodDate)],
				[PERIODS.YEAR]: () => [startOfYear(periodDate), endOfYear(periodDate)],
			};
			const [startDate, endDate] = map[period]?.();
			dateValues.startDate = startDate;
			dateValues.endDate = endDate;
		}

		onChange?.(dateValues);
	};

	prev = () => this.go(-1);
	next = () => this.go(1);

	render() {
		const { startDate, endDate, period, periodDate } = this.props.dateValues;
		const { onPeriodChange, renderPlaceholder, prev, next, onDateChange } = this;
		const className = classNames('range-date-picker', this.props.className);
		const noPeriod = period === null;
		const prevDisabled = noPeriod;
		const nextDisabled = noPeriod || periodDate >= startOfDay(new Date());
		const periodSelectItems = Object.values(PERIODS).map((mode) => ({ label: t(`period.${mode}`), value: mode }));
		const periodSelectProps = { noTotal: true, width: 200, noSearch: true, isRequired: true };

		return (
			<div className={className}>
				<DatePicker
					value={startDate}
					onChange={onDateChange('startDate')}
					showClearButton={false}
					placeholder={renderPlaceholder('startDate')}
					maxDate={endDate ? endDate : undefined}
				/>
				<DatePicker
					value={endDate}
					onChange={onDateChange('endDate')}
					showClearButton={false}
					placeholder={renderPlaceholder('endDate')}
					minDate={startDate ? startDate : undefined}
					maxDate={new Date()}
				/>
				<Button variant='primary' onClick={prev} disabled={prevDisabled}>
					<ChevronIcon className='left-chevron' />
				</Button>
				<Select items={periodSelectItems} value={period} onChange={onPeriodChange} placeholder={renderPlaceholder('period')} {...periodSelectProps} />
				<Button variant='primary' onClick={next} disabled={nextDisabled}>
					<ChevronIcon className='right-chevron' />
				</Button>
			</div>
		);
	}
}
