import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { TextField } from 'fires/components';
import { renderMunicipalityStatus, renderPopulation } from '../../../helpers'

@observer
export default class MunicipalityPopup extends React.Component {
	@observable isLoading = true;
	@observable record = true;
	@observable cities = null;
	@observable municipalityStatus = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.Municipality.findById(this.props.record.id, { include: ['cities', 'readinessStatus'] });
		this.municipalityStatus = await store.model.MunicipalSufficiencyStatus.find();
		this.cities = this.record.cities();
		this.isLoading = false;
	};

	gotoMunicipality = (e) => {
		e.preventDefault();
		store.route.push({ path: `/emergency/registry/${this.record.id}` });
	};

	render() {
		if (this.isLoading) return <Loader size={18} />;

		return (
			<div className='popup'>
				<div className='cursor-pointer' onClick={this.gotoMunicipality}>
					<h2>{`${this.record.name}: #${this.record.id}`}</h2>
				</div>
				<Row>
					<TextField label={t('municipalFormation.area')} value={this.record.area} />
					<TextField label={t('municipalFormation.population')} value={renderPopulation(this.cities)} />
				</Row>
				<Row>
					<TextField label={t('municipalFormation.cities')} value={this.cities.length} />
					<TextField label={t('municipalFormation.readinessStatus')} value={renderMunicipalityStatus(this.cities, this.municipalityStatus)} />
				</Row>
			</div>
		);
	}
}
