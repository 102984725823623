import store from 'client/store';

export const fixVehicleType = async (record) => {
	console.log('------------------->', record);
	if (!record.specialTypeId) {
		const specialType = await store.model.FireDepSpecialVehicleType.findById(1);
		record.specialType = specialType;
		await record.save();
	}
};
