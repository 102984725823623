import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faAlignLeft, faComment } from '@fortawesome/free-solid-svg-icons';
import t from 'i18n';
import { relative } from 'client/tools';
import './style.scss';

@observer
export default class TaskCard extends React.Component {

	render() {
		const { appeal } = this.props;

		//* берем тайтл, а не текст у распознанных заявок ЕСЭД
		let text = appeal.text || appeal.title || '';
		if ((appeal.systemId === 5) && appeal.recognized) {
			text = appeal.title;
		};

		const attachmentsLen = appeal.attachments()?.totalCount;


		return <>
			<div className="appeal-content">
				<div className="top">
					<div className={'id' + (appeal.closed ? ' closed' : '')}># {appeal.id} {appeal.closed && <FontAwesomeIcon icon={faCheck} />}</div>
					<div className="right">
						{relative(appeal.updatedAt)}
					</div>
				</div>
				<div className="main">
					<div className="name" dangerouslySetInnerHTML={{ __html: text?.replace(/[\r\n]+/g, '<br />') }}/>
					{appeal.user && <div className="user evented">
						{/*<UserAvatar user={appeal.user} size={24} />*/}
						{JSON.stringify(appeal.userId)}
					</div>}
				</div>
				<div className="bottom">
					{/*<div className="labels evented">*/}
					{/*	?*/}
					{/*</div>*/}
					<div className="attachments-count">
						{attachmentsLen && t('attachments') + `: ${attachmentsLen}`}
					</div>
					<div className="info">
						{/*{task.hasDescription ? <FontAwesomeIcon icon={faAlignLeft} /> : null}*/}
						{appeal.commentsCount > 0 ? <><FontAwesomeIcon icon={faComment} /> {appeal.commentsCount}</> : null}
					</div>
				</div>
			</div>
		</>;
	}
	
}
