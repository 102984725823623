import React from 'react';
import { inject, observer } from 'mobx-react';
import './style.scss';
import { Summary } from './summary/Summary';
import { PlanTotal } from './plan-total/PlanTotal';
import { ExecutionPlan } from './execution-plan/ExecutionPlan';
import { Loader } from '@smartplatform/ui';
import { PlanTotalTable } from './plan-total-table/PlanTotalTable';
import { ContractorTable } from './contractor-table/ContractorTable';

@inject('store')
@observer
export class AnalyticsTab1 extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { isLoading, data } = this.store;
		if (isLoading) return <Loader />;
		if (!data) return <div>Нет данных</div>;

		return (
			<div className='analytics-tab1'>
				<Summary />
				<PlanTotal />
				<PlanTotalTable />
				<ExecutionPlan />
				<ContractorTable />
			</div>
		);
	}
}
