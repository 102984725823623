import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Column, Pager, Table } from '@smartplatform/ui';
import t from 'i18n';
import { RowAddButton } from 'fires/components';
import { EditPeoplePopup } from './EditPeoplePopup';
import { POPUP_PER_PAGE } from '../../constants';
import store from 'client/store';
@inject('store')
@observer
export class People extends React.Component {
	@observable records = [];
	@observable showEditPopup = false;
	@observable page = 1;

	constructor(props) {
		super(props);
		this.records = this.props.store.people;
	}

	onClosePopup = () => this.toggleEditPopup(false);
	onOpenPopup = () => this.toggleEditPopup(true);

	toggleEditPopup = (boolean) => {
		if (!boolean) {
			this.selectedRecord = null;
		}
		this.showEditPopup = boolean;
	};

	onRowClick = (record) => {
		this.selectedRecord = record;
		this.toggleEditPopup(true);
	};

	onPageChange = (page) => (this.page = page);

	render() {
		return (
			<div className='people-list'>
				<div className='top'>
					<Pager current={this.page} onChange={this.onPageChange} totalCount={this.records.totalCount} itemsPerPage={POPUP_PER_PAGE} />
				</div>
				<Table rows={this.records.slice(POPUP_PER_PAGE * (this.page - 1), POPUP_PER_PAGE * this.page)} onRowClick={this.onRowClick}>
					<Column relation='type' property='shortName' label={t('crewType.title_short')} />
					<Column relation='formation' property='shortName' label={t('fireFightingFormation.title_short')} />
					<Column width={70} property='count' label={t('countShort')} />
				</Table>
				<RowAddButton onClick={this.onOpenPopup} disabled={this.props.store.disabled} />
				{this.showEditPopup && <EditPeoplePopup onClose={this.onClosePopup} record={this.selectedRecord} />}
			</div>
		);
	}
}
