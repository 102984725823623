import { inject, observer } from 'mobx-react';
import React from 'react';
import { DatePicker, Field, Input, RelationSelect, Row } from '@smartplatform/ui';
import t from 'i18n';
import { renderStatus } from 'client/tools';
import { Attachments } from 'client/components';

@inject('store')
@observer
export class Main extends React.Component {
	onUpload = (record) => this.props.store.onChange('explanatoryNoteFiles')(record);
	onDelete = (record) => {
		if (!this.props.store.changes['explanatoryNoteFiles']) this.props.store.changes['explanatoryNoteFiles'] = [];
		this.props.store.changes['explanatoryNoteFiles'].push(record.id);
		this.props.store.isChanged = true;
	};

	render() {
		const { record, onChange, disableEdit, isNew } = this.props.store;
		const selectProps = {
			noTotal: true,
			maxItems: 11,
			itemsPerPage: 1000,
			isRequired: true,
			filter: { fields: ['id', 'name'] }, // при открытии списка County задержка больше секунды, из-за их больших geo
		};

		return (
			<div className='main'>
				<Row>
					{record.id && (
						<Field label={t('number')} property='id' disabled>
							<Input />
						</Field>
					)}
					<Field label={t('date')} property='date' isRequired disabled>
						<DatePicker showTimeSelect showClearButton={false} onChange={onChange('date')} />
					</Field>
					<Field label={t('status')} className='form-field' relation='status' computed={renderStatus} isRequired>
						<RelationSelect showValue={renderStatus(record.status)} onChange={onChange('statusId')} isRequired noSearch />
					</Field>
				</Row>
				<Row>
					<Field property='explanatoryNote' label={t('municipality.explanatoryNote')}>
						<textarea onChange={onChange('explanatoryNote')} />
					</Field>
				</Row>
				<Row>
					<Attachments
						record={record}
						canUpload={!disableEdit}
						withFormContext
						uploadTitle={t('municipality.attachExplanatoryNoteFiles')}
						relation='explanatoryNoteFiles'
						onUpload={this.onUpload}
						onDelete={this.onDelete}
					/>
				</Row>
				<Row>
					<Field label={t('level')} relation='level' property='name'>
						<RelationSelect onChange={onChange('levelId')} {...selectProps} />
					</Field>
					<Field label={t('county.title')} relation='county' property='name'>
						<RelationSelect onChange={onChange('countyId')} {...selectProps} />
					</Field>
				</Row>
			</div>
		);
	}
}
