import Layer from 'ol/layer/Layer.js';

import CustomWebglPointsRenderer from './CustomWebglPointsRenderer';

export default class CustomWebglPointsLayer extends Layer {

	constructor(options) {
		const baseOptions = Object.assign({}, options);
		super(baseOptions);
	}

	setPoints = (points, customSetupFunc) => {
		this.getRenderer().setPoints(points, customSetupFunc);
		this.changed();
	};

	setTexture = (image, size, count) => {
		this.getRenderer().setTexture(image, size, count);
		this.changed();
	};

	cleanUp = () => {
		// TODO: очистить webgl-контекст
	};

	setPointSizeFunc = (func) => {
		this.getRenderer().setPointSizeFunc(func);
		this.changed();
	};

	createRenderer() {
		return new CustomWebglPointsRenderer(this);
	}

}
