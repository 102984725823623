import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import ru from 'date-fns/locale/ru';
import { formatDuration } from 'date-fns';

import appealsStore, { EVENTS } from '../store';
import store from 'client/store';

@observer
export default class AvgCategories extends React.Component {

	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		appealsStore.subscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.subscribe(EVENTS.RELOAD_CATEGORIES, this.init);
	}

	componentWillUnmount() {
		appealsStore.unsubscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.unsubscribe(EVENTS.RELOAD_CATEGORIES, this.init);
	}

	init = async () => {
		this.records = await store.model.ViewAppealCategory.getPeriods({
			startDate: appealsStore.startDate,
			endDate: appealsStore.endDate,
			parentId: appealsStore.category ? appealsStore.category.id : null,
			systemId: appealsStore.system.id,
		});
		this.isLoading = false;
	};
	
	render() {
		if (this.isLoading) return null;

		return this.records
			.filter(category => !!category.avgperiod)
			.map(category => {
				const color = appealsStore.categoryColor(category);
				const days = Math.floor(category.avgperiod / (60 * 24));
				// const hours = days === 0 ? Math.floor(category.avgperiod / 60) - days * 24 : 0;
				const hours = Math.floor(category.avgperiod / 60) - days * 24;
				// const minutes = category.avgperiod - days * 24 * 60 - hours * 60;
				const minutes = days === 0 && hours === 0 ? category.avgperiod - days * 24 * 60 - hours * 60 : 0;
				const duration = { days, hours, minutes };
				return <div key={category.id} className="category">
					<div className="color"><span style={{ background: color }} /></div>
					<div className="name">{category.name}</div>
					<div className="period">{category.avgperiod ? formatDuration(duration, { locale: ru }) : '-'}</div>
				</div>;
			});
	}
	
}
