import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Panel } from 'components';
import store from 'client/store';
import appealsStore, { EVENTS } from '../store';
import './style.scss';

@observer
export default class Counties extends React.Component {

	@observable counties = [];
	@observable noCounties = false;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		appealsStore.subscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.subscribe(EVENTS.RELOAD_CATEGORIES, this.init);
		appealsStore.subscribe(EVENTS.SYSTEM_CHANGED, this.onSystemChange);
	}

	componentWillUnmount() {
		appealsStore.unsubscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.unsubscribe(EVENTS.RELOAD_CATEGORIES, this.init);
		appealsStore.unsubscribe(EVENTS.SYSTEM_CHANGED, this.onSystemChange);
	}

	onSystemChange = () => {
		this.noCounties = appealsStore.system.id === 5;
	};

	init = async () => {
		this.counties = await store.model.County.withAppealCount({
			startDate: appealsStore.startDate,
			endDate: appealsStore.endDate,
			parentId: appealsStore.category ? appealsStore.category.id : null,
			systemId: appealsStore.system.id,
		});
		this.isLoading = false;
	};

	onClick = async (countyId) => {
		if (!countyId) return;
		const county = await store.model.County.findById(countyId);
		store.map.clearHelper();
		if (county.geo) {
			console.log('county', county.name, 'has geo');
			store.map.addGeo(county.geo);
			store.map.fitGeo(county.geo);
		}
	};

	render() {
		if (this.isLoading) return null;

		const counties = !this.noCounties ? this.counties : this.counties.slice(0, 1);

		return <Panel right={0} top={store.ui.sideBarBtnSize} bottom={30} left="auto" width={280} header="Обращения по ГО/улусам" height="auto" className="appeals-counties">
			<table>
{/*
				<thead>
				<tr>
					<th>Наименование</th>
					<th className="text-center">Обращений</th>
				</tr>
				</thead>
*/}
				<tbody>
				{counties.map(county => <tr key={county.id || 'republic'} onClick={() => this.onClick(county.id)}>
					<td>{county.name}</td>
					<td className="text-center">{county.count}</td>
				</tr>)}
				</tbody>
			</table>
			{this.noCounties && <div className="no-counties">По улусам данных нет.</div>}
		</Panel>;
	}

}
