import React from 'react';
import { observable } from 'mobx';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { asArray } from 'ol/color';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { get as getProj } from 'ol/proj';
import MVT from 'ol/format/MVT';
import { createXYZ } from 'ol/tilegrid';

import { ColorUtils } from '@smartplatform/ui';
import MunicipalityPopup from './MunicipalityPopup';

const TILE_SIZE = 2048;

export default class MunicipalityLayer {

	@observable municipality = 'all';

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}
	
	init = async () => {
		const renderPopup = this.renderPopup;
		
		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: createXYZ({
				extent: getProj('EPSG:3857').getExtent(),
				maxZoom: 18,
				tileSize: TILE_SIZE,
			}),
			tileUrlFunction: this.tileUrlFunction,
			tileLoadFunction: this.tileLoadFunction(renderPopup),
		});
		
		this.layer = new VectorTileLayer({
			source: this.source,
			style: this.getStyle,
		});
		
		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
	};

	tileUrlFunction = (coords) => {
		let filters = [];

		if (this.municipality !== 'all') {
			filters.push(`id=${this.municipality.id}`);
		}

		const filter = '&filter=' + filters.join(' and ');

		return `/api/mvt?model=Municipality&x=${coords[1]}&y=${coords[2]}&z=${coords[0]}${filter}&columns=id,name,id&buffer=0&extent=${TILE_SIZE}`;
	};
	
	tileLoadFunction = (render) => (tile, url) => {
		tile.setLoader(function(extent, resolution, projection) {
			fetch(url).then(function(response) {
				response.arrayBuffer().then(function(data) {
					const format = tile.getFormat() // ol/format/MVT configured as source format
					const renderFeatures = format.readFeatures(data, {
						extent: extent,
						featureProjection: projection,
					});
					renderFeatures.forEach(f => {
						const record = f.getProperties();
						f.properties_ = {
							title: record.name,
							record,
							render,
						};
						if (render) f.properties_.record = record;
					});
					tile.setFeatures(renderFeatures);
				});
			});
		});
	};
	
	onMunicipalityChange = record => {
		this.municipality = record;
		if (record && record.id) {
			this.mapStore.fitGeo(record.geo)
		}
		this.source.refresh();
	};
	
	getStyle = (feature) => {
		const name = feature.getProperties().title;
		const { background } = ColorUtils.getColorForString(name);
		const { r, g, b } = ColorUtils.hexToRgb(background);
		return new Style({
			fill: new Fill({ color: asArray([r, g, b, 0.2]) }),
			stroke: new Stroke({ color: '#888', width: 1, lineDash: [1, 4] }),
			text: new Text({
				textAlign: 'center',
				textBaseline: 'middle',
				// overflow: true,
				font: '11px/14px sans-serif',
				text: name,
				fill: new Fill({ color: '#888' }),
			}),
		});
	};
	
	setVisible = () => {
		this.layer.setVisible(this.settings.show);
	};
	
	renderPopup = (record) => {
		return <MunicipalityPopup record={record} />
	};
}
