import { defaultsDeep } from 'lodash';
import { toJS } from 'mobx';
import store from 'client/store';

const layers = {
	store: 'techfires',
	counties: {
		show: false,
	},
	municipality: {
		show: false,
		minimized: false,
	},
	minerals: {
		show: false,
	},
	/*
	techfires: {
		show: true,
		minimized: false,
		status: {
			intensifies: true,    // Усиливается
			continues: true,    // Продолжается
			weakens: true,   // Ослабевает
			notspreading: true,   // Не распространяется
			localized: true,   // Локализован
			resumed: true,    // Возобновился
			extinguished: false,   // Ликвидирован
			abandoned: false,   // Прекращено тушение по решению КЧС
		}
	},
*/
	heatPoints: {
		show: false,
		minimized: false,
		period: 0,
		useMVT: false,
		region: 14,
	},
	genShtab: {
		show: false,
	},
	weather: {
		show: false,
		minimized: false,
		layer: 'pm25',
	},
	wind: {
		show: false,
		minimized: false,
		fadeOpacity: 0.995,
		speedFactor: 5,
		particleLife: 128,
		opacity: 0.8,
		webgl: true,
	},
	fireDep: {
		show: false,
		countyId: 1,
	},
	adpi: {
		show: false,
		minimized: false,
		filter: {
			selectedStatuses: [],
			organizationId: null,
		},
	},
	address: {
		show: false,
		minimized: false,
		filter: {
			fields: {
				ptp: false,
				ktp: false,
			},
			ranks: [],
		},
	},
	waterSupply: {
		show: false,
		minimized: false,
		filter: {
			division: null,
			selectedTypes: [],
			working: null,
		},
	},
};

const defaultConfig = {
	tileSource: '2gis',
	filtersMaximized: false,
	layers,
	dynamicLayers: [],
};

export default function initLocal() {
	store.local.techfires = defaultsDeep(toJS(store.local.techfires), defaultConfig);
	store.local.save();
}
