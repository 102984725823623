import { observable, action } from 'mobx';
import store from 'client/store';
import t from 'i18n';

export const INITIAL_LIMIT = 20;

export const APPEAL_FIELDS = [
	'id',
	'text',
	'startDate',
	'priority',
	'closed',
	'resolved',
	'updatedAt',
	'commentsCount',
	'statusId',
	'userId',
	'organizationId',
	'ratingId',
	'systemId',
	'recognized',
	'title'
];

export const USER_FIELDS = [
	'id',
	'avatar',
	'lastName',
	'firstName',
	'middleName',
	'username'
];

export const APPEAL_INCLUDE = [
	{ relation: 'person', scope: { fields: ['id', 'lastName', 'firstName', 'middleName' ]}},
	{ relation: 'user', scope: { fields: USER_FIELDS }},
	{ relation: 'attachments'}
];

class AppealsKanbanStore {

	@observable isInitialized = false;
	@observable isLoading = true;
	@observable project = [];
	@observable error = null;
	@observable lists = [];
	@observable search = '';
	@observable user = null;
	@observable organization = null;
	@observable errors = [];
	@observable saving = false;
	
	@action init = async () => {
		this.search = '';
		await this.load();
		this.isInitialized = true;
	};
	
	@action load = async () => {
		this.isLoading = true;
		this.error = null;
		this.errors = [];

		const appealsWhere = this.compileFilter();

		const filter = {
			order: 'priority desc',
			fields: ['id', 'name', 'priority', 'color', 'closing' ],
			include: [
				{
					relation: 'appeals',
					scope: {
						where: appealsWhere,
						limit: INITIAL_LIMIT,
						fields: APPEAL_FIELDS,
						include: APPEAL_INCLUDE,
						order: 'priority desc',
					}
				}
			],
		};

		const lists = await store.model.ViewAppealStatus.find(filter);
		await this.getCounts(lists, appealsWhere);

		this.project = lists;
		console.log('load', this.project, lists);

		this.isLoading = false;
	};

	getCounts = async (lists, where) => {
		for (let list of lists) {
			const and = [{ statusId: list.id }];
			console.log('where', where);
			if (where.and) and.push(...where.and);
			list.totalCount = await store.model.ViewAppeal.count({ and });
			// console.log('count', list.totalCount);
		}
	};

	compileFilter = () => {
		const where = {
			and: [
			],
		};

		const trimmed = this.search.trim();
		if (trimmed.length > 0) {
			const words = trimmed.split(' ');
			words.forEach(word => where.and.push({
				or: [
					{ id: { like: `${word}%` }},
					{ title: { ilike: `%${word}%` }},
					{ lastName: { ilike: `%${word}%` }},
					{ firstName: { ilike: `%${word}%` }},
					{ middleName: { ilike: `%${word}%` }},
					{ externalSystemName: { ilike: `%${word}%` }},
					{ organizationName: { ilike: `%${word}%` }},
				]
			}));
			// console.log('>', where.and);
		}

		if (this.user) {
			where.and.push({ userId: this.user });
		}

		return where.and.length > 0 ? where : {};
	};

	loadMore = async list => {
		const where = this.compileFilter();
		console.log('loadMore', list.items.length);

		const moreItems = await store.model.ViewAppeal.find({
			where: {
				and: [
					...(where.and || []),
					{ statusId: list.id },
				],
			},
			order: 'priority desc',
			limit: INITIAL_LIMIT,
			skip: list.items.length,
			include: APPEAL_INCLUDE,
		});

		return moreItems.map(appeal => ({
			id: appeal.id,
			listId: appeal.statusId,
			path: `/appeals/${appeal.id}`,
			data: appeal,
		}));
	};
	
	onError = error => this.errors.push({
		date: new Date(),
		error,
	});
}

export default new AppealsKanbanStore();

