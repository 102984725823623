import { Fill, Stroke, Style, Icon } from 'ol/style';
import { asArray } from 'ol/color';
import VectorLayer from 'ol/layer/Vector';
import { Vector as VectorSource } from "ol/source";

import { ColorUtils } from '@smartplatform/ui';
import { drawGeoMarker, geoJSON, DEFAULT_STYLE } from '@smartplatform/map/client';
import iconSrc from './firedep.svg';
import store from 'client/store';
import t from 'i18n';
import FireDepPopup from './FireDepPopup';
import React from "react";

export default class FireDepLayer {

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource()
		});

		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
		this.load();
	};

	load = async () => {
		const where = this.settings.countyId ? { countyId: this.settings.countyId } : undefined;
		const records = await store.model.FireDep.find({
			where,
		});

		const features = [];
		for (let record of records) {
			const { geo, responsibilityArea, style } = record;
			
			const { r, g, b } = ColorUtils.hexToRgb('#0af');
			
			let styleParams = {
				fill: new Fill({ color: asArray([r, g, b, 0.4]) }),
				stroke: new Stroke({ color: asArray([r, g, b, 0.9]), width: 1 }),
			};
			
			const marker = drawGeoMarker(geo, {
				data: {
					title: t('fireDep.title'),
					record,
					render: this.renderPopup,
				},
				style: new Style({
					...styleParams,
					image: new Icon({
						src: record.icon ?? iconSrc,
					}),
				}),
			});
			
			features.push(...marker);

			if (responsibilityArea) {
				let styleParams = DEFAULT_STYLE;
				if (record.style) {
					const { fill, fillOpacity, stroke, strokeOpacity, strokeWidth, strokeDasharray } = record.style;
					let lineDash = null;
					if (strokeDasharray) {
						lineDash = [];
						strokeDasharray.forEach(v => lineDash.push(...[v * strokeWidth]));
					}
					styleParams = {
						fill: new Fill({ color: asArray([...ColorUtils.hexToArray(fill), fillOpacity])}),
						stroke: new Stroke({
							color: asArray([...ColorUtils.hexToArray(stroke), strokeOpacity]),
							width: strokeWidth,
							lineDash,
						}),
					}
					// console.log('styleParams', lineDash, styleParams);
				}
				const zone = drawGeoMarker(responsibilityArea, {
					data: {
						title: t('fireDep.responsibilityArea'),
						record,
						render: this.renderPopup,
					},
					style: new Style({
						...styleParams,
					}),
					onlyGeo: true,
				});
				
				features.push(...zone);
			}
		}
		
		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
	};

	renderPopup = (record) => {
		return <FireDepPopup record={record} />;
	}

};
