import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Address, Create } from '../single-page';
import { AddressList } from './AddressList';

import './address.scss';

export default (props) => {
	return (
		<Switch>
			<Route path='/techfires/addresses' exact render={(routeProps) => <AddressList {...routeProps} />} />
			<Route path='/techfires/addresses/page/:page' render={(routeProps) => <AddressList {...routeProps} />} />
			<Route path='/techfires/addresses/create' render={(routeProps) => <Create {...routeProps} />} />
			<Route path='/techfires/addresses/:id' render={(routeProps) => <Address {...routeProps} />} />
		</Switch>
	);
};
