import React from 'react';
import { observer, Provider } from 'mobx-react';
import { DatePicker, Field, Form, Popup, RelationSelect, Row } from '@smartplatform/ui';
import { Actionbar } from 'components';
import t from 'i18n';
import OperStore from './store';
import { fio } from 'client/tools';
import { isAdmin, isMinArct } from 'delivery/helpers/roles';

@observer
class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.store = new OperStore();
		this.store.id = this.props.selectedRecordId;
		this.store.onClose = this.props.onClose;
		this.store.init();
	}

	render() {
		const { isLoading, record, onSave, onDelete, isNew, onChange } = this.store;
		if (isLoading) return null;

		const title = isNew ? t('create') : t('save');

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton disabled={!record.categoryId || !record.periodId || !record.countyId || !record.cityId} title={title} />
				<Actionbar.CancelButton back={this.props.onClose} />
				{(isMinArct() || isAdmin()) && record.id && <Actionbar.DeleteButton onDelete={onDelete} />}
			</Actionbar>
		);

		return (
			<Popup onClose={this.props.onClose} className='delivery-popup request-supply-popup'>
				<Provider store={this.store}>
					<Form record={record} controls={controls} stay onSave={onSave} onDelete={onDelete} noDelete noSave>
						<Row>
							<Field label={t('date_short')} property='date'>
								<DatePicker showClearButton={false} />
							</Field>
							<Field label={t('operInfo.lastEditDate')} property='updatedAt' disabled>
								<DatePicker showClearButton={false} />
							</Field>
							<Field label={t('request.author')} relation='owner' computed={fio} disabled />
						</Row>
						<Row>
							<Field label={t('county.title')} relation='county' property='name' disabled isRequired>
								<RelationSelect onChange={(item) => onChange(item, 'county')} />
							</Field>
							<Field label={t('city.title')} relation='city' property='name' filter={{ where: { countyId: record.countyId ?? null } }} disabled isRequired />
						</Row>
						<Row>
							<Field label={t('operInfo.organization')} relation='organization' property='name' />
								{/*<Field label={t('request.contractor')} relation='contractor' property='name' />*/}
						</Row>
						<Row>
							<Field label={t('requestPeriod.title')} relation='period' property='name' isRequired />
							{record.period?.code === 'summer' && <Field label={t('requestRiver.title')} relation='river' property='name' />}
							{record.period?.code === 'winter' && <Field label={t('requestPeriodDate.title')} relation='periodDate' property='name' />}
						</Row>
						<Row>
							<Field label={t('category')} relation='category' property='name' isRequired disabled />
						</Row>
						<Row>
							<Field label={t('operInfo.plan')} property='plan' disabled />
							<Field label={t('operInfo.ship')} property='ship' />
							<Field label={t('operInfo.deliver')} property='deliver' />
						</Row>
					</Form>
				</Provider>
			</Popup>
		);
	}
}

export default Edit;
