import store from 'client/store';

export const isCustomer = () => {
	return !!store.model.roles.find((role) => role.name === 'customer_delivery');
};
export const isContractor = () => {
	return !!store.model.roles.find((role) => role.name === 'contractor_delivery');
};
export const isOperator = () => {
	return !!store.model.roles.find((role) => role.name === 'operator_delivery');
};
export const isAdmin = () => {
	return !!store.model.roles.find((role) => role.name === 'admin' || role.name === 'admin_delivery');
};

export const isMinArct = () => {
	return !!store.model.roles.find((role) => role.name === 'min_arct_delivery');
};

export const isYakutOptTorg = () => {
	return !!store.model.roles.find((role) => role.name === 'yakut_opt_torg_delivery');
};

export const isHozSubject = () => {
	return !!store.model.roles.find((role) => role.name === 'hoz_subject_delivery');
};

export const isMO = () => {
	return !!store.model.roles.find((role) => role.name === 'mo_delivery');
};

export const isAdminDelivery = () => {
	return !!store.model.roles.find((role) => role.name === 'admin_delivery');
};
