import { observable } from 'mobx';
import store from 'client/store';
import { LOCAL_STORAGE_FIELDS } from './constants';
import { KANBAN, LIST, PER_PAGE } from 'client/constants';
import debounce from 'lodash/debounce';
import {isHozSubject, isMO} from "delivery/helpers/roles";

export default class TrackingStore {
	@observable search = '';
	@observable querySearch = '';
	@observable showFilters = false;
	@observable type = null;
	@observable statuses = [];
	@observable searchField = '';
	@observable mode = LIST;
	@observable startDate = null;
	@observable endDate = null;
	@observable period = null;
	@observable reload = null;
	//list
	@observable order = 'id desc';
	@observable page = 1;
	@observable totalCount = 0;

	constructor() {
		this.localStorageData = store.local.delivery.tracking || {};
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	init = async () => {
		this.loadLocalStorage();
		this.querySearch = this.search;
	};

	doSearch = () => {
		this.querySearch = this.search;
		this.reload?.();
	};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
		this.saveToLocalStorage();
	};

	onChange = (prop) => (value) => {
		this.page = 1;
		if (['endDate', 'startDate'].includes(prop)) this.period = null;
		this[prop] = value;
		this.reload?.();
		this.saveToLocalStorage();
	};

	onModeChange = (mode) => () => {
		this.mode = mode;
		this.saveToLocalStorage();
	};

	onShowFiltersChange = () => {
		this.showFilters = !this.showFilters;
		this.saveToLocalStorage();
	};

	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (store.local.delivery.tracking[field] = this[field]));
		store.local.save();
	};

	loadLocalStorage = async () => {
		const keys = Object.keys(this.localStorageData) || [];
		keys.forEach((key) => {
			if (this.localStorageData[key]) {
				this[key] = this.localStorageData[key];
			}
		});
		if (!Array.isArray(this.statuses)) {
			this.statuses = this.localStorageData.statuses = [];
			this.saveToLocalStorage();
		}
	};

	onQueryUpdate = async (query) => {
		if (query._totalCount) this.totalCount = query._totalCount;
		this.order = query.order;
	};

	get requestFilter() {
		const { startDate, endDate, type, querySearch, statuses, mode, searchField } = this;
		const filter = {
			where: { and: [{ statusId: { neq: null } }] },
			include: [
				{ relation: 'tracks', scope: { include: ['status'] } }
			],
		};
		if (isHozSubject()) filter.where.and.push({ organizationId: store.model.user.organizationId, typeCode: 'SCHEDULE' });
		if (isMO()) filter.where.and.push({ organizationId: store.model.user.organizationId, typeCode: 'BASIC'  });
		if (startDate) filter.where.and.push({ actualDeliveryDate: { gte: startDate } });
		if (endDate) filter.where.and.push({ actualDeliveryDate: { lte: endDate } });
		if (type) filter.where.and.push({ typeId: type.id });
		if (querySearch.length) {
			if (searchField) {
				filter.where.and.push({ [searchField]: { ilike: `%${querySearch}%` } });
			} else {
				filter.search = querySearch;
			}
		}
		if (statuses.length) filter.where.and.push({ statusId: { inq: statuses.map((i) => i.id) } });

		if (mode === KANBAN) {
			filter.order = 'priority desc';
		} else if (mode === LIST) {
			filter.order = this.order;
			filter.limit = PER_PAGE;
			filter.skip = (this.page - 1) * PER_PAGE;
		}
		if (!filter.where.and.length) delete filter.where;
		return filter;
	}
}
