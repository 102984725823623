import React from 'react';
import PropTypes from 'prop-types';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHome, faMapMarker, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { Trigger, Button } from '@smartplatform/ui';
import { URL, SUGGEST_COUNT } from '../search/store';
import { shortenAddressName } from 'client/tools';
import store from 'client/store';
import AddressSearchStore from './store';
import './style.scss';

@observer
export default class AddressSearch extends React.Component {
	
	static propTypes = {
		/**
		 * Текущее значение, запись модели Address или null.
		 * @type {(Address|null)}
		 */
		value: PropTypes.object,
		
		/**
		 * Коллбэк при изменении значения.
		 * @param {(Address|null)} newValue
		 */
		onChange: PropTypes.func,
		
		/**
		 * Префикс для поиска. По умолчанию "Якутск"
		 */
		prefix: PropTypes.string,
		className: PropTypes.string,
		renderValue: PropTypes.func,
		onValueClick: PropTypes.func,
		position: PropTypes.string,
		matchWidth: PropTypes.bool,
		noImmediateSearch: PropTypes.bool,
	};
	
	static defaultProps = {
		prefix: 'Якутск',
		position: 'bottomLeft',
		matchWidth: false,
		noImmediateSearch: false,
	};
	
	constructor(props) {
		super(props);
		this.store = new AddressSearchStore(props);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			const value = this.props.value ? this.props.value.name : '';
			this.store.search = this.store.prevSearch = this.store.actualSearch = value;
			this.store.props = { ...this.props };
		}
	}
	
	getInstance = (triggerInstance) => this.store.triggerInstance = triggerInstance;
	
	render() {
		const {
			search,
			onChange,
			onActivate,
			onClose,
			onSelect,
			isLoading,
			selected,
			actualSearch,
			focus,
			onFocus,
			onBlur,
			suggestions,
			getInputRef,
			onValueClick,
		} = this.store;
		
		const _suggestions = suggestions
			// .slice((this.page - 1) * SUGGEST_COUNT, this.page * SUGGEST_COUNT)
			.map((obj, i) => {
				if (obj.__type === 'db') {
					return <div className="suggestion db" key={i} onClick={onSelect(obj)} title={`address id: ${obj.id}`}>
						<span className="badge"><FontAwesomeIcon icon={faHome}/></span>
						<span title={obj.name}>{shortenAddressName(obj.name)}</span>
					</div>;
				}
				else {
					return <div className="suggestion fias" key={i} onClick={onSelect(obj)}>
						<span className="badge"><FontAwesomeIcon icon={faLocationArrow}/></span>
						<span title={obj['unrestricted_value']}>{shortenAddressName(obj.value)}</span>
					</div>;
				}
			});
		
		const popup = suggestions.length > 0 && !isLoading ?
				<div className="suggestions">{_suggestions}</div>
				:
				<div className="empty">{isLoading ? 'Поиск...' : 'нет результатов'}</div>;
		
		const isActive = this.store.active;

		const triggerProps = {
			position: this.props.position,
			trigger: 'manual',
			getInstance: this.getInstance,
			portalClassName: 'address-search-popup',
			className: classNames('address-search', this.props.className),
			popup,
			onActivate: onActivate,
			onClose: onClose,
			matchWidth: this.props.matchWidth,
		};
		
		const content = this.props.value && !isActive ?
			<div className="address-value ui-input" onClick={onValueClick}>
				{this.props.renderValue ?
					this.props.renderValue(this.props.value)
					:
					<><FontAwesomeIcon icon={faHome} /> {shortenAddressName(this.props.value.name)}</>
				}
			</div>
			:
			<input
				type="text"
				value={search}
				onChange={onChange}
				ref={getInputRef}
				className="ui-input"
				placeholder={focus ? 'Поиск адреса' : (actualSearch || 'Поиск адреса')}
				onFocus={onFocus}
				onBlur={onBlur}
			/>;
		
		return <Trigger {...triggerProps}>
			{content}
		</Trigger>;
	}
	
}
