import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { generatePath } from 'react-router';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Pager, Loader, Select } from '@smartplatform/ui';
import WrappedTable from './WrappedTable';
import store from 'client/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import t from 'i18n';
import './style.scss';

const pageSizeSelection = [5, 10, 20, 30, 50, 100];

@withRouter
@observer
export default class List extends React.Component {
	static propTypes = {
		model: PropTypes.any,
		title: PropTypes.string,
		filter: PropTypes.object,
		path: PropTypes.string,
		order: PropTypes.string,
		pageSize: PropTypes.number,
		noEdit: PropTypes.bool,
		noFilters: PropTypes.bool,
		noHeader: PropTypes.bool,
		searchValue: PropTypes.string,
		searchQuery: PropTypes.func,
		noPerPageSelector: PropTypes.bool,
		onCreate: PropTypes.func,
		onRowClick: PropTypes.func,
		getInstance: PropTypes.func,
	};

	static defaultProps = {
		pageSize: 10,
		order: 'id desc',
		noPerPageSelector: true,
	};

	@observable query = {};
	@observable whereFilter = {};
	@observable records = [];
	@observable isLoading = false;
	@observable page = 1;
	@observable search = '';
	@observable itemsPerPage = 10;
	initial = true;

	constructor(props) {
		super(props);
		this.page = this.getPage();
		this.itemsPerPage = this.props.pageSize;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.query = {
			...this.props.filter,
			skip: (this.page - 1) * this.itemsPerPage,
			limit: this.itemsPerPage,
			order: props.order,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const page = this.getPage();
		if (prevProps.searchValue !== this.props.searchValue) {
			const { searchValue, searchQuery } = this.props;
			if (searchQuery) {
				this.whereFilter = searchQuery(searchValue);
			} else {
				this.search = searchValue;
			}
			this.doSearch();
		}
		if (page !== this.page) {
			this.page = page;
			this.query = {
				...this.query,
				skip: (page - 1) * this.itemsPerPage,
				limit: this.itemsPerPage,
			};
		}
	}

	getPage = () => (this.props.match && this.props.match.params ? parseInt(this.props.match.params.page) || 1 : 1);

	onPageChange = (newPage) => {
		const { page, ...params } = this.props.match.params;
		let pattern = this.props.match.path;
		if (!page && newPage !== 1) pattern += (this.props.path || '') + '/page/:page';
		// if (newPage === 1) pattern = pattern.replace(/\/page\/:page/, '');
		let url = generatePath(pattern, {
			...params,
			page: newPage, //newPage > 1 ? newPage : undefined,
		});
		store.route.push({ path: url });
	};

	create = () => {
		if (this.props.onCreate) {
			this.props.onCreate();
			return;
		}
		const { page, ...params } = this.props.match.params;
		let pattern = this.props.match.path;
		pattern = pattern.replace(/\/page\/:page/, '');
		pattern += (this.props.path || '') + '/create';
		let path = generatePath(pattern, { ...params });
		const route = { path };
		store.route.push(route);
	};

	onRowClick = (record) => {
		if (this.props.onRowClick) {
			this.props.onRowClick(record);
			return;
		}
		store.route.push({ path: this.makePath(record) });
	};

	makePath = (record) => {
		const { page, ...params } = this.props.match.params;
		let pattern = this.props.match.path;
		pattern = pattern.replace(/\/page\/:page/, '');
		pattern += (this.props.path || '') + '/:id';
		let path = generatePath(pattern, {
			...params,
			id: record.id,
		});
		return path;
	};

	onQueryUpdate = (query, prevQuery) => {
		this.initial = false;
		this.query = query;
		if (prevQuery && this.page > 1 && query.skip === 0) {
			const { page, ...params } = this.props.match.params;
			let pattern = this.props.match.path;
			pattern = pattern.replace(/\/page\/:page/, '');
			let path = generatePath(pattern, { ...params });
			store.route.push({ path });
		}
	};

	onLoadStart = () => (this.isLoading = true);
	onLoadEnd = () => (this.isLoading = false);

	onSearch = (e) => {
		this.search = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		const trimmed = this.search.trim();
		const { _totalCount, ...query } = this.query;
		this.page = 1;
		this.query = {
			...query,
			...this.whereFilter,
			search: trimmed.length > 0 ? trimmed : undefined,
			skip: 0,
			limit: this.itemsPerPage,
		};
	};

	onPageSizeChange = (value) => {
		this.itemsPerPage = value;
		this.doSearch();
	};

	render() {
		const { children, title, model, noFilters, noHeader, noEdit, noPerPageSelector, onCreate, onRowClick, ...rest } = this.props;

		const className = classNames('table-with-pager', {
			loading: this.isLoading,
			initial: this.isLoading && this.initial,
		});

		const modelLowerName = model.name.charAt(0).toLowerCase() + model.name.slice(1);

		return (
			<div className='fixed-page model-list'>
				{!noHeader && <h1>{title || t(`${modelLowerName}.plural`)}</h1>}
				{!noFilters && (
					<div className='filters'>
						<input type='text' style={{ height: 36 }} value={this.search} onChange={this.onSearch} placeholder={t('search')} />
						{!noEdit && (
							<Button variant='primary' onClick={this.create} disabled={!model.INFO.WRITE}>
								<FontAwesomeIcon icon={faPlus} /> {t('add')}
							</Button>
						)}
					</div>
				)}
				{this.isLoading && this.initial && null}
				<div className={className}>
					<div className='ui-pager'>
						<Pager current={this.page} totalCount={this.query._totalCount || 0} onChange={this.onPageChange} itemsPerPage={this.itemsPerPage} />
						{!noPerPageSelector && (
							<div className='items-per-page'>
								<>{t('itemsPerPage')}: </>
								<Select value={this.itemsPerPage} items={pageSizeSelection} onChange={this.onPageSizeChange} isRequired noSearch width={60} size='sm' />
							</div>
						)}
					</div>
					<WrappedTable
						{...rest}
						model={model}
						query={this.query}
						onQueryUpdate={this.onQueryUpdate}
						onRowClick={this.onRowClick}
						onLoadStart={this.onLoadStart}
						onLoadEnd={this.onLoadEnd}
					>
						{children}
					</WrappedTable>
				</div>
			</div>
		);
	}
}
