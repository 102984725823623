import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { ModelList } from 'components';
import { Column, Table, Pager } from '@smartplatform/ui';
import { shortenAddressName } from 'client/tools';
import { Filters } from '../filters';
import store from 'client/store';
import t from 'i18n';

@inject('addressStore')
@observer
export class ObjectsList extends React.Component {
	constructor(props) {
		super(props);
		this.addressStore = this.props.addressStore;
		this.addressStore.init();
	}

	onRowClick = (record) => store.route.push({ path: `/techfires/addresses/${record.addressId}` });

	shortAddress = (record) => shortenAddressName(record.addressName);

	render() {
		const { getFilters, onQueryUpdate, totalCount, onChange, page } = this.addressStore;
		const filters = getFilters();

		return (
			<div className='address-list'>
				<Filters noObjectCount />
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} />
				<Table query={filters} onQueryUpdate={onQueryUpdate} model={store.model.ViewTechFireObject} onRowClick={this.onRowClick}>
					<Column property='name' label={t('name')} />
					<Column property='rank' label={t('techFireAddress.rank')} />
					<Column property='ptp' label={t('techFireAddress.ptp')} />
					<Column property='ktp' label={t('techFireAddress.ktp')} />
					<Column computed={this.shortAddress} property='addressName' label={t('address.title')} />
					<Column property='description' label={t('techFireAddress.description')} />
				</Table>
			</div>
		);
	}
}
