import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import appealsStore from '../store';

@observer
export default class Legend extends React.Component {

	static propTypes = {};
	static defaultProps = {};
	
	constructor(props) {
		super(props);
	}
	
	render() {
		const { data }= this.props;
		const sum = data.map(c => parseInt(c.count)).reduce((a, b) => a + b, 0);

		const categories = data.map(c => {
			const color = appealsStore.categoryColor(c);
			const percent = sum !== 0 ? c.count * 100 / sum : 0;
			let fixed = 0;
			if (c.count > 0 && percent < 10) fixed = 1;
			if (c.count > 0 && percent < 1) fixed = 2;
			return <div key={c.id} className="item" >
				<div className="color"><span style={{ background: color }} /></div>
				<div className="name">{c.name}</div>
				<div className={'count' + (percent === 0 ? ' zero' : '')}>{c.count}</div>
				<div className={'percent' + (percent === 0 ? ' zero' : '')}>{percent.toFixed(fixed)}%</div>
			</div>;
		});

		return <div className="pie-legend">
			{categories}
		</div>;
	}
	
}
