import React from 'react';
import { observer, Provider } from 'mobx-react';
import List from './list/List';
import OperInfoStore from './store';
import Toolbar from './toolbar/Toolbar';
import t from '@smartplatform/map/i18n';

@observer
export default class OperationalInfo extends React.Component {
	constructor(props) {
		super(props);
		this.store = new OperInfoStore();
		this.store.path = props.path;
		this.store.init();
	}

	render() {
		return (
			<Provider store={this.store}>
				<div className='oper-info'>
					<h2>{t(`operInfo.title`)}</h2>
					<Toolbar store={this.store} />
					<List />
				</div>
			</Provider>
		);
	}
}
