import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '@smartplatform/ui';

import './style.scss';

import { DateRangePicker as DRP } from 'components';

@observer
export default class DateRangePicker extends React.Component {
	@observable dateValues = {
		startDate: null,
		endDate: null,
		period: null,
	};

	onChange = () => console.log('dateValues changed: ', this.dateValues);
	onClean = () => {
		this.dateValues.startDate = null;
		this.dateValues.endDate = null;
		this.dateValues.period = null;
	};

	render() {
		const { onChange, dateValues, onClean } = this;
		return (
			<div className='mb-3'>
				<h2>DateRangePicker</h2>

				<div className='d-flex'>
					<DRP onChange={onChange} dateValues={dateValues} />
					<Button variant='info' className='ml-2' onClick={onClean}>
						Сбросить значения инпутов
					</Button>
				</div>
			</div>
		);
	}
}
