import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import TechFire, { CreateTechFire } from './fire';
import { WayBill, WayBills } from './fire/waybills';
import './style.scss';

export default () => (
	<div className='techfires-registry'>
		<Switch>
			<Route path='/techfires/fires' exact render={({ match }) => <List match={match} />} />
			<Route path='/techfires/fires/page/:page' render={({ match }) => <List match={match} />} />
			<Route path='/techfires/fires/create' exact render={(routeProps) => <CreateTechFire {...routeProps} />} />
			<Route path='/techfires/fires/:id' render={(routeProps) => <TechFire {...routeProps} />} />
		</Switch>
	</div>
);
