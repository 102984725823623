import React from 'react';
import { renderStatus } from 'client/tools';
import { DatePicker, Select, RecordSelect } from '@smartplatform/ui';
import appStore from 'client/store';
import t from 'i18n';
import { observer } from 'mobx-react';
import { PERIODS } from '../constants';
import './style.scss';

export const Filters = observer(({ store }) => {
	const { onChange, type, statuses, startDate, endDate, period, searchField } = store;
	const periods = PERIODS.map((period) => {
		return { label: t('period.' + period), value: period };
	});
	const showStatusesValue = statuses.length > 0 ? t('selectedStatuses') + ': ' + statuses.length : <span className='placeholder'>{t('selectStatus')}</span>;

	return (
		<div className='filters'>
			<Select
				noTotal
				maxItems={10}
				itemsPerPage={1000}
				items={periods}
				placeholder={t('period.select')}
				onChange={onChange('period')}
				value={period}
				width={200}
			/>
			<DatePicker placeholder={t('period.start')} onChange={onChange('startDate')} value={startDate} />
			<DatePicker placeholder={t('period.end')} onChange={onChange('endDate')} value={endDate} />
			<RecordSelect
				model={appStore.model.RequestTrackStatus}
				computed={renderStatus}
				value={statuses}
				onChange={onChange('statuses')}
				showValue={showStatusesValue}
				width={200}
			/>
			<RecordSelect
				noTotal
				maxItems={10}
				itemsPerPage={1000}
				placeholder={t('selectType')}
				model={appStore.model.RequestType}
				property='name'
				onChange={onChange('type')}
				value={type}
				width={200}
			/>
			<Select
				noTotal
				maxItems={10}
				itemsPerPage={1000}
				placeholder={t('selectField')}
				items={[
					{ label: t('request.trackNumber'), value: 'trackNumber' },
				]}
				value={searchField}
				onChange={onChange('searchField')}
				noSearch
				width={200}
			/>
		</div>
	);
});
