import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Panel } from 'components';
import store from 'client/store';
import { formatDate } from 'client/tools';
import t from 'i18n';
import './style.scss';

const panelProps = {
	left: 0,
	top: 0,
	bottom: 0,
	width: 960,
	height: 'auto',
};

@observer
export default class Form extends React.Component {

	@observable record = null;
	@observable error = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.error = null;
		this.id = parseInt(this.props.match.params.id);

		if (this.id) {
			try {
				this.record = await store.model.Appeal.findById(this.id, {
					include: [
						{
							relation: 'category',
							scope: {
								include: ['parent']
							}
						},
						{ relation: 'status' },
						{ relation: 'system' },
						{ relation: 'attachments' },
					],
				});
			}
			catch (e) {
				this.error = e.message;
			}
		}
		else {
			this.error = t('idError');
		}
	};

	processText = (text) => {
		console.assert(text, 'Нет текста заявки')
		return text && text.replace(/(https?:[^\s\r\n]+)/g, '<a target="_blank" href="$1">$1</a>');
	};

	render() {
		if (this.error) return <Panel {...panelProps} header={t('error')}>{this.error}</Panel>;
		if (!this.record) return <Panel {...panelProps} header={t('appeal.title')}>...</Panel>;

		let header = t('appeal.title');
		if (this.record.id) header += ' #' + this.record.id;
		const attachments = this.record.attachments && this.record.attachments() || [];

		//* берем тайтл, а не текст у распознанных заявок ЕСЭД
		let text = this.record.text || this.record.title || '';
		if ((this.record.systemId === 5) && this.record.recognized) {
			text = this.record.title;
		};

		return <Panel {...panelProps} header={header} className="appeal-form">
			{this.record.category && <div className="category">
				{this.record.category.parent && <>{this.record.category.parent.name} / </>}
				{this.record.category.name}
			</div>}
			{this.record.startDate && <div className="date">{t('appeal.date')}: <strong>{formatDate(this.record.startDate)}</strong></div>}
			{this.record.endDate && <div className="date">{t('appeal.endDate')}: <strong>{formatDate(this.record.endDate)}</strong></div>}
			{this.record.status && <div className="status">{t('appeal.status')}: <strong>{this.record.status.name}</strong></div>}
			{this.record.system && <div className="system">{t('appeal.system')}: <strong>{this.record.system.name}</strong></div>}
			{/*{this.record.title && <div className="title">{this.record.title}</div>}*/}
			<div className="text" dangerouslySetInnerHTML={{ __html: this.processText(text) }}/>

			<div>
				{attachments.length > 0 &&
				attachments.map(attach => (
					<p key={`attachment-${attach.id}`}>
						<a target='_blank' href={attach.downloadFile('filename')}>
							{attach.filename}
						</a>
					</p>
				))}
			</div>
		</Panel>;
	}

}
