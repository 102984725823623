import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { Button, Table, Column } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@inject('fireDepStore')
@observer
export default class List extends React.Component {
	@observable records = [];

	constructor(props) {
		super(props);
		this.store = props.fireDepStore;
		this.init();
	}

	init = async () => {
		this.records = await store.model.FireDepSentry.find({
			where: { fireDepId: this.store.id },
			include: [
				{ relation: 'personnel', scope: { fields: ['id'] } },
				{ relation: 'vehicles', scope: { fields: ['id'] } },
			],
		});
	};

	go = (record) => {
		store.route.push({ path: `/techfires/firedep/${this.store.id}/sentries/${record.id}` });
	};

	create = () => {
		store.route.push({ path: `/techfires/firedep/${this.store.id}/sentries/create` });
	};

	personnelCount = ({ record }) => record.personnel().length;
	vehiclesCount = ({ record }) => record.vehicles().length;

	render() {
		return (
			<div className='sentries-list'>
				<div className='actions-bar'>
					<Button variant='primary' onClick={this.create}>
						{t('fireDepSentry.create')}
					</Button>
				</div>
				<Table rows={this.records} onRowClick={this.go}>
					<Column relation='globalSentry' property='name' label={t('fireDepSentry.globalSentry')} width={200} />
					<Column property='name' label={t('fireDepSentry.customName')} />
					<Column label={t('fireDep.personnel')}>{this.personnelCount}</Column>
					<Column property='units' label={t('fireDepSentry.units')} />
					<Column label={t('fireDep.vehicles')}>{this.vehiclesCount}</Column>
				</Table>
			</div>
		);
	}
}
