import React from 'react';
import { ExportButton } from 'components';
import { Button } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export default class Actionbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { isHTML, disableGenerate, generateReport } = this.props;
		const { downloadReport, downloadReportHTML, printReport, url, isLoading, report } = this.store;

		const download = isHTML ? downloadReportHTML : downloadReport;
		const updateButton = isHTML ? t('update') : t('techFireReport.generate');

		return (
			<div className='action-bar'>
				<Button variant='primary' onClick={generateReport} disabled={isLoading || disableGenerate}>
					{updateButton}
				</Button>
				<ExportButton disabled={isLoading || !report} onClick={download} text={t('report.download')} />
				{!isHTML && (
					<Button variant='primary' onClick={printReport} disabled={isLoading || !url}>
						{t('techFireReport.print')}
					</Button>
				)}
			</div>
		);
	}
}
