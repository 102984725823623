import React from 'react';
import { observer } from 'mobx-react';

import { Map, Popup } from '@smartplatform/map/client';
import store from 'client/store';
import Filters from './filters';
import emergencyMap from './store';
import { WeatherScale } from 'components';
import MainPopup from './MainPopup';

const PARAMS = {
    zoom: 5,
    minZoom: 1,
    maxZoom: 20,
    center: [ 128.74929854276843, 63.27207348590292 ], // Якутия
};

@observer
export default class EmergencyMap extends React.Component {

    onLayerToggle = (layers) => {
        store.local.emergency.dynamicLayers = layers;
        store.local.save();
    };

    render() {
        const { popup, mapInitialized, onTileSourceChange } = emergencyMap;
        const visibleLayers = store.local.emergency.dynamicLayers;

        const mapParams = {
            ...PARAMS,
            onTileSourceChange,
            defaultTileSource: store.local.emergency.tileSource,
        };
        
        const mapProps = {
            className: 'emergency-map full-map',
            onInit: emergencyMap.onMapInit,
            params: mapParams,
            modelStore: store.model,
            visibleLayers,
            onLayerToggle: this.onLayerToggle,
        };
        
        return <Map {...mapProps}>
            {popup && <Popup {...popup} width={popup.width || 550}>
                <MainPopup {...popup} />
            </Popup>}
            {mapInitialized && <>
                <Filters />
                <WeatherScale onMount={emergencyMap.weatherLayer.onWeatherScaleMount} hidden={!store.local.emergency.layers.weather.show} />
            </>}
        </Map>;
    }
}
