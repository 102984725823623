import React, { createRef } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Popup } from '@smartplatform/ui';
import defaultAvatarUrl from '/client/img/userpic.png';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import { Controls, UploadButton } from 'components';
import t from 'i18n';
import './style.scss';

@observer
export class AvatarEdit extends React.Component {
	@observable showEditPopup = false;
	@observable imgToUpload;
	@observable scale = 1;

	static propTypes = {
		imgWidth: PropTypes.number,
		imgHeight: PropTypes.number,
		editorWidth: PropTypes.number,
		editorHeight: PropTypes.number,
		property: PropTypes.string,
		borderRadius: PropTypes.number,
	};
	static defaultProps = {
		imgWidth: 300,
		imgHeight: 300,
		editorWidth: 400,
		editorHeight: 400,
		borderRadius: 500,
		scale: 1,
		property: 'filename',
	};

	constructor(props) {
		super(props);
		this.ref = createRef();
	}

	closeEditPopup = () => {
		this.showEditPopup = false;
		this.imgToUpload = null;
		this.scale = 1;
	};
	openEditPopup = () => {
		this.showEditPopup = true;
	};

	onAttach = async (e) => {
		this.file = e.target.files[0];
		if (!this.file) return;
		const reader = new FileReader();
		reader.onload = (e) => {
			this.imgToUpload = e.target.result;
		};
		reader.readAsDataURL(this.file);
	};

	onSave = async () => {
		if (this.ref) {
			const { record, property } = this.props;
			const canvas = this.ref.getImage().toDataURL();
			const res = await fetch(canvas);
			this.uploadedImg = res.url;
			const blob = await res.blob();
			let filename = this.file?.name || record[property];
			filename = blob.size + filename;
			await record.uploadFile(property, new File([blob], filename, { type: blob.type }));
			await record.patchAttributes({ [property]: filename });
			this.closeEditPopup();
		}
	};

	onDelete = async () => {
		const { record, property } = this.props;
		if (record[property]) {
			await record.patchAttributes({ [property]: null });
			await record.deleteFile(property);
		}

		this.closeEditPopup();
	};

	onScaleChange = (e) => (this.scale = +e.target.value);

	setEditorRef = (editor) => (this.ref = editor);

	get imgSrc() {
		const propertyValue = this.props.record[this.props.property];
		const { imgWidth, imgHeight } = this.props;
		return propertyValue
			? this.props.record.downloadFile('avatar') + `?image=[{"resize":{"width":${imgWidth},"height":${imgHeight}}}]&` + propertyValue
			: defaultAvatarUrl;
	}

	render() {
		const { imgWidth, imgHeight, editorWidth, editorHeight, borderRadius, record, property } = this.props;
		const { closeEditPopup, openEditPopup, showEditPopup, imgToUpload, setEditorRef, onSave, onDelete, onAttach, scale, onScaleChange } = this;
		const avatarEditorProps = { width: editorWidth, height: editorHeight, borderRadius, scale };
		const isNew = !record[property];
		const disableSave = this.imgSrc === defaultAvatarUrl && !imgToUpload;

		return (
			<div className='edit-avatar'>
				<div className='uploaded-image' onClick={openEditPopup} key={this.props.record[this.props.property]}>
					<img src={this.imgSrc} alt={''} loading='lazy' height={imgHeight} width={imgWidth} />
					<span className='edit-span'>{t('edit')}</span>
				</div>

				{showEditPopup && (
					<Popup onClose={closeEditPopup} className='edit-avatar-popup profile-popup'>
						<div>
							<h2>{t(isNew ? 'addAvatar' : 'editAvatar')}</h2>
							<div className='editor'>
								<AvatarEditor ref={setEditorRef} image={imgToUpload || this.imgSrc} {...avatarEditorProps} scale={scale} />
								<h3>{t('zoom')}</h3>
								<input name='scale' type='range' onChange={onScaleChange} min='1' max='4' step='0.01' defaultValue='1' disabled={disableSave} />
							</div>
							<div className='buttons'>
								<Controls>
									<Controls.SaveButton onSave={onSave} disableSave={disableSave} />
									<Controls.CancelButton onCancel={closeEditPopup} />
									<input id='attachAvatar' onChange={onAttach} type='file' accept='image/*' />
									<label htmlFor='attachAvatar'>
										<UploadButton />
									</label>

									{!isNew && <Controls.DeleteButton onDelete={onDelete} />}
								</Controls>
							</div>
						</div>
					</Popup>
				)}
			</div>
		);
	}
}
