import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Map } from '@smartplatform/map/client';
import { Button } from '@smartplatform/ui';
import GeoEditStore from './editStore';
import store from 'client/store';
import t from '@smartplatform/map/i18n';
import './style.scss';

const MAP_PARAMS = {
	zoom: 4,
	maxZoom: 18,
};

@observer
export default class GeoEdit extends React.Component {

	static propTypes = {
		value: PropTypes.object,
		onChange: PropTypes.func,
		previewJSON: PropTypes.bool,
		disabled: PropTypes.bool,
		params: PropTypes.object,
	};
	
	static defaultProps = {
		previewJSON: false,
		disabled: false,
		params: {},
	};

	constructor(props) {
		super(props);
		this.editStore = new GeoEditStore(props);
	}
	
	render() {
		const { previewJSON, disabled, params } = this.props;
		
		const _params = {
			...(store.config.map || MAP_PARAMS),
			...params,
			fitPadding: 50,
		};
		
		// console.log(JSON.stringify(_params))
		
		const btnProps = { variant: 'primary', size: 'sm' };
		
		const drawControls = this.editStore.mapInitialized && (
			!this.editStore.mapStore.drawing ?
				<Button onClick={this.editStore.startDrawing} disabled={this.editStore.mapStore.drawing} {...btnProps}>
					{t(this.editStore.geo ? 'map.startEditing' : 'map.startDrawing')}
				</Button>
				:
				<>
					<Button onClick={this.editStore.finishDrawing} disabled={!this.editStore.changed} {...btnProps}>{t('map.finishDrawing')}</Button>
					<Button onClick={this.editStore.cancelDrawing} {...btnProps}>{t('map.cancelDrawing')}</Button>
				</>
		);
		
		return <div className={'geo-edit' + (this.editStore.mapMaximized ? ' maximized' : '')}>
			{!disabled && <div className="draw-controls">{drawControls}</div>}
			<div className="edit-area">
				<Map onInit={this.editStore.onMapInit} params={_params} className="geo-edit-map" noPopups />
				{previewJSON && <div className='json-preview'><pre>{JSON.stringify(this.editStore.geo, null, 2)}</pre></div>}
			</div>
		</div>;
	}
	
}
