import Layer from 'ol/layer/Layer.js';

import CustomMVTWebglRenderer from './CustomMVTWebglRenderer';

export default class CustomMVTWebglLayer extends Layer {

	constructor(options) {
		const baseOptions = Object.assign({}, options);
		super(baseOptions);
		this.renderer = this.getRenderer();
	}

	setTexture = (image, size, count) => {
		this.renderer.setTexture(image, size, count);
		this.changed();
	};

	reset = () => {
		this.renderer.reset();
	};

	update = () => {
		this.renderer.reset();
		this.getSource().refresh();
	};

	setVisible = (isVisible) => {
		this.values_.visible = isVisible;
		if (isVisible) {
			this.renderer.initialized = false;
			this.renderer.init();
			if (this.renderer.textureImage) this.renderer.setTexture(this.renderer.textureImage);
		}
		else {
			this.renderer.remove();
		}
	};

	getPoints = () => {
		return this.renderer.getPoints();
	};

	checkZoom = () => {
		this.renderer.checkZoom();
	};

	cleanUp = () => {
		// TODO: очистить webgl-контекст
	};

	createRenderer() {
		return new CustomMVTWebglRenderer(this);
	}

}
