import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './style.scss';

import Edit from './Edit';
import List from './List';

const WayBills = () => <div className="techfire-waybills">
	<Switch>
		<Route path="/techfires/fires/:id/waybills" render={routeProps => <List {...routeProps} />} />
		{/*<Route path="/techfires/fires/:id/waybills/:wayBillId" render={routeProps => <Edit {...routeProps} />} />*/}
	</Switch>
</div>;

export default WayBills;
