import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form, NumberInput, Popup, Select } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { Actionbar } from 'components';
import { COST, INCOME } from 'client/constants';

@inject('store')
@observer
export class MovementEditPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	originalValues;
	recIndex;
	type;

	constructor(props) {
		super(props);
		this.isNew = !this.props.selectedRecord;
		this.type = this.props.type;
		this.store = this.props.store;
		this.init();
	}

	init = async () => {
		const selectedRecord = this.props.selectedRecord;

		if (!this.isNew) {
			this.record = new store.model.ReserveMovement(selectedRecord);
			const loadRelation = async (relation) => {
				delete this.record['_' + relation];
				this.record[relation] = await this.record[relation];
			};
			await loadRelation('storehouse');
			this.recIndex = this.store.movements.findIndex(({ id }) => id === this.record.id);
			this.type = this.record.quantity > 0 ? INCOME : this.record.quantity < 0 ? COST : undefined;
		} else {
			this.record = new store.model.ReserveMovement({ date: new Date(), storehouseId: this.store.record.storehouseId });
			await this.record.storehouse;
		}
		this.isLoading = false;
	};

	beforeSave = async () => {
		if (this.type === COST) {
			this.record.quantity = -this.record.quantity;
		}
		if (this.isNew) {
			await this.record.save();
			this.props.addMovement(this.record);
		} else {
			this.store.movements[this.recIndex] = this.record;
		}
		this.props.onClose();
		return false;
	};

	onDelete = () => {
		this.props.deleteMovement(this.record, this.recIndex);
		this.props.onClose();
		return false;
	};

	onCancel = async () => this.props.onClose();

	render() {
		if (this.isLoading) return null;
		const title = this.isNew ? t('editRecord') : t('addRecord');
		const disableSave = !!['date', 'quantity'].find((prop) => !this.record[prop]);

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton disabled={disableSave} />
				<Actionbar.CancelButton back={this.onCancel} />
				{this.isNew ? <Actionbar.CleanButton /> : <Actionbar.DeleteButton onDelete={this.onDelete} noDelete />}
			</Actionbar>
		);

		return (
			<Popup onClose={this.onCancel} className='delivery-popup'>
				<h2>{title}</h2>
				<Form record={this.record} stay controls={controls} noDelete noSave beforeSave={this.beforeSave}>
					<div className='form-field'>
						<label>{t('reserve.movementType')}</label>
						<Select items={[INCOME, COST].map((i) => ({ label: i, value: i }))} disabled showValue={t('reserve.' + this.type?.toLowerCase())} />
					</div>
					<Field relation='storehouse' property='name' label={t('storehouse.title')} disabled />
					<Field property='date' label={t('date')} />
					<Field label={t('reserve.quantity')} property='quantity'>
						<NumberInput positiveOnly />
					</Field>
				</Form>
			</Popup>
		);
	}
}
