import { observable } from 'mobx';

export default class FireReportStore {
	@observable year = new Date().getFullYear();

	get storeParams() {
		return {
			reportCode: 'LO3',
			reportParams: { year: this.year },
			isHTML: true,
		};
	}

	onYearChange = (y) => (this.year = y);
}
