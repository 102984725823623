import { observable } from 'mobx';
import store from 'client/store';

import { CountiesLayer, MineralsLayer, GenShtabLayer, WeatherLayer, WindLayer, MunicipalityLayer } from 'components';
import { transform } from "ol/proj";
import Feature from 'ol/Feature';
import { geoJSON, getFeatureCenter, toLonLat } from '@smartplatform/map/client';
// import FiresLayer from 'fires/map/fires';
import { heatPointsLayerCustomMVT } from 'components';
import { endOfDay, startOfDay, subDays } from 'date-fns';

class EmergencyMap {

	@observable mapInitialized = false;
	@observable popup = null;
	@observable date = new Date();
	@observable heatPointsStartDate;
	@observable heatPointsEndDate;

	featuresGetters = [];
	
	onMapInit = async mapStore => {
		this.mapStore = mapStore;
		this.map = mapStore.map;
		this.layersSettings = store.local.emergency.layers;

		this.heatPointsEndDate = endOfDay(new Date());
		this.heatPointsStartDate = startOfDay(subDays(this.heatPointsEndDate, 2));
		if (store.local.emergency.startDate) {
			this.heatPointsEndDate = endOfDay(new Date(store.local.emergency.startDate));
			this.heatPointsStartDate = startOfDay(subDays(new Date(store.local.emergency.startDate), 6));
		}

		this.countiesLayer = new CountiesLayer(mapStore, this.layersSettings.counties);
		this.genShtabLayer = new GenShtabLayer(mapStore, this.layersSettings.genShtab);
		this.weatherLayer = new WeatherLayer(mapStore, this.layersSettings.weather);
		this.windLayer = new WindLayer(mapStore, this.layersSettings.wind);
		this.municipalityLayer = new MunicipalityLayer(mapStore, this.layersSettings.municipality);
		// this.firesLayer = new FiresLayer(this);
		this.heatPointsLayer = new heatPointsLayerCustomMVT(mapStore, this.layersSettings.heatPoints);
		this.mineralsLayer = new MineralsLayer(mapStore, this.layersSettings.minerals);

		this.countiesLayer.init();
		this.genShtabLayer.init();
		this.weatherLayer.init();
		this.windLayer.init();
		this.municipalityLayer.init();
		// this.firesLayer.init(mapStore);
		this.heatPointsLayer.init({ start: this.heatPointsStartDate, end: this.heatPointsEndDate });
		this.mineralsLayer.init();

		// this.map.on('click', this.onClick);
		this.map.on('pointermove', this.onPointerMove);
		mapStore.on('cleanup', this.destroy);

		this.mapInitialized = true;
	}
	
	onTileSourceChange = (tileSource) => {
		store.local.emergency.tileSource = tileSource;
		store.local.save();
	};
	
	setHeatPointsRegion = (value) => {
		store.local.emergency.layers.heatPoints.region = value ? null : 1;
		store.local.save();
		this.heatPointsLayer.update();
	};

	addFeatureGetter = (func) => {
		this.featuresGetters.push(func);
	};

	getFeaturesAtPixel = async (e) => {
		const features = await this.map.getFeaturesAtPixel(e.pixel);
		const otherFeatures = [];
		for (let getter of this.featuresGetters) {
			const feature = await getter(e);
			if (feature) otherFeatures.push(feature);
		}
		return [...features, ...otherFeatures];
	};
	
/*
	onClick = async (e) => {
		const features = await this.getFeaturesAtPixel(e);
		const coords = this.map.getCoordinateFromPixel(e.pixel);
		const lonLat = transform(coords, 'EPSG:3857', 'EPSG:4326');

		const [lon, lat] = lonLat

		if (features.length === 0) {
			this.popup = null;
			return;
		}

		let popup = { lonLat: [lon, lat], offset: 0, objects: [] };
		let initial = true;
		const sameModels = {};

		for (let feature of features) {
			const data = feature.getProperties();

			if (data.record) {
				const modelName = data.record.MODEL.INFO.name;
				if (!sameModels[modelName]) {
					popup.objects.push(data);
					sameModels[modelName] = true;
				}

				if (initial) {
					if (data.record.geo.type === 'Point') {
						popup.lonLat = data.record.geo.coordinates;
					}
					else {
						const geometry = geoJSON.readGeometry(data.record.geo);
						const feature = new Feature({ geometry, ...data });
						const center = toLonLat(getFeatureCenter(feature));
						popup.lonLat = center;
					}
					popup.offset = 20;
					if (modelName === 'FireData') {
						popup.width = 300;
						popup.height = 220;
						popup.offset = 3;
					}
				}
				initial = false;
			}
			else if (feature.constructor.name) {
				if (data.layer === 'county' && features.length === 1) {
					this.popup = null;
					return;
				}
				if (data.layer === 'ViewMunicipality') {
					const record = new store.model.Municipality({ id: data.id });
					console.log('>>>', feature.constructor.name, record);
					popup.objects.push({ record });
				}
			}
		}

		this.popup = popup;
	};
*/

	destroy = () => {
		this.mapStore.off('cleanup', this.destroy);
		this.mapInitialized = false;
	};
	
	onPointerMove = async (e) => {
		this.weatherLayer.updateTip(e);
	};

	switchFilters = (maximize) => {
		store.local.emergency.filtersMaximized = maximize !== undefined ? maximize : !store.local.emergency.filtersMaximized;
		store.local.save();
	};

}

export default new EmergencyMap();
