import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from '@smartplatform/ui';
import t from 'i18n';
import { getYearArray, MONTHES } from 'fires/helpers';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const className = 'form-field';
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className={className}>
							<label>{t('fireReport.year')}</label>
							<Select noTotal maxItems={10} itemsPerPage={1000} noSearch isRequired items={getYearArray()} value={report.year} onChange={report.onYearChange} />
						</div>
					</div>
					<div className='col-3'>
						<div className={className}>
							<label>{t('fireReport.month')}</label>
							<Select noTotal maxItems={10} itemsPerPage={1000} noSearch isRequired items={MONTHES} value={report.month} onChange={report.onMonthChange} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
