import React from 'react';

export default {
	1: props => <path data-name="ГО Якутск" d="M202,235h-1l-4,2l-1,1h-4l-2-1l-1,1v2l1,2l1,3h4l2,1l3-5v-1l2-4V235z" {...props}/>,
	2: props => <path data-name="Абыйский" d="M328,76l-4,2l-2,4l-19,2l-6,3h-4v-4l-4-1l-5,3l3,4l-2,2l-6,1l4,5v2l2,2l1,3l-3,6v2l2,2l1,7l8,2l1,3h2l10,4l3-1l3-7l7-1l1,1h4l1-1l-1-3l1-2l4-1l-1-2l-2-1l5-7l-4-5h-2l-2-8h6l2-2l-4-6l2-2L328,76z" {...props}/>,
	3: props => <path data-name="Алданский" d="M139,285l-1,5l-7,6l3,4v11h-2l-1,3h11l3-2v-3h6l-3,11l-3,3v3h6l3,2l2-2v-3h6l2,5l9,3l2,8l10,2l3-2l13-1l8,5h3l5-9l3-2l1-6l2-2l-3-5l5-3v-4l3-8l-3-1l-1-5l-6-4v-5l1-5l1-1l-9,1l-3,3v3h-4l-3-3h-5l-4-3l-2-17l2-2l-7-1l-5,5l-4,1l-3,3h-3l-6,4h-5l-6,6l-6,1l-1,3L139,285z" {...props}/>,
	4: props => <path data-name="Аллаиховский" d="M335,40l-4-5l-12-4l-5-6l-6-2l-8,1l-3,4v3l1,2l-6,3l-3-2l2-3l-2-3l-4,2l-7,6l-1,7l-2,1l3,11l-6,12l-1,11l3-1l3,3l3-1l1,4l2,1l6-3l5,1v4h3l6-3l18-2l2-4l10-6l7-7h7l-3-9l-5-7l1-6L335,40z" {...props}/>,
	5: props => <path data-name="Амгинский" d="M193,265l-2,2l2,16l4,3h4l3,3h3v-2l4-4l10-1l2-3l1-10h3l5-4l-2-6v-4l-4-3l-4,1l-5-1v-4l-1-1l-3,7l-4,2l-1,9l-5,1h-7L193,265z" {...props}/>,
	6: props => <path data-name="Анабарский" d="M128,20l1-8l-8-1l-9-4h-10l-1-7l-2,1v2l-2,1l-9-4l-5,4l-1,22.1l3,5.9h8l2,4l-3,12l3,1l-5,8l3,2h3l5-11l10-5l1-5l7-4l6,5l11-2l-2-7l1-6l-2-3L128,20z" {...props}/>,
	7: props => <path data-name="Булунский" d="M180,18l-9,1l-8-8l-6,5v11l-2,1l-1-2l-18-1l-1,5l2,8l4,4v5l6,10l-7,10l-1,6l-4,1l-5,9l-1,11l-2,3h4l4-3l7,8l11,6l7-3l6,4l5,1l13-1l3-3l3,2l6-4l8,5l6-5l6-2l3-7l4-3v-2l-4-2v-8l-7-2l-1-11l-3-1l-5,4l-8-6l-5-11l5-13l-5-3v-7l-3-8L180,18z" {...props}/>,
	8: props => <path data-name="Верхневилюйский" d="M115,155l-3-1l-3-4l-3,1v5l2,1v4l-2,2l1,10l2,4l1,9l-2,1l1,9l3,7v5l-2,4l-1,7l2,2l1,8l2,1l1,5l-2,4l1,1h3l8,7h7l5-2l-1-3l-2-1l-3-6l2-4l-4-5v-7l-1-7l-4-2v-3l3-3l1-5l-1-2l-4-1l-2,1l-4-3v-3l-2-3l1-6l-1-4l-4-5l1-5l5-7L115,155z" {...props}/>,
	9: props => <path data-name="Верхнеколымский" d="M384,128l-5-4l-4,1l-7-1l-6-6l-6-2l-10-7l-8-2l-2-2h-3l-5,7l2,1l1,2l3,3l5,1l2,4v6l-4,7v8l5,2l11,12l-3,5l3,8l10-6l7,3h2v-2l10-7h2v-5l-2-3l-2-6l1-3l4-1v-4L384,128z" {...props}/>,
	10: props => <path data-name="Верхоянский" d="M251,98l-3,1l-5-1l-1-12h-4l-9,2l-1-1l-4,4v1l-4,3l-3,7l2,8l5,4l-4,5l-2,9l-8,4l-3,9l-4,2l-4,9l4,5v3l9,9l1,7l2,1l7,14h2l1-2l-2-4l1-7l7-2l1-2l4-1l1-5l5-2l5-7l7-4l3,2l18-1l1,1h4v-2l-4-1v-6h3v-3l-10-4l-1-2h-3l-2-3v-4v-3h-2v-4l2-1v-5l-3-2l1-4l-1-2h-2l-3-3v-3L251,98z" {...props}/>,
	11: props => <path data-name="Вилюйский" d="M114,142h-1v5l-3,2v1l2,3l4,1l2,7l-5,7l-1,5l4,5l1,4l-1,6l2,3v3l3,2l2-1l4,1l2,3l-1,5l-3,3v2l4,3l1,7v7l3,4h4l9-2l5,1l8-4l2,2h2v-4l1-2l-5-1l-4-2h-4l-4-2l-2-8l3-8h3l9-7l-5-8l-8,4l-10-2l-1-3h-2l-8-10l-4-14l1-5L114,142z" {...props}/>,
	12: props => <path data-name="Горный" d="M134,231l-2,4l3,6l2,1l1,3l4,1l2,7l6,4h8l5-6h8l3-4h4l1-5l6-2l3,1v-3l1-1h-5l-3-3v-2l1-1h2l1-3l-1-1h-2l-1-4l1-1v-1l-1-1v-2l-2-1h-2l-2,3h-8l-1,1h-1l-1,2v5h-3l-2-2l-8,4l-5-1l-9,2H134z" {...props}/>,
	13: props => <path data-name="Жиганский" d="M171,111l-5-1l-6-4l-7,3l-11-6l-7-8l-4,3h-5l-3,3l6,9l-2,4l-5,1l-3,6h-6l-5,5l-1,5l-5,3l1,2l5,1l4,4h2l10,14l-1,5l4,14l7,9h3l1,3l9,2l8-4l2-2v-5l11-6l5,1l4-6l3-1l-1-6l2-4l-5-5l-1-4l3-9l-4-6v-4l2-3l-3-4v-4h3l-1-5H171z" {...props}/>,
	14: props => <path data-name="Кобяйский" d="M202,160v-3l-4-4l-2,2l-7-2l-4,3h-3l-2,3l1,7l-3,1l-4,6l-6-1l-10,6v4l-2,2l5,9l-10,8h-2l-3,7l2,8l3,1h4l4,2l7,1l1-1h8l2-3h2l2,1l1,1v2l4,2l4-6l-1-1v-3h3l1,1h5l6-6l1,1h3l5,1l8-5v-4l2-8h-1l-8-15l-2-1l-1-7L202,160z" {...props}/>,
	15: props => <path data-name="Ленский" d="M50,236l-9,8l-7,2l-10-4l-5,1l-4,3h-1l-4,5l-2,5v2l-4,6H3l-3,3v5l2,1v2l3,2h5l1-3l2-1l2,2l8,1l4-3l3,1l1,5l-2,2l2,2l5-1l6-6v-2l6-2l12-9l1-2h7l3,5l5-5l3-6v-9l-5-5l-10-1l-2-3h-1l-4,4l-3-1v-2L50,236z" {...props}/>,
	16: props => <path data-name="Мегино-Кангаласский" d="M204,235h-1v1l-2,4v1l-3,5v5l1,1l5,1v8l-1,4l4-1l1-8l4-3l3-7l-1-7l-1-3l-6-3h-1L204,235z" {...props}/>,
	17: props => <path data-name="Мирнинский" d="M57,136l-8-1l-12,4v-7l-2-4l-8,1l-2-2l-7,1l-2-2l-1,3l-2,20l-7,3l-1,3l-5,4v2l2,3l10,8.1l8,3l1,5l-2,3l-4-1v2l-2,2l5,1l5,6v5l-5,11l3,4l-6,12v5l4,11.9l5-0.9l10,3.9l7-2l9-7.9l5,2v2l2,1l4-4l1-7l-2-3v-9l-2-11l6,1l-1-5l-2-1L60,190l8-2l-1-12.9l-7-6V166l-4-4l1-6l6-4l3,1l1-2l-1-5l1-3L57,136z" {...props}/>,
	18: props => <path data-name="Момский" d="M327,121l-2,2h-5l-1-1l-5,1l-3,6l-4,2l-10-4h-3l-1-3l-8-2l-1-5h-4l-4,3h-5l-2,2v2l-4,5h-1v7l1,2h4l1.1,2.2l9.9,4.8v4h-3v4l4,1v4l1,5h15l15,4h4l6,6l11,2l4,4h9l7-3l-4-13l3-5l-10-11l-6-3v-8l4-7v-6l-2-3l-4-1l-3-3l-4,1l-1,1L327,121z" {...props}/>,
	19: props => <path data-name="Намский" d="M203,229v-2h-3v-8l-1-5h-6l-1-1h-2v2l1,1l-4,7l-4-1l-1,1l1,3h1l2,2l-1,4h-3v2l2,2h7l2,1h3l1-1l4-2l1-4L203,229z" {...props}/>,
	20: props => <path data-name="Нерюнгринский" d="M146,312l-4,3h-7l-4,6l-3,8l1,4l-6,1l-12-2l-2-3l-5-2l-9,2l-1,5l1,1h11l1,2l5,1l5,5l10,3l4,4l9,3l5,7h7l9,7l19-3l9,6l5-1l2-4l17-1l1-8l3-7h2l4-4l-3-5l-4-2l-4,6h-3l-8-5l-13,1l-3,2l-11-2l-2-8l-9-3l-2-5h-4v2l-3,3l-3-2h-7v-4l3-3l3-10h-4V312z" {...props}/>,
	21: props => <path data-name="Нижнеколымский" d="M414,39l-4-2l-5,6l-4-2l-9-1l-1-8l-7-7l-6-1l-9,1l-22,12l-6,5l-1,6l5,7l3,10h-1l1,3l5,1l2-3l3,2l11,1l4-2l3,3l11-1l4-3h2l6,7h2l16-14l7-3l-1-2v-4L414,39z" {...props}/>,
	22: props => <path data-name="Нюрбинский" d="M79.5,175H76l-8,4l1,9l2,1l4,8l2,9l4,6l11,1l6,6l12,3v-1l-2-2l1-7l2-4v-5l-3-7l-1-9l2-1l-1-9l-2-4l-1-10l-6,2l-5,8l-6,2l-1-2L79.5,175z" {...props}/>,
	23: props => <path data-name="Оймяконский" d="M337,184v-4l-4-4l-12-2l-5-6h-4l-15-4h-15v2l-2,5l-2,2l1,5l4,4h4l-1,7l6,3v12l-4,2v0.1l-1,8.9l1,5l7,4h6l5,6l10,1l5-5l5,1l1,2l6-2v-3l3-8l-3-20l1-3l-1-5L337,184z" {...props}/>,
	24: props => <path data-name="Олёкминский" d="M141,247l-3-1l-6,2h-7l-8-7h-3l-7-3l-1-2l-2-1l-1,3l1,3l-9,8h-2l-2,5l-11,1l-3,6l-5,5l3,1l3,10l-2,1l1,4l8,1l6,5l2,18l-3,12v5l5,5l9-2l5,2l2,3l12,2l5-1l-1-3l3-8l4-6h-4l1-5h2v-9l-3-5l7-6l1-6l7,2l1-3l7-1l6-6h5l6-4h3l2-3l5-1l4-5l-4-2l-2,2h-6l-9,4l-1,3h-3l-7-6l1-2l-1-4h-3l-6-5L141,247z" {...props}/>,
	25: props => <path data-name="Оленёкский" d="M140,42l-4-4l-11,2l-6-5l-6,4l-1,5l-10,5l-5,11h-5l-3-3l4-7l-2-1l3-13l-2-3h-7v5l-2,5l2,5l-6,8l-9-1L57,71H36l-2,4l2,11l-3,3l-1,8l-3,3l-3,11l-6,4h-3l-1,10l2,2l7-1l2,2l9-1l2,4v7l11-4l8,1l11,8l-1,3l1,5l-1,3l-4-1l-5,3l-1,6l4,4v3.1l7,6v3l8-4h3l8-2l1,2l6-2l5-8.1l8-3v-4l-2-1v-5l7-4v-5l-5-4l-5-1l-1-3l5-3l1-6l6-5h5l3-6l5-1l2-3l-6-9l6-7l1-11l6-10l3-1l1-5l7-10l-6-10V42z" {...props}/>,
	26: props => <path data-name="Среднеколымский" d="M353,70l-6-1l-1-4h-6l-6,7l-5,3l2,7l-2,2l4,6l-3,3h-5l2,6h1l5,5h3l2,2l8,2l10,7l6,2l6,6l7,1l4-1l5,4h4l1-1l-1-3l1-5l21-3l1-2l5-4l-3-8l-1-9l-10-2l-2-14l1-2h-2l-6-7h-2l-4,3l-11,1l-3-3l-4,2l-11-1l-3-2L353,70z" {...props}/>,
	27: props => <path data-name="Сунтарский" d="M68,189l-7,2l1,10l2,1l1,6l-6-1l2,10v9l2,3l-1,7l2,3l10,1l6,6v8l10-1l2-5h3l8-7l-1-3l1-4l4,1l1,2l4,2l2-4l-1-5l-2-1l-1-6l-12-3l-6-6l-11-1l-5-7l-2-9l-3-7L68,189z" {...props}/>,
	28: props => <path data-name="Таттинский" d="M250,247l-1-2l2-6l-4-6l-5-3l-6-1v-12l-2-3h-3l-1,1h-5l-1,6v10h2v4l4,6h8l4,1l1,9l6-1L250,247z" {...props}/>,
	29: props => <path data-name="Томпонский" d="M232,175l-1,2l-6,2l-1,6l2,4l-2,3l-2,8v4l-3,3l2,2h1l3,5h5l1-1h3l3,4v11l5,1l6,4l4,6l-2,6l2,1l4-4l5,2l8-2l1-11l11-6l6-1v-3l-1-6l1-9l4-3v-10l-6-4l1-6h-3l-5-4l-1-6l2-2l2-5v-3l-1-5h-4l-1-1l-18,1l-3-2l-6,3l-5,7l-5,3l-1,5L232,175z" {...props}/>,
	30: props => <path data-name="Усть-Алданский" d="M223,221l1-6l-2-5h-1l-3-3l-5,3l-5-1h-3l-1-1l-4,4l1,7v7h3v3l-1,1l-1,4h2l2-2h1l6,3l5-3h4l1-2V221z" {...props}/>,
	31: props => <path data-name="Усть-Майский" d="M288,221v4l-7,1l-10,5l-1,12l-9,2l-5-2l-4,4h-1l-1,4l-7,1v2h-8l-4,1v4l2,6l-6,5h-2l-1,9l-4,5l-1,5v5l6,3l1,5l2,1l5-7l6-1l10,1l6-2l3-4v-2l3-1l8,2l12-10l1-11l-4-13l11-10l4-10v-6l1-5L288,221z" {...props}/>,
	32: props => <path data-name="Усть-Янский" d="M289,23l-1,1l-14-1l-12,2l-1,5l-7,1l-1,5l4,1l-1,5l2,8l-9,1l-1,3l1,3h-6v-3l-11-2l-1,2l-10,3l-5-5l-4-2l-3,4l1,1l-2,10l4,1l1,11l7,2v9l4,2l4-4l1,1l9-2h5l1,12l4,1l3-1l5,8v3l2,2h3l1,3l-1,4l3,2v5l-2,1v3h3l3-4v-2l3-3h5l4-3h4v-2l-2-2v-2l3-6l-1-3l-2-2v-2l-4-5v-1l7-1l1-1l-3-4l-3-1l-1-4l-2,1l-3-3l-4,1l1-13l6-11l-3-11l2-1l1-7l8-7l4-2l4-3v-2L289,23z" {...props}/>,
	33: props => <path data-name="Хангаласский" d="M158,258h-5l1,5l-1,1l6,5h2l1-3l10-4h6l2-2l5,3l11,2h6l1-4v-7l-4-1l-2-2v-4l-2-1h-4l-2-4l-4-1l-5,2l-1,5h-5l-3,4h-8L158,258z" {...props}/>,
	34: props => <path data-name="Чурапчинский" d="M222,233h-4l-4,3l1,3l1,7l2,1v4l4,1l4-1l4,3l5-1h7l-1-10l-3-1h-8l-5-7v-3h-2L222,233z" {...props}/>,
	35: props => <path data-name="Эвено-Бытантайский" d="M216,103l-6,2l-6,5l-8-5l-6,4l-3-2l-3,3l-8,1l1,6h-3v3l3,4l-2,3v4l4,5l-3,10l1,4l5,5h3l4-3l7,2l2-2l4-9l4-2l3-10l8-4l2-8l4-5l-5-4L216,103z" {...props}/>,
};
