import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';
import { ModuleWrapper } from 'components';
import CovidMap from './components/map';
import Dictonaries from './components/dictionaries';
import MapLayout from './MapLayout';
import Cases from './Cases';
import store from 'client/store';
import module from './index';
import './style.scss';

/**
 * Корневой компонент модуля "Covid-19"
 */

@observer
export default class Root extends React.Component {
	constructor(props) {
		super(props);
		if (!store.covid.isInitialized) store.covid.init();
	}

	render() {
		if (!store.covid.isInitialized) return null;
		const { path } = this.props.match;

		return (
			<ModuleWrapper className='covid-module' module={module} path={path}>
				<CovidMap />
				<Switch>
					<Route path={`${path}/covid'`} exact component={MapLayout} />
					<Route path={`${path}/cases`} component={Cases} />
					<Route path={`${path}/dictionaries`} component={Dictonaries} />
				</Switch>
			</ModuleWrapper>
		);
	}
}
