import React from 'react';
import { observable } from 'mobx';
import { startOfDay, endOfDay, subDays, differenceInDays } from 'date-fns';
import Pbf from 'pbf';
import { fromLonLat } from 'ol/proj';

import CustomWebglPointsLayer from './custom-webgl-points';
import { Points } from 'proto/heatpoints.proto';
import store from 'client/store';

const ICON_SIZE = 128;

export default class HeatPoints {

	@observable isLoading = false;
	@observable source = null;
	@observable count = null;
	@observable error = null;

	initialized = false;

	constructor(store) {
		this.store = store;
	}

	init = async (mapStore, params = {}) => {
		this.mapStore = mapStore;
		this.layer = new CustomWebglPointsLayer();
		this.layer.setZIndex(5);
		mapStore.addLayer(this.layer);

		this.dataSource = await store.model.FireDataSource.findById(3);
		this.setVisible();
		this.initialized = true;

		const texture = this.createTexture(['#d00', '#f80', '#888']);
		this.layer.setTexture(texture, ICON_SIZE, 3);

		const { idArray, useTechnoZones = true, currentZoneId } = params;
		this.useTechnoZones = useTechnoZones; // фильтровать точки внутри технозон
		this.idArray = idArray; // показывать точки из массива id (на время редактирования технозоны)
		this.currentZoneId = currentZoneId; // прятать точки из других технозон в редактировании технозоны

		this.load();
	};

	// для совместимости с mvt-версией
	update = async () => {
		await this.load();
	};

	remove = () => {
		if (this.mapStore && this.layer) {
			this.layer.cleanUp();
			this.mapStore.removeLayer(this.layer);
			this.layer = null;
		}
	};

	load = async () => {
		const settings = this.store.layersSettings.heatPoints;
		if (!settings.show) return;
		this.count = null;
		this.isLoading = true;

		if (!this.dataSource) {
			this.dataSource = await store.model.FireDataSource.findById(3);
		}
		this.layer.setPointSizeFunc(this.calcPointSize);

		const start = this.store.heatPointsStartDate;
		const end = this.store.heatPointsEndDate;

		const data = {
			start: start.toISOString(),
			end: end.toISOString(),
			useTechnoZones: this.useTechnoZones,
			idArray: this.idArray,
			currentZoneId: this.currentZoneId,
			regionId: store.local.fires.hpRegion || undefined,
		};

		try {
			const res = await fetch('/api/heatpoints', {
				method: 'POST',
				mode: 'cors',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data),
			});
			// console.log('res', res);

			if (!res.ok) {
				const error = await res.text();
				throw new Error(error);
			}

			const buffer = await res.arrayBuffer();
			// console.log('buffer', buffer);
			const pbf = new Pbf(buffer);
			const { points } = Points.read(pbf);

			this.count = points.length / 3;
			console.log('count', this.count);

			console.time('setPoints');
			this.layer.setPoints(points, this.bufferSetup);
			console.timeEnd('setPoints');
		}
		catch(e) {
			console.error(e);
			this.error = e.message;
		}
		this.isLoading = false;
	};

	bufferSetup = (gl, program) => {
		const elementSize = 3 * Float32Array.BYTES_PER_ELEMENT;

		const aPosition = gl.getAttribLocation(program, 'a_position');
		gl.vertexAttribPointer(
			aPosition,
			2,                                      // number of elements per attribute
			gl.FLOAT,                               // type of elements
			false,                                  // normalize
			elementSize,                            // size of an element in bytes
			0                                       // offset to this attribute
		);
		gl.enableVertexAttribArray(aPosition);

		const aDif = gl.getAttribLocation(program, 'a_dif');
		gl.vertexAttribPointer(
			aDif,
			1,                                      // number of elements per attribute
			gl.FLOAT,                               // type of elements
			false,                                  // normalize
			elementSize,                            // size of an element in bytes
			2 * Float32Array.BYTES_PER_ELEMENT      // offset to this attribute
		);
		gl.enableVertexAttribArray(aDif);
	};

	calcPointSize = (viewState) => {
		// return 1;
		const { zoom, resolution } = viewState;
		const meters = this.dataSource.id === 1 ? 1000 : 375;
		// console.log('viewState', viewState);
		const pixels = meters * 2 / resolution;
		return Math.max(pixels, 7);
		// let size = zoom * 1.5;
		// const meters = this.dataSource.id === 1 ? 1000 : 375;
		// return Math.max(size, meters / resolution);
	};

	setVisible = () => {
		this.layer.setVisible(this.store.layersSettings.heatPoints.show);
	};

	createTexture = (colors) => {
		const size = ICON_SIZE;

		const canvas = document.createElement('canvas', {
			antialias: false,
			premultipliedAlpha: false,
		});
		canvas.width = size * colors.length;
		canvas.height = size;
		const ctx = canvas.getContext('2d');
		ctx.imageSmoothingEnabled = false;

		for (let i = 0; i < colors.length; i++) {
			const color = colors[i];

			ctx.globalAlpha = 1;
			ctx.fillStyle = color;
			ctx.beginPath();
			ctx.arc(size / 2 + i * size, size / 2, size / 2 - 1, 0, 2 * Math.PI);
			ctx.fill();

			// ctx.globalAlpha = 0.1;
			// ctx.fillStyle = '#fff';
			// ctx.beginPath();
			// ctx.arc(size / 2 + i * size, size / 2, size / 2 - size / 4, 0, 2 * Math.PI);
			// ctx.fill();
		}

		// canvas.style.width = canvas.width + 'px';
		// canvas.style.height = canvas.height + 'px';
		// canvas.style.position = 'absolute';
		// canvas.style.left = '100px';
		// canvas.style.top = '100px';
		// canvas.style.zIndex = 5000;
		// document.body.appendChild(canvas);

		return canvas;
	};

	switchSource = (source) => {
		this.dataSource = source;
		this.store.popup = null;
		this.load();
	};
};
