import React from 'react';
import { Column, Pager, Table } from '@smartplatform/ui';
import { Toolbar } from 'components';
import store from 'client/store';
import t from 'i18n';
import { observer } from 'mobx-react';
import { renderStatus } from 'client/tools';
import { MODEL_NAME } from './constants';
import { AVATAR_SIZE } from 'client/constants';
import { MembersAvatars } from 'delivery/components';
import PlansStore from './store';
import { getPerPage } from 'client/tools';
@observer
export class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new PlansStore();
		this.store.path = props.path;
	}
	componentDidMount() {
		this.store.perPage = getPerPage();
	}

	renderMembers = (requestMembers) => <MembersAvatars members={requestMembers.map(({ user }) => user)} size={AVATAR_SIZE} avatarsCount={2} full />;

	render() {
		const { onChange, perPage, totalCount, page, onRowClick, onQueryUpdate, query, create } = this.store;

		const model = perPage ? store.model[MODEL_NAME] : undefined;
		let tableProps = model ? { model } : { rows: [] };

		return (
			<div className='plan-list'>
				<h2>{t('emergencySituationPlan.plural')}</h2>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<Toolbar.SearchIconInput value={this.search} onChange={this.onSearch} />
					<Toolbar.FilterButton />
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table query={query} onQueryUpdate={onQueryUpdate} {...tableProps} key={model} onRowClick={onRowClick}>
						<Column property='id' label={t('number')} />
						<Column relation='status' label={t('status')} computed={renderStatus} />
						<Column property='date' label={t('date')} />
						<Column relation='level' property='name' label={t('level')} />
						<Column relation='county' property='name' label={t('county.title')} />
						<Column relation='members' computed={this.renderMembers} label={t('request.members')} width={280} />
					</Table>
				</div>
			</div>
		);
	}
}
