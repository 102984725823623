import React from 'react';
import { observer, inject } from 'mobx-react';

import { Form, Row, Field, DatePicker, Input, Select } from '@smartplatform/ui';
import { TechFireAddressSearch } from 'components';
import t from 'i18n';

@inject('fireStore')
@observer
export default class MainInfo extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { fire, onChange } = this.props.fireStore;

		return <Form record={fire} noSave noDelete stay>
			<h3>{t('techFire.date')}</h3>
			<Row>
				<Field property='detectTime' label={t('techFire.detectTime')} disabled>
					<DatePicker showTimeSelect showClearButton={false} />
				</Field>
				<Field property='reportTime' label={t('techFire.messageFireDep')} disabled>
					<DatePicker showTimeSelect showClearButton={false} />
				</Field>
				<div />
			</Row>
			<h3>{t('techFire.fireAddress')}</h3>
			<Field relation='object' label={t('techFireAddress.title')}>
				<TechFireAddressSearch />
			</Field>
			<Row>
				<div className='form-field'>
					<label>{t('techFireAddress.title')}</label>
					<Input value={fire.object?.name} disabled />
				</div>
				<div className='form-field'>
					<label>{t('techFireAddress.rank')}</label>
					<Input value={fire.rank?.name} disabled />
				</div>
				<div className='form-field'>
					<label>{t('techFireAddress.fireDep')}</label>
					<Input value={fire.object?.fireDep?.name} disabled />
				</div>
			</Row>
			<Row>
				<div className='form-field'>
					<label>{t('techFireAddress.ptp')}</label>
					<Input value={fire.object?.ptp} disabled />
				</div>
				<div className='form-field'>
					<label>{t('techFireAddress.ktp')}</label>
					<Input value={fire.object?.ktp} disabled />
				</div>
				<div />
			</Row>
			<Row>
				<div className='form-field'>
					<label>{t('techFireAddress.description')}</label>
					<textarea value={fire.object?.description || ''} rows={3} disabled />
				</div>
			</Row>
			<h3>{t('techFire.distance')}</h3>
			<Row>
				<Field property='fireDepDistance' label={t('fireDep.distance')} />
				<Field property='distanceProperty' label={t('fireDep.distanceProperty')}>
					<Select value={fire.distanceProperty} items={[t('km'), t('metre')]} onChange={onChange('distanceProperty')} />
				</Field>
				<div />
			</Row>
			<h3>{t('techFire.firstUnit')}</h3>
			<Row>
				<Field property='arrivalTime' label={t('techFire.time')}>
					<DatePicker showTimeSelect />
				</Field>
				<Field property='firstSupplyTime' label={t('techFire.firstSupplyTime')}>
					<DatePicker showTimeSelect />
				</Field>
				<Field label={'Нарушение тех. регламента'} disabled />
			</Row>
			<Row>
				<Field property='localizationTime' label={t('techFire.localizationTime')}>
					<DatePicker showTimeSelect />
				</Field>
				<Field property='terminationTime' label={t('techFire.terminationTime')}>
					<DatePicker showTimeSelect />
				</Field>
				<Field property='terminationFireTime' label={t('techFire.terminationFireTime')}>
					<DatePicker showTimeSelect />
				</Field>
			</Row>
			<Row>
				<Field property='situation' label={t('techFire.situationToFirstDivison')}>
					<textarea rows={3} />
				</Field>
			</Row>
		</Form>;
	}
}
