import React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { startOfDay, endOfDay, isBefore } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Row, DatePicker, MaskedInput, Checkbox, Select, Popconfirm, Popover, Tab, Tabs } from '@smartplatform/ui';
import { AddressSearch } from '../index';
import CaseLog from './CaseLog';
import { getGeo, makeAddressFromFias } from '../address-search';
import store from 'client/store';
import t from "i18n";
import { MessageList } from "./MessageList";

const getId = (function() {
	let id = 1;
	return () => id ++;
})();

@inject('listStore') @observer
export default class Edit extends React.Component {

	@observable record = null;
	@observable error = null;
	
	@observable phone = '';
	@observable prevPhone = '';
	@observable address = null;
	@observable agreed = false;
	@observable abroad = false;
	@observable isPublic = false;
	@observable mobileOperator = null;
	@observable mobileStatus = null;
	@observable organization = null;
	
	@observable changed = false;
	@observable isSaving = false;
	@observable logs = [];
	@observable tracks = [];
	@observable mobileOperators = [];
	@observable mobileStatuses = [];
	@observable organizations = [];
	
	deletedLogs = [];
	
	constructor(props) {
		super(props);
		this.listStore = props.listStore;
		this.listStore.onTracksFilterChange = this.loadTracks;
		this.mapStore = this.listStore.mapStore;
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.id !== this.props.match.params.id) {
			this.load();
			this.listStore.resetTracksFilter();
		}
	}
	
	init = async () => {
		this.mobileOperators = await store.model.MobileOperator.find({ order: 'id asc' });
		this.mobileStatuses = await store.model.MobileStatus.find({ order: 'id asc' });
		this.organizations = await store.model.Organization.find({ order: 'id asc' });
		if (store.model.user.organizationId) {
			this.organization = this.organizations.find(org => org.id === store.model.user.organizationId);
		}
		this.load();
	};
	
	@action load = async () => {
		this.error = null;
		if (this.listStore.trackLayer) this.listStore.trackLayer.remove();
		this.listStore.tracks = [];
		this.id = this.props.match.params.id;
		console.log('id', this.id);
		if (this.id) {
			try {
				this.record = await store.model.Case.findById(this.id, {
					include: [
						{
							relation: 'logs',
							scope: {
								include: ['status', 'isolation', 'address'],
								order: 'date asc',
							}
						},
						{
							relation: 'address',
							scope: {
								include: ['type']
							}
						},
						{ relation: 'mobileOperator' },
						{ relation: 'mobileStatus' },
						{ relation: 'organization' },
					]
				});
				this.listStore.case = this.record;
				this.agreed = this.record.agreed;
				this.abroad = this.record.abroad;
				this.isPublic = this.record.isPublic;
				this.prevPhone = this.phone = this.record.phone || '';
				this.mobileOperator = this.record.mobileOperator;
				this.mobileStatus = this.record.mobileStatus;
				this.organization = this.record.organization;

				this.logs = this.record.logs();

				this.address = this.logs.length > 0 && this.logs[this.logs.length - 1].address ?
					this.logs[this.logs.length - 1].address
					:
					this.record.address;
				this.listStore.address = this.address;
				console.log('this.listStore.address', this.listStore.address ? this.listStore.address.name : null);

				if (this.address && !this.address.geo) {
					this.address.geo = await getGeo(this.address.name);
				}
				
				await this.loadTracks();
				
				console.log('?', this.tracks.length, this.address ? this.address.geo : null);

				// TODO: вывод маркера адреса в openlayers
				if (this.address && this.address.geo) {
					this.listStore.addAddressMarker(this.address);

					// fitGeo можно использовать для любой геометрии
					this.mapStore.fitGeo(this.address.geo);
					// gotoPoint только для геометрии типа Point
					// this.mapStore.gotoPoint(this.address.geo.coordinates);
				}
				else {
					this.listStore.removeAddressMarker();
					this.mapStore.gotoCenter();
				}
			}
			catch(e) {
				console.error(e);
				this.error = e.message;
				this.listStore.case = null;
			}
		}
		else {
			this.record = new store.model.Case();
			this.record.isPublic = !store.model.user.organizationId;
			this.isPublic = this.record.isPublic;
			this.prevPhone = this.phone = '';
			this.logs = [];
			this.address = null;
			this.addLog();
			this.listStore.removeAddressMarker();
			this.listStore.address = null;
			this.listStore.case = null;
		}
	};
	
	loadTracks = async (fitBounds = true) => {
		if (!this.record) return;
		if (!this.listStore.mobileTracks && !this.listStore.medvedTracks) {
			this.tracks = [];
			this.listStore.tracks = [];
		}
		else {
			const date = new Date(this.listStore.tracksDate || undefined);
			const start = startOfDay(date);
			const end = endOfDay(date);
			const where = {
				and: [
					{ geo: { neq: null }},
					{ date: { gte: start }},
					{ date: { lte: end }},
				],
			};
			if (!this.listStore.mobileTracks && this.listStore.medvedTracks) {
				where.and.push({ medved: true });
			}
			else if (this.listStore.mobileTracks && !this.listStore.medvedTracks) {
				// where.and.push({ medved: { neq: true }});
				where.and.push({ or: [
					{ medved: false },
					{ medved: null },
				]});
			}
			this.tracks = await this.record.tracks.find({
				where,
				fields: ['id', 'geo', 'date', 'radius', 'medved'],
				order: 'date asc',
			});
			this.listStore.tracks = this.tracks;
		}

		// console.log('tracks', this.tracks.map(track => format(new Date(track.date), 'dd.MM.yyyy HH:mm')));
		if (this.listStore.trackLayer) this.listStore.trackLayer.remove();

		if (this.tracks.length > 0) {
			const fromPos = this.address && this.address.geo ? this.address.geo.coordinates.slice().reverse() : null;

			// TODO: переделать вывод треков на openlayers

/*
			const line = this.tracks.length > 0 ?
				L.polyline(this.tracks.map(track => track.geo.coordinates.slice().reverse()), { color: '#468' })
					.arrowheads({ size: '5px', frequency: '50m' })
				:
				null;

			const circleOptions = {
				fillColor: '#0af',
				fillOpacity: 0.05,
				color: '#048',
				opacity: 0.2,
				weight: 1,
			};
			
			const points = this.tracks.map((track, i) => {
				const coords = track.geo.coordinates.slice().reverse();
				let content = `Дата: ${format(new Date(track.date), 'dd.MM.yyyy HH:mm')}`;
				let distance = null;
				if (fromPos) {
					distance = Math.round(L.latLng(fromPos).distanceTo( L.latLng(coords)));
					content += `<br />Дистанция: ${distance} м.`;
					if (track.radius) content += ` Погрешность: ${track.radius} м.`;
				}
				const className = classNames({
					red: !distance || distance > 200,
					medved: track.medved,
				});
				const options = {
					// icon: this.listStore.map.icons[!distance || distance <= 100 ? 'circle' : 'circleRed'],
					icon: new L.IconWithText({ text: i + 1, className }),
					// draggable: true,
				};
				const marker = new L.marker(coords, options);
				marker.on('drag', e => {
					console.log('drag', marker.getLatLng());
				});
				marker.on('click', e => {
					if (track.radius) {
						if (this.listStore.radius) this.listStore.radius.remove();
						this.listStore.radius = L.circle(marker.getLatLng(), { radius: track.radius, ...circleOptions });
						this.listStore.radius.addTo(this.listStore.map.map);
					}
				});
				return marker.bindPopup(content).openPopup();
			});
			
			this.listStore.trackLayer = L.layerGroup([
				line,
				// arrows,
				...points
			]);
			this.listStore.trackLayer.addTo(this.listStore.map.helperLayer);
*/
		}

		if (fitBounds) this.fitBounds();
	};
	
	fitBounds = () => {
		const geoArray = this.tracks.map(track => track.geo);
		if (this.address && this.address.geo) {
			geoArray.push(this.address.geo);
		}
		// const group = L.geoJSON(geoArray);
		console.log('geoArray', geoArray);
		// this.mapStore.fitGeo(group.getBounds(), { paddingTopLeft: [10, 10], paddingBottomRight: [50, 10] });
	};
	
	pickPoint = () => {
		this.mapStore.pickPoint(this.onPickPoint);
	};

	cancelPickPoint = () => {
		this.mapStore.cancelPickPoint();
	};

	onPickPoint = async e => {
		if (this.record && this.record.id && this.listStore.pickingTrack && e.originalEvent.ctrlKey) {
			console.log(e.latlng.lng, e.latlng.lat);
			// const track = new store.model.CaseTrack();
			// track.caseId = this.record.id;
			// track.geo = { type: 'Point', coordinates: [ e.latlng.lng, e.latlng.lat ]};
			// track.date = new Date();
			// await track.save();
			// await this.loadTracks();
		}
	};
	
	addLog = e => {
		if (e) e.preventDefault();
		const caseLog = new store.model.CaseLog();
		caseLog.date = new Date();
		caseLog.__dirty = true;
		caseLog.__new = true;
		caseLog.__outerAddress = false;
		const prev = this.logs.length > 0 ? this.logs[this.logs.length - 1] : null;
		if (prev) {
			caseLog.contacted = prev.contacted;
			caseLog.address = prev.address;
		}
		// console.log('logs1', this.logs);
		this.logs.push(caseLog);
		// console.log('logs2', this.logs);
	};
	
	onPhoneChange = e => {
		const phone = this.phone.replace(/[()\-_\s]/g, '');
		// console.log('onPhoneChange', e.target.value, '>', phone);
		this.phone = e.target.value;
		if (this.prevPhone !== phone) {
			if (this.address) this.changed = true;
			this.prevPhone = phone;
		}
		this.changed = true;
	};
	
	onMobileOperatorChange = async operator => {
		this.mobileOperator = operator;
		this.changed = true;
	};
	
	onOrganizationChange = async record => {
		this.organization = record;
		this.changed = true;
	};
	
	onMobileStatusChange = async status => {
		// this.mobileStatus = status;
		// this.changed = true;
	};
	
	onAddressChange = async address => {
		this.changed = true;
		if (address.__type === 'db') {
			if (!address.geo) {
				address.geo = await getGeo(address.name);
			}
			this.listStore.address = address;
			this.address = this.listStore.address;
		}
		else {
			this.listStore.address = await makeAddressFromFias(address);
			this.address = this.listStore.address;
			console.log('from fias', this.listStore.address);
		}
		if (this.address) {
			if (this.address.geo) {
				this.listStore.addAddressMarker(this.address);
				this.mapStore.fitGeo(this.address.geo, this.mapStore.map.getView().getZoom() < 16 ? 16 : undefined);
			}
			else {
				this.listStore.removeAddressMarker();
			}
			for (let log of this.logs) {
				if (!log.address) {
					if (address.__type === 'db') {
						log.__setAddress(this.address);
						log.address = this.address;
					}
					else {
						log.__setAddress(this.address);
						log.__address = this.address;
					}
					log.cityId = this.address.cityId;
					log.countyId = this.address.countyId;
				}
			}
		}
	};
	
	onAddressFocus = () => {
		if (this.address && this.address.geo) {
			this.listStore.address = this.address;
			this.listStore.addAddressMarker(this.address);
			this.mapStore.fitGeo(this.address.geo, this.mapStore.map.getView().getZoom() < 16 ? 16 : undefined);
		}
	};
	
	setInstance = (log, instance) => {
		log.__setAddress = instance.setAddress;
	};
	
	onLogFocus = log => {
		console.log('onLogFocus', log.address ? log.address.name : '-');
		if (log.__outerAddress && this.address) {
			this.listStore.address = this.address;
			if (this.address && this.address.geo) {
				this.listStore.addAddressMarker(this.address);
				this.mapStore.fitGeo(this.address.geo, this.mapStore.map.getView().getZoom() < 16 ? 16 : undefined);
			}
		}
		else if (log.__address) {
			this.listStore.address = log.__address;
			if (log.__address && log.__address.geo) {
				this.listStore.addAddressMarker(log.__address);
				this.mapStore.fitGeo(log.__address.geo, this.mapStore.map.getView().getZoom() < 16 ? 16 : undefined);
			}
		}
		else if (log.address) {
			this.listStore.address = log.address;
			if (log.address && log.address.geo) {
				this.listStore.addAddressMarker(log.address);
				this.mapStore.fitGeo(log.address.geo, this.mapStore.map.getView().getZoom() < 16 ? 16 : undefined);
			}
		}
	};
	
	onLogChange = (log, data) => {
		// console.log('onLogChange', log, data);
		log.date = data.date;
		log.status = data.status;
		log.isolation = data.isolation;
		log.contacted = data.contacted;
		log.__outerAddress = data.__outerAddress;
		if (data.address) {
			if (!data.__outerAddress && data.address.__type !== 'new') {
				log.address = data.address;
			}
			else if (!data.__outerAddress) {
				log.__address = data.address;
			}
			log.cityId = data.address.cityId;
			log.countyId = data.address.countyId;
		}
		if (this.address) this.changed = true;
		log.__dirty = true;
	};
	
	onLogDelete = log => {
		const index = this.logs.findIndex(_log => _log === log);
		if (index !== -1) {
			if (log.id) {
				this.deletedLogs.push(log);
				// log.__deleted = true;
				if (this.address) this.changed = true;
			}
			this.logs.splice(index, 1);
		}
		else {
			console.warn('log not found!', log);
		}
	};
	
	save = async () => {
		if (!this.address) {
			this.error = 'Выберите адрес';
			return;
		}
		// const address = this.address.__type === 'fias' ? await makeAddressFromFias(this.address) : this.address;
		this.isSaving = true;
		const isNew = !this.address.id;
		await this.address.save();
		if (isNew) {
			const res = await this.address.setCounty();
			if (res) {
				this.address.countyId = res.countyid;
			}
			console.log('res', res, this.address.countyId);
		}
		this.record.phone = this.trimmedPhone();
		this.record.address = this.address;
		this.record.cityId = this.address.cityId;
		this.record.countyId = this.address.countyId;
		this.record.agreed = this.agreed;
		this.record.abroad = this.abroad;
		this.record.isPublic = this.isPublic;
		this.record.mobileOperator = this.mobileOperator;
		this.record.mobileStatus = this.mobileStatus;
		this.record.organization = this.organization;
		await this.record.save();
		for (let log of this.deletedLogs) {
			await log.delete();
		}
		for (let log of this.logs) {
			if (log.__dirty) {
				log.caseId = this.record.id;
				if (log.__address) {
					const isNew = !log.__address.id;
					await log.__address.save();
					if (isNew) {
						const res = await log.__address.setCounty();
						if (res) {
							log.__address.countyId = res.countyid;
						}
					}
					log.address = log.__address;
					log.cityId = log.__address.cityId;
					log.countyId = log.__address.countyId;
				}
				await log.save();
			}
		}
		
		this.listStore.reload();
		this.init();
		
		this.changed = false;
		this.isSaving = false;
	};
	
	duplicate = async () => {
		this.isSaving = true;
		
		const clone = new store.model.Case();
		clone.lasName = null;
		clone.firstName = null;
		clone.middleName = null;
		clone.phone = null;
		clone.mobileOperatorId = null;
		clone.mobileStatusId = null;
		clone.address = this.address;
		clone.cityId = this.address.cityId;
		clone.countyId = this.address.countyId;
		clone.organizationId = this.organization ? this.organization.id : null;
		clone.agreed = this.agreed;
		await clone.save();
		for (let log of this.record.logs()) {
			const logClone = new store.model.CaseLog();
			logClone.caseId = clone.id;
			logClone.date = log.date;
			logClone.statusId = log.statusId;
			logClone.isolationId = log.isolationId;
			logClone.contacted = log.contacted;
			logClone.addressId = log.addressId;
			logClone.countyId = log.countyId;
			logClone.cityId = log.cityId;
			await logClone.save();
		}
		
		this.listStore.reload();
		this.props.history.push(`/covid/cases/${clone.id}`);
		
		this.isSaving = false;
	};
	
	delete = async () => {
		for (let log of [...this.deletedLogs, ...this.logs]) {
			await log.delete();
		}
		await this.record.delete();
		this.listStore.reload();
		this.props.history.push('/covid/cases');
	};
	
	onLastNameChange = e => {
		this.record.lastName = e.target.value;
		this.changed = true;
	};
	
	onFirstNameChange = e => {
		this.record.firstName = e.target.value;
		this.changed = true;
	};
	
	onMiddleNameChange = e => {
		this.record.middleName = e.target.value;
		this.changed = true;
	};
	
	onAgreedChange = value => {
		this.agreed = value;
		this.changed = true;
	};

	onIsPublicChange = value => {
		this.isPublic = value;
		this.changed = true;
	};

	onAbroadChange = value => {
		this.abroad = value;
		this.changed = true;
	};
	
	onCommentChange = e => {
		this.record.comment = e.target.value;
		this.changed = true;
	};
	onbirthDateChange = date => {
		this.record.birthDate = date;
		this.changed = true;
	};
	
	trimmedPhone = () => this.phone.replace(/[()\-_\s]/g, '');
	
	getOperator = async () => {
		const operator = await store.model.Case.getOperator(this.trimmedPhone());
		if (operator) {
			if (!this.mobileOperator || this.mobileOperator.id !== operator.id) {
				this.mobileOperator = operator;
				this.changed = true;
			}
		}
/*
		const code = await store.model.Case.getOperator(this.trimmedPhone());
		if (code) {
			const res = await store.model.MobileOperator.find({ where: { code }});
			const operator = res.length > 0 ? res[0] : null;
			if (operator) {
				if (!this.mobileOperator || this.mobileOperator.id !== operator.id) {
					this.mobileOperator = operator;
					this.changed = true;
				}
			}
		}
*/
	};
	
	render() {
		let content;

		if (!this.record) {
			content = 'Загрузка...';
		}
		else {
			content = <div>
				{/*<div className="id">ID: <strong>{this.record.id || 'новый'}</strong></div>*/}
				<div className="row">
					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-field">
						<label>Фамилия</label>
						<input type="text" value={this.record.lastName || ''} onChange={this.onLastNameChange} />
					</div>
					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-field">
						<label>Имя</label>
						<input type="text" value={this.record.firstName || ''} onChange={this.onFirstNameChange} />
					</div>
					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-field">
						<label>Отчество</label>
						<input type="text" value={this.record.middleName || ''} onChange={this.onMiddleNameChange} />
					</div>
					<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 form-field">
						<label>Дата рождения</label>
						<DatePicker value={this.record.birthDate} onChange={this.onbirthDateChange} />
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 form-field">
						<label>Телефон</label>
						<div className="phone-input">
							<MaskedInput
								value={this.phone}
								onChange={this.onPhoneChange}
								mask="8 (999) 999-99-99"
								placeholder="Номер телефона"
							/>
							<Button onClick={this.getOperator} className="btn-primary" disabled={this.trimmedPhone().length < 11}>оп.</Button>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 form-field">
						<label>Оператор</label>
						<Select noTotal maxItems={10} itemsPerPage={1000}
							items={this.mobileOperators.map(operator => ({ label: operator.name, value: operator }))}
							value={this.mobileOperator}
							onChange={this.onMobileOperatorChange}
							className="drop-down-md"
							noSearch
						/>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 form-field">
						<label>Статус отслеживания</label>
						<Select noTotal maxItems={10} itemsPerPage={1000}
							items={this.mobileStatuses.map(status => ({ label: status.name, value: status }))}
							value={this.mobileStatus}
							onChange={this.onMobileStatusChange}
							className="drop-down-md"
							disabled
							noSearch
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 form-field">
						<label>Прибыл из-за границы</label>
						<div className="line-30">
							<Checkbox value={this.abroad} onChange={this.onAbroadChange} />
							Прибыл из-за границы
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 form-field">
						<label>Персональные данные</label>
						<div className="line-30">
							<Checkbox value={this.agreed} onChange={this.onAgreedChange} />
							Персональные данные
							<Popover className="popover-hint" content="Предоставил согласие на обработку персональных данных"><FontAwesomeIcon icon={faQuestionCircle}/></Popover>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 form-field">
						<label>Публичная карта</label>
						<div className="line-30">
							<Checkbox value={this.isPublic} onChange={this.onIsPublicChange} disabled={!!store.model.user.organizationId} />
							Показывать
							<Popover className="popover-hint" content="Показывать на публичной карте. Не влияет на частные дома."><FontAwesomeIcon icon={faQuestionCircle}/></Popover>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 form-field">
						<label>Адрес</label>
						<AddressSearch
							value={this.address}
							onFocus={this.onAddressFocus}
							onChange={this.onAddressChange}
						/>
						<div className="error">{this.addressError}</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 form-field">
						<label>Организация</label>
						<Select noTotal maxItems={10} itemsPerPage={1000}
							items={this.organizations.map(organization => ({ label: organization.name, value: organization }))}
							value={this.organization}
							onChange={this.onOrganizationChange}
							className="drop-down-md"
							disabled
							noSearch
						/>
					</div>
				</div>

				<div className="case-logs form-field">
					<label>История</label>
					<div className="list">
						{this.logs
							.filter(log => !log.__deleted)
							.map((log, i) => {
								const now = new Date();
								const date = log.date ? new Date(log.date) : now;
								const past = isBefore(date, now);
								let type = 'past';
								if (!past) {
									type = 'future';
								}
								else {
									if (i < this.logs.length - 1) {
										const nexLogDate = this.logs[i + 1].date;
										const date = nexLogDate ? new Date(nexLogDate) : now;
										const past = isBefore(date, now);
										if (!past) type = 'current';
									}
									else {
										type = 'current';
									}
								}
								
								return <CaseLog
									key={log.id || 'new' + getId()}
									log={log}
									type={type}
									onChange={data => this.onLogChange(log, data)}
									onDelete={() => this.onLogDelete(log)}
									logCount={this.logs.length}
									onFocus={() => this.onLogFocus(log)}
									instance={instance => this.setInstance(log, instance)}
								/>;
							})
						}
					</div>
					<div className="actions">
						<a href="#" onClick={this.addLog}>Добавить статус</a>
					</div>
				</div>
				
				<div className="form-field">
					<label>Комментарий</label>
					<textarea rows={3} value={this.record.comment || ''} onChange={this.onCommentChange} />
					<div className="error">{this.addressError}</div>
				</div>

				{this.error && <div className="error">{this.error}</div>}
				<hr/>
				<div className="case-actions">
					<Button onClick={this.save} className="btn-primary" disabled={!this.changed || this.isSaving}>Сохранить</Button>
					<Popconfirm className="delete-case" content="Удалить?" onConfirm={this.delete}><FontAwesomeIcon icon={faTrash}/></Popconfirm>
					<Button onClick={this.duplicate} className="btn-primary duplicate-btn" disabled={this.isSaving || !this.record.id}>Дублировать</Button>
				</div>
			
			</div>;
		}
		
		return <div className="details">
			{this.id
				? <Tabs>
				<Tab title={t('case.mainDate')} path={`/covid/cases/${this.id}`} exact>{content}   </Tab>
				<Tab title={t('case.messages')} path={`/covid/cases/${this.id}/messages`}><MessageList record={this.record}/></Tab>
			</Tabs>
				:content
			}
		</div>;
	}
	
}
