import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Provider, observer } from 'mobx-react';

import { Panel } from 'components';
import List from './List';
import Edit from './Edit';
import Map from './Map';
import store from 'client/store';
import ListStore from './store';
import './style.scss';

@observer
export default class CaseList extends React.Component {

	constructor(props) {
		super(props);
		this.listStore = new ListStore(store);
	}

	render() {
		return <Provider listStore={this.listStore}>
			<Panel full className="case-list">
				<Route path="/covid/cases" component={List}/>
				<div className="case-pane">
					<Route path="/covid/cases" component={Map}/>
					{this.listStore.mapInitialized &&
						<Switch>
							<Route path="/covid/cases/create" component={Edit}/>
							<Route path="/covid/cases/:id" component={Edit}/>
							<Route path="/covid/cases">
								Выберите запись из списка.
							</Route>
						</Switch>
					}
				</div>
			</Panel>
		</Provider>;
	}
	
}
