import React, { Suspense, lazy } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Profile } from 'components';
import { Loader } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { ADMIN_MODELS } from './adminModels';
const Dictionaries = lazy(() => import('components/dictionaries'));

@observer
export class ModuleWrapper extends React.Component {
	componentDidMount() {
		document.title = this.props.module.name;
		store.module = this.props.module;
	}

	componentWillUnmount() {
		document.title = t('title');
		store.module = null;
	}

	render() {
		const { children, className, path, redirectPath } = this.props;
		return (
			<div className={className}>
				<Suspense fallback={<Loader size={18} />}>
					<Switch>
						{redirectPath && (
							<Route path={path} exact>
								<Redirect to={redirectPath} />
							</Route>
						)}
						<Route path={`${path}/profile`} component={Profile} />
						<Route
							path={`${path}/admin`}
							render={(props) => (
								<div className='administration-page	'>
									<Dictionaries {...props} models={ADMIN_MODELS} title={t('administration')} />
								</div>
							)}
						/>
						<Route> {children}</Route>
					</Switch>
				</Suspense>
			</div>
		);
	}
}
