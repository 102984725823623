import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Form, Row, Input, Field, Button, DatePicker, RecordSelect } from '@smartplatform/ui';
import t from 'i18n';

@observer
export default class AlarmAdpi extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		const { alarmMessage } = this.props;
		if (!alarmMessage) return 'no alarmMessage!';
		
		return <>
			<Row>
				<div className="form-field">
					<label>{t('adpiDevice.phoneNumber')}</label>
					<Input value={alarmMessage.adpiDevice?.phoneNumber || '-'} disabled />
				</div>
				<div className="form-field">
					<label>{t('adpiDevice.organization')}</label>
					<Input value={alarmMessage.adpiDevice?.organization?.name || '-'} disabled />
				</div>
				<div className="form-field">
					<label>{t('adpiDevice.organizationNumber')}</label>
					<Input value={alarmMessage.adpiDevice?.organization?.phoneNumber || '-'} disabled />
				</div>
			</Row>
			<Row>
				<div className="form-field">
					<label>{t('adpiDevice.contacts')}</label>
					<Input value={alarmMessage.adpiDevice?.contacts || '-'} disabled />
				</div>
			</Row>
			<Row>
				<div className='form-field'>
					<label>{t('fireAlarmMessage.information')}</label>
					<textarea value={alarmMessage.information || ''} rows={10} disabled />
				</div>
			</Row>
		</>;
	}
}
