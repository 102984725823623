import { observable } from 'mobx';
import store from 'client/store';
import { LOCAL_STORAGE_FIELDS, REQUESTS_INCLUDE } from './constants';
import { KANBAN, LIST, PER_PAGE } from 'client/constants';
import debounce from 'lodash/debounce';
import t from 'i18n';
import { isHozSubject, isMinArct, isMO, isYakutOptTorg } from '../helpers/roles';
import { PLAN_CODE, REQUEST_CODE } from 'delivery/constants';

export default class RequestsStore {
	@observable search = '';
	@observable querySearch = '';
	@observable showFilters = false;
	@observable type = null;
	@observable level = null;
	@observable statuses = [];
	@observable mode = KANBAN;
	@observable startDate = null;
	@observable endDate = null;
	@observable period = null;
	@observable reload = null;
	//list
	@observable order = 'id desc';
	@observable page = 1;
	@observable totalCount = 0;
	@observable isLoading = true;
	code;

	constructor() {
		this.localStorageData = store.local.delivery.requests || {};
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	init = async () => {
		this.isLoading = true;
		this.loadLocalStorage();
		if (this.code !== REQUEST_CODE) {
			this.mode = LIST;
		}
		
		// не используйте findOne, если ничего не будет найдено - приложение вылетит
		const types = await store.model.RequestType.find({ where: { code: { ilike: `%${this.code}%` } } });
		this.type = types.length > 0 ? types[0] : null;
		this.isLoading = false;
	};

	doSearch = () => {
		this.querySearch = this.search;
		this.reload?.();
	};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
		this.saveToLocalStorage();
	};

	onChange = (prop) => (value) => {
		this.page = 1;
		if (['endDate', 'startDate'].includes(prop)) this.period = null;
		this[prop] = value;
		this.reload?.();
		this.saveToLocalStorage();
	};

	onModeChange = (mode) => () => {
		this.mode = mode;
		this.saveToLocalStorage();
	};

	onShowFiltersChange = () => {
		this.showFilters = !this.showFilters;
		this.saveToLocalStorage();
	};

	create = () => store.route.push({ path: `${store.route.path}/create` });

	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (store.local.delivery.requests[field] = this[field]));
		store.local.save();
	};

	loadLocalStorage = async () => {
		const keys = Object.keys(this.localStorageData) || [];
		keys.forEach((key) => {
			if (this.localStorageData[key]) {
				this[key] = this.localStorageData[key];
			}
		});
		if (!Array.isArray(this.statuses)) {
			this.statuses = this.localStorageData.statuses = [];
			this.saveToLocalStorage();
		}
	};

	onQueryUpdate = async (query) => {
		if (query._totalCount) this.totalCount = query._totalCount;
		this.order = query.order;
	};

	get requestFilter() {
		const { startDate, endDate, type, querySearch, statuses, mode, level } = this;

		const filter = {
			where: { and: [{ typeId: type ? type.id : undefined }] },
			...REQUESTS_INCLUDE,
		};
		if (isMinArct() || isYakutOptTorg()) filter.where.and.push({ typeOnCreate: REQUEST_CODE });
		if (isHozSubject()) filter.where.and.push({ typeOnCreate: PLAN_CODE, organizationId: store.model.user.organizationId });
		if (isMO()) filter.where.and.push({ organizationId: store.model.user.organizationId });
		if (startDate) filter.where.and.push({ date: { gte: startDate } });
		if (endDate) filter.where.and.push({ date: { lte: endDate } });
		if (level) filter.where.and.push({ levelId: level.id });
		if (querySearch.length) filter.search = querySearch;
		if (statuses.length) filter.where.and.push({ statusId: { inq: statuses.map((i) => i.id) } });

		if (mode === KANBAN) {
			filter.order = 'priority desc';
		} else if (mode === LIST) {
			filter.order = this.order;
			filter.limit = PER_PAGE;
			filter.skip = (this.page - 1) * PER_PAGE;
		}
		if (!filter.where.and.length) delete filter.where;
		return filter;
	}

	get exportUrl() {
		const cols = {
			id: t('number'),
			date: t('createdAt'),
			type: { name: t('type') },
			organization: { name: t('request.customer') },
			supplies: t('category'),
			status: { name: t('status') },
			owner: t('request.author'),
			members: t('request.members'),
		};

		const { limit, skip, ...filters } = this.requestFilter;
		const params = {
			filters,
			date: new Date(),
			cols,
		};
		return '/api/requests/exportToExcel?params=' + encodeURIComponent(JSON.stringify(params));
	}
}
