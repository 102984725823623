import React from 'react';
import { observable } from 'mobx';
import { startOfDay, endOfDay } from 'date-fns';

import store from 'client/store';
import { ALARM_TYPE_PHONE, ALARM_TYPE_MANUAL } from '../store';
import { drawGeoMarker, geoJSON } from '@smartplatform/map/client';
import { Icon, Style } from 'ol/style';
import markerIconGeo from 'img/icons/marker.svg';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

const UPDATE_FIELDS = ['detectTime', 'reportTime'];

export default class TechFireCreationStore {
	@observable fire = null;
	@observable alarmMessage = null;
	@observable alarmType = null;
	@observable wayBill = null;
	@observable addedFireDep = null;
	@observable mapInitialized = false;
	@observable isSaving = false;
	@observable isLoading = false;
	@observable firstRank = null;

	// карта ПЧ, ключ - id ПЧ, свойство - объект вида { fireDep, vehicles, selectedVehicles }
	// vehicles - редактируемый массив
	@observable fireDeps = {};

	@observable vehicles = [];
	@observable errors = [];

	constructor() {
		this.fire = new store.model.TechFire();
		this.fire.fireDate = new Date();
		this.alarmId = store.route.params ? parseInt(store.route.params.notifier) || null : null;
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		if (this.alarmId) {
			try {
				const existingFires = await store.model.TechFire.find({
					where: { alarmMessageId: this.alarmId },
					fields: ['id'],
					order: 'id desc',
				});
				if (existingFires.length > 0) {
					store.route.push({ path: `/techfires/fires/${existingFires[0].id}`, params: {} });
				}

				const alarmMessage = await store.model.FireAlarmMessage.findById(this.alarmId, {
					include: [
						'source',
						{
							relation: 'adpiDevice',
							scope: {
								include: ['organization'],
							},
						},
						{
							relation: 'object',
							scope: {
								include: ['address', 'rank', { relation: 'fireDep', scope: { fields: ['id', 'name'] } }],
							},
						},
						'status',
					],
				});

				this.alarmType = alarmMessage.source.code;

				this.fire.alarmMessage = alarmMessage;
				this.fire.detectTime = new Date(alarmMessage.date) - 60000;
				this.fire.fireDate = this.fire.reportTime = alarmMessage.date;
				this.fire.source = alarmMessage.source;
				this.fire.status = alarmMessage.status;
				this.alarmMessage = alarmMessage;
			} catch (e) {
				console.warn(e);
			}
		} else {
			const sources = await store.model.FireAlarmMessageSource.find({ where: { code: ALARM_TYPE_MANUAL } });
			this.fire.detectTime = new Date() - 60000;
			this.fire.fireDate = this.fire.reportTime = new Date();
			this.fire.source = sources[0] || null;
		}
		this.firstRank = await store.model.TechFireRank.find({ where: { name: '1 РАНГ ПОЖАРА' } });
		this.fire.rank = this.firstRank[0] || null;
		this.isLoading = false;
	};

	getWayBillInstance = async (wayBillStore) => {
		this.wayBillStore = wayBillStore;
		console.log('getWayBillInstance', wayBillStore);

		if (this.alarmMessage?.object) {
			await this.onObjectChange(this.alarmMessage.object);
		}
	};

	onMapInit = (mapStore) => {
		this.mapStore = mapStore;
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.mapStore.addLayer(this.layer);
		this.mapInitialized = true;
	};

	save = async () => {
		this.isSaving = true;

		const globalShifts = await store.model.TechFireShift.find({
			where: {
				and: [{ date: { gte: startOfDay(this.fire.detectTime) } }, { date: { lte: endOfDay(this.fire.detectTime) } }],
			},
			order: 'date desc',
		});

		this.wayBillStore.rank = this.fire.rank;
		await this.wayBillStore.save();
		const wayBill = this.wayBillStore.record;

		console.log('waybill', wayBill);

		this.fire.fireDepId = this.fire.object?.fireDepId || null;
		this.fire.globalShift = globalShifts.length > 0 ? globalShifts[0] : null;
		console.log('globalShift', this.fire.globalShift);

		await this.fire.save();

		if (this.fire.source?.code === ALARM_TYPE_PHONE || this.fire.source?.code === ALARM_TYPE_MANUAL) {
			console.log(this.fire.source?.code);
			const alarmMessage = new store.model.FireAlarmMessage();
			alarmMessage.source = this.fire.source;
			alarmMessage.object = this.fire.object;
			alarmMessage.status = this.fire.status;
			alarmMessage.reportingPersonName = this.fire.reportingPersonName;
			alarmMessage.reportingPersonPhone = this.fire.reportingPersonPhone;
			alarmMessage.reportingPersonAddress = this.fire.reportingPersonAddress;
			await alarmMessage.save();
			this.fire.alarmMessage = alarmMessage;
			await this.fire.save();
		}

		await wayBill.patchAttributes({
			fireId: this.fire.id,
			detectTime: this.fire.detectTime,
			reportTime: this.fire.reportTime,
		});

		store.route.push({ path: `/techfires/fires/${this.fire.id}`, params: {} });
	};

	// TODO: export waybill
	export = () => {
		console.log('TODO: export waybill');
	};

	onObjectChange = async (object) => {
		this.errors = [];
		console.log('onObjectChange', object, object?.rank, object?.address, object?.fireDep);
		this.fire.object = object;
		this.fireDeps = {};
		if (object) {
			if (object.rank) {
				this.fire.rank = object.rank;
			}
			if (this.mapInitialized) {
				const marker = drawGeoMarker(object.address.geo, {
					style: new Style({
						image: new Icon({
							src: markerIconGeo,
							anchor: [9, 45],
							anchorXUnits: 'pixels',
							anchorYUnits: 'pixels',
						}),
					}),
				});
				this.layer.getSource().clear();
				this.layer.getSource().addFeatures([...marker]);
				this.mapStore.fitGeo(object.address.geo, { maxZoom: 18, padding: [50, 50, 50, 50] });
			}
			this.wayBillStore.addFireDeps(object.fireDep, this.fire.rank);
		}
	};

	onObjectReset = () => {
		this.fire.object = null;
		this.layer.getSource().clear();
		this.wayBillStore.fireDeps = {};
		this.fire.rank = this.firstRank[0] || null;
	};

	onChange = (field) => (value) => {
		if (UPDATE_FIELDS.includes(field)) {
			this.fireDeps = {};
			if (this.fire.object) this.wayBillStore.addFireDep(this.fire.object.fireDep);
		}
		if (field === 'rank' && this.fire.object?.fireDep) {
			this.wayBillStore.addFireDeps(this.fire.object.fireDep, this.fire.rank);
		}
	};
}
