import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import logo from 'client/img/logo.svg';
import { Button } from '@smartplatform/ui';
import ValidTime from './ValidTime';
import t from '../i18n';
import store from './store';

@observer
export default class Login extends React.Component {
	@observable login = '';
	@observable password = '';
	@observable captcha = '';
	@observable error = null;
	@observable tries = null;
	@observable reloads = 0;
	@observable validTime = null;

	constructor(props) {
		super(props);
	}

	onLoginChange = (e) => {
		this.login = e.target.value;
	};

	onPasswordChange = (e) => {
		this.password = e.target.value;
	};

	onCaptchaChange = (e) => {
		this.captcha = e.target.value;
	};

	onSubmit = async () => {
		this.error = null;
		this.tries = null;
		this.login = this.login.trim();
		this.password = this.password.trim();

		if (this.login.length === 0) {
			this.error = t('auth.insertLogin');
			return;
		}
		if (this.password.length === 0) {
			this.error = t('auth.insertPassword');
			return;
		}

		try {
			await store.model.login(this.login, this.password, this.captcha);
		} catch (e) {
			this.tries = e.tries;
			this.validTime = e.code === 'LOGIN_WAIT' ? e.tries.validTime : null;
			this.error = t(e.code);
			this.captcha = '';
		}
	};

	onKeyUp = (e) => {
		if (e.keyCode === 13) {
			this.onSubmit();
		}
	};

	refresh = () => this.reloads++;

	onTimeout = () => (this.validTime = null);

	render() {
		const showCaptcha = this.tries && this.tries.count >= 3;
		return (
			<div className='login-layout'>
				<div className='login-form'>
					<div className='title'>
						<img src={logo} alt='logo' />
						<span>{t('title')}</span>
					</div>
					<div className='welcome'>{t('auth.welcome')}</div>
					<div className='form-field'>
						<label>{t('user.name')}:</label>
						<input type='text' name='email' value={this.login} onChange={this.onLoginChange} />
					</div>
					<div className='form-field'>
						<label>{t('user.password')}:</label>
						<input type='password' name='password' value={this.password} onKeyUp={this.onKeyUp} onChange={this.onPasswordChange} />
					</div>

					<div className='captcha'>
						{showCaptcha && (
							<>
								<div className='form-field'>
									<label>{t('code')}:</label>
									<input type='text' name='captcha' value={this.captcha} onChange={this.onCaptchaChange} />
								</div>
								<div className='d-flex align-items-center'>
									<img src={`/api/captcha?${this.tries.count + this.reloads}`} ref={(el) => (this.img = el)} alt='' />
									<FontAwesomeIcon icon={faSyncAlt} onClick={this.refresh} />
								</div>
							</>
						)}
					</div>

					<div className='submit'>
						<Button className='btn-primary' onClick={this.onSubmit} variant='primary'>
							{t('auth.login')}
						</Button>
						<a href='http://erp.smartunit.pro/api/containers/attachments/download/12886-filename' target='_blank'>
							{t('auth.order')}
						</a>
					</div>

					<div className='error'>
						{this.error && (
							<>
								{this.error}
								{this.validTime && <ValidTime time={this.validTime} onTimeout={this.onTimeout} />}
							</>
						)}
					</div>
				</div>
				<div className='login-description'>
					<div className='top'>
						<span>{t('auth.fast')}.</span>
						<span>{t('auth.easy')}.</span>
						<span>{t('auth.safely')}.</span>
					</div>
					<div className='modules-list'>
						{store.config.modules.map((module, i) => (
							<span key={i}>{t('module.' + module)}</span>
						))}
					</div>
				</div>
			</div>
		);
	}
}
