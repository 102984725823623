import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { Column, Field, Form, Row } from '@smartplatform/ui';
import { AddButton, ModelList } from 'components';
import { fio } from 'client/tools';
import { PERSONNEL_SCOPE } from '../store';
import { fireDepVehicleItem } from 'techfires/helpers';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@inject('fireDepStore')
@observer
export default class Personnel extends React.Component {
	@observable person = null;

	constructor(props) {
		super(props);
		this.fireDepStore = props.fireDepStore;
		this.init();
	}

	init = async () => {
		const { id, fireDepId } = this.props.match.params;
		if (id) {
			this.person = await store.model.FireDepPersonnel.findById(id, {
				include: [
					{ relation: 'rank', scope: { fields: ['id', 'name'] } },
					{ relation: 'position', scope: { fields: ['id', 'name'] } },
					{ relation: 'section', scope: { fields: ['id', 'name'] } },
					{ relation: 'vehicle', scope: { fields: ['id', 'stateNumber'] } },
					{ relation: 'gasMask', scope: { fields: ['id', 'name'] } },
					{ relation: 'status', scope: { fields: ['id', 'name'] } },
				],
			});
		} else {
			this.person = new store.model.FireDepPersonnel({ fireDepId });
		}
	};

	render() {
		if (!this.person) return '...';
		const { fireDepId } = this.props.match.params;

		return (
			<div className='personnel'>
				<Form record={this.person} returnTo={`/techfires/firedep/${fireDepId}/personnel`}>
					<div className='personnel-basic'>
						<h1>{t('fireDepPersonnel.basic')}</h1>
						<Row>
							<Field property='lastName' label={t('lastName')} />
							<Field property='firstName' label={t('firstName')} />
							<Field property='middleName' label={t('middleName')} />
						</Row>
						<Row>
							<Field relation='section' property='name' label={t('fireDepSection.title')} />
							<Field relation='position' property='name' label={t('fireDepPosition.title')} />
							<Field relation='rank' property='name' label={t('fireDepRank.title')} />
						</Row>
						<Row>
							<Field relation='status' property='name' label={t('personnelStatus.title')} />
							<Field property='dayShift' label={t('fireDepPersonnel.dayShift')} />
							<div />
						</Row>
					</div>
					<div className='personnel-vehicle'>
						<h1>{t('fireDepVehicle.plural')}</h1>
						<Row>
							<Field relation='vehicle' filter={{ where: { fireDepId } }} computed={fireDepVehicleItem} label={t('fireDepVehicle.title')} />
							<Field relation='gasMask' property='name' label={t('gasMask.title')} />
							<div />
						</Row>
					</div>
					<h1>{t('fireDepVehicle.contacts')}</h1>
					<Row>
						<Field property='phone' label={t('phone')} />
						<Field property='address' label={t('address.title')} />
						<Field relation='user' computed={fio} label={t('user.title')} />
					</Row>
				</Form>
			</div>
		);
	}
}
