import React from 'react';
import { observer, Provider } from 'mobx-react';
import Actionbar from './Actionbar';
import ReportToolbarStore from './store';

@observer
export default class ReportToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportToolbarStore();
	}

	generateReport = async () => {
		await this.store.generateReport(this.props);
	};

	render() {
		const { isHTML, disableGenerate } = this.props;
		const { url, report } = this.store;


		let preview;
		if (isHTML) {
			preview = report && <div className='div-table' dangerouslySetInnerHTML={{ __html: report.content }} />;
		} else {
			preview = url && <iframe id='preview' src={`https://docs.google.com/viewer?url=${url}&embedded=true`} className='w-100' style={{ height: '40vh' }} />;
		}

		return (
			<Provider store={this.store}>
				<div>
					<Actionbar generateReport={this.generateReport} disableGenerate={disableGenerate} isHTML={isHTML} />
					{preview}
				</div>
			</Provider>
		);
	}
}
