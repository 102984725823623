import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import t from 'i18n';
import { TextField } from 'fires/components';
import store from 'client/store';

@observer
export default class FormationPopup extends React.Component {

	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.City.findById(this.props.record.id, {
			include: [
				{ relation: 'sufficiencyStatus' },
			]
		});
	};

	gotoCity = () => {
		store.route.push({ path: `/emergency/dictionaries/city/${this.record.id}` });
	};

	render() {
		if (!this.record) return <Loader size={18} />;

		return <div className='city'>
			<div className='main-info'>
				<Row>
					<div className='text-field'>
						<span className='label'>{t('name')}:</span>
						<span className='value cursor-pointer text-link' onClick={this.gotoCity}>{this.record.name}</span>
					</div>
					<TextField label={t('city.area')} value={this.record.area || '-'} />
					<TextField label={t('city.population')} value={this.record.population || '-'} />
				</Row>
				<Row>
					<TextField label={t('city.fireDanger')} value={this.record.fireDanger ? 'Да' : 'Нет'} />
					<TextField label={t('city.okato')} value={this.record.okato} />
					<TextField label={t('city.importantObjects')} value={this.record.importantObjects || '-'} />
				</Row>
				<Row>
					<TextField label={t('municipalFormation.sufficiencyStatusFull')} value={this.record.sufficiencyStatus?.name || '-'} />
				</Row>
				<Row>
					<TextField label={t('city.participatingEnterprises')} value={this.record.participatingEnterprises || '-'} />
				</Row>
			</div>
		</div>;
	}

}
