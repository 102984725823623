import React from 'react';
import { inject, observer } from 'mobx-react';

import { Checkbox } from '@smartplatform/ui';
import store from 'client/store';
import { Hint } from '../../map/filters';
import Totals from './Totals';
import Dynamics from './Dynamics';
import Danger from './Danger';
import Daily from './Daily';
import Season from './Season';
import t from 'i18n';
import './style.scss';

@inject('dashboardStore')
@observer
export default class OperationalReport extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.dashboardStore;
		this.init();
	}

	init = async () => {
		this.store.getDashboardData();
		this.store.getDashboardCitiesData();
	};

	componentWillUnmount() {
		if (this.store.chart) this.store.chart.destroy();
	}

	toggleTest = async () => {
		store.local.fires.citiesTest = !store.local.fires.citiesTest;
		store.local.save();
		this.store.citiesData = {};
		this.store.getDashboardCitiesData();
	};

	render() {
		return (
			<div className='operational-report-by-types'>
				<div className='main'>
					<div className='left'>
						<Totals />
						<Season />
						<Danger />
					</div>
					<div className='right'>
						<div className='category block'>
							<Dynamics type='forest' />
							<Daily type='forest' />
						</div>
						<div className='category block'>
							<Dynamics type='oopt' />
							<Daily type='oopt' />
						</div>
						<div className='category block'>
							<Dynamics type='other' />
							<Daily type='other' />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
