export const POPUP_PER_PAGE = 10;
export const PER_PAGE = 20;
export const MODES = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    QUARTER: 'quarter',
    YEAR: 'year',
};
export const UI_SIZE = 30;
export const YEARS = [...new Array(new Date().getFullYear() - 2018 + 1)].map((v, i) => i + 2018);


export const RAID_JOBS_INCLUDE = {
	relation: 'jobs',
	scope: {
		include: [
			{ relation: 'type', scope: { fields: ['id', 'name'] } },
			{
				relation: 'fire',
				scope: {
					fields: ['id', 'countyId', 'fireNumber'],
					include: [
						{ relation: 'county', scope: { fields: ['id', 'name'] } },
					]
				}
			},
			{ relation: 'forestOwnership' },
		],
		order: 'id asc',
	}
};