import React, { lazy } from 'react';
import { observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';
import FiresMap from './map';
import Dashboard from './dashboard';
import Registry from './registry';
import Raids from './raids';
import store from 'client/store';
import fireStore from './fireStore';
import module from './index';
import ForceAvailability from './force-availability';
import EmergencySituation from './emergency-situation';
import { ISDM } from 'fires/isdm';
import { Reports } from './reports';
import { DICTIONARIES_MODELS, EXCLUDED_PROPERTIES } from './dictonaries';
const RequestLog = lazy(() => import('./request-log'));
const TransportModule = lazy(() => import('components/transport-module'));
const Dictionaries = lazy(() => import('components/dictionaries'));
const Audit = lazy(() => import('components/audit'));
import { ModuleWrapper } from 'components';

@observer
export default class Root extends React.Component {
	constructor(props) {
		super(props);
		if (!fireStore.isInitialized) fireStore.init();
		store.fires = fireStore;
	}

	render() {
		if (!fireStore.isInitialized) return null;
		const { path } = this.props.match;
		return (
			<ModuleWrapper className='fires-module' module={module} path={path} redirectPath={`${path}/dashboard`}>
				<Route path={`${path}/map`} component={FiresMap} />
				<Route>
					<div className='fires-page'>
						<Route path={`${path}/dashboard`} exact component={Dashboard} />
						<Route path={`${path}/registry`} component={Registry} />
						<Route path={`${path}/raids`} component={Raids} />
						<Route
							path={`${path}/dictionaries`}
							render={(props) => <Dictionaries {...props} models={DICTIONARIES_MODELS} excludedProperties={EXCLUDED_PROPERTIES} />}
						/>
						<Route path={`${path}/reports`} render={(props) => <Reports {...props} />} />
						<Route path={`${path}/force-availability`} component={ForceAvailability} />
						<Route path={`${path}/emergency-situation`} component={EmergencySituation} />
						<Route path={`${path}/request-log`} component={RequestLog} />
						<Route path={`${path}/transport-data`} render={() => <TransportModule reportRelation='report' />} />
						<Route path={`${path}/isdm`} component={ISDM} />
					</div>
				</Route>
			</ModuleWrapper>
		);
	}
}
