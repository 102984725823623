import React from 'react';
import { Switch, Route } from 'react-router-dom';
import StorageControl from './Reserves';
import { SinglePage } from './single/Single';

export default (props) => {
	const { path } = props.match;
	return (
		<Switch>
			<Route path={path} exact children={() => <StorageControl />} />
			<Route path={`${path}/:id`} children={({ match }) => <SinglePage match={match} path={path} />} />
		</Switch>
	);
};
