import React from 'react';

export const random_rgba = () => {
	let o = Math.round,
		r = Math.random,
		s = 255;
	return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + 1 + ')';
};

export const tickFormatter = (value) => {
	const limit = 15;
	if (value.length < limit) return value;
	return `${value.substring(0, limit)}...`;
};

export const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
	if (percent < 0.01) return;
	const RADIAN = Math.PI / 180;

	const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill='white' textAnchor={'middle'} dominantBaseline='central'>
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

export const getYearArray = (n = 4) => {
	const currentYear = new Date().getFullYear();
	return new Array(n).fill(null).map((item, index) => currentYear - index);
};

export const getTopLevelCategory = (category, field = 'name') => {
	return category.parent ? getTopLevelCategory(category.parent, field) : category[field];
};