import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Row, Field, NumberInput, RecordSelect } from '@smartplatform/ui';
import { Form } from 'components';
import { AvatarEdit } from './avatar-edit';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

@observer
export class Profile extends React.Component {
	@observable password = '';
	@observable passwordRepeat = '';
	@observable errors = [];
	@observable isPasswordVisible = false;
	@observable user;
	@observable isLoading = true;
	@observable roles = [];

	rolesToAdd = [];
	rolesToRemove = [];
	originalRoleIds = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { id } = this.props.match?.params;
		if (id === 'create') {
			this.user = new store.model.User();
			this.isNew = true;
		} else if (!Number.isNaN(parseInt(id))) {
			this.user = await store.model.User.findById(id);
		} else {
			// /profile path
			this.user = store.model.user;
		}
		if (!this.isNew) {
			const roles = await store.model.RoleMapping.find({
				where: {
					principalType: 'USER',
					principalId: this.user.id,
				},
				include: ['role'],
			});
			this.roles = roles.map((rm) => rm.role);
			this.originalRoleIds = this.roles.map(({ id }) => id);
		}
		await this.user.organization;
		this.isLoading = false;
	};

	togglePasswordVisible = () => {
		this.isPasswordVisible = !this.isPasswordVisible;
	};

	onChangePassword = (e) => (this.password = e.target.value);
	onChangePasswordRepeat = (e) => (this.passwordRepeat = e.target.value);

	beforeSave = async (user) => {
		this.errors = [];
		if (this.password.length > 0 && this.passwordRepeat.length > 0 && this.password !== this.passwordRepeat) {
			this.errors.push(t('password.noMatch'));
		}

		if (this.errors.length === 0) {
			if (user.id) {
				if (this.password.length > 0) {
					user.password = this.password;
				} else {
					user.password = undefined;
				}
			} else {
				user.password = this.password;
			}
		}
		return this.errors.length === 0;
	};

	onAction = () => store.route.history.goBack();

	onAddRole = (role) => {
		const removeIndex = this.rolesToRemove.findIndex((remove) => remove === role.id);
		~removeIndex && this.rolesToRemove.splice(removeIndex, 1);
		const originalIndex = this.originalRoleIds.findIndex((origin) => origin === role.id);
		!~originalIndex && this.rolesToAdd.push(role.id);
		this.roles.push(role);
	};

	onRemoveRole = (e, index) => {
		e.stopPropagation();
		const roleId = this.roles[index].id;
		const addIndex = this.rolesToAdd.findIndex((add) => add === roleId);
		~addIndex ? this.rolesToAdd.splice(addIndex, 1) : this.rolesToRemove.push(roleId);
		this.roles.splice(index, 1);
	};

	onSave = async (record) => {
		const promises = [];
		if (this.rolesToAdd.length) {
			for (const roleId of this.rolesToAdd) {
				promises.push(store.model.RoleMapping.create({ principalType: 'USER', principalId: record.id, roleId }));
			}
		}
		if (this.rolesToRemove.length) {
			const rolemapping = await store.model.RoleMapping.find({
				where: { principalType: 'USER', principalId: record.id, roleId: { inq: this.rolesToRemove } },
				fields: ['id'],
			});
			rolemapping.forEach((rm) => rm.delete());
		}
		await Promise.all(promises);
		this.onAction();
	};
	get isMyProfile() {
		return this.user?.id === store.model.user.id;
	}
	render() {
		const { user, onAction, isNew, onAddRole, onSave, isMyProfile } = this;
		const { noTitle } = this.props;
		if (this.isLoading) return null;
		const passwordFields = [
			{
				value: this.password,
				disabled: false,
				onChange: this.onChangePassword,
				placeholder: t('password.new'),
				label: t('password.new'),
				children: <div className='password-hint'>{t('password.newHint')}</div>,
			},
			{
				value: this.passwordRepeat,
				disabled: !this.password && !this.passwordRepeat,
				onChange: this.onChangePasswordRepeat,
				placeholder: t('password.repeat'),
				label: t('password.repeat'),
			},
		];

		const rolesValue = this.roles.map((item, i) => (
			<div key={item.id || i} className='item-tag'>
				<span className='item-tag-content'>{item.description}</span>
				<FontAwesomeIcon className='remove-btn' icon={faTimes} onClick={(e) => this.onRemoveRole(e, i)} />
			</div>
		));

		const showRoleSelect = !isMyProfile || store.model.ACL.INFO.WRITE;
		return (
			<div className='profile-page'>
				{!noTitle && <h2>{t('profile')}</h2>}
				<AvatarEdit record={user} property='avatar' imgWidth={200} imgHeight={200} />
				<Form record={user} noDelete={isMyProfile} onCancel={onAction} onDelete={onAction} onSave={onSave} stay>
					<h3>{t('user.fullName')}</h3>
					<Row>
						<Field property='lastName' label={t('user.lastName')} width={300} />
						<Field property='firstName' label={t('user.firstName')} />
						<Field property='middleName' label={t('user.middleName')} />
					</Row>
					<h3>{t('contact.plural')}</h3>
					<Row>
						<Field property='username' label={t('user.username')} />
						<Field property='telegram' label={t('user.telegram')}>
							<NumberInput integerOnly positiveOnly />
						</Field>
						<Field property='email' label={t('user.email')} disabled={!isNew} />
					</Row>
					<Row>
						<Field relation='organization' property='name' label={t('user.organizationName')}>
							<RecordSelect noTotal maxItems={10} itemsPerPage={1000} filter={{ fields: ['id', 'name'] }} />
						</Field>
						<Field property='position' label={t('user.position')} />
					</Row>

					<h3>{t('password.title')}</h3>
					<Row>
						{passwordFields.map(({ children, label, ...inputProps }, i) => (
							<div className={classNames('form-field', { disabled: inputProps.disabled })} key={i}>
								<label>{label}</label>
								<div className='password'>
									<input type={this.isPasswordVisible ? 'text' : 'password'} autoComplete='new-password' {...inputProps} />
									<FontAwesomeIcon onClick={this.togglePasswordVisible} icon={this.isPasswordVisible ? faEye : faEyeSlash} />
								</div>
								{children}
							</div>
						))}
					</Row>

					{showRoleSelect && (
						<>
							<h3>{t('role.plural')}</h3>
							<Field>
								<RecordSelect
									className='role-select'
									model={store.model.Role}
									noTotal
									maxItems={10}
									itemsPerPage={1000}
									isRequired
									property='description'
									onChange={onAddRole}
									showValue={rolesValue}
									filter={{ where: { id: { nin: this.roles.map(({ id }) => id) } } }}
								/>
							</Field>
						</>
					)}

					<div className='form-errors'>
						{this.errors.map((e, i) => (
							<div key={i}>{e}</div>
						))}
					</div>
				</Form>
			</div>
		);
	}
}
