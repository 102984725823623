export const FIRE_NAMED_RELATIONS = [
	'aviaDep',
	'forestry',
	'landCategory',
	'county',
	'cause',
	'passedBreed',
	'combustionPattern',
	'combustionIntensity',
	'combustionType',
	'messageType',
	'zone',
	'status',
	'forestCategory',
	'cover',
	'tenant',
	'forestryPrecinct',
	'determiningMethod',
	'city',
	'airfield',
	'forestOwnership',
	'status',
	'notLandingReason',
	'notInspectionReason'
].map(item => ({ relation: item, scope: { fields: ['id', 'name'] } }));


const getNameShortName = (relationName) => ({ relation: relationName, scope: { fields: ['name', 'shortName', 'id', 'code'] } })

export const PEOPLE_INCLUDE = {
	relation: 'people',
	scope: {
		include: ['formation', 'crewType'].map(i => getNameShortName(i))
	},
	order: 'createdAt desc',
};
export const VEHICLE_INCLUDE = {
	relation: 'vehicles',
	scope: {
		include: ['type', 'formation', 'crewType'].map(i => getNameShortName(i))
	},
	order: 'createdAt desc',
};
export const FIRE_INCLUDE = [
	...FIRE_NAMED_RELATIONS,
	PEOPLE_INCLUDE,
	VEHICLE_INCLUDE,
	{ relation: 'status', scope: { fields: ['id', 'name', 'color', 'code'] } },
	{ relation: 'forestOwnership', scope: { fields: ['id', 'name', 'code'] } },
	{ relation: 'isdmFire', scope: { fields: ['id', 'fireNumber'] } },
	{ relation: 'owner', scope: { fields: ['id', 'lastName', 'middleName', 'firstName','username'] } },
	{
		relation: 'forestryQuarters',
		scope: {
			include: [ { relation: 'forestCategory', scope: { fields: [ 'id', 'color' ] }} ],
		}
	},
];

export const RAID_INCLUDE = [
	{
		relation: 'raid',
		scope: {
			include: [
				{
					relation: 'aircraft',
					scope: {
						include: ['type'],
					},
					fields: ['id', 'typeId'],
				},
			],
			fields: ['id', 'aircraftId'],
		},
	},
];


export const AIR_JOBS_INCLUDE = [
	{
		relation: 'jobs',
		scope: {
			order: 'createdAt desc',
			include: RAID_INCLUDE,
			fields: ['id', 'duration', 'raidId', 'createdAt'],
		},
	},
];

export const VEHICLES_GROUPS = ['TR', 'BUL', 'TV', 'BA', 'PM', 'MLPK', 'TP'];



//* validation
export const ALREADY_EXIST = 'ALREADY_EXIST';
export const INCORRECT_EVACUATED = 'INCORRECT_EVACUATED';
export const REQUIRED_CREWTYPE = 'REQUIRED_CREWTYPE';
export const REQUIRED_EXTINGUISHING_AGENTS_TYPE = 'REQUIRED_EXTINGUISHING_AGENTS_TYPE';
export const INCORRECT_AIR = 'INCORRECT_AIR';