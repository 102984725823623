import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ReportStore } from '../store';
import t from 'i18n';
import './style.scss';
import { Row } from '@smartplatform/ui';
import { SaveButton, ReportToolbar } from 'components';

const CODE = 'LSOGPS40';

const FIELDS = ['title', 'header', 'sign1', 'sign2'];

@observer
export class LSOGPS40 extends React.Component {
	@observable title = '';
	@observable header = '';
	@observable sign1 = '';
	@observable sign2 = '';

	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.lsogps40'), false);
		this.init();
	}
	init = async () => {
		await this.store.init();
		FIELDS.forEach((field) => (this[field] = this.store.report[field] ?? ''));
	};

	onChange = (name) => (e) => (this[name] = e.target.value);
	renderInput = (name, tag = 'input', props) => {
		props = { name, autoComplete: name, value: this[name], onChange: this.onChange(name), ...props };
		if (tag === 'textarea') return <textarea {...props} />;
		if (tag === 'input') return <input {...props} />;
	};

	saveReport = async () => {
		FIELDS.forEach((field) => (this.store.report[field] = this[field]));
		await this.store.report.save();
	};

	get getParams() {
		const reportParams = {};

		FIELDS.forEach((field) => {
			let value = this[field];
			const parsedValue = parseInt(value);
			if (!isNaN(parsedValue) && parsedValue == value) value = parsedValue;
			reportParams[field] = value;
		});

		const params = { ...this.store.storeParams };
		params.reportParams = { ...params.reportParams, ...reportParams };
		return params;
	};

	render() {
		if (!this.store.report) return null;
		const { error, name } = this.store;
		const disabled = !!['header', 'title'].find((field) => !this[field]);

		return (
			<form className='lsogps40'>
				<h2 className='title'>{name}</h2>

				<div className='form-field'>
					<label>{t('techFireReport.title')}</label>
					{this.renderInput('title')}
				</div>
				<Row>
					<div className='form-field'>
						<label>{t('techFireReport.header')}</label>
						{this.renderInput('header', 'textarea', { rows: 5 })}
					</div>
					<div className='form-field'>
						<label>{t('techFireReport.sign1')}</label>
						{this.renderInput('sign1', 'textarea', { rows: 5 })}
					</div>

					<div className='form-field'>
						<label>{t('techFireReport.sign2')}</label>
						{this.renderInput('sign2', 'textarea', { rows: 5 })}
					</div>
				</Row>
				<div>
					<SaveButton text={t('techFireReport.saveTemplate')} onClick={this.saveReport} className='mr-2' disabled={disabled} />
					<ReportToolbar {...this.getParams} />
				</div>
				{error && <span className='error'>{error}</span>}
			</form>
		);
	}
}
