import store from 'client/store';
import getGeo from './getGeo';

export default async function makeAddressFromFias(obj) {
	console.group('making address...', obj);
	const address = new store.model.Address();
	address.__type = 'new';
	address.name = obj.name;
	address.street = obj.street;
	address.building = obj.building;
	address.geo = await getGeo(address.name);
	
	let county = null;
	if (address.geo) {
		const res = await store.model.Address.getCountyByGeo(address.geo);
		county = res.length > 0 ? res[0] : null;
		if (county) {
			address.county = county;
			address.countyId = county.id;
		}
	}
	console.log('county', county);
	
	let cityName = obj.city;
	if (obj.city) {
		const where = {
			and: [
				{ name: cityName },
			]
		};
		if (county) where.and.push({ countyId: county.id });
		const cities = await store.model.City.find({ where });
		console.log('cityName', cityName, 'cities', cities);
		if (cities.length > 0) {
			address.city = cities[0];
		}
		else {
			const city = new store.model.City();
			city.name = cityName;
			city.countyId = county ? county.id : null;
			const geo = await getGeo(`${store.config.region.searchString}, ${county ? county.name + ',' : ''} ${cityName}`);
			city.geo = geo || null;
			// console.log('city', cityName, geo);
			await city.save();
			address.city = city;
		}
	}
	console.log('city', address.city);
	console.log('county', address.county);
	console.groupEnd();
	
	return address;
};
