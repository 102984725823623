import React from 'react';
import { inject, observer } from 'mobx-react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import { observable } from 'mobx';
const RADIAN = Math.PI / 180;
const width = 400;
const height = 300;

@inject('store')
@observer
export class PlanTotal extends React.Component {
	@observable percent = 0;

	constructor(props) {
		super(props);
		this.store = props.store;
	}

	renderCustomizedLabel = () => {
		let result = [];
		const innerRadius = (width / 2) * 0.5;
		const outerRadius = (width / 2) * 0.8;

		for (let i = 0, j = 5; i <= 5; i++, j--) {
			const radius = innerRadius + (outerRadius - innerRadius);
			let x = 190 + radius * Math.cos(-36 * i * RADIAN);
			let y = 200 + radius * Math.sin(-36 * i * RADIAN);

			if (x >= 190) {
				x += 10;
			} else {
				x -= 10;
			}

			result.push(
				<text x={x} y={y} fill='black' key={i} textAnchor={x > 190 ? 'start' : 'end'} dominantBaseline='auto' fontWeight='bold' fontSize='12px'>
					{`${20 * j}%`}
				</text>
			);
		}
		return result;
	};

	renderArrow = ({ cx, cy, midAngle, outerRadius }) => {
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const mx = cx + (outerRadius + width * 0.09) * cos;
		const my = cy + (outerRadius + width * 0.09) * sin;
		return (
			<g>
				{this.percent > 0 && this.percent < 100 && (
					<path d={`M${cx},${cy}L${cx},${cy - 3}L${mx},${my}`} strokeWidth='3' stroke='white' fill='white' strokeLinecap='round' />
				)}
				<circle cx={cx} cy={cy} r={width * 0.05} fill='white' stroke='none' />
			</g>
		);
	};

	render() {
		const { deliver, plan } = this.store.data.total;

		const pieSettings = { startAngle: 180, endAngle: 0, cx: width / 2, cy: width / 2, isAnimationActive: false };
		const slices = new Array(5).fill({ value: 1 });

		this.percent = plan && deliver ? (deliver / plan) * 100 : 0;
		const arrowData = [{ value: this.percent }, { value: 0 }, { value: 100 - this.percent }];

		return (
			<div className='plan-total card'>
				<h1>Общее выполнение плана, %</h1>
				<hr />
				<PieChart
					width={width}
					height={height}
					margin={{
						left: -10,
					}}
				>
					<Pie data={[{ value: 100 }]} dataKey='value' outerRadius={(width / 2) * 0.8} fill='#37B81380' {...pieSettings} />

					<Pie stroke='none' data={slices} dataKey='value' innerRadius={(width / 2) * 0.5} outerRadius={(width / 2) * 0.8} paddingAngle={0.5} {...pieSettings}>
						{slices.map((each, i) => (
							<Cell key={`cell-${i}`} fill='#37B813' stroke='none' />
						))}
						<Label content={this.renderCustomizedLabel} position='centerBottom' offset={20} className='gauge-label' fontSize='12px' fontWeight='bold' />
					</Pie>

					<Pie
						stroke='none'
						fill='none'
						activeIndex={1}
						activeShape={this.renderArrow}
						data={arrowData}
						dataKey='value'
						innerRadius={(width / 2) * 0.5}
						outerRadius={(width / 2) * 0.6}
						{...pieSettings}
					>
						<Label value={`${this.percent.toFixed(2)}%`} position='centerTop' fontSize='12px' fontWeight='bold' />
					</Pie>
				</PieChart>
			</div>
		);
	}
}
