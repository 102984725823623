import React from 'react';
import { inject, observer } from 'mobx-react';
import { NumberValue } from '@smartplatform/ui';
import ClockPlusIcon from '!!svg-react-loader!img/icons/fires/dashboard/clock-plus.svg';
import t from 'i18n';

const LOADING = <>&nbsp;</>;

@inject('dashboardStore')
@observer
export default class Season extends React.Component {
	render() {
		const { dashboardStore } = this.props;
		const { data } = dashboardStore || {};
		const { seasonCountCurrent, seasonAreaCurrent } = data.season || {};
		const { seasonCountLastYear, seasonAreaLastYear } = data.prevSeason || {};

		return (
			<div className='season block'>
				<div className='title'>
					<ClockPlusIcon />
					<h2>{t('fireDashboard.seasonFires')}</h2>
				</div>
				<div className='content'>
					<table className='dashboard-table'>
						<thead>
							<tr>
								<th />
								<th width='33%'>{t('fireDashboard.currentYear')}</th>
								<th width='33%'>{t('fireDashboard.lastYear')}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='category'>{t('fireDashboard.seasonTotalCount')}</td>
								<td className='number'>{seasonCountCurrent !== undefined ? <NumberValue value={seasonCountCurrent} /> : LOADING}</td>
								<td className='number'>{seasonCountLastYear !== undefined ? <NumberValue value={seasonCountLastYear} /> : LOADING}</td>
							</tr>
							<tr>
								<td className='category'>{t('fireDashboard.seasonTotalArea')}</td>
								<td className='number'>{seasonAreaCurrent !== undefined ? <NumberValue value={seasonAreaCurrent} fraction={1} /> : LOADING}</td>
								<td className='number'>{seasonAreaLastYear !== undefined ? <NumberValue value={seasonAreaLastYear} fraction={1} /> : LOADING}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
