import { observable } from 'mobx';
import store from 'client/store';
import { subMonths, startOfDay, endOfDay, startOfMonth, endOfMonth } from 'date-fns';

import { ColorUtils } from '@smartplatform/ui';

export const MODES = {
	WEEK: 'week',
	MONTH: 'month',
	YEAR: 'year',
}

const RELOAD_ALL = 'reload-all';
const RELOAD_CATEGORIES = 'reload-categories';
const SYSTEM_CHANGED = 'system-changed';

export const EVENTS = {
	RELOAD_ALL,
	RELOAD_CATEGORIES,
	SYSTEM_CHANGED,
};

class AppealsStore {

	@observable isInitialized = false;
	@observable currentDate = new Date();
	@observable dateMode = MODES.MONTH;
	@observable startDate = startOfDay(startOfMonth(new Date()));
	@observable endDate = endOfDay(endOfMonth(new Date()));
	@observable category = null;
	@observable system = null;

	events = {};

	init = async () => {
		if (store.model.isInitialized && store.model.isAuthorized) {
			this.system = await store.model.AppealSystem.findById(1);
			this.isInitialized = true;
		}
	};

	categoryColor = (category) => {
		const { background, color } = ColorUtils.getColorForString(category.name);
		return background;
	};

	subscribe = (event, callback) => {
		if (!this.events[event]) this.events[event] = [];
		this.events[event].push(callback);
		// console.log('subscribe', event, this.events);
	};

	unsubscribe = (event, callback) => {
		if (!this.events[event]) return;
		const index = this.events[event].findIndex(cb => cb === callback);
		if (index !== -1) {
			this.events[event].splice(index, 1);
		}
		if (this.events[event].length === 0) delete this.events[event];
		// console.log('unsubscribe', event, index, this.events);
	};

	reload = async () => {
		if (this.events[RELOAD_ALL]) {
			this.events[RELOAD_ALL].forEach(cb => cb());
		}
	};

	reloadCategories = async () => {
		if (this.events[RELOAD_CATEGORIES]) {
			this.events[RELOAD_CATEGORIES].forEach(cb => cb());
		}
	};

	onSystemChange = async system => {
		const update = this.system.id !== system.id;
		this.system = system;
		if (update) {
			console.log('onSystemChange', system);
			if (this.events[SYSTEM_CHANGED]) {
				await Promise.all(this.events[SYSTEM_CHANGED].map(cb => cb(system)));
			}
			this.category = null;
			await this.reload();
		}
	};
}

export default new AppealsStore();
