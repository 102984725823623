import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import ru from 'date-fns/locale/ru';
import { formatDuration } from 'date-fns';

import appealsStore, { EVENTS } from '../store';
import store from 'client/store';

@observer
export default class OrganizationsCount extends React.Component {

	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		appealsStore.subscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.subscribe(EVENTS.RELOAD_CATEGORIES, this.init);
	}

	componentWillUnmount() {
		appealsStore.unsubscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.unsubscribe(EVENTS.RELOAD_CATEGORIES, this.init);
	}

	init = async () => {
		this.records = await store.model.Organization.getCounts({
			startDate: appealsStore.startDate,
			endDate: appealsStore.endDate,
			parentId: appealsStore.category ? appealsStore.category.id : null,
			systemId: appealsStore.system.id,
		});
		this.isLoading = false;
	};
	
	render() {
		if (this.isLoading) return null;

		return this.records
			.filter(organization => parseInt(organization.count) > 0)
			.map(organization => {
				const color = appealsStore.categoryColor(organization);
				return <div key={organization.id} className="category">
					<div className="color"><span style={{ background: color }} /></div>
					<div className="name">{organization.name}</div>
					<div className="period">{organization.count}</div>
				</div>;
			});
	}
	
}
