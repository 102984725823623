import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import mapStore from '../store';
import './style.scss';
import {
	HeatPointsFilter,
	CountiesFilter,
	GenShtabFilter,
	WindFilter,
	MunicipalityFilter,
	FireDepFilter,
	WaterSupplyFilter,
	AdpiFilter,
	WeatherFilter
} from 'components';
import { observable } from 'mobx';
import { AddressFilter } from 'components/common-layers/address';

@observer
export default class Filters extends React.Component {
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.localSettings = store.local.techfires;
		this.settings = this.localSettings.layers;
		this.init();
	}

	init = async () => {
		const [adpiStatuses, waterSupplyTypes] = await Promise.all([
			store.model.AdpiDeviceStatus.find({ fields: ['id', 'name', 'code', 'color'] }),
			store.model.WaterSupplyType.find({ fields: ['id', 'code', 'name'] }),
		]);

		this.adpiStatuses = adpiStatuses;
		this.waterSupplyTypes = waterSupplyTypes;
		// await this.checkLocalStorage();
		this.isLoading = false;
	};

	// checkLocalStorage = async () => {
	// 	const { waterSupply, adpi } = this.settings;

	// 	if (Object.keys(waterSupply.filter.selectedTypes).length === 0) {
	// 		for (const { id } of this.waterSupplyTypes) {
	// 			waterSupply.filter.selectedTypes[id] = false;
	// 		}
	// 	}

	// 	if (Object.keys(adpi.filter.selectedStatuses).length === 0) {
	// 		for (const { id } of this.adpiStatuses) {
	// 			adpi.filter.selectedStatuses[id] = false;
	// 		}
	// 	}

	// 	await store.local.save();
	// };
	render() {
		if (this.isLoading) return null;
		const settings = this.settings;

		return this.localSettings.filtersMaximized ? (
			<div className='techfires-filters-wrapper'>
				<div className='minimize-btn' onClick={() => mapStore.switchFilters(false)}>
					<FontAwesomeIcon icon={faFilter} />
				</div>
				<div className='techfires-map-filters'>
					<AdpiFilter layer={mapStore.adpiLayer} settings={settings.adpi} statuses={this.adpiStatuses} />
					<AddressFilter layer={mapStore.addressLayer} settings={settings.address} />
					<WaterSupplyFilter layer={mapStore.waterSupplyLayer} settings={settings.waterSupply} types={this.waterSupplyTypes} />
					<FireDepFilter layer={mapStore.fireDepLayer} settings={settings.fireDep} />
					<MunicipalityFilter layer={mapStore.municipalityLayer} settings={settings.municipality} />
					{/*<Fires layer={mapStore.firesLayer} store='techfires'/>*/}
					<HeatPointsFilter layer={mapStore.heatPointsLayer} settings={settings.heatPoints} />
					<CountiesFilter layer={mapStore.countiesLayer} settings={settings.counties} />
					<GenShtabFilter layer={mapStore.genShtabLayer} settings={settings.genShtab} />
					<WeatherFilter layer={mapStore.weatherLayer} settings={settings.weather} />
					<WindFilter layer={mapStore.windLayer} settings={settings.wind} />
				</div>
			</div>
		) : (
			<div className='techfires-filters-minimized' onClick={() => mapStore.switchFilters(true)}>
				<FontAwesomeIcon icon={faFilter} />
			</div>
		);
	}
}
