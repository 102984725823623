import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import { AddressInfo, TechFireAddressSearch } from 'components';
import AddressCreateStore from './store';
import GeoEdit from '../../../../components/geo-edit/GeoEdit';
import { Row } from '@smartplatform/ui';

@observer
export class Create extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable error = null;

	constructor(props) {
		super(props);
		this.store = new AddressCreateStore();
	}

	render() {
		const { fire, address, onObjectChange, onObjectReset } = this.store;

		return (
			<>
				<div className='form-field'>
					<label>
						{t('techFireAddress.title')} <span className='is-required'>*</span>
					</label>
					<TechFireAddressSearch value={fire.object} onChange={onObjectChange} onReset={onObjectReset} />
				</div>
				{address && (
					<Row>
						<div className='address-form'>
							<AddressInfo address={address} />
						</div>
						<GeoEdit value={address.geo} disabled params={{ zoom: 17 }} />
					</Row>
				)}
			</>
		);
	}
}
