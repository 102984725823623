import React, { Component } from 'react';
import { Button, DateValue, DatePicker, Field, Row, Form, Input } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { MODEL_NAME } from './constants';
import { renderStatus } from 'client/tools';
import { ADPI, FALSE, TRUE } from 'client/constants';
import GeoEdit from 'components/geo-edit/GeoEdit';
import { AlarmAdpi, Alarm112, AlarmPhone, AlarmManual, ALARM_TYPE_ADPI, ALARM_TYPE_112, ALARM_TYPE_PHONE, ALARM_TYPE_MANUAL } from '../fires';

@observer
export class Edit extends Component {
	@observable record = null;
	@observable existingFire = null;
	@observable isLoading = true;
	path;

	constructor(props) {
		super(props);
		this.fetchRecord();
		this.path = props.path;
	}

	fetchRecord = async () => {
		this.isLoading = true;
		const id = parseInt(this.props.match.params.id);
		if (!Number.isNaN(id)) {
			const existingFires = await store.model.TechFire.find({
				where: { alarmMessageId: id },
				fields: ['id'],
				order: 'id desc',
			});
			if (existingFires.length > 0) {
				this.existingFire = existingFires[0];
			}
			this.record = await store.model[MODEL_NAME].findById(id, {
				include: [
					'source',
					{
						relation: 'adpiDevice',
						scope: {
							include: ['organization'],
						},
					},
					{
						relation: 'object',
						scope: {
							include: ['address', 'rank'],
						},
					},
					'status',
				],
			});
		}
		this.isLoading = false;
	};

	goToFire = () => store.route.push({ path: `/techfires/fires/${this.existingFire.id}`, params: {} });

	onFalseMessageClick = async () => {
		try {
			await this.saveRecordStatus(FALSE);
			store.route.push({ path: `/techfires/fires/create`, params: { notifier: this.record.id } });
		} catch (e) {
			console.error(e);
		}
	};
	onTrueMessageClick = async () => {
		try {
			await this.saveRecordStatus(TRUE);
			store.route.push({ path: `/techfires/fires/create`, params: { notifier: this.record.id } });
		} catch (e) {
			console.error(e);
		}
	};

	saveRecordStatus = async (statusCode) => {
		const status = await store.model.FireAlarmMessageStatus.findOne({ where: { code: statusCode } });
		this.record.statusId = status.id;
		this.record.status = status;
		await this.record.save();
	};

	render() {
		if (this.isLoading || !this.record) return null;
		const messageCode = this.record.source?.code || null;
		const geo = this.record.object?.address?.geo;

		const controls = this.existingFire ? (
			<>
				<Button onClick={this.goToFire} variant='primary'>
					{t('fireAlarmMessage.goToFire') + ' #' + this.existingFire.id}
				</Button>
			</>
		) : (
			<>
				<Button onClick={this.onTrueMessageClick} variant='primary'>
					{t('fireAlarmMessage.trueMessage')}
				</Button>
				<Button onClick={this.onFalseMessageClick} variant='danger'>
					{t('fireAlarmMessage.falseMessage')}
				</Button>
			</>
		);

		return (
			<>
				<Form record={this.record} noSave noDelete disabled>
					<div className='notify-form'>
						<div className='main-fields'>
							<Row>
								<Field property='date' label={t('techFire.notifier.messageDate')}>
									<DatePicker showTimeSelect disabled />
								</Field>
								<Field property='incidentCode' label={t('techFire.notifier.incidentCode')} />
								<Field property='operatorNumber' label={t('techFire.notifier.operatorNumber')} />
							</Row>
							<Field relation='object' label={t('techFire.notifier.fireObject')}>
								{(record) => <Input value={record?.name || '-'} disabled />}
							</Field>
							<Row>
								<Field property='reportingPersonName' label={t('fireAlarmMessage.reportingPersonName')} />
								<Field property='reportingPersonPhone' label={t('fireAlarmMessage.reportingPersonPhone')} />
							</Row>
							<Row>
								<Field relation='object' label={t('techFire.rank')}>
									{(record) => <Input value={record?.rank?.name} disabled />}
								</Field>
								<Field relation='object' property='ktp' label={t('techFireAddress.ktp')} />
								<Field relation='object' property='ptp' label={t('techFireAddress.ptp')} />
							</Row>

							<Row>
								<Field relation='source' property='description' label={t('techFire.notifier.source')} />
								<Field relation='status' computed={renderStatus} label={t('status')} />
								<div />
							</Row>
							{messageCode === ALARM_TYPE_ADPI && <AlarmAdpi alarmMessage={this.record} />}
							{messageCode === ALARM_TYPE_112 && <Alarm112 alarmMessage={this.record} />}
							{messageCode === ALARM_TYPE_PHONE && <AlarmPhone record={this.record} />}
							{messageCode === ALARM_TYPE_MANUAL && <AlarmManual record={this.record} />}
							{/*<Field property='createdAt' label={t('createdAt')}><DateValue format='dd.MM.yyyy HH:mm' /></Field>*/}
							<div className='notify-buttons-controls'>{controls}</div>
						</div>
						{geo && <GeoEdit value={geo} disabled params={{ zoom: 17 }} />}
					</div>
				</Form>
			</>
		);
	}
}
