import React, { lazy } from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import store from 'client/store';
import module from './index';
import t from 'i18n';
import { ModuleWrapper } from 'components';
import Map from './map';
import { DynamicLayers } from '@smartplatform/map/client';
import initLocal from './localStore';
import Fires from './fires';
import { FireDepartments } from './firedepartments';
import Note from './note';
import { Notifier } from './notifier';
import Reports from './reports';
import AddressRegistry from './address-registry';
import Weather from './weather';
import Shifts from './shifts';
import { DICTIONARIES_MODELS, REGISTRY_MODELS } from './constants';
const TransportModule = lazy(() => import('components/transport-module'));
const Dictionaries = lazy(() => import('components/dictionaries'));
const Audit = lazy(() => import('components/audit'));

@observer
export default class Root extends React.Component {
	constructor(props) {
		super(props);
		initLocal();
	}

	render() {
		const { path } = this.props.match;
		return (
			<ModuleWrapper className='techfires-module' module={module} path={path} redirectPath={`${path}/map`}>
				<Route path={`${path}/map`} component={Map} />
				<Route path={`${path}/layers`} render={(routeProps) => <DynamicLayers {...routeProps} modelStore={store.model} />} />
				<Route>
					<div className='techfires-page'>
						<Route path={`${path}/fires`} component={Fires} />
						<Route path={`${path}/weather`} component={Weather} />
						<Route path={`${path}/firedep`} component={FireDepartments} />
						<Route path={`${path}/note`} render={(props) => <Note {...props} />} />
						<Route path={`${path}/addresses`} component={AddressRegistry} />
						<Route path={`${path}/addresses-objects`} component={AddressRegistry} />
						<Route path={`${path}/registry`} render={(props) => <Dictionaries models={REGISTRY_MODELS} title={t('fire.registry.title')} {...props} />} />
						<Route path={`${path}/notifier`} component={Notifier} />
						<Route path={`${path}/reports`} component={Reports} />
						<Route path={`${path}/transport`} render={() => <TransportModule reportRelation='techFireReport' />} />
						<Route path={`${path}/dictionaries`} render={(props) => <Dictionaries models={DICTIONARIES_MODELS} {...props} />} />
						<Route path={`${path}/journal`} component={Audit} />
						<Route path={`${path}/shifts`} component={Shifts} />
					</div>
				</Route>
			</ModuleWrapper>
		);
	}
}
