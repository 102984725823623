import React from 'react';
import './style.scss';
import { Members } from './Members';
import { Discussion, Attachments } from 'components';
import { inject, observer } from 'mobx-react';
import { Card } from 'delivery/components';
import UsersIcon from '!!svg-react-loader!client/img/icons/users.svg';
import t from 'i18n';
import store from 'client/store';
import { Row } from '@smartplatform/ui';
import { observable } from 'mobx';
@inject('store')
@observer
export class Agreement extends React.Component {
	@observable isPlanAdded = this.props.store.record.status?.code === 'approved' || !!this.props.store.record.planFiles().length;
	onUpload = async () => (this.isPlanAdded = true);

	get canUpload() {
		const { members } = this.props.store;
		const headMembers = members.filter((member) => member.role?.code === 'HEAD');
		const isLoggedUserHeadMember = headMembers.map((i) => i.userId).includes(store.model.user.id);
		return isLoggedUserHeadMember;
	}

	get isAgreed() {
		const members = this.props.store.members;
		return !!members.length && !members.find((member) => member.status?.code !== 'finished');
	}

	render() {
		const { fetchComments, record, comments } = this.props.store;
		const discussionProps = { fetchComments, record, comments };
		return (
			<div className='agreement'>
				<Card icon={<UsersIcon />} title={t('request.agreementMembers')}>
					<Members />
					{this.isAgreed && (
						<div className='plan-files'>
							<Row>
								<Attachments
									softDelete
									record={record}
									canUpload={this.canUpload}
									uploadTitle={t('emergencySituationPlan.attachPlanFiles')}
									relation='planFiles'
									noDelete
									onUpload={this.onUpload}
								/>
								{this.isPlanAdded && (
									<Attachments
										softDelete
										record={record}
										canUpload={this.canUpload}
										uploadTitle={t('emergencySituationPlan.attachCorrectionPlanFiles')}
										relation='correctionPlanFiles'
									/>
								)}
							</Row>
						</div>
					)}
				</Card>
				<Discussion {...discussionProps} noDelete withAttachments />
			</div>
		);
	}
}
