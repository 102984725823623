import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Loader, Form, Field, Row, DatePicker } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';

@observer
export default class Edit extends React.Component {
	
	@observable record = null;
	@observable error = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const id = this.props.match.params.id;
		if (id) {
			try {
				this.record = await store.model.TechFireShift.findById(id);
			}
			catch (e) {
				this.error = e.message;
			}
		}
		else {
			this.record = new store.model.TechFireShift();
			this.record.date = new Date();
		}
		this.isLoading = false;
	};
	
	render() {
		if (this.isLoading) return <Loader />;
		if (this.error) return <div className="error">{this.error}</div>;

		return <div>
			<Form record={this.record} returnTo="/techfires/shifts">
				<Row>
					<Field property="name" label={t('name')} />
					<Field property="date" label={t('date')} />
					<div />
				</Row>
			</Form>
		</div>;
	}
}
