import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import TrackingIcon from '!!svg-react-loader!img/icons/delivery/search.svg';
import { Card } from 'delivery/components';
import { observable } from 'mobx';
import { Loader } from '@smartplatform/ui';
import './style.scss';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import { formatDate } from 'client/tools';
import { getStatusPosition, mergeStatusesByName } from '../helper';

@inject('store')
@observer
export class CardStatus extends React.Component {
	@observable statuses = [];
	@observable progressBarPosition = 0;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.init();
	}

	init = () => {
		const { trackStatuses, tracks } = this.store;
		let lastTrackName = null;

		let allStatuses = trackStatuses.map((status, i) => {
			return {
				name: status.name,
				position: getStatusPosition(i, trackStatuses.length),
			};
		});

		let trackData = [];
		for (const [index, track] of tracks.entries()) {
			trackData.push({ date: track.date, name: track.status.name, city: track.city.name });
			if (index === tracks.length - 1) lastTrackName = track.status.name;
		}

		this.statuses = mergeStatusesByName(allStatuses, trackData);
		if (lastTrackName) {
			this.progressBarPosition = this.statuses.find((status) => status.name === lastTrackName).position;
		} else {
			this.progressBarPosition = 0;
		}
		this.isLoading = false;
	};

	render() {
		let content;
		if (this.isLoading) {
			content = <Loader />;
		} else {
			const gridStatus = {
				display: 'grid',
				gridTemplateColumns: `repeat(${this.statuses.length},1fr)`,
			};
			content = (
				<div className='status-items'>
					<ProgressBar bgcolor={'#37B813'} completed={this.progressBarPosition} />
					<div className='statuses' style={gridStatus}>
						{this.statuses.map((status) => (
							<div className='status' key={status.name}>
								<div className='name'>{status.name}</div>
								<div className='date'>{formatDate(status.date, 'dd.MM.yyyy HH:mm:ss')}</div>
								<div className='city'>{status.city}</div>
							</div>
						))}
					</div>
				</div>
			);
		}
		return (
			<Card icon={<TrackingIcon />} title={t('trackingProduction.status')} className='card-status'>
				{content}
			</Card>
		);
	}
}
