import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { renderStatus } from 'client/tools';
import t from 'i18n';
import './style.scss';

@observer
export default class PlanMapPopup extends React.Component {
	
	render() {
		const { record } = this.props;
		
		return <div className="plan-popup">
			<div>{renderStatus(record.status) || t('emergencySituationPlan.noStatus')}</div>
			<div>{t('county.title')}: {record.county ? record.county.name : '-'}</div>
		</div>;
	}
}
