import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, computed } from 'mobx';

import Casualties from './Casualties';
import Chiefs from './Chiefs';

import store from 'client/store';
import { Form, Row, Field, NumberInput, NumberValue } from '@smartplatform/ui';
import t from 'i18n';

@inject('fireStore')
@observer
export default class AfterMath extends React.Component {
	@observable casualty = null;
	@observable chief = null;
	@observable organization = null;

	constructor(props) {
		super(props);
		this.init();
	}

	onOrganizationChange = (organization) => {
		this.organization = organization;
		this.init();
	};

	init = async () => {
		const { id, fireId } = this.props.match.params;
		if (id) {
			this.casualty = await store.model.TechFireCasualty.findById(id, {
				include: [{ relation: 'fire', scope: { fields: ['id', 'name'] } }],
			});
		} else {
			this.casualty = new store.model.TechFireCasualty({ fireId });
		}
	};

	render() {
		const { fireStore, match } = this.props;
		if (!fireStore.id) return t('techFire.needSave');
		const { fire } = fireStore;

		return (
			<Form record={fire} noSave noDelete stay>
				<h3>{t('techFire.effects')}</h3>
				<Row>
					<Field property='destroyedBuildings' label={t('techFire.destroyedBuildings')} />
					<Field property='area' label={t('techFire.area')} />
					<Field property='destroyedVehicles' label={t('techFire.destroyVeh')} />
					<Field property='destroyedLivestock' label={t('techFire.destroyAnimals')} />
				</Row>
				<Row>
					<Field property='addition' label={t('techFire.addition')}>
						<textarea rows={4} />
					</Field>
				</Row>
				<Row>
					<Field relation='status' property='name' label={t('techFire.status')} />
					<div />
					<div />
					<div />
				</Row>
				<h3>{t('techFire.infoAffected')}</h3>
				<Row>
					<Casualties casualty={this.casualty} />
				</Row>
				<h3>{t('techFire.resultInquiry')}</h3>
				<Row>
					<Field property='causeText' label={t('techFire.causes')} />
					<Field property='culprit' label={t('techFire.culprit')} />
					<Field property='damage' label={t('techFire.damage')} />
					<div />
				</Row>
				<h3>{t('techFire.workFireDep')}</h3>
				<Row>
					{/*<Field property='vehiclesCount' label={t('techFire.vehiclesCount')} />*/}
					<div className='form-field'>
						<label>{t('techFire.vehiclesCount')}</label>
						<NumberInput value={fireStore.vehicles.length} disabled />
					</div>
					<Field property='rescueTeamsCount' label={t('techFire.rescueTeamsCount')} />
					<Field property='barrelsCount' label={t('techFire.barrelsCount')} />
					<Field property='waterSupplyMounts' label={t('techFire.waterSupplyMounts')} />
				</Row>
				<Row>
					{/*<Field property='specialVehicle' label={t('techFire.specialVehicle')} />*/}
					<div className='form-field'>
						<label>{t('techFire.specialVehicle')}</label>
						<NumberInput value={fireStore.vehicles.filter((v) => v.specialTypeCode === 'SPECIAL').length} disabled />
					</div>
					<Field property='ond' label={t('techFire.ond')} />
					<Field property='additionalTech' label={t('techFire.additionalTech')} />
					<div />
				</Row>
				<Row>
					<Field property='interactionService' label={t('techFire.organizations')} />
				</Row>
				<h3>{t('fireDep.chief')}</h3>
				<Row>
					<Chiefs chief={this.chief} />
				</Row>
				<h3>{t('techFire.rescued')}</h3>
				<Row>
					<Field property='savedMaterials' label={t('techFire.savedMaterials')} />
					<Field property='savedValues' label={t('techFire.savedValues')} />
					<Field property='savedBuildings' label={t('techFire.savedBuildings')} />
					<Field property='savedVehicles' label={t('techFire.savedVehicles')} />
				</Row>
				<Row>
					<Field property='rescuedPeople' label={t('techFire.rescuedPeople')} />
					<Field property='savedLivestock' label={t('techFire.savedLivestock')} />
					<div />
					<div />
				</Row>
				<h3>{t('techFire.evacuated')}</h3>
				<Row>
					<Field property='savedPeople' label={t('techFire.savedPeople')} />
					<Field property='savedAdult' label={t('techFire.savedAdult')} />
					<Field property='savedChildren' label={t('techFire.savedChildren')} />
					<div />
				</Row>
			</Form>
		);
	}
}
