import React from 'react';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import { matchPath } from 'react-router-dom';

import { Tabs, Tab, Form, Button } from '@smartplatform/ui';
import { DeleteBtn, CleanButton } from 'components';
import MainInfo from './maininfo';
import AfterMath from './aftermath';
import AlarmMessage from './alarmmessage';
import { WayBills } from './waybills';
import TechFireStore from './store';
import t from 'i18n';
import './style.scss';
import FireReport from './firereport';

const PATHS_WITH_ACTIONS = [{ path: '/techfires/fires/:id', exact: true }, { path: '/techfires/fires/:id/aftermath' }];

@observer
export default class TechFire extends React.Component {
	constructor(props) {
		super(props);
		const { id } = props.match.params;
		this.store = new TechFireStore(id);
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.store = new TechFireStore(this.props.match.params.id);
		}
	}

	render() {
		const { fire, saveFire, deleteFire, cancelFire, clearMainInfo } = this.store;
		if (!fire || !fire.id) return '...';

		const { pathname } = this.props.history.location;
		const showActions = PATHS_WITH_ACTIONS.some((path) => matchPath(pathname, path) !== null);

		return (
			<div className='techfire-single'>
				<h1>{fire.id ? t('techFire.title') + ` #${fire.id}` : t('techFire.create')}</h1>

				<Provider fireStore={this.store}>
					<Tabs>
						<Tab path={`/techfires/fires/${fire.id}`} exact title={t('techFire.mainInfo')} render={(routeProps) => <MainInfo {...routeProps} />} />
						<Tab path={`/techfires/fires/${fire.id}/alarmmessage`} title={t('fireAlarmMessage.title')} render={() => <AlarmMessage fireId={fire.id} />} />
						<Tab path={`/techfires/fires/${fire.id}/aftermath`} title={t('techFire.afterMath')} render={(routeProps) => <AfterMath {...routeProps} />} />
						<Tab path={`/techfires/fires/${fire.id}/waybills`} title={t('techFireWayBill.plural')} render={(routeProps) => <WayBills {...routeProps} />} />
						<Tab
							path={`/techfires/fires/${fire.id}/firereport`}
							title={t('techFire.firereport')}
							render={(routeProps) => <FireReport {...routeProps} fireId={fire.id} />}
						/>
					</Tabs>
				</Provider>
				{showActions && (
					<div className='main-actions'>
						<Button onClick={saveFire} variant='primary'>
							{t('save')}
						</Button>
						<Button onClick={cancelFire}>{t('cancel')}</Button>
						{/* <CleanButton onClick={clearMainInfo} /> */}
						{/* <DeleteBtn onClick={deleteFire}>{t('delete')}</DeleteBtn> */}
					</div>
				)}
			</div>
		);
	}
}
