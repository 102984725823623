import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, Popup } from '@smartplatform/ui';
import store from 'client/store';

@inject('store')
@observer
export default class MemberComment extends React.Component {
	static propTypes = {
		onClose: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.store = props.store;
		this.state = {
			comment: '',
		};
	}

	saveRequest = async () => {
		const commentMessage = this.state.comment;
		if (commentMessage.trim().length === 0) {
			this.props.onClose();
			return;
		}

		const { record } = this.store;
		const comment = new store.model.Comment();
		comment.text = commentMessage;
		await comment.save();

		record.comments.add(comment.id);
		await record.save();

		this.props.store.fetchComments();
		this.props.onClose();
	};

	static defaultProps = {};

	render() {
		return (
			<Popup onClose={this.props.onClose} width={450} className='edit-request-log-popup request-member' header='Оставить комментарий'>
				<textarea className='textarea' onChange={(e) => this.setState({ comment: e.target.value })} />
				<Button onClick={this.saveRequest} style={{ height: '3em' }} variant='primary'>
					Сохранить
				</Button>
			</Popup>
		);
	}
}
