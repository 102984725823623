import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Panel, FILTERS_MARGIN } from 'components';
import GraphTotal from './GraphTotal1';
import './style.scss';

const tabs = ['total', 'hotline', 'remote'];

@observer
export default class Graphs extends React.Component {

	@observable tab = tabs[0];
	
	constructor(props) {
		super(props);
	}
	
	switchTab = async tab => {
		this.tab = tab;
	};
	
	render() {
		
		const props = {
			width: window.outerWidth <= 1024 ? 300 : 360,
			height: 260
		};
		
		return <Panel right={0} bottom={0} left="auto" top="auto" width={380} height={280} className="block graphs">
{/*
			<TabMenu>
				<MenuItem label="Всего больных" onClick={() => this.switchTab(tabs[0])} active={this.tab === tabs[0]}/>
				<MenuItem label="Горячая линия" onClick={() => this.switchTab(tabs[1])} active={this.tab === tabs[1]}/>
				<MenuItem label="Удаленный режим" onClick={() => this.switchTab(tabs[2])} active={this.tab === tabs[2]}/>
			</TabMenu>
*/}
			{this.tab === tabs[0] && <GraphTotal {...props} />}
			{this.tab === tabs[1] && tabs[1]}
			{this.tab === tabs[2] && tabs[2]}
		</Panel>;
	}
	
}
