import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteMenu } from 'components';
import { MENU_ITEMS } from './constants';
import { Report } from './Report';
import { cloneDeep } from 'lodash';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

export class Reports extends React.Component {
	constructor(props) {
		super(props);
		this.menuItems = cloneDeep(MENU_ITEMS);
		this.routes = [];
		this.path = props.match.path;

		this.menuItems.forEach((item, index) => {
			if (Array.isArray(item.items)) {
				const childItems = item.items.map((code) => ({
					path: `${this.path}/${code?.toLowerCase()}`,
					title: t('report.' + code),
					code,
				}));
				this.routes.push(...childItems);
				this.menuItems[index].items = childItems;
			}
		});
		if (props.match.path === props.location.pathname) {
			store.route.push({ path: this.routes[0].path });
		}
	}
	render() {
		const { menuItems, routes } = this;

		return (
			<div className='reports-page'>
				<h2>{t('report.plural')}</h2>
				<div>
					<RouteMenu items={menuItems} />
					<Switch>
						{routes.map(({ path, code }) => (
							<Route key={code} path={path} render={() => <Report name={code} />} />
						))}
					</Switch>
				</div>
			</div>
		);
	}
}
