import ReactDOM from 'react-dom';
import { action, observable, toJS } from 'mobx';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import Feature from 'ol/Feature';
import { transform, toLonLat, fromLonLat, get as getProj } from 'ol/proj';
import Overlay from 'ol/Overlay';

import { geoJSON, getFeatureCenter } from '@smartplatform/map/client';
import { heatPointsLayerCustomMVT } from 'components/common-layers/heat-points';
import { CountiesLayer, WeatherLayer, WeatherTip, WindLayer, GenShtabLayer, CountriesLayer, DzzLayer, ModelsLayer }  from 'components';
import ForestriesLayer from './map/forestries';
import QuartersLayer from './map/quarters';
import FiresLayer from './map/fires';
import store from 'client/store';
import initLocal from './localStore';

export const PARAMS = {
	zoom: 5,
	// zoom: 16,
	minZoom: 1,
	maxZoom: 20,
	center: [ 128.74929854276843, 63.27207348590292 ], // Якутия
	// center: [ 129.7470320165464, 62.025968425204695 ], // Якутск
	// center: [ 122.02786514166061, 64.98766751806718 ], // test
};

class FireStore {

	@observable map = null;
	@observable isInitialized = false;
	@observable date;
	@observable heatPointsStartDate;
	@observable heatPointsEndDate;
	@observable popup = null;
	@observable weatherImage = null;
	@observable mapInitialized = false;

	minBrightness = 200;
	maxBrightness = 510;
	canShowPopup = true;
	featuresGetters = [];
	silamMin = 0;
	silamMax = 0;

	countiesLayer;
	quartersLayer;
	firesLayer;
	weatherLayer;
	heatPointsLayer;
	windLayer;

	init = async (options) => {
		initLocal();
		this.layersSettings = store.local.fires.layers;

		try {
			const data = await store.model.FireData.getData();
			// console.log('[fires]initialData', data);
			this.minBrightness = data.minBrightness;
			this.maxBrightness = data.maxBrightness;
		}
		catch (e) {
			console.warn(e);
		}
		this.brightnessRange = this.maxBrightness - this.minBrightness;

		this.date = store.local.fires.startDate ? new Date(store.local.fires.startDate) : new Date();
		const hour = ((this.date.getHours() / 3) | 0) * 3;
		this.date.setHours(hour);
		this.date.setMinutes(0);
		this.date.setSeconds(0);
		this.date.setMilliseconds(0);

		// this.heatPointsEndDate = endOfDay(new Date(2021, 7, 25));
		this.heatPointsEndDate = endOfDay(new Date());
		this.heatPointsStartDate = startOfDay(subDays(this.heatPointsEndDate, 2));
		if (store.local.fires.startDate) {
			this.heatPointsEndDate = endOfDay(new Date(store.local.fires.startDate));
			this.heatPointsStartDate = startOfDay(subDays(new Date(store.local.fires.startDate), 6));
		}

		this.zoom = PARAMS.zoom;
		this.isInitialized = true;
	};

	onMapInit = async mapStore => {
		this.mapStore = mapStore;
		this.map = mapStore.map;

		// this.mapStore.on('click', this.onClick);
		this.map.on('pointermove', this.onPointerMove);

		this.countiesLayer = new CountiesLayer(mapStore, this.layersSettings.counties);
		this.countriesLayer = new CountriesLayer(mapStore, this.layersSettings.countries);
		this.dzzLayer = new DzzLayer(mapStore, this.layersSettings.dzz);
		this.genShtabLayer = new GenShtabLayer(mapStore, this.layersSettings.genShtab);
		this.quartersLayer = new QuartersLayer(mapStore, this.layersSettings.forestryQuarters);
		this.firesLayer = new FiresLayer(mapStore, this.layersSettings.fires);
		this.weatherLayer = new WeatherLayer(mapStore, this.layersSettings.weather, {
			onLoad: this.onWebglImageLoad,
			onSetLayer: this.onSetWeatherLayer,
		});
		this.heatPointsLayer = new heatPointsLayerCustomMVT(mapStore, this.layersSettings.heatPoints);
		this.windLayer = new WindLayer(mapStore, this.layersSettings.wind);
		this.modelsLayer = new ModelsLayer(mapStore, this.layersSettings.models);
		this.forestriesLayer = new ForestriesLayer(mapStore, this.layersSettings.forestries);

		store.fireCard = this;

		this.firesLayer.init();
		this.heatPointsLayer.init({ start: this.heatPointsStartDate, end: this.heatPointsEndDate });
		this.weatherLayer.init();
		this.weatherTip = new WeatherTip(this);

		if (!store.isPublic) {
			this.countiesLayer.init();
			this.countriesLayer.init();
			this.dzzLayer.init();
			this.genShtabLayer.init();
			this.quartersLayer.init();
			this.windLayer.init();
			this.modelsLayer.init();
			this.forestriesLayer.init();
		}

		mapStore.on('popupclose', this.onPopupClose);
		mapStore.on('cleanup', this.destroy);
		mapStore.on('mouseleave', this.onMouseLeave);
		this.mapInitialized = true;
	}

	onTileSourceChange = layerCode => {
		console.log('onTileSourceChange', layerCode);
		store.local.fires.tileSource = layerCode;
		store.local.save();
	};

	changeDate = date => {
		// this.date = startOfDay(date);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		this.weatherImage = null;
		this.date = date;
		this.popup = null;
		this.firesLayer.load();
		this.weatherLayer.update();
		this.windLayer.update();
		this.modelsLayer.load();
	};

	onScaleMount = el => {
		this.scaleElement = el;
	};

	setHeatPointsRegion = (value) => {
		store.local.fires.layers.heatPoints.region = value ? null : 1;
		store.local.save();
		this.heatPointsLayer.update();
	};

	setHeatPointsVersion = (useMvt) => {
		store.local.fires.layers.heatPoints.useMVT = useMvt;
		store.local.save();
		this.heatPointsLayer.remove();
		this.createHeatPointsLayer();
		this.heatPointsLayer.init(this.mapStore);
		this.heatPointsLayer.update();
	};

	createHeatPointsLayer = () => {
		this.heatPointsLayer = new heatPointsLayerCustomMVT(this, store.local.fires.layers);
		// this.heatPointsLayer = store.local.useMVT ? new heatPointsLayerCustomMVT(this) : new HeatPointsLayerCustomWebgl(this);
	};
	
	onSetWeatherLayer = async (weatherLayerConfig) => {
		this.weatherLayerConfig = weatherLayerConfig;
		let { type, code, colors, min, max, measure, convert } = weatherLayerConfig;
		// console.log('weatherLayerConfig', weatherLayerConfig);
		
		if (type === 'silam') {
			const metaUrl = `/api/silammeta?date=${this.date.toISOString()}&layer=${code}`;
			console.log('metaUrl', metaUrl);
			try {
				const res = await fetch(metaUrl);
				const json = await res.json();
				console.log('json', json);
				this.silamMin = min = json.min;
				this.silamMax = max = json.max;
			}
			catch (e) {
				console.warn('/api/silammeta', e);
			}
		}
		
		if (colors && this.scaleElement) {
			this.scaleElement.innerHTML = '';
			this.scaleElement.appendChild(this.getColorRamp(colors));
			if (min !== undefined && max !== undefined) {
				const minDiv = document.createElement('div');
				minDiv.className = 'min-value';
				minDiv.innerHTML = Math.floor(convert ? convert(min) : min) + (measure ? ' ' + measure : '');
				this.scaleElement.appendChild(minDiv);

				const maxDiv = document.createElement('div');
				maxDiv.className = 'max-value';
				maxDiv.innerHTML = Math.floor(convert ? convert(max) : max) + (measure ? ' ' + measure : '');
				this.scaleElement.appendChild(maxDiv);
			}
		}
	};

	@action prevDate = async (e) => {
		e.stopPropagation();
		const date = subDays(this.date, 1);
		this.changeDate(date);
	};

	@action nextDate = async (e) => {
		e.stopPropagation();
		const date = addDays(this.date, 1);
		this.changeDate(date);
	};

	toggleWeatherLayer = (value) => {
		const weather = store.local.fires.layers.weather;
		weather.show = value;
		store.local.save();
		this.weatherLayer.setVisible();
		if (value) this.weatherLayer.update();
	};

	addFeatureGetter = (func) => {
		this.featuresGetters.push(func);
	};

	getFeaturesAtPixel = async (e) => {
		const features = await this.map.getFeaturesAtPixel(e.pixel);
		const otherFeatures = [];
		for (let getter of this.featuresGetters) {
			const feature = await getter(e);
			if (feature) otherFeatures.push(feature);
		}
		return [...features, ...otherFeatures];
	};

	onClick = async e => {
		// console.log('fires: onClick', e, e.originalEvent.stopPropagation);
		if (e.originalEvent.ctrlKey) return;
		const coords = this.map.getCoordinateFromPixel(e.pixel);
		const [ lon, lat ] = transform(coords, 'EPSG:3857', 'EPSG:4326');

		const features = await this.getFeaturesAtPixel(e);

		if (features.length === 0) {
			this.popup = null;
			return;
		}

		const popup = { lonLat: [lon, lat], offset: 0, objects: [] };
		let initial = true;
		const sameModels = {};

		for (let feature of features) {
			const data = feature.getProperties();
			// console.log('> fires: data', data);
			if (data.record) {
				const modelName = data.record.MODEL.INFO.name;
				if (!sameModels[modelName]) {
					popup.objects.push(data);
					sameModels[modelName] = true;
				}
				if (initial) {
					// console.log('pin popup to', data.record);
					if (data.record.geo.type === 'Point') {
						popup.lonLat = data.record.geo.coordinates;
					}
					else {
						const geometry = geoJSON.readGeometry(data.record.geo);
						const feature = new Feature({ geometry, ...data });
						popup.lonLat = toLonLat(getFeatureCenter(feature));
					}
					popup.offset = 20;
					if (modelName === 'FireData') {
						popup.width = 300;
						popup.height = 220;
						popup.offset = 3;
					}
				}
				initial = false;
			}
			else if (feature.constructor.name) {
				if (['viewforestryquarter', 'forestryquarter'].includes(data.layer)) {
					const record = new store.model.ForestryQuarter({ id: data.id });
					console.log('>>>', feature.constructor.name, record);
					popup.objects.push({ record });
				}
				if (data.layer === 'county' && features.length === 1) {
					this.popup = null;
					return;
				}
			}
		}

		this.popup = popup;
	};

	onWebglImageLoad = (image, colorRamp) => {
		console.log('>>> onWebglImageLoad (global)', image);
		this.weatherImage = image;
		this.colorRamp = colorRamp;

		setTimeout(() => {
			const container = this.firesLayer.layer.getRenderer().container;
			if (container) {
				container.style.zIndex = 1;
			}
		}, 0);
	};

	onMouseLeave = () => {
		if (this.weatherTip) this.weatherTip.hide();
	};

	onPointerMove = async (e) => {
		if (e.dragging || this.drawing) {
			if (this.weatherTip) this.weatherTip.hide();
			return;
		}

		const features = await this.map.getFeaturesAtPixel(e.pixel);

		if (store.local.fires.layers.weather.show) {
			if (this.weatherImage && this.weatherLayerConfig) {
				const coords = this.map.getCoordinateFromPixel([e.pixel[0], e.pixel[1] - 20]);
				this.weatherTip.update(coords, this.weatherImage, this.weatherLayerConfig, this.colorRamp);
			}
		}
		else {
			if (this.weatherTip) this.weatherTip.hide();
		}
	};

	getColorRamp = (colors) => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		
		const width = 300;
		const gradient = ctx.createLinearGradient(0, 0, width, 20);

		canvas.width = width;
		canvas.height = 20;
		
		if (Array.isArray(colors)) {
			for (let i = 0; i < colors.length; i++) {
				gradient.addColorStop(i / colors.length, colors[i]);
				ctx.fillStyle = gradient;
				ctx.fillRect(0, 0, width, 20);
			}
		}
		else {
			for (const breakpoint of colors.scale) {
				const [ value, pos, [ r, g, b, a ]] = breakpoint;
				gradient.addColorStop(pos, `rgba(${r},${g},${b},${a / 255})`);
			}
			ctx.fillStyle = gradient;
			ctx.fillRect(0, 0, width, 20);
			
/*
			ctx.font = '13px Roboto, sans-serif';
			ctx.fillStyle = '#fff';
			ctx.textBaseline = 'middle';
			for (let i = 0; i < colors.scale.length; i++) {
				const breakpoint = colors.scale[i];
				const [ value, pos ] = breakpoint;
				ctx.textAlign = 'center';
				if (i === 0) ctx.textAlign = 'left';
				if (i === colors.scale.length - 1) ctx.textAlign = 'right';
				let offset = 0;
				if (i === 0) offset = 5;
				if (i === colors.scale.length - 1) offset = -5;
				console.log('value', value);
				ctx.fillText(value, Math.round(pos * width) + offset, 11);
			}
*/
		}

		return canvas;
	}

	onPopupClose = () => this.popup = null;

	destroy = () => {
		// деинициализация
		this.mapStore.off('cleanup', this.destroy);
		this.mapStore.off('popupclose', this.onPopupClose);
	};

	onZoomStart = () => {
		// console.log('onZoomStart');
	};

	onZoomEnd = () => {
		this.zoom = this.map.getZoom();
		// console.log('onZoomEnd', this.zoom);
	};

	log = (...args) => console.log('%c[fires]', 'color: #c80', ...args);

	switchFilters = (maximize) => {
		store.local.fires.filtersMaximized = maximize !== undefined ? maximize : !store.local.fires.filtersMaximized;
		store.local.save();
	};

}

export default new FireStore();
