import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ModuleWrapper } from 'components';
import Dashboard from './Dashboard';
import Sync from './Sync';
import module from './index';
import errandsStore from './errandsStore';
import './style.scss';

@observer
export default class Appeals extends React.Component {
	constructor(props) {
		super(props);
		if (!errandsStore.isInitialized) errandsStore.init();
	}

	render() {
		if (!errandsStore.isInitialized) return null;
		const { path } = this.props.match;

		return (
			<ModuleWrapper className='errands-root' module={module} path={path}>
				<Switch>
					<Route path='/errands' exact component={Dashboard} />
					<Route path='/errands/sync' component={Sync} />
				</Switch>
			</ModuleWrapper>
		);
	}
}
