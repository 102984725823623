import React from 'react';
import { inject, observer } from 'mobx-react';
import { Toolbar as BaseToolbar } from 'components';
import { RecordSelect } from '@smartplatform/ui';
import store from 'client/store';

@inject('store')
@observer
export class Toolbar extends React.Component {
	render() {
		const { create, onSearch, onShowFiltersChange, showFilters, search, onSelectByStoreHouse, selectedStorehouse, storehouses } = this.props.store;
		const { Storehouse } = store.model;
		const storehouse = storehouses.find((item) => item.id === selectedStorehouse);

		return (
			<div className='storage-control-toolbar'>
				<BaseToolbar>
					<BaseToolbar.AddButton onClick={create} />
					<BaseToolbar.SearchIconInput value={search} onChange={onSearch} />
					<BaseToolbar.FilterButton showFilters={showFilters} onClick={onShowFiltersChange} />
					<RecordSelect width={180} value={storehouse} model={Storehouse} property='name' onChange={onSelectByStoreHouse} />
				</BaseToolbar>
			</div>
		);
	}
}
