import { observable } from 'mobx';
import store from 'client/store';
import { MODEL_NAME, RELATIONS, REQUEST_INCLUDE } from './constants';
import { isEqual } from 'client/tools';

export default class RequestStore {
	@observable record = null;
	@observable isLoading = true;
	@observable members = [];
	@observable supplies = [];
	@observable categories = [];
	@observable tracks = [];
	@observable tracksStorehouse = null;
	@observable ladingBills = [];
	@observable comments = [];
	@observable isChanged = false;
	@observable showChangedPopup = false;
	id;
	originalValues = {};
	changes = {};
	path;
	returnToPath;
	code;

	get isNew() {
		return !this.id;
	}

	init = async () => {
		await this.fetchRecord();
		await this.fetchCategories();
		if (this.id) {
			await this.fetchComments();
		}
		this.isLoading = false;
	};

	// fetches
	fetchCategories = async () => {
		const isShedule = this.record.type?.code === 'SCHEDULE';
		const where = isShedule ? { shortName: { nilike: `%СЗПТ%` } } : { shortName: { ilike: `%СЗПТ%` } };
		this.categories = await store.model.SupplyCategory.find({
			include: [{ children: ['parent'] }],
			fields: ['id', 'shortName'],
			where: { parentId: null, ...where },
		});
	};

	fetchRecord = async () => {
		if (this.id) {
			this.record = await store.model[MODEL_NAME].findById(this.id, REQUEST_INCLUDE);
			RELATIONS.forEach((relationName) => (this[relationName] = this.record[relationName]()));
			this.getOriginalValues();
		} else await this.createNewRecord();
	};
	fetchComments = async () => {
		const [comments, logs] = await Promise.all([
			this.record.comments.find({
				include: [{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } }],
				order: 'createdAt asc',
			}),
			this.record.logs.find({
				where: { memberStatusId: { neq: null } },
				include: [
					{ relation: 'memberStatus', scope: { fields: ['id', 'color', 'name', 'code'] } },
					{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'member', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
				],
				order: 'createdAt asc',
			}),
		]);

		const items = [
			...logs.map((record, index) => ({ type: 'status', record, index })),
			...comments.map((record, index) => ({ type: 'comment', record, index })),
		];
		this.comments.replace(items.sort((a, b) => new Date(a.record.createdAt) - new Date(b.record.createdAt)));
	};

	getOriginalValues = () => {
		const { id, ownerId, createdAt, updatedAt, ...properties } = store.model[MODEL_NAME].PROPERTIES;
		Object.keys(properties).forEach((property) => (this.originalValues[property] = this.record[property] ?? null));
		RELATIONS.forEach((relation) => {
			this.originalValues[relation] = [];
			this[relation].forEach((record) => this.originalValues[relation].push({ ...record }));
		});
	};

	// record actions
	onChange = (prop) => async (value) => {
		if (this.id && this.originalValues.hasOwnProperty(prop)) {
			const model = store.model[MODEL_NAME];
			const isModelProp = Boolean(model.PROPERTIES[prop]);
			if (isModelProp) {
				const originalValue = this.originalValues[prop];
				const recordValue = this.record[prop];
				const propertyType = store.model[MODEL_NAME].PROPERTIES[prop].type;

				if (isEqual({ propertyType, a: originalValue, b: recordValue })) {
					delete this.changes[prop];
				} else {
					this.changes[prop] = recordValue;
				}
			} else {
				const isModelRelation = Boolean(model.RELATIONS[prop]);
				if (isModelRelation) {
					if (!this.changes[prop]) this.changes[prop] = [];
					const originalRecord = this.originalValues[prop].find(({ id }) => id === value.id);
					if (originalRecord) {
						// если меняем существующую запись
						const relationModel = model.RELATIONS[prop].model;
						const { id, ownerId, createdAt, updatedAt, ...relationProperties } = store.model[relationModel].PROPERTIES;
						const isChangedRecord = Boolean(Object.keys(relationProperties).find((prop) => originalRecord[prop] !== value[prop]));
						if (isChangedRecord) {
							this.changes[prop].push(value.id);
						} else {
							this.changes[prop] = this.changes[prop].filter((id) => id !== value.id);
						}
					} else {
						this.changes[prop].push(value.id);
					}
					if (!this.changes[prop].length) delete this.changes[prop];
				}
			}

			this.isChanged = Object.keys(this.changes).length > 0;
		}
		if (prop === 'typeId') await this.fetchCategories();
	};
	onRelationRecordRemove = (prop, value) => {
		if (!this.changes[prop]) this.changes[prop] = [];
		const originalRecord = this.originalValues[prop].find(({ id }) => id === value.id);
		if (originalRecord) {
			// если удаляем реалную запись
			this.changes[prop].push(value.id);
		} else {
			// если удаляем добавляемую запись, то убираем её из changes
			const changedRecord = this.changes[prop].findIndex((id) => id === value.id);
			this.changes[prop].splice(changedRecord, 1);
		}

		if (!this.changes[prop].length) delete this.changes[prop];
		this.isChanged = Object.keys(this.changes).length > 0;
	};

	createNewRecord = async () => {
		const defaultStatus = await store.model.RequestStatus.find({ where: { default: true } });
		const defaultType = await store.model.RequestType.find({ where: { code: { ilike: `%${this.code}%` } } });
		this.record = new store.model[MODEL_NAME]({
			date: new Date(),
			ownerId: store.model.user.id,
			organizationId: store.model.user.organizationId,
			statusId: defaultStatus[0]?.id,
			status: defaultStatus[0],
			typeId: defaultType[0]?.id,
			type: defaultType[0],
		});
	};

	moveSupplies = async () => {
		for (const supply of this.supplies) {
			const movement = new store.model.ReserveMovement({
				date: new Date(),
				reserveId: supply.reserveId,
				storehouseId: supply.reserve.storehouseId,
				quantity: -supply.quantity,
			});
			await movement.save();
		}
	};

	beforeSave = async () => {
		if (this.isNew) {
			this.record.typeOnCreate = this.code;
		}
		if (this.record.status.code === 'agreed' && this.record.type.code === 'BASIC') {
			this.record.type = await store.model.RequestType.findOne({ where: { code: 'SCHEDULE' } });

			await this.moveSupplies();
		}

		if (this.tracksStorehouse) {
			for (const supply of this.supplies) {
				let reserve = await store.model.Reserve.find({
					where: { nomenclatureId: supply.nomenclatureId, categoryId: supply.categoryId, storehouseId: this.tracksStorehouse.id },
				});
				reserve = reserve[0];
				if (!reserve) {
					reserve = new store.model.Reserve({
						startQuantity: 0,
						nomenclatureId: supply.nomenclatureId,
						categoryId: supply.categoryId,
						storehouseId: this.tracksStorehouse.id,
					});
					await reserve.save();
				}

				const movement = new store.model.ReserveMovement({
					date: new Date(),
					reserveId: reserve.id,
					storehouseId: supply.reserve.storehouseId,
					quantity: supply.quantity,
				});
				await movement.save();
			}
		}
	};

	onSave = async () => {
		let path = this.returnToPath;
		if (this.isNew) {
			this.id = this.record.id;
			path += `/${this.id}`;
			this.dropChanges();
			await this.init();
			store.route.push({ path });
		} else {
			const promises = [];
			for (const arr of [this.supplies, this.ladingBills, this.tracks]) {
				for (const rec of arr) {
					promises.push(rec.save());
				}
			}
			await Promise.all(promises);
			this.init();
			this.dropChanges();
			if (this.record.type.code === 'SCHEDULE') {
				store.route.push({ path });
			}
		}
	};
	dropChanges = () => {
		this.isChanged = false;
		this.changes = {};
	};

	onClean = () => {
		this.members = [];
		this.record.attachments = [];
	};

	onDelete = async () => {
		await this.record.delete();
		this.back();
	};

	back = () => store.route.push({ path: this.returnToPath });

	onLeave = ({ path }) => {
		if (this.isChanged) {
			this.showChangedPopup = true;
			this.newPath = path;
			return false;
		}
		this.members = this.members.filter(({ userId }) => userId);
		return true;
	};
}
