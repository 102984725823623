import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Loader } from '@smartplatform/ui';
import store from 'client/store';
import { shortenAddressName } from 'client/tools';
import { Panel } from 'components';
import { STATUS_CONFIRMED, STATUS_SELFISOLATED, STATUS_CONTACTED } from '../../store';
import t from 'i18n';

import './style.scss';

@observer
export default class Address extends React.Component {

	@observable address = null;
	@observable isLoading = true;
	@observable error = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.address.id !== prevProps.address.id) {
			this.init();
		}
	}

	init = async () => {
		this.isLoading = true;
		this.error = null;
		try {
			this.address = await store.model.ViewAddress.findById(this.props.address.id, {
				include: [
					{
						relation: 'cases',
						scope: {
							order: 'statusId, isolationId',
						}
					},
				],
			});
		}
		catch (e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};

	render() {
		let header = t('loading');
		let content = <Loader />;

		if (!this.isLoading) {
			if (this.error) {
				header = t('error');
				content = this.error;
			}
			else {
				header = t('address.title');
				content = <>
					<div className="address-name">{shortenAddressName(this.address.name)}</div>
					<div className="cases">
						{this.address.cases()
							.filter(record => {
								if (store.covid.type === STATUS_CONFIRMED) {
									return record.statusId === 2 && record.isolationId !== 1;
								}
								else if (store.covid.type === STATUS_SELFISOLATED) {
									return record.isolationId === 1 && !record.contacted && (record.statusId === null || record.statusId !== 2);
								}
								else if (store.covid.type === STATUS_CONTACTED) {
									return record.contacted;
								}
								return false;
							})
							.map(record => <Link key={record.id} to={`/covid/cases/${record.id}`}>
								<span className="id">#{record.id}</span> {record.phone}
								{/*{(record.lastName || '') + ' ' + (record.firstName || '') + ' ' + (record.middleName || '')}*/}
							</Link>)
						}
					</div>
				</>;
			}
		}

		return <Panel right={40} top={0} width={340} bottom={300} left="auto" height="auto" className="block selected-address" header={header}>
			{content}
		</Panel>;
	}

}
