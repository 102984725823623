import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import { shortenAddressName } from 'client/tools';
import Objects from './Objects';
import FireDepIcon from '!!svg-react-loader!../../modules/techfires/icons/fire.svg';

const ADDRESS_MODELS = ['Address', 'ViewTechFireAddress'];

@observer
export default class AddressInfo extends React.Component {

	static propTypes = {
		address: function(props) {
			if (!props.address) {
				return new Error(`AddressInfo: missing "address" prop`);
			}
			if (!ADDRESS_MODELS.includes(props.address.constructor.name)) {
				return new Error(`AddressInfo: "address" prop must be one of types: ${ADDRESS_MODELS.join(', ')}`);
			}
		},
		value: PropTypes.object,
		onChange: PropTypes.func,
	};

	@observable fireDep = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { address } = this.props;
		if (address.fireDepId) {
			this.fireDep = await store.model.FireDep.findById(address.fireDepId, { fields: ['id', 'name'] });
		}
		else {
			const res = await address.getFireDep();
			if (res.length > 0) this.fireDep = res[0];
		}
	};

	onObjectSelect = async (object) => {
		const fullObject = await store.model.TechFireAddress.findById(object.id, {
			include: [
				{ relation: 'fireDep', scope: { fields: ['id', 'name'] } },
				{ relation: 'address', scope: { fields: ['id', 'name', 'geo', 'typeid'] } },
				{ relation: 'rank', scope: { fields: ['id', 'name'] } },
			],
		});
		this.props.onChange && this.props.onChange(fullObject);
	};

	render() {
		const { address, value } = this.props;
		if (!address) return null;

		return <div className="address-info">
			<div className="hdr">
				<div className="name"><FontAwesomeIcon icon={faHome}/> {shortenAddressName(address.name)}</div>
				<div className="info"><FireDepIcon width={16} height={16} /> {this.fireDep ? this.fireDep.name : '-'}</div>
			</div>
			<Objects address={address} value={value} onObjectSelect={this.onObjectSelect} />
		</div>;
	}
}
