import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Field, Popup, Row, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n/index';
import { Form } from 'components';

@inject('store')
@observer
export default class EditPilotPopup extends React.Component {
	@observable pilot = null;
	@observable raid = null;
	@observable isNew;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.init();
	}

	init = async () => {
		this.raid = this.props.raid;
		this.pilot = this.props.pilot;
		// сохраняем оригинальную запись
		this.selectedRecordOriginal = { ...this.pilot };
		this.isNew = !this.pilot;
		if (this.isNew) {
			this.pilot = new store.model.ObserverPilot();
		}
	};

	revertRecord = async () => {
		if (!this.isNew) {
			const { staffId: originalId } = this.selectedRecordOriginal;
			const { staffId: selectedId } = this.pilot;
			// перезаписываем объект, если id было изменено
			if (originalId !== selectedId) {
				this.pilot.staff = await store.model.Staff.findById(originalId, { fields: ['id', 'fullName'] });
			}
			// получаем все поля модели ObserverPilot, проходим по каждому полю и меняем значение на оригинальное значение (объекты не перезаписываются)
			const modelKeys = Object.keys(store.model['ObserverPilot'].PROPERTIES);
			for (let key of modelKeys) {
				this.pilot[key] = this.selectedRecordOriginal[key];
			}
		}
	};

	onClosePopup = async () => {
		await this.revertRecord();
		this.props.onClose();
	};

	beforeSave = () => {
		this.store.addHasMany('pilots', this.pilot);
		this.props.onClose();
		// сохраняем только новые реляции, редактируемые сохраним только при сохранении записи
		if (!this.isNew) return false;
	};
	beforeDelete = () => {
		this.store.removeHasMany('pilots', this.pilot);
		this.props.onClose();
		return false;
	};

	render() {
		const title = this.pilot.id ? t('editRecord') : t('addRecord');

		return (
			<Popup width={400} className='edit-pilot-popup fires-popup' onClose={this.onClosePopup}>
				<h1>{title}</h1>
				<Form
					beforeSave={this.beforeSave}
					beforeDelete={this.beforeDelete}
					disabled={this.props.store.disabled}
					noDelete={this.isNew}
					record={this.pilot}
					disableSave={!this.pilot.staff}
					stay
					className='edit-pilot-form'
					onCancel={this.onClosePopup}
				>
					<Field relation='staff' isRequired property='fullName' label={t('staff.fullName')}>
						<RecordSelect noTotal maxItems={10} itemsPerPage={1000} isRequired />
					</Field>
					<Row>
						<Field property='intern' label={t('observerPilot.intern')} />
						<Field property='inspector' label={t('observerPilot.inspector')} />
					</Row>
				</Form>
			</Popup>
		);
	}
}
