import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import {Input} from "@smartplatform/ui";

@inject('card') @observer
export default class ExtinguishingCosts extends React.Component {
	render() {
		const { card } = this.props;
		const { fire } = card;
		const className = 'form-field';
		return <div style={{ marginTop: '20px' }}>
			<h2>{t('fire.extinguishingCosts')}</h2>
			{[
				'extinguishingCostsSubject',
				'rentBC',
				'totalCost'
			]
				.map(label =>
					<React.Fragment  key={label}>
						<div className={className}>
							<label>{t(`fire.${label}`)}</label>
							<Input value={fire[label]} onChange={card.onChange(label)}/>
						</div>
					</React.Fragment>
				)}
		</div>
	}
}
