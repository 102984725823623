
import React from 'react';
import FullReport from './full/FullReport';
import OperationalReport from './operational/OperationalReport';
import t from 'i18n';

export const DASHBOARD_SECTIONS = [
    { label: t('fireDashboard.operationalReport'), component: <OperationalReport /> },
    { label: t('fireDashboard.fullReport'), component: <FullReport /> },
].map((item, index) => ({ ...item, value: index + 1 }));
