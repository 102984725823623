import { Column, Loader, Pager, Popover, Table } from '@smartplatform/ui';
import t from 'i18n';
import { action, observable } from 'mobx';
import React from 'react';
import store from 'client/store'
import { observer } from 'mobx-react';

const PER_PAGE = 5;

@observer
export default class NotifyTemplate extends React.Component {
	@observable isInitialized = false;
	@observable page = 1;
	@observable records = [];
	@observable search = '';

	constructor(props) {
		super(props);
		this.init();

	}

	init = async () => {
		const search = this.search.length > 0 ? this.search.trim() : undefined;
		const where = search ? { template: { ilike: `%${search}%` } } : undefined;
		this.records = await store.model.NotifyTemplate.find({
			where,
			limit: PER_PAGE,
			skip: (this.page - 1) * PER_PAGE
		}
		);
		this.isInitialized = true;
	};

	onPageChange = page => {
		this.page = page;
		this.init();
	};
	getPopoverInstance = instance => this.popover = instance;

	onSelect = template => {
		if (this.popover) this.popover.close();
		this.props.onSelect(template);
	};

	@action
	onSearch = e => {
		this.search = e.target.value;
		this.page = 1;
		this.init();
	};

	render() {
		let content;
		if (!this.isInitialized) content = <div className="loading-popover"><Loader size={20} /></div>
		if (this.records.totalCount === 0) content = <div className='notify-popover-label'>{t('notifyTemplate.noTemplates')}</div>

		content = <div className='notify-template-popover'>
			<div className='filters row align-items-center justify-content-between'>
				<Pager current={this.page} onChange={this.onPageChange} itemsPerPage={PER_PAGE} totalCount={this.records.totalCount} />
				<input type="text" value={this.search} onChange={this.onSearch} placeholder={t('search')} />
			</div>

			<Table rows={this.records} onRowClick={this.onSelect} multiline>
				<Column property="template" label={t('notifyTemplate.template')} />
			</Table>
		</div>

		return <div className='notify-template' ><Popover
			trigger="click" position='bottom' className="notify-popover-label" getInstance={this.getPopoverInstance}
			content={content}>
			{t('notifyLog.pickTemplate')}
		</Popover>
		</div>
	}
};
