import React, { lazy } from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import store from 'client/store';
import module from './index';
import { Contragents, ModuleWrapper } from 'components';
import Map from './map';
import initLocal from './localStore';
import { Municipalities } from './municipalities';
import { DynamicLayers } from '@smartplatform/map/client';
import { Plans } from './plans';
import { DICTIONARIES_MODELS } from './constants';
const Dictionaries = lazy(() => import('components/dictionaries'));
const Audit = lazy(() => import('components/audit'));

@observer
export default class Root extends React.Component {
	constructor(props) {
		super(props);
		initLocal();
	}

	render() {
		const { path } = this.props.match;
		return (
			<ModuleWrapper className='emergency-module' module={module} path={path} redirectPath={`${path}/map`}>
				<Route path={`${path}/map`} component={Map} />
				<Route path={`${path}/layers`} render={(routeProps) => <DynamicLayers {...routeProps} modelStore={store.model} />} />
				<Route>
					<div className='emergency-page'>
						<Route path={`${path}/plans`} component={Plans} />
						<Route path={`${path}/municipalities`} component={Municipalities} />
						<Route path={`${path}/contragents`} component={Contragents} />
						<Route path={`${path}/dictionaries`} render={(props) => <Dictionaries models={DICTIONARIES_MODELS} {...props} />} />
						<Route path={`${path}/journal`} component={Audit}></Route>
					</div>
				</Route>
			</ModuleWrapper>
		);
	}
}
