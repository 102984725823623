import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { formatDate } from 'client/tools';
import { Button } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class HeatPointPopup extends React.Component {
	
	@observable record = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const { lon, lat, start, end, dataSourceId } = this.props.record;
		console.log('?', { lon, lat });
		this.record = await store.model.FireData.getClosestPoint({
			start,
			end,
			dataSourceId,
			lon,
			lat,
		});
		console.log('heatPoint', this.record);
	};
	
	createFire = () => {
		const { id } = this.record;
		store.route.push({ path: `/fires/registry/add?hp=${id}` });
		// store.route.push({ path: `/fires/registry/add?lon=${lon}&lat=${lat}` });
	};
	
	render() {
		if (!this.record) return null;
		
		return <div className="heat-point-popup">
			{/*<div><em>#{record.id}</em></div>*/}
			<div>{t('dateTime')}: <em>{formatDate(this.record.date, 'dd.MM.yyyy HH:mm')}</em></div>
			<div>{t('coordinates')}: <em>{this.record.geo.coordinates[0]}, {this.record.geo.coordinates[1]}</em></div>
			<div>{t('heatPoint.brightness')}: <em>{this.record.brightness}</em></div>
			<div>{t('heatPoint.dayNight')}: <em>{this.record.dayNight}</em></div>
			<div>{t('heatPoint.frp')}: <em>{this.record.frp}</em></div>
			<div>{t('heatPoint.scan')}: <em>{this.record.scan}</em></div>
			<div>{t('heatPoint.track')}: <em>{this.record.track}</em></div>
			<div className="mt-2">
				<Button variant="primary" size="sm" onClick={this.createFire}>{t('heatPoint.createFire')}</Button>
			</div>
		</div>;
	}
	
}
