import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form, NumberInput, Popup, GroupSelect, Row, RelationSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import { SCHEDULE } from 'client/constants';
import t from 'i18n';
import { observable } from 'mobx';
import { Actionbar } from 'components';
import { add, format, startOfMonth } from 'date-fns';
import { AddButtonSecondary } from 'components';
import { FormContext } from '@smartplatform/ui';
import { LOCALE } from 'client/tools';
import { SUPPLIES_INCLUDE } from '../constants';

const getPeriods = () => {
	const date = new Date();
	const startValue = startOfMonth(date);
	return new Array(6).fill(0).map((_, index) => {
		const value = add(startValue, { months: index });
		const label = format(value, 'LLL yy', { locale: LOCALE[store.local.language] });
		return { label, value };
	});
};

@inject('store')
@observer
export class SuppliesEditPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable plans = [];
	recIndex;
	static contextType = FormContext;

	constructor(props) {
		super(props);
		this.isNew = !this.props.selectedRecord;
		this.store = this.props.store;
		this.init();
		this.periods = getPeriods();
	}

	init = async () => {
		const selectedRecord = this.props.selectedRecord;
		if (!this.isNew) {
			this.record = new store.model.RequestSupply(selectedRecord);
			this.plans = await this.record.plans();
			this.recIndex = this.store.supplies.findIndex(({ id }) => id === this.record.id);
		} else {
			this.record = new store.model.RequestSupply({ requestId: this.store.record.id });
		}

		this.isLoading = false;
	};

	beforeSave = async (record) => {
		const category = await store.model.RequestCategory.find();
		const categoryId = category.find((cat) => {
			if (record.category.name === cat.name || record.category.parent.shortName === cat.name) {
				return true;
			}
		}).id;

		if (this.isNew) {
			const operInfo = new store.model.RequestOperationalInfo({
				date: new Date(),
				countyId: record.countyId,
				periodId: record.periodId,
				cityId: record.cityId,
				categoryId: categoryId,
				plan: record.quantity,
				typeOnCreate: this.store.record.typeOnCreate,
				organizationId: this.store.record.contractorId
			});
			await operInfo.save();
			this.record.operInfoId = operInfo.id;
		} else {
			const operInfo = await store.model.RequestOperationalInfo.findById(this.record.operInfoId);
			operInfo.countyId = record.countyId;
			operInfo.cityId = record.cityId;
			operInfo.periodId = record.periodId;
			operInfo.plan = record.quantity;
			operInfo.categoryId = categoryId;
			await operInfo.save();
			this.record.operInfoId = operInfo.id;
		}
	};

	onSave = async (record) => {
		const promises = [];
		this.plans.forEach((plan) => {
			plan.supplyId = record.id;
			promises.push(plan.save());
		});
		await Promise.all(promises);

		if (this.isNew) {
			if (this.store.record.status.code === 'agreed' || this.store.record.type.code === 'SCHEDULE') {
				const movement = new store.model.ReserveMovement({
					date: new Date(),
					reserveId: record.reserveId,
					storehouseId: record.reserve.storehouseId,
					quantity: -record.quantity,
				});
				await movement.save();
			}
			this.store.supplies.push(this.record);
			this.context.form.addHasMany('supplies', this.record);
		} else {
			await this.refetchListRecord();
		}

		this.props.onClose();
	};

	onDelete = async () => {
		if (this.record.operInfoId) {
			try {
				const operInfo = await store.model.RequestOperationalInfo.findById(this.record.operInfoId);
				await operInfo.delete();
			} catch (e) {}
		}

		await this.record.delete();
		this.store.supplies.splice(this.recIndex, 1);

		const movement = new store.model.ReserveMovement({
			date: new Date(),
			reserveId: this.record.reserveId,
			storehouseId: this.record.reserve.storehouseId,
			quantity: this.record.quantity,
		});
		await movement.save();

		this.props.onClose();
	};

	refetchListRecord = async () => {
		this.store.supplies[this.recIndex] = await store.model.RequestSupply.findById(this.record.id, {
			include: SUPPLIES_INCLUDE,
		});
	};

	onCancel = async () => {
		if (this.record.id) await this.refetchListRecord();
		this.props.onClose();
	};

	onChange = (prop) => (value) => {
		this.record[prop] = value;
		this.record[prop + 'Id'] = value?.id;
		if (prop === 'category') {
			this.record.nomenclature = null;
			this.record.nomenclatureId = null;
		}
		if (prop === 'county') {
			this.record.city = null;
			this.record.cityId = null;
		}
	};

	onAddPlan = () => {
		let date;
		if (!this.plans.length) {
			date = this.periods[0].value;
		} else {
			const arr = this.periods.filter((period) => !this.plans.find((plan) => plan.date === period.value));
			date = arr[0]?.value;
		}
		this.plans.push(new store.model.RequestSupplyPlan({ date, count: 0, quantity: 0 }));
	};
	onPlanChange = (plan, value, field) => {
		if (field === 'date') {
			return (plan[field] = new Date(value));
		}
		plan[field] = value;
	};

	onClean = () => {
		this.plans = [];
	};

	render() {
		if (this.isLoading) return null;
		const { type } = this.props;
		const { categories } = this.props.store;
		const title = this.isNew ? t('addRecord') : t('editRecord');
		const disableSave = !!['quantity', 'category', 'nomenclature', 'measure', 'reserve', 'county', 'city'].find((prop) => !this.record[prop]);

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton disabled={disableSave} />
				<Actionbar.CancelButton back={this.onCancel} />
				{this.isNew ? <Actionbar.CleanButton onClean={this.onClean} /> : <Actionbar.DeleteButton onDelete={this.onDelete} noDelete />}
			</Actionbar>
		);

		return (
			<Popup onClose={this.onCancel} className='delivery-popup request-supply-popup'>
				<h2>{title}</h2>
				<Form record={this.record} stay onSave={this.onSave} beforeSave={this.beforeSave} controls={controls} noDelete noSave>
					<Row>
						<Field relation='county' property='name' label={t('county.title')} isRequired>
							<RelationSelect onChange={this.onChange('county')} />
						</Field>
					</Row>
					<Row>
						<Field
							relation='city'
							property='name'
							label={t('city.title')}
							filter={{ where: { countyId: this.record.countyId ?? null } }}
							disabled={!this.record.county?.id}
							isRequired
						>
							<RelationSelect onChange={this.onChange('city')} showValue={this.record.city ? this.record.city.name : '-'} />
						</Field>
					</Row>
					<Row>
						<Field
							relation='period'
							property='name'
							label={t('period.title')}
						>
							<RelationSelect onChange={this.onChange('period')} />
						</Field>
					</Row>
					<Row>
						<Field label={t('category')} className='category-select' isRequired disabled={!this.isNew}>
							<GroupSelect
								onChange={this.onChange('category')}
								selected={this.record.category}
								computed={({ shortName }) => shortName}
								isRequired
								groups={categories.map(({ shortName, children }) => ({ title: shortName, items: children() }))}
							/>
						</Field>
						<Field
							relation='nomenclature'
							property='name'
							filter={{ where: { categoryId: this.record.categoryId ?? null } }}
							label={t('reserve.nomenclature')}
							isRequired
							disabled={!this.record.categoryId || !this.isNew}
						>
							<RelationSelect
								noTotal
								maxItems={10}
								itemsPerPage={1000}
								showValue={this.record.nomenclature ? this.record.nomenclature.name : '-'}
								isRequired
								onChange={this.onChange('nomenclature')}
							/>
						</Field>
					</Row>
					<Row>
						<Field relation='measure' property='name' label={t('measure')} disabled={!this.isNew} isRequired />
						<Field
							relation='reserve'
							isRequired
							filter={{ where: { nomenclatureId: this.record.nomenclature?.id }, include: ['storehouse'] }}
							computed={(r) => r.storehouse.name}
							disabled={!this.record.nomenclature?.id || !this.isNew}
							label={t('storehouse.title')}
						/>
						<Field property='quantity' label={t('quantity')} isRequired>
							<NumberInput positiveOnly />
						</Field>
					</Row>
					{type === SCHEDULE && (
						<div className='periods'>
							<div className='period-title'>{t('period.title')}</div>

							{this.plans.map((plan, index) => (
								<Row key={index}>
									<div className='form-field'>
										<label>{t('period.month')}</label>
										<Select
											noTotal
											maxItems={10}
											itemsPerPage={1000}
											items={this.periods}
											value={plan.date}
											onChange={(value) => this.onPlanChange(plan, value, 'date')}
											isRequired
											showValue={plan.date ? format(new Date(plan.date), 'LLL yy', { locale: LOCALE[store.local.language] }) : '-'}
											noSearch
										/>
									</div>
									<div className='form-field'>
										<label>{t('request.shipment')}</label>
										<NumberInput value={plan.count} positiveOnly integerOnly onChange={(value) => this.onPlanChange(plan, value, 'count')} />
									</div>
									<div className='form-field'>
										<label>{t('quantity')}</label>
										<NumberInput value={plan.quantity} positiveOnly onChange={(value) => this.onPlanChange(plan, value, 'quantity')} />
									</div>
								</Row>
							))}
							<AddButtonSecondary text={t('request.addPlan')} onClick={this.onAddPlan} disabled={this.plans.length === this.periods.length} />
						</div>
					)}
				</Form>
			</Popup>
		);
	}
}
