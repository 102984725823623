import React from 'react';
import { DatePicker, Button, Select } from '@smartplatform/ui';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MODES, UI_SIZE, YEARS } from './constants';
import { startOfDay } from 'date-fns';

@observer
export default class Filters extends React.Component {
	constructor(props) {
		super(props);
	}
	prev = () => this.store.go(-1);
	next = () => this.store.go(1);

	render() {
		const showSelectValue = true ? (
			<span className='select-list'>{t(`period.${MODES.QUARTER}`)}</span>
		) : (
			<span style={{ color: '#aaa' }}>{t('period.select')}</span>
		);

		return (
			<div className='techfires-filters'>
				{/* <DatePicker size={UI_SIZE} fontSize={14} years={YEARS} value='01.11.2022' />
				<DatePicker years={YEARS} size={UI_SIZE} fontSize={14} value='01.11.2022' /> */}
				{/* <Select
					noTotal
					maxItems={10}
					itemsPerPage={1000}
					value='04.11.2022'
					onChange={() => undefined}
					showValue={showSelectValue}
					items={Object.values(MODES).map((mode) => ({ label: t(`period.${mode}`), value: mode }))}
					position='bottomLeft'
					className='date-mode-select-techfires'
					isRequired
					noSearch
					size={UI_SIZE}
					fontSize={14}
					itemFontSize={14}
				/>
				<Select
					noTotal
					maxItems={10}
					itemsPerPage={1000}
					value='04.11.2022'
					onChange={() => undefined}
					showValue={showSelectValue}
					items={Object.values(MODES).map((mode) => ({ label: t(`period.${mode}`), value: mode }))}
					position='bottomLeft'
					className='date-mode-select-techfires'
					isRequired
					noSearch
					size={UI_SIZE}
					fontSize={14}
					itemFontSize={14}
				/> */}
				{/* <Button variant='primary'>
					{t('cleanFilters')}
					<FontAwesomeIcon icon={faTimes} />
				</Button> */}
				{/* <Button variant='primary' className='className'>
					{t('cleanFilters')}
				</Button> */}
			</div>
		);
	}
}
