import tinymce from 'tinymce/tinymce';
import './style.scss';

const uploadPromise = (file, record, property, progressHandler) =>
	new Promise((resolve, reject) => {
		record
			.uploadFile(property, file)
			.on('progress', (event) => {
				if (progressHandler) progressHandler(event);
			})
			.end((error, result) => {
				if (error) {
					reject(error);
				} else {
					resolve(result);
				}
			});
	});

export default function (filesUploadData) {
	const { record, relation, property, accept, onStart, onEnd, onError, fileHtml } = filesUploadData;

	tinymce.PluginManager.add('sp-files', function (editor, url) {
		let panel = document.createElement('div');
		panel.id = 'sp-upload';

		const setFileHtml = (url, name, count) => {
			if (fileHtml) return fileHtml(url, name, count);
			let html = `<a target="_blank" href="${url}">${name}</a>`;
			if (count > 1) {
				html = `<p>${html}</p>`;
			}
			return html;
		};

		const handleProgress = (event) => {
			console.log('handleProgress', event.percent);
			// this.setState({ progress: Math.round(event.percent) });
		};

		const uploadHandler = async (files) => {
			const store = record.STORE;
			const relationInfo = record.MODEL.INFO.relations[relation];
			const attachmnetModel = relationInfo ? store[relationInfo.model] : null;
			console.log('uploadHandler', files.length, record, relationInfo, attachmnetModel);
			if (attachmnetModel) {
				onUploadStart(files);
				let count = files.length;
				for (let file of files) {
					const attachmnet = new attachmnetModel({
						[property]: file.name,
						name: file.name,
					});
					await attachmnet.save();
					await uploadPromise(file, attachmnet, property, handleProgress);
					await record[relation].add(attachmnet.id);
					const url = attachmnet.downloadFile(property);
					editor.insertContent(setFileHtml(url, file.name, files.length), { format: 'raw' });
					panel.innerHTML = `Загрузка файлов... ${--count}`;
				}
				onUploadEnd(files);
			} else {
				throw new Error(`relation model not found: ${relation}`);
			}
		};

		const onUploadStart = (files) => {
			if (onStart) onStart(files);
			const editorContainer = editor.getContainer();
			editorContainer.appendChild(panel);
			panel.innerHTML = `Загрузка файлов... ${files.length}`;
			console.log('editorContainer', editorContainer);
		};

		const onUploadEnd = (files) => {
			if (onEnd) onEnd(files);
			const editorContainer = editor.getContainer();
			editorContainer.removeChild(panel);
		};

		const selectFiles = function () {
			const input = document.createElement('input');
			input.type = 'file';
			input.multiple = true;
			input.accept = accept;
			input.onchange = async (e) => {
				const files = e.target.files;
				try {
					await uploadHandler(files);
				} catch (e) {
					if (onError) onError(e);
				}
			};
			input.click();
		};

		editor.ui.registry.addButton('sp-files', {
			// text: 'Файл',
			tooltip: 'Загрузить файл и вставить в текст',
			icon: 'upload',
			onAction: async function () {
				selectFiles();
				// editorContainer.removeChild(panel);
			},
		});

		editor.ui.registry.addMenuItem('sp-files', {
			text: 'File',
			onAction: async function () {
				selectFiles();
			},
		});

		return {
			getMetadata: function () {
				return {
					name: 'Files upload plugin',
					url: 'http://exampleplugindocsurl.com',
				};
			},
		};
	});
}
