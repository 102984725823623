export const MODEL_NAME = 'Request';
export const REQUEST_PATH = '/delivery/tracking';
export const PERIODS = ['day', 'week', 'month', 'quarter', 'year'];
export const LOCAL_STORAGE_FIELDS = ['mode', 'statuses', 'type', 'period', 'startDate', 'endDate', 'showFilters', 'page', 'search', 'searchField'];

// include list
export const REQUESTS_INCLUDE = {
	include: [
		{ relation: 'type', scope: { fields: ['id', 'name'] } },
		{ relation: 'destinationCity', scope: { fields: ['id', 'name'] } },
		{ relation: 'departureCity', scope: { fields: ['id', 'name'] } },
		{
			relation: 'ladingBills',
			scope: {
				fields: ['id', 'number', 'date', 'senderId', 'consigneeId', 'payerId', 'totalItems', 'netWeight', 'grossWeight', 'totalReleased'],
				include: [
					{ relation: 'sender', scope: { fields: ['id', 'name', 'inn'] } },
					{ relation: 'consignee', scope: { fields: ['id', 'name', 'inn'] } },
					{ relation: 'payer', scope: { fields: ['id', 'name', 'inn'] } },
					{ relation: 'supplies' },
				],
			},
		},
		{ relation: 'logs', scope: { include: ['track'], order: 'createdAt asc' } },
		{
			relation: 'tracks',
			scope: {
				fields: ['id', 'date', 'statusId', 'cityId'],
				include: [
					{ relation: 'city', scope: { fields: ['id', 'name', 'geo'] } },
					{ relation: 'status', scope: { fields: ['id', 'name', 'color'] } },
				],
			},
		},
	],
};
