//* получаем сумму поля(sumField) одинаковых записей (по какому-то targetField) 
export const calcSameRecordsValues = (array, targetField, sumField) => {
    const finalArray = [];
    array.reduce((acc, next) => {
        const key = 'target_' + next[targetField];
        if (acc[key] === undefined) {
            finalArray.push(next);
            acc[key] = finalArray.length - 1;
        } else {
            const index = acc[key];
            const value = +finalArray[index][sumField] || 0;
            finalArray[index][sumField] = value + (+next[sumField] || 0)
        }
        return acc
    }, [])
    return finalArray;
}


// const mock = [
//     {
//         crewTypeId: 1,
//         name: "OOO1",
//     },
//     {
//         crewTypeId: 1,
//         name: "OOO1",
//         current: 50
//     },
//     {
//         crewTypeId: 2,
//         name: "OOO2",
//         current: 10
//     },
//     {
//         crewTypeId: 3,
//         name: "OOO3",
//         current: NaN
//     },
//     {
//         crewTypeId: 3,
//         name: "OOO3",
//         current: undefined
//     },
//     {
//         crewTypeId: 3,
//         name: "OOO3",
//         current: 100
//     },
//     {
//         crewTypeId: 3,
//         name: "OOO3",
//         current: 400
//     },
// ]

// console.log(calcSameRecordsValues(mock, 'crewTypeId', 'current'))