import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { FilterLayout } from '..';
import t from 'i18n';

@observer
export default class GenShtabFilter extends React.Component {

	static propTypes = {
		layer: PropTypes.object.isRequired,
	};

	render() {
		return <FilterLayout
			title={t('genShtab.title')}
			{...this.props}
		/>;
	}

}
