import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { Select, DatePicker, Checkbox, Button } from '@smartplatform/ui';
import { DASHBOARD_SECTIONS } from './sections';
import { downloadReport } from 'fires/helpers';
import ChevronIcon from '!!svg-react-loader!client/img/icons/shared/chevron-left.svg';
import { ExportButton } from 'components';
import { startOfDay } from 'date-fns';

@inject('dashboardStore')
@observer
export default class ActionsPanel extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.dashboardStore;
	}

	render() {
		const currentDate = new Date();
		const nextDisabled = this.store.date >= startOfDay(currentDate);

		return (
			<div className='action-panel'>
				<div className='actions'>
					<div className='date-select'>
						<Button variant='primary' onClick={this.store.prev}>
							<ChevronIcon className='left-chevron' />
						</Button>
						<DatePicker value={this.store.date} onChange={this.store.onDateChange} showClearButton={false} maxDate={currentDate} />
						<Button variant='primary' onClick={this.store.next} disabled={nextDisabled}>
							<ChevronIcon className='right-chevron' />
						</Button>
					</div>
					<div className='select-wrapper'>
						<Select
							noTotal
							maxItems={10}
							itemsPerPage={1000}
							width={250}
							items={DASHBOARD_SECTIONS.filter((i) => i.value !== this.store.section.value)}
							value={this.store.section}
							onChange={this.store.onSectionChange}
							isRequired
							noSearch
						/>
					</div>

					{this.store.section.value === 2 && (
						<>
							<ExportButton
								text={t('report.download')}
								disabled={!this.store.report}
								onClick={() =>
									downloadReport({
										id: 'report-table',
										name: this.store.report.filename,
									})
								}
							/>

							<Checkbox
								className='empty-sections ml-2'
								value={this.store.showEmptySections}
								onChange={this.store.onShowEmptySectionsChange}
								label='показать пустые секции(для демострации)'
							/>
						</>
					)}
				</div>
				<span className='description'>{t('fireDashboard.sectionDescription')}</span>
			</div>
		);
	}
}
