import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';
import FireCard from '../fire-card/FireCard';

const Registry = ({ match }) => {
	const { path } = match;

	return (
		<Switch>
			<Route path={`${path}`} exact component={List} />
			<Route path={`${path}/:id`} component={FireCard} />
		</Switch>
	);
};

export default Registry;
