import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form, Popup } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { Attachments } from 'client/components';
import { Actionbar } from 'components';
import { observable } from 'mobx';

@inject('store')
@observer
export class CommentPopup extends React.Component {
	@observable comment = null;

	constructor(props) {
		super(props);
		this.comment = this.props.comment;
		this.isNew = !this.comment.id;
		this.store = this.props.store;
	}

	onSave = async () => {
		await this.comment.save();
		this.props.onSave(this.comment);
	};

	onClose = async () => {
		if (this.comment.id) {
			try {
				this.comment = await store.model.Comment.findById(this.comment.id, { include: ['attachments'] });
			} catch (e) {
				console.log(e);
			}
		}
		this.props.onClose();
	};

	render() {
		let title = this.isNew ? t('addComment') : t('editComment');
		const isAdmin = !!store.model.roles.find((role) => role.name === 'admin');
		const isOwner = this.comment.ownerId ? store.model.user.id === this.comment.ownerId : true;
		const disableEdit = !isOwner && !isAdmin;
		if (disableEdit) title = null;
		const disableSave = !this.comment.text || disableEdit;
		const controls = (
			<Actionbar>
				<Actionbar.SaveButton disabled={disableSave} />
				<Actionbar.CancelButton back={this.onClose} />
			</Actionbar>
		);

		return (
			<Popup onClose={this.onClose} className='emergency-popup' width={500}>
				{title && <h2>{title}</h2>}
				<Form record={this.comment} stay onSave={this.onSave} controls={controls} noDelete noSave>
					<Field property='text' disabled={disableEdit} label={t('comments.title')}>
						<textarea style={{ marginBottom: 10 }} />
					</Field>
					<Attachments softDelete record={this.comment} canUpload={!disableEdit} withFormContext noDelete={disableEdit} />
				</Form>
			</Popup>
		);
	}
}
