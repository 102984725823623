import React from 'react';
import { observer, Provider } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import store from 'client/store';
import mapStore from '../store';
import './style.scss';
import { MineralsFilter, HeatPointsFilter, CountiesFilter, GenShtabFilter, WeatherFilter, WindFilter, MunicipalityFilter } from 'components';

@observer
export default class Filters extends React.Component {

	render() {
		const localSettings = store.local.emergency;
		const settings = localSettings.layers;

		return localSettings.filtersMaximized ?
			<Provider settings={settings.layers}>
				<div className="emergency-filters-wrapper">
					<div className="minimize-btn" onClick={() => mapStore.switchFilters(false)}><FontAwesomeIcon icon={faFilter} /></div>
					<div className="emergency-filters">
						<MunicipalityFilter layer={mapStore.municipalityLayer} settings={settings.municipality} />
						{/*<Fires layer={mapStore.firesLayer} store='emergency'/>*/}
						<HeatPointsFilter layer={mapStore.heatPointsLayer} settings={settings.heatPoints} />
						<CountiesFilter layer={mapStore.countiesLayer} settings={settings.counties} />
						<GenShtabFilter layer={mapStore.genShtabLayer} settings={settings.genShtab} />
						<WeatherFilter layer={mapStore.weatherLayer} settings={settings.weather} />
						<WindFilter layer={mapStore.windLayer} settings={settings.wind} />
						<MineralsFilter layer={mapStore.mineralsLayer} settings={settings.minerals} />
					</div>
				</div>
			</Provider>
			:
			<div className="emergency-filters-minimized" onClick={() => mapStore.switchFilters(true)}>
				<FontAwesomeIcon icon={faFilter} />
			</div>;
	}
}
