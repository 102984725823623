import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { RecordSelect } from '@smartplatform/ui';
import { Panel } from 'components';
import Root from './Root';
import Category from './Category';
import store from 'client/store';
import appealsStore from '../store';
import './style.scss';

@observer
export default class Pie extends React.Component {

	onChange = category => {
		appealsStore.category = category;
		appealsStore.reloadCategories();
	};

	goBack = (e) => {
		e.preventDefault();
		appealsStore.category = null;
		appealsStore.reloadCategories();
	};

	renderName = (category) => <>{category.name}</>;

	render() {
		const props = { width: 400, height: 400 };

		const filter = {
			where: {
				parentId: null,
				totalCount: { gt: 0 },
				systemId: appealsStore.system.id,
			},
			order: 'totalCount desc, name asc'
		};

		// return <Panel left={0} top={store.ui.sideBarBtnSize} width={420} bottom={0} height="auto" header="Обращения по категориям" className="appeals-pie-chart" scrollable={false}>
		return <Panel left={0} top={store.ui.sideBarBtnSize - 15} width={420} bottom={0} height="auto" className="appeals-pie-chart" scrollable={false}>
			{!appealsStore.category ?
				<Root onChange={this.onChange} {...props} />
				:
				<Category key={appealsStore.category.id} category={appealsStore.category} {...props} />
			}
		</Panel>
	}

}
