import React from 'react';
import './style.scss';

export const ProgressBar = (props) => {
	const { bgcolor, completed } = props;

	return (
		<div className='progress-bar'>
			<div className='filler' style={{ width: `${completed > 100 ? 100 : completed}%`, backgroundColor: bgcolor }}>
				<span className='label'>
					<span style={{ padding: '0 8px', borderRadius: 50, backgroundColor: 'white' }}> </span>
				</span>
			</div>
		</div>
	);
};
