import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { TextField } from 'fires/components';

@observer
export default class IsdmFirePopup extends React.Component {
	@observable record = null;
	@observable isShowMore = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.IsdmFire.findById(this.props.record.id);
	};

	gotoAirfield = (e) => {
		e.preventDefault();
		store.route.push({ path: `/fires/dictionaries/isdmfire/${this.record.id}` });
	};

	render() {
		if (!this.record) return <Loader size={18} />;

		return <div className='isdm'>
			<div className='main-info'>
				<div className="title">
					<h2>{t('isdm.title')}: <span className='id' onClick={this.gotoAirfield} >#{this.record.id}</span></h2>
				</div>
				<Row>
					<TextField label={t('fire.numberLong')} value={this.record.fireNumber} />
					<TextField label={t('fire.description')} value={this.record.description} />
				</Row>
			</div>
		</div>;
	}

}
