import React from 'react';
import { Switch, Route } from 'react-router-dom';
import OperationalInfo from "./OperationalInfo";

export default (props) => {
	const { match } = props;
	const { path } = match;
	return (
		<Switch>
			<Route path={path} children={() => <OperationalInfo path={path} />} />
		</Switch>
	);
};
