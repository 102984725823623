import React from 'react';
import { observer, Provider } from 'mobx-react';
import t from 'i18n';
import * as ReportsList from './reports-list';

@observer
export class Report extends React.Component {
	render() {
		const inProgress = () => <div>{t('report.inProgress')}</div>;
		const Report = ReportsList[this.props.name] || inProgress;
		return (
			<Provider reportsStore={this.reportsStore}>
				<div className='report'>
					<Report />
				</div>
			</Provider>
		);
	}
}
