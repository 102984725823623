import React from 'react';

export default class Weather extends React.Component {
	render() {
		return (
			<div>
				<h1>Метеосводка г. Якутск</h1>
			</div>
		);
	}
}
