import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Hint } from 'client/components';
import { Checkbox, Loader } from '@smartplatform/ui';
import store from 'client/store';

@observer
export default class FilterLayout extends React.Component {
	
	static propTypes = {
		layer: PropTypes.object.isRequired,
		title: PropTypes.string.isRequired,
		className: PropTypes.string,
		onToggle: PropTypes.func,
		isLoading: PropTypes.bool,
		error: PropTypes.bool,
	};
	
	constructor(props) {
		super(props);
		if (!props.layer) {
			console.warn('no layer!', props);
		}
	}

	onToggle = (value) => {
		const { layer } = this.props;
		if (this.props.onToggle) {
			this.props.onToggle(value, this.props);
		}
		else {
			layer.settings.show = value;
			store.local.save();
			layer.setVisible && layer.setVisible();
		}
	};

	toggleState = () => {
		const { layer } = this.props;
		layer.settings.minimized = !layer.settings.minimized;
		store.local.save();
	};

	render() {
		const { layer, title, isLoading, error, children, legend, subKey, className } = this.props;
		const settings = subKey ? layer.settings[subKey] : layer.settings;
		const _className = classNames('filters-layout', className);
		
		return <div className={_className}>
			<div className="hdr">
				<div className="layer-toggle"><Checkbox value={settings.show} onChange={this.onToggle} label={title} />
					{legend && <Hint text={legend} position='right' size='1x' offset={10} trigger='hover' />}
				</div>
				<div className="layer-actions">
					{error && <FontAwesomeIcon icon={faExclamationTriangle} className="error-icon" />}
					{isLoading && <Loader size={16} color="#000" />}
					{children && <div className="minimize-filter" onClick={this.toggleState}>
						<FontAwesomeIcon icon={faCaretUp} className={settings.minimized ? 'minimized' : ''} />
					</div>}
				</div>
			</div>

			{children && !settings.minimized && <div className={'settings'}>{children}</div>}
		</div>;
	}

}
