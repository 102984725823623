import React from 'react';
import { observable } from 'mobx';
import store from 'client/store';
import debounce from 'lodash/debounce';

export default class ScheduleStore {
	@observable isShowFilters = false;
	@observable fireDepId = null;
	@observable rankId = null;
	@observable filter = {
		callSign: null,
		search: null,
		fireDep: null,
	};
	@observable schedule = null;
	@observable callSigns = [];
	@observable callSignsOriginId = [];

	constructor({ fireDepId, rankId }) {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.fireDepId = fireDepId;
		this.rankId = rankId;
		this.init();
	}

	init = async () => {
		let schedule = null;
		let callSignsOriginId = [];
		try {
			schedule = await store.model.FireDepScheduleDeparture.findOne({
				where: {
					fireDepId: this.fireDepId,
					rankId: this.rankId,
				},
				include: ['callSign'],
			});
			this.callSigns = schedule.callSign();
			this.callSigns.forEach((sign) => callSignsOriginId.push(sign.id));
		} catch (e) {}

		if (!schedule) {
			schedule = new store.model.FireDepScheduleDeparture({
				fireDepId: this.fireDepId,
				rankId: this.rankId,
			});
			await schedule.save();
			this.callSigns = [];
		}
		this.schedule = schedule;
		this.callSignsOriginId = callSignsOriginId;
	};

	toggleFilters = () => {
		this.isShowFilters = !this.isShowFilters;
		this.init();
	};

	toggleRecord = async (record) => {
		const index = this.callSigns.findIndex((item) => item.id === record.id);
		if (index !== -1) {
			this.callSigns.splice(index, 1);
		} else {
			this.callSigns.push(record);
		}
	};

	activeRecord = (record) => {
		return !!this.callSigns.find((item) => item.id === record.id);
	};

	onConfirm = async () => {
		let addId = [];
		let deleteId = [];
		for (const callSign of this.callSigns) {
			const index = this.callSignsOriginId.findIndex((item) => item === callSign.id);
			if (index === -1) {
				addId.push(callSign.id);
			}
		}
		for (const id of this.callSignsOriginId) {
			const callSign = this.callSigns.find((item) => item.id === id);
			if (!callSign) {
				deleteId.push(id);
			}
		}
		await Promise.all([...addId.map((item) => this.schedule.callSign.add(item)), ...deleteId.map((item) => this.schedule.callSign.remove(item))]);

		this.toggleFilters();
	};

	onSearch = (value) => {
		this.filter.search = value;
		this.doSearch();
	};

	doSearch = () => {
		this.onChange('callSign')(this.filter.search);
	};

	onChange = (prop) => (value) => {
		this.filter[prop] = value;
	};

	resetFilters = () => {
		for (let key of Object.keys(this.filter)) {
			this.filter[key] = null;
		}
	};

	getFilter = () => {
		const filter = {
			where: {},
			include: [{ relation: 'fireDep' }],
		};

		const { fireDep, callSign } = this.filter;

		if (fireDep) filter.where.fireDepId = fireDep.id;
		if (callSign) filter.search = callSign;

		return filter;
	};
}
