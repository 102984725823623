import React from 'react';
import { observer } from 'mobx-react';
import { renderCategories } from 'delivery/helpers';
import { fioShort, relative } from 'client/tools';
import t from 'i18n';

@observer
export default class Card extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { request, list } = this.props;
		const supplies = request.supplies();
		return (
			<div className='request-kanban-card'>
				<div className='top'>
					<div>#{request.id}</div>
					<div className='last-udpate'>{relative(request.updatedAt)}</div>
				</div>
				<div className='type'>{request.type ? request.type.name : '-'}</div>
				{request.level && (
					<div>
						{t('level')}: {request.level.name}
					</div>
				)}
				<div className='name'>{request.name}</div>
				{supplies.length > 0 && <div className='categories'>{renderCategories(supplies)}</div>}
				<div className='customer'>
					{t('request.customer')}: {fioShort(request.owner)}
				</div>
				<div className='positions'>
					{t('request.positionsCount')}: {supplies.length}
				</div>
			</div>
		);
	}
}
