import React, { lazy } from 'react';
import { observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';
import { DynamicLayers } from '@smartplatform/map/client';
import store from 'client/store';
import module from './index';
import { Contragents, ModuleWrapper } from 'components';
import Map from './map';
import Requests from './requests';
import Reserves from './reserves';
import Analytics from './analytics';
import Tracking from './tracking';
import initLocal from './localStore';
import { DICTIONARIES_MODELS, PLAN_CODE, REQUEST_CODE } from './constants';
import OperationalInfo from './operational-info';
import { isHozSubject, isMO } from 'delivery/helpers/roles';
const Dictionaries = lazy(() => import('components/dictionaries'));
const Audit = lazy(() => import('components/audit'));

@observer
export default class Root extends React.Component {
	constructor(props) {
		super(props);
		initLocal();
	}

	render() {
		const { path } = this.props.match;
		return (
			<ModuleWrapper className='delivery-module' module={module} path={path} redirectPath={`${path}/requests`}>
				{isHozSubject() && (
					<Route path={`${path}/requests`} exact>
						<Redirect to={`${path}/plans`} />
					</Route>
				)}
				{isMO() && (
					<Route path={`${path}/plans`} exact>
						<Redirect to={`${path}/requests`} />
					</Route>
				)}
				{isMO() && (
					<Route path={`${path}/oper-info`} exact>
						<Redirect to={`${path}/requests`} />
					</Route>
				)}
				<Route path={`${path}/map`} component={Map} />
				<Route path={`${path}/layers`} render={(routeProps) => <DynamicLayers {...routeProps} modelStore={store.model} />} />
				<Route path={`${path}/tracking`} component={Tracking} />
				<Route>
					<div className='delivery-page'>
						<Route path={`${path}/requests`} component={(props) => <Requests {...props} code={REQUEST_CODE} />} />
						<Route path={`${path}/plans`} component={(props) => <Requests {...props} code={PLAN_CODE} />} />
						<Route path={`${path}/oper-info`} component={OperationalInfo} />
						<Route path={`${path}/journal`} component={Audit} />
						<Route path={`${path}/reserves`} component={Reserves} />
						<Route path={`${path}/contragents`} render={(props) => <Contragents module='delivery' {...props} />} />
						<Route path={`${path}/analytics`} component={Analytics} />
						<Route path={`${path}/dictionaries`} render={(props) => <Dictionaries models={DICTIONARIES_MODELS} {...props} />} />
					</div>
				</Route>
			</ModuleWrapper>
		);
	}
}
