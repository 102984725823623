export const exportReport = ({ filename, href, content }) => {
	const downloadLink = document.createElement('a');
	downloadLink.href = 'data:application/vnd.ms-excel;base64,' + content;
	if (href) downloadLink.href = href;
	downloadLink.rel = 'noopener noreferrer';
	downloadLink.download = filename;
	downloadLink.click();
	downloadLink.remove();
};

export const exportReportHTML = ({ id, name }) => {
	const htmlTable = document.getElementById(id);
	const html = htmlTable.outerHTML;
	const downloadLink = document.createElement('a');
	downloadLink.href = 'data:application/vnd.ms-excel,\uFEFF ' + encodeURIComponent(html);
	downloadLink.download = `${name}.xls`;
	downloadLink.click();
};