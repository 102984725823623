import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Field, Form, Row, RecordSelect } from '@smartplatform/ui';
import { Suits } from './suits';
import { Gasi } from './gasi';
import { CODES_FOR_ENABLE_END_DATE, CODES_FOR_ENABLE_DESCRIPTION } from './constants';
import store from 'client/store';
import { fireDepVehicleTypeItem } from 'techfires/helpers';
import t from 'i18n';

import './style.scss';

const VEHICLE_TYPE_FILTER = {
	include: ['specialType'],
	order: 'name asc',
};

@inject('fireDepStore')
@observer
export default class Vehicles extends React.Component {
	@observable vehicle = null;
	@observable takenCallSignsIds = [];
	@observable isLoading = true;
	@observable selectedVehicleStatusCode = null;

	constructor(props) {
		super(props);
		this.fireDepStore = props.fireDepStore;
		this.init();
	}

	init = async () => {
		const { id, fireDepId } = this.props.match.params;
		this.fireDepId = fireDepId;

		if (id) {
			this.vehicle = await store.model.FireDepVehicle.findById(id, {
				include: [
					{ relation: 'brand', scope: { fields: ['id', 'name'] } },
					{ relation: 'type', scope: { fields: ['id', 'name'] } },
					{ relation: 'pump', scope: { fields: ['id', 'name'] } },
					{ relation: 'status', scope: { fields: ['id', 'name'] } },
					{ relation: 'cisternBulk', scope: { fields: ['id', 'name'] } },
					{ relation: 'callSign', scope: { fields: ['id', 'name'] } },
					{ relation: 'foamCapacity', scope: { fields: ['id', 'name'] } },
					{ relation: 'fuelValue', scope: { fields: ['id', 'name'] } },
				],
			});
			this.selectedVehicleStatusCode = this.vehicle.status?.code.toUpperCase();
		} else {
			this.vehicle = new store.model.FireDepVehicle({ fireDepId });
		}
		this.currentCallSignId = this.vehicle.callSignId;
		this.isLoading = false;
		await this.fetchTakenCallSigns();
	};

	fetchTakenCallSigns = async () => {
		const takenCallSigns = await store.model.FireDepVehicle.find({
			where: { fireDepId: this.fireDepId, callSignId: { neq: null } },
			fields: ['id', 'callSignId'],
		});
		this.takenCallSignsIds = [...new Set([...takenCallSigns.map(({ callSignId }) => callSignId)])];
	};

	get callSignsFilter() {
		const where = { or: [], fireDepId: this.fireDepId };
		if (this.takenCallSignsIds.length) where.or.push({ id: { nin: this.takenCallSignsIds } });
		if (this.currentCallSignId) where.or.push({ id: this.currentCallSignId });
		if (!where.or.length) delete where.or;
		return { where };
	}

	onChange = (status) => {
		this.selectedVehicleStatusCode = status?.code.toUpperCase();
	};
	
	render() {
		if (this.isLoading) return null;
		const { fireDepId } = this.props.match.params;
		const canSave = !!this.vehicle.stateNumber;

		return (
			<div className='firedep-vehicles'>
				<Form record={this.vehicle} returnTo={`/techfires/firedep/${fireDepId}/vehicles`} disableSave={!canSave}>
					<div className='vehicles-all'>
						<div className='basic-additional-feature'>
							<div className='vehicle-box'>
								<h1>{t('fireDepPersonnel.basic')}</h1>
								<Row>
									<Field relation='brand' property='name' label={t('fireDepVehicleBrand.title')} />
									<Field relation='type' computed={fireDepVehicleTypeItem} filter={VEHICLE_TYPE_FILTER} label={t('fireDepVehicle.type')} />
									<Field relation='pump' property='capacity' label={t('pump.title')} />
								</Row>
								<Row>
									<Field relation='callSign' property='name' label={t('vehicle.callSign')} filter={this.callSignsFilter} />
									<div />
									<div />
								</Row>
							</div>
							<div className='vehicle-box'>
								<h1>{t('fireDepVehicle.additional')}</h1>
								<Row>
									<Field property='year' label={t('vehicle.year')} />
									<Field property='stateNumber' label={t('vehicle.stateNumber')} />
									<Field property='passport' label={t('vehicle.passport')} />
								</Row>
							</div>
							<div className='vehicle-box'>
								<h1>{t('fireDepVehicle.feature')}</h1>
								<Row>
									<Field relation='status' property='name' label={t('fireDepVehicleStatus.title')}>
										<RecordSelect model={store.model.FireDepVehicleStatus} onChange={this.onChange} />
									</Field>
									<Field
										property='description'
										label={t('vehicle.description')}
										disabled={!CODES_FOR_ENABLE_DESCRIPTION.includes(this.selectedVehicleStatusCode)}
									/>
									<Field property='endDate' label={t('vehicle.endDate')} disabled={!CODES_FOR_ENABLE_END_DATE.includes(this.selectedVehicleStatusCode)} />
								</Row>
								<Row>
									<Field relation='fuelValue' property='value' label={t('fireDepVehicleFuel.fuelValue')} />
									<Field property='actualFuelValue' label={t('vehicle.actualFuelValue')} />
									<div />
								</Row>
								<Row>
									<Field relation='cisternBulk' property='volume' label={t('vehicle.capacity')} />
									<div />
									<div />
								</Row>
								<Row>
									<Field property='glonass' label={t('fireDepVehicleSuit.glonass')} />
								</Row>
							</div>
							<div className='vehicle-box'>
								<h1>{t('extinguishingAgents')}</h1>
								<Row>
									<Field property='actualFoamValue' label={t('vehicle.faxFoamValue')} />
									<Field relation='foamCapacity' property='capacity' label={t('vehicle.foamCapacity')} />
									<div />
								</Row>
								<Row>
									<Field property='actualPowderCapacity' label={t('vehicle.actualPowderCapacity')} />
									<Field relation='powderCapacity' property='capacity' label={t('fireDepPowderCapacity.title')} />
									<div />
								</Row>
							</div>
						</div>
						<div className='suits-gasi'>
							{this.vehicle.id && (
								<>
									<Suits vehicle={this.vehicle} />
									<Gasi vehicle={this.vehicle} />
								</>
							)}
						</div>
					</div>
				</Form>
			</div>
		);
	}
}
