import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { List } from './List';
import { Plan } from './plan';
import './style.scss';
import { MODEL_NAME } from './constants';

export const Plans = ({ match }) => {
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact children={({ match }) => <List match={match} modelName={MODEL_NAME} path={path} />} />
			<Route path={`${path}/:id`} children={({ match }) => <Plan match={match} modelName={MODEL_NAME} path={path} />} />
		</Switch>
	);
};
