// для очистки значений интутов формы
export const cleanInputsValues = (props = {}) => {
	let { formElementId = 'form', tags = ['textarea'], formElement } = props;
	formElement ?? document.getElementById(formElementId);
	if (formElement && tags.length) {
		for (const tagName of tags) {
			const tags = formElement.getElementsByTagName(tagName);
			if (tags.length) {
				Array.from(tags).forEach((tag) => (tag.value = null));
			}
		}
	}
};
