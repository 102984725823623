import React from 'react';
import { DatePicker } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { ReportToolbar } from 'components';
import { ReportStore } from '../store';
import t from 'i18n';

const CODE = 'Statistic112';

@observer
export class Statistic112 extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.112statistic'));
	}

	render() {
		const { endDate, startDate, onEndDateChange, onStartDateChange, error, name } = this.store;

		return (
			<div className='statistic-112'>
				<h2 className='title'>{name}</h2>
				<div className='filter'>
					<div>
						<div className='form-field'>
							<label>{t('date1')}</label>
							<DatePicker value={startDate} onChange={onStartDateChange} showClearButton={false} />
						</div>
					</div>
					<div>
						<div className='form-field'>
							<label>{t('date2')}</label>
							<DatePicker value={endDate} onChange={onEndDateChange} showClearButton={false} />
						</div>
					</div>
				</div>
				<ReportToolbar {...this.store.storeParams} />
				{error && <span className='error'>{error}</span>}
			</div>
		);
	}
}
