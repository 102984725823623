import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Analytics } from './Analytics';

export default (props) => {
	const { path } = props.match;
	return (
		<Switch>
			<Route path={path} exact children={() => <Analytics path={path} />} />
			<Route path={`${path}/:id`} children={() => <Analytics path={path} />} />
		</Switch>
	);
};
