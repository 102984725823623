import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Table, Column, Pager } from '@smartplatform/ui';
import { fireName } from '../../../helpers';
import t from 'i18n/index';
import { RowAddButton } from 'fires/components';
import EditJobPopup from './EditJobPopup';
import store from 'client/store';
import { POPUP_PER_PAGE, RAID_JOBS_INCLUDE } from '../../constants';
import EditPilotPopup from 'fires/raids/single-record/pilots/EditPilotPopup';

@inject('store')
@observer
export default class Jobs extends React.Component {

	@observable records = [];
	@observable showJobPopup = false;
	@observable page = 1;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.records = this.props.store.jobs;
	}

	onClosePopup = () => {
		this.props.calcArrivalDate();
		this.togglePopup(false)
	};
	onOpenPopup = () => this.togglePopup(true);

	togglePopup = (boolean) => {
		if (!boolean) {
			this.selectedRecord = null;
		}
		this.showJobPopup = boolean
	};

	onRowClick = (row) => {
		this.selectedRecord = row;
		this.togglePopup(true);
	};

	onPageChange = (page) => {
		this.page = page;
	};

	render() {
		return <div className="raid-jobs">
			<Pager
				current={this.page}
				onChange={this.onPageChange}
				totalCount={this.records.totalCount}
				itemsPerPage={POPUP_PER_PAGE}
			/>
			<Table rows={this.records.slice(POPUP_PER_PAGE * (this.page - 1), POPUP_PER_PAGE * this.page)} onRowClick={this.onRowClick}>
				<Column relation="type" property="name" label={t('airJobType.title')} />
				<Column relation="fire" computed={fireName} label={t('fire.title')} />
				<Column relation='forestOwnership' property="name" label={t('forestOwnership.title')} />
				<Column width={60} property="duration" label={t('airJob.duration_short')} className="text-center" />
				<Column width={60} property="drains" label={t('airJob.drains')} className="text-center" />
				<Column width={80} property="effects" label={t('airJob.effects')} className="text-center" />
				<Column width={60} property="jumpings" label={t('airJob.jumpings_short')} className="text-center" />
				<Column width={60} property="descents" label={t('airJob.descents_short')} className="text-center" />
			</Table>
			<RowAddButton onClick={this.onOpenPopup} model="AirJob" disabled={this.props.store.disabled} />
			{this.showJobPopup && (
				<EditJobPopup
					airJob={this.selectedRecord}
					raid={this.raid}
					onClose={this.onClosePopup}
					store={this.props.store}
				/>
			)}
		</div>;
	}

}
