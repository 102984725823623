import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Button, Input, MaskedInput, NumberInput, RecordSelect } from '@smartplatform/ui';
import { DegreeInput } from 'components';
import t from 'i18n';
import { Hint } from '../../map/filters';
import store from 'client/store';
import { RequiredLabel } from '../../components';

@inject('card')
@observer
export default class Location extends React.Component {
	@observable lon = null;
	@observable lat = null;

	constructor(props) {
		super(props);
		if (props.card) {
			props.card.location = {
				updateLocationFields: this.updateLocationFields,
			};
		}
		this.updateLocationFields();
	}

	updateLocationFields = () => {
		const fire = this.props.card.fire;
		if (fire && fire.hqGeo) {
			const [lon, lat] = fire.hqGeo.coordinates;
			this.lon = lon;
			this.lat = lat;
		}
	};

	onLatChange = (value, e) => {
		console.log('e', e.target.value);
		this.lat = value;
		this.props.card.map.checkCoords(this.lon, this.lat);
	};

	onLonChange = (value) => {
		this.lon = value;
		this.props.card.map.checkCoords(this.lon, this.lat);
	};

	render() {
		const { card } = this.props;

		const disabled = !store.model.Fire.INFO.WRITE;
		const className = 'form-field';

		return (
			<div>
				<h2>{t('fire.origin')}</h2>
				<div>
					{/*
				<div className={className}>
					<label>{t('latitude')}</label>
					<NumberInput value={this.lat} onChange={this.onLatChange} decimalScale={5} />
				</div>
				<div className={className}>
					<label>{t('longitude')}</label>
					<NumberInput value={this.lon} onChange={this.onLonChange} decimalScale={5} />
				</div>
*/}
					<div className={className}>
						<RequiredLabel label={t('latitude')} />
						<DegreeInput type='latitude' value={this.lat} onChange={this.onLatChange} />
					</div>
					<div className={className}>
						<RequiredLabel label={t('longitude')} />
						<DegreeInput type='longitude' value={this.lon} onChange={this.onLonChange} />
					</div>
					{card.map.mapStore && (
						<div>
							{!card.map.mapStore.picking ? (
								<Button variant='primary' disabled={disabled} onClick={card.map.startPickingHqGeo}>
									{t('fire.pickPoint')}
								</Button>
							) : (
								<Button variant='primary' onClick={card.map.cancelPickingHqGeo}>
									{t('cancel')}
								</Button>
							)}
							<Hint text={t('fire.pickPointHint')} trigger='hover' />
						</div>
					)}
					<div className={className}>
						<label>{t(`city.title`)}</label>
						<Input value={card.fire.city ? card.fire.city.name || t('noName') : t('no')} disabled />
					</div>
					<div className={className}>
						<label>{t('county.title')}</label>
						<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
							model={store.model.County}
							property='name'
							value={card.fire.county}
							filter={{ fields: ['id', 'name'] }}
							onChange={card.onChange('county')}
							disabled={true}
						/>
					</div>
				</div>
			</div>
		);
	}
}
