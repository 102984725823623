import { getCells, parseObj, renderCell } from './helpers';
import { HEADER_STYLE, TABLE_STYLES, TOTAL_STYLE } from './constants';

export const analyticReport = (records, year, counties, totalCounties, total) => {
	try {
		//* CAPTION
		const filename = `Аналитика за ${year} год`;
		const caption = `<caption><b>${filename}</b></caption>`;

		//** HEADER
		const headerRows = [
			[
				{ text: 'Район', rows: 2 },
				{ text: 'Всего', cols: 3 },
				...records.map((record) => ({
					text: record.name,
					cols: 3,
				})),
			],
			[...new Array(records.length + 1).fill([{ text: 'План' }, { text: 'Отгрузка' }, { text: 'Факт' }]).flat()],
		];

		const headerHtml = `<thead> ${headerRows
			.map(
				(row) =>
					`<tr style=${parseObj(HEADER_STYLE)}>${row
						.map((cell, index) => {
							cell.style = cell.style || { 'min-width': '6em' };
							return cell && renderCell(cell, index);
						})
						.join('')}</tr>`
			)
			.join('')}</thead> `;

		//** BODY
		const bodyRow = (record = {}) => {
			const [name, value] = record;
			const { plan, ship, deliver } = totalCounties.find((total) => total.name === name);
			const columns = [
				name,
				parseFloat(plan.toFixed(2)),
				parseFloat(ship.toFixed(2)),
				parseFloat(deliver.toFixed(2)),
				...records
					.map((record) => {
						const rec = value.find((r) => r.analyticName === record.name);
						return [
							rec ? parseFloat(rec.plan.toFixed(2)) : 0,
							rec ? parseFloat(rec.ship.toFixed(2)) : 0,
							rec ? parseFloat(rec.deliver.toFixed(2)) : 0,
						];
					})
					.flat(),
			];
			return getCells(columns);
		};

		const bodyHtml = `<tbody>${Object.entries(counties)
			.map(
				(record) => `<tr>
        ${bodyRow(record)
					.map((cell) => {
						cell.style = cell.style || { 'text-align': 'center' };
						return renderCell(cell);
					})
					.join('')}</tr>`
			)
			.join('')}</tbody>`;

		//** FOOTER
		const totalRow = () => {
			const { plan, ship, deliver } = total;

			const columns = [
				'Всего:',
				parseFloat(plan.toFixed(2)),
				parseFloat(ship.toFixed(2)),
				parseFloat(deliver.toFixed(2)),
				...records
					.map((record) => [
						record.plan ? parseFloat(record.plan.toFixed(2)) : 0,
						record.ship ? parseFloat(record.ship.toFixed(2)) : 0,
						record.deliver ? parseFloat(record.deliver.toFixed(2)) : 0,
					])
					.flat(),
			];
			return getCells(columns);
		};
		const footerHtml =
			records.length > 0
				? `<tfoot>
    <tr style="${parseObj(TOTAL_STYLE)}">${totalRow()
						.map((cell) => renderCell({ ...cell, zeroColor: '#393E46' }))
						.join('')}</tr></tfoot>`
				: '';

		return {
			filename,
			content: `<table ${TABLE_STYLES}>
					 ${caption}
					 ${headerHtml}
					 ${bodyHtml}
					 ${footerHtml}
					 </table>`,
		};
	} catch (e) {
		console.log('AnalyticReport Error: ', e.message);
	}
};
