import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Row, Field, Input, StringValue } from '@smartplatform/ui';
import { renderStatus } from 'client/tools';
import { AlarmAdpi, Alarm112, AlarmPhone, AlarmManual, ALARM_TYPE_ADPI, ALARM_TYPE_112, ALARM_TYPE_PHONE, ALARM_TYPE_MANUAL } from '../../';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class AlarmMessage extends React.Component {
	@observable alarmMessage = null;
	@observable error = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { fireId } = this.props;
		try {
			const fire = await store.model.TechFire.findById(fireId, {
				fields: ['id', 'alarmMessageId', 'sourceId'],
				include: [
					{
						relation: 'alarmMessage',
						scope: {
							include: [
								'source',
								{
									relation: 'adpiDevice',
									scope: {
										include: [
											{
												relation: 'address',
												scope: {
													include: [{ relation: 'fireDep', scope: { fields: ['id', 'name'] } }, { relation: 'address' }, { relation: 'rank' }],
												},
											},
											'organization',
										],
									},
								},
								'status',
							],
						},
					},
				],
			});
			this.alarmMessage = fire.alarmMessage;
		} catch (e) {
			this.error = e.message;
		}
	};

	render() {
		if (this.error) return this.error;
		if (!this.alarmMessage) return 'Нету сообщения о пожаре';

		const alarmType = this.alarmMessage.source?.code;
		console.log(this.alarmMessage);

		return (
			<div className='alarm-message'>
				<Row>
					<div className='form-field'>
						<label>{t('techFireReportSource.title')}</label>
						<Input value={this.alarmMessage.source?.description} disabled />
					</div>
					<div className='form-field'>
						<label>{t('techFireReportSource.title')}</label>
						<StringValue value={renderStatus(this.alarmMessage.status)} />
					</div>
					<div />
				</Row>
				<Row>
					<div className='form-field'>
						<label>{t('fireAlarmMessage.reportingPersonName')}</label>
						<Input value={this.alarmMessage.reportingPersonName} disabled />
					</div>
					<div className='form-field'>
						<label>{t('fireAlarmMessage.reportingPersonPhone')}</label>
						<Input value={this.alarmMessage.reportingPersonPhone} disabled />
					</div>
					<div />
				</Row>
				{alarmType === ALARM_TYPE_ADPI && <AlarmAdpi alarmMessage={this.alarmMessage} />}
				{alarmType === ALARM_TYPE_112 && <Alarm112 alarmMessage={this.alarmMessage} />}
				{alarmType === ALARM_TYPE_PHONE && <AlarmPhone record={this.alarmMessage} />}
				{alarmType === ALARM_TYPE_MANUAL && <AlarmManual record={this.alarmMessage} />}
			</div>
		);
	}
}
