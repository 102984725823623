import React from 'react';
import { DatePicker, RecordSelect } from '@smartplatform/ui';
import appStore from 'client/store';
import t from 'i18n';
import { observer } from 'mobx-react';

export const Filters = observer(({ store }) => {
	const { onChange, river, startDate, endDate, county, city, organization, category } = store;

	const cityFilter = county?.id ? { where: { countyId: county.id } } : {};

	return (
		<div className='filters'>
			<DatePicker placeholder={t('period.start')} onChange={onChange('startDate')} value={startDate} />
			<DatePicker placeholder={t('period.end')} onChange={onChange('endDate')} value={endDate} />
			<RecordSelect model={appStore.model.County} value={county} property='name' onChange={onChange('county')} placeholder={t('county.title')} width={200} />
			<RecordSelect
				model={appStore.model.City}
				value={city}
				property='name'
				onChange={onChange('city')}
				placeholder={t('city.title')}
				width={200}
				filter={cityFilter}
			/>
			<RecordSelect
				model={appStore.model.RequestRiver}
				value={river}
				property='name'
				onChange={onChange('river')}
				placeholder={t('requestRiver.title')}
				width={200}
			/>
			<RecordSelect
				model={appStore.model.Organization}
				value={organization}
				property='name'
				onChange={onChange('organization')}
				placeholder={t('organization.title')}
				width={200}
			/>
			<RecordSelect
				model={appStore.model.RequestCategory}
				value={category}
				property='name'
				onChange={onChange('category')}
				placeholder={t('requestCategory.title')}
				width={200}
			/>
		</div>
	);
});
