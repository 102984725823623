import { inject, observer } from 'mobx-react';
import React from 'react';
import Vehicles from './vehicles';
import People from './people';
import { Checkbox, DatePicker, Input, Tabs, Tab, NumberInput, RecordSelect, } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import Xarrow from "react-xarrows";

const AREA_TYPES = [
	'protectiveArea',
	'nonCoverArea',
	'activeBurningArea',
	'operationalArea',
	'forestArea',
	'reserveArea',
	'coverArea',
	'area',
	'upperArea',
	'nonForestArea',
	'soilArea',
];

const AR_AREA_TYPES = [
	'upperArea',
	'coverArea',
	'soilArea',
	'forestArea',
	'area',
	'nonCoverArea',
	'nonForestArea',
];

@inject('card')
@observer
export default class Dynamic extends React.Component {
	constructor(props) {
		super(props);
		this.card = this.props.card;
		this.fireLogs = this.card.fireLogs;
	}

	onDateChange = (date) => {
		this.card.onChange('date')(date);
		//* триггернем ререндер сайдбара новой ссылкой
		this.fireLogs.replace(this.fireLogs);
	}

	renderStatus = status => status && <>
		<span className="status-color" style={{ background: status?.color || '#808080' }} />
		{status.code && t('fireStatus.codes.' + status.code)}</>;

	renderRecordSelect = ({ field, model, value, ...props }) => <div className='form-field'>
		<label>{t('fire.' + field)}</label>
		<RecordSelect noTotal maxItems={10} itemsPerPage={1000} model={store.model[model || field[0].toUpperCase() + field.slice(1)]}
			property="name"
			value={value}
			onChange={this.card.onChange((field))}
			width='100%'
			disabled={this.card.disabled}
			{...props}
		/>
	</div>

	renderNumberInput = ({ field, label, ...props }) => <div className='form-field'>
		<label>{t(label || ('fire.' + field))}</label>
		<NumberInput disabled={this.card.disabled} onChange={this.card.onChange(field)} positiveOnly value={this.card.fire[field]} decimalScale={2} {...props} />
	</div>

	get className() {
		return 'form-field';
	}

	render() {
		const { card } = this.props;
		const { fire } = card;
		const disabled = card.disabled;

		return (
			<div className="dynamic">
				<Tabs>
					<Tab title={t('fire.mainInfo')} path={`/fires/registry/${this.card.id}/dynamic`} exact>
						{/* //* основные данные */}

						<div className="main-info">
							<div className="main-info-box">
								{/* //* основные данные */}
								<h2>{t('fire.mainInfo')}</h2>
								<div className={this.className}>
									<label>{t('fire.dynamicDate')}</label>
									<DatePicker value={card.fire.date} onChange={this.onDateChange} showTimeSelect showClearButton={false} isRequired disabled={disabled} />
								</div>

								{this.renderRecordSelect({ field: 'status', model: 'FireStatus', value: fire.status, isRequired: true, computed: this.renderStatus })}
								{/* //* Характеристика горения */}
								<h2 style={{ marginTop: '20px' }}>{t('fire.combustionCharacteristic')}</h2>
								{this.renderRecordSelect({ field: 'combustionType', value: fire.combustionType })}
								{this.renderRecordSelect({ field: 'combustionIntensity', value: fire.combustionIntensity })}
								{this.renderRecordSelect({ field: 'combustionPattern', value: fire.combustionPattern })}
								<div className={this.className}>
									<label>{t(`fire.edge`)}</label>
									<NumberInput value={fire.edge} onChange={card.onChange('edge')} />
								</div>
							</div>
							<div className="main-info-box">
								{/* //* Реквизиты решения КЧС и ОПБ РС(Я) */}
								<h2>{t('fire.decisionDetailsOpb')}</h2>
								<div className={this.className}>
									<label>{t(`fire.date`)}</label>
									<DatePicker value={card.fire.decisionDate} onChange={card.onChange('decisionDate')} showTimeSelect showClearButton={false} />
								</div>
								<div className={this.className}>
									<label>{t(`fire.protocolNumber`)}</label>
									<NumberInput value={fire.protocolNumber} onChange={card.onChange('protocolNumber')} />
								</div>
								<div className="form-field">
									<label>{t('fire.description')}</label>
									<textarea
										rows={5}
										style={{ width: '100%' }}
										value={this.props.card.fire.decisionDescription || ''}
										onChange={(r) => this.props.card.onChange('decisionDescription')(r.target.value)}
									/>
								</div>
							</div>
						</div>
						{/* //* площади */}
						<div>
							<h2 style={{ marginTop: '20px' }}>{t('fire.areaTitle')}</h2>

							<div className="squares-block">
								{AREA_TYPES.map((label, i) => (
									<div className={`${this.className} box${i}`} key={label}>
										<label>{t(`fire.${label}Short`)}</label>
										<NumberInput
											positiveOnly
											decimalScale={2}
											id={`box${i}`}
											value={fire[label] || null}
											className={i === 6 && card.boxColorByGroup === 's1' ? 'box-color-blue' : i === 6 && card.boxColorByGroup === 's2' ? 'box-color-orange' : ''}
											onChange={card.calcManualArea(label)}
										/>
									</div>
								))}
							</div>

							<div className="connection-lines">
								<Xarrow start={'box7'} end={'box4'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'box7'} end={'box9'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'box4'} end={'box1'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'box4'} end={'box6'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'box6'} end={'box0'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'box6'} end={'box3'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'box6'} end={'box5'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'box6'} end={'box8'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'box6'} end={'box10'} showHead={false} path={'grid'} color={'#37b813'} />
							</div>
						</div>
						{/* //* Площадь на арендуемых лесных участках, га */}
						<div>
							<h2 style={{ marginTop: '20px' }}>{t('fire.leasedArea')}</h2>

							<div className="squares-block">
								{AR_AREA_TYPES.map((label, i) => (
									<div className={`${this.className} ar-box${i}`} key={label}>
										<label>{t(`fire.${label}Short`)}</label>
										<NumberInput
											positiveOnly
											decimalScale={2}
											id={`ar-box${i}`}
											value={fire[label + 'Ar'] || null}
											onChange={card.calcManualAreaAr(label + 'Ar')} />
									</div>
								))}
							</div>

							<div className="connection-lines">
								<Xarrow start={'ar-box0'} end={'ar-box1'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'ar-box2'} end={'ar-box1'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'ar-box1'} end={'ar-box3'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'ar-box5'} end={'ar-box3'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'ar-box6'} end={'ar-box4'} showHead={false} path={'grid'} color={'#37b813'} />
								<Xarrow start={'ar-box3'} end={'ar-box4'} showHead={false} path={'grid'} color={'#37b813'} />
							</div>
						</div>
					</Tab>
					<Tab title={t('fire.resources')} path={`/fires/registry/${this.card.id}/dynamic/resources`}>
						<div key={this.card.fire.id}>
							<People />
							<Vehicles />

							{/* //* дополнительная информация */}
							<h2>{t('fire.secondaryInfo')}</h2>
							<div className='secondary-info'>
								<div className='secondary-info-container' style={{ paddingRight: '10px' }}>
									<div className={this.className}>
										<label>{t('fire.fireManager')}</label>
										<Input disabled={card.disabled} value={card.fire.fireManager} onChange={card.onChange('fireManager')} />
									</div>
									{this.renderNumberInput({ field: 'delivered' })}
									{this.renderRecordSelect({ field: 'notLandingReason', value: fire.notLandingReason, property: 'shortName' })}
									{this.renderRecordSelect({ field: 'notInspectionReason', value: fire.notInspectionReason, property: 'shortName' })}
									<div className={`${this.className} threat-checkbox`}>
										<label>{t('fire.threat')}</label>
										<Checkbox disabled={card.disabled} value={card.fire.threat} onChange={card.onChange('threat')} />
									</div>
									<div className="form-field">
										<label>{t('fire.description')}</label>
										<textarea
											disabled={card.disabled}
											rows={5}
											value={card.fire.dynamicDescription || ''}
											onChange={(r) => card.onChange('dynamicDescription')(r.target.value)}
										/>
									</div>
								</div>
								<div className='secondary-info-container'>
									<div className='jumping-info'>
										{this.renderNumberInput({ field: 'jumpings', integerOnly: true })}
										{this.renderNumberInput({ field: 'descents', integerOnly: true })}
									</div>
									{this.renderNumberInput({ field: 'deliveredAvia' })}
								</div>
							</div>
						</div>
					</Tab>
				</Tabs>
			</div>
		);
	}
}


