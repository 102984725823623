import React from 'react';
import { observable } from 'mobx';
import techfiresMap from '../store';
import store from 'client/store';

const DADATA_API_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs';
const DADATA_API_KEY = '842709bce84734ee40403e8ce6ef9bb80e722fa5';

export default class AddressToolStore {
	
	@observable address = null;
	@observable showPopup = false;
	
	constructor() {
		// this.test();
	}
	
	test = async () => {
		const address = await store.model.Address.findById(1715);
		this.onAddressSelect(address)
	};
	
	onAddressSelect = async (address) => {
		this.address = address;
		if (address.geo) {
			techfiresMap.mapStore.fitGeo(address.geo, { maxZoom: 18, padding: [50, 150, 250, 0] });
		}
		this.showPopup = true;
	};
	
	onPopupClose = () => this.showPopup = false;
	
	pickPoint = () => {
		techfiresMap.mapStore.pickPoint(this.onPickPoint);
	};
	
	onPickPoint = async (e, lonLat) => {
		console.log('pickPoint', e, lonLat)
		const suggestions = await this.searchAddress(lonLat);
		console.log('suggestions', suggestions.map(obj => obj['unrestricted_value']))
		if (this.searchInstance) {
			this.searchInstance.setSuggestions(suggestions);
		}
	};
	
	getSearchInstance = (instance) => this.searchInstance = instance;
	
	searchAddress = async (lonLat) => {
		const body = {
			lon: lonLat[0],
			lat: lonLat[1],
			count: 10,
		};
		let res = await fetch(`${DADATA_API_URL}/geolocate/address`, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Token ${DADATA_API_KEY}`
			},
		});
		const json = await res.json();
		return json.suggestions;
	};
}
