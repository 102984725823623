import { observer } from 'mobx-react';
import React from 'react';
import { Main } from './Main';
import './style.scss';
import { Members } from './Members';

@observer
export class MainInfo extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className='main-info'>
				<Main />
				<Members />
			</div>
		);
	}
}
