import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form, Popup, RelationSelect } from '@smartplatform/ui';
import { Actionbar } from 'components';
import t from 'i18n';
import { FormContext } from '@smartplatform/ui';
import store from 'client/store';

@inject('store')
@observer
export class VehiclePopup extends React.Component {
	static contextType = FormContext;
	constructor(props) {
		super(props);
		this.isNew = !props.record.id;
	}
	onSave = () => {
		const { onClose, record, vehicles } = this.props;
		if (!record.resourceId) this.context.form.addHasMany('vehicles', record);
		if (this.isNew) vehicles.push(record);
		onClose();
	};

	onDelete = () => {
		const { onClose, record, vehicles } = this.props;
		const index = vehicles.findIndex((i) => i.id === record.id);
		vehicles.splice(index, 1);
		if (!record.resourceId) this.context.form.removeHasMany('vehicles', record);
		onClose();
	};

	revertChanges = async () => {
		let { onClose, record } = this.props;
		if (!this.isNew) {
			record = await store.model.EmergencySituationPlanVehicle.findById(record.id, { include: ['type'] });
		}
		onClose();
	};

	render() {
		const { record, onClose, addVehicle } = this.props;
		const controls = (
			<Actionbar>
				<Actionbar.SaveButton onSave={this.onSave} />
				<Actionbar.CancelButton back={this.revertChanges} />
				{!record?.id ? <Actionbar.CleanButton onClean={addVehicle} /> : <Actionbar.DeleteButton onDelete={this.onDelete} />}
			</Actionbar>
		);

		return (
			<Popup onClose={this.revertChanges} className='emergency-popup'>
				<h2>{record.id ? t('editRecord') : t('addRecord')}</h2>
				<Form record={record} noDelete noSave controls={controls} stay>
					<Field relation='type' property='name' label={t('emergencySituationPlanResource.vehicleType')}>
						<RelationSelect isRequired />
					</Field>
					<Field property='count' label={t('count')} />
				</Form>
			</Popup>
		);
	}
}
