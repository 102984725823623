import React, { Component } from 'react';
import { MenuItem } from './MenuItem';
import { SubMenu } from './SubMenu';
import PropTypes from 'prop-types';
import './style.scss';

export class RouteMenu extends Component {
	static propTypes = {
		items: PropTypes.array,
	};

	static defaultProps = {
		items: [],
	};

	// items = [{ title, path }];
	// items = [{ title, items: [{ title, path }] }];

	render() {
		const { items } = this.props;

		if (!items?.length) return <div className='error'>no menu items provided</div>;

		return (
			<div className='route-menu'>
				{items.map((item, index) => {
					const Component = Array.isArray(item?.items) ? SubMenu : MenuItem;
					return <Component {...item} key={item.title + index} />;
				})}
			</div>
		);
	}
}
