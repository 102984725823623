import React from 'react';
import SearchIcon from '!!svg-react-loader!img/icons/search-input.svg';
import t from 'i18n';
import './style.scss';

export const SearchIconInput = ({ onChange, value, style }) => (
	<div style={style} className='search-icon-input'>
		<input value={value} onChange={onChange} placeholder={t('search')} />
		<SearchIcon className='icon' />
	</div>
);
