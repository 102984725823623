import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, DatePicker, Input, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';

@observer
export default class FireAlarmAdpi extends React.Component {

	@observable fireObject = null;
	@observable status = null;
	@observable messageDate = new Date();

	onChange = (prop) => (value) => (this[prop] = value);

	onClick = async () => {
		await store.model.FireAlarmMessage.postAdpiMessage({
			adpiDeviceId: this.fireObject.id,
			date: this.messageDate,
		});
	};

	render() {
		return <>
			<h2>Имитация сообщения о пожаре АДПИ</h2>
			<div className='d-flex'>
				<div className='form-field' style={{ marginRight: 10 }}>
					<label>объект адпи</label>
					<RecordSelect
						width={300}
						model={store.model.AdpiDevice}
						value={this.fireObject}
						onChange={this.onChange('fireObject')}
						property='name'
						isRequired
					/>
				</div>
				<div className='form-field' style={{ marginRight: 10 }}>
					<label>статус</label>
					<RecordSelect
						width={300}
						model={store.model.FireAlarmMessageStatus}
						value={this.status}
						onChange={this.onChange('status')}
						property='name'
						isRequired
					/>
				</div>
				<div className='form-field' style={{ marginRight: 20 }}>
					<label>Дата сообщения</label>
					<DatePicker value={this.messageDate} onChange={this.onChange('messageDate')} showTimeSelect />
				</div>
				<div className='form-field'>
					<label>.</label>
					<Button onClick={this.onClick} disabled={!this.fireObject} variant='primary'>
						ТЕСТ АДПИ
					</Button>
				</div>
			</div>
		</>;
	}
}
