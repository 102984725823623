import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Requests from './Requests';
import { Request } from './request';

export default (props) => {
	const { match, code } = props;
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact children={() => <Requests path={path} code={code} />} />
			<Route path={`${path}/:id`} children={({ match }) => <Request match={match} path={path} code={code} />} />
		</Switch>
	);
};
