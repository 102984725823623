export const YANDEX_API_KEY = 'e6acf07a-28f1-4fad-a5b4-1703e39197cc';

export default async function getGeo(str) {
	console.log('getGeo', str);
	const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${YANDEX_API_KEY}&format=json&geocode=${encodeURIComponent(str)}`;
	const res = await fetch(url);
	const json = await res.json();
	// console.log('json', json);
	if (json.response && json.response['GeoObjectCollection'] &&
		json.response['GeoObjectCollection']['featureMember'] &&
		json.response['GeoObjectCollection']['featureMember'][0] &&
		json.response['GeoObjectCollection']['featureMember'][0]['GeoObject'] &&
		json.response['GeoObjectCollection']['featureMember'][0]['GeoObject']['Point']) {
		const coordStr = json.response['GeoObjectCollection']['featureMember'][0]['GeoObject']['Point'].pos;
		const pos = coordStr.split(' ');
		const lon = parseFloat(pos[0]);
		const lat = parseFloat(pos[1]);
		console.log('>', lon, lat);
		return {
			type: 'Point',
			coordinates: [lon, lat]
		}
		
	}
};
