import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Panel } from 'components';
import store from 'client/store';
import Count from './Count';
import './style.scss';

@observer
export default class Total extends React.Component {

	render() {
		let title = 'Всего заболевших';
		if (store.covid.type === 'isolated') title = 'Всего самоизолированных';
		if (store.covid.type === 'contacted') title = 'Всего контактных';
		
		return <Panel left={0} top={0} width={300} height={120} className={'block total' + ' ' + store.covid.type}>
			<h1>{title}</h1>
			<Count type={store.covid.type} />
		</Panel>;
	}
	
}
