import React from 'react';
import RowContext from '@smartplatform/ui/src/components/table/context';
import './style.scss';

export const RowSkeleton = (props) => {
	const { rowsCount, emptyRecord, columns } = props;
	return new Array(rowsCount).fill(emptyRecord).map((record, index) => {
		const children = React.Children.map(columns, (child) => {
			let column;
			return child ? React.cloneElement(child, { column: column++ }) : null;
		});
		return (
			<tr key={'skeleton-row-' + index} className='skeleton-row'>
				<RowContext.Provider value={{ record }}>{children}</RowContext.Provider>
			</tr>
		);
	});
};
