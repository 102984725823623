import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Column, Pager, Table } from '@smartplatform/ui';
import { fio } from 'client/tools';
import { observable } from 'mobx';
import { PER_PAGE } from 'client/constants';

@inject('store')
@observer
export class Staff extends React.Component {
	@observable page = 1;
	onPageChange = (page) => (this.page = page);

	render() {
		const records = this.props.store.record.users();
		if (!records.length) return <span>{t('organization.noStaff')}</span>;
		return (
			<div className='staff'>
				<Pager current={this.page} onChange={this.onPageChange} totalCount={records.totalCount || 0} />
				<Table rows={records.slice(PER_PAGE * (this.page - 1), PER_PAGE * this.page)}>
					<Column computed={fio} label={t('fullName')} />
					<Column property='position' label={t('position')} />
					<Column property='phone' label={t('phone')} />
					<Column property='email' label={t('email.title')} />
				</Table>
			</div>
		);
	}
}
