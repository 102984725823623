import React from 'react';
import { observer, inject } from 'mobx-react';
import { RecordSelect, SlideInput } from '@smartplatform/ui';
import { FilterLayout } from 'components';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { colorName } from 'fires/helpers';

@inject('settings') @observer
export default class ForestryQuarters extends React.Component {
	@observable zones = [];

	constructor(props) {
		super(props);
		this.settings = props.settings;
		this.init();
	}

	init = async () => {
		this.zones = await store.model.MonitoringZone.find({ order: 'id asc ', fields: ['id', 'name', 'color'] });
	};

	onToggle = (value) => {
		this.props.layer.setVisible();
	};
	
	onOpacityChange = (value) => {
		this.settings.opacity = value;
		store.local.save();
		this.props.layer.setOpacity();
	};
	
	render() {
		const { layer, inCard } = this.props;
		const { forestry, aviaDep, monitoringZone } = layer;

		const props = {
			layer,
			layerName: 'forestryQuarters',
			title: t('forestryQuarter.plural'),
			isLoading: layer.isLoading,
		}

		const legend = <div className='legend'>
			{this.zones.map((zone, index) => <div className='d-flex align-items-center' key={index}>
				{colorName(zone)}
			</div>)}
		</div>

		return <FilterLayout {...props} legend={legend}>
			{!inCard && <>
				<div>
					<label>{t('forestry.title')}</label>
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						model={store.model.Forestry}
						prependItems={[{ label: t('all'), value: 'all' }]}
						property="name"
						value={forestry === 'all' ? { name: t('all') } : forestry}
						onChange={layer.onForestryChange}
						disabled={!this.settings.show}
					/>
				</div>
				<div>
					<label>{t('aviaDep.title')}</label>
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						model={store.model.AviaDep}
						prependItems={[{ label: t('all'), value: 'all' }]}
						property="name"
						filter={{ where: { name: { neq: '' } } }}
						value={aviaDep === 'all' ? { name: t('all') } : aviaDep}
						onChange={layer.onAviaDepChange}
						disabled={!this.settings.show}
						isRequired
					/>
				</div>
				<div>
					<label>{t('monitoringZone.title')}</label>
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						model={store.model.MonitoringZone}
						prependItems={[{ label: t('all'), value: 'all' }]}
						value={monitoringZone === 'all' ? { name: t('all') } : monitoringZone}
						onChange={layer.onMonitoringZoneChange}
						computed={colorName}
						disabled={!this.settings.show}
						isRequired
					/>
				</div>
			</>}
			<div>
				<label>{t('opacity')}</label>
				<SlideInput
					min={0.1}
					max={0.9}
					value={this.settings.opacity || 0.5}
					onChange={this.onOpacityChange}
					step={0.1}
					showTicks
				/>
			</div>
		</FilterLayout>;
	}

}
