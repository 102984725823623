import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import { RecordSelect } from '@smartplatform/ui';
import FilterLayout from '../FilterLayout';
import t from 'i18n';

@observer
export default class MineralsFilter extends React.Component {

	static propTypes = {
		layer: PropTypes.object,
		onToggle: PropTypes.func,
	};

	render() {
		const { layer } = this.props;

		return (
			<FilterLayout layer={layer} title={t('mineral.mining')}/>
		);
	}
}
