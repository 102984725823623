import React from 'react';
import { observer } from 'mobx-react';

import { Map, Popup } from '@smartplatform/map/client';
import store from 'client/store';
import Filters from './filters';
import techfiresMap from './store';
import AddressTools from './address-tools';
import { WeatherScale, MainPopup } from 'components';

const PARAMS = {
    zoom: 13,
    minZoom: 1,
    maxZoom: 20,
    center: [ 129.7320152072727, 62.028330265644826 ], // Якутск
};

@observer
export default class TechFiresMap extends React.Component {

    onLayerToggle = (layers) => {
        store.local.techfires.dynamicLayers = layers;
        store.local.save();
    };

    render() {
        const { popup, mapInitialized, onTileSourceChange } = techfiresMap;

        const visibleLayers = store.local.techfires.dynamicLayers;
        console.log('mapInitialized', mapInitialized)

        const mapParams = {
            ...PARAMS,
            onTileSourceChange,
            defaultTileSource: store.local.techfires.tileSource,
        };
    
        const mapProps = {
            className: 'techfires-map full-map',
            onInit: techfiresMap.onMapInit,
            params: mapParams,
            modelStore: store.model,
            visibleLayers,
            onLayerToggle: this.onLayerToggle,
        };

        return <Map {...mapProps}>
            {popup && <Popup {...popup} width={popup.width || 550}>
                <MainPopup {...popup} />
            </Popup>}
            {mapInitialized && <>
                <Filters />
                <AddressTools />
                {/*<WeatherScale onMount={techfiresMap.weatherLayer.onWeatherScaleMount} hidden={!store.local.techfires.layers.weather.show} />*/}
            </>}
        </Map>;
    }
}
