import React from 'react';
import { inject, observer } from 'mobx-react';
import { contractorReport } from '../../report/contractor-report';

@inject('store')
@observer
export class ContractorTable extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { data } = this.store;
		const record = contractorReport(data);
		return (
			<div className='contractor-table card'>
				<h1>Исполнители</h1>
				<hr />
				{record.content && <div dangerouslySetInnerHTML={{ __html: record.content }} />}
			</div>
		);
	}
}
