import React from 'react';
import { Column, Table, Pager } from '@smartplatform/ui';
import store from 'client/store';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { renderStatus, renderUser, renderCategories } from 'delivery/helpers';
import { REQUEST_PATH } from 'delivery/requests/constants';
import { AVATAR_SIZE, PER_PAGE } from 'client/constants';
import { MembersAvatars } from 'delivery/components';
import { observable } from 'mobx';

@inject('store')
@observer
export class Requests extends React.Component {
	@observable page = 1;
	onRowClick = (record) => store.route.push({ path: `${REQUEST_PATH}/${record.id}` });
	onPageChange = (page) => (this.page = page);
	renderMembers = (requestMembers) => <MembersAvatars members={requestMembers.map(({ user }) => user)} size={AVATAR_SIZE} avatarsCount={2} full />;
	renderUser = (user) => renderUser({ user, full: true });

	render() {
		const records = this.props.store.record.requests();
		if (!records.length) return <span>{t('organization.noRequests')}</span>;
		return (
			<div className='requests'>
				<Pager current={this.page} onChange={this.onPageChange} totalCount={records.totalCount || 0} />
				<Table rows={records.slice(PER_PAGE * (this.page - 1), PER_PAGE * this.page)} onRowClick={this.onRowClick}>
					<Column property='id' label={t('number')} width={50} />
					<Column property='date' label={t('createdAt')} width={120} />
					<Column relation='type' property='name' label={t('type')} />
					<Column relation='supplies' computed={renderCategories} label={t('category')} />
					<Column relation='status' computed={renderStatus} label={t('status')} />
					<Column relation='owner' computed={this.renderUser} label={t('request.author')} width={280} />
					<Column relation='members' computed={this.renderMembers} label={t('request.members')} width={280} />
				</Table>
			</div>
		);
	}
}
