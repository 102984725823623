import React from 'react';
import { Column, Table, Pager } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import { renderStatus, renderUser, renderCategories } from 'delivery/helpers';
import { AVATAR_SIZE } from 'client/constants';
import { MembersAvatars } from 'delivery/components';
import store from 'client/store';
import t from 'i18n';
import { REQUEST_CODE } from 'delivery/constants';
@inject('store')
@observer
export class RequestsList extends React.Component {
	onRowClick = (record) => store.route.push({ path: `${this.props.store.path}/${record.id}` });
	renderMembers = (requestMembers) => <MembersAvatars members={requestMembers.map(({ user }) => user)} size={AVATAR_SIZE} avatarsCount={2} full />;
	renderUser = (user) => renderUser({ user, full: true });

	render() {
		const { onQueryUpdate, onChange, totalCount, page, requestFilter, code, isLoading } = this.props.store;

		if (isLoading) return null;

		let table;
		const isBasic = code === REQUEST_CODE;

		if (isBasic) {
			table = (
				<Table query={requestFilter} onQueryUpdate={onQueryUpdate} model={store.model.Request} onRowClick={this.onRowClick}>
					<Column property='id' label={t('number')} width={30} />
					<Column property='date' label={t('createdAt')} width={120} />
					<Column relation='type' property='name' label={t('type')} />
					<Column relation='level' property='name' label={t('level')} />
					<Column relation='organization' property='name' label={t('request.customer')} />
					<Column relation='supplies' computed={renderCategories} label={t('category')} />
					<Column relation='status' computed={renderStatus} label={t('status')} />
					<Column relation='owner' computed={this.renderUser} label={t('request.author')} width={280} />
					<Column relation='members' computed={this.renderMembers} label={t('request.members')} width={280} />
				</Table>
			);
		} else {
			table = (
				<Table query={requestFilter} onQueryUpdate={onQueryUpdate} model={store.model.Request} onRowClick={this.onRowClick}>
					<Column property='id' label={t('number')} width={30} />
					<Column property='date' label={t('createdAt')} width={120} />
					<Column relation='level' property='name' label={t('level')} />
					<Column relation='organization' property='name' label={t('request.customer')} />
					<Column relation='supplies' computed={renderCategories} label={t('category')} />
					<Column relation='owner' computed={this.renderUser} label={t('request.author')} width={280} />
					<Column relation='members' computed={this.renderMembers} label={t('request.members')} width={280} />
				</Table>
			);
		}

		return (
			<div className='requests-list'>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} />
				{table}
			</div>
		);
	}
}
