import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { getRoleNames } from 'client/tools';
import store from 'client/store';
import MainDispatchShifts from './main-dispatcher';
import FireDepShifts from './firedep-dispatcher';
import t from 'i18n';
import './style.scss';

@observer
export default class Shifts extends React.Component {
	
	@observable canCreate = false;
	@observable isloading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const roleNames = await getRoleNames();
		console.log('roleNames', roleNames)
		this.canCreate = roleNames.includes('tfires_main') || store.model.TechFireShift.INFO.WRITE;
		this.isloading = false;
	};
	
	render() {
		if (this.isloading) return null;
	
		return <div className="techfire-shifts">
			<div className="title">
				<h1>{t('fireDepShift.plural')}</h1>
				{this.canCreate && <span className="main-dispatch">{t('fireDepShift.mainDispatch')}</span>}
			</div>
			{this.canCreate ? <MainDispatchShifts /> : <FireDepShifts />}
		</div>;
	}
}
