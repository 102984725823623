import React, { useEffect } from 'react';
import './style.scss';
import { ALARM } from '../../constants';
import { Button } from '@smartplatform/ui';
import store from 'client/store';
import { observer } from 'mobx-react';
import useSound from 'use-sound';
import InfoIcon from '!!svg-react-loader!img/icons/info.svg';
import alarmSound from 'client/sounds/alarm.mp3';
import classNames from 'classnames';

@observer
export class Notifications extends React.Component {
	render() {
		const { notifications } = store.ui;
		if (!notifications.length) return null;
		const withAlarmSound = !!notifications.find((i) => i.type === ALARM);
		return (
			<div className='notifications'>
				{notifications.map((notification) => {
					return <Notification notification={notification} key={notification.id} />;
				})}
				{/* если если сообщение с типом ALARM, то крутим звук тревоги */}
				{withAlarmSound && <Sound />}
			</div>
		);
	}
}

const Notification = ({ notification }) => {
	const { message, type, onClick, sourceCode } = notification;

	const removeNotification = () => {
		cancelNotification();
		onClick?.();
	};

	const cancelNotification = () => {
		store.ui.notifications = store.ui.notifications.filter((i) => i.id !== notification.id);
	};

	const className = classNames('notification', type ? type.toLowerCase() : 'success', { [`_${sourceCode?.toLowerCase()}`]: sourceCode ? true : false });

	let renderItems;
	if (sourceCode === '112') {
		renderItems = (
			<div className={className} onClick={removeNotification} onAnimationEnd={type === ALARM ? undefined : removeNotification}>
				<div className='notification-message-text'>
					<InfoIcon />
					<span>{message}</span>
				</div>
			</div>
		);
	} else {
		renderItems = (
			<div className={className} onAnimationEnd={type === ALARM ? undefined : removeNotification}>
				<div className='notification-message-text'>
					<InfoIcon />
					<span>{message}</span>
				</div>
				<div className='buttons'>
					<Button className='notification-open-button' onClick={removeNotification}>
						Открыть
					</Button>
					<Button className='notification-cancel-button' onClick={cancelNotification}>
						Отклонить
					</Button>
				</div>
			</div>
		);
	}

	return renderItems;
};

const Sound = () => {
	const [play, { stop }] = useSound(alarmSound, { interrupt: true, loop: true });

	useEffect(() => {
		play();
		return () => stop();
	}, [play]);
	return null;
};
