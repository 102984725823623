import hydrantBlue from './hydrant-blue.svg';
import hydrantRed from './hydrant-red.svg';
import defaultIcon from './icon.svg';
import prorubIcon from './prorub.svg';
import gusakIcon from './gusak.svg';
import rezervuarBlue from './rezervuar-blue.svg';
import rezervuarRed from './rezervuar-red.svg';

export const ICON_TYPE_MAP = {
	PR: ({ working }) => (working ? rezervuarBlue : rezervuarRed),
	ICEHOLE: () => prorubIcon,
	PG: ({ working }) => (working ? hydrantBlue : hydrantRed),
	GSK: () => gusakIcon,
	undefined: () => defaultIcon,
};
