import { observable } from 'mobx';
import { get as getProjection } from 'ol/proj';
import ImageStatic from 'ol/source/ImageStatic';
import { isEqual } from 'date-fns';
import WebglWindLayer from './webgl-wind-layer';
import store from 'client/store';

const worldExtent = [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244];

const DEFAULT_SETTINGS = {
	show: false,
	minimized: false,
	fadeOpacity: 0.995,
	speedFactor: 5,
	particleLife: 128,
	opacity: 0.8,
	date: new Date(),
};

export default class WindLayer {

	@observable isLoading = false;
	@observable error = false;

	level = '10 m above ground';
	min = -30;
	max = 30;

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.mapStore.on('cleanup', this.cleanup);
		console.log('settings', JSON.stringify(this.settings))

		this.source = this.makeSource();

		if (this.layer) {
			this.layer.remove();
			this.mapStore.removeLayer(this.layer);
		}

		this.layer = new WebglWindLayer({
			extent: worldExtent,
			source: this.source,
			opacity: this.settings.opacity || 0.8,
			zIndex: 10,
			fadeOpacity: this.settings.fadeOpacity,
			speedFactor: this.settings.speedFactor,
			_mapRef: this.mapStore.map,
		});

		this.mapStore.addLayer(this.layer);
		this.setVisible();
	};

	makeSource = () => {
		this.isLoading = this.settings.show;
		this.error = false;

		const date = new Date(this.settings.date || new Date());
		const hour = ((date.getHours() / 3) | 0) * 3;
		date.setHours(hour);

		const url = `/api/gfs?date=${date.toISOString()}&degree=0.25&layer=UGRD,VGRD&level=${this.level}&min=${this.min}&max=${this.max}&format=png`;

		const projection = getProjection('EPSG:4326');
		// const projection = getProjection('EPSG:3857');
		const imageExtent = projection.getExtent();

		const source = new ImageStatic({
			url,
			projection,
			imageExtent,
			crossOrigin: 'Anonimous',
		});

		source.on('imageloadend', () => this.isLoading = false);
		source.on('imageloaderror', this.onError);

		return source;
	};

	updateSettings = (filterProps, settingsUpdate) => {
		const { settings, settingsPath } = filterProps;
		this.settings = {
			...this.settings,
			...settingsUpdate,
		};
		this.layer.setSpeedFactor(this.settings.speedFactor);
		this.layer.setFadeOpacity(this.settings.fadeOpacity);
		this.layer.setParticleLife(this.settings.particleLife);
		this.layer.setOpacity(this.settings.opacity);
		this.update();
	};

	onError = () => {
		this.isLoading = false;
		this.error = true;
	};

	setVisible = () => {
		if (this.layer) {
			this.layer.setVisible(this.settings.show);
		}
		if (this.settings.show) {
			const animate = () => {
				this.frameId = requestAnimationFrame(() => {
					this.mapStore.map.render();
					animate();
				});
			};
			animate();
		}
		else {
			cancelAnimationFrame(this.frameId);
		}
	};

	update = () => {
		// if (this.layer) this.layer.update();
		this.source = this.makeSource();
		if (this.layer) {
			this.layer.setSource(this.source);
			this.layer.refreshSource();
		}
	};

	cleanup = (...args) => {
		// console.log('>>> cleanup!', args);
		cancelAnimationFrame(this.frameId);
	};

};
