import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Loader, Button } from '@smartplatform/ui';
import { AddressInfo } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export class Address extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable error = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.init();
		}
	}

	init = async () => {
		this.isLoading = true;
		if (this.props.match.params.id) {
			try {
				this.record = await store.model.Address.findById(this.props.match.params.id);
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.error = 'No ID!';
		}
		this.isLoading = false;
	};

	back = () => this.props.history.goBack();

	render() {
		if (this.isLoading) return <Loader size={18} />;
		if (this.error) return this.error;

		return (
			<div className='address-single'>
				<Button variant='primary' onClick={this.back}>
					{t('backToList')}
				</Button>
				<div className='address-form'>
					<AddressInfo address={this.record} />
				</div>
			</div>
		);
	}
}
