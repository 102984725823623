import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import html2canvas from 'html2canvas';
import { format } from 'date-fns';
import { throttle } from 'lodash';
import store from 'client/store';
import { Map } from '@smartplatform/map/client';
import { Popup, Select, Loader } from '@smartplatform/ui';
import { ExportButton } from 'components';
import DataOnMap from './DataOnMap';

export const exportFormats = [
	{ title: '1200x800 мм',  size: [1200, 800] },
	{ title: '800x1200 мм',  size: [800, 1200] },
	{ title: '2200x1800 мм', size: [2200, 1800] },
	{ title: '1800x2200 мм', size: [1800, 2200] },
];

export const exportResolutions = [
	{ title: '72 dpi',  value: 72 },
	{ title: '150 dpi', value: 150 },
	{ title: '300 dpi', value: 300 },
];

@observer
export default class ExportPopup extends React.Component {
	
	@observable isRendered = false;
	@observable isExported = false;
	@observable updates = 0;
	@observable scale = 1;
	@observable exportFormat = exportFormats[0];
	@observable exportResolution = exportResolutions[0];
	
	constructor(props) {
		super(props);
		this.store = props.store;
		this.onResize = throttle(this.onResize, 1000, { leading: false, trailing: true });
	}
	
	componentDidMount() {
		window.addEventListener('resize', this.onResize);
	}
	
	componentWillUnmount() {
		window.addEventListener('resize', this.onResize);
	}
	
	onResize = () => {
		this.isRendered = false;
		this.updates++;
	}
	
	get size () { return this.exportFormat.size };
	
	onFormatChange = (format) => {
		this.scale = format.size > 1200 ? 1.8 : 1;
		this.exportFormat = format;
		const className = `preview size-${format.size[0]}x${format.size[1]}`;
		console.log('onFormatChange', format, className);
		if (this.container) {
			this.container.className = className;
		}
		this.store.mapStore.map.setSize([format.size[0], format.size[1]]);
		this.isRendered = false;
		this.updates++;
	};
	
	onResolutionChange = (resolution) => {
		console.log('onResolutionChange', resolution)
		this.exportResolution = resolution;
	};
	
	onMount = (el) => {
		this.container = el;
		if (el) {
			this.mapEl = el.getElementsByClassName('export-map')[0];
			if (this.mapEl) {
				this.rect = el.getBoundingClientRect();
				this.scale = this.rect.width / this.size[0];
				// this.mapEl.style.transform = `scale(${this.scale}, ${this.scale})`;
				this.ready = true;
			}
		}
	};
	
	onRender = async (dataElement) => {
		if (!this.ready) {
			const { container, mapEl, scale } = this;
			console.warn('container not mounted?', { container, mapEl, scale });
			return;
		}
		this.canvas = document.createElement('canvas');
		const resolution = this.exportResolution.value;
		const width = Math.round((this.size[0] * resolution) / 25.4);
		const height = Math.round((this.size[1] * resolution) / 25.4);
		console.log({ width, height })
		this.canvas.width = width;
		this.canvas.height = height;
		this.canvas.style.width = this.rect.width + 'px';
		this.canvas.style.height = this.rect.height + 'px';
		// this.canvas.style.transform = `scale(${this.scale}, ${this.scale})`;
		this.canvas.className = 'rendered-content sp-map-overlay';
		this.container.appendChild(this.canvas);

		requestAnimationFrame(() => {
			requestAnimationFrame(async () => {
				await html2canvas(dataElement, {
					canvas: this.canvas,
					windowWidth: width,
					windowHeight: height * 1.1,
					width: width,
					height: height,
					backgroundColor: null,
				});
				
				this.isRendered = true;
			});
		});
	};
	
	export = async () => {
		const { mapStore } = this.store;
		const map = mapStore.map;
		const resolution = this.exportResolution.value;
		const width = Math.round((this.size[0] * resolution) / 25.4);
		const height = Math.round((this.size[1] * resolution) / 25.4);
		console.log('>>> export', { resolution, width, height });
		const size = map.getSize();
		const viewResolution = map.getView().getResolution();
		const center = map.getView().getCenter();
		const zoom = map.getView().getZoom();
		
		this.isExported = true;
		const dataCanvas = this.canvas;
		
		map.once('rendercomplete', async () => {
			const mapCanvas = document.createElement('canvas');
			mapCanvas.width = width;
			mapCanvas.height = height;
			const mapContext = mapCanvas.getContext('2d');
			
			Array.prototype.forEach.call(
				document.querySelectorAll('.ol-layer canvas'),
				function (canvas) {
					if (canvas.width > 0) {
						const custom = canvas.className.search(/webgl/) !== -1;
						if (custom) {
							console.log('- custom webgl layer, skipping...', canvas);
							return;
						}
						const opacity = canvas.parentNode.style.opacity;
						mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
						const transform = canvas.style.transform;
						// Get the transform parameters from the style's transform matrix
						const match = transform.match(/^matrix\(([^\(]*)\)$/);
						if (match) {
							const matrix = match[1].split(',').map(Number);
							// Apply the transform to the export map context
							const context = custom ? WebGLRenderingContext : CanvasRenderingContext2D;
							console.log('context', context, context.prototype);
							context.prototype.setTransform && context.prototype.setTransform.apply(
								mapContext,
								matrix
							);
						}
						mapContext.drawImage(canvas, 0, 0);
					}
				}
			);
			
			mapContext.drawImage(dataCanvas, 0, 0, width, height);
			
			mapContext.globalAlpha = 1;
			const data = mapCanvas.toDataURL('image/png');
			
			const downloadLink = document.createElement('a');
			downloadLink.href = data;
			downloadLink.download = 'map-screenshot-' + format(new Date(), 'dd.MM.yyyy-HH:mm:ss') + '.png';
			downloadLink.click();
			
			this.isExported = false;
			map.getView().setCenter(center);
			map.getView().setZoom(zoom);
		});
		
		// Set print size
		const printSize = [ width, height ];
		map.setSize(printSize);
		const scaling = Math.min(width / size[0], height / size[1]);
		map.getView().setResolution(viewResolution / scaling);
	};
	
	onMapInit = (mapStore) => {
		this.store.onMapInit(mapStore, true);
	};
	
	// Не используется. Лучше не позволять управлять слоями из экспорта.
	onLayerToggle = (layers) => {
		store.local.emergency.planDynamicLayers = layers;
		store.local.save();
	};

	render() {
		const { closeExportPopup } = this.store;
		
		const className = `preview size-${this.exportFormat.size[0]}x${this.exportFormat.size[1]}`;
		
		return <Popup onClose={closeExportPopup} className="plan-export-popup" key={this.updates}>
			<div className="actions">
				<Select
					items={exportFormats}
					computed={r => r.title}
					value={this.exportFormat}
					onChange={this.onFormatChange}
					isRequired
					noSearch
				/>
				<Select
					items={exportResolutions}
					computed={r => r.title}
					value={this.exportResolution}
					onChange={this.onResolutionChange}
					isRequired
					noSearch
				/>
				<ExportButton onClick={this.export} />
				{this.isExported && <Loader size={18} />}
			</div>
			<div className={className} ref={this.onMount}>
				{!this.isRendered && <DataOnMap onRender={this.onRender} scale={this.scale} />}
				<div className="export-bg" />
				<Map
					onInit={this.onMapInit}
					className='export-map'
					modelStore={store.model}
					// onLayerToggle={this.onLayerToggle}
					visibleLayers={store.local.emergency.planDynamicLayers}
				/>
				{/*<DataOnMap onRender={this.onRender} />*/}
			</div>
		</Popup>;
	}
}
