import React from 'react';
import { observer } from 'mobx-react';
import { ReportToolbar } from 'components';
import { ReportStore } from '../store';
import t from 'i18n';

const CODE = 'Fuel';

@observer
export class Fuel extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.fuel'));
	}

	render() {
		const { error, name } = this.store;
		return (
			<div className='list-112'>
				<h2 className='title'>{name}</h2>
				<ReportToolbar {...this.store.storeParams} />
				{error && <span className='error'>{error}</span>}
			</div>
		);
	}
}
