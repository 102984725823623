import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Loader, Table, Column, Button, DateValue } from '@smartplatform/ui';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class List extends React.Component {
	
	@observable records = [];
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.records = await store.model.TechFireShift.find({
			include: [
				{ relation: 'owner' },
				{ relation: 'standInSection' },
				{ relation: 'standInPosition' },
			],
			order: 'id desc',
		});
		this.isLoading = false;
	};
	
	create = () => store.route.push({ path: `/techfires/shifts/create` });
	
	go = (record) => store.route.push({ path: `/techfires/shifts/${record.id}` });
	
	renderDispatcher = ({ record }) => {
		if (!record.standIn) return fio(record.owner);
		return record.standInName || '-';
	};
	
	render() {
		if (this.isLoading) return <Loader/>;
		
		const { isMain } = this.props;
		
		return <div className="list">
			{isMain && <div className="actions">
				<Button onClick={this.create} variant="primary">{t('create')}</Button>
			</div>}
			<Table rows={this.records} onRowClick={this.go}>
				<Column property="id" label={t('id')} width={50} />
				<Column property="date" label={t('date')} width={100}><DateValue format="dd.MM.yyyy" /></Column>
				<Column property="name" label={t('name')} />
				<Column property="standIn" label={t('fireDepShift.standIn')} width={180} className="text-center" />
				<Column label={t('fireDepShift.dispatcher')}>{this.renderDispatcher}</Column>
			</Table>
		</div>;
	}
}
