import { observable } from "mobx";
import t from 'i18n';

export const GROUPS = [
	{ value: 1, label: t('aviaDep.title'), field: 'aviaDep', nullLabel: t('fireReport.noAviaDep') },
	{ value: 2, label: t('forestry.title'), field: 'forestry', nullLabel: t('fireReport.noForestry') },
	{ value: 3, label: t('county.title'), field: 'county', nullLabel: t('fireReport.noCounty') },
];

export default class FireReportStore {
	@observable ownership = null;
	@observable date = new Date();
	@observable group = 1;

	get storeParams() {
		return {
			reportCode: 'CumulativeFires',
			reportParams: {
				date: this.date,
				forestOwnership: this.ownership || undefined,
				group: GROUPS.find(i => i.value === this.group)
			},
			isHTML: true,
		};
	}

	onOwnershipChange = (ownership) => this.ownership = ownership;
	onGroupChange = (group) => this.group = group;
	onDateChange = (date) => this.date = date;
}
