import React from 'react';
import { observer } from 'mobx-react';

import t from 'i18n';
import '../style.scss';

import { Button, Checkbox, Popconfirm, Row } from '@smartplatform/ui';
import { formatDate } from 'client/tools';

@observer
export default class CasualtySingle extends React.Component {
	delete = async () => {
		await this.props.record.delete();
		this.props.onUpdate && this.props.onUpdate();
	};

	render() {
		const { record, onEdit } = this.props;
		return (
			<div className='casualty-card'>
				<Row>
					<div className='form-field'>
						<label>{t('user.fullName')}</label>
						{`${record.lastName || ''} ${record.firstName || ''} ${record.middleName || ''}`}
					</div>
					<div className='form-field'>
						<label>{t('techFireCasualty.birth')}</label>
						{record.yearOfBirth}
					</div>
					<div />
					<div className='main-actions'>
						<label>&nbsp;</label>
						<Button onClick={() => onEdit(record)} variant='primary'>
							{t('edit')}
						</Button>{' '}
						{record.id && (
							<Popconfirm onConfirm={this.delete} content='Удалить?'>
								<Button>{t('delete')}</Button>
							</Popconfirm>
						)}
					</div>
				</Row>
				<Row>
					<div className='form-field'>
						<label>{t('techFireCasualty.deceased')}</label>
						<Checkbox checked={record.deceased} disabled />
					</div>
					<div className='form-field'>
						<label>{t('techFireCasualty.detectTime')}</label>
						{formatDate(record.detectTime, 'dd.MM.yyyy HH:mm')}
					</div>
					<div />
					<div />
				</Row>
				<Row>
					<div className='form-field'>
						<label>{t('techFireCasualty.furtherAction')}</label>
						{record.furtherAction}
					</div>
				</Row>
			</div>
		);
	}
}
