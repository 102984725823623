import { observable } from 'mobx';
import appStore from 'client/store';
import { addTimeDurationToDate, formatDate } from 'client/tools';
import t from 'i18n/index';

export default class RaidEditStore {
	@observable raid = null;
	@observable isLoading = true;
	@observable isNew;
	@observable aircraft = null;
	@observable activeTabIndex = 0;

	@observable pilots = [];
	@observable jobs = [];
	hasManyToSave = {};
	hasManyToDelete = {};

	init = async (raid) => {
		this.raid = raid;
		this.isNew = !this.raid;
		if (this.isNew) {
			this.raid = new appStore.model.AirRaid();
			this.raid.requestDate = new Date();
		} else {
			this.pilots = this.raid.pilots();
			this.jobs = this.raid.jobs();
		}
		if (this.raid.aircraftId) {
			this.aircraft = await appStore.model.ViewAircraft.findById(this.raid.aircraftId);
		}
		this.pilots.totalCount = this.pilots.length
		this.jobs.totalCount = this.jobs.length
		this.isLoading = false;
	};

	onAircraftChange = (aircraft) => this.aircraft = aircraft;
	calcArrivalDate = async () => {
		//нет времени отправления = убираем время прибытия
		if (this.raid.departureDate) {
			let tempArrivalDate = this.raid.departureDate;
			// если нет айди налета,то у него нет работ
			if (this.raid.id) {
				const jobs = await appStore.model.AirJob.find({ where: { raidId: this.raid.id } });
				for (const job of jobs) {
					if (job.duration) {
						tempArrivalDate = addTimeDurationToDate(tempArrivalDate, job.duration);
					}
				}
			}
			console.log('TIME SET')
			this.raid.arrivalDate = tempArrivalDate;
		} else this.raid.arrivalDate = null
	};

	renderAircraft = (record) => `${record.aircraftName || ''} ${t('aircraft.number_short')}: ${record.aircraftNumber || ''} № ${record.contractNumber || ''} ${t('dateFrom')} ${formatDate(record.contractDate) || ''}`;

	beforeSave = () => {
		this.raid.aircraftId = this.aircraft?.id || null;
	};

	setTab = (tabIndex) => (this.activeTabIndex = tabIndex);

	checkHasMany = (relation) => {
		if (!this.hasManyToSave[relation]) this.hasManyToSave[relation] = [];
		if (!this.hasManyToDelete[relation]) this.hasManyToDelete[relation] = [];
	};

	addHasMany = (relation, raid) => {
		this.checkHasMany(relation);
		// Проверяем массив на наличие добавляемой реляции,есть есть - изменяем, если нет - добавляем
		const addToSaveIndex = this.hasManyToSave[relation].findIndex((i) => i.id === raid.id);
		if (addToSaveIndex === -1) {
			this.hasManyToSave[relation].push(raid);
		} else this.hasManyToSave[relation][addToSaveIndex] = raid;
		// Такая же логика для массива для рендера, totalCount при этом уже меняем вручную
		const addToRelationIndex = this[relation].findIndex((i) => i.id === raid.id);
		if (addToRelationIndex === -1) {
			this[relation].push(raid);
			if (!this[relation].totalCount) this[relation].totalCount = 0;
			this[relation].totalCount++;
		} else this[relation][addToRelationIndex] = raid;

		// console.log('a: ', this.hasManyToSave);
	};

	removeHasMany = (relation, raid) => {
		this.checkHasMany(relation);
		// Убираем из добавленных реляций, если там уже удаляемая
		const addToSaveIndex = this.hasManyToSave[relation].findIndex((i) => i.id === raid.id);
		if (addToSaveIndex !== -1) {
			this.hasManyToSave[relation].splice(addToSaveIndex, 1);
		}
		// Добавляем в список удаляемых
		this.hasManyToDelete[relation].push(raid);
		// Убираем запись из массива для рендера
		const removeFromRelationIndex = this[relation].findIndex((i) => i.id === raid.id);
		this[relation].splice(removeFromRelationIndex, 1);
		this[relation].totalCount--;

		// console.log('a: ', this.hasManyToSave, 'r:', this.hasManyToDelete);
	};

	// Когда сохраняем запись, мы должны дать новым и измененным реляцям forceAvailabilityId и сохранить
	// При этом удаленные реляции мы удаляем ( методом deleteRelations )
	saveRelations = async () => {
		for (const key of Object.keys(this.hasManyToSave)) {
			for (const raid of this.hasManyToSave[key]) {
				raid.raidId = this.raid.id;
				await raid.save();
			}
		}
	};
	deleteRelations = async () => {
		for (const key of Object.keys(this.hasManyToDelete)) {
			for (const raid of this.hasManyToDelete[key]) await raid.delete();
		}
	};
	// Когда мы не сохраняем запись, то чистим реляции что мы создали(без forceAvailabilityId)
	// Отредактированные и удаленные реляции просто не сохранятся
	deleteNotSavedRelations = async () => {
		for (const key of Object.keys(this.hasManyToSave)) {
			for (const raid of this.hasManyToSave[key]) {
				!raid.raidId && (await raid.delete());
			}
		}
	};
}
