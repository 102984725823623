import React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Column, Table } from '@smartplatform/ui';
import t from 'i18n';
import { RowAddButton } from 'fires/components';
import store from 'client/store';
import EditVehiclePopup from './EditVehiclePopup';
import { getVehiclesValues } from 'fires/helpers';
import { VEHICLE_INCLUDE } from '../../constants';
import { RowDeleteButton } from 'fires/components/buttons';

@inject('card')
@observer
export default class Vehicles extends React.Component {
    @observable showPeoplePopup = false;
    @observable currentVehicles = [];
    @observable records = [];

    constructor(props) {
        super(props);
        this.card = this.props.card;
        this.fire = this.card.fire;
        this.fireLogId = this.fire.id;
        this.prevLogs = this.card.fireLogs.filter((i) => new Date(i.date) < new Date(this.fire.date));
        this.currentVehicles = this.fire.vehicles();
        this.records = this.getRecords();
    }

    fetchRecords = async () => {
        //* сфетчим снова людей и поменяем пожару кэш в реляции
        this.currentVehicles = await store.model.FireFightingVehicle.find({ where: { fireLogId: this.fireLogId, include: VEHICLE_INCLUDE.scope.include } });
        this.fire._vehicles._cache = this.currentVehicles;
        this.records = this.getRecords();
    };

    onClosePopup = () => this.togglePopup(false);

    togglePopup = async (boolean) => {
        this.showPeoplePopup = boolean;
        if (!boolean) {
            this.selectedRecord = null;
            this.fetchRecords();
        }
    };

    onRowClick = (row) => {
        row.fireLogId = this.fireLogId;
        this.selectedRecord = row;
        this.togglePopup(true);
    };

    add = async () => {
        this.selectedRecord = new store.model.FireFightingVehicle({ fireLogId: this.fireLogId });
        this.togglePopup(true);
    };

	renderAction = (record) => record.id && !this.card.disabled ? <RowDeleteButton onClick={() => this.delete(record)} /> : ' ';
    renderFormation = (record) => record.formationShortName || record.formation?.shortName;
    renderCrewType = (record) => record.crewTypeShortName || record.crewType?.shortName;
    renderType = (record) => record.typeShortName || record.type?.shortName;

    delete = async (record) => {
        await record.delete();
        this.fetchRecords();
    };

    @action getRecords() {
        const prev = getVehiclesValues(this.prevLogs);
        //* найдем записи, которые есть в предыдущих логах, изменим их, добавим записи из текущей, если совпадения нет
        this.currentVehicles.forEach((currentLog) => {
            const sameLog = prev.find((prev) => prev.crewTypeId === currentLog.crewTypeId && prev.formationId === currentLog.formationId && prev.typeId === currentLog.typeId);
            if (sameLog) {
                const { crewTypeId, formationId, typeId, ...properties } = store.model.FireFightingVehicle.PROPERTIES;
                Object.keys(properties).forEach((prop) => (sameLog[prop] = currentLog[prop]));
            } else {
                prev.push(currentLog);
            }
        });

        return prev.map((i) => new store.model.FireFightingVehicle(i));
    }

    render() {
        return (
            <div className="vehicles">
                {this.showPeoplePopup && (
                    <EditVehiclePopup record={this.selectedRecord} onClose={this.onClosePopup} records={this.records} />
                )}
                <div className="vehicles-table">
					<h2>{t('fireFightingVehicle.plural')}</h2>
                    <Table rows={this.records} onRowClick={this.onRowClick}>
                        <Column computed={this.renderType} label={t('extinguishingAgentsType.title_short')} />
                        <Column computed={this.renderCrewType} label={t('crewType.title_short')} />
                        <Column computed={this.renderFormation} label={t('fireFightingFormation.title_short')} />
                        <Column property="current" label={t('fireFightingVehicle.current_short')} className="text-center" />
                        <Column property="required" label={t('fireFightingVehicle.required')} className="text-center" />
                        <Column property="plan" label={t('fireFightingVehicle.plan')} className="text-center" />
                        <Column width={40} computed={this.renderAction} clickable={false} />
                    </Table>
					<RowAddButton onClick={this.add} disabled={this.card.disabled} />
                </div>
            </div>
        );
    }
}
