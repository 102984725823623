import { getCells, parseObj, renderCell } from '../helpers';
import { HEADER_STYLE, TABLE_STYLES } from '../constants';

export const executionPlanReport = (records) => {
	try {
		//* CAPTION
		const filename = `Общий план`;
		const caption = `<caption><b>${filename}</b></caption>`;

		//** HEADER
		const headerRows = [
			[
				{ text: 'Населенный пункт' },
				{ text: 'План, тонн' },
				{ text: 'Отгружено, тонн' },
				{ text: '% отгрузки к плану' },
				{ text: 'Доставлено, тонн' },
				{ text: '% доставки к плану' },
			],
		];

		const headerHtml = `<thead> ${headerRows
			.map(
				(row) =>
					`<tr style=${parseObj(HEADER_STYLE)}>${row
						.map((cell, index) => {
							cell.style = cell.style || { 'min-width': '6em' };
							return cell && renderCell(cell, index);
						})
						.join('')}</tr>`
			)
			.join('')}</thead> `;

		//** BODY
		const bodyRow = (record = {}) => {
			const [name, value] = record;
			const plan = value?.plan;
			const ship = value?.ship;
			const deliver = value?.deliver;
			const columns = [
				name,
				plan.toLocaleString('ru'),
				ship.toLocaleString('ru'),
				ship && plan ? `${((ship / plan) * 100).toFixed(2)}%` : '0%',
				deliver.toLocaleString('ru'),
				deliver && plan ? `${((deliver / plan) * 100).toFixed(2)}%` : '0%',
			];
			return getCells(columns);
		};
		const bodyHtml = `<tbody>${Object.entries(records)
			.map(
				(record) => `<tr>
        ${bodyRow(record)
					.map((cell) => {
						cell.style = cell.style || { 'text-align': 'center' };
						return renderCell(cell);
					})
					.join('')}</tr>`
			)
			.join('')}</tbody>`;

		return {
			filename,
			content: `<table ${TABLE_STYLES} width="690">
					 ${headerHtml}
					 ${bodyHtml}
					 </table>`,
		};
	} catch (e) {
		console.log('PlanTotalReport Error: ', e.message);
	}
};
