import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import format from 'date-fns/format';

import { Panel } from 'components';
import store from 'client/store';
import './style.scss';

@observer
export default class LastUpdate extends React.Component {

	@observable date = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const res = await store.model.CaseLog.find({
			where: { updatedAt: { neq: null }},
			order: 'updatedAt desc',
			limit: 1,
		});
		this.date = res.length > 0 ? res[0].updatedAt : null;
	};
	
	render() {
		return <Panel left={0} bottom={0} top="auto" width={300} height={80} className="block last-update">
			Последнее обновление<br />
			<strong>{this.date ? format(new Date(this.date), 'dd.MM.yyyy HH:mm') : ''}</strong>
		</Panel>;
	}
	
}
