import React from 'react';
import { observer, Provider } from 'mobx-react';
import { ColorUtils, Loader, Tab, Tabs } from '@smartplatform/ui';
import t from 'i18n';
import { REQUEST_PATH } from 'delivery/tracking/constants';
import { Main } from './main-info/Main';
import SingleCargoStore from './store';
import { CargoTracking } from './cargo-tracking/CargoTracking';
import { Breadcrumbs } from 'components';
import { drawGeoMarker, geoJSON, Map } from '@smartplatform/map/client';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import './style.scss';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { asArray } from 'ol/color';
import markerIconGeo from 'img/icons/transporter.svg';
import store from 'client/store';
import { Redirect, Route } from 'react-router-dom';

@observer
export class SingleCargo extends React.Component {
	constructor(props) {
		super(props);
		this.store = new SingleCargoStore();
		this.store.id = parseInt(this.props.match.params.id);
		this.store.init();
	}

	onMapInit = (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;

		// создаем свой векторный слой с источником VectorSource
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		// добавляем слой на карту
		this.mapStore.addLayer(this.layer);

		this.map.getView().animate({ zoom: 2 });
		this.updateMap();
	};

	updateMap = () => {
		const { tracks } = this.store;

		let features = [];
		let city = null;

		if (tracks.length) {
			city = tracks[tracks.length - 1].city;
			const marker = drawGeoMarker(city.geo, {
				data: { record: city },
				style: new Style({
					fill: new Fill({ color: asArray([...ColorUtils.hexToArray('#0af'), 0.2]) }),
					stroke: new Stroke({ color: asArray([...ColorUtils.hexToArray('#024'), 0.8]), width: 1, lineDash: [1, 4] }),
					image: new Icon({
						src: markerIconGeo,
						anchor: [9, 31],
						anchorXUnits: 'pixels',
						anchorYUnits: 'pixels',
					}),
				}),
			});

			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);

		// можно переместить карту на какую-то геометрию
		if (city) this.mapStore.fitGeo(city.geo, 13);
	};

	gotoRequest = () => {
		const { record } = this.store;
		store.route.push({ path: `/delivery/requests/${record.id}/ladingBill` });
	};

	render() {
		const { isLoading, record } = this.store;
		if (isLoading) return <Loader />;
		const id = parseInt(this.props.match.params.id);
		const path = `${REQUEST_PATH}/${id}`;
		const title = record.trackNumber;
		const rootTitle = t('tracking.title');

		return (
			<Provider store={this.store}>
				<div className='single-cargo'>
					<div className='left-panel'>
						<div className='header'>
							<div>
								<Breadcrumbs crumbs={[{ path: REQUEST_PATH, title: rootTitle }, { title }]} />
								<h2>{title}</h2>
							</div>
							<button className='header-btn' onClick={this.gotoRequest}>
								Перейти к завке
							</button>
						</div>
						<Tabs>
							<Tab title={t('tracking.menu.cargoTracking')} path={path} exact render={() => <CargoTracking />} />
							<Tab title={t('tracking.menu.mainInfo')} path={`${path}/main`} render={() => <Main />} />
						</Tabs>
						<Route path={`${path}/main`} exact>
							<Redirect to={`${path}/main/1`} />
						</Route>
					</div>
					<Map className='full-map' onInit={this.onMapInit}></Map>
				</div>
			</Provider>
		);
	}
}
