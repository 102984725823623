import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from 'fires/components';
import t from 'i18n';
import { observable } from 'mobx';
import store from 'client/store';
import { formatDate, toLowerCamelCase } from 'client/tools';
import './style.scss';

const MODEL_NAME = 'WaterSupply';
const EXCLUDED_FIELDS = ['id', 'createdAt', 'updatedAt', 'working'];
@observer
export default class Popup extends React.Component {
	@observable isLoading = true;
	recordPropValues = {};
	constructor(props) {
		super(props);
		this.model = store.model[MODEL_NAME];
		this.modelName = toLowerCamelCase(MODEL_NAME);
		const modelForeignKeys = Object.values(this.model.RELATIONS).map(({ foreignKey }) => foreignKey);
		this.properties = Object.keys(this.model.PROPERTIES).filter((prop) => {
			return ![...EXCLUDED_FIELDS, ...modelForeignKeys].includes(prop) && !['Object', 'Geography'].includes(this.model.PROPERTIES[prop].type);
		});
		this.init();
	}

	init = async () => {
		this.record = await this.model.findById(this.props.record.id, { include: ['type', 'division'] });
		for (const prop of this.properties) {
			if (this.record[prop]) {
				if (this.model.PROPERTIES[prop].type === 'Date') {
					this.recordPropValues[prop] = formatDate(this.record[prop]);
				} else {
					this.recordPropValues[prop] = this.record[prop];
				}
			}
		}
		this.isLoading = false;
	};

	go = () => store.route.push({ path: `/techfires/registry/${this.modelName}/${this.props.record.id}` });

	render() {
		if (this.isLoading) return null;
		return (
			<div className='map-water-supply-popup'>
				<div className='title'>
					<h2>
						{t(`${this.modelName}.title`)}:{' '}
						<a style={{ cursor: 'pointer' }} onClick={this.go}>
							#{this.record.id}
						</a>
					</h2>
				</div>
				<div className='water-supply-data'>
					{this.record.type && <TextField label={t('type')} value={this.record.type.name} />}
					{this.record.division && <TextField label={t('division')} value={this.record.division.name} />}
					{Object.keys(this.recordPropValues).map((prop) => (
						<TextField label={t(`${this.modelName}.${prop}`)} value={this.recordPropValues[prop]} key={prop} />
					))}
				</div>
			</div>
		);
	}
}
