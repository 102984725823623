import React from 'react';
import { inject, observer } from 'mobx-react';
import { Row } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';

@inject('store')
@observer
export class CustomerInfo extends React.Component {
	render() {
		const record = this.props.store.record.organization;
		if (!record) return <span>{t('request.noOwnerOrganization')}</span>;

		return (
			<div className='customer-info'>
				<div className='main'>
					<div className='form-field'>
						<label>{t('organization.title')} </label>
						<input value={record.name} disabled />
					</div>
					<div className='form-field'>
						<label>{t('organization.description')} </label>

						<>
							<div className='form-field'>
								<label>Основные сведения</label>
								<Row>
									<strong>Название</strong>
									<span>{record.name}</span>
								</Row>
								<Row>
									<strong>ИНН</strong>
									<span>{record.inn}</span>
								</Row>
								<Row>
									<strong>КПП</strong>
									<span>{record.kpp}</span>
								</Row>
								<Row>
									<strong>Юридический адрес</strong>
									<span>{record.address}</span>
								</Row>
								<Row>
									<strong>Фактический адрес</strong>
									<span>{record.factAddress}</span>
								</Row>
								<Row>
									<strong>Телефон</strong>
									<span>{record.phone}</span>
								</Row>
								<Row>
									<strong>E-mail</strong>
									<span>{record.email}</span>
								</Row>
							</div>
							<div className='form-field'>
								<label>Дополнительные сведения</label>
								<Row>
									<strong>ОКАТО</strong>
									<span>{record.okato}</span>
								</Row>
								<Row>
									<strong>ОКТМО</strong>
									<span>{record.oktmo}</span>
								</Row>
								<Row>
									<strong>ОКВЭД</strong>
									<span>{record.okved}</span>
								</Row>
								<Row>
									<strong>ОГРН</strong>
									<span>{record.ogrn}</span>
								</Row>
								<Row>
									<strong>ОКПО</strong>
									<span>{record.okpo}</span>
								</Row>
							</div>
							<div className='form-field'>
								<label>Данные о руководителе</label>
								<Row>
									<strong>ФИО Руководителя</strong>
									<span>{record.headname}</span>
								</Row>
								<Row>
									<strong>Должность руководителя</strong>
									<span>{record.headposition}</span>
								</Row>
							</div>
						</>
					</div>
				</div>
			</div>
		);
	}
}
