import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Field, Popup, RecordSelect } from '@smartplatform/ui';
import { Form } from 'components';
import store from 'client/store';
import t from 'i18n';
@inject('store')
@observer
export class EditExtinguishingAgentPopup extends React.Component {
	@observable record = null;
	@observable isNew;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.init();
	}

	init = async () => {
		this.forceAvailabilityId = this.props.forceAvailabilityId;
		this.record = this.props.record;
		// сохраняем оригинальную запись
		this.selectedRecordOriginal = { ...this.record };
		this.isNew = !this.record;
		if (this.isNew) {
			this.record = new store.model.ForceAvailabilityExtinguishingAgent();
		}
	};

	revertRecord = async () => {
		if (!this.isNew) {
			const { typeId: originalTypeId, formationId: originalFormationId } = this.selectedRecordOriginal;
			const { typeId: selectedTypeId, formationId: selectedFormationId } = this.record;
			// перезаписываем объект, если id было изменено
			if (originalTypeId !== selectedTypeId) {
				this.record.type = await store.model.ExtinguishingAgentsType.findById(originalTypeId, { fields: ['id', 'name'] });
			}
			if (originalFormationId !== selectedFormationId) {
				this.record.formation = await store.model.FireFightingFormation.findById(originalFormationId, { fields: ['id', 'name'] });
			}
			// получаем все поля модели ForceAvailabilityExtinguishingAgent, проходим по каждому полю и меняем значение на оригинальное значение (объекты не перезаписываются)
			const modelKeys = Object.keys(store.model['ForceAvailabilityExtinguishingAgent'].PROPERTIES);
			for (let key of modelKeys) {
				this.record[key] = this.selectedRecordOriginal[key];
			}
		}
	};

	onClosePopup = async () => {
		await this.revertRecord();
		this.props.onClose();
	};

	beforeSave = () => {
		this.store.addHasMany('agents', this.record);
		this.props.onClose();
		// сохраняем только новые реляции, редактируемые сохраним только при сохранении записи
		if (!this.isNew) return false;
	};
	beforeDelete = async () => {
		this.store.removeHasMany('agents', this.record);
		this.props.onClose();
		return false;
	};

	render() {
		const { onClose } = this.props;
		const title = this.props.record ? t('editRecord') : t('addRecord');
		return (
			<Popup className='force-availability-people-popup fires-popup' onClose={this.onClosePopup}>
				<div className='form-wrapper'>
					<div className='left'>
						<h2>{title}</h2>
						<Form
							beforeSave={this.beforeSave}
							beforeDelete={this.beforeDelete}
							disabled={this.props.store.disabled}
							noDelete={this.isNew}
							record={this.record}
							onDelete={onClose}
							onCancel={this.onClosePopup}
							stay
							disableSave={!this.record.typeId || !this.record.formationId || !this.record.count}
						>
							<Field relation='type' property='name' label={t('extinguishingAgentsType.title_short')} isRequired>
								<RecordSelect isRequired maxItems={10} itemsPerPage={1000} noTotal />
							</Field>
							<Field relation='formation' property='shortName' label={t('fireFightingFormation.title_short')} isRequired>
								<RecordSelect isRequired maxItems={10} itemsPerPage={1000} noTotal />
							</Field>
							<Field property='count' label={t('count')} isRequired />
						</Form>
					</div>
				</div>
			</Popup>
		);
	}
}
