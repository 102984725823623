import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Popconfirm } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { ExportButton } from 'components';

@inject('card')
@observer
export default class ActionsPanel extends React.Component {
	constructor(props) {
		super(props);
		this.local = store.local.fires.card;
	}

	onShowCountiesChange = (value) => {
		store.local.fires.card.layers.counties.show = value;
		store.local.save();
		this.props.card.countiesLayer.setVisible(value);
	};

	onShowForestryQuartersChange = (value) => {
		store.local.fires.card.layers.forestryQuarters.show = value;
		store.local.save();
		this.props.card.forestryQuartersLayer.setVisible(value);
	};

	onDelete = async () => {
		try {
			const fire = await store.model.Fire.findById(this.props.card.fire.fireId);
			await fire.delete();
			store.route.push({ path: `/fires/registry` });
		} catch (e) {
			console.log('Fire delete error: ', e);
		}
	};

	render() {
		const { card } = this.props;
		const { aviaDepId, forestryId, zoneId, geo, forestOwnershipId } = card.fire;
		const isSaveAvailable = card.changed && !card.disable && aviaDepId && forestryId && zoneId && forestOwnershipId && geo;
		const fullAccess = store.model.roles.find((role) => ['admin'].includes(role.name));

		return (
			<div className='justify-content-between'>
				<div className='card-actions'>
					<div>
						<Button onClick={card.save} disabled={!isSaveAvailable} variant='primary'>
							{t('save')}
						</Button>
					</div>
					<div>
						<ExportButton text={t('fire.fireAct')} onClick={card.downloadFireAct} disabled={!card.fire.id} />
						{fullAccess && card.fire.id && (
							<Popconfirm className='delete-btn' content={t('delete')} onConfirm={this.onDelete}>
								<Button variant='danger'>{t('fire.delete')}</Button>
							</Popconfirm>
						)}
					</div>
				</div>
			</div>
		);
	}
}
