import React from 'react';
import { Column, Field } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store'
import { ModelList, ModelEdit } from 'components';

const Edit = ({ modelName, path, match }) => {
	const id = parseInt(match.params.id);
	return <ModelEdit model={store.model[modelName]} id={id} path={path}>
		<div className="row">
			<Field property="template" label={t('notifyTemplate.template')} className="col-6" >
				<textarea rows={4}/></Field>
		</div>
	</ModelEdit>
};

const List = ({ modelName }) => {
	return <ModelList model={store.model[modelName]} >
		<Column width={100} property="id" label={t('id')} />
		<Column property="template" label={t('notifyTemplate.template')} />
	</ModelList >
};

export const NotifyTemplate = { Edit, List };