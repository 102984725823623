import React from 'react';
import { observer } from 'mobx-react';

import Period from './topbar';
import { Pie, Line } from './charts';
import Counties from './counties';
import Statistics from './statistics';
import Sources from './sources';
import store from 'client/store';
import appealsStore from './store';

@observer
export default class Map extends React.Component {

	componentDidMount() {
		// store.map.setControlPosition('appeals');
	}

	componentWillUnmount() {
		// store.map.setControlPosition('topright');
	}

	render() {
		return <>
			<Period />
			{/* {appealsStore.system.id !== 5 && <Pie />} */}
			<Pie />
			<Line />
			<Counties />
			<Statistics />
			{appealsStore.system.id === 1 && <Sources />}
		</>;
	}

}
