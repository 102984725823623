import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Table, Column, DateValue } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';
import Filters from './Filters';
import RaidStore from './store';
import { PER_PAGE } from './constants';
import { EditRaidPopup } from './single-record/EditRaidPopup';

@observer
export default class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new RaidStore();
		this.store.init();
	}

	renderPilots = (pilots = []) =>
		pilots.length > 0
			? pilots.map(
					(pilot) =>
						pilot.staff && (
							<div key={pilot.id}>
								{pilot.staff.fullName} {pilot.staff.tableNumber}
							</div>
						)
			  )
			: '-';
	renderAircraft = (record) => `${(record.type && `${record.type.name}`) || ''}  ${record.number ? `- ${record.number}` : ''}`;

	render() {
		if (this.store.isLoading) return null;

		return (
			<Provider store={this.store}>
				<div className='raids'>
					<h2>{t('airRaid.plural')}</h2>
					<Filters />
					{this.store.withPagination && (
						<Pager current={this.store.page} onChange={this.store.onPageChange} totalCount={this.store.records.totalCount} itemsPerPage={PER_PAGE} />
					)}
					<Table rows={this.store.records} multiline onRowClick={this.store.onRowClick}>
						<Column relation='aviaDep' property='name' label={t('aviaDep.title')} />
						<Column property='requestNumber' label={t('airRaid.requestNumber')} />
						<Column property='requestDate' label={t('airRaid.requestDate')}>
							<DateValue format='dd.MM.yyyy' />
						</Column>
						<Column property='departureDate' label={t('airRaid.departureDate')} />
						<Column property='arrivalDate' label={t('airRaid.arrivalDate')} />
						<Column relation='paymentGroup' property='name' label={t('paymentGroup.title')} />
						<Column relation='aircraft' computed={this.renderAircraft} label={t('aircraft.title')} />
						<Column relation='pilots' computed={this.renderPilots} label={t('observerPilot.plural')} />
					</Table>
					{this.store.showRaidPopup && <EditRaidPopup onClose={this.store.onClosePopup} record={this.store.selectedRecord} />}
				</div>
			</Provider>
		);
	}
}
