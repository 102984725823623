import React from 'react';
import { CancelButton as CancelButton_ } from 'components';
import { ControlsContext } from './Controls';

export const CancelButton = (props) => {
	const context = { ...React.useContext(ControlsContext), ...props };
	const { form, cancelTitle, onCancel, noCancel } = context;

	if (noCancel) return null;

	const onBack = async () => {
		if (form) {
			form.back();
		}
		if (onCancel) {
			onCancel();
		}
	};

	const buttonProps = { onClick: onBack };

	if (cancelTitle) {
		buttonProps.text = title;
	}

	return <CancelButton_ {...buttonProps} />;
};
