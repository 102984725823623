import React from 'react';
import { observer } from 'mobx-react';
import { DatePicker, Field, ManyToManySelect, Popup, RecordSelect, Row } from '@smartplatform/ui';
import { Form } from 'components';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import { MODEL_NAME } from './constants';
import Damage from './damage';

@observer
export class EditPopup extends React.Component {
	constructor(props) {
		super(props);
		this.model = store.model[MODEL_NAME];
		this.record = props.record || new this.model({ republicStatus: true });
		this.isNew = !this.record.id;
	}

	render() {
		const { onClose } = this.props;
		const { record, damage, model, isNew } = this;
		const title = !isNew ? t('editRecord') : t('addRecord');
		const isFormDisabled = !model?.INFO.WRITE;
		const isRequiedFilled = !!this.record.type;

		const nameFieldsProps = { filter: { fields: ['id', 'name'] } };

		return (
			<Popup width={1400} onClose={onClose} className='edit-emergency-situation fires-popup'>
				<h2>{title}</h2>
				<Form disabled={isFormDisabled} record={record} onSave={onClose} onDelete={onClose} stay onCancel={onClose} disableSave={!isRequiedFilled}>
					<div className='form-content'>
						<div className='left'>
							<Row>
								<Field property='startDate' label={t('emergencySituation.startDate')}>
									<DatePicker showClearButton={false} />
								</Field>
								<Field property='endDate' label={t('emergencySituation.endDate')}>
									<DatePicker showClearButton={false} />
								</Field>
							</Row>
							<Row>
								<Field property='startAct' label={t('emergencySituation.startAct')} />
								<Field property='endAct' label={t('emergencySituation.endAct')} />
							</Row>
							<Field relation='type' property='name' isRequired label={t('emergencySituation.type')}>
								<RecordSelect isRequired {...nameFieldsProps} />
							</Field>

							<div className='counties'>
								<Field property='republicStatus' label={t('emergencySituation.republicStatus')} />
								{!record.republicStatus && (
									<Field relation='counties' property='name' 	itemTag={(f) => f.name} label={t('emergencySituation.counties')}>
										<ManyToManySelect
											searchFields={['name']}
											property='name'		
											maxItems={10}
											itemsPerPage={1000}
											noTotal
											disabled={isFormDisabled}
											{...nameFieldsProps}
										/>
									</Field>
								)}
							</div>
							<Field property='comment' label={t('emergencySituation.comment')}>
								<textarea className='textarea' />
							</Field>
						</div>
						<Damage record={this.record} disabled={isFormDisabled} />
					</div>
				</Form>
			</Popup>
		);
	}
}
