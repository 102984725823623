import { observable } from 'mobx';
import store from 'client/store';
import { analyticReport } from './report';
import { OPER_INFO_INCLUDE } from 'delivery/analytics/constants';
import { exportReport } from 'client/tools';

export default class AnalyticStore {
	@observable data = null;
	@observable previousData = null;
	@observable recordsWithCounties = null;
	@observable countiesWithExecutionPlan = null;
	@observable recordsWithOrganizations = null;
	@observable isLoading = true;
	@observable year = new Date().getFullYear();
	@observable period = null;
	@observable periods = [];

	init = async () => {
		this.isLoading = true;
		if (!this.periods.length) {
			await this.fetchPeriods();
		}
		await this.fetchRecord();
		if (this.data) {
			await this.fetchCountiesWithExecutionPlan();
		}
		this.isLoading = false;
	};

	fetchPeriods = async () => {
		const periods = await store.model.RequestPeriod.find();
		this.periods = periods.map((period) => ({ label: period.name, value: period.id, code: period.code }));
		this.period = this.periods.find((period) => period.code === 'summer').value;
	};

	fetchRecord = async () => {
		const records = await store.model.RequestOperationalInfo.find({
			...this.getFilter(this.year),
			...OPER_INFO_INCLUDE,
		});
		const previousRecords = await store.model.RequestOperationalInfo.find({
			...this.getFilter(this.year - 1),
			...OPER_INFO_INCLUDE,
		});
		if (!records.length) {
			this.data = null;
			return;
		}
		this.data = this.getAnalytic(records);
		this.previousData = this.getAnalytic(previousRecords);
	};

	getFilter = (year) => {
		return { where: { and: [{ date: { between: [new Date(year, 0), new Date(year, 12)] } }, { periodId: this.period }] } };
	};

	getAnalytic = (records) => {
		const data = {
			total: { plan: 0, ship: 0, deliver: 0, planArc: 0, shipArc: 0, deliverArc: 0 },
			organization: {},
			category: {},
			county: {},
			countyWithCity: {},
		};
		for (const record of records) {
			data.total.plan += record.plan;
			data.total.ship += record.ship;
			data.total.deliver += record.deliver;

			if (record.riverId) {
				data.total.planArc += record.plan;
				data.total.shipArc += record.ship;
				data.total.deliverArc += record.deliver;
			}

			const organizationName = record.organization?.name || 'Не указан';
			if (!data.organization[organizationName]) {
				data.organization[organizationName] = { plan: 0, ship: 0, deliver: 0 };
			}
			data.organization[organizationName].plan += record.plan;
			data.organization[organizationName].ship += record.ship;
			data.organization[organizationName].deliver += record.deliver;

			if (record.countyId) {
				const countyName = record.county.name;
				if (!data.county[countyName]) {
					data.county[countyName] = { plan: 0, ship: 0, deliver: 0 };
				}
				data.county[countyName].plan += record.plan;
				data.county[countyName].ship += record.ship;
				data.county[countyName].deliver += record.deliver;
			}

			if (record.countyId) {
				const countyName = record.county.name;
				const cityName = record.city.name;

				if (!data.countyWithCity[countyName]) {
					data.countyWithCity[countyName] = {};
				}
				if (!data.countyWithCity[countyName][cityName]) {
					data.countyWithCity[countyName][cityName] = { plan: 0, ship: 0, deliver: 0 };
				}
				data.countyWithCity[countyName][cityName].plan += record.plan;
				data.countyWithCity[countyName][cityName].ship += record.ship;
				data.countyWithCity[countyName][cityName].deliver += record.deliver;
			}

			if (record.categoryId) {
				const categoryName = record.category.name;

				if (!data.category[categoryName]) {
					data.category[categoryName] = { plan: 0, ship: 0, deliver: 0 };
				}
				data.category[categoryName].plan += record.plan;
				data.category[categoryName].ship += record.ship;
				data.category[categoryName].deliver += record.deliver;
			}
		}
		console.log('Analytic', data);

		return data;
	};

	fetchCountiesWithExecutionPlan = async () => {
		let county = await store.model.County.find({ fields: ['id', 'name'] });
		county = county.map((c) => ({ name: c.name, id: c.id }));
		for (let countyElement of county) {
			let currentCounty = Object.entries(this.data.county).find(([name, value]) => name === countyElement.name);
			countyElement.plan = currentCounty?.[1]?.plan || 0;
			countyElement.ship = currentCounty?.[1]?.ship || 0;
			countyElement.deliver = currentCounty?.[1]?.deliver || 0;
			countyElement.cities = Object.entries(this.data.countyWithCity).find(([name, value]) => name === countyElement.name)?.[1] || [];
		}
		county.forEach((county) => {
			if (county.deliver && county.plan) {
				county.__ratio = county.deliver / county.plan;
			} else {
				county.__ratio = 0;
			}
		});
		this.countiesWithExecutionPlan = county;
	};

	onChange = (prop, value) => {
		this[prop] = value;
		this.init();
	};

	downloadReport = () => {
		const { filename, content } = analyticReport(this.data, this.year, this.countiesWithExecutionPlan);
		const downloadLink = document.createElement('a');
		downloadLink.href = 'data:application/vnd.ms-excel,\uFEFF ' + encodeURIComponent(content);
		downloadLink.download = `${filename}.xls`;
		downloadLink.click();

		//*ф-ия экспортит xlsx, нужна ли она здесь?
		// exportReport({filename, href: 'data:application/vnd.ms-excel,\uFEFF ' + encodeURIComponent(content)})
	};
}
