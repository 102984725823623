import Airfield from './airfield';
import Fire from './fire';
import Camera from './camera';
import FireData from './heat-points';
import ForestryQuarter from './forestry-quarter';
import TechnoZone from './techno-zone';
import AviaDep from './aviadep';
import City from './city';
import IsdmFire from './isdm';

export const modelsConfig = {
	Airfield,
	IsdmFire,
	Camera,
	Fire,
	ViewFirePublic: Fire,
	FireData,
	ForestryQuarter,
	TechnoZone,
	AviaDep,
	City,
};
