import React from 'react';
import { inject, observer } from 'mobx-react';
import { Row } from '@smartplatform/ui';
import { LabelField } from 'delivery/components';

@inject('store')
@observer
export class Summary extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { data, previousData } = this.store;
		const { plan, ship, deliver, planArc, shipArc, deliverArc } = data.total;
		const { plan: planPrev, ship: shipPrev, deliver: deliverPrev, planArc: planArcPrev, shipArc: shipArcPrev, deliverArc: deliverArcPrev } = previousData.total;

		return (
			<div className='summary card'>
				<Row>
					<div className={'text-uppercase font-weight-bold'}>Общий план, тонн</div>
					<div className={'text-uppercase font-weight-bold'}>Арктические реки, тонн</div>
				</Row>
				<hr />
				<Row>
					<div className={'text-uppercase font-weight-bold'}>Текущий год</div>
					<div className={'text-uppercase font-weight-bold'}>АППГ</div>
					<div className={'text-uppercase font-weight-bold'}>Текущий год</div>
					<div className={'text-uppercase font-weight-bold'}>АППГ</div>
				</Row>
				<hr />
				<Row>
					<LabelField label={'План'}>
						<span>{plan.toLocaleString('ru') || 0}</span>
					</LabelField>
					<LabelField label={'План'}>
						<span>{planPrev.toLocaleString('ru') || 0}</span>
					</LabelField>
					<LabelField label={'План'}>
						<span>{planArc.toLocaleString('ru') || 0}</span>
					</LabelField>
					<LabelField label={'План'}>
						<span>{planArcPrev.toLocaleString('ru') || 0}</span>
					</LabelField>
				</Row>
				<Row>
					<LabelField label={'Доставлено'}>
						<span>{deliver.toLocaleString('ru') || 0}</span>
					</LabelField>
					<LabelField label={'Доставлено'}>
						<span>{deliverPrev.toLocaleString('ru') || 0}</span>
					</LabelField>
					<LabelField label={'Доставлено'}>
						<span>{deliverArc.toLocaleString('ru') || 0}</span>
					</LabelField>
					<LabelField label={'Доставлено'}>
						<span>{deliverArcPrev.toLocaleString('ru') || 0}</span>
					</LabelField>
				</Row>
				<Row>
					<LabelField label={'Отгружено'}>
						<span>{ship.toLocaleString('ru') || 0}</span>
					</LabelField>
					<LabelField label={'Отгружено'}>
						<span>{shipPrev.toLocaleString('ru') || 0}</span>
					</LabelField>
					<LabelField label={'Отгружено'}>
						<span>{shipArc.toLocaleString('ru') || 0}</span>
					</LabelField>
					<LabelField label={'Отгружено'}>
						<span>{shipArcPrev.toLocaleString('ru') || 0}</span>
					</LabelField>
				</Row>
			</div>
		);
	}
}
