import React from 'react';
import { observer } from 'mobx-react';
import { Total, Counties, LastUpdate, Graphs, ModeSwitch, Address } from './components';
import store from 'client/store';

/**
 * UI раздела "Карта".
 */

@observer
export default class MapLayout extends React.Component {

	render() {
		return <>
			<ModeSwitch />
			<Total />
			<Counties />
			<LastUpdate />
			<Graphs />
			{store.covid.selectedAddress ? <Address address={store.covid.selectedAddress} /> : null}
		</>;
	}

}
