import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import debounce from 'lodash/debounce';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import { saveAs } from 'file-saver';
import { TextEncoder } from 'text-encoding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope, faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import NotifyPopup from './notify';
import { Button, Column, FileSelect, Pager, Table } from '@smartplatform/ui';
import store from 'client/store';
import { shortenAddressName } from 'client/tools';
import Filters from './filters';
import t from 'i18n';
import { NOTIFY_COLORS } from "client/tools/notifyStatus";

const PER_PAGE = 20;



@inject('listStore') @observer
export default class List extends React.Component {
	
	@observable isLoading = false;
	@observable records = null;
	@observable version = 2;
	@observable showPopup = false;
	
	constructor(props) {
		super(props);
		this.load = debounce(this.load, 500, { leading: true, trailing: true });
		this.listStore = props.listStore;
		this.listStore.reloadList = this.load;
		this.init();
	}
	
	@action init = async() => {
		this.mobileOperators = await store.model.MobileOperator.find({ order: 'id asc' });
		this.mobileStatuses = await store.model.MobileStatus.find({ order: 'id asc' });
		this.organizations = await store.model.Organization.find({ order: 'id asc' });
		if (store.model.user.organizationId) this.organization = await store.model.Organization.findById(store.model.user.organizationId);
		this.load();
	};
	
	@action load = async() => {
		this.isLoading = true;
		this.records = await this.getRecords();
		this.isLoading = false;
	};
	
	@action getRecords = async (pages = true) => {
		const filter = { where: { and: [] }};
		
		const filters = this.listStore.filters;
		
		const trimmed = filters.search.trim();
		if (trimmed.length > 0) {
			const words = trimmed.replace(',', ' ').replace(/\s+/g, ' ').split(' ');
			const or = [
				// { addressName: { ilike: `%${words.join('%')}%` } },
				// { phone: { like: `${trimmed}%` } }
			];
			const and = words.map(word => ({
				or: [
					{ lastName: { ilike: `%${word}%`}},
					{ firstName: { ilike: `%${word}%`}},
					{ middleName: { ilike: `%${word}%`}},
					{ addressName: { ilike: `%${word}%` } },
					{ phone: { like: `%${word}%` } }
				]
			}));
			or.push({ and });
			filter.where.and.push({ or });
		}
		
		if (filters.dateFrom || filters.dateTo) {
			const start = filters.dateFrom ? startOfDay(new Date(filters.dateFrom)) : null;
			const end = filters.dateTo ? endOfDay(new Date(filters.dateTo)) : null;
			const and = [];
			if (start) and.push({ date: { gte: start }});
			if (end) and.push({ date: { lte: end }});
			filter.where.and.push({ and });
		}
		
		if (filters.status) {
			filter.where.and.push({ statusId: filters.status.id });
		}
		
		if (filters.isolation) {
			filter.where.and.push({ isolationId: filters.isolation.id });
		}

		if (filters.contacted) {
			filter.where.and.push({ contacted: true });
		}

		if (filters.isPublic !== 'all') {
			if (filters.isPublic === 'show') {
				filter.where.and.push({ isPublic: true });
			}
			else {
				filter.where.and.push({
					or: [
						{ isPublic: false },
						{ isPublic: null },
					]
				});
			}
		}

		if (filters.mobileOperator) {
			filter.where.and.push({ mobileOperatorId: filters.mobileOperator !== 'none' ? filters.mobileOperator.id : null });
		}
		
		if (filters.mobileStatus) {
			filter.where.and.push({ mobileStatusId: filters.mobileStatus.id });
		}
		
		if (filters.city) {
			filter.where.and.push({ cityId: filters.city.id });
		}
		
		if (filters.county) {
			filter.where.and.push({ countyId: filters.county.id });
		}

		if (!store.model.user.organizationId) {
			if (filters.organization !== 'all') {
				let organizationId = filters.organization === 'none' ? null : filters.organization.id;
				filter.where.and.push({ organizationId })
			}
		}
		else {
			filter.where.and.push({ organizationId: store.model.user.organizationId })
		}

		if (filters.abroad) {
			filter.where.and.push({ abroad: true });
		}
		
		if (filters.agreed) {
			filter.where.and.push({ agreed: true });
		}
		
		if (filters.medved) {
			filter.where.and.push({ medved: true });
		}

		if (filters.violators) {
			filter.where.and.push({
				and: [
					{ distance0: { gt: filters.maxDistance } },
				]
			});
		}

		if ((filters.notifyDateFrom || filters.notifyDateTo) && (filters.notifyStatus?.id !== null)) {
			const start = filters.notifyDateFrom ? startOfDay(new Date(filters.notifyDateFrom)) : null;
			const end = filters.notifyDateTo ? endOfDay(new Date(filters.notifyDateTo)) : null;
			const and = [];
			if (start) and.push({
				or: [
					{ notifySmsDate: { gte: start } },
					{ notifyPhoneDate: { gte: start } }
				]
			});
			if (end) and.push({
				or: [
					{ notifySmsDate: { lte: end } },
					{ notifyPhoneDate: { lte: end } }
				]
			});
			filter.where.and.push({ and });
		}

		const isNoType = !filters.notifyType;
		const notifyTypeId = filters.notifyType?.id;
		const notifyStatusId = filters.notifyStatus?.id;
		const isSmsType = notifyTypeId === 1;
		const isPhoneType = notifyTypeId === 2;



		if (filters.notifyStatus) {
			//* если статус = "не отправлялось" и выбран любой из типов сообщений
			if ((notifyStatusId === null)) {

				const or = [];
				if (isNoType) {
					or.push(
						{
							and: [
								{ notifySmsStatusId: null },
								{ notifyPhoneStatusId: null }
							]
						}
					)
				} else {
					isSmsType && or.push({ notifySmsStatusId: null });
					isPhoneType && or.push({ notifyPhoneStatusId: null });
				}

				if (filters.notifyDateFrom || filters.notifyDateTo) {
					const start = filters.notifyDateFrom ? startOfDay(new Date(filters.notifyDateFrom)) : null;
					const end = filters.notifyDateTo ? endOfDay(new Date(filters.notifyDateTo)) : null;

					if (start) {
						(isSmsType || isNoType) && or.push({ notifySmsDate: { lte: start }, });
						(isPhoneType || isNoType) && or.push({ notifyPhoneDate: { lte: start } });
					}
					if (end) {
						(isSmsType || isNoType) && or.push({ notifySmsDate: { gte: end } });
						(isPhoneType || isNoType) && or.push({ notifyPhoneDate: { gte: end } });
					}
				}
				filter.where.and.push({ and: [{ or }] });

			} else if (filters.notifyType){
				isSmsType && filter.where.and.push({ notifySmsStatusId: notifyStatusId });
				isPhoneType && filter.where.and.push({ notifyPhoneStatusId: notifyStatusId });

			} else {
				filter.where.and.push({
					and: [{
						or: [
							{ notifySmsStatusId: notifyStatusId },
							{ notifyPhoneStatusId: notifyStatusId }
						]
					}]
				});
			}
		}

		if (filters.notifyType) {
			if (notifyStatusId !== null) {
				isSmsType && filter.where.and.push({ notifySmsTypeId: notifyTypeId });
				isPhoneType && filter.where.and.push({ notifyPhoneTypeId: notifyTypeId });
			}
		}

		if (filters.notifyPhoneAccepted) {
			let and;
			const accepted = filters.notifyPhoneAccepted === 'true';
			if (!accepted) and = {notifyPhoneAccepted: false};
			else and = { or : [{notifyPhoneAccepted: true} , {notifyPhoneAccepted: null}]}
			filter.where.and.push(and);
		}

		if (filters.startAge) {
			const age = parseInt(filters.startAge);
			filter.where.and.push({ age: { gte: age } });
		}

		if (filters.endAge) {
			const age = parseInt(filters.endAge);
			filter.where.and.push({ age: { lte: age } });
		}



		if (filter.where.and.length === 0) delete filter.where;
		//фильтры для запроса
		this.listStore.requestFilter = filter.where ? filter : undefined;
		return await store.model.ViewCase.find({
			include: [
				{
					relation: 'address',
					scope: {
						fields: ['id', 'name', 'cityId', 'countyId', 'typeId'],
						include: [
							{
								relation: 'type',
								scope: {
									fields: ['id', 'name'],
								},
							}
						],
					},
				},
				{ relation: 'mobileStatus' }
			],
			limit: pages ? PER_PAGE : undefined,
			skip: pages ? (filters.page - 1) * PER_PAGE : undefined,
			order: 'id desc',
			...filter,
		});
		
	};

	@action onSearch = e => {
		this.listStore.filters.search = e.target.value;
		this.listStore.page = 1;
		this.load();
	};
	
	onPageChange = async (page) => {
		this.listStore.filters.page = page;
        this.load();
    };

	onToggleShowPopup = boolean => this.showPopup = boolean;
	
	create = () => {
		this.props.history.push(`/covid/cases/create`);
	};
	
	edit = (record) => {
		this.props.history.push(`/covid/cases/${record.id}`);
	};

	buildingType = address => address.type ? address.type.name : '-';
	
	hilightRow = record => record.id === parseInt(store.route.path.replace(/\/covid\/cases\//, ''));
	
	renderAddress = record => {
		let title = '';
		if (record.address) {
			title += `${shortenAddressName(record.address.name)} [id: ${JSON.stringify(record.addressId)}]\n`;
			// title += `county: ${record.county ? record.county.name : '-'} [id: ${JSON.stringify(record.countyId)}]\n`;
			// title += `city: ${record.city ? record.city.name : '-'} [id: ${JSON.stringify(record.cityId)}]`;
		}
		return <div className="address-fio">
			<div className="address" title={title}>{record.address ? shortenAddressName(record.address.name) : '-'}</div>
			<div className="fio">{(record.lastName || '') + ' ' + (record.firstName || '') + ' ' + (record.middleName || '')}</div>
		</div>;
	};
	
	exportCSV = async () => {
		let exp = 'id; Фамилия; Имя; Отчество; Телефон; Адрес; Дата; Тип дома; Статус; Изоляция; Контактный; Статус отслеживания \n';
		const records = await this.getRecords(false);
		records.forEach(record => {
			exp += record.id + '; '
				+ (record.lastName || '') + '; '
				+ (record.firstName || '') + '; '
				+ (record.middleName || '') + '; '
				+ (record.phone || '') + '; '
				+ (record.address ? record.address.name : '') + '; '
				+ format(new Date(record.date), 'dd.MM.yyyy') + '; '
				+ (record.address && record.address.type ? record.address.type.name : '') + '; '
				+ (record.statusId ? store.covid.getStatusById(record.statusId).name : '') + '; '
				+ (record.isolationId ? store.covid.getIsolationById(record.isolationId).name : '') + '; '
				+ (record.contacted ? 'контактный' : '') + '; '
				+ (record.mobileStatus ? record.mobileStatus.name : '')
				+ '\n';
		});
		let csv = new TextEncoder('windows-1251', { NONSTANDARD_allowLegacyEncoding: true }).encode([exp]);
		let blob = new Blob([csv], {type: 'text/csv;charset=windows-1251;'});
		
		const date = format(new Date(), 'dd.MM.yyyy');
		saveAs(blob, `cases-${date}.csv`);
	};

	dateAndType = record => <div className="date-type">
		{record.date ? <div>{format(new Date(record.date), 'dd.MM.yyyy')}</div> : null}
		<small>{record.address ? (record.address.type ? record.address.type.name : '-') : ''}</small>
	</div>;

	renderPhone = record => {
		const style = { marginLeft: '5px' };
		return <div>
			{record.phone}
			{record.notifySmsStatusCode &&
			<span title={record.notifySmsResponse || ''}>
				<FontAwesomeIcon style={style} color={NOTIFY_COLORS[record.notifySmsStatusCode]} icon={faEnvelope}/>
			</span>
			}
			{record.notifyPhoneStatusCode &&
			<span title={record.notifyPhoneResponse || ''}>
				{(record.notifyPhoneAccepted === false) 
				? <FontAwesomeIcon style={style} color={NOTIFY_COLORS[record.notifyPhoneStatusCode]} icon={faPhoneSlash}/> 
				: <FontAwesomeIcon style={style} color={NOTIFY_COLORS[record.notifyPhoneStatusCode]} icon={faPhone}/>}
			</span>
			}
		</div>
	};

	importData = async (files) => {
		try {
			const file = files[0];

			function readFile(file){
				return new Promise((resolve, reject) => {
					var fr = new FileReader();
					fr.onload = () => {
						resolve(fr.result )
					};
					fr.onerror = reject;
					fr.readAsBinaryString(file);
				});
			}

			const binaryString = await readFile(file)
			await store.model.Case.importCases(binaryString)
			this.load();
		} 
		catch (e) {
			console.log(e);
		}
	};

	render () {
		if (!this.records) return <div className="list-pane">...</div>;

		const operators = this.mobileOperators.map(operator => ({ label: operator.name, value: operator }));
		operators.splice(0, 0, { label: 'не определен', value: 'none' });

		return <div className={'list-pane version-' + this.version}>
			{this.version === 2 && <Filters />}
			<div className="list">
				<div className="top">
					<Button variant="primary" onClick={this.create}>{t('add')}</Button>
					<input type="text" value={this.listStore.filters.search} onChange={this.onSearch} placeholder="Поиск по записям" />
					<div>
						<Button variant="primary" onClick={this.exportCSV}><FontAwesomeIcon icon={faDownload} /> {t('export')}</Button>
					</div>
					<div>
						<FileSelect
							disabled={this.isSendingFile}
							variant='primary'
							title={t('import')}
							onSelect={this.importData}
							type='file'
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						/>
					</div>
				</div>
				{this.version === 1 && <Filters />}
				<div className='pager'>
				<Pager
					itemsPerPage={PER_PAGE}
					totalCount={this.records.totalCount}
					current={this.listStore.filters.page}
					onChange={this.onPageChange}
				/>
					<div>
						<Button disabled={this.records.length === 0} variant="primary" onClick={() => this.onToggleShowPopup(true)}><FontAwesomeIcon
							icon={faEnvelope}/> {t('notifyLog.mailing')}</Button>
						{this.showPopup && <NotifyPopup count={this.records.totalCount} onClose={() => this.onToggleShowPopup(false)}/>}
					</div>
				</div>

				<div className="list-container">
					<Table rows={this.records} hilightRow={this.hilightRow} onRowClick={this.edit} className={'multiline' + (this.isLoading ? ' is-loading' : '')}>
						<Column width={50} property="id" label={t('ID')}/>
						<Column width={110} computed={this.renderPhone} label="Телефон"/>
						{/*<Column width={60} relation="address" property="id" label="id"/>*/}
						<Column computed={this.renderAddress} property="name" label="Адрес"/>
						{/*<Column computed={this.renderAddressName} label="Адрес"/>*/}
						{/*<Column width={100} relation="address" computed={this.buildingType} label="Тип"/>*/}
						<Column width={80} computed={this.dateAndType} />
						{/*<Column width={60} property="date" label="Дата"><DateValue format="DD.MM.YYYY"/></Column>*/}
					</Table>
				</div>
			</div>
		</div>;
	}
}
