import React from 'react';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import t from 'i18n';
import { observable } from "mobx";
import store from "client/store";

@observer
export default class ForestryQuarterPopup extends React.Component {
	
	@observable fullRecord = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.fullRecord = await store.model.ForestryQuarter.findById(this.props.record.id, {
			include: [
				{ relation: 'forestry', scope: { fields: ['id', 'name' ]}},
				{ relation: 'forestryPrecinct', scope: { fields: ['id', 'name' ]}},
				{ relation: 'forestCategory', scope: { fields: ['id', 'name' ]}},
				{ relation: 'aviaDep', scope: { fields: ['id', 'name' ]}},
				{ relation: 'monitoringZone', scope: { fields: ['id', 'name' ]}},
			],
		});
		this.isLoading = false;
	};
	
	// go = () => store.route.push({ path: `/techfires/dictionaries/hydrant/${this.props.record.id}` });
	
	render() {
		const { record } = this.props;
		
		let fullContent = <Loader size={18} />;
		if (!this.isLoading) {
			fullContent = this.fullRecord ? <>
					<div className="attr-value">
						<label>{t('forestryQuarter.code')}</label>
						<div>{this.fullRecord.code || '-'}</div>
					</div>
					<div className="attr-value">
						<label>{t('forestryQuarter.area')}</label>
						<div>{this.fullRecord.area || '-'}</div>
					</div>
					<div className="attr-value">
						<label>{t('forestry.title')}</label>
						<div>{this.fullRecord.forestry ? this.fullRecord.forestry.name : '-'}</div>
					</div>
					<div className="attr-value">
						<label>{t('forestryPrecinct.title')}</label>
						<div>{this.fullRecord.forestryPrecinct ? this.fullRecord.forestryPrecinct.name : '-'}</div>
					</div>
					<div className="attr-value">
						<label>{t('forestCategory.title')}</label>
						<div>{this.fullRecord.forestCategory ? this.fullRecord.forestCategory.name : '-'}</div>
					</div>
					<div className="attr-value">
						<label>{t('aviaDep.title')}</label>
						<div>{this.fullRecord.aviaDep ? this.fullRecord.aviaDep.name : '-'}</div>
					</div>
					<div className="attr-value">
						<label>{t('monitoringZone.title')}</label>
						<div>{this.fullRecord.monitoringZone ? this.fullRecord.monitoringZone.name : '-'}</div>
					</div>
				</>
				:
				null;
		}
		
		return <div className="hydrant-popup">
			<div className="attributes">
				{fullContent}
			</div>
		</div>;
	}
}
