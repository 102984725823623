import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Table, Column, Checkbox, Button } from '@smartplatform/ui';
import { fireDepVehicleItem } from 'techfires/helpers';
import t from 'i18n';

@inject('sentryStore') @observer
export default class Personnel extends React.Component {
	
	constructor(props) {
		super(props);
		this.store = props.sentryStore;
	}
	
	render() {
		const { record, fireDep, renderChecked } = this.store;
		
		return <div>
			<Table rows={fireDep.personnel()}>
				<Column label={<FontAwesomeIcon icon={faCheck} />} width={30}>{renderChecked('personnel')}</Column>
				<Column property="id" label={t('id')} width={50} />
				<Column property="lastName" label={t('lastName')} />
				<Column property="firstName" label={t('firstName')} />
				<Column property="middleName" label={t('middleName')} />
				<Column relation="status" property="name" label={t('fireDepPersonnel.status')} />
				<Column relation="vehicle" computed={fireDepVehicleItem} width={260} label={t('fireDepVehicle.title')} />
			</Table>
		</div>;
	}
}
