import React from 'react';
import { Column, Table, Pager } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import { REQUEST_PATH } from '../constants';
import store from 'client/store';
import t from 'i18n';
import { formatDate } from 'client/tools';
import { renderTrackStatus } from './helper';

@inject('store')
@observer
export class List extends React.Component {
	onRowClick = (record) => store.route.push({ path: `${REQUEST_PATH}/${record.id}` });

	render() {
		const { onQueryUpdate, onChange, totalCount, page, requestFilter } = this.props.store;

		return (
			<div className='requests-list'>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} />
				<Table query={requestFilter} onQueryUpdate={onQueryUpdate} model={store.model.ViewRequest} onRowClick={this.onRowClick}>
					<Column property='trackNumber' label={t('request.trackNumber')} />
					<Column property='typeName' label={t('tracking.category')} />
					<Column relation='tracks' computed={renderTrackStatus} label={t('tracking.cargoStatus')} />
					<Column property='direction' label={t('tracking.direction')} />
					<Column computed={(r) => formatDate(r.deliveryDate)} label={t('tracking.planedDeliveryDate')} />
					<Column computed={(r) => formatDate(r.actualDeliveryDate)} label={t('tracking.actualDeliveryDate')} />
				</Table>
			</div>
		);
	}
}
