import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Table, Column, Checkbox, Button } from '@smartplatform/ui';
import store from 'client/store';
import { fireDepSpecialTypeItem } from 'techfires/helpers';
import t from 'i18n';

@inject('sentryStore')
@observer
export default class Vehicles extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.sentryStore;
	}

	render() {
		const { record, fireDep, renderChecked } = this.store;

		return (
			<div>
				<Table rows={fireDep.vehicles()}>
					<Column label={<FontAwesomeIcon icon={faCheck} />} width={30}>
						{renderChecked('vehicles')}
					</Column>
					<Column property='stateNumber' label={t('vehicle.stateNumber')} />
					<Column relation='brand' property='name' label={t('fireDepVehicle.brand')} />
					<Column relation='status' property='name' label={t('fireDepVehicle.status')} />
					<Column relation='type' property='name' label={t('fireDepVehicle.type')} />
					<Column relation='type' computed={fireDepSpecialTypeItem} label={t('fireDepVehicle.specialType')} />
					<Column property='pumpCapacity' label={t('pump.title')} />
					<Column property='year' label={t('vehicle.year')} />
					<Column property='passport' label={t('vehicle.passport')} />
					<Column property='capacity' label={t('vehicle.capacity')} />
					{/* <Column property='foamCapacity' label={t('vehicle.foamCapacity')} /> */}
					<Column relation='callSign' property='name' label={t('vehicle.callSign')} />
				</Table>
			</div>
		);
	}
}
