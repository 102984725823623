import React from 'react';
import { inject, observer } from 'mobx-react';
import { RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@inject('card') @observer
export default class Charactaristic extends React.Component {

	render() {
		const { card } = this.props;
		const className = 'form-field';
		const disabled = !store.model.Fire.INFO.WRITE;

		return <div>
			<h2 style={{whiteSpace:'nowrap'}}>{t('FireCharacteristics.location')}</h2>
			<div className={className}>
				<label>{t('breed.title')}</label>
				<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
					model={store.model.Breed}
					property="name"
					value={card.fire.passedBreed}
					onChange={card.onChange('passedBreed')}
					disabled={disabled}
				/>
			</div>
			<div className={className}>
				<label>{t('cover.title')}</label>
				<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
					model={store.model.Cover}
					property="name"
					value={card.fire.cover}
					onChange={card.onChange('cover')}
					disabled={disabled}
				/>
			</div>
			<div className={className}>
				<label>{t('forestCategory.plural')}</label>
				<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
					model={store.model.ForestCategory}
					property="name"
					value={card.fire.forestCategory}
					onChange={card.onChange('forestCategory')}
					disabled={disabled}
				/>
			</div>
			<div className={className}>
				<label>{t('fireCause.title')}</label>
				<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
					model={store.model.FireCause}
					property="shortName"
					value={card.fire.cause}
					onChange={card.onChange('cause')}
					disabled={disabled}
				/>
			</div>
			<div className={className}>
				<label>{t('landCategory.plural')}</label>
				<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
					model={store.model.LandCategory}
					property="name"
					value={card.fire.landCategory}
					onChange={card.onChange('landCategory')}
					disabled={disabled}
				/>
			</div>
			<div className='form-field'>
				<label>{t('determiningMethod.title')}</label>
				<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
					model={store.model.DeterminingMethod}
					property="name"
					value={card.fire.determiningMethod}
					onChange={card.onChange('determiningMethod')}
					disabled={disabled}
				/>
			</div>
			<div className='form-field'>
				<label>{t('tenant.title')}</label>
				<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
					model={store.model.Tenant}
					property="name"
					value={card.fire.tenant}
					onChange={card.onChange('tenant')}
					disabled={disabled}
				/>
			</div>

			{/* <div className={className}>
				<label>{t('discoveryMessageType.title')}</label>
				<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
					model={store.model.DiscoveryMessageType}
					property="name"
					value={card.fire.messageType}
					onChange={card.onChange('messageType')}
					disabled={disabled}
				/>
			</div> */}
		</div>
	}
};
