export const MODEL_NAME = 'Reserve';
export const RESERVES_PATH = '/delivery/reserves';
export const LOCAL_STORAGE_FIELDS = ['showFilters', 'openedCategories', 'selectedCategories', 'selectedSubCategories', 'page', 'search', 'selectedStorehouse'];
export const CAPACITY_ABBREVIATE = { Килограмм: 'кг', Штука: 'шт', Литр: 'л', Тонна: 'т', Упаковка: 'у' };

// include
export const RESERVES_INCLUDE = {
	include: [
		['category', 'measure'].map((rel) => ({ relation: rel, scope: { fields: ['id', 'name', 'shortName'] } })),
		{ relation: 'movements', scope: { fields: ['id', 'quantity'] } },
		{ relation: 'storehouse', scope: { fields: ['id', 'capacity', 'name', 'measureId'], include: ['measure'] } },
		{ relation: 'nomenclature', scope: { fields: ['id', 'name'] } },
	],
};
export const RESERVE_INCLUDE = {
	include: [
		['category', 'measure'].map((rel) => ({ relation: rel, scope: { fields: ['id', 'name', 'shortName'] } })),
		{ relation: 'storehouse', scope: { fields: ['id', 'name', 'capacity'] } },
		{ relation: 'nomenclature', scope: { fields: ['id', 'name'] } },
		{
			relation: 'movements',
			scope: { fields: ['id', 'date', 'storehouseId', 'quantity'], include: { relation: 'storehouse', scope: { fields: ['id', 'name'] } } },
		},
	],
};
