import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Loader } from '@smartplatform/ui';
import store from 'client/store';
import OrganizationsTree from './OrganizationsTree';
import './style.scss';

@observer
export default class TechFireOrganizations extends React.Component {
	
	@observable records = [];
	@observable selectedId = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.records = await store.model.TechFireOrganization.find({
			include: [
				{ relation: 'children' },
				{ relation: 'fireDepartments', scope: { fields: ['id'] }},
			],
		});
		const selected = this.records.find(r => r.parentId === null);
		this.selectedId = selected ? selected.id : null;
		this.isLoading = false;
	};
	
	onSelect = (record) => {
		this.selectedId = record.id;
		this.props.onSelect && this.props.onSelect(record);
	};
	
	render() {
		if (this.isLoading) return <Loader />;
		
		return <div className="tree-root">
			<OrganizationsTree allRecords={this.records} selectedId={this.selectedId} onSelect={this.onSelect} />
		</div>;
	}
}
