import { observable } from 'mobx';
import { endOfDay, startOfDay } from 'date-fns';

export default class FireReportStore {
	@observable date1 = startOfDay(new Date());
	@observable date2 = endOfDay(new Date());

	get storeParams() {
		return {
			reportCode: 'LO1Section1',
			reportParams: { date1: this.date1, date2: this.date2 },
			isHTML: true,
		};
	}

	onDateChange1 = (date) => {
		if (date > this.date2) this.date2 = endOfDay(date);
		this.date1 = startOfDay(date);
	};
	onDateChange2 = (date) => {
		if (this.date1 > date) this.date1 = startOfDay(date);
		this.date2 = endOfDay(date);
	};
}
