import React from 'react';
import { observer, Provider } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import { Panel, HeatPointsFilter, CountiesFilter, GenShtabFilter, WeatherFilter, WindFilter, DzzFilter, ModelsFilter } from 'components';
import Forestries from './Forestries';
import ForestryQuarters from './ForestryQuarters';
import Fires from './Fires';
import store from 'client/store';
import fireStore from '../../fireStore';
import './style.scss';

@observer
export default class Filters extends React.Component {

	render() {
		if (!fireStore.mapInitialized) return null;
		const { isPublic } = store;

		const localSettings = store.local.fires;
		const settings = localSettings.layers;

		return localSettings.filtersMaximized ?
			<Panel left={-5} top={5} width={290} bottom={70} height="auto" scrollable={false} wrapperClassName={'fires-filters-wrapper' + (!isPublic ? ' private' : '')} className="fires-filters">
				<div className="minimize-btn" onClick={() => fireStore.switchFilters(false)}><FontAwesomeIcon icon={faFilter} /></div>
				{/*{!isPublic && <Countries layer={fireStore.countriesLayer} />}*/}
				{!isPublic && <CountiesFilter layer={fireStore.countiesLayer} settings={settings.counties} />}
				{!isPublic && <Forestries layer={fireStore.forestriesLayer} settings={settings.forestries} />}
				{!isPublic && <DzzFilter layer={fireStore.dzzLayer} settings={settings.dzz} />}
				{!isPublic && <GenShtabFilter layer={fireStore.genShtabLayer} settings={settings.genShtab} />}
				{!isPublic && <ModelsFilter layer={fireStore.modelsLayer} settings={settings.models} />}
				{!isPublic && <ForestryQuarters layer={fireStore.quartersLayer} settings={settings.forestryQuarters} />}
				<Fires layer={fireStore.firesLayer} store='fires' settings={settings.fires}/>
				<HeatPointsFilter layer={fireStore.heatPointsLayer} settings={settings.heatPoints} />
				<WeatherFilter layer={fireStore.weatherLayer} settings={settings.weather} />
				{!isPublic && <WindFilter layer={fireStore.windLayer} settings={settings.wind} />}
			</Panel>
			:
			<div className={'fires-filters-minimized' + (!isPublic ? ' private' : '')} onClick={() => fireStore.switchFilters(true)}>
				<FontAwesomeIcon icon={faFilter} />
			</div>;
	}
}
