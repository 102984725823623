import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, DatePicker, Select } from '@smartplatform/ui';
import store from 'client/store';
import { ExportButton } from 'components';
import t from 'i18n';
import { MENU_ITEMS } from './constants';
import { formatDate } from 'client/tools';
import './style.scss';

@observer
export class Report extends React.Component {
	@observable record = null;
	@observable date = new Date();
	@observable isLoading = false;
	@observable reportName = MENU_ITEMS[1];
	@observable reportHtml = '';

	constructor(props) {
		super(props);
		this.loadLocalStorage();
		this.init();
	}
	saveLocalStorage = () => {
		store.local.fires.isdm = { reportName: this.reportName };
		store.local.save();
	};

	loadLocalStorage = () => {
		this.reportName = store.local.fires.isdm.reportName;
	};

	onGenerate = async () => {
		this.isLoading = true;
		this.record = null;
		await store.model.IsdmReport.import({
			reportName: this.reportName,
			saveToStorage: true,
			reportParams: { date: this.date },
		});
		await this.init();
		this.isLoading = false;
	};

	loadReport = async (report = 'METEO', filetype = 'html') => {
		const response = await fetch(`/api/IsdmReports/load?report="${report}"&filetype="${filetype}"`);
		return await response.text();
	};

	init = async () => {
		this.record = (await store.model.IsdmReport.find({ where: { report: this.reportName }, order: 'id desc', limit: 1 }))[0];
		if (this.record) {
			this.reportHtml = await this.loadReport(this.reportName, 'html');
		}
	};

	onExport = () => {
		const element = document.createElement('a');
		element.href = `/api/IsdmReports/load?report="${this.props.name}"&filetype="xlsx"`;
		element.rel = 'noopener noreferrer';
		element.download = this.props.name;
		element.click();
		element.remove();
	};

	onChange = (prop) => (value) => (this[prop] = value);
	onReportChange = (value) => {
		this.reportName = value;
		this.saveLocalStorage();
		this.init();
	};

	render() {
		const { reportName, onChange, onExport, record, onGenerate, isLoading, onReportChange } = this;
		const content = isLoading ? t('loading') : <div className='isdm-report' dangerouslySetInnerHTML={{ __html: this.reportHtml }} />;

		return (
			<div className='isdm-page'>
				<h2>{t('isdm.titleLong')}</h2>
				<div className='filters'>
					<Select items={MENU_ITEMS.map((i) => ({ label: t('isdm.' + i), value: i }))} value={reportName} onChange={onReportChange} width={250} />
					<DatePicker value={this.date} onChange={onChange('date')} showClearButton={false} />
					<Button onClick={onGenerate} className='ml-2 mr-2' variant='primary' disabled={isLoading}>
						{t('update')}
					</Button>
					<ExportButton text={t('report.download')} disabled={!record} onClick={onExport} />
				</div>
				{record && (
					<b className='mb-1 mt-1'>
						{t('report.downloadDate')} {formatDate(record.createdAt)}
					</b>
				)}
				{content}
			</div>
		);
	}
}
