import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Field, RecordSelect, MaskedInput, Popup, Row } from '@smartplatform/ui';
import { Form } from 'components';
import { fireName } from '../../../helpers';
import store from 'client/store';
import t from 'i18n/index';
import { endOfToday, startOfToday } from 'date-fns';
import { EXCLUDED_STATUSES } from 'fires/registry/constants';

@inject('store')
@observer
export default class EditJobPopup extends React.Component {
	@observable airJob = null;
	@observable fire = null;
	@observable isNew;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.init();
	}

	init = async () => {
		this.airJob = this.props.airJob;
		// сохраняем оригинальную запись
		this.selectedRecordOriginal = { ...this.airJob };
		this.isNew = !this.airJob;
		if (this.isNew) {
			this.airJob = new store.model.AirJob({ duration: '00:00' });
		}
		this.fire = this.airJob.fire;
	};

	revertRecord = async () => {
		if (!this.isNew) {
			const { fireId: originalFireId, forestOwnershipId: originalForestOwnershipId, typeId: originalTypeId } = this.selectedRecordOriginal;
			const { fireId: selectedFireId, forestOwnershipId: selectedForestOwnershipId, typeId: selectedTypeId } = this.airJob;
			// перезаписываем объект, если id было изменено
			if (originalFireId !== null && originalFireId !== selectedFireId) {
				this.airJob.fire = await store.model.Fire.findById(originalFireId, { fields: ['id', 'name'] });
			}
			if (originalForestOwnershipId !== null && originalForestOwnershipId !== selectedForestOwnershipId) {
				this.airJob.forestOwnership = await store.model.ForestOwnership.findById(originalForestOwnershipId, { fields: ['id', 'name'] });
			}
			console.log(originalTypeId, selectedTypeId);
			if (originalTypeId !== selectedTypeId) {
				this.airJob.type = await store.model.AirJobType.findById(originalTypeId, { fields: ['id', 'name'] });
			}
			// получаем все поля модели AirJob, проходим по каждому полю и меняем значение на оригинальное значение (объекты не перезаписываются)
			const modelKeys = Object.keys(store.model['AirJob'].PROPERTIES);
			for (let key of modelKeys) {
				this.airJob[key] = this.selectedRecordOriginal[key];
			}
		}
	};

	onTypeChange = (type) => {
		if (type.code !== 'extinguishing') {
			this.airJob.effects = null;
			this.airJob.drains = null;
			this.fire = null;
		}
		if (type.code !== 'training') {
			this.airJob.jumpings = null;
			this.airJob.descents = null;
		}
		if (type.code === 'extinguishing') {
			this.airJob.forestOwnership = null;
		}
	};

	onClosePopup = async () => {
		await this.revertRecord();
		this.props.onClose();
	};

	beforeSave = () => {
		this.airJob.fireId = this.fire?.id || null;
		this.store.addHasMany('jobs', this.airJob);
		this.props.onClose();
		// сохраняем только новые реляции, редактируемые сохраним только при сохранении записи
		if (!this.isNew) return false;
	};

	beforeDelete = () => {
		this.store.removeHasMany('jobs', this.airJob);
		this.props.onClose();
		return false;
	};

	onClean = () => (this.fire = null);

	render() {
		const title = this.airJob.id ? t('editRecord') : t('addRecord');
		const disabled = this.props.store.disabled;
		return (
			<Popup width={400} className='edit-raid-job-popup  fires-popup' onClose={this.onClosePopup}>
				<h1>{title}</h1>
				<Form
					beforeSave={this.beforeSave}
					beforeDelete={this.beforeDelete}
					disabled={disabled}
					noDelete={this.isNew}
					record={this.airJob}
					disableSave={!this.airJob.type || !this.airJob.duration || this.airJob.duration === '00:00'}
					stay
					onClean={this.onClean}
					onCancel={this.onClosePopup}
					className='edit-job-form'
				>
					<Field relation='type' property='name' label={t('airJobType.title')} isRequired>
						<RecordSelect onChange={this.onTypeChange} filter={{ order: 'id desc' }} maxItems={10} itemsPerPage={1000} noTotal isRequired />
					</Field>
					<Field property='duration' label={t('airJob.duration')} isRequired>
						<MaskedInput mask={'99:59'} formatChars={{ 5: '[0-5]', 9: '[0-9]' }} maskChar='0' alwaysShowMask />
					</Field>
					<Field relation='forestOwnership' property='name' label={t('forestOwnership.title')} disabled={this.airJob.typeId === 13} />
					<div className='form-field'>
						<label>{t('fire.title')}</label>
						<RecordSelect
							noTotal
							maxItems={10}
							itemsPerPage={1000}
							disabled={disabled || this.airJob.typeId !== 13}
							model={store.model.ViewFire}
							filter={{
								where: {
									or: [
										//* первое условие - все активные пожары до конца дня
										{ and: [{ statusCode: { nin: EXCLUDED_STATUSES } }, { detectDate: { lte: endOfToday() } }] },
										//* второе все обновленные пожары(тут могут пожары,которые стали неактивными сегодня)
										{ date: { between: [startOfToday(), endOfToday()] } },
									],
								},
								include: ['county'],
								order: 'updatedAt desc',
							}}
							searchFields={['id', 'countyName']}
							computed={fireName}
							value={this.fire}
							onChange={(rec) => (this.fire = rec)}
						/>
					</div>
					<Row>
						<Field disabled={this.airJob.typeId !== 13} property='drains' label={t('airJob.drains')} />
						<Field disabled={this.airJob.typeId !== 13} property='effects' label={t('airJob.effects')} />
					</Row>
					<Row>
						<Field disabled={this.airJob.typeId !== 14} property='jumpings' label={t('airJob.jumpings')} />
						<Field disabled={this.airJob.typeId !== 14} property='descents' label={t('airJob.descents')} />
					</Row>
				</Form>
			</Popup>
		);
	}
}
