import { Button, Loader, Popconfirm, Popup, RecordSelect, MaskedInput } from '@smartplatform/ui';
import t from 'i18n';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import NotifyTemplate from './NotifyTemplates';
import store from 'client/store'
import { fio } from "client/tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MASS_NOTIFY_COUNT, MESSAGE_TYPE_ICONS, NOTIFY_CODE_ICONS, NOTIFY_COLORS } from "client/tools/notifyStatus";
import { Hint } from 'components';



@inject('listStore') @observer
export default class NotifyPopup extends React.Component {
	@observable text = '';
	@observable code = '';
	@observable type = null
	@observable isSending = false;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.generatedCode = String(Math.random()).substring(2, 8);
		this.listStore = this.props.listStore;
		//передаем в попап одну запись или массив записей по фильтрам
		this.requestFilter = this.props.record
			? { where: { and: [{ id: this.props.record.id }] } }
			: this.listStore.requestFilter;
		this.init()
	};
	init = async () => {
		try {
			const type = await store.model.NotifyType.find({ where: { default: true } });
			this.type = type.length > 0 ? type[0] : null;
		} catch (e) {
			console.log('type init error', e)
		} finally {
			this.isLoading = false;
		}
	};
	onSubmit = async () => {
		this.isSending = true;
		const typeId = this.type?.id;
		const params = {
			filter: this.requestFilter,
			message: this.text,
			typeId,
		};
		await store.model.NotifyLog.createMessageQueue(params);
		this.isSending = false;
		this.props.onClose();
		this.listStore.reloadList();
	};
	onTextChange = e => this.text = e.target.value;
	onTemplateSelect = ({ template }) => this.text = template || '';
	onNotifyTypeChange = type => this.type = type;
	onCodeChange = code => this.code = code.target.value;
	renderNotifyType = type => {
		if (type) {
			const icon = MESSAGE_TYPE_ICONS[type.code];
			const noApiWarning = type.id && !type.active && <span style={{ color: '#d00', fontWeight: 'bold', marginLeft: 10 }}>{t('notifyLog.noApi')}</span>
			return type.name && <> {type.name}
				{icon && <FontAwesomeIcon
					style={{ marginLeft: 10 }}
					color={NOTIFY_COLORS['sended']}
					icon={icon}
				/>}
				{noApiWarning}
			</>;
		}
	};

	render() {
		const count = this.props.count;
		const isMassNotify = count > MASS_NOTIFY_COUNT;
		const correctCode = !isMassNotify || this.code === this.generatedCode;

		const confirmInput = <>
		<MaskedInput className='confirm-input' mask='999999' value={this.code} onChange={this.onCodeChange} placeholder={t('code')} />
			<FontAwesomeIcon
				style={{ marginLeft: 10 }}
				color={NOTIFY_CODE_ICONS[correctCode].color}
				icon={NOTIFY_CODE_ICONS[correctCode].icon}
			/>
		</>

		const submitContent = <Button
			variant="primary"
			disabled={
				!this.type?.active
				|| this.text.trim().length === 0
				|| this.isSending
				|| (count === 0)
				|| !correctCode
			}
		>
			{this.isSending ? <> {t('sending')} <Loader size={16} />  </> : t('send')}
		</Button>;

		return <Popup width={600} onClose={this.props.onClose} header={`${t('notifyLog.add')} : ${this.type && this.type.name || ''}`} className="notify-popup">
			<div className="top">
				{/* получатели */}
				<div className='repicients'>
					{this.props.record
						? <div className='count'>{t('notifyLog.recipient')} : {fio(this.props.record)}</div>
						: <div className='count'>{t('notifyLog.recipientsCount')}:
							<span className={isMassNotify ? 'mass' : ''}> {count} </span></div>}
				</div>
				{/* шаблон */}
				<NotifyTemplate onSelect={this.onTemplateSelect} />
			</div>
			{/* код подтверждения */}
			{isMassNotify && <div className='confirm-code'>
				<h3>{t('notify.code')}:</h3>
				<span className='generated-code'>{this.generatedCode}</span>
				<Hint position='topLeft' size='sm' text={t('notify.massNotify')} />
			</div>}


			<div className='form-field'>
				<label>{t('notifyType.title')}</label>
				<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
					model={store.model.NotifyType}
					value={this.type || { name: t('notifyType.select') }}
					computed={this.renderNotifyType}
					onChange={this.onNotifyTypeChange}
					property='name'
					isRequired
					disabled={this.isLoading}
				/>
			</div>
			<div className='form-field'>
				<label>{t('notifyLog.text')}</label>
				<textarea
					disabled={this.isSending}
					value={this.text}
					onChange={this.onTextChange}
					rows={4}
				/>
			</div>
			<div className='action'>
				{
					this.isSending ? submitContent
						: <Popconfirm
							disabled={this.isSending}
							confirmBtn={t('yes')}
							cancelBtn={t('cancel')}
							content={t('confirm')}
							onConfirm={this.onSubmit}>
							{submitContent}
						</Popconfirm>
				}
				{isMassNotify && confirmInput}
			</div>
		</Popup >
	}
};
