import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Switch, Redirect, Route } from 'react-router-dom';

import { Tabs, Tab, Loader } from '@smartplatform/ui';
import WayBillEdit from './Edit';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class List extends React.Component {
	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		this.records = await store.model.TechFireWayBill.find({
			where: {
				fireId: this.props.match.params.id,
			},
			order: 'id asc',
		});
		this.isLoading = false;
	};

	onSave = async (record) => {
		const { id } = this.props.match.params;
		await this.init();
		store.route.push({ path: `/techfires/fires/${id}/waybills/${record.id}` });
	};

	render() {
		if (this.isLoading) return <Loader />;
		const { id } = this.props.match.params;
		const basePath = `/techfires/fires/${id}/waybills`;

		return (
			<div className='techfire-waybills'>
				<Tabs>
					<Route path={basePath} exact>
						<Redirect to={basePath + `/${this.records[0]?.id || 'create'}`} />
					</Route>
					{this.records.map((record, i) => {
						const props = {
							key: record.id,
							path: basePath + `/${record.id}`,
							title: `${t('techFireWayBill.title')} #${i + 1}`,
						};
						return <Tab {...props} />;
					})}
					<Tab path={basePath + '/create'} title={t('add')} />
					<Switch>
						<Route path={`/techfires/fires/:id/waybills/create`} render={(routeProps) => <WayBillEdit onSave={this.onSave} {...routeProps} />} />
						<Route path={`/techfires/fires/:id/waybills/:wayBillId`} render={(routeProps) => <WayBillEdit {...routeProps} />} />
					</Switch>
				</Tabs>
			</div>
		);
	}
}
