import { inject, observer } from 'mobx-react';
import React from 'react';
import { Column, Table } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { fio } from 'client/tools';
import { AddMemberButton } from 'components';
import { observable } from 'mobx';
import { MemberEditPopup } from './MemberEditPopup';

@inject('store')
@observer
export class Members extends React.Component {
	@observable selectedRecord = null;
	constructor(props) {
		super(props);
		this.store = props.store;
		this.MODEL = this.store.record.MODEL.RELATIONS.members.model;
	}
	addMember = () => (this.selectedRecord = new store.model[this.MODEL]());
	onClosePopup = () => (this.selectedRecord = null);
	onRowClick = (rec) => (this.selectedRecord = rec);

	render() {
		const { disableEdit, originalStatusCode, isNew, members } = this.props.store;
		const isAddingAllowed = isNew || ['new', 'approval'].includes(originalStatusCode);
		const disabled = isAddingAllowed && !disableEdit;
		return (
			<div className='members'>
				<h3>{t('request.agreement')}</h3>
				<Table rows={members} onRowClick={!disabled ? undefined : this.onRowClick}>
					<Column relation='user' computed={fio} label={t('fullName_full')} />
					<Column relation='role' property='name' label={t('request.agreementRole')} />
					<Column property='position' label={t('user.position')} />
					<Column relation='organization' property='name' label={t('organization.title')} />
				</Table>
				{disabled && <AddMemberButton onClick={this.addMember} />}
				{this.selectedRecord && <MemberEditPopup onClose={this.onClosePopup} record={this.selectedRecord} members={members} />}
			</div>
		);
	}
}
