import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import store from 'client/store';
import { renderStatus, relative } from 'client/tools';
import t from 'i18n';

import './objectLog.scss';

@observer
export class ObjectLog extends Component {
	@observable logs = null;
	@observable actions = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		if (this.props.id) this.logs = await store.model.AdpiDeviceLog.find({ where: { adpiDeviceId: this.props.id }, include: ['adpiStatus'] });

		this.logs.forEach((log, index) => {
			if (log.adpiStatusId === null) {
				if (index === 0) this.actions.push({ text: t('adpiDeviceLog.statusNotSet'), date: relative(log.createdAt) });
				else this.actions.push({ text: t('adpiDeviceLog.statusRemoved'), date: relative(log.createdAt) });
			} else {
				this.actions.push({ text: t('adpiDeviceLog.statusChanged'), item: renderStatus(log.adpiStatus), date: relative(log.createdAt) });
			}
		});
	};

	render() {
		return (
			<div className='object-log-block'>
				<h2>{t('adpiDeviceLog.title')}</h2>
				{this.actions.map((action, index) => {
					return (
						<div className='adpi-status-info' key={index}>
							<span>{action.text}</span>
							{action.item} {action.date}
						</div>
					);
				})}
			</div>
		);
	}
}
