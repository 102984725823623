import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import FilterLayout from '../FilterLayout';
import t from 'i18n';

@observer
export default class ShipsFilter extends React.Component {

	static propTypes = {
		layer: PropTypes.object,
		onToggle: PropTypes.func,
	};

	onToggle = () => {
		this.props.layer.setVisible();
	};

	render() {
		const { layer } = this.props;

		return (
			<FilterLayout layer={layer} title={t('ship.plural')} onToggle={this.onToggle}/>
		);
	}
}
