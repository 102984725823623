import { defaultsDeep } from 'lodash';
import { toJS } from 'mobx';
import store from 'client/store';

const layers = {
	counties: {
		show: false,
	},
	genShtab: {
		show: false,
	},
	ships: {
		show: false,
	},
	weather: {
		show: false,
		minimized: false,
		layer: 'pm25',
	},
	wind: {
		show: false,
		minimized: false,
		fadeOpacity: 0.995,
		speedFactor: 5,
		particleLife: 128,
		opacity: 0.8,
		webgl: true,
	},
};

const defaultConfig = {
	tileSource: '2gis',
	filtersMaximized: false,
	layers,
	dynamicLayers: [],
	contragents: {},
	requests: {},
	tracking: {},
	reserves: {},
};

export default function initLocal() {
	store.local.delivery = defaultsDeep(toJS(store.local.delivery), defaultConfig);
	store.local.save();
}
