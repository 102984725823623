import { action, observable, reaction } from 'mobx';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import { geoJSON, drawGeoMarker } from '@smartplatform/map/client';
import store from 'client/store';

export default class CaseListStore {

	@observable address = null;
	@observable addressInfo = '';
	@observable case = null;
	@observable pickingTrack = false;
	@observable tracksDate = new Date();
	@observable mobileTracks = true;
	@observable medvedTracks = true;
	@observable error = null;
	@observable mapInitialized = false;

	@observable trackLayer = null;
	@observable tracks = [];
	
	@observable filters = {
		page: 1,
		search: '',
		status: null,
		isolation: null,
		contacted: false,
		mobileOperator: null,
		mobileOperators: [],
		mobileStatus: null,
		organization: 'all',
		mobileStatuses: [],
		organizations: [],
		abroad: false,
		agreed: false,
		medved: false,
		dateFrom: null,
		dateTo: null,
		violators: false,
		maxDistance: 200,
		city: null,
		county: null,
		isPublic: 'all',
		//фильтры сообщений
		notifyStatus: null,
		notifyDateFrom: null,
		notifyDateTo: null,
		notifyType: null,
		notifyPhoneAccepted: null,
		startAge: '',
		endAge: ''
	};
	requestFilter;

	addressMarker = null; // массив features для отображения адреса в openlayers
	
	constructor(globalStore) {
		reaction(
			() => this.address,
			this.onAddressChange,
		)
	}

	onMapInit = (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;

		this.geoLayer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.geoLayer.setVisible(true);
		mapStore.addLayer(this.geoLayer);

		this.mapInitialized = true;
	};

	reload = async () => {
		if (this.reloadList) {
			await this.reloadList();
		}
	};
	
	resetTracksFilter = () => {
		this.tracksDate = new Date();
		this.mobileTracks = true;
		this.medvedTracks = true;
	};
	
	onAddressChange = async address => {
		if (address) {
			let cityInfo = '-';
			if (address.cityId) {
				const city = await store.model.City.findById(address.cityId);
				if (city) cityInfo = `${city.name || '-'} (${city.id})`;
			}
			let countyInfo = '-';
			if (address.countyId) {
				const county = await store.model.County.findById(address.countyId);
				if (county) countyInfo = `${county.name || '-'} (${county.id})`;
			}
			this.addressInfo = `${cityInfo}, ${countyInfo}`;
		}
		else {
			this.addressInfo = '';
		}
		console.log('addressInfo:', this.addressInfo);
	};

	addAddressMarker = (address) => {
		const { geo } = address;

		if (!this.map || !geo) {
			console.warn('addAddressMarker error, map:', this.map, 'geo:', geo);
			return;
		}

		const features = drawGeoMarker(geo);

		// TODO: решить: или addAddressMarker() всегда очищает слой, или удалять нарисованную геометрию вручную?
		this.geoLayer.getSource().clear();
		this.geoLayer.getSource().addFeatures(features);
	};

	removeAddressMarker = () => {
		// console.log('removeAddressMarker', this.addressMarker);
		// if (this.addressMarker) {
		// 	this.addressMarker.forEach(feature => this.geoLayer.getSource().removeFeature(feature));
		// }
	};
}
