export const MODEL_NAME = 'RequestOperationalInfo';

export const OPER_INFO_PATH = '/delivery/oper-info';

export const OPER_INFO_INCLUDE = {
    include: [
        { relation: 'county', scope: { fields: ['id', 'name'] } },
        { relation: 'city', scope: { fields: ['id', 'name'] } },
        { relation: 'river', scope: { fields: ['id', 'name'] } },
        { relation: 'organization', scope: { fields: ['id', 'name'] } },
        { relation: 'period', scope: { fields: ['id', 'name'] } },
        { relation: 'owner', scope: { fields: ['id', 'name'] } },
        { relation: 'category'},
    ]
};

export const PER_PAGE = 50;

