import React from 'react';
import { inject, observer } from 'mobx-react';
import { Mode } from 'delivery/components';
import { Filters } from './Filters';
import { Toolbar as BaseToolbar } from 'components';
import { REQUEST_CODE } from 'delivery/constants';
import { isMinArct, isYakutOptTorg } from 'delivery/helpers/roles';

@inject('store')
@observer
export default class Toolbar extends React.Component {
	render() {
		const { create, onSearch, onModeChange, onShowFiltersChange, showFilters, search, mode, exportUrl, code } = this.props.store;

		return (
			<div className='requests-toolbar'>
				<div className='top'>
					<BaseToolbar>
						{!((isMinArct() || isYakutOptTorg()) && code !== REQUEST_CODE) && <BaseToolbar.AddButton onClick={create} />}
						<BaseToolbar.SearchIconInput value={search} onChange={onSearch} />
						<BaseToolbar.FilterButton showFilters={showFilters} onClick={onShowFiltersChange} />
						<BaseToolbar.ExportButton exportUrl={exportUrl} />
					</BaseToolbar>

					{code === REQUEST_CODE && (
						<div className='right'>
							<Mode mode={mode} onChange={onModeChange}>
								<Mode.List />
								<Mode.Kanban />
							</Mode>
						</div>
					)}
				</div>
				{showFilters && <Filters store={this.props.store} />}
			</div>
		);
	}
}
