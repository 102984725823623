import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.scss';

export default class Breadcrumbs extends React.Component {
	static propTypes = {
		crumbs: PropTypes.array,
	};

	render() {
		const { crumbs } = this.props;

		return (
			<div className='breadcrumbs'>
				{crumbs.map((crumb, i) => (
					<span key={i}>
						{i > 0 && <span className='bc-arr'> - </span>}
						{crumb.path ? <Link to={crumb.path}>{crumb.title}</Link> : <span className='bc-title'>{crumb.title}</span>}
					</span>
				))}
			</div>
		);
	}
}
