import React from 'react';
import { observer, Provider } from 'mobx-react';
import { MainInfo } from './main-info';
import { Staff } from './staff';
import { BankAccounts } from './bank-accounts';
import { Agreements } from './agreements';
import { Requests } from './requests';
import { Form, Tab, Tabs } from '@smartplatform/ui';
import { Create } from './Create';
import { DeleteButton } from 'components';
import { Actionbar } from 'components';
import { Breadcrumbs } from 'client/components';
import ContragentStore from './store';
import t from 'i18n';
import './style.scss';

@observer
export class Contragent extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ContragentStore();
		this.store.path = props.match.url;
		this.store.returnToPath = props.path;
		this.getData();
	}

	getData = () => {
		this.store.id = parseInt(this.props.match.params.id);
		this.store.init();
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.store.path = this.props.match.url;
			this.getData();
		}
	}

	render() {
		const { isLoading, record, onSave, onClean, back, path, returnToPath } = this.store;
		if (isLoading) return null;
		const { id, name } = record;
		const title = id ? `${t('contragent.card')} ${name}` : t('contragent.create');
		const rootTitle = t('contragent.plural');

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton disabled={!record.name} />
				<Actionbar.CancelButton back={back} />
				{!record.id && <Actionbar.CleanButton onClean={onClean} />}
			</Actionbar>
		);

		const form = () => (
			<Form record={record} controls={controls} stay onSave={onSave} onDelete={back} noDelete noSave>
				{id ? <MainInfo /> : <Create />}
			</Form>
		);

		let tabs = [{ title: t('contragent.mainInfo'), path, render: form }];
		if (id) {
			const notIsNewTabs = [
				{ title: t('contragent.staff'), path: `${path}/staff`, render: () => <Staff /> },
				{ title: t('contragent.bankAccounts'), path: `${path}/bankAccounts`, render: () => <BankAccounts /> },
			];
			tabs = [...tabs, ...notIsNewTabs];
		}
		if (this.props.module === 'delivery') {
			const deliveryTabs = [
				{ title: t('contragent.requests'), path: `${path}/requests`, render: () => <Requests /> },
				{ title: t('contragent.agreements'), path: `${path}/agreements`, render: () => <Agreements /> },
			];
			tabs = [...tabs, ...deliveryTabs];
		}

		return (
			<Provider store={this.store}>
				<div className='contragent'>
					<div className='header'>
						<div>
							<Breadcrumbs crumbs={[{ path: returnToPath, title: rootTitle }, { title }]} />
							<h2>{title}</h2>
						</div>
						<DeleteButton text={t('contragent.delete')} onConfirm={this.store.onDelete} />
					</div>

					<Tabs>
						{tabs.map((tab, index) => (
							<Tab {...tab} key={index} exact />
						))}
					</Tabs>
				</div>
			</Provider>
		);
	}
}
