import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Chart from 'chart.js';
import ru from 'date-fns/locale/ru';
import { format, subMonths } from 'date-fns';

import { ColorUtils } from '@smartplatform/ui';
import { Panel } from 'components';
import { formatDate } from 'client/tools';
import store from 'client/store';
import appealsStore, { MODES, EVENTS } from '../store';
import './style.scss';

@observer
export default class Pie extends React.Component {

	@observable records = [];
	@observable recordsYearAgo = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		appealsStore.subscribe(EVENTS.RELOAD_ALL, this.reload);
		appealsStore.subscribe(EVENTS.RELOAD_CATEGORIES, this.reload);
	}

	componentWillUnmount() {
		appealsStore.unsubscribe(EVENTS.RELOAD_ALL, this.reload);
		appealsStore.unsubscribe(EVENTS.RELOAD_CATEGORIES, this.reload);
	}

	init = async () => {
		this.records = await store.model.ViewAppealCategory.getCounts({
			startDate: appealsStore.startDate,
			endDate: appealsStore.endDate,
			parentId: appealsStore.category ? appealsStore.category.id : null,
			systemId: appealsStore.system.id,
		});
		this.recordsYearAgo = await store.model.ViewAppealCategory.getCounts({
			startDate: subMonths(appealsStore.startDate, 12),
			endDate: subMonths(appealsStore.endDate, 12),
			parentId: appealsStore.category ? appealsStore.category.id : null,
			systemId: appealsStore.system.id,
		});
		console.log('records', this.records);
		this.isLoading = false;
	};

	getData = () => {
		const color = appealsStore.category ? appealsStore.categoryColor(appealsStore.category) : '#0af';
		const { r, g, b } = ColorUtils.hexToRgb(color);

		const datasets = [
			{
				data: this.records.map(record => parseInt(record.count)),
				label: appealsStore.category ? appealsStore.category.name : 'Всего',
				borderColor: color,
				backgroundColor: `rgba(${r},${g},${b}, 0.1)`,
				borderWidth: 1.5,
				pointRadius: 0,
			},
			{
				data: this.recordsYearAgo.map(record => parseInt(record.count)),
				label: subMonths(appealsStore.startDate, 12).getFullYear(),
				borderColor: `rgba(128,128,128, 0.7)`,
				backgroundColor: `rgba(128,128,128, 0.1)`,
				borderWidth: 1.5,
				pointRadius: 0,
			},
		];

		const labels = this.records.map(record => formatDate(new Date(record.day), 'dd.MM'));

		console.log({ datasets, labels });
		return { datasets, labels };
	};

	reload = async () => {
		if (this.chart) {
			await this.init();
			const { datasets, labels } = this.getData();
			this.chart.data.datasets = datasets;
			this.chart.data.labels = labels;
			this.chart.update();
		}
	};

	onMount = el => {
		this.el = el;
		if (el) {
			const { datasets, labels } = this.getData();

			this.chart = new Chart(el, {
				type: 'line',
				data: { labels, datasets },
				options: {
					// title: false,
					responsive: true,
					// legend: false,
					scales: {
						xAxes: [
							{
								ticks: {
									autoSkip: true,
									maxRotation: 0,
									minRotation: 0
								}
							}
						],
						yAxes: [{
							ticks: {
								beginAtZero: true,
								callback: function(value) {if (value % 1 === 0) {return value;}}
							}
						}],
					},
					tooltips: {
						mode: 'index',
						intersect: false,
					},
					animation: {
						duration: 0
					},
				},
			});
		}
	}

	render() {
		if (this.isLoading) return null;

		let header = 'Обращения за период ' + formatDate(appealsStore.startDate) + ' - ' + formatDate(appealsStore.endDate);
		if (appealsStore.dateMode === MODES.MONTH) {
			header = 'Обращения за ' + format(appealsStore.currentDate, 'LLLL', { locale: ru }) + ' ' + appealsStore.currentDate.getFullYear() + ' года';		}
		else if (appealsStore.dateMode === MODES.YEAR) {
			header = 'Обращения за ' + appealsStore.currentDate.getFullYear() + ' год';
		}

		return <Panel left={435} top={store.ui.sideBarBtnSize} width={750} height={330} header={header} className="appeals-bar-chart" scrollable={false}>
			<canvas width={730} height={280} ref={this.onMount} />;
		</Panel>
	}

}
