import React from 'react';
import { inject, observer } from 'mobx-react';
import { Field, FormContext, RelationSelect } from '@smartplatform/ui';
import { AUTOCOMPLETE, Suggestions } from './suggestInput';
import t from 'i18n';

@inject('store')
@observer
export class Create extends React.Component {
	static contextType = FormContext;
	onSelectSuggestion(suggestion) {
		if (!suggestion || !this.context.record) return;
		Object.keys(AUTOCOMPLETE).forEach((property) => {
			this.context.record[property] = AUTOCOMPLETE[property](suggestion);
		});
	}

	render() {
		return (
			<div className='main'>
				<div className='top'>
					<Field label={t('type')} relation='type' property='name'>
						<RelationSelect />
					</Field>
					<Field label={t('name')} property='name' isRequired>
						<Suggestions onSelect={this.onSelectSuggestion} />
					</Field>
				</div>
			</div>
		);
	}
}
