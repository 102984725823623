import React, { Component } from 'react';
import { Table, Column, Checkbox } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@inject('store')
@observer
export class Edit extends Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { activeRecord, toggleRecord, getFilter } = this.store;

		return (
			<Table query={getFilter()} model={store.model.FireDepVehicleCallSign}>
				<Column label={<FontAwesomeIcon icon={faCheck} />} width={30}>
					{({ record }) => <Checkbox checked={activeRecord(record)} onChange={() => toggleRecord(record)} />}
				</Column>
				<Column property='name' label={t('fireDepVehicle.callSign')} />
				<Column relation='fireDep' property='name' label={t('fireDepVehicle.fireDepShort')} />
			</Table>
		);
	}
}
