import { observable } from 'mobx';

export default class FireReportStore {
	@observable date = new Date();

	get storeParams() {
		return {
			reportCode: 'FireByArea',
			reportParams: { date: this.date },
			isHTML: true,
		};
	}

	onDateChange = (date) => (this.date = date);
}
