import React from 'react';
import { observer } from 'mobx-react';
import { Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import t from 'i18n';

@observer
export default class MineralsPopup extends React.Component {
	static propTypes = {};
	static defaultProps = {};

	constructor(props) {
		super(props);
	}

	render() {
		const { record } = this.props;
		const { id, name } = record;

		return (
			<div>
				<div>
					<h2>{`${name}: #${id}`}</h2>
				</div>
				{record.mining() &&
					record.mining().map((m, i) => (
						<div key={m.id}>
							<Row>
								<TextField label={t('mineral.PIType')} value={m.mineral.category.name} />
								<TextField label={t('mineral.PI')} value={m.mineral.name} />
								<TextField label={t('mineral.units')} value={m.mineral.measure} />
							</Row>
							<Row>
								<TextField label={t('mineral.c2')} value={m.c2} />
								<TextField label={t('mineral.abc1')} value={m.abc1} />
								<TextField label={t('mineral.zabC')} value={m.zabC} />
							</Row>
							{record.mining().length !== i + 1 && <hr />}
						</div>
					))}
			</div>
		);
	}
}
