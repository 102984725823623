import { IconButton } from './IconButton';
import t from 'i18n';
import './style.scss';
import React from 'react';
import FilterIcon from '!!svg-react-loader!img/icons/filter.svg';
import PlusIcon from '!!svg-react-loader!img/icons/plus.svg';
import ExportIcon from '!!svg-react-loader!img/icons/shared/export.svg';
import CrossIcon from '!!svg-react-loader!img/icons/cross.svg';
import ContragentsIcon from '!!svg-react-loader!img/icons/shared/contragents.svg';
import CloseIcon from '!!svg-react-loader!img/icons/shared/x-close.svg';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const makeButton = (funcProps) => (props) => <IconButton {...funcProps} {...props} />;
export const AddButton = makeButton({ icon: <PlusIcon />, className: 'plus-btn' });
export const AddButtonSecondary = makeButton({ icon: <PlusIcon />, className: 'add-btn-secondary' });
export const FilterButton = makeButton({ icon: <FilterIcon />, className: 'filter-btn', text: t('_filter') });
export const ExportButton = makeButton({ icon: <ExportIcon />, className: 'export-btn', text: t('export') });
export const UploadButton = makeButton({ icon: <FontAwesomeIcon icon={faUpload} />, className: 'upload-btn', text: t('upload') });
export const CleanButton = makeButton({ className: 'clean-btn', text: t('clean') });
export const CleanFiltersButton = makeButton({ icon: <CloseIcon />, text: t('cleanFilters') });
export const SaveButton = makeButton({ text: t('save') });
export const CancelButton = makeButton({ className: 'cancel-btn', text: t('cancel') });
export const AddMemberButton = makeButton({ icon: <ContragentsIcon />, className: 'add-contragent-button ', text: t('request.addMember') });
export const CloseButton = makeButton({ className: 'close-btn', icon: <CrossIcon /> });
export const CopyButton = makeButton({ icon: <FontAwesomeIcon icon={faCopy} />, text: t('copy') });

export * from './DeleteButton';
