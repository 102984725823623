import React from 'react';
import { observer, Provider } from 'mobx-react';
import AnalyticStore from './store';
import { AnalyticsTab1 } from './tab-1';
import Filters from './filters/Filters';

@observer
export class Analytics extends React.Component {
	constructor(props) {
		super(props);
		this.store = new AnalyticStore();
		this.store.init();
	}

	render() {
		return (
			<Provider store={this.store}>
				<div>
					<h2>Аналитика</h2>
					<Filters />
					<AnalyticsTab1 />
				</div>
			</Provider>
		);
	}
}
