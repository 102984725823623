import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Edit } from './Edit';

export const Notifier = ({ match }) => {
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact children={() => <List path={path} />} />
			<Route path={`${path}/:id`} children={({ match }) => <Edit match={match} path={path} />} />
		</Switch>
	);
};
