import { observable } from 'mobx';
import store from 'client/store';
import { subMonths, startOfDay, endOfDay, startOfMonth, endOfMonth } from 'date-fns';

class ErrandsStore {

	@observable isInitialized = false;

	init = async () => {
		if (store.model.isInitialized && store.model.isAuthorized) {

			// ... инициализация

			this.isInitialized = true;
		}
	};

}

export default new ErrandsStore();
