import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Tabs, Tab } from '@smartplatform/ui';
import store from 'client/store';
import Personnel from './Personnel';
import Vehicles from './Vehicles';
import t from 'i18n';

@observer
export default class SentryPersonnelAndVehicles extends React.Component {
	
	@observable sentry = null;
	@observable tab = 'personnel';
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.sentry.id !== prevProps.sentry.id) {
			this.sentry = null;
			this.init();
		}
	}
	
	init = async () => {
		this.sentry = await store.model.FireDepSentry.findById(this.props.sentry.id, {
			include: [
				{ relation: 'globalSentry', scope: { fields: ['id', 'name'] }},
				{
					relation: 'personnel',
					scope: {
						include: [
							{ relation: 'vehicle', scope: { include: [ 'brand', 'type' ] }},
							{ relation: 'status', scope: { fields: [ 'id', 'name' ] }},
						],
						order: 'lastName asc, firstName asc, middleName asc'
					}
				},
				{
					relation: 'vehicles',
					scope: {
						include: [
							{
								relation: 'type',
								scope: {
									include: [ 'specialType' ],
								}
							},
							'personnel'
						],
						order: 'id asc'
					}
				},
			]
		});
	};
	
	setTab = (tab) => this.tab = tab;
	
	render() {
		if (!this.sentry) return null;
		
		return <div>
			<Tabs type="manual">
				<Tab title={t('fireDep.personnel')} initial onClick={() => this.setTab('personnel')} />
				<Tab title={t('fireDep.vehicles')} onClick={() => this.setTab('vehicles')} />
			</Tabs>
			{this.tab === 'vehicles' ?
				<Vehicles record={this.sentry} />
				:
				<Personnel record={this.sentry} />
			}
		</div>;
	}
}
