import { CleanButton as _CleanButton } from 'components';
import { ActionbarContext } from './Actionbar';
import React from 'react';

export const CleanButton = ({ onClean }) => {
	const context = React.useContext(ActionbarContext);
	const _onClean = async () => {
		await context.form.reset();
		onClean?.();
	};

	return <_CleanButton onClick={_onClean} />;
};
