import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Field, Popup, Row, RecordSelect, DatePicker } from '@smartplatform/ui';
import { Form } from 'components';
import store from 'client/store';
import t from 'i18n';

const MODEL_NAME = 'EmergencySituationDamage';
@observer
export default class EditDamagePopup extends React.Component {
	@observable record = null;
	@observable countyIds;

	constructor(props) {
		super(props);
		this.model = store.model[MODEL_NAME];
		this.record = props.record || new this.model({ date: new Date() });
		this.isNew = !this.record.id;

		// сохраняем оригинальную запись
		this.selectedRecordOriginal = { ...this.record };
	}

	revertRecord = async () => {
		if (!this.isNew) {
			const originCountyId = this.selectedRecordOriginal.countyId;
			if (originCountyId) {
				const selectedCountyId = this.record.countyId;
				// перезаписываем объект, если id было изменено
				if (originCountyId !== selectedCountyId) {
					this.record.county = await store.model.County.findById(originCountyId, { fields: ['id', 'name'] });
				}
			} else {
				this.record.county = null;
			}

			// получаем все поля модели EmergencySituationDamage, проходим по каждому полю и меняем значение на оригинальное значение (объекты не перезаписываются)
			const modelKeys = Object.keys(this.model.PROPERTIES);
			for (let key of modelKeys) {
				this.record[key] = this.selectedRecordOriginal[key];
			}
		}
	};

	onClosePopup = async () => {
		this.revertRecord();
		this.props.onClose();
	};

	render() {
		const { disabled, beforeSave, onDelete } = this.props;
		const { record, isNew, onClosePopup } = this;
		const title = !isNew ? t('editRecord') : t('addRecord');

		return (
			<Popup onClose={onClosePopup} width={500} className='edit-damagepopup fires-popup'>
				<h1>{title}</h1>
				<Form stay disabled={disabled} record={record} className='edit-damage-form' beforeSave={beforeSave} onDelete={onDelete}>
					<Field
						relation='county'
						property='name'
						label={t('county.title')}
						filter={{ fields: ['id', 'name'] }}
						// filter={countyFilter}
					>
						<RecordSelect maxItems={10} itemsPerPage={1000} noTotal />
					</Field>
					<Row>
						<Field property='date' label={t('date')}>
							<DatePicker showClearButton={false} />
						</Field>
						<Field property='dead' label={t('emergencySituationDamage.dead')} />
					</Row>
					<Row>
						<Field property='injured' label={t('emergencySituationDamage.injured')} />
						<Field property='affectedObjects' label={t('emergencySituationDamage.affectedObjects')} />
					</Row>
					<Field property='comment' label={t('emergencySituationDamage.comment')}>
						<textarea rows={3} />
					</Field>
				</Form>
			</Popup>
		);
	}
}
