import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Column, Pager, Table } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { RowAddButton } from 'fires/components';
import { POPUP_PER_PAGE } from '../../constants';
import { EditAircraftPopup } from './EditAircraftPopup';
@inject('store')
@observer
export class Aircrafts extends React.Component {
	@observable records = [];
	@observable showEditPopup = false;
	@observable page = 1;

	constructor(props) {
		super(props);
		this.records = this.props.store.aircrafts;
	}

	onClosePopup = () => this.toggleEditPopup(false);
	onOpenPopup = () => this.toggleEditPopup(true);

	toggleEditPopup = (boolean) => {
		if (!boolean) {
			this.selectedRecord = null;
		}
		this.showEditPopup = boolean;
	};

	onRowClick = (record) => {
		this.selectedRecord = record;
		this.toggleEditPopup(true);
	};

	onPageChange = (page) => {
		this.page = page;
	};

	render() {
		if (this.isLoading) return null;

		return (
			<div className='aircrafts-list'>
				<div className='top'>
					{/* <h2>{t('aircraft.plural')}</h2> */}
					<Pager current={this.page} onChange={this.onPageChange} totalCount={this.records.totalCount} itemsPerPage={POPUP_PER_PAGE} />
				</div>
				<Table rows={this.records.slice(POPUP_PER_PAGE * (this.page - 1), POPUP_PER_PAGE * this.page)} onRowClick={this.onRowClick}>
					<Column relation='type' property='name' label={t('aircraft.title')} />
					<Column width={70} property='count' label={t('countShort')} />
				</Table>
				<RowAddButton onClick={this.onOpenPopup} disabled={this.props.store.disabled} />
				{this.showEditPopup && <EditAircraftPopup onClose={this.onClosePopup} record={this.selectedRecord} />}
			</div>
		);
	}
}
