import { observable } from 'mobx';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { asArray } from 'ol/color';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { get as getProj } from 'ol/proj';
import MVT from 'ol/format/MVT';
import TileGrid from 'ol/tilegrid/TileGrid';

import { ColorUtils } from '@smartplatform/ui';
import store from 'client/store';

export default class ForestiesLayer {

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		const resolutions = [];
		for (let i = 0; i <= 8; ++i) {
			resolutions.push(156543.03392804097 / Math.pow(2, i * 2));
		}

		this.layer = new VectorTileLayer({
			source: new VectorTileSource({
				format: new MVT(),
				tileGrid: new TileGrid({
					extent: getProj('EPSG:3857').getExtent(),
					resolutions: resolutions,
					tileSize: 512,
				}),
				tileUrlFunction: this.tileUrlFunction,
			}),
			style: function (feature, resolution) {
				const name = feature.getProperties().name;
				const { background } = ColorUtils.getColorForString(name);
				const { r, g, b } = ColorUtils.hexToRgb(background);
				return new Style({
					fill: new Fill({ color: asArray([r, g, b, 0.2]) }),
					stroke: new Stroke({ color: '#888', width: 1, lineDash: [1, 4] }),
					text: new Text({
						textAlign: 'center',
						textBaseline: 'middle',
						// overflow: true,
						font: '11px/14px sans-serif',
						text: name,
						// fill: new Fill({ color: '#888' }),
					}),
				});
			},
		});

		this.layer.setZIndex(3);
		this.mapStore.addLayer(this.layer);
		this.setVisible();
	};

	tileUrlFunction = (coords) => {
		return `/api/mvt?model=Forestry&x=${coords[1]}&y=${coords[2]}&z=${coords[0] * 2 - 1}&columns=name`;
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
	};

	// onClick = async (feature) => {
	// 	const { id, name } = feature.getProperties();
	// 	console.log('county', { id, name });
	// };

};
