import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import Map from './Map';
import Kanban from './kanban';
import Form from './form';
import Dictionaries from './Dictionaries';
import module from './index';
import appealsStore from './store';
import { ModuleWrapper } from 'components';
import './style.scss';

@observer
export default class Appeals extends React.Component {
	constructor(props) {
		super(props);
		if (!appealsStore.isInitialized) appealsStore.init();
	}

	render() {
		if (!appealsStore.isInitialized) return null;
		const { path } = this.props.match;

		return (
			<ModuleWrapper className='appeals-root' module={module} path={path}>
				<Switch>
					<Route path='/appeals' exact component={Map} />
					<Route path='/appeals/kanban' component={Kanban} />
					<Route path='/appeals/dictionaries' component={Dictionaries} />
					<Route path='/appeals/:id' component={Form} />
				</Switch>
			</ModuleWrapper>
		);
	}
}
