import React from 'react';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import TableRow from '@smartplatform/ui/src/components/table/TableRow';
import './style.scss';
import { RowSkeleton } from './RowSkeleton';

@observer
export default class Rows extends React.Component {
	//!
	@observable initial = true;
	infinityScrollPart = 1;
	//!

	constructor(props) {
		super(props);
		props.getInstance &&
			props.getInstance({
				reload: this.load,
			});
		this.model = this.props.model;
		this.state = {
			records: [],
			errors: [],
			query: this.props.query || {},
			isLoading: !!this.model,
		};
		this.load = throttle(this.load, 500, { leading: true, trailing: true });
		//! +++++++ infinityScroll vars and methods
		if (props.infinityScroll) {
			this.infiniteObserver = new IntersectionObserver(([entry], osbserver) => {
				if (entry.isIntersecting) {
					osbserver.unobserve(entry.target);
					this.loadNextPage();
				}
			});

			this.loadNextPage = async () => {
				this.setState((state) => ({ ...state, isLoading: true }));
				const pageRecords = await this.model.find({
					...this.state.query,
					limit: props.infinityScrollPartSize,
					skip: this.infinityScrollPart * props.infinityScrollPartSize,
				});
				this.setState((state) => (state.records = [...state.records, ...pageRecords]));
				const hasMoreRecords = pageRecords.totalCount > (this.infinityScrollPart + 1) * props.infinityScrollPartSize;
				if (hasMoreRecords) {
					this.setInfinityObserve();
				}
				this.infinityScrollPart++;
				this.setState((state) => ({ ...state, isLoading: false }));
			};

			this.setInfinityObserve = () => {
				const ancor = `table.sp-table tbody tr:nth-child(${this.state.records.length + 1})`;
				const lastRow = document.querySelector(ancor);
				lastRow && this.infiniteObserver.observe(lastRow);
			};
		}
		//! +++++++
	}

	componentDidMount() {
		this.checkForErrors();
		if (this.model) this.init(true);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.query) {
			const { _totalCount: prevTotalCount, ...prev } = toJS(prevProps.query);
			const { _totalCount: nextTotalCount, ...next } = toJS(this.props.query);
			const update = !isEqual(prev, next);
			if (update) {
				const getTotalCount = !isEqual(prev.where, next.where);
				// console.log('--- update! getTotalCount:', getTotalCount, 'prev:', JSON.stringify(prev.where), 'next', JSON.stringify(next.where));
				this.load(getTotalCount);
			}
		}
	}

	init = async (getTotalCount = true) => {
		await this.load(getTotalCount);
		this.initial = false;
	};

	load = async (getTotalCount = true) => {
		//! ------- this.initial не нужен
		this.initial = true;
		this.setState((state) => ({ ...state, records: [], isLoading: true }));
		this.props.onLoadStart && this.props.onLoadStart();

		const publicQuery = this.props.query || { limit: 10 };
		// const innerQuery = { ...publicQuery, noTotalCount: true };

		try {
			const records = await this.model.find(publicQuery);
			this.props.onLoadEnd && this.props.onLoadEnd(records);

			if (!publicQuery._totalCount || getTotalCount) {
				// const totalCount = await this.model.count(publicQuery.where);
				const totalCount = records.totalCount;
				this.setState({ records, isLoading: false });
				if (this.props.onQueryUpdate) {
					this.props.onQueryUpdate({ ...publicQuery, _totalCount: totalCount }, this.initial ? null : { ...publicQuery });
				}
			} else {
				this.setState({ records, isLoading: false });
			}
		} catch (error) {
			console.warn(error);
			this.setState({ records: [], isLoading: false, errors: [error.message] });
		}

		//! +++++++ init infinity scroll ancor
		if (this.props.infinityScroll) {
			const hasMoreRecords = this.state.records.totalCount > this.props.infinityScrollPartSize;
			if (hasMoreRecords) {
				this.infinityScrollPart = 1;
				this.setInfinityObserve();
			}
		}
		//! +++++++
	};

	checkForErrors = () => {
		if (!this.props.rows && !this.props.model) {
			const error = 'Table: you must supply one of these props: "rows" or "model"';
			this.setState({ records: [], isLoading: false, errors: [error] });
		}
	};

	onMount = (el) => {
		if (this.props.getRef) this.props.getRef(el);
	};

	sortWithProperty = (e, property) => {
		e.preventDefault();
		if (this.props.onSort) this.props.onSort(property);
	};

	sortWithFunc = (e, sortFunc) => {
		e.preventDefault();
		sortFunc();
	};

	render() {
		const { rows, children, debug, path, rootPath, onRowClick, hilightRow, clickable, rowClassName } = this.props;

		const items = rows && rows.length ? rows : this.state.records;

		//! +++++++ skeleton component
		const skeleton = this.model && <RowSkeleton columns={children} emptyRecord={new this.model()} rowsCount={this.props.infinityScrollPartSize} />;
		//! +++++++

		const _rows = items.map((record, row) => {
			const hilighted = hilightRow && hilightRow(record, row);
			const props = { record, row, path, rootPath, debug, onRowClick, hilighted, clickable, rowClassName };
			// console.log('props: ', props);
			return (
				<TableRow {...props} key={`row-${record.id || row}`}>
					{children}
				</TableRow>
			);
		});

		const columnsCount = React.Children.count(children);

		// return (
		// 	<tbody>
		// 		<RowSkeleton columns={children} emptyRecord={new this.model()} rowsCount={this.props.infinityScrollPartSize} />;
		// 	</tbody>
		// );

		return (
			<>
				<tbody>
					<tr className={'border' + (this.state.isLoading ? ' is-loading' : '')}>
						<th colSpan={columnsCount} />
					</tr>
					{/* //! +++++++ loading start part and loading parts */}

					{_rows}
					{this.state.isLoading && this.model && skeleton}
					{/* //! +++++++ */}
					{this.state.errors.map((error, i) => (
						<tr className='error' key={i}>
							<td colSpan={columnsCount}>{error}</td>
						</tr>
					))}
				</tbody>
			</>
		);
	}
}
