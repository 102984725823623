import { inject, observer } from 'mobx-react';
import React from 'react';
import { observable } from 'mobx';
import { fio } from 'client/tools';
import t from 'i18n';
import { renderStatus } from 'client/tools';
import { History } from 'components';

const FIELDS = {
	name: t('name'),
	departureCityId: t('request.departureCity'),
	destinationCityId: t('request.destinationCity'),
	deliveryDate: t('request.deliveryDate'),
	date: t('request.date'),
	isCollective: t('request.collective'),
	typeId: t('request.type'),
	levelId: t('level'),
	organizationId: t('organization.title'),
};

@inject('store')
@observer
export class Log extends React.Component {
	@observable items = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.record = this.props.store.record;
		this.init();
	}

	init = async () => {
		const getComments = async () =>
			await this.record.comments.find({
				include: [{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } }],
				order: 'createdAt asc',
			});

		const getLogs = async () =>
			await this.record.logs.find({
				include: ['owner', 'memberStatus', 'member', 'status'],
				order: 'createdAt asc',
			});

		const [logs, comments] = await Promise.all([getLogs(), getComments()]);

		comments.forEach((i) => (i.updated = 'comment'));

		const items = [...logs, ...comments].map((record) => {
			let { updated, memberId, ownerId, member, memberStatus, status } = record;
			if (updated === 'comment') updated = ['comment'];
			record.actions = [];
			const actions = record.actions;
			const newFields = [];
			// new request
			if (!updated) {
				actions.push({ title: t('history.requestCreate') });
				return record;
			}

			const map = {
				memberStatusId: {
					title: t('history.updateMemberStatus_short'),
					subject: memberId !== ownerId && fio(member),
					newValue: <span className='ml-2'>{renderStatus(memberStatus)}</span>,
				},
				comment: { title: t('history.leftCommentDiscussion') },
				addMember: { title: t('history.addMember'), subject: fio(member) },
				removeMember: { title: t('history.removeMember'), subject: fio(member) },
				addTrack: { title: t('history.addTrack'), subject: fio(member) },
				removeTrack: { title: t('history.removeTrack'), subject: fio(member) },
				addLadingBill: { title: t('history.addLadingBill'), subject: fio(member) },
				removeLadingBill: { title: t('history.removeLadingBill'), subject: fio(member) },
			};
			for (const field of updated) {
				if (field === 'statusId') {
					if (record.ownerId) actions.push({ title: t('history.requestStatus'), newValue: <span className='ml-2'>{renderStatus(status)}</span> });
					else if (status?.code === 'agreed') actions.push({ title: t('history.allAgreed'), newValue: <span className='ml-2'>{renderStatus(status)}</span> });
					else actions.push({ title: t('history.notAllAgreed'), newValue: <span className='ml-2'>{renderStatus(status)}</span> });
				} else if (map[field]) {
					actions.push(map[field]);
				} else {
					if (!newFields.length) {
						newFields.push(field);
						actions.push({ title: t('history.requestUpdate') + ':', newFields });
					} else newFields.push(field);
				}
			}

			return record;
		});
		this.items = items.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		return <History items={this.items} manualFields={FIELDS} />;
	}
}
