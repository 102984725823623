import React from 'react';

export const TextField = ({ label, value }) => {
	return (
		<div className='text-field'>
			<span className='label'>{label}:</span>
			<span className='value'>{value || '-'}</span>
		</div>
	);
};
