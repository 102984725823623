import { observable } from 'mobx';
import store from 'client/store';
import { MODEL_NAME, OPER_INFO_INCLUDE } from '../constant';

export default class OperStore {
	@observable record = null;
	@observable isLoading = true;
	id;
	onClose;

	constructor() {}

	get isNew() {
		return !this.id;
	}

	init = async () => {
		await this.fetchRecord();
	};

	fetchRecord = async () => {
		this.isLoading = true;
		if (this.id) {
			await this.refetchListRecord();
		} else this.createNewRecord();
		this.isLoading = false;
	};

	refetchListRecord = async () => {
		this.record = await store.model[MODEL_NAME].findById(this.id, OPER_INFO_INCLUDE);
	};

	createNewRecord = () => (this.record = new store.model[MODEL_NAME]({ date: new Date() }));

	onSave = async () => {
		await this.record.save();
		this.onClose();
	};

	onDelete = async () => {
		await this.record.delete();
		this.onClose();
	};

	onChange = (item, field) => {
		if (field === 'category') {
			this.record.category = item;
			this.record.nomenclature = null;
		}
		if (field === 'county') {
			this.record.city = null;
		}
	};
}
