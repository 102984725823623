import { observable } from 'mobx';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { asArray } from 'ol/color';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { get as getProj } from 'ol/proj';
import MVT from 'ol/format/MVT';
import TileGrid from 'ol/tilegrid/TileGrid';
import { ColorUtils } from '@smartplatform/ui';
import store from 'client/store';
// import Worker from 'client/tools/model.worker';

export default class QueartersLayer {

	@observable forestry = null;
	@observable forestryPrecinct = null;
	@observable forestCategory = 'all';
	@observable aviaDep = 'all';
	@observable monitoringZone = 'all';
	@observable isLoading = false;

	constructor(mapStore, settings) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		const resolutions = [];
		for (let i = 0; i <= 18; ++i) {
			resolutions.push(156543.03392804097 / Math.pow(2, i * 2));
		}
		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: new TileGrid({
				extent: getProj('EPSG:3857').getExtent(),
				resolutions: resolutions,
				tileSize: 512,
			}),
			tileUrlFunction: this.tileUrlFunction,
		});

		this.source.on('tileloadstart', this.onLoadStart);
		this.source.on('tileloadend', this.onLoadEnd);

		this.layer = new VectorTileLayer({
			source: this.source,
			style: function (feature, resolution) {
				if (!store.local.fires.layers.forestryQuarters.colorful) {
					const { id, code, zonecolor }= feature.getProperties();
					const [r, g, b] = ColorUtils.hexToArray(zonecolor);
					return new Style({
						fill: new Fill({ color: asArray([r, g, b, 1]) }),
						stroke: new Stroke({ color: '#000000', width: 0.3 }),
						text: new Text({
							textAlign: 'center',
							textBaseline: 'middle',
							// overflow: true,
							font: '11px/14px sans-serif',
							text: code,
							fill: new Fill({ color: '#000' }),
						})
					});
				}
				const { id, code }= feature.getProperties();
				const { background } = ColorUtils.getColorForString(String(id));
				const { r, g, b } = ColorUtils.hexToRgb(background);
				return new Style({
					// fill: new Fill({ color: '#0af' }),
					fill: new Fill({ color: asArray([r, g, b, 0.5]) }),
					// stroke: new Stroke({ color: '#004488', width: 0.5 }),
					stroke: new Stroke({ color: asArray([0, 68, 136, 1]), width: 0.5 }),
				});
			},
		});

		// console.log('>>>>>>>>>', this.layer);

		this.mapStore.addLayer(this.layer);
		this.setVisible();
		this.setOpacity();
	};

	tileUrlFunction = (coords) => {
		let filters = [];
		if (this.forestry && this.forestry !== 'all') {
			filters.push(`forestryid=${this.forestry.id}`);
		}
		if (this.forestryPrecinct && this.forestryPrecinct !== 'all') {
			filters.push(`forestryPrecinctId=${this.forestryPrecinct.id}`);
		}
		if (this.forestCategory && this.forestCategory !== 'all') {
			filters.push(`forestCategoryId=${this.forestCategory.id}`);
		}
		if (this.aviaDep && this.aviaDep !== 'all') {
			filters.push(`aviaDepId=${this.aviaDep.id}`);
		}
		if (this.monitoringZone && this.monitoringZone !== 'all') {
			filters.push(`monitoringZoneId=${this.monitoringZone.id}`);
		}

		if (filters.length === 0) {
			return null;
		}

		const filter = '&filter=' + filters.join(' and ');
		// console.log('filter', filter);

		return `/api/mvt?model=ViewForestryQuarter&x=${coords[1]}&y=${coords[2]}&z=${coords[0] * 2 - 1}${filter}&columns=id,code,zoneColor`;
		// return `/api/mvt?model=ViewForestryQuarter&x=${coords[1]}&y=${coords[2]}&z=${coords[0] * 2 - 1}&filter=forestryid=26`;
	};

	onForestryChange = record => {
		this.forestry = record;
		this.forestryPrecinct = null;
		this.isLoading = true;
		this.source.refresh();
	};

	onAviaDepChange = record => {
		this.aviaDep = record;
		this.isLoading = true;
		this.source.refresh();
	};

	onMonitoringZoneChange = record => {
		this.monitoringZone = record;
		this.isLoading = true;
		this.source.refresh();
	};

	setVisible = (value = undefined) => {
		this.layer.setVisible(value !== undefined ? value : this.settings.show);
	};

	setOpacity = () => {
		this.layer.setOpacity(this.settings.opacity || 0.5);
	};

	onClick = async (feature) => {
		const { id, name } = feature.getProperties();
		console.log('forestryQuarter', { id, name });
	};

	onLoadStart = () => {
		this.isLoading = true;
	};

	onLoadEnd = () => {
		this.isLoading = false;
	};

};
