export const DICTIONARIES_MODELS = [
	{ modelName: 'RequestMemberStatus' },
	{ modelName: 'RequestTrackStatus' },
	{
		modelName: 'Storehouse',
		listRelations: [
			{ relation: 'county', property: 'name' },
			{ relation: 'measure', property: 'name' },
		],
	},
	{ modelName: 'RequestType' },
	{ modelName: 'RegionLevel' },
	{ modelName: 'RequestStatus' },
	{ modelName: 'SupplyMeasure' },
	{ modelName: 'SupplyCategory', listRelations: [{ relation: 'parent', property: 'name' }] },
	{ modelName: 'SupplyNomenclature' },
	{ modelName: 'RequestCategory' },
	{ modelName: 'RequestRiver' },
	{ modelName: 'RequestPeriod' },
	{ modelName: 'RequestPeriodDate' },
	{ modelName: 'RequestPlan', listRelations: [{ relation: 'supplies', property: 'name' }] },
];

export const REQUEST_CODE = 'BASIC'
export const PLAN_CODE = 'SCHEDULE'
