import React from 'react';
import { observer } from 'mobx-react';
import FireAlarmMessageStore from './store';
import { Column, DateValue, Pager, Table } from '@smartplatform/ui';
import { MODEL_NAME } from './constants';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import { getPerPage, renderStatus } from 'client/tools';
@observer
export class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new FireAlarmMessageStore();
		this.store.path = props.path;
	}
	componentDidMount() {
		this.store.perPage = getPerPage();
	}

	render() {
		const { onQueryUpdate, onChange, totalCount, page, query, onRowClick, perPage } = this.store;
		const model = perPage ? store.model[MODEL_NAME] : undefined;
		let tableProps = model ? { model } : { rows: [] };
		return (
			<div className='model-list'>
				<h2>{t('fireAlarmMessage.plural')}</h2>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table query={query} onQueryUpdate={onQueryUpdate} key={model} {...tableProps} onRowClick={onRowClick}>
						<Column property='createdAt' label={t('createdAt')} />
						<Column property='date' label={t('techFire.notifier.messageDate')} />
						<Column property='operatorNumber' label={t('techFire.notifier.operatorNumber')} />
						<Column property='incidentCode' label={t('techFire.notifier.incidentCode')} />
						<Column relation='status' computed={renderStatus} label={t('status')} />
						<Column relation='source' property='description' label={t('techFire.notifier.source')} />
						<Column relation='adpiDevice' property='name' label={t('adpiDevice.title')} />
						<Column relation='object' property='name' label={t('techFire.notifier.fireObject')} />
					</Table>
				</div>
			</div>
		);
	}
}
