import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import icons from '../fires/icons';
import { Checkbox } from '@smartplatform/ui';
import { FilterLayout } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export default class FiresFilter extends React.Component {

	@observable statuses = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.statuses = await store.model.FireStatus.find({ order: 'id asc ' });
		this.isLoading = false;
	};

	onToggle = (value) => {
		this.props.layer.setVisible();
		if (value) this.props.layer.load();
	};

	onChange = async (statusCode, value) => {
		const { store: storeName } = this.props;
		console.log(store);
		console.log('statusCode', statusCode);
		store.local[storeName].layers.fires.status[statusCode] = value;
		store.local.save();
		this.props.layer.load();
	};

	render() {
		const { store: storeName } = this.props;
		const layers = store.local[storeName].layers;

		const legend = <div className='legend'>
			{Object.keys(icons).map(key => typeof icons[key] === 'object' && <div className='d-flex' key={key}>
				<span><img src={icons[key]?.src} alt="" height={16} /></span>
				<span>{icons[key].label}</span>
			</div>)}
		</div>

		return <FilterLayout layer={this.props.layer} title={t('fire.plural')} isLoading={this.isLoading} legend={legend}>
			{this.statuses.map(status => <div key={status.id}>
				<Checkbox
					value={layers.fires.status[status.code]}
					onChange={v => this.onChange(status.code, v)}
					label={t('fireStatus.codes.' + status.code)}
					disabled={!layers.fires.show}
				/>
			</div>)}
			{legend}
		</FilterLayout>;
	}

}
