import React from 'react';
import t from 'i18n';
import './style.scss';
import RequestStore from './store';
import { observer, Provider } from 'mobx-react';
import { MainInfo } from './main-info';
import { Form, Tab, Tabs } from '@smartplatform/ui';
import { DeleteButton } from 'components';
import { Actionbar } from 'components';
import { Breadcrumbs } from 'client/components';
import { Log } from './log';
import { CustomerInfo } from './customer-info';
import { Agreement } from './agreement';
import { LadingBills } from './lading-bills';
import store from 'client/store';
import { AddBillButton } from './lading-bills/AddBillButton';
import { ChangedPopup } from './ChangedPopup';
import { isAdmin, isMinArct } from 'delivery/helpers/roles';
import { REQUEST_CODE } from 'delivery/constants';

@observer
export class Request extends React.Component {
	constructor(props) {
		super(props);
		this.store = new RequestStore();
		const { url, path } = props.match;
		this.store.path = url;
		this.store.code = props.code;
		this.store.returnToPath = path.substr(0, path.lastIndexOf('/'));
		this.getData();
	}

	getData = () => {
		this.store.id = parseInt(this.props.match.params.id);
		this.store.init();
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.store.path = this.props.match.url;
			this.getData();
		}
	}

	render() {
		const { isLoading, record, onSave, onClean, back, isChanged, onLeave, showChangedPopup, path, returnToPath, code, beforeSave } = this.store;

		if (isLoading) return null;
		const { id } = record;
		const title = record.id ? `${this.store.record.type.name} №${id}` : `${t('create')}`;
		const isRequiredNotFilled = !!['typeId', 'date', 'name', 'statusId'].find((prop) => !record[prop]);
		const disableSave = isRequiredNotFilled || (id && !isChanged);
		const isLadingBillPath = store.route.path === `${path}/ladingBill`;

		const tabsWithControls = [`${path}/ladingBill`, path, `${returnToPath}/create`];
		const controls = tabsWithControls.includes(store.route.path) && (
			<Actionbar>
				<Actionbar.SaveButton disabled={disableSave} />
				<Actionbar.CancelButton back={back} />
				{!id && <Actionbar.CleanButton onClean={onClean} />}
				{isLadingBillPath && <AddBillButton store={this.store} />}
			</Actionbar>
		);

		const isBasic = code === REQUEST_CODE;

		let tabs = [{ title: t('request.mainInfo'), path, render: () => <MainInfo /> }];
		if (id) {
			let notIsNewTabs = isBasic ? [{ title: t('agreement'), path: `${path}/agreement`, render: () => <Agreement /> }] : [];
			notIsNewTabs = [
				...notIsNewTabs,
				...[
					{ title: t('request.customerInfo'), path: `${path}/customerInfo`, render: () => <CustomerInfo />, disabed: !record.organization },
					{ title: t('historyLog'), path: `${path}/requestLog`, render: () => <Log /> },
					{ title: t('ladingBill.title'), path: `${path}/ladingBill`, render: () => <LadingBills /> },
				],
			];
			tabs = [...tabs, ...notIsNewTabs];
		}
		return (
			<Provider store={this.store}>
				<div className='request'>
					<div className='header'>
						<div>
							<Breadcrumbs crumbs={[{ path: returnToPath, title: t(`request.${code}`) }, { title }]} />
							<h2>{title}</h2>
						</div>
						{(isMinArct() || isAdmin()) && <DeleteButton text={t('request.delete')} onConfirm={this.store.onDelete} />}
					</div>
					<Form record={record} controls={controls} stay onSave={onSave} beforeSave={beforeSave} onDelete={back} noDelete noSave>
						<Tabs key={id}>
							{tabs.map((tab, index) => (
								<Tab {...tab} key={index} onLeave={onLeave} exact />
							))}
						</Tabs>
						{showChangedPopup && <ChangedPopup />}
					</Form>
				</div>
			</Provider>
		);
	}
}
