import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Warehouse } from './Warehouse';

import './warehouse.scss';

export const WarehouseRoute = (props) => {
	return (
		<Switch>
			<Route exact path='/techfires/firedep/:fireDepId/warehouse' render={(routeProps) => <Warehouse {...routeProps} {...props} />} />
			<Route exact path='/techfires/firedep/:fireDepId/warehouse/sizod' render={(routeProps) => <Warehouse {...routeProps} {...props} />} />
		</Switch>
	);
};
