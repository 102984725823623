export const MODEL_NAME = 'EmergencySituationPlan';

// list single
export const EMERGENCY_SITUATION_PLANS_INCLUDE = {
	include: [
		{ relation: 'status', scope: { fields: ['id', 'name', 'color'] } },
		{ relation: 'level', scope: { fields: ['id', 'name'] } },
		{ relation: 'county', scope: { fields: ['id', 'name'] } },
		{ relation: 'members', scope: { include: [{ relation: 'status', scope: { fields: ['id', 'color', 'name'] } }, { relation: 'user' }] } },
	],
	fields: ['id', 'countyId', 'levelId', 'riskCategoryId', 'statusId', 'date'],
};

export const LOCAL_STORAGE_FIELDS = ['showFilters', 'page', 'search'];
