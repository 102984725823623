import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { RecordSelect, Select } from '@smartplatform/ui';
import { FilterLayout } from '..';
import store from 'client/store';
import t from 'i18n';

@observer
export default class FireDepFilter extends React.Component {

	static propTypes = {
		layer: PropTypes.object.isRequired,
	};
	
	@observable county = null;
	@observable counties = [];
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		// this.init();
	}
	
	init = async () => {
		const { layer } = this.props;
		const counties = await store.model.County.find({ fields: ['id', 'name'], order: 'name asc'});
		const firstIndex = counties.findIndex(county => county.id === 1);
		if (firstIndex !== -1) {
			const firstCounty = counties[firstIndex];
			counties.splice(firstIndex, 1);
			counties.splice(0, null, firstCounty);
		}
		this.county = layer.settings.countyId ? counties.find(county => layer.settings.countyId === county.id) : null;
		this.counties = counties;
		this.isLoading = false;
	};
	
	onCountyChange = async (county) => {
		this.county = county;
		this.props.layer.settings.countyId = county ? county.id : null;
		store.local.save();
	};
	
	render() {
		const { layer } = this.props;
		
		return <FilterLayout title={t('fireDep.plural')} {...this.props}>
{/*
			{!this.isLoading && <Select
				items={this.counties.map(county => ({ label: county.name, value: county }))}
				value={this.county}
				onChange={this.onCountyChange}
				property="name"
				showValue={!this.county ? t('all') : this.county.name}
				empty={t('all')}
			/>}
*/}
		</FilterLayout>;
	}

}
