import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { MapLayerGroups } from '@smartplatform/map/client';
import store from "client/store";

@observer
export default class LayerGroups extends React.Component {

	static propTypes = {};
	static defaultProps = {};

	constructor(props) {
		super(props);
	}

	render() {
		return <div>
			<MapLayerGroups modelStore={store.model} />
		</div>;
	}

}
