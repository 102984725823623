import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Loader, Form, Field, Row, DatePicker, Input, RecordSelect, Button } from '@smartplatform/ui';
import FireDepList from './FireDepList';
import t from 'i18n';
import store from 'client/store';

@observer
export default class Edit extends React.Component {
	
	@observable globalShift = null;
	@observable error = null;
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		try {
			this.globalShift = await store.model.TechFireShift.findById(this.props.match.params.id);
		}
		catch (e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};
	
	edit = () => store.route.push({ path: `/techfires/shifts/${this.props.match.params.id}/edit` });
	
	render() {
		if (this.isLoading) return <Loader />;
		if (this.error) return <div className="error">{this.error}</div>;
		
		const controls = this.props.isMain ?
			<div className="actions">
				<Button onClick={this.edit} variant="primary">{t('edit')}</Button>
			</div>
			:
			null;
		
		return <div>
			{controls}
			<Form record={this.globalShift} disabled noSave noDelete>
				<Row>
					<Field property="name" label={t('name')} />
					<Field property="date" label={t('date')}><DatePicker /></Field>
					<div />
				</Row>
			</Form>
			<FireDepList {...this.props} />
		</div>;
	}
}
