import React from 'react';
import { action, observable, toJS } from 'mobx';
import debounce from 'lodash/debounce';
import store from "client/store";
import { shortenAddressName } from 'client/tools';
import { Geometry } from 'wkx';

const DADATA_API_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs';
const DADATA_API_KEY = '842709bce84734ee40403e8ce6ef9bb80e722fa5';
const DADATA_SECRET_KEY = '2a8c0542bb7ca0e1248a580104c255c9edfb5ec4';

export default class AddressSearchStore {

	@observable search = '';
	@observable prevSearch = '';
	@observable actualSearch = '';
	@observable isLoading = false;
	@observable suggestions = [];
	@observable active = false;
	@observable focus = false;
	@observable selected = null;
	
	triggerInstance = null;
	
	constructor(props) {
		this.props = props;
		this.doSearch = debounce(this.doSearch, 500, { leading: true, trailing: true });
		if (this.props.getInstance) this.props.getInstance({
			setSuggestions: this.setSuggestions,
		});
	}
	
	getInputRef = (el) => this.inputEl = el;
	
	toggle = (activate) => {
		if (this.triggerInstance) {
			const isActive = this.triggerInstance.isActive();
			if (activate && !isActive) this.activate();
			if (!activate && isActive) this.close();
		}
	};
	
	activate = () => {
		if (this.triggerInstance) this.triggerInstance.activate();
	};
	
	close = () => {
		if (this.triggerInstance) this.triggerInstance.close();
	};
	
	onValueClick = () => {
		console.log('onValueClick', this.props.value);
		this.selected = this.props.value;
		this.search = this.prevSearch = shortenAddressName(this.props.value.name);
		if (this.props.onValueClick) this.props.onValueClick(this.props.value);
		if (!this.props.noImmediateSearch) {
			this.activate();
			this.doSearch();
		}
	};
	
	onActivate = () => {
		this.active = true;
		console.log('onActivate')
	};
	
	onClose = () => {
		this.active = false;
		this.prevSearch = this.search;
		this.search = this.selected ? shortenAddressName(this.selected.name) : '';
	};
	
	onSelect = (obj) => async () => {
		console.log('select', toJS(obj));
		this.isLoading = true;
		const geoObj = {
			type: 'Point',
			coordinates: [ parseFloat(obj.data['geo_lon']), parseFloat(obj.data['geo_lat']) ],
		};
		
		if (obj.__type === 'db') {
			this.search = obj.name;
			this.props.onChange && this.props.onChange(obj);
			this.selected = obj;
			this.close();
		}
		else {
			if (!obj.data.house) {
				this.prevSearch = this.search = obj.value + ' ';
				await this.doSearch();
				setTimeout(() => {
					if (this.inputEl) {
						const length = this.inputEl.value.length;
						this.inputEl.setSelectionRange(length, length);
						this.inputEl.focus();
					}
				}, 100);
			}
			else {
				let address;
				const existing = await store.model.Address.find({
					where: { name: obj['unrestricted_value'] }
				});
				if (existing.length === 0) {
					console.log('🏠 create Address', obj['unrestricted_value'], {...geoObj}, {...obj});
					address = new store.model.Address({
						name: obj['unrestricted_value'],
						street: obj.data.street,
						building: obj.data.house,
					});
					address.geo = geoObj;
					await address.save();
				}
				else {
					address = existing[0];
					console.log('🏠 existing Address', existing[0], existing.length > 1 ? 'more than one!' : 'ok', address.geo)
					if (!address.geo) {
						address.geo = geoObj;
						await address.save();
						console.log('...saved geo', {...geoObj})
					}
				}
				this.props.onChange && this.props.onChange(address);
				this.selected = address;
				this.close();
			}
		}
		this.isLoading = false;
	};
	
	@action onChange = e => {
		this.search = e.target.value;
		this.selected = null;
		this.checkActive();
		this.doSearch();
	}
	
	checkActive = () => {
		const active = this.search.trim().length > 0;
		this.toggle(active);
	};
	
	onFocus = () => {
		this.focus = true;
		if (this.prevSearch) this.search = this.prevSearch;
		this.props.onFocus && this.props.onFocus();
		this.checkActive();
	};
	
	onBlur = () => this.focus = false;
	
	doSearch = async () => {
		const trimmed = this.search.trim();
		if (trimmed.length === 0) return;

		this.isLoading = true;
		const words = trimmed.replace(',', ' ').replace(/\s+/g, ' ').split(' ');
		
		let suggestions = [];
/*
		suggestions = await store.model.Address.find({
			where: {
				name: { ilike: `%${words.join('%')}%` }
			},
			limit: 5,
		});
		suggestions.forEach(s => s.__type = 'db');
*/
		
		const fromFias = await this.searchDadata(trimmed);
		fromFias.forEach(s => s.__type = 'fias');
		const filtered = fromFias
			.filter(obj => {
				// const unrestricted = this.makeUnrestrictedValue(obj);
				// console.log('?', obj.data['fias_level'] > 8, unrestricted)
				if (obj.data['fias_level'] > 8) return false;
				if (!obj.data.house) return true;
				if (!obj.data['house_fias_id']) return false;
				return true;//unrestricted === obj['unrestricted_value'];
			});
		// suggestions = [...suggestions, ...filtered];
		suggestions = [...filtered];
		// suggestions.forEach(s => console.log('-', s.data['fias_level'], s['unrestricted_value']));

		this.suggestions = suggestions;
		this.isLoading = false;
	};
	
	setSuggestions = (suggestions) => {
		this.activate();
		this.suggestions = suggestions;
	};
	
	makeUnrestrictedValue = (obj) => {
		let str = '';
		if (obj.data['region_with_type']) str += obj.data['region_with_type'] + ' ';
		if (obj.data['area_with_type']) str += obj.data['area_with_type'] + ' ';
		if (obj.data['city_with_type']) str += obj.data['city_with_type'] + ' ';
		if (obj.data['settlement_with_type']) str += obj.data['settlement_with_type'] + ' ';
		if (obj.data['street_with_type']) str += obj.data['street_with_type'] + ' ';
		if (obj.data['house']) str += obj.data['house_type'] + ' ' + obj.data['house'];
		return str;
/*
		let str = obj.data['postal_code'] + ', ';
		str += obj.data['region_with_type'] + ', ';
		str += obj.data['city_with_type'] + ', ';
		str += obj.data['street_with_type'] + ', ';
		str += obj.data['house_type'] + ' ' + obj.data['house'];
		return str;
*/
	};

	searchDadata = async (query) => {
		const body = {
			query: this.props.prefix + ' ' + query,
			count: 20,
			from_bound: { value: 'settlement' },
			to_bound: { value: 'house' },
		};
		let res = await fetch(`${DADATA_API_URL}/suggest/address`, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Token ${DADATA_API_KEY}`
			},
		});
		const json = await res.json();
		return json.suggestions;
	};
	
}