import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import HomeLineIcon from '!!svg-react-loader!img/icons/fires/dashboard/home-line.svg';
import { Checkbox, Loader } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { Hint } from 'fires/map/filters';

const hint = (
	<div>
		Для демонстрации радиусы
		<br />
		увеличиваются до 20 и 50 км.
		<br />
		На случай, если угроз населенным
		<br />
		пунктам в данный момент нет.
	</div>
);

@inject('dashboardStore')
@observer
export default class Danger extends React.Component {
	breakByCounties = (cities) => {
		const counties = {};
		for (let city of cities) {
			if (!counties[city.countyId]) {
				counties[city.countyId] = { id: city.countyId, name: city.countyName, cities: [] };
			}
			counties[city.countyId].cities.push(city);
		}
		return counties;
	};

	render() {
		const { dashboardStore } = this.props;

		let content = <Loader />;

		if (!dashboardStore.citiesLoading) {
			const { special, underway, unattended } = dashboardStore.citiesData.cities;

			if (!special || !underway || !unattended) return null;

			const total = special.length + underway.length + unattended.length;

			const specialByCounties = this.breakByCounties(special);
			const underwayByCounties = this.breakByCounties(underway);
			const unattendedByCounties = this.breakByCounties(unattended);

			content = (
				<>
					<FontAwesomeIcon icon={faCheckCircle} className='ok-icon' /> {t('fireDashboard.noDanger')}
				</>
			);

			if (total > 0) {
				content = (
					<>
						{special.length > 0 && (
							<div className='cities'>
								<div className='title-block special'>
									<h3>{t(`fireDashboard.${!store.local.fires.citiesTest ? 'citiesSpecial' : 'citiesSpecialTest'}`)}</h3>
									<strong>{special.length}</strong>
									<em>{t('fireDashboard.citiesCount')}</em>
								</div>
								<table>
									<thead>
										<tr>
											<th>{t('county.plural')}</th>
											<th className='center'>{t('fireDashboard.count')}</th>
											<th>{t('city.pluralShort')}</th>
											<th>{t('fireDashboard.distance')}</th>
										</tr>
										{Object.keys(specialByCounties).map((countyId) => {
											const county = specialByCounties[countyId];
											return (
												<tr key={countyId}>
													<td width={150}>{county.name.replace(/улус/, '')}</td>
													<td width={60} className='center'>
														{county.cities.length}
													</td>
													<td>
														{county.cities.map((city) => (
															<div key={city.id}>{city.name}</div>
														))}
													</td>
													<td width={60} className='text-right'>
														{county.cities.map((city) => (
															<div key={city.id}>{city.distance.toFixed(1)}</div>
														))}
													</td>
												</tr>
											);
										})}
									</thead>
								</table>
							</div>
						)}

						{underway.length > 0 && (
							<div className='cities'>
								<div className='title-block underway'>
									{/*<h3>{t('fireDashboard.citiesUnderway')}</h3>*/}
									<h3>{t(`fireDashboard.${!store.local.fires.citiesTest ? 'citiesUnderway' : 'citiesUnderwayTest'}`)}</h3>
									<strong>{underway.length}</strong>
									<em>{t('fireDashboard.citiesCount')}</em>
								</div>
								<table>
									<thead>
										<tr>
											<th>{t('county.plural')}</th>
											<th className='center'>{t('fireDashboard.count')}</th>
											<th>{t('city.pluralShort')}</th>
											<th>{t('fireDashboard.distance')}</th>
										</tr>
										{Object.keys(underwayByCounties).map((countyId) => {
											const county = underwayByCounties[countyId];
											return (
												<tr key={countyId}>
													<td width={150}>{county.name.replace(/улус/, '')}</td>
													<td width={60} className='center'>
														{county.cities.length}
													</td>
													<td>
														{county.cities.map((city) => (
															<div key={city.id}>{city.name}</div>
														))}
													</td>
													<td width={60} className='text-right'>
														{county.cities.map((city) => (
															<div key={city.id}>{city.distance.toFixed(1)}</div>
														))}
													</td>
												</tr>
											);
										})}
									</thead>
								</table>
							</div>
						)}

						{unattended.length > 0 && (
							<div className='cities'>
								<div className='title-block unattended'>
									{/*<h3>{t('fireDashboard.citiesUnattended')}</h3>*/}
									<h3>{t(`fireDashboard.${!store.local.fires.citiesTest ? 'citiesUnattended' : 'citiesUnattendedTest'}`)}</h3>
									<strong>{unattended.length}</strong>
									<em>{t('fireDashboard.citiesCount')}</em>
								</div>
								<table>
									<thead>
										<tr>
											<th>{t('county.plural')}</th>
											<th className='center'>{t('fireDashboard.count')}</th>
											<th>{t('city.pluralShort')}</th>
											<th>{t('fireDashboard.distance')}</th>
										</tr>
										{Object.keys(unattendedByCounties).map((countyId) => {
											const county = unattendedByCounties[countyId];
											return (
												<tr key={countyId}>
													<td width={150}>{county.name.replace(/улус/, '')}</td>
													<td className='center'>{county.cities.length}</td>
													<td>
														{county.cities.map((city) => (
															<div key={city.id}>{city.name}</div>
														))}
													</td>
													<td className='text-right'>
														{county.cities.map((city) => (
															<div key={city.id}>{city.distance.toFixed(1)}</div>
														))}
													</td>
												</tr>
											);
										})}
									</thead>
								</table>
							</div>
						)}
					</>
				);
			}
		}

		return (
			<div className='danger block'>
				<div className='title'>
					<HomeLineIcon />
					<h2>{t('fireDashboard.cityDanger')}</h2>
				</div>
				<div className='content'>
					{content}

					<div className='mt-3'>
						<Checkbox value={store.local.fires.citiesTest} onChange={this.toggleTest} label='Увеличить минимальные расстояния' />
						<Hint text={hint} trigger='hover' />
					</div>
				</div>
			</div>
		);
	}
}
