import React from 'react';
import Root from './Root';
import t from 'i18n';
import tMap from '@smartplatform/map/i18n';
import store from 'client/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import EmergencyIcon from '!!svg-react-loader!img/icons/menu/emergency.svg';
import JourneyIcon from '!!svg-react-loader!img/icons/sidebar/shared/journey.svg';
import PlanIcon from '!!svg-react-loader!img/icons/sidebar/emergency/plan.svg';
import DictionaryIcon from '!!svg-react-loader!img/icons/sidebar/shared/dictionary.svg';
import MapIcon from '!!svg-react-loader!img/icons/sidebar/shared/map.svg';
import AdminIcon from '!!svg-react-loader!img/icons/sidebar/shared/admin.svg';
import ContragentsIcon from '!!svg-react-loader!img/icons/sidebar/shared/contragents.svg';

export const path = '/emergency';

export default {
	name: t('module.emergency'),
	code: 'emergency',
	icon: <EmergencyIcon />,
	path,
	component: Root,
	access: () => store.model.Municipality && store.model.Municipality.INFO.WRITE,
	menu: [
		{ title: t('emergency.map'), path: `${path}/map`, icon: <MapIcon />, exact: true },
		{ title: t('emergencySituationPlan.plural'), path: `${path}/plans`, icon: <EmergencyIcon /> },
		{ title: t('emergency.registry'), path: `${path}/municipalities`, icon: <PlanIcon /> },
		{ title: t('contragent.plural'), path: `${path}/contragents`, icon: <ContragentsIcon /> },
		{ title: t('audit.title'), path: `${path}/journal`, icon: <JourneyIcon /> },
		{ title: tMap('map.layer.plural'), path: `/layers`, icon: <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 26 }} /> },
		{ title: t('dictionaries.plural'), path: `${path}/dictionaries`, icon: <DictionaryIcon /> },
		{ title: t('admin.titleShort'), path: `${path}/admin`, icon: <AdminIcon /> },
	],
};
