import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import store from 'client/store';
import { Edit } from './Edit';

export const Resources = ({ match }) => {
	const { path } = match;
	const model = store.model['EmergencySituationPlanResource'];

	return (
		<Switch>
			<Route path={path} exact render={(routeProps) => <List {...routeProps} model={model} />} />
			<Route path={`${path}/:id`} render={(routeProps) => <Edit {...routeProps} model={model} path={path} />} />
		</Switch>
	);
};
