import { inject, observer } from 'mobx-react';
import React from 'react';
import { observable } from 'mobx';
import t from 'i18n';
import { Column, Pager, Table } from '@smartplatform/ui';
import { ListStore, AddButtonSecondary } from 'components';
import { getPerPage } from 'client/tools';
import store from 'client/store';
import { RESOURCES_SCOPE } from '../constants';

@inject('store')
@observer
export class List extends React.Component {
	@observable records = [];
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.path = props.match.path;
		this.model = props.model;
		this.store = new ListStore({ filters: { where: { planId: props.store.record.id }, ...RESOURCES_SCOPE } });
	}

	componentDidMount() {
		if (!this.error) this.store.setPerPage(getPerPage());
	}

	onRowClick = (record) => store.route.push({ path: `${this.path}/${record.id}` });
	add = () => store.route.push({ path: `${this.path}/create` });
	renderVehicleCount = (vehicles) => vehicles.reduce((acc, value) => acc + value.count, 0);

	render() {
		const { onQueryUpdate, onChange, totalCount, page, query, perPage, getInstance, isPerPageSetted } = this.store;
		const { onRowClick } = this;
		const tableProps = isPerPageSetted ? { model: this.model } : { rows: [] };

		return (
			<div className='model-list'>
				<div>
					<AddButtonSecondary onClick={this.add} text={t('add')} />
				</div>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table query={query} onQueryUpdate={onQueryUpdate} {...tableProps} key={tableProps.model} onRowClick={onRowClick} getInstance={getInstance}>
						<Column relation='division' property='name' label={t('emergencyDivision.title')} width={200} />
						<Column property='personnelCount' label={t('emergencySituationPlanResource.personnelCount')} width={100} />
						<Column relation='vehicles' computed={this.renderVehicleCount} label={t('emergencySituationPlanResource.vehicleCount')} width={100} />
						<Column relation='tier' property='name' label={t('emergencySituationPlanResourceTier.title_short')} width={100} />
					</Table>
				</div>
			</div>
		);
	}
}
