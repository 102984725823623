import React from 'react';
import { observable } from 'mobx';

import store from 'client/store';
import { FIRE_DEP_SCOPE, MODEL_NAME } from './constants';

export default class FireDepStore {
	@observable fireDep = null;
	@observable isLoading = true;

	constructor(id) {
		this.id = parseInt(id);
		this.model = store.model[MODEL_NAME];
		this.init();
	}

	get isNew() {
		return Number.isNaN(this.id);
	}

	get canEdit() {
		return this.model?.INFO.WRITE;
	}

	init = async () => {
		this.isLoading = true;
		this.fireDep = this.isNew ? new this.model() : await this.model.findById(this.id, { ...FIRE_DEP_SCOPE });
		this.isLoading = false;
	};
}
