// функция сравнения значений записи
export const isEqual = ({ propertyType, a, b }) => {
	if (a === null && (b === null || b === false)) return true; // отсекаем случай с обоими null-ами и случай с чекбоксом
	if (a === null || b === null) return false; // отсекаем случай когда хоть один = null

	if (propertyType === 'Date' && a && b) {
		return new Date(a).getTime() === new Date(b).getTime();
	}

	return a === b;
};
