import React from 'react';

export const LabelField = ({ children, label, className = 'form-field' }) => {
	return (
		<div className={className}>
			<label>{label}</label>
			{children}
		</div>
	);
};
