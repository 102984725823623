import React from 'react';
import FirePopup from './FirePopup';
import t from 'i18n';

export default {
	modelName: 'Fire',
	title: t('fire.short'),
	filterTitle: t('fire.plural'),
	showInFilters: false,
	priority: 1,
	renderPopup: record => <FirePopup key={record.id} record={record} />,
};
