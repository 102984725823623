import { inject, observer } from 'mobx-react';
import React from 'react';
import { Column, Table } from '@smartplatform/ui';
import { renderStatus } from 'client/tools';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { REQUEST_PATH } from 'delivery/requests/constants';

@inject('store')
@observer
export class Agreements extends React.Component {
	constructor(props) {
		super(props);
		this.props.store.fetchAgreements();
	}

	onRowClick = (record) => store.route.push({ path: `${REQUEST_PATH}/${record.requestId}/agreement` });

	render() {
		return (
			<div className='agreements'>
				<Table rows={this.props.store.agreements} key={this.props.store.agreements.length} onRowClick={this.onRowClick}>
					<Column relation='request' property='name' label={t('request.title')} />
					<Column relation='user' computed={fio} label={t('fullName_full')} />
					<Column property='position' label={t('organization.position')} />
					<Column relation='status' computed={renderStatus} label={t('status')} />
				</Table>
			</div>
		);
	}
}
