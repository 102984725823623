import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';
import { Button, NumberInput, RecordSelect, Row, Field, Input, Popconfirm, RelationSelect } from '@smartplatform/ui';
import { AddButton } from 'components';

import '../style.scss';

@observer
export class Gasi extends React.Component {
	@observable hydraulicTools = [];
	@observable isAdding = false;
	@observable changedValuesId = new Set();

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const hydraulicTools = await this.props.vehicle.hydraulicTools.find({
			include: 'tool',
		});
		this.hydraulicTools = hydraulicTools.reverse();
	};

	onChange = (prop, record) => async (value) => {
		if (record.id) this.changedValuesId.add(record.id);

		if (prop === 'name') {
			record.toolId = value.id;
			record.tool = value;
		} else if (prop === 'amount') {
			record.amount = value;
		}
	};

	onSave = async (record, type, index) => {
		await record.save();
		this.changedValuesId.delete(record.id);
		if (type === 'add') this.isAdding = false;
	};

	cancelAdding = () => {
		this.isAdding = false;
		this.hydraulicTools.shift();
	};

	onDelete = async (record, row) => {
		const index = this.hydraulicTools.findIndex((hTool) => hTool.id === record.id);
		this.hydraulicTools.splice(index, 1);
		await record.delete();
	};

	onAddVehicleTool = () => {
		this.isAdding = true;
		this.hydraulicTools.unshift(new store.model.FireDepVehicleTool({ vehicleId: this.props.vehicle.id }));
	};

	renderActions = (record) => {
		return record.id ? (
			<>
				<Button onClick={() => this.onSave(record)} variant='primary' disabled={!this.changedValuesId.has(record.id)}>
					{t('save')}
				</Button>
				<Popconfirm content={t('delete')} onConfirm={() => this.onDelete(record)}>
					<Button>{t('delete')}</Button>
				</Popconfirm>
			</>
		) : (
			<>
				<Button onClick={() => this.onSave(record, 'add')} variant='primary' disabled={!record.toolId || !record.amount}>
					{t('add')}
				</Button>
				<Button onClick={this.cancelAdding}>{t('cancel')}</Button>
			</>
		);
	};

	render() {
		return (
			<>
				<h1 className='title-name'>{t('fireDepVehicleHydraulicTool.title')}</h1>
				<div className='vehicle-addition tools'>
					<AddButton text={t('fireDepVehicleHydraulicTool.add')} onClick={this.onAddVehicleTool} disabled={this.isAdding} />
					{!!this.hydraulicTools.length && (
						<div className='addition-items-block'>
							{this.hydraulicTools.map((hTool) => (
								<Row key={hTool.id || 'new'}>
									<div className='form-field'>
										<RecordSelect model={store.model.HydraulicTool} property='name' value={hTool.tool} onChange={this.onChange('name', hTool)} isRequired />
									</div>
									<div className='form-field'>
										<NumberInput value={hTool.amount} onChange={this.onChange('amount', hTool)} />
									</div>
									{this.renderActions(hTool)}
								</Row>
							))}
						</div>
					)}
				</div>
			</>
		);
	}
}
