import React from 'react';
import Root from './Root';
import t from 'i18n';
import store from 'client/store';
import RegistryIcon from '!!svg-react-loader!img/icons/sidebar/shared/registry.svg';
import MapIcon from '!!svg-react-loader!img/icons/sidebar/shared/map.svg';
import FiresIcon from '!!svg-react-loader!img/icons/menu/fires.svg';
import RaidsIcon from '!!svg-react-loader!img/icons/sidebar/fires/raids.svg';
import DictionaryIcon from '!!svg-react-loader!img/icons/sidebar/shared/dictionary.svg';
import ForceAvailabilityIcon from '!!svg-react-loader!img/icons/sidebar/fires/force-availability.svg';
import ReportIcon from '!!svg-react-loader!img/icons/sidebar/shared/report.svg';
import DashboardIcon from '!!svg-react-loader!img/icons/sidebar/shared/dashboard.svg';
import RequestLog from '!!svg-react-loader!img/icons/sidebar/shared/request-log.svg';
import EmergencyIcon from '!!svg-react-loader!img/icons/sidebar/fires/emergency.svg';
import TransportData from '!!svg-react-loader!img/icons/sidebar/shared/transport-data.svg';
import IsdmIcon from '!!svg-react-loader!img/icons/sidebar/fires/isdm.svg';

export const path = '/fires';

export default {
	name: t('fire.plural'),
	code: 'fires',
	icon: <FiresIcon />,
	path,
	component: Root,
	access: () => store.model.Fire && store.model.Fire.INFO.READ,
	menu: [
		{ title: t('fireDashboard.title'), path: `${path}/dashboard`, icon: <DashboardIcon />, exact: true },
		{ title: t('fire.map'), path: `${path}/map`, icon: <MapIcon />, exact: true },
		{ title: t('fire.registry.title'), path: `${path}/registry`, icon: <RegistryIcon /> },
		{ title: t('airRaid.plural'), path: `${path}/raids`, icon: <RaidsIcon /> },
		{ title: t('forceAvailability.plural'), path: `${path}/force-availability`, icon: <ForceAvailabilityIcon /> },
		{ title: t('emergencySituation.plural'), path: `${path}/emergency-situation`, icon: <EmergencyIcon /> },
		{ title: t('requestLog.plural'), path: `${path}/request-log`, icon: <RequestLog /> },
		{ title: t('report.plural'), path: `${path}/reports`, icon: <ReportIcon /> },
		{ title: t('transportData.title'), path: `${path}/transport-data`, icon: <TransportData /> },
		{ title: t('dictionaries.plural'), path: `${path}/dictionaries`, icon: <DictionaryIcon /> },
		{ title: t('isdm.title'), path: `${path}/isdm`, icon: <IsdmIcon /> },
	],
};
