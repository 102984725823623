export default {
	translation: {
		dashboard: 'Сводка',
		edit: 'Редактировать',
		zoom: 'Увеличение',
		editAvatar: 'Редактировать аватар',
		addAvatar: 'Добавить аватар',
		upload: 'Загрузить',
		profile: 'Профиль',
		administration: 'Администрирование',
		checkAll: 'Отметить все',
		uncheckAll: 'Убрать все',
		addComment: 'Добавить комментарий',
		overall: 'Итого',
		editComment: 'Редактировать комментарий',
		population: 'Население',
		downloadReport: 'Скачать отчет',
		result: 'Результат',
		note: 'Примечание',
		garrison: 'Гарнизон',
		extinguishingAgents: 'Огнетушащие вещества',
		division: 'Подразделение',
		risks: 'Риски',
		peopleCount: 'Количество людей',
		teenagersCount: 'Количество детей',
		group: 'Группа',
		geo: 'Геометрия',
		pointGeo: 'Геометрия(точка)',
		id: 'Id',
		phoneNumber: 'Телефонный номер',
		fireNotifier: 'Пожарный извещатель',
		registries: 'Реестры',
		notSave: 'Не сохранять',
		historyLog: 'Журнал событий',
		agreement: 'Согласование',
		equipment: 'Оборудование',
		measure: 'Единица измерения',
		level: 'Уровень',
		price: 'Цена',
		byAddress: 'По адресам',
		byObjects: 'По объектам',
		quantity: 'Количество',
		fullName_full: 'Фамилия, имя, отчество',
		fullName: 'ФИО',
		position: 'Должность',
		attachFiles: 'Прикрепить файлы',
		deleteFile: 'Удалить файл',
		clean: 'Очистить',
		type: 'Тип',
		typeVehicle: 'Вид Техники',
		category: 'Категория',
		categories: 'Категории',
		number: '№',
		createdAt: 'Дата создания',
		selectedStatuses: 'Выбрано статусов',
		selectStatus: 'Выберите статус',
		selectType: 'Выберите тип',
		selectField: 'Выберите поле',
		color: 'Цвет',
		accept: 'Подтвердить',
		active: 'Активен',
		cleanFilters: 'Очистить фильтры',
		_filter: 'Фильтр',
		week: 'Неделя',
		quarter: 'Квартал',
		year: 'Год',
		title: 'Оперативный мониторинг обстановки',
		name: 'Наименование',
		noName: 'Без названия',
		rank: 'Звание',
		shortName: 'Сокращенное наименование',
		section: 'Отдел',
		LOGIN_FAILED: 'Неверный логин/пароль',
		LOGIN_WAIT: 'Превышен лимит попыток',
		LOGIN_EMPTY_CAPTCHA: 'Не введен код',
		LOGIN_INVALID_CAPTCHA: 'Неверный код',
		AUTHORIZATION_REQUIRED: 'Необходимо авторизоваться',
		description: 'Описание',
		create: 'Создать',
		save: 'Сохранить',
		backToList: 'Назад к списку',
		notSaved: 'не сохранено',
		longitude: 'Долгота',
		latitude: 'Широта',
		lonShort: 'д.',
		latShort: 'ш.',
		coordinates: 'Координаты',
		azimuth: 'Азимут',
		distance: 'Дистанция',
		metersPerSecond: 'м/с',
		loading: 'Загрузка...',
		showOnMap: 'Показывать на карте',
		addRecord: 'Добавить новую запись',
		editRecord: 'Изменить запись',
		copyRecord: 'Копировать запись',
		update: 'Обновить',
		send: 'Отправить',
		sending: 'Отправляется',
		export: 'Экспорт',
		import: 'Импорт',
		confirm: 'Подтвердить?',
		confirmDelete: 'Удалить?',
		squareKm: 'кв.км',
		km: 'км',
		ga: 'га',
		all: 'Все',
		off: 'выкл.',
		date: 'Дата/Время',
		date_short: 'Дата',
		date1: 'Дата (от)',
		date2: 'Дата (до)',
		age1: 'Возраст (от)',
		age2: 'Возраст (до)',
		attachment: 'Прикрепленый файл',
		attachments: 'Прикрепленые файлов',
		default: 'По умолчанию',
		count: 'Количество',
		countShort: 'Кол-во',
		copy: 'Копирование',
		mainInfo: 'Основные данные',
		info: 'Информация',
		dateTime: 'Дата/время',
		dateFrom: 'от',
		at: 'в',
		downloadExcel: 'Скачать в Excel',
		no: 'нет',
		depTitle: 'Звание',
		opacity: 'Прозрачность',
		go: 'Перейти',
		reset: 'Сбросить',
		system112: 'Система 112',
		emergencyPlan: 'Планирование ЧС',
		statuses: 'Статусы',
		metre: 'м',
		enter: {
			title: 'Введите',
			ptp: 'Введите ПТП',
			ktp: 'Введите КТП',
			name: 'Введите название',
			addressName: 'Введите адрес',
			description: 'Введите краткую характеристику',
			objectCount: 'Введите количество объектов',
		},
		admin: {
			title: 'Администрирование',
			titleShort: 'Админ',
		},
		module: {
			fires: 'Лесные пожары',
			covid: 'COVID-19',
			appeals: 'Обращения граждан',
			errands: 'Поручения Главы РС(Я)',
			delivery: 'Северный завоз',
			emergency: 'Планирование по ЧС',
			techfires: 'Техногенные пожары',
		},

		day: {
			sunday: 'Воскресенье',
			monday: 'Понедельник',
			tuesday: 'Вторник',
			wednesday: 'Среда',
			thursday: 'Четверг',
			friday: 'Пятница',
			saturday: 'Суббота',
		},
		month: {
			january: 'Январь',
			february: 'Февраль',
			march: 'Март',
			april: 'Апрель',
			may: 'Май',
			june: 'Июнь',
			july: 'Июль',
			august: 'Август',
			september: 'Сентябрь',
			october: 'Октябрь',
			november: 'Ноябрь',
			december: 'Декабрь',
		},
		validation: {
			REQUIRED_FIELDS: 'Не заполнены обязательные поля',
			REQUIRED_CREWTYPE: "Не заполнено поле 'Тип команды'",
			REQUIRED_EXTINGUISHING_AGENTS_TYPE: "Не заполнено поле 'Средство тушения'",
			ALREADY_EXIST: 'Такая запись уже существует',
			INCORRECT_AIR: "Наверно заполнено поле 'Авиа'",
			INCORRECT_EVACUATED: "Наверно заполнено поле 'Вывезено'",
		},
		dictionaries: {
			title: 'Справочник',
			plural: 'Справочники',
			group: {
				title: 'Группа справочников',
				norm: 'Нормативно-справочная информация',
				fires: 'Справочники реестра пожаров',
				air: 'Справочники налета воздушных судов',
				weather: 'Справочники метеостанции',
				other: 'Прочие',
				region: 'Региональные справочники',
				layersData: 'Справочные данные слоев',
			},
		},
		code: 'Код',
		externalCode: 'Код во внешней системе',
		weatherScaleValue: {
			title: 'Значение метеошкалы',
			plural: 'Значения метеошкал',
			startDate: 'Дата начала',
			endDate: 'Дата окончания',
			KPO1: 'КПО1',
			KPO2: 'КПО2',
			KPO3: 'КПО3',
			KPO4: 'КПО4',
		},
		forestUseType: {
			title: 'Вид использования лесов',
			plural: 'Виды использования лесов',
		},
		staffPosition: {
			title: 'Должность',
			plural: 'Должности',
		},
		paymentGroup: {
			title: 'Группа оплаты',
			plural: 'Группы оплаты',
		},
		report: {
			downloadDate: 'Дата загрузки отчета',
			title: 'Отчет',
			plural: 'Отчеты',
			group: 'Группа отчетов',
			type: 'Вид отчета',
			download: 'Скачать отчет',
			downloadLO1: 'Скачать Форму 1-ЛО.Раздел 1',
			section: 'Раздел',
			date1: 'Дата (от)',
			date2: 'Дата (до)',
			inProgress: 'В разработке...',
			name: 'Название отчета',
			summary: 'Суммарный',
			detailed: 'Детальный',
			FiresOnCountyAndDate: 'Сведения о лесных пожарах по районам на дату',
			FireCard: 'Карточка лесного пожара',
			ExtinguishedFires: 'Ликвидированные пожары',
			FiresOnDate: 'Сведения о лесных пожарах на дату',
			CumulativeFires: 'Сведения о лесных пожарах нарастающим итогом',
			DetailedFireSituation: 'Подробная информация о лесопожарной ситуации и принимаемых мерах по тушению лесных пожаров',
			FireAct: 'Акт о пожаре',
			FireRegistryOnDate: 'Реестр лесных и ландшафтных (природных) пожаров на дату',
			FireByArea: 'Общая площадь и количество лесных пожаров',
			FireByMonitoringZone: 'Лесные пожары по зонам мониторинга',
			FireByCauseAndWorker: 'Сведения о причинах лесных пожарах, работающих людях и технике',
			LO1Section1: 'Форма 1-ЛО. Раздел 1. Сведения о пожарах в лесах',
			LO1Section2: 'Форма 1-ЛО. Раздел 2. Пожарная опасность и назначение режимов',
			LO1Section3: 'Форма 1-ЛО. Раздел 3. Показатели работы воздушных средств',
			LO1Section4: 'Форма 1-ЛО. Раздел 4. Наличие сил и средств пожаротушения',
			LO2: 'Форма 2-ЛО Противопожарные мероприятия и привлечение к ответственности за лесные пожары',
			LO3: 'Форма 3-ЛО Ежегодная форма сведений о работе в пожароопасном сезоне',
			DetectionMethods: 'Распределение пожаров по способам обнаружения и площади обнаружения',
			ExtinguishingCosts: 'Информация о затратах на тушение в разрезе каждого лесного пожара, а также о причиненном ими ущербе',
			ExtinguishingVehiclesAndPeoples: 'Привлекаемые силы и средства для тушения лесных пожаров',
			CityThreat: 'Угрозы лесных пожаров населенным пунктам',
			FireAreaCount: 'Сведения по количеству и площадям лесных пожаров',
			RequestLog: 'Журнал обращений граждан о нарушениях в лесах',
			ForestryHarm: 'Сведения о вреде, причиненном лесному хозяйству вследствие возникновения лесных пожаров ',
			PeoplesAndVehiclesByDate: 'Распределение показателей пожароопасного периода',
			AviaDepByCause: 'Сведения о причинах возникновения пожаров по авиаотделениям на дату',
		},
		forceAvailability: {
			title: 'Готовность сил и средств',
			plural: 'Готовность сил и средств',
			newDate: 'Дата новой записи',
			copyWarning: 'Копируются только сохраненные данные',
		},
		auth: {
			welcome: 'Добро пожаловать',
			title: 'Авторизация',
			login: 'Войти',
			order: 'Получить доступ',
			insertLogin: 'Введите логин',
			insertPassword: 'Введите пароль',
			register: 'Зарегистрироваться',
			logout: 'Выйти',
			forgotPassword: 'Забыли пароль?',
			recover: 'Восстановить',
			required: 'Необходимо заполнить поле',
			wait: 'Подождите',
			seconds: 'секунд',
			easy: 'Легко',
			fast: 'Быстро',
			safely: 'Надежно',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'Электронная почта',
			input: 'Введите E-mail',
		},
		password: {
			title: 'Пароль',
			hint: 'Пароль...',
			input: 'Введите пароль',
			new: 'Введите новый пароль',
			repeat: 'Повторите пароль',
			newHint: 'Оставьте поля пустыми, чтобы не менять пароль',
			empty: 'Введите пароль в оба поля',
			noMatch: 'Пароли не совпадают!',
			match: 'Пароли совпадают',
		},
		person: {
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			fullName: 'Ф.И.О.',
			status: 'Статус',
			section: 'Отдел',
			gasMask: 'Тип СИЗОД',
			phone: 'Телефон',
			address: 'Адрес',
			fio: 'Пользователь',
		},
		user: {
			title: 'Пользователь',
			plural: 'Пользователи',
			position: 'Должность',
			lastAuthDate: 'Дата последней авторизации',
			create: 'Новый пользователь',
			profile: 'Профиль',
			email: 'E-mail',
			emailVerified: 'E-mail подтвержден',
			name: 'Логин',
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			leftComment: 'оставил(а) комментарий',
			createdTask: 'создал задачу',
			telegram: 'Telegram ID',
			username: 'Логин',
			password: 'Пароль',
			avatar: 'Аватар',
			fullName: 'ФИО',
			organizationName: 'Организация',
			searchFioPositionOrganization: 'Поиск по ФИО, должности, организиации',
			error: {
				email: {
					custom: {
						email: 'Неверный e-mail',
					},
					uniqueness: 'Пользователь с этим e-mail уже существует',
					verifySent: 'На e-mail отправлено письмо для подтвержения',
				},
			},
		},
		role: {
			title: 'Роль',
			plural: 'Роли',
			create: 'Новая роль',
			created: 'Созданo',
			modified: 'Изменено',
			name: 'Код',
			description: 'Название',
			isSaving: 'Права сохраняются...',
		},
		acl: {
			title: 'Право',
			plural: 'Права',
			create: 'Новое право',
			principalType: 'Вид субъекта',
			app: 'Приложение',
			role: 'Роль',
			user: 'Пользователь',
			accessType: 'Действие',
			read: 'Чтение',
			write: 'Запись',
			execute: 'Выполнение',
			all: 'Все',
			principalId: 'Субъект',
			model: {
				title: 'Модель',
				user: 'Пользователи',
				project: 'Проекты',
				person: 'Персоны',
				description: 'Описание',
			},
			permission: 'Право',
			allow: 'Разрешить',
			deny: 'Запретить',
			property: 'Свойство',
			allowAll: 'Разрешить все',
			list: 'Список прав',
		},
		roleMapping: {
			title: 'Назначение роли',
			plural: 'Назначенния ролей',
			create: 'Новое назначение роли',
		},
		comments: {
			title: 'Комментарий',
			plural: 'Комментарии',
			placeholder: 'Оставьте комментарий',
			submit: 'Отправить',
		},
		label: {
			title: 'Метка',
			plural: 'Метки',
			create: 'Новая метка',
		},
		file: {
			title: 'Файл',
			plural: 'Файлы',
			uploadFile: 'Загрузить файл',
			uploadImage: 'Загрузить изображение',
			uploadStart: 'начинаю загрузку',
			uploading: 'загрузка файла...',
			uploadComplete: 'загрузка завершена',
			notSelected: 'файл не выбран',
			willBeUploaded: 'Будет загружен',
			wrongType: 'Неверный тип файла',
			uploadError: 'Ошибка при загрузке файла',
			fileTooBig: 'Файл слишком большой',
		},
		organization: {
			title: 'Организация',
			plural: 'Организации',
			name: 'Название организации',
			fullName: 'Полное название организации',
			shortName: 'Краткое наименование',
			ownershipForm: 'Форма собственности',
			address: 'Юридический адрес',
			headName: 'ФИО Руководителя',
			headPosition: 'Должность руководителя',
			inn: 'ИНН',
			ogrn: 'ОГРН',
			kpp: 'КПП',
			comment: 'Комментарий',
			externalId: 'ID организации во внешней системе',
			externalData: 'Содержимое организации из внешней системы',
			position: 'Должность',
			description: 'Информация об организации',
			info: 'Основные сведения',
			factAddress: 'Фактический адрес',
			secondaryInfo: 'Дополнительные сведения',
			headInfo: 'Данные о руководителе',
			okato: 'ОКАТО',
			oktmo: 'ОКТМО',
			okved: 'ОКВЭД',
			okpo: 'ОКПО',
			noStaff: 'Сотрудники не найдены',
			noRequests: 'Заявки не найдены',
			noBankAccounts: 'Банковские счета не найдены',
			contractor: 'Подрядчик',
		},
		organizationType: {
			title: 'Тип организации',
			plural: 'Типы организации',
		},
		appeal: {
			title: 'Обращение гражданина',
			plural: 'Обращения граждан',
			kanban: 'Реестр',
			date: 'Дата обращения',
			endDate: 'Отработано',
			status: 'Статус обращения',
			system: 'Система-источник',
		},
		appealStatus: {
			title: 'Состояние обращения',
			plural: 'Состояния обращений',
			default: 'По умолчанию',
			closing: 'Закрывающее',
		},
		appealSystem: {
			title: 'Внешняя система',
			plural: 'Внешние системы',
		},
		appealCategory: {
			title: 'Категория обращений',
			plural: 'Категории обращений',
		},
		period: {
			title: 'Период',
			start: 'Начало периода',
			end: 'Конец периода',
			select: 'Период',
			day: 'День',
			week: 'Неделя',
			month: 'Месяц',
			quarter: 'Квартал',
			year: 'Год',
			everyday: 'Каждый день',
			everyweek: 'Каждую неделю',
			everymonth: 'Каждый месяц',
			lastDayOfMonth: 'Последний день месяца',
			dayOfMonth: 'числа каждого месяца`',
		},
		stats: {
			title: 'Статистика',
			catAvg: 'Средняя продолжительность обработки обращений по категориям',
			orgAvg: 'Средняя продолжительность обработки обращений по организациям',
			orgCount: 'Количество обращений по организациям',
			sources: 'Обращения по источникам',
			heatmap: 'Тепловая карта',
		},
		audit: {
			title: 'Журнал',
			model: 'Модель',
			action: 'Действие',
			create: 'Создание',
			delete: 'Удаление',
			update: 'Обновление',
			select: 'Выберите запись',
			data: 'Данные записи',
			owner: 'Пользователь',
			hidden: '*скрыто*',
		},
		heatPoint: {
			title: 'Термоточка',
			plural: 'Термоточки',
			source: 'Спутник',
			period: 'Период',
			periodHint: 'Максимальный период ограничен одним годом. При его превышении даты будут скорректированы.',
			reload: 'Загрузить',
			brightness: 'Яркость (в кельвинах)',
			dif: 'dif',
			eurasia: 'Евразия',
			today: 'сегодня',
			yesterday: 'вчера',
			earlier: 'ранее',
			dayNight: 'Day/Night',
			frp: 'FRP',
			scan: 'Scan',
			track: 'Track',
			createFire: 'Добавить пожар',
		},
		adpiDeviceType: {
			title: 'Тип АДПИ',
			plural: 'Типы АДПИ',
		},
		pump: {
			title: 'Насос',
			plural: 'Насосы',
			capacity: 'Производительность',
		},
		foam: {
			title: 'Пена',
			plural: 'Пены',
		},
		techFireFoam: {
			title: 'Огнетушащее вещество',
			plural: 'Огнетушащие вещества',
		},
		cisternBulk: {
			title: 'Вместимость цистерн',
			plural: 'Вместимость цистерн',
			volume: 'Вместимость',
		},
		personnelStatus: {
			title: 'Статус личного состава',
			plural: 'Статусы личного состава',
		},
		personnel: {
			title: 'Личный состав',
			add: 'Добавить личный состав',
		},
		adpiDeviceStatus: {
			title: 'Статус АДПИ',
			plural: 'Статусы АДПИ',
		},
		adpiDeviceModel: {
			title: 'Модель АДПИ',
			plural: 'Модели АДПИ',
		},
		fireAlarmAdpiModel: {
			plural: 'Модели АДПИ',
		},
		fireAlarmOrganizationAdpiService: {
			plural: 'Организации, обсулижвающие АДПИ',
			title: 'Организации',
		},
		fireDepPowderCapacity: {
			plural: 'Порошок, объем',
			title: 'Порошок, объем',
			capacity: 'Объем',
		},
		fire: {
			popup: {
				mainInfo: 'Основные данные',
				vpp: 'Данные о ВПП',
				activity: 'Задействовано в тушении',
			},
			allotmentNumber: 'Выдел',
			id: '№ по реестру ОМ-Пожары',
			delete: 'Удалить пожар',
			fieldsSelected: 'Выбрано полей',
			fireNumber: '№ пожара',
			fireAct: 'Акт о пожаре',
			distances: 'Расстояния',
			numbers: 'Нумерация',
			meteo: 'Метеоинформация',
			extinguishingCosts: 'Затраты на тушение',
			forestFireAct: 'Акт о лесном пожаре',
			forestryDamage: 'Затраты и ущерб, причиненные лесному хозяйству',
			cityAndAirfield: 'Город и аэропорт',
			dynamicDate: 'Время',
			new: 'Новый пожар',
			name: 'Наименование',
			shortName: 'Сокращенное наименоваие',
			status: 'Состояние пожара',
			title: 'Лесной пожар',
			plural: 'Лесные пожары',
			short: 'Пожар',
			registry: {
				title: 'Реестр',
			},
			map: 'Карта',
			add: 'Добавить',
			addForm: 'Добавление пожара',
			mainInfo: 'Основные данные',
			resources: 'Силы и средства',
			secondaryInfo: 'Дополнительная информация',
			jumpings: 'Прыжки',
			descents: 'Спуски',
			delivered: 'Грузов доставлено, тонн',
			deliveredAvia: 'В том числе авиа, тонн',
			threat: 'Угроза населенному пункту',
			fireManager: 'Руководитель тушения пожара',
			number: '№',
			numberLong: 'Номер пожара',
			created: 'Создана карточка пожара',
			geo: 'Геометрия',
			coordinates: 'Координаты пожара',
			coordsAndDistances: 'Координаты и расстояния',
			cityAzimuth: 'Азимут от н/п',
			cityDistance: 'Удаление от н/п',
			airfieldAzimuth: 'Азимут от аэропорта',
			airfieldDistance: 'Расстояние до места высадки',
			aviaDepAzimuth: 'Азимут от а/о',
			aviaDepDistance: 'Удаление от а/о',
			transportRoutesDistance: 'Расстояние до транспортных путей',
			fromMap: 'Рисовать на карте',
			time: 'Время',
			detectDate: 'Обнаружение',
			reportDate: 'Сообщение',
			startDate: 'Начало тушения',
			lastEditDate: 'Последнее изменение',
			lastEditPerson: 'Кто внес изменения',
			extinguishedDate: 'Дата/время тушения',
			localizationDate: 'Дата/время локализации',
			dynamic: 'Динамика',
			createDynamic: 'Создание новой динамики',
			edge: 'Кромка, км',
			edgeShort: 'Кромка',
			protectiveArea: 'Защитная площадь, га',
			protectiveAreaShort: 'Защитная',
			operationalArea: 'Эксплуатационная площадь, га',
			operationalAreaShort: 'Эксплуатационная',
			reserveArea: 'Резервная площадь, га',
			reserveAreaShort: 'Резервная',
			soilArea: 'Почвенная площадь, га',
			soilAreaShort: 'Почвенная',
			upperArea: 'Верховая площадь, га',
			upperAreaShort: 'Верховая',
			lowerArea: 'Низовая площадь, га',
			lowerAreaShort: 'Низовая',
			forestArea: 'Лесная площадь, га',
			forestAreaShort: 'Лесная',
			nonForestArea: 'Нелесная площадь, га',
			nonForestAreaShort: 'Нелесная',
			coverArea: 'Покрытая площадь, га',
			coverAreaShort: 'Покрытая',
			nonCoverArea: 'Непокрытая площадь, га',
			nonCoverAreaShort: 'Непокрытая',
			activeBurningArea: 'Зона активного горения, га',
			activeBurningAreaShort: 'Активн. гор.',
			description: 'Примечания',
			startDrawing: 'Рисовать',
			finishDrawing: 'Закончить',
			showCounties: 'Показывать районы',
			showForestryQuarters: 'Показывать квартальную сеть',
			area: 'Площадь, га',
			areaKm: 'Площадь, кв.км',
			areaShort: 'Общая',
			areaTitle: 'Площадь пожара, га',
			leasedArea: 'Площадь на арендуемых лесных участках, га',
			geoChanged: 'Изменена геометрия пожара',
			forestOwnership: 'Принадлежность лесов',
			combustionIntensity: 'Интенсивность горения',
			combustionPattern: 'Характер лесных насаждения',
			combustionType: 'Вид пожара',
			combustionCharacteristic: 'Характеристика горения',
			decisionDetailsOpb: 'Реквизиты решения КЧС и ОПБ РС(Я)',
			date: 'Дата',
			protocolNumber: 'Номер протокола',
			forestryPrecinct: 'Участковое лесничество',
			cause: 'Причина пожара',
			zone: 'Зона мониторинга',
			forestryFireNumber: '№ пожара по лесничеству',
			aviaDepFireNumber: '№ пожара по авиаотделению',
			bigFireNumber: '№ крупного пожара',
			isdmFireNumber: '№ в ИСДМ',
			isdmSync: 'Синхронизация с ИСДМ',
			notLandingReason: 'Причина непринятия мер',
			notInspectionReason: 'Причина неосмотра',
			windSpeed: 'Скорость ветра',
			extinguishingCostsSubject: 'Субъекта',
			rentBC: 'В т.ч. аренда ВС',
			totalCost: 'Общий ущерб',
			actDate: 'Дата акта',
			actPercent: '% акта',
			compiler: 'Составитель',
			woodLoss: 'Потери древесины на корню',
			youngWoodLoss: 'Погибло молодняков',
			burnedWood: 'Сгорело и повреждено лесной продукции',
			burnedHarvestedWood: 'В т.ч. заготовленной древесины',
			KPOdetected: 'КПО обнаружения',
			KPOliquidation: 'КПО ликвидации',
			frontalPrecipitation: 'Фронтальные осадки',
			origin: 'Координаты и расстояния',
			pickPoint: 'Указать на карте',
			pickPointHint: 'Указать точку курсором на карте',
			lastDynamic: 'Дата/время последней динамики',
			updateMainInfo: 'Автозаполнение',
			updateMainInfoHint: 'Автоматически заполнить некоторые поля на основе точки возникновения или геометрии пожара',
		},
		dynamic: {
			popup: {
				confirm: 'Подтвердить',
				message: 'Внесенные в редактируемую динамику данные будут утеряны',
			},
		},
		notifyRecipient: {
			title: 'Получатель рассылки',
			plural: 'Получатели рассылки',
			group_filter: 'Фильтр получателей по группе',
			group: 'Группа получателей',
			name: 'Имя',
			email: 'Email',
		},
		notifyRecipientGroup: {
			title: 'Группа получателей рассылки',
			plural: 'Группы получателей рассылки',
			showAll: 'Показывать всех',
		},
		transportData: {
			title: 'Транспортный модуль',
			period: 'Период',
			schedule: 'Установленное расписание',
			report: 'Отчет',
			nextDate: 'Дата следующей отправки',
			disabled: 'Рассылка неактивна',
			active: 'Активность',
			minute: 'Мин',
			hour: 'Час',
			day: 'День месяца',
			dayOfWeek: 'День недели',
			subject: 'Заголовок письма',
			text: 'Текст письма',
		},
		notLandingReason: {
			title: 'Причина невысадки',
			plural: 'Причины невысадки',
		},
		notInspectionReason: {
			title: 'Причина неосмотра',
			plural: 'Причины	 неосмотра',
		},
		aviaDep: {
			title: 'Авиаотделение',
			plural: 'Авиаотделения',
			active: 'Активность',
			location: 'Расположение',
			area: 'Осматриваемая площадь',
		},
		forestry: {
			title: 'Лесничество',
			plural: 'Лесничества',
			forestOwnership: 'Принадлежность леса',
		},
		landCategory: {
			title: 'Категория земли',
			plural: 'Категории земель',
		},
		fireAnalyticalIndicator: {
			title: 'Аналитический показатель пожаров',
			plural: 'Аналитические показатели пожаров',
		},
		tenant: {
			title: 'Арендатор',
			plural: 'Арендаторы',
		},
		county: {
			title: 'Район',
			plural: 'Районы',
			name: 'Название',
			population: 'Население',
			oktmo: 'ОКТМО',
		},
		municipality: {
			attachExplanatoryNoteFiles: 'Прикрепить файлы пояснительной записки',
			explanatoryNote: 'Краткие выводы из оценки ситуации',
			title: 'Муниципальное образование',
			plural: 'Муниципальные образования',
			name: 'Наименование',
			citiesCount: 'Населеных пунктов',
			population: 'Население',
			area: 'Общая площадь, кв.м',
			status: 'Статус',
		},
		city: {
			title: 'Населенный пункт',
			plural: 'Населенные пункты',
			pluralShort: 'Нас. пункты',
			add: 'Добавить населенный пункт',
			okato: 'Код ОКАТО',
			fireDanger: 'Угроза населенному пункту',
			population: 'Население',
			area: 'Площадь, кв.м',
			participatingEnterprises: 'Перечень предприятий, принимающих участие в подготовке к ЧС',
			importantObjects: 'Перечень важных по ЧС объектов',
			county: 'Район',
			oktmo: 'Код ОКТМО',
			sufficiencyStatus: 'Статус достаточности',
			municipality: 'Муниципальное образование',
		},
		crewType: {
			title: 'Тип противопожарной команды',
			title_short: 'Тип команды',
			plural: 'Типы противопожарных команд',
			formations: 'Лесопожарные формирования',
		},
		extinguishingAgentsType: {
			title: 'Тип средства тушения',
			title_short: 'Средство тушения',
			plural: 'Типы средств тушения',
		},
		discoveryMessageType: {
			title: 'Тип входящего сообщения о нарушениях в лесах',
			plural: 'Типы входящих сообщений о нарушениях в лесах',
		},
		breed: {
			title: 'Преобладающая порода',
			plural: 'Преобладающие породы',
		},
		cover: {
			title: 'Преобладающий покров',
			plural: 'Преобладающие покровы',
		},
		forestCategory: {
			title: 'Категория леса',
			plural: 'Категории лесов',
		},
		forestOwnership: {
			title: 'Принадлежность леса',
			plural: 'Принадлежность лесов',
			short: 'Принадлежность',
		},
		fireCause: {
			title: 'Причина пожара',
			plural: 'Причины пожаров',
		},
		fireStatus: {
			title: 'Cостояние пожара',
			plural: 'Состояния пожаров',
			selected: 'Выбрано статусов',
			codes: {
				abandoned: 'Прекращено тушение по решению КЧС',
				extinguished: 'Ликвидирован',
				resumed: 'Возобновился',
				localized: 'Локализован',
				notspreading: 'Не распространяется',
				weakens: 'Ослабевает',
				continues: 'Продолжается',
				intensifies: 'Усиливается',
				active: 'Активен',
				zik: 'На землях иных категорий',
			},
		},
		determiningMethod: {
			title: 'Способ обнаружения пожара',
			plural: 'Способы обнаружения пожаров',
		},
		combustionType: {
			title: 'Вид пожара',
			plural: 'Виды пожара',
		},
		combustionIntensity: {
			title: 'Интенсивность горения',
			plural: 'Виды интенсивности горения',
		},
		combustionPattern: {
			title: 'Характер леса',
			plural: 'Виды характера леса',
		},
		airRaid: {
			title: 'Налёт',
			plural: 'Налёты',
			pluralLong: 'Воздушные налеты',
			add: 'Добавление налёт',
			requestNumber: 'Номер заявки',
			requestDate: 'Дата заявки',
			departureDate: 'Дата/время вылета',
			arrivalDate: 'Дата/время прилета',
			mainInfo: 'Основная информация',
			addContract: 'Добавить договор',
			noContracts: 'Нет активных договоров',
			startDate: 'Дата (с) ',
			endDate: 'Дата (по)',
			pickAirRaid: 'Выберите налет',
		},
		airJob: {
			title: 'Воздушная работа',
			plural: 'Воздушные работы',
			add: 'Добавить воздушную работу',
			duration: 'Время выполнения',
			duration_short: 'Время',
			drains: 'Сливы',
			effects: 'Воздействия',
			jumpings: 'Тренировочные прыжки',
			jumpings_short: 'прыжки',
			descents: 'Тренировочные спуски',
			descents_short: 'спуски',
		},
		airJobType: {
			title: 'Вид воздушной работы',
			plural: 'Виды воздушных работ',
		},
		staff: {
			title: 'Личный состав',
			plural: 'Личный состав',
			number: 'Номер в табеле',
			fullName: 'ФИО',
			category: 'Категория',
			tableNumber: 'Номер в табеле',
		},
		unit: {
			title: 'Единица измерения',
			plural: 'Единицы измерения',
			nationalSymbol: 'Условное обозначение (национальное)',
			internationalSymbol: 'Условное обозначение (международное)',
			nationalCode: 'Кодовое обозначение (национальное)',
			internationalCode: 'Кодовое обозначение (международное)',
		},
		contract: {
			title: 'Договор',
			plural: 'Договора',
			number: 'Номер договора',
			date: 'Дата договора',
			term: 'Срок действия',
		},
		contractor: {
			title: 'Подрядчик',
			plural: 'Подрядчики',
		},
		vehicleType: {
			title: 'Тип транспортного средства',
			plural: 'Типы транспортный средств',
		},
		vehicle: {
			// здесь много дублей из fireDepVehicle, в коде много использований "vehicle" вместо "fireDepVehicle"
			// это надо будет переделать
			title: 'Техника',
			add: 'Добавить технику',
			stateNumber: 'Гос. номер',
			passport: 'ПТС',
			capacity: 'Емкость, в кубометрах',
			pumpCapacity: 'Насос, литров/сек',
			foamCapacity: 'Пена, объем',
			glonass: 'Признак наличия системы ГЛОНАСС',
			year: 'Год выпуска',
			callSign: 'Позывной',
			actualFoamValue: 'Факт. объем пены',
			faxFoamValue: 'Факт. объем пены',
			description: 'Описание',
			actualFuelValue: 'Факт. объем топлива',
			endDate: 'Дата выхода',
			actualPowderCapacity: 'Факт. объем порошка',
		},
		fireDepVehicleStatus: {
			plural: 'Статусы техники',
			title: 'Статус техники',
		},
		fireDepVehicleFuel: {
			title: 'Объем топлива',
			fuelValue: 'Объем топлива',
			value: 'Объем',
			plural: 'Объемы топлива для техники',
		},
		foamCapacity: {
			plural: 'Объем пены',
			capacity: 'Объем',
			deletedAt: 'Удалено',
		},
		aircraftType: {
			title: 'Тип воздушного средства',
			plural: 'Типы воздушных средств',
			group: 'Группа',
			class: 'Класс',
			speed: 'Скорость',
			fuelConsumption: 'Расход топлива',
			fuelSupply: 'Запас топлива',
			runwayLength: 'Длина ВПП',
			aeronauticalSupply: 'Аэронавигационный запас',
		},
		weatherStation: {
			title: 'Метеостанция',
			plural: 'Метеостанции',
			latitude: 'Широта',
			longitude: 'Долгота',
			activity: 'Активность',
		},
		airfieldRunway: {
			title: 'ВПП аэродрома',
			plural: 'ВПП аэродрома',
			add: 'Добавить ВПП',
			size: 'Размеры',
			number: 'Номер',
			class: 'Класс',
			pcn: 'PCN',
			utpzCategory: 'Категория УТПЗ',
			icaoCategory: 'Категория ИКАО',
			coverage: 'Покрытие',
			magneticBearing: 'Магнитный курс посадки',
			lightingSystem: 'Светосигнальное оборудование',
		},
		airfield: {
			title: 'Аэродром',
			plural: 'Аэродромы',
			name: 'Наименование',
			add: 'Добавление аэродром',
			icaoCode: 'Код по ИКАО',
			icaoLandingCategory: 'Категория посадки по ИКАО',
			localTime: 'Местное время',
			latitude: 'Контрольная точка: широта',
			longitude: 'Контрольная точка: долгота',
			exceeding: 'Превышение аэродрома',
			magneticDeclination: 'Магнитное склонение',
			iataCode: 'Код аэропорта ИАТА',
		},
		fireFightingVehicle: {
			title: 'Противопожарное средство',
			plural: 'Противопожарные средства',
			plan: 'План',
			required: 'Требуется',
			current: 'Задейстовано сейчас',
			current_short: 'Задейстовано',
			date: 'Дата/время',
		},
		fireFightingPeople: {
			title: 'Противопожарная сила',
			plural: 'Противопожарные силы',
			plan: 'План',
			required: 'Требуется',
			current_short: 'Работает',
			evacuated_short: 'Вывезено',
			delivered: 'Доставлено',
			air_short: 'Авиа',
		},
		fireFightingFormation: {
			title: 'Лесопожарное формирование',
			plural: 'Лесопожарные формирования',
			title_short: 'Формирование',
		},
		FireCharacteristics: {
			location: 'Характеристики местности и пожара',
		},
		observerPilot: {
			title: 'Летчик-наблюдатель',
			plural: 'Летчики-наблюдатели',
			inspector: 'Проверяющий',
			intern: 'Стажёр',
			add: 'Добавить летчика-наблюдателя',
		},
		aircraftKind: {
			title: 'Вид летательного аппарата',
			title_short: 'Вид ЛА',
			plural: 'Виды летательных аппаратов',
		},
		silam: {
			title: 'Загрязнение воздуха',
			on: 'Показывать',
		},
		monitoringZone: {
			title: 'Зона мониторинга',
			plural: 'Зоны мониторинга',
			bigFireLimit: 'Лимит крупного пожара, га',
		},
		emergencySituation: {
			title: 'Режим ЧС и ОПР',
			plural: 'Режимы ЧС и ОПР',
			startAct: '№ акта введения',
			endAct: '№ акта снятия',
			republicStatus: 'На всей территории республики',
			comment: 'Примечание',
			startDate: 'Дата введения',
			endDate: 'Дата снятия',
			type: 'Тип',
			counties: 'Районы',
		},
		emergencySituationDamage: {
			title: 'Ущерб от режима ЧС',
			title_short: 'Ущерб',
			plural: 'Ущерб от режима ЧС',
			dead: 'Погибших',
			injured: 'Пострадавших',
			affectedObjects: 'Поcтрадавших объектов',
			comment: 'Примечание',
		},
		emergencySituationType: {
			title: 'Тип чрезвычайной ситуации',
			plural: 'Типы чрезвычайных ситуаций',
		},
		requestLog: {
			title: 'Журнал обращений',
			plural: 'Журнал обращений',
			number: 'Номер',
			date: 'Дата',
			location: 'Местоположение',
			description: 'Описание',
			measures: 'Принятые меры',
			result: 'Результат',
			contactInfo: 'Контактная информация',
			messageType: 'Тип сообщения',
			operator: 'Оператор',
			processed: 'Обработано',
		},
		fireReport: {
			title: 'Пожарный отчет',
			plural: 'Пожарные отчеты',
			coordinats: 'Координаты пожара',
			tenant: 'Арендатор',
			localization: 'Дата локализации/ликвидации пожара',
			peopleTypes: 'Дополнительные типы людей',
			stayed: 'Люди на конец дня',
			year: 'Год',
			month: 'Месяц',
			groupBy: 'Группировка',
			nonForestArea: 'Нелесная площадь',
			noAviaDep: 'Без авиаотделения',
			noCounty: 'Без района',
			noForestry: 'Без лесничества',
			header: 'Шапка',
			sourceFirefightingDrain: 'Ближайший источник наружного противопожарного водоснабжения',
			fireInfo: 'Сведения о пожаре',
			stewCount: 'К тушению пожара привлекалось',
			securityData: 'Сведения о проведении мероприятий по обеспечению пожарной безопасности объекта',
			postSign: 'Должность (Подпись)',
			sign: 'Подпись',
		},
		forestryQuarter: {
			title: 'Квартальная сеть',
			short: 'Квартал',
			plural: 'Квартальная сеть',
			area: 'Площадь кв.м.',
			add: 'Добавить квартал',
			name: 'Наименование квартала',
			code: 'Код квартала',
			forestry: 'Лесничество',
			forestryPrecinct: 'Участковое лесничество',
			forestCategory: 'Категория лесов',
			aviaDep: 'Авиаотделение',
			monitoringZone: 'Зона мониторинга',
		},
		forestryPrecinct: {
			title: 'Участковое лесничество',
			plural: 'Участковые лесничества',
			short: 'Уч.лесничество',
		},
		aircraft: {
			title: 'Воздушное судно',
			plural: 'Воздушные суда',
			number: 'Бортовой номер',
			number_short: 'б/н',
		},
		weather: {
			title: 'Погода',
			temp: 'Температура',
			tempDescription: 'Температура на высоте 2 м над землей',
			tempMeasure: '°C',
			pressure: 'Давление',
			pressureDescription: 'Атмосферное давление',
			pressureMeasure: 'мм рт.ст.',
			precipitation: 'Осадки',
			precipitationDescription: 'Накопление осадков за 3 часа',
			precipitationMeasure: 'кг/м²',
			humidity: 'Влажность',
			humidityDescription: 'Относительная влажность на высоте 2 м над землей',
			humidityMeasure: '%',
			pm25: 'PM2.5',
			pm25Description: 'Взвешенные твердые микрочастицы и мельчайшие капельки жидкости диаметром 2.5 мкм и менее',
			pm25Measure: 'мкг/м³',
			pm10: 'PM10',
			pm10Description: 'Взвешенные твердые микрочастицы и мельчайшие капельки жидкости диаметром 10 мкм и менее',
			pm10Measure: 'мкг/м³',
			co: 'CO',
			coDescription: 'Описание слоя CO',
			coMeasure: 'мкг/м³',
			no: 'NO',
			noDescription: 'Описание слоя NO',
			noMeasure: 'мкг/м³',
			no2: 'NO2',
			no2Description: 'Описание слоя NO2',
			no2Measure: 'мкг/м³',
			ozone: 'Озон',
			ozoneDescription: 'Описание слоя O3',
			ozoneMeasure: 'DU',
			so2: 'SO2',
			so2Description: 'Описание слоя SO2',
			so2Measure: 'кг/м²',
			feeling: 'Ощущается как',
		},
		firepopup: {
			fire: 'Пожар',
			point: 'Термоточка',
			info: 'Информация',
			weather: 'Погода',
		},
		wind: {
			title: 'Ветер',
			speedFactor: 'Скорость',
			fadeOpacity: 'Длина шлейфа',
			particleLife: 'Жизнь частицы',
			opacity: 'Прозрачность',
		},
		notifyStatus: {
			title: 'Статус сообщения',
			plural: 'Статусы сообщений',
			status: 'Статус',
		},
		notifyType: {
			title: 'Тип сообщения',
			plural: 'Типы сообщений',
			type: 'Тип',
			default: 'По умолчанию',
			select: 'Выберите тип сообщения',
			active: 'Активное API',
		},
		notifyTemplate: {
			title: 'Шаблон сообщения',
			plural: 'Шаблоны сообщений',
			template: 'Шаблон',
			noTemplates: 'Шаблоны не добавлены',
		},
		notifyLog: {
			title: 'Cообщение',
			plural: 'Сообщения',
			text: 'Текст сообщения',
			phone: 'Телефон получателя',
			recipient: 'Получатель',
			date: 'Дата отправления',
			add: 'Новая рассылка',
			pickTemplate: 'Выбрать шаблон',
			mailing: 'Информирование',
			recipientsCount: 'Количество получателей',
			notSent: 'Не отправлялось',
			noApi: 'API не подключено',
			accepted: 'Подтверждено',
			notAccepted: 'Не подтверждено',
			acceptedPlaceholder: 'Статус подверждения',
		},
		notify: {
			massNotify: 'Для подтверждения массовой рассылки требуется ввести код в конце формы',
			code: 'Код подтверждения',
		},
		case: {
			mainDate: 'Основные данные',
			messages: 'Сообщения',
			sendMessage: 'Отправить сообщение',
			notifyFilter: 'Фильтры по сообщениям',
			noCounty: 'Район не определен',
		},
		camera: {
			title: 'Камера',
			plural: 'Камеры',
			error: 'Ошибка подключения',
			maximize: 'развернуть',
			minimize: 'свернуть',
		},
		dzz: {
			title: 'ДЗЗ',
		},
		genShtab: {
			title: 'Генштаб СССР',
		},
		fireDashboard: {
			title: 'Сводка',
			total: 'Всего',
			active: 'Действует пожаров',
			fires: 'Пожаров',
			dynamics: 'Динамика ситуации',
			cityDanger: 'Угроза населенным пунктам',
			area: 'Площадь, Га',
			totalArea: 'Общая пл., Га',
			dailyIncrease: 'Прирост в сутки, Га',
			extinguished: 'Ликвидировано',
			firesCount: 'Количество пожаров',
			fullReport: 'Полная сводка',
			operationalReport: 'Оперативная сводка',
			sectionDescription: 'По ситуации с ландшафтными пожарами на территории Республики Саха (Якутия)',
			underway: 'Ведутся работы',
			suspended: 'Приост. Тушен.',
			unattended: 'Не обслуж.',
			activeArea: 'Действующих пожаров, Га',
			extinguishedArea: 'Ликвидировано пожаров, Га',
			noDanger: 'Угроз населенным пунктам нет',
			dailyExtinguished: 'Ликвидировано пожаров за сутки',
			dailyDetected: 'Обнаружено пожаров за сутки',
			dailyLocalized: 'Локализовано пожаров за сутки',
			dailyUnderway: 'Задействовано в работе за сутки',
			naturalGrouth: 'Естественный прирост пожаров',
			naturalGrouthShort: 'Ест. пр. пож.',
			personnel: 'Людей',
			vehicles: 'Тех. средств',
			seasonFires: 'Зарегистрировано пожаров с начала сезона',
			currentYear: 'Текущий год',
			lastYear: 'Аналогичный период прошлого года',
			seasonTotalCount: 'Всего, пожаров',
			seasonTotalArea: 'Общая площадь, Га',
			count: 'Кол-во',
			citiesCount: 'Населенных пунктов, шт',
			distance: 'Расст., км',
			citiesSpecial: 'На особом контроле',
			citiesUnderway: 'Ведутся работы',
			citiesUnattended: 'В пред. 10 км зоны',
			citiesSpecialTest: 'На особом контроле (20 км)',
			citiesUnderwayTest: 'Ведутся работы (50 км)',
			citiesUnattendedTest: 'В пред. 50 км зоны',
			dynamicsType: {
				forest: 'Динамика ситуации на территории лесного фонда (лесные пожары)',
				oopt: 'Динамика ситуации на особо охраняемых природных территориях',
				other: 'Динамика ситуации на иных категориях земель',
			},
		},
		address: {
			title: 'Адрес',
		},
		errand: {
			dashboard: 'Статистика исполнения поручений Главы РС(Я)',
			freeMode: 'Произвольный период',
			week: 'неделя',
			month: 'месяц',
			halfYear: 'полгода',
			year: 'год',
			org: 'Исполнитель',
		},
		monMap: {
			hpSelectModeDisabled: 'Включите слой термоточек',
			hpSelectAdd: 'Режим выделения: добавить термоточки',
			hpSelectSubtract: 'Режим выделения: удалить термоточки',
			hpSelectRadius: 'Радиус вокруг точки',
			metersShort: 'м',
			kmShort: 'км',
			searchHint: 'Поиск по широте/долготе',
			gridHint: 'Градусная сетка',
			export: 'Экспорт',
			exportHint: 'Экспортировать в PNG формате',
			exportFormat: 'Формат',
			exportResolution: 'Разрешение',
		},
		technoZone: {
			title: 'Техногенная зона',
			plural: 'Техногенные зоны',
		},
		isdm: {
			title: 'ИСДМ',
			titleLong: 'Информационная система дистанционного мониторинга',
			METEO: 'Метеоинформация 1-МЕТЕО',
			ISDM1T: '1-Т ИСДМ',
			ISDM1: '1-ИСДМ',
			ISDM8: '8-ИСДМ',
			ISDM9: '9-ИСДМ',
		},
		isdmFire: {
			title: 'ИСДМ',
			plural: 'Информационная система дистанционного мониторинга',
			fireNumber: 'Номер пожара',
			externalId: 'Внешний номер',
			reportDate: 'Дата первого наблюдения',
			processed: 'Обработано',
		},
		nasleg: {
			title: 'Наслег',
			plural: 'Наслеги',
		},
		extinguishingAgentsGroup: {
			title: 'Группа средств тушения',
			plural: 'Группы средств тушения',
		},
		delivery: {
			title: 'Северный завоз',
			map: 'Карта',
		},
		reserve: {
			title: 'Запас',
			plural: 'Запасы',
			nomenclature: 'Номенклатура',
			title_full: 'Контроль запасов',
			measure: 'Единица измерения',
			storage: 'Хранилище',
			quantity: 'Количество запаса',
			capacity: 'Вместимость склада',
			available: 'Свободно',
			nomenclatureCard: 'Карточка номенклатуры',
			createNomenclature: 'Создать номенклатуру',
			delete: 'Удалить номенклатуру',
			mainInfo: 'Общая информация',
			movement: 'Движение',
			newNomenclature: 'Новая номенклатура',
			date: 'Дата добавления',
			startQuantity: 'Начальный остаток',
			income: 'Приход',
			cost: 'Расход',
			movementType: 'Тип движения',
		},
		reserveMovement: {
			title: 'Движение номенклатуры',
			plural: 'Движение номенклатуры',
		},
		contragent: {
			title: 'Контрагент',
			plural: 'Контрагенты',
			delete: 'Удалить контрагента',
			create: 'Добавить контрагента',
			mainInfo: 'Общая информация',
			card: 'Карточка контрагента',
			staff: 'Сотрудники',
			bankAccounts: 'Банковские счета',
			requests: 'Заявки',
			agreements: 'Реестр соглашений',
		},
		nomenclature: {
			title: 'Номенклатура',
		},
		plan: {
			title: 'План',
			plural: 'План',
		},
		request: {
			messageTitle: 'Опишите запрос',
			sendMessageToOwner: 'Запрос',
			agreementRole: 'Роль в согласовании',
			title: 'Заявка',
			plural: 'Заявки',
			number: 'Номер заявки',
			type: 'Тип заявки',
			noStatuses: 'Не заданы состояния заявок',
			author: 'Автор',
			members: 'Участники',
			agreementMembers: 'Участники согласовования',
			customer: 'Заказчик',
			contractor: 'Предприятие',
			river: 'Река',
			create: 'Создать заявку',
			collective: 'Коллективная заявка',
			member: 'Участник',
			selectMember: 'Выберите участника',
			addMember: 'Добавить участника',
			agreement: 'Согласование',
			mainInfo: 'Основная информация',
			mainInfo_full: 'Основная информация о заявке',
			customerInfo: 'Информация о заказчике',
			requestLog: 'Журнал событий',
			discussion: 'Обсуждение',
			date: 'Дата создания заявки',
			noOwnerOrganization: 'У создателя заявки нет организации',
			departureCity: 'Направление из',
			destinationCity: 'Направление до',
			deliveryDate: 'Плановая дата поставки',
			trackNumber: '№ Трека',
			delete: 'Удалить заявку',
			positionsCount: 'Позиций',
			changed: 'Данные заявки были изменены',
			addPlan: 'Добавить план',
			shipment: 'Рейсов',
			status: 'Статус заявки',
			levels: 'Уровни заявки',
			BASIC: 'Заявки',
			SCHEDULE: 'План',
		},
		ladingBill: {
			short: 'Накладная',
			title: 'Товарно-транспортная накладная',
			plural: 'Товарно-транспортные накладные',
			add: 'Добавить накладную',
			delete: 'Удалить накладную',
			sender: 'Отправитель',
			payer: 'Плательщик',
			consignee: 'Грузополучатель',
			totalItems: 'Всего наименований',
			totalReleased: 'Всего отпущено',
			netWeight: 'Масса груза(нетто)',
			grossWeight: 'Масса груза(брутто)',
			number: 'Номер документа',
			date: 'Дата документа',
		},
		supply: {
			packageType: 'Тип упаковки',
			placeQuantity: 'Кол-во мест',
			nomenclatureCode: 'Номенклатурный код',
		},
		requestTrack: {
			title: 'Местоположение груза',
			status: 'Статус груза',
		},
		history: {
			planApproved: 'Добавлен план ЧС, статус плана изменен',
			comments: 'Комментарии',
			leftComment: 'оставил(а) комментарий',
			leftCommentDiscussion: 'оставил(а) комментарий в обсуждении',
			updated: 'обновил(а)',
			deleteFile: 'удалил(а) файл',
			updateMemberStatus: 'изменил(а) статус согласования заявки на',
			updateMemberStatus_short: 'изменил(а) статус согласования заявки',
			to: 'на',
			requestCreate: 'создал(а) заявку',
			requestUpdate: 'изменил(а) поля заявки',
			addMember: 'добавил(а) участника',
			addLadingBill: 'добавил(а) накладную',
			addTrack: 'добавил(а) местоположение груза',
			removeMember: 'удалил(а) участника',
			removeLadingBill: 'удалил(а) накладную',
			removeTrack: 'удалил(а) местоположение груза',
			requestStatus: 'изменил(а) статус заявки',
			changeTrackStatus: 'Статус груза изменен на',
			allAgreed: 'все участники согласовали заявку, статус заявки изменен',
			notAllAgreed: 'не все участники согласовали заявку, статус заявки изменен',
			addExplanatoryNoteFile: 'добавил файл пояснительной записки',
			removeExplanatoryNoteFile: 'удалил файл пояснительной записки',
			addPlanFile: 'добавил файл плана ЧС',
			removePlanFile: 'удалил файл плана ЧС',
			addCorrectionPlanFile: 'добавил файл корректированного плана ЧС',
			removeCorrectionPlanFile: 'удалил файл корректированного плана ЧС',
		},
		emergency: {
			title: 'Планирование по ЧС',
			map: 'Карта',
			registry: 'Реестр МО',
		},
		municipalSufficiencyStatus: {
			title: 'Статус достаточности',
			plural: 'Статусы достаточности',
		},
		municipalReadinessStatus: {
			title: 'Статус готовности',
			plural: 'Статусы готовности',
		},
		municipalFormation: {
			risks: 'Риски / людей / детей',
			citiesCount: 'Городов',
			peopleCount: 'Людей',
			teenagersCount: 'Детей',
			plural: 'Муниципальные образования',
			county: 'Район, городской округ',
			nasleg: 'Наслег',
			name: 'Наименование МО',
			city: 'Населенный пункт',
			cities: 'Населенных пунктов',
			population: 'Население, чел.',
			foam: 'Пена',
			gasi: 'ГАСИ',
			protectiveSuit: 'Защитные костюмы',
			fireRanks: 'Ранги пожара',
			positions: 'Должности',
			ranks: 'Звания',
			typeVehicle: 'Виды техники',
			departments: 'Отделы',
			area: 'Площадь, кв.м',
			sufficiencyStatus: 'Статус достаточности',
			sufficiencyStatusFull: 'Состав сил и средств МО',
			readinessStatus: 'Статус готовности',
			readinessStatusFull: 'Статус готовности плана по предупреждению и ликвидации ЧС',
			number: 'Номер',
		},
		bankAccount: {
			title: 'Банковский счет',
			plural: 'Банковские счета',
			bank: 'Банк',
			bic: 'БИК',
			swift: 'СВИФТ',
			correspondentAccount: 'Расчетный счет',
		},
		mineral: {
			plural: 'Полезные ископаемые',
			mining: 'Добыча полезных ископаемых',
			abc1: 'ABC1',
			c2: 'C2',
			zabC: 'Заб.С',
			PI: 'ПИ',
			PIType: 'Вид ПИ',
			units: 'Ед.изм.',
		},
		ship: {
			title: 'Судно',
			plural: 'Водный транспорт',
		},
		storehouse: {
			title: 'Склад',
			plural: 'Склады',
			measure: 'Единица измерения',
			capacity: 'Вместимость',
			address: 'Юридический адрес',
			area: 'Площадь, м2',
			wearout: 'Износ, %',
			county: 'Район, городской округ',
		},
		requestMemberStatus: {
			title: 'Статус участника заявки',
			plural: 'Статусы участника заявки',
		},
		requestStatus: {
			title: 'Статус заявки',
			plural: 'Статусы заявки',
		},
		requestType: {
			title: 'Тип заявки',
			plural: 'Типы заявки',
		},
		requestTrackStatus: {
			title: 'Статус трека заявки',
			plural: 'Статусы трека заявки',
		},
		supplyCategory: {
			title: 'Категория товара',
			plural: 'Категории товара',
			parent: 'Родительская категория',
		},
		supplyNomenclature: {
			title: 'Номенклатура товара',
			plural: 'Номенклатуры товаров',
		},
		supplyMeasure: {
			title: 'Единица измерения товара',
			plural: 'Единицы измерения товара',
		},
		techFire: {
			rescuedPeople: 'Людей',
			firereport: 'Донесение о пожаре',
			additionalTech: 'А/техника',
			specialVehicle: 'Спец.техника',
			ond: 'ОНД',
			reportTimeEmergency: 'Дата/время поступления сообщения на 112',
			phoneOperator: 'Номер оператора 112',
			evacuated: 'Эвакуировано',
			addition: 'Дополнение',
			terminationFireTime: 'Время ликвидации',
			situationToFirstDivison: 'Обстановка на момент прибытия первого пожарного подразделения',
			firstSupplyTime: 'Время подачи первого ствола',
			rank: 'Ранг пожара',
			messageFireDep: 'Дата/время сообщения в ПО',
			status: 'статус ПСЧ для 112',
			title: 'Техногенный пожар',
			plural: 'Техногенные пожары',
			create: 'Новый пожар',
			mainInfo: 'Общая информация',
			afterMath: 'Последствия',
			date: 'Дата пожара',
			detectTime: 'Дата/время обнаружения',
			destroyedBuildings: 'Уничтожено зданий',
			message: 'Сообщения в пожарную охрану',
			person: 'Заявитель',
			personName: 'Ф.И.О. заявителя',
			phone: 'Телефон',
			address: 'Адрес',
			fireAddress: 'Адрес пожара',
			distance: 'Расстояние до ПЧ',
			time: 'Время прибытия',
			situation: 'Обстановка',
			timeServing: 'Время подачи',
			firstUnit: 'Прибытие первого подразделения',
			effects: 'Последствия пожара',
			area: 'Площадь (кв.м.)',
			destroyVeh: 'Уничтожено техники',
			destroyAnimals: 'Скота и птицы',
			infoAffected: 'Сведения о погибших и пострадавших',
			resultInquiry: 'Результаты дознания',
			causes: 'Причина',
			culprit: 'Виновник',
			damage: 'Ущерб',
			workFireDep: 'Работа пожарных подразделений',
			vehiclesCount: 'Задействовано техники',
			rescueTeamsCount: 'Звеньев ГДЗС',
			barrelsCount: 'Кол-во стволов',
			waterSupplyMounts: 'Установка на водоисточники',
			dutyInspector: 'Деж. инспектор',
			organizations: 'Службы взаимодействия',
			rescued: 'Спасено',
			savedPeople: 'Людей (всего)',
			savedAdult: 'из них Взрослых (кол-во)',
			savedChildren: 'из них Детей (кол-во)',
			savedMaterials: 'Материалов',
			savedValues: 'Ценностей (руб.)',
			savedBuildings: 'Строений',
			savedVehicles: 'Техники (ед.)',
			savedLivestock: 'Скота и птиц (голов)',
			fireDep: 'Ведомственная принадлежность',
			localizationTime: 'Время локализации',
			terminationTime: 'Время ликвидации открытого горения',
			schedule: 'Расписание выездов',
			map: {
				title: 'Карта',
			},
			fires: {
				title: 'Учет пожаров',
			},
			notifier: {
				title: 'Пожарный извещатель',
				fireObject: 'Объект который горит',
				source: 'Источник',
				messageDate: 'Время сообщения',
				operatorNumber: 'Номер оператора',
				incidentCode: 'Код проишествия',
				responseTime: 'Время обработки вызова',
			},
			weather: {
				title: 'Метеосводка',
			},
		},
		techFireAddress: {
			title: 'Объект, здание',
			address: 'Адрес',
			registry: 'Адреса, объекты',
			name: 'Наименование объекта',
			rank: 'Ранг пожароопасности',
			allRanks: 'Все ранги',
			fireDep: 'Ведомственная принадлежность',
			description: 'Краткая характеристика объекта',
			info: 'Объект',
			objects: 'Объекты',
			pickPoint: 'Выбор точки на карте',
			noObjects: 'Нет объектов',
			newObject: 'Новый объект',
			select: 'Выбрать этот объект',
			noValue: 'Объект не выбран!',
			searchOtherAddress: 'Искать другой адрес',
			ptp: 'ПТП',
			ktp: 'КТП',
			selectRank: 'Выберите ранг пожароопасности',
			selectedRanks: 'Выбрано рангов',
			information: 'Информация',
		},
		techFireRank: {
			title: 'Ранг пожароопасности объекта',
			plural: 'Ранги пожароопасности объектов',
		},
		techFireCasualty: {
			deceased: 'Погибший',
			furtherAction: 'Дальнейшие действия',
			detectTime: 'Время обнаружения',
			lastName: 'Фамилия',
			birth: 'Год рождения',
			socialStatus: 'Социальное положение',
			add: 'Добавить пострадавшего',
			firstName: 'Имя',
			middleName: 'Отчество',
		},
		techFireChief: {
			add: 'Добавить РТП',
			text: 'Текст',
		},
		fireAlarm: {
			title: 'АДПИ',
			phoneNumber: 'Номер телефона',
			info: 'Дополнительная информация',
			status: {
				title: 'Состояние АДПИ',
			},
			location: {
				title: 'Тип объекта где установлен',
			},
			type: {
				title: 'Тип АДПИ',
			},
			source: {
				title: 'Источник сигнализации',
			},
		},
		analytics: {
			title: 'Аналитика',
		},
		tracking: {
			title: 'Трекинг грузов',
			titleShort: 'Трекинг',
			create: 'Создать груз',
			nameList: '№ Накладной',
			nameSingle: 'Номер документа',
			documentDate: 'Дата документа',
			planedDeliveryDate: 'Плановая дата поставки',
			actualDeliveryDate: 'Фактическая дата поставки',
			shipmentDate: 'Дата отгрузки',
			organizationFrom: 'Грузоотправитель',
			organizationTo: 'Грузополучатель',
			payer: 'Плательщик',
			cityFrom: 'Направление из',
			cityTo: 'Направление до',
			inn: 'ИНН',
			nomenclature: 'Номенклатура',
			category: 'Категория',
			cargoStatus: 'Статус груза',
			direction: 'Направление',
			menu: {
				mainInfo: 'Общая информация',
				cargoTracking: 'Отслеживание груза',
			},
		},
		trackingProduction: {
			name: 'Наименование продукции, товара, груза, ТУ, марка, размер, сорт',
			code: 'Код продукции (номенклатурный номер)',
			productionCount: 'Количество',
			price: 'Цена, руб.коп',
			unit: 'Единица измерения',
			containerType: 'Вид упаковки',
			seatsNumber: 'Кол-во мест',
			totalProduction: 'Всего наименований',
			totalNetto: 'Масса груза (нетто)',
			totalBrutto: 'Масса груза (брутто)',
			totalCost: 'Всего отпущено',
			status: 'Статус груза',
			update: 'Обновления',
			transporter: 'Грузоперевозчик',
		},
		regionLevel: {
			title: 'Уровень',
			plural: 'Уровни',
			select: 'Выберите уровень',
		},
		emergencySituationPlan: {
			title: 'План ЧС',
			plural: 'Планы ЧС',
			agreement: 'Согласование',
			delete: 'Удалить план ЧС',
			create: 'Создать план ЧС',
			levels: 'Уровни плана ЧС',
			changed: 'Данные плана ЧС были изменены',
			noStatus: 'Статус не назначен',
			dataOnMap: 'Показать данные на карте',
			legend: 'Условные обозначения',
			attachPlanFiles: 'Прикрепить план',
			attachCorrectionPlanFiles: 'Прикрепить корректированный план',
			name: 'Наименование плана ',
		},
		analytic: {
			title: 'Оперативная информация',
			plural: 'Оперативная информация',
			productName: 'Название груза',
			date: 'Дата статистики',
			settlement: 'Населенные пункты',
			organization: 'Контрагенты',
			mainInfo: 'Основная информация',
			plan: 'План',
			ship: 'Отгружено',
			deliver: 'Доставлено',
			contragent: 'Контрагент',
			tab1: 'Страница 1',
			tab2: 'Страница 2',
			winter: 'Зима',
			summer: 'Лето',
		},
		fireDep: {
			code: 'Код',
			chief: 'РТП',
			dutyInspector: 'Деж. инспектор',
			distance: 'Расстояние',
			title: 'Пожарная часть',
			plural: 'Пожарные части',
			responsibilityArea: 'Район выезда',
			mainInfo: 'Информация',
			personnel: 'Личный состав',
			vehicles: 'Техника',
			edit: 'Редактировать',
			phone: 'Номер телефона',
			address: 'Адрес',
			staffUnits: 'Штатные ед.',
			select: 'Выберите пожарную часть',
			addPhone: 'Добавить номер телефона',
			enterPhone: 'Введите номер телефона',
			enterStaffUnits: 'Введите штатные единицы',
			addStaffUnits: 'Добавить штатные единицы',
			callSigns: 'Позывные',
			warehouse: 'Склад',
			sentries: 'Караулы',
			officerUnits: 'Штатные ед. офицерского состава',
			addOfficerUnits: 'Добавить еденицы офицерского состава',
			enterOfficerUnits: 'Введите количество',
			distanceProperty: 'Еденицы измерения',
			sbis: '1 СБИС',
			firstRank: '1 ранг',
			secondRank: '2 ранг',
			thirdRank: '3 ранг',
		},
		fireDepFoam: {
			title: 'Огнетушащие вещества',
			capacity: 'Объем',
			foam: 'Вид огнетушащего вещества',
		},
		fireDepGasMask: {
			title: 'СИЗОД',
			gasMask: 'Тип СИЗОД',
		},
		gasMask: {
			title: 'Тип СИЗОД',
			plural: 'Типы СИЗОД',
		},
		fireDepPersonnel: {
			user: 'Пользователь',
			address: 'Адрес',
			gasMask: 'Тип СИЗОД',
			headOrg: 'Организация',
			vehicle: 'Пожарная техника',
			basic: 'Основное',
			fireDep: 'Пожарная часть',
			plural: 'Личный состав',
			title: 'Личный состав',
			add: 'Добавить',
			choseName: 'Выберите ФИО',
			status: 'Статус',
			dayShift: 'Дневная смена',
		},
		fireDepSection: {
			title: 'Отдел',
			plural: 'Отделы',
		},
		fireDepPosition: {
			title: 'Должность',
			plural: 'Должности',
			choosePosition: 'Выберите должность',
		},
		fireDepRank: {
			title: 'Звание',
			plural: 'Звания',
			chooseRank: 'Выберите звание',
			officers: 'Офицерский состав',
		},
		fireDepVehicle: {
			fuelValue: 'Объем топлива',
			actualFuelValue: 'Факт. объем топлива',
			endDate: 'Дата выхода',
			foamCapacity: 'Пена, объем',
			actualFoamValue: 'Факт.объем пены',
			feature: 'Характеристики',
			additional: 'Доп. информация',
			contacts: 'Контакты',
			fireDep: 'Пожарная часть',
			callSign: 'Позывной',
			passport: 'ПТС',
			pump: 'Насос',
			cisternBulk: 'Емкость, в кубометрах',
			stateNumber: 'Гос. номер',
			plural: 'Техника',
			title: 'Пожарная техника',
			brand: 'Марка',
			type: 'Тип',
			year: 'Год выпуска',
			foam: 'Пена, фактический объем',
			name: 'ГАСИ',
			chooseType: 'Выберите год выпуска',
			specialType: 'Спец.тип',
			fireDepShort: 'ПСЧ',
			status: 'Статус',
		},
		fireDepVehicleSuit: {
			suit: 'Тип костюма',
			amount: 'Количество',
			title: 'Защитные костюмы',
			add: 'Добавить костюм',
			glonass: 'Глонасс',
		},
		fireDepVehicleHydraulicTool: {
			title: 'ГАСИ',
			add: 'Добавить ГАСИ',
		},
		fireDepVehicleBrand: {
			title: 'Марка пожарной техники',
			plural: 'Марки пожарной техники',
			chooseBrand: 'Выберите марку',
		},
		fireDepVehicleType: {
			title: 'Тип пожарной техники',
			plural: 'Типы пожарной техники',
			special: 'Спецтехника',
			chooseType: 'Выберите тип',
			specialType: 'Вид техники',
		},
		fireDepSpecialVehicleType: {
			plural: 'Специальные виды техники',
		},
		emergencySituationRiskCategory: {
			title: 'Категория рисков',
			plural: 'Категории рисков',
		},
		emergencySituationPlanStatus: {
			title: 'Статус плана ЧС',
			plural: 'Статусы плана ЧС',
		},
		emergencySituationPlanResource: {
			title: 'Силы и средства плана ЧС',
			title_short: 'Силы и средства',
			personnelCount: 'Личный состав',
			vehicleCount: 'Техника',
			department: 'Подразделение',
			vehicle: 'Техника',
			vehicleType: 'Тип техники',
		},
		emergencySituationPlanResourceTier: {
			title: 'Эшелон сил и средств плана ЧС',
			plural: 'Эшелоны сил и средств плана ЧС',
			title_short: 'Эшелон',
		},
		fireAlarmMessage: {
			newAdpiAlarm: 'Поступило сообщение от датчика АДПИ',
			new112Alarm: 'Поступило сообщение от службы 112',
			title: 'Сообщение о пожарной тревоге',
			plural: 'Сообщения о пожарной тревоге',
			trueMessage: 'Реальная угроза',
			falseMessage: 'Ложное сообщение',
			goToFire: 'Перейти к пожару',
			information: 'Информация',
			incidentCode: 'Код происшествия',
			operatorNumber: 'Номер оператора',
			addressText: 'Адрес (текст, пришедший из 112)',
			reportingPersonName: 'ФИО заявителя',
			reportingPersonPhone: 'Номер телефона заявителя',
			call: 'Вызов',
		},
		fireAlarmMessageStatus: {
			title: 'Статус сообщения пожарной тревоги',
			plural: 'Статусы сообщений пожарной тревоги',
		},
		emergencyDivision: {
			title: 'Подразделение',
			plural: 'Подразделения',
			contacts: 'Контакты',
		},
		emergencyDivisionGroup: {
			title: 'Группа подразделения',
			plural: 'Группы подразделений',
		},
		fireDepPersonnelStatus: {
			title: 'Статус личного состава',
			plural: 'Статусы личного состава',
			chooseStatus: 'Выберите статус',
		},
		fireAlarmMessageSource: {
			title: 'Источник сообщения пожарной тревоги',
			plural: 'Источники сообщения пожарной тревоги',
		},
		protectiveSuit: {
			title: 'Защитный костюм',
			plural: 'Защитные костюмы',
		},
		hydraulicTool: {
			title: 'Гидравлические аварийно-спасательные инструмент',
			plural: 'Гидравлические аварийно-спасательные инструменты',
		},
		municipalityStatus: {
			title: 'Статус готовности МО',
			plural: 'Статусы готовности МО',
		},
		hydrant: {
			title: 'Гидрант',
			plural: 'Гидранты',
			number: 'Номер',
			working: 'Исправен',
			notWorking: 'Неисправен',
			workingPlural: 'Исправные',
			notWorkingPlural: 'Неисправные',
			address: 'Адрес',
			reference: 'Ориентир',
			owner: 'Ведомственная принадлежность',
			type: 'Вид',
			intake: 'Заборное устройство',
			reason: 'Причина неисправности',
		},
		emergencySituationPlanMemberStatus: {
			title: 'Статус участника согласования ЧС',
			plural: 'Статусы участника согласования ЧС',
		},
		adpiDeviceLog: {
			title: 'История изменения статуса АДПИ',
			statusChanged: 'Статус АДПИ изменен на ',
			statusRemoved: 'Cтатус АДПИ убран ',
			statusNotSet: 'Cтатус АДПИ не задан ',
		},
		emergencySituationPlanMemberRole: {
			title: 'Роль участника согласования ЧС',
			plural: 'Роли участника согласования ЧС',
		},
		fireDepVehicleCallSign: {
			title: 'Позывной пожарной техники',
			plural: 'Позывные пожарной техники',
			fireDep: 'Пожарная часть',
		},
		fireDepStatus: {
			plural: 'Статусы ПСЧ для 112',
			title: 'Статус ПСЧ для 112',
			fire: 'Пожар',
			lie: 'Ложный',
			shortСircuit: 'КЗ',
			foodBurning: 'Пригорание пищи',
			bombThreat: 'Угроза взрыва',
			givingHelp: 'Оказание помощи',
			rescueWork: 'АСР',
			redirect: 'Переадресация',
			double: 'Двойник',
		},
		adpiDevice: {
			basic: 'Основная информация',
			add: 'Добавить пожароопасный объект',
			title: 'Датчик АДПИ',
			model: 'Модель АДПИ',
			type: 'Тип',
			phoneNumber: 'Телефонный номер',
			plural: 'Реестры АДПИ',
			status: 'Статус АДПИ',
			address: 'Адрес',
			organization: 'Организация',
			contacts: 'Контакты',
			organizationNumber: 'Номер телефона организации',
			dateAndTime: 'Дата и время',
			changeHistory: 'История изменения статуса АДПИ',
		},
		techFireReportSource: {
			title: 'Источник сообщения о пожаре',
			plural: 'Источники сообщения о пожарах',
		},
		techFireWayBill: {
			title: 'Путевой лист',
			plural: 'Путевые листы',
			detectTime: 'Дата/время обнаружения',
			reportTime: 'Дата/время сообщения',
			source: 'Источник сообщения',
			falseAlarm: 'Ложное сообщение',
			trueAlarm: 'Реальная угроза',
			addFireDep: 'Добавить пожарную часть',
			noVehicles: 'Не будет добавлена в путевой лист',
		},
		techFireSentry: {
			title: 'Караул (общий)',
			plural: 'Караулы (общие)',
		},
		techFireEmergencyCode: {
			plural: 'Коды происшествий по 112',
			title: 'Код происшествия по 112',
		},
		fireDepSentry: {
			title: 'Караул',
			plural: 'Караулы',
			noFireDep: 'Не выбрана пожарная часть',
			firstHint: 'Выберите пожарную часть',
			personnelHint: 'Чтобы составлять личный состав и технику для нового караула, нужно сохранить запись.',
			create: 'Создать караул',
			globalSentry: 'Общий караул',
			selectGlobalSentry: 'Выбрать общий караул',
			customName: 'Свое название',
			setCustomName: 'Задать свое название',
			back: 'К списку караулов',
			units: 'Штатные еденицы',
			select: 'Выберите караул',
		},
		waterSupply: {
			number: 'Номер',
			title: 'Источник воды',
			plural: 'Источники воды',
			division: 'Подразделение',
			typeName: 'Вид/диаметр',
			ownership: 'Принадлежность',
			intake: 'Заборное устройство',
			reference: 'Ориентир',
			working: 'Исправен',
			address: 'Адрес',
			comment: 'Комментарий',
			depName: 'Название пожарной части',
			volume: 'Объём',
			defectCause: 'Причина неисправности',
			name: 'Название',
			add: 'Добавить источник воды',
			type: 'Тип',
			dateLastCheck: 'Дата последней проверки',
			addition: 'Дополнительно',
		},
		waterSupplyType: {
			title: 'Тип источника воды',
			plural: 'Типы источника воды',
		},
		waterSupplyDivision: {
			title: 'Подразделение источника воды',
			plural: 'Подразделения источника воды',
		},
		techFireShift: {
			title: 'Смена',
			plural: 'Смены',
		},
		fireDepShift: {
			title: 'Смена',
			plural: 'Смены',
			submit: 'Заступить на смену',
			date: 'Дата/время',
			dispatcher: 'Диспетчер',
			mainDispatch: 'Главный диспетчер',
			standIn: 'Подменный диспечтер',
			standInName: 'Ф.И.О. подменного диспечтера',
			standInSection: 'Отдел подменного диспечтера',
			standInPosition: 'Должность подменного диспечтера',
			guardHead: 'Начальник караула',
			reset: 'Сбросить (главный диспетчер)',
			confirmReset: 'Сбросить смену пожарной части?',
		},
		mapLayerHelipad: {
			title: 'Вертолетная площадка',
			plural: 'Вертолетные площадки',
		},
		mapLayerRoad: {
			title: 'Автомобильная дорога',
			plural: 'Автомобильные дороги',
		},
		mapLayerRailway: {
			title: 'Железная дорога',
			plural: 'Железные дороги',
		},
		roadObject: {
			title: 'Объект на автомобильной дороге',
			plural: 'Объекты на автомобильных дорогах',
		},
		waterTransportType: {
			title: 'Тип водного транспорта',
			plural: 'Типы водного транспорта',
		},
		emergencySituationPlanVehicleType: {
			title: 'Тип техники (ЧС)',
			plural: 'Типы техники (ЧС)',
		},
		mapLayerPier: {
			title: 'Причал',
			plural: 'Причалы',
		},
		mapLayerHydraulicStructure: {
			title: 'Гидротехническое сооружение',
			plural: 'Гидротехнические сооружения',
		},
		mapLayerGasPipeline: {
			title: 'Магистральный газопровод',
			plural: 'Магистральные газопроводы',
		},
		mapLayerOilPipeline: {
			title: 'Магистральный нефтепровод',
			plural: 'Магистральные нефтепроводы',
		},
		mapLayerRASCO: {
			title: 'РАСЦО',
			plural: 'РАСЦО',
		},
		techFireReport: {
			techfaulty: 'Неисправная техника',
			'digital-notefree': 'Цифровая записка ЛС - Вольники',
			ls5pso: 'Цифровая записка ЛС - 5ОФПС',
			'112list': 'Список по 112',
			'112statistic': 'Статистика по 112',
			plural: 'Отчеты',
			fuel: 'Топливо',
			lsogps40: 'Цифровая записка ЛС - Вольники',
			saveTemplate: 'Сохранить шаблон',
			sign1: 'Подпись 1',
			sign2: 'Подпись 2',
			sign3: 'Подпись 3',
			header: 'Шапка',
			title: 'Заголовок',
			date: 'Дата формирования',
			summarylist: 'Сводная ведомость',
			preview: 'Предпросмотр',
			generate: 'Генерация отчета',
			print: 'Печать',
			appg: 'Сведения об оперативной обстановке',
		},
		requestPlan: {
			title: 'Оперативная информация',
			plural: 'Оперативная информация',
		},
		techFireError: {
			noDateAndTime: 'Нет даты/времени обнаружения/сообщения',
			noFireDep: 'У объекта не назначена ответственная пожарная часть',
			noGlobalShift: 'На эту дату нет общей смены',
			noFireDepShift: 'Пожарная часть не заступила на смену',
			shiftWithNoVehicles: 'В смене пожарной части нет техники',
		},
		techFireOrganization: {
			parent: 'Родительская организация',
			title: 'Организация',
			plural: 'Организации',
			staffUnits: 'Штат ед.',
			officerUnits: 'Офицеры ед.',
			supervisorUnits: 'Руководство ед.',
			supervisorOfficerUnits: 'Руководство офицеры ед.',
			OOSPandPUnits: 'ООСПиП штат ед.',
			OOSPandPOfficerUnits: 'ООСПиП офицеры ед.',
			laborProtectionUnits: 'Служба охраны труда штат ед.',
			laborProtectionOfficerUnits: 'Служба охраны труда офицеры ед.',
		},
		requestRiver: {
			title: 'Река',
			plural: 'Реки',
		},
		requestPeriod: {
			title: 'Период',
			plural: 'Периоды',
		},
		requestPeriodDate: {
			title: 'Период (даты)',
			plural: 'Период (даты)',
		},
		requestCategory: {
			title: 'Категория',
			plural: 'Категории',
		},
		operInfo: {
			title: 'Оперативка',
			plural: 'Оперативка',
			plan: 'План',
			ship: 'Отгружено',
			deliver: 'Доставлено',
			organization: 'Предприятие',
			lastEditDate: 'Последнее изменение',
		},
		fireDepNote: {
			title: 'Строевая записка',
			number: '№',
			fireDeps: 'Наименования подразделений',
			location: 'Место дислокации',
			vehicles: {
				title: 'Пожарная техника',
				active: {
					title: 'Боевой расчет',
					main: {
						title: 'Основная',
						col4: 'АЦ',
						col5: 'АЦЛ',
						col6: 'АНР',
						col7: 'АА',
						col8: 'АП',
						col9: 'Пожарный поезд',
						col10: 'ПНС',
						col11: 'Прочие',
					},
					special: {
						title: 'Спец.техника',
						col12: 'АЛ',
						col13: 'АКП',
						col14: 'АТП',
						col15: 'АР',
						col16: 'АС',
						col17: 'АШ',
						col18: 'Прочие',
					},
				},
				reserve: {
					title: 'Резерв',
					col19: 'АЦ',
					col20: 'АЦЛ',
					col21: 'АНР',
					col22: 'АА',
					col23: 'ПНС',
					col24: 'АР',
					col25: 'АЛ',
					col26: 'АКП',
					col27: 'Прочие',
				},
				maintenance: {
					title: 'Не в расчете',
					col28: 'ТО 1',
					col29: 'ТО 2',
					col30: 'Ремонт',
					col31: 'Хранилище, консерв.',
				},
				support: {
					title: 'Вспомог. тех-ка',
					col32: 'Автобус',
					col33: 'Автоводовозка',
					col34: 'Грузовая',
					col35: 'Прочие',
				},
			},
			gasMasks: {
				title: 'СИЗОД',
				col36: 'Б/р',
				col37: 'Резерв',
			},
			suits: {
				title: 'Защит. костюмы',
				col38: 'Л-1',
				col39: 'ТОК',
				col40: 'Прочие',
			},
			tools: {
				title: 'ГАСИ',
				col41: 'Б/р',
				col42: 'Резерв',
			},
			personnel: {
				title: 'Личный состав',
				col43: 'По штату',
				col44: 'По списку',
				col45: 'На лицо',
				active: {
					title: 'Боевой расчет',
					col46: 'Всего',
					col47: 'НДС, НК, РДС',
					col48: 'ПНДС, ПНК',
					col49: 'КО',
					col50: 'Водитель',
					col51: 'Пожарные',
					col52: 'ГДЗС',
				},
				col53: 'Диспетчер',
				col54: 'Постовой (невыездной)',
				missing: {
					title: 'Отсутствует',
					col55: 'Всего',
					col56: 'Отпуск',
					col57: 'Болезнь',
					col58: 'Командировка',
					col59: 'Прочие',
					col60: 'Некомплект',
				},
			},
			foams: {
				title: 'Огнетушащие вещества',
				active: {
					title: 'В боевом расчете',
					col61: 'Пенообразователь',
					col62: 'Порошок',
				},
				reserve: {
					title: 'В резеврной технике',
					col63: 'Пенообразователь',
					col64: 'Порошок',
				},
				store: {
					title: 'На складе',
					col65: 'Пенообразователь',
					col66: 'Порошок',
				},
			},
			chief: 'Ф.И.О. начальника караула (начальника дежурной смены) подразделения',
		},
		dateRangePicker: {
			startDate: 'Дата начала',
			endDate: 'Дата конца',
			period: 'Период',
		},
		contact: {
			title: 'Контакт',
			plural: 'Контакты',
		},
	},
};
