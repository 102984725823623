import React, { Component } from 'react';
import { RecordSelect, Input, Checkbox, Button } from '@smartplatform/ui';
import store from 'client/store';
import { inject, observer } from 'mobx-react';
import t from 'i18n';

import './filters.scss';

@inject('addressStore')
@observer
export class Filters extends Component {
	render() {
		const { addressSearch, ktp, ptp, descriptionSearch, nameSearch, rank, onChange, resetFilters, onSearch, objectsCountSearch } = this.props.addressStore;
		const { noAdditionalFilters, noObjectCount } = this.props;

		return (
			<div className='address-filters'>
				<div>
					<Input value={nameSearch} type='search' onChange={onSearch('nameSearch', 'name')} placeholder={t('enter.name')} className='input-name' />
					{!noObjectCount && (
						<Input
							value={objectsCountSearch}
							type='search'
							onChange={onSearch('objectsCountSearch', 'objectsCount')}
							placeholder={t('enter.objectCount')}
							className='input-name'
						/>
					)}
					{!noAdditionalFilters && (
						<>
							<Input
								value={addressSearch}
								type='search'
								onChange={onSearch('addressSearch', 'addressName')}
								placeholder={t('enter.addressName')}
								className='input-name'
							/>
							<Input
								value={descriptionSearch}
								type='search'
								onChange={onSearch('descriptionSearch', 'description')}
								placeholder={t('enter.description')}
								className='input-name'
							/>
							<RecordSelect model={store.model.TechFireRank} property='name' value={rank} onChange={onChange('rank')} empty={t('techFireAddress.allRanks')} />
						</>
					)}
					<Button variant='primary' onClick={() => store.route.push({ path: '/techfires/addresses/create' })}>
						{t('add')}
					</Button>
					<Button onClick={resetFilters}>{t('reset')}</Button>
				</div>
				{!noAdditionalFilters && (
					<div>
						<Checkbox value={ptp} label={t('techFireAddress.ptp')} onChange={onChange('ptp')} />
						<Checkbox value={ktp} label={t('techFireAddress.ktp')} onChange={onChange('ktp')} />
					</div>
				)}
			</div>
		);
	}
}
