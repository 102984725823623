import React from 'react';
import { observer } from 'mobx-react';
import { fioShort, formatDate } from 'client/tools';
import { Table, Column, Checkbox } from '@smartplatform/ui';
import { DeleteBtn } from 'components';
import { fireDepSpecialTypeItem } from 'techfires/helpers';
import t from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@observer
export default class FireDep extends React.Component {
	constructor(props) {
		super(props);
	}

	renderFoam = ({ record }) => {
		// console.log('renderFoam', record.foam())
		const currentFoam = record.foam().length > 0 ? record.foam()[0] : null;
		return currentFoam?.volume || '-';
	};

	onVehicleToggle = (vehicle) => (value) => {
		const { selectedVehicles } = this.props.fireDepObj;
		const index = selectedVehicles.findIndex((vehicleId) => vehicleId === vehicle.id);
		if (value) {
			if (index === -1) selectedVehicles.push(vehicle.id);
		} else {
			if (index !== -1) selectedVehicles.splice(index, 1);
		}
	};

	renderCheckbox = ({ record }) => {
		const { selectedVehicles } = this.props.fireDepObj;
		const checked = selectedVehicles.includes(record.id);
		return <Checkbox checked={checked} onChange={this.onVehicleToggle(record)} disabled={!!this.props.store.record.id} />;
	};

	render() {
		const { fireDepObj, store } = this.props;
		const { fireDep, errors, vehicles, selectedVehicles, acceptInfo = {} } = fireDepObj;
		const isAccepted = !!acceptInfo.date && !!acceptInfo.user;

		return (
			<div key={fireDep.id} className='firedep'>
				<div className='header'>
					<div className='left'>
						<div className='name'>{fireDep.name}</div>
						{isAccepted && (
							<div className='accepted'>
								<FontAwesomeIcon icon={faCheck} /> {fioShort(acceptInfo.user)} {formatDate(acceptInfo.date)}
							</div>
						)}
						{selectedVehicles.length === 0 && <span className='reason'>{t('techFireWayBill.noVehicles')}</span>}
					</div>
					<div className='actions'>{!store.record.id && <DeleteBtn onConfirm={() => store.removeFireDep(fireDep)} />}</div>
				</div>
				{errors.length > 0 && (
					<div className='errors'>
						{errors.map((e, i) => (
							<div key={i}>{e}</div>
						))}
					</div>
				)}
				{vehicles.length > 0 && (
					<div className='vehicles'>
						<Table rows={vehicles}>
							<Column width={30}>{this.renderCheckbox}</Column>
							<Column relation='brand' property='name' label={t('fireDepVehicle.brand')} />
							<Column relation='type' property='name' label={t('fireDepVehicle.type')} />
							<Column relation='type' computed={fireDepSpecialTypeItem} label={t('fireDepVehicle.specialType')} />
							<Column relation='pump' property='capacity' label={t('fireDepVehicle.pump')} />
							<Column relation='cisternBulk' property='volume' label={t('fireDepVehicle.cisternBulk')} />
							<Column label={t('fireDepVehicle.foam')}>{this.renderFoam}</Column>
							<Column relation='callSign' property='name' label={t('fireDepVehicle.callSign')} />
						</Table>
					</div>
				)}
			</div>
		);
	}
}
