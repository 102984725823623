import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { formatDate } from 'client/tools';
import { Button } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class HeatPointPopup extends React.Component {

	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		setTimeout(() => {
			this.isLoading = false;
		}, 1000);
	};

	createFire = () => {
		const id = this.props.record.id;
		const [ lon, lat ] = this.props.record.geo.coordinates;
		store.route.push({ path: `/fires/registry/add?hp=${id}` });
		// store.route.push({ path: `/fires/registry/add?lon=${lon}&lat=${lat}` });
	};

	render() {
		const { record } = this.props;

		return <div className="heat-point-popup">
			{/*<div><em>#{record.id}</em></div>*/}
			<div>{t('dateTime')}: <em>{formatDate(record.date, 'dd.MM.yyyy HH:mm')}</em></div>
			<div>{t('coordinates')}: <em>{record.geo.coordinates[0]}, {record.geo.coordinates[1]}</em></div>
			<div>{t('heatPoint.brightness')}: <em>{record.brightness}</em></div>
			<div>{t('heatPoint.dayNight')}: <em>{record.dayNight}</em></div>
			<div>{t('heatPoint.frp')}: <em>{record.frp}</em></div>
			<div>{t('heatPoint.scan')}: <em>{record.scan}</em></div>
			<div>{t('heatPoint.track')}: <em>{record.track}</em></div>
			<div className="mt-2">
				<Button variant="primary" size="sm" onClick={this.createFire}>{t('heatPoint.createFire')}</Button>
			</div>
		</div>;
	}

}
