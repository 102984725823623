import React from 'react';
import { observable } from 'mobx';
import store from 'client/store';

export default class AddressCreateStore {
	@observable fire = null;
	@observable address = null;

	constructor() {
		this.fire = new store.model.TechFire();
	}

	onObjectChange = async (object) => {
		this.fire.object = object;
		this.address = await store.model.Address.findById(object.addressId);
	};

	onObjectReset = () => {
		this.fire.object = null;
		this.address = null;
	};
}
