import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, Field, Input, RelationSelect } from '@smartplatform/ui';
import { LadingBillCard } from './LadingBillCard';
import { Track } from './Track';
import t from 'i18n';
import './style.scss';

@inject('store')
@observer
export class LadingBills extends React.Component {
	render() {
		const { ladingBills, onChange } = this.props.store;
		return (
			<div className='lading-bills'>
				<div className='path'>
					<Field relation='departureCity' property='name' label={t('request.departureCity')}>
						<RelationSelect onChange={onChange('departureCityId')} />
					</Field>
					<Field relation='destinationCity' property='name' label={t('request.destinationCity')}>
						<RelationSelect onChange={onChange('destinationCityId')} />
					</Field>
					<Field property='trackNumber' label={t('request.trackNumber')}>
						<Input onChange={onChange('trackNumber')} />
					</Field>
				</div>
				<Field property='deliveryDate' label={t('request.deliveryDate')} className='deliveryDate'>
					<DatePicker onChange={onChange('deliveryDate')} />
				</Field>
				<div className='d-flex'>
					<div className='lading-bills-list'>
						{ladingBills.map((bill, index) => (
							<div key={bill.id} className='d-flex'>
								<LadingBillCard bill={bill} index={index} />
							</div>
						))}
					</div>
					<Track />
				</div>
			</div>
		);
	}
}
