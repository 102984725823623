import React from 'react';
import { ActionbarContext } from 'components';
import { AddButtonSecondary } from 'client/components';
import store from 'client/store';
import t from 'i18n';
import { inject } from 'mobx-react';

export const _AddBillButton = (props) => {
	const context = React.useContext(ActionbarContext);
	const addBill = async () => {
		const newRecord = await store.model.RequestLadingBill.create({ date: new Date() });
		props.store.ladingBills.push(newRecord);
		context.form.addHasMany('ladingBills', newRecord);
		props.store.onChange('ladingBills')(newRecord);
	};
	return <AddButtonSecondary text={t('ladingBill.add')} onClick={addBill} />;
};

export const AddBillButton = inject('store')(_AddBillButton);
