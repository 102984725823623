import React from 'react';
import { Column, Table } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { callSigns } = this.store;

		return (
			<Table className='mt-3' rows={callSigns}>
				<Column property='name' label={t('fireDepVehicle.callSign')} />
				<Column relation='fireDep' property='name' label={t('fireDepVehicle.fireDepShort')} />
			</Table>
		);
	}
}
