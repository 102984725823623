import React from 'react';
import { observer } from 'mobx-react';
import { Toolbar } from './toolbar';
import { Column, Pager, Table } from '@smartplatform/ui';
import { FIRE_DEPS_SCOPE, MODEL_NAME } from './constants';
import store from 'client/store';
import t from 'i18n';
import { getPerPage } from 'client/tools';
import { ListStore } from 'components';
import TechFireOrganizations from './TechFireOrganizations';
import './style.scss';

@observer
export class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.match.path });
		this.store.path = props.path;
		this.model = store.model[MODEL_NAME];
	}
	componentDidMount() {
		if (!this.error) this.store.setPerPage(getPerPage());
	}

	onSelect = async (record) => {
		if (!record.parentId) {
			this.store.updateFilters({});
			return;
		}
		const ids = [];
		const getIds = (record) => {
			ids.push(record.id);
			record.children().forEach(getIds);
		};
		getIds(record);
		this.store.updateFilters({ where: { headOrgId: { inq: ids } } });
	};

	render() {
		const { onQueryUpdate, onChange, totalCount, page, query, onRowClick, perPage, isPerPageSetted } = this.store;
		const tableProps = isPerPageSetted ? { model: this.model } : { rows: [] };

		return (
			<div className='firedep-list-container'>
				<h2>{t('fireDep.plural')}</h2>
				<Toolbar store={this.store} />
				<div className='firedep-list'>
					<div className='sidebar'>
						<TechFireOrganizations onSelect={this.onSelect} />
					</div>
					<div className='content'>
						<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
						<div id='table'>
							<Table query={query} onQueryUpdate={onQueryUpdate} key={tableProps.model} {...tableProps} onRowClick={onRowClick}>
								<Column property='name' label={t('name')} />
							</Table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
