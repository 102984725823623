import { Popup } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Actionbar } from 'components';
import { observable } from 'mobx';
import store from 'client/store';

@inject('store')
@observer
export class MessagePopup extends React.Component {
	@observable text = '';

	onTextChange = (e) => (this.text = e.target.value);
	onClean = () => (this.text = '');
	onSave = async () => {
		const request = this.props.store.record;
		request.sendMessageToOwner({ text: this.text, userId: store.model.user.id });
		this.props.onClose();
	};

	render() {
		const { onClose } = this.props;

		return (
			<Popup onClose={onClose} className='delivery-popup' width={600}>
				<h2>{t('request.messageTitle')}</h2>

				<textarea rows={6} value={this.text} onChange={this.onTextChange} />

				<Actionbar>
					<Actionbar.SaveButton disabled={!this.text} onSave={this.onSave} />
					<Actionbar.CancelButton back={onClose} />
					<Actionbar.CleanButton onClean={this.onClean} />
				</Actionbar>
			</Popup>
		);
	}
}
