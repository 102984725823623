import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Row, Input } from '@smartplatform/ui';
import t from 'i18n';

// компоненты AlarmPhone и AlarmManual пока что идентичны, возможно в будущем появятся отличия
@observer
export default class AlarmManual extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	onChange = (property) => (value) => {
		if (this.props.canEdit) this.props.record[property] = value;
	};
	
	render() {
		// проп "record" вместо "alarmMessage" потому что компонент может использоваться для
		// модели TechFire, там есть те же поля (в этом случае нужен проп "canEdit").
		const { record, canEdit } = this.props;
		
		return <>
			<Row>
				<div className="form-field">
					<label>{t('techFire.phone')}</label>
					<Input value={record.reportingPersonPhone || ''} disabled={!canEdit} onChange={this.onChange('reportingPersonPhone')} />
				</div>
				<div className="form-field">
					<label>{t('techFire.personName')}</label>
					<Input value={record.reportingPersonName || ''} disabled={!canEdit} onChange={this.onChange('reportingPersonName')} />
				</div>
				<div className="form-field">
					<label>{t('techFire.address')}</label>
					<Input value={record.reportingPersonAddress || ''} disabled={!canEdit} onChange={this.onChange('reportingPersonAddress')} />
				</div>
			</Row>
{/*
			<Row>
				<div className='form-field'>
					<label>{t('fireAlarmMessage.information')}</label>
					<textarea value={record.information || ''} rows={10} disabled={!canEdit} />
				</div>
			</Row>
*/}
		</>;
	}
}
