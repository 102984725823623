export const RELATIONS = ['members', 'tracks', 'ladingBills', 'supplies', 'attachments'];
export const MODEL_NAME = 'Request';

export const SUPPLIES_INCLUDE = [
	{ relation: 'measure', scope: { fields: ['id', 'name'] } },
	{ category: ['parent'] },
	{ relation: 'county', scope: { fields: ['id', 'name'] } },
	{ relation: 'city', scope: { fields: ['id', 'name'] } },
	{ relation: 'plans', scope: { fields: ['id', 'count', 'quantity', 'date'] } },
	{ relation: 'nomenclature', scope: { fields: ['id', 'name'] } },
	{ relation: 'reserve' },
	{ relation: 'period' },
];
export const REQUEST_INCLUDE = {
	include: [
		{ relation: 'organization', scope: { fields: ['id', 'name'] } },
		{ relation: 'contractor', scope: { fields: ['id', 'name'] } },
		{ relation: 'river', scope: { fields: ['id', 'name'] } },
		{ relation: 'type', scope: { fields: ['id', 'name', 'code'] } },
		{ relation: 'level', scope: { fields: ['id', 'name'] } },
		{ relation: 'destinationCity', scope: { fields: ['id', 'name'] } },
		{ relation: 'departureCity', scope: { fields: ['id', 'name'] } },
		{
			relation: 'ladingBills',
			scope: {
				fields: ['id', 'totalItems', 'netWeight', 'grossWeight', 'totalReleased', 'number', 'date', 'senderId', 'payerId', 'consigneeId'],
				include: [
					{ relation: 'sender', scope: { fields: ['id', 'name'] } },
					{ relation: 'payer', scope: { fields: ['id', 'name'] } },
					{ relation: 'consignee', scope: { fields: ['id', 'name'] } },
					{ relation: 'supplies' },
				],
			},
		},
		{ relation: 'attachments' },
		'comments',
		{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar', 'position'] } },
		{
			supplies: SUPPLIES_INCLUDE,
		},
		{ members: [{ user: ['organization'] }, 'status'] },
		{
			relation: 'tracks',
			scope: {
				fields: ['id', 'date', 'statusId', 'cityId', 'storehouseId'],
				include: [
					{ relation: 'city', scope: { fields: ['id', 'name'] } },
					{ relation: 'storehouse', scope: { fields: ['id', 'name'] } },
					{ relation: 'status', scope: { fields: ['id', 'name', 'color'] } },
				],
			},
		},
		{ relation: 'status', scope: { fields: ['id', 'name', 'color', 'code'] } },
	],
	fields: [
		'id',
		'typeId',
		'date',
		'isCollective',
		'name',
		'departureCityId',
		'destinationCityId',
		'organizationId',
		'ownerId',
		'levelId',
		'deliveryDate',
		'statusId',
		'trackNumber',
		'contractorId',
		'riverId',
		'typeOnCreate'
	],
};

