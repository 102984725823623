import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Card, LabelField } from 'delivery/components';
import InfoIcon from '!!svg-react-loader!client/img/icons/info.svg';
import { Row } from '@smartplatform/ui';
import { formatDate } from 'client/tools';
import { renderCategories } from 'delivery/helpers';

@inject('store')
@observer
export class RequestInfo extends React.Component {
	render() {
		const { record, supplies } = this.props.store;

		return (
			<Card icon={<InfoIcon />} title={t('request.mainInfo_full')} contentPadding={20}>
				<Row>
					<LabelField label={t('request.type')} children={record.type?.name} />
					<LabelField label={t('request.date')} children={formatDate(record.date, 'dd.MM.yyyy HH:mm')} />
					<LabelField label={t('organization.title')} children={record.organization?.name} />
				</Row>
				{/* TODO */}
				<Row>
					<LabelField label={t('category')} className='categories'>{renderCategories(supplies)}</LabelField>
					<LabelField
						label={t('nomenclature.title')}
						children={supplies.map(({ name }) => (
							<div>{name}</div>
						))}
					/>
					<LabelField label={t('information')} children={record.organization?.comment} />
				</Row>
			</Card>
		);
	}
}
