import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FilterLayout } from '..';
import { Checkbox, RecordSelect } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import './style.scss';

@observer
export default class Filter extends React.Component {
	static propTypes = {
		layer: PropTypes.object,
	};

	render() {
		const { layer, types } = this.props;
		const { working, selectedTypes, division } = layer.settings.filter || {};
		const { onTypeToggle, onDivisionChange } = layer;

		const radioBtn = (label, condition, newValue) => (
			<div>
				<input name='working' type='radio' checked={condition} onChange={() => layer.onWorkingChange(newValue)} /> {label}
			</div>
		);

		return (
			<FilterLayout layer={layer} title={t('waterSupply.plural')} className='water-supply-filter'>
				{types.map((type) => (
					<Checkbox value={!!selectedTypes.find((i) => i === type.id)} onChange={() => onTypeToggle(type.id)} label={type.name} key={type.code} />
				))}
				<div>
					{radioBtn(t('all'), working === undefined || working === null, null)}
					{radioBtn(t('hydrant.workingPlural'), working === true, true)}
					{radioBtn(t('hydrant.notWorkingPlural'), working === false, false)}
				</div>
				<div>
					<label> {t('division')}</label>
					<RecordSelect
						model={store.model.WaterSupplyDivision}
						value={division}
						onChange={onDivisionChange}
						property='name'
						filter={{ fields: ['id', 'name'] }}
					/>
				</div>
			</FilterLayout>
		);
	}
}
