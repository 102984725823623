import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Table, Column } from '@smartplatform/ui';
import t from 'i18n';
import { RowAddButton } from 'fires/components';
import EditDamagePopup from './EditDamagePopup';
import { FormContext } from '@smartplatform/ui';
import store from 'client/store';

@observer
export default class Damage extends React.Component {
	@observable showEditPopup = false;
	@observable selectedRecord = null;
	@observable damage = [];
	static contextType = FormContext;

	constructor(props) {
		super(props);
		this.emergencySituation = props.record;
		if (this.emergencySituation.id) {
			this.damage = props.record.damage();
		}
	}

	toggleEditPopup = (record, index) => {
		this.selectedRecord = record || null;
		this.selectedRecordIndex = Number.isInteger(index) ? index : null;
		this.showEditPopup = !this.showEditPopup;
	};

	beforeDamageSave = async (record) => {
		const recId = this.emergencySituation?.id;
		record.emergencySituationId = recId;
		await record.save();

		if (!recId) {
			this.context.form.addHasMany('damage', record);
		}

		if (Number.isInteger(this.selectedRecordIndex)) {
			this.damage[this.selectedRecordIndex] = record;
		} else {
			this.damage.push(record);
		}
		this.toggleEditPopup();
		return false;
	};

	onDamageDelete = () => {
		if (Number.isInteger(this.selectedRecordIndex)) {
			this.damage.splice(this.selectedRecordIndex, 1);
		}
		this.toggleEditPopup();
	};

	render() {
		const { selectedRecord, toggleEditPopup, damage, onDamageAction, beforeDamageSave, onDamageDelete } = this;
		const { disabled } = this.props;

		return (
			<div className='es-damage'>
				<h2>{t('emergencySituationDamage.title_short')}</h2>
				<Table rows={damage} onRowClick={toggleEditPopup} clickable={!disabled}>
					<Column property='date' label={t('date')} />
					<Column relation='county' property='name' label={t('county.title')} />
					<Column property='dead' label={t('emergencySituationDamage.dead')} className='text-center' />
					<Column property='injured' label={t('emergencySituationDamage.injured')} className='text-center' />
					<Column roperty='affectedObjects' label={t('emergencySituationDamage.affectedObjects')} className='text-center' />
				</Table>
				<RowAddButton onClick={() => toggleEditPopup()} disabled={disabled} />
				{this.showEditPopup && (
					<EditDamagePopup
						record={selectedRecord}
						onClose={toggleEditPopup}
						onAction={onDamageAction}
						beforeSave={beforeDamageSave}
						onDelete={onDamageDelete}
					/>
				)}
			</div>
		);
	}
}
