import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Column, Field, Form, Popup, RelationSelect, Row, Table } from '@smartplatform/ui';
import store from 'client/store';
import { Actionbar, AddButtonSecondary } from 'components';
import t from 'i18n';
import { VehiclePopup } from './VehiclePopup';

@inject('store')
@observer
export class Edit extends React.Component {
	@observable isLoading = true;
	@observable selectedVehicle = null;
	@observable vehicles = [];
	constructor(props) {
		super(props);
		this.returnPath = props.path;
		this.model = props.model;
		this.init();
	}

	init = async () => {
		try {
			this.id = parseInt(this.props.match.params.id);
			this.planId = this.props.store.record.id;

			if (!Number.isNaN(this.id)) {
				this.record = await this.model.findById(this.id, { include: [{ relation: 'vehicles', scope: { include: ['type'] } }] });
				this.vehicles = this.record.vehicles();
			} else this.setEmptyRecord();
		} catch (e) {
		} finally {
			this.isLoading = false;
		}
	};

	onAction = () => store.route.push({ path: this.returnPath });
	onRowClick = async (record) => (this.selectedVehicle = record);
	addVehicle = () => {
		this.selectedVehicle = new store.model.EmergencySituationPlanVehicle({ resourceId: this.record.id });
	};
	onClose = async () => (this.selectedVehicle = null);

	setEmptyRecord = () => (this.record = new this.model({ planId: this.planId }));
	renderVehicleCount = () => this.vehicles.reduce((acc, value) => acc + value.count, 0);

	render() {
		if (this.isLoading) return null;
		const disableSave = !!['tierId', 'divisionId'].find((prop) => !this.record[prop]);
		const controls = (
			<Actionbar>
				<Actionbar.SaveButton onSave={this.onAction} disabled={disableSave} />
				<Actionbar.CancelButton back={this.onAction} />
				{!this.record?.id && <Actionbar.CleanButton onClean={this.setEmptyRecord} />}
			</Actionbar>
		);

		return (
			<div className='resource'>
				<h2>{this.record.id ? t('editRecord') : t('addRecord')}</h2>
				<Form record={this.record} stay noDelete noSave controls={controls}>
					<Row>
						<Field relation='division' property='name' label={t('emergencyDivision.title')} isRequired>
							<RelationSelect noTotal isRequired noSearch />
						</Field>
						<Field relation='tier' property='name' label={t('emergencySituationPlanResourceTier.title_short')} isRequired>
							<RelationSelect noTotal isRequired noSearch />
						</Field>
					</Row>
					<Row>
						<Field property='personnelCount' label={t('emergencySituationPlanResource.personnelCount')} />
						<Field disabled computed={this.renderVehicleCount} label={t('emergencySituationPlanResource.vehicleCount')} />
					</Row>
					<h2>{t('emergencySituationPlanResource.vehicle')}</h2>
					<div style={{ marginBottom: 10 }}>
						<AddButtonSecondary onClick={this.addVehicle} text={t('add')} />
					</div>
					<Table rows={this.vehicles} onRowClick={this.onRowClick}>
						<Column relation='type' property='name' label={t('emergencySituationPlanResource.vehicleType')} />
						<Column property='count' label={t('count')} />
					</Table>
					{this.selectedVehicle && <VehiclePopup record={this.selectedVehicle} onClose={this.onClose} addVehicle={this.addVehicle} vehicles={this.vehicles} />}
				</Form>
			</div>
		);
	}
}
