import React from 'react';
import { NavLink, Route, Redirect, Switch } from 'react-router-dom';
import t from 'i18n';
import './style.scss';
import { REPORTS } from './constants';

export default function ({ match, title = t('techFireReport.plural') }) {
	const { path } = match;
	const _REPORTS = REPORTS.map(({ name, component }) => ({ path: `${path}/${name}`, title: t(`techFireReport.${name}`), component }));
	return (
		<div className='reports-page'>
			<Route path={path} exact>
				<Redirect to={_REPORTS[0].path} />
			</Route>
			<h2>{title}</h2>
			<div className='content'>
				<div className='menu'>
					{_REPORTS.map(({ path, title }) => (
						<NavLink to={path} activeClassName='active' key={title}>
							{title}
						</NavLink>
					))}
				</div>
				<div className='report'>
					<Switch>
						{_REPORTS.map(({ path, title, component }) => {
							const Component = component;
							return <Route key={title} path={path} exact children={(props) => <Component {...props} />} />;
						})}
					</Switch>
				</div>
			</div>
		</div>
	);
}
