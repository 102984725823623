import { inject, observer } from 'mobx-react';
import React from 'react';
import store from 'client/store';
import t from 'i18n';
import { Member } from './Member';
import { AddMemberButton } from 'components';
import { FormContext } from '@smartplatform/ui';

@inject('store')
@observer
export class Members extends React.Component {
	static contextType = FormContext;
	constructor(props) {
		super(props);
		this.store = props.store;
		this.MODEL = this.store.record.MODEL.RELATIONS.members.model;
	}
	get isAdding() {
		return this.store.members.find((i) => !i.user?.id);
	}

	addMember = async () => {
		const newMember = await store.model[this.MODEL].create();
		this.store.members.push(newMember);
	};
	onMemberChange = (member) => {
		this.context.form.addHasMany('members', member);
		this.store.onChange('members')(member);
	};
	onRemoveMember = (member) => () => {
		const index = this.store.members.findIndex((i) => i.id === member.id);
		this.store.members.splice(index, 1);
		this.context.form.removeHasMany('members', member);
		if (!this.props.store.changes['members']) this.props.store.changes['members'] = [];
		this.props.store.changes['members'].push(member.id);
		this.props.store.isChanged = true;
	};

	render() {
		const { enableForCustomer, enableForContractor } = this.props.store;
		const memberIds = this.store.members.map((i) => i.user?.id).filter(Boolean);
		return (
			<div className='members'>
				<h3>{t('request.agreement')}</h3>
				{this.store.members.map((member, i) => {
					return <Member key={member.id} member={member} onMemberChange={this.onMemberChange} onRemoveMember={this.onRemoveMember} memberIds={memberIds} />;
				})}

				{!this.isAdding && !(enableForCustomer && enableForContractor) && <AddMemberButton onClick={this.addMember} />}
			</div>
		);
	}
}
