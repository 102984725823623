import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { startOfDay, endOfDay, format } from 'date-fns';

import store from 'client/store';
import t from 'i18n';
import { ExportButton } from 'components';
import './style.scss';

@observer
export default class Note extends React.Component {

	@observable date = new Date();
	@observable shift = null;
	@observable shiftData = {};
	@observable headOrgs = [];
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		const start = startOfDay(this.date);
		const end = endOfDay(this.date);
		
		const shifts = await store.model.TechFireShift.find({
			where: {
				and: [
					{ date: { gte: start }},
					{ date: { lte: end }},
				],
			},
			order: 'id desc',
			limit: 1,
		})
		this.shift = shifts.length > 0 ? shifts[0] : null;
		
		const headOrgs = await store.model.TechFireOrganization.find({
			where: { parentId: { neq: null }},
			include: [
				{ relation: 'parent' },
				{ relation: 'children' },
				{
					relation: 'fireDepartments',
					scope: {
						fields: ['id', 'name', 'cityId'],
						include: [
							{ relation: 'city', scope: { fields: ['id', 'name']}},
						],
					}
				},
			],
			order: 'id asc',
		});
		
		// [
		//     title: 'ФПС',
		//     divisions: [
		//         {
		//             fireDeps: [...],
		//         },
		//         {
		//             fireDeps: [...],
		//         },
		//     ]
		// ]
		
		const rows = [];
		
		const getChildren = (org) => {
			const children = [];
			org.__name = org.name;
			for (let child of org.children()) {
				children.push(child);
				org.__name += ', ' + child.name;
				const _children = getChildren(child);
				_children.forEach(c => org.__name += ', ' + c.name)
				children.push(..._children);
			}
			return children;
		};
		
		for (let org of headOrgs) {
			org.__children = getChildren(org);
			org.__fireDeps = [...org.fireDepartments()];
		}
		
		for (let org of headOrgs) {
			if (org.parentId === 1 && (org.__children.length > 0 || org.__fireDeps.length > 0)) {
				rows.push({
					id: org.id,
					name: org.__name,
					__children: org.__children,
					__fireDeps: org.__fireDeps,
				});
			}
		}
		
		const getDivisions = (row) => {
			const divisions = [];
			if (row.__fireDeps.length > 0) {
				divisions.push(row.__fireDeps);
			}
			for (let child of row.__children) {
				divisions.push(...getDivisions(child));
			}
			return divisions;
		};
		
		for (let row of rows) {
			row.__divisions = getDivisions(row);
		}
		
		console.log(rows);
		
		// let filtered = headOrgs.filter(org => org.fireDepartments().length > 0);
		this.headOrgs = rows;
		
		console.log('shift', this.shift);
		
		if (this.shift) {
			const shiftData = {};
			const noteRows = await this.shift.getNote();
			console.log('noteRows', noteRows);
			for (let fireDep of noteRows) {
				shiftData[fireDep.id] = fireDep;
			}
			this.shiftData = shiftData;
		}
		
		console.log(this.shiftData);
	};
	
	renderFireDep = (fireDep, index) => {
		let content;
		const shift = this.shiftData[fireDep.id] || null;
		content = [...new Array(64)].map((v, i) => this.renderFireDepCell(shift, i + 4));
		return <tr key={fireDep.id} className={'firedep' + (!shift ? ' empty' : '')}>
			<td>{index + 1}</td>
			<td>{fireDep.name}</td>
			<td>{fireDep.city?.name || ''}</td>
			{content}
		</tr>;
	};
	
	renderFireDepCell = (shift, number) => {
		if (number === 67) {
			return <td key={number} className="value">
				{shift ? (shift['col' + number] || '') : ''}
			</td>;
		}
		return <td key={number} className="value">
			{shift ? (parseInt(shift['col' + number]) || '') : ''}
		</td>;
	};
	
	renderTotalCell = (name, fireDeps) => {
		const total = {};
		for (let fireDep of fireDeps) {
			const fireDepData = this.shiftData[fireDep.id];
			if (fireDepData) {
				if (!total[name]) total[name] = 0;
				total[name] += parseInt(fireDepData[name]) || 0;
			}
		}
		return <td key={name} className="value">
			{total[name] || '0'}
		</td>
	};
	
	renderTotal = (division, key) => {
		const cols = [...new Array(63)].map((v, i) => ({ name: 'col' + (i + 4) }));
		const content = cols.map((v, i) => this.renderTotalCell(v.name, division));
		return <tr className="division-total" key={key}>
			<td/>
			<td colSpan={2}>{t('total')}</td>
			{content}
			<td/>
		</tr>;
	};
	
	renderHeadOrgs = () => {
		return this.headOrgs.map(org => {
			return <React.Fragment key={'org-' + org.id}>
				<tr className="head-org"><td colSpan={67}>{org.name}</td></tr>
				{org.__divisions && org.__divisions.map((division, i) => <React.Fragment key={'org-' + org.id + '-div-' + i}>
					{division.map(this.renderFireDep)}
					{this.renderTotal(division, org.id + '-' + i)}
				</React.Fragment>)}
			</React.Fragment>;
		});
	};
	
	numberedRow = () => {
		return <tr className="numbered">
			{[...new Array(67)].map((v, i) => <td key={i}>{i + 1}</td>)}
		</tr>;
	};
	
	export = () => {
		const htmlTable = document.getElementById('techfires-note-table');
		const html = htmlTable.outerHTML;
		const downloadLink = document.createElement('a');
		downloadLink.href = 'data:application/vnd.ms-excel,\uFEFF ' + encodeURIComponent(html);
		const name = this.shift ?
			`Строевая записка ${format(new Date(this.shift.date), 'dd.MM.yyyy')}.xls`
			:
			`Строевая записка.xls`;
		downloadLink.download = name;
		downloadLink.click()
	};
	
	render() {
		return <div className="techfires-note">
			<h1>{t('fireDepNote.title')}</h1>
			<div className="mb-2">
				<ExportButton onClick={this.export} />
			</div>
			<table id="techfires-note-table">
				<thead>
				<tr>
					<th rowSpan={4}>{t('fireDepNote.number')}</th>
					<th rowSpan={4}>{t('fireDepNote.fireDeps')}</th>
					<th rowSpan={4}>{t('fireDepNote.location')}</th>
					<th colSpan={32}>{t('fireDepNote.vehicles.title')}</th>
					<th colSpan={2} rowSpan={3}>{t('fireDepNote.gasMasks.title')}</th>
					<th colSpan={3} rowSpan={3}>{t('fireDepNote.suits.title')}</th>
					<th colSpan={2} rowSpan={3}>{t('fireDepNote.tools.title')}</th>
					<th colSpan={18} rowSpan={2}>{t('fireDepNote.personnel.title')}</th>
					<th colSpan={6} rowSpan={2}>{t('fireDepNote.foams.title')}</th>
					<th rowSpan={4}>{t('fireDepNote.chief')}</th>
				</tr>
				<tr>
					<th colSpan={15}>{t('fireDepNote.vehicles.active.title')}</th>
					<th colSpan={9} rowSpan={2}>{t('fireDepNote.vehicles.reserve.title')}</th>
					<th colSpan={4} rowSpan={2}>{t('fireDepNote.vehicles.maintenance.title')}</th>
					<th colSpan={4} rowSpan={2}>{t('fireDepNote.vehicles.support.title')}</th>
				</tr>
				<tr>
					<th colSpan={8}>{t('fireDepNote.vehicles.active.main.title')}</th>
					<th colSpan={7}>{t('fireDepNote.vehicles.active.special.title')}</th>
					<th rowSpan={2} className="vert">{t('fireDepNote.personnel.col43')}</th>
					<th rowSpan={2} className="vert">{t('fireDepNote.personnel.col44')}</th>
					<th rowSpan={2} className="vert">{t('fireDepNote.personnel.col45')}</th>
					<th colSpan={7}>{t('fireDepNote.personnel.active.title')}</th>
					<th rowSpan={2} className="vert">{t('fireDepNote.personnel.col53')}</th>
					<th rowSpan={2} className="vert">{t('fireDepNote.personnel.col54')}</th>
					<th colSpan={6}>{t('fireDepNote.personnel.missing.title')}</th>
					<th colSpan={2}>{t('fireDepNote.foams.active.title')}</th>
					<th colSpan={2}>{t('fireDepNote.foams.reserve.title')}</th>
					<th colSpan={2}>{t('fireDepNote.foams.store.title')}</th>
				</tr>
				<tr>
					<th className="vert">{t('fireDepNote.vehicles.active.main.col4')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.main.col5')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.main.col6')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.main.col7')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.main.col8')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.main.col9')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.main.col10')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.main.col11')}</th>

					<th className="vert">{t('fireDepNote.vehicles.active.special.col12')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.special.col13')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.special.col14')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.special.col15')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.special.col16')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.special.col17')}</th>
					<th className="vert">{t('fireDepNote.vehicles.active.special.col18')}</th>

					<th className="vert">{t('fireDepNote.vehicles.reserve.col19')}</th>
					<th className="vert">{t('fireDepNote.vehicles.reserve.col20')}</th>
					<th className="vert">{t('fireDepNote.vehicles.reserve.col21')}</th>
					<th className="vert">{t('fireDepNote.vehicles.reserve.col22')}</th>
					<th className="vert">{t('fireDepNote.vehicles.reserve.col23')}</th>
					<th className="vert">{t('fireDepNote.vehicles.reserve.col24')}</th>
					<th className="vert">{t('fireDepNote.vehicles.reserve.col25')}</th>
					<th className="vert">{t('fireDepNote.vehicles.reserve.col26')}</th>
					<th className="vert">{t('fireDepNote.vehicles.reserve.col27')}</th>

					<th className="vert">{t('fireDepNote.vehicles.maintenance.col28')}</th>
					<th className="vert">{t('fireDepNote.vehicles.maintenance.col29')}</th>
					<th className="vert">{t('fireDepNote.vehicles.maintenance.col30')}</th>
					<th className="vert">{t('fireDepNote.vehicles.maintenance.col31')}</th>

					<th className="vert">{t('fireDepNote.vehicles.support.col32')}</th>
					<th className="vert">{t('fireDepNote.vehicles.support.col33')}</th>
					<th className="vert">{t('fireDepNote.vehicles.support.col34')}</th>
					<th className="vert">{t('fireDepNote.vehicles.support.col35')}</th>

					<th className="vert">{t('fireDepNote.gasMasks.col36')}</th>
					<th className="vert">{t('fireDepNote.gasMasks.col37')}</th>
					
					<th className="vert">{t('fireDepNote.suits.col38')}</th>
					<th className="vert">{t('fireDepNote.suits.col39')}</th>
					<th className="vert">{t('fireDepNote.suits.col40')}</th>
					
					<th className="vert">{t('fireDepNote.tools.col41')}</th>
					<th className="vert">{t('fireDepNote.tools.col42')}</th>
					
					<th className="vert">{t('fireDepNote.personnel.active.col46')}</th>
					<th className="vert">{t('fireDepNote.personnel.active.col47')}</th>
					<th className="vert">{t('fireDepNote.personnel.active.col48')}</th>
					<th className="vert">{t('fireDepNote.personnel.active.col49')}</th>
					<th className="vert">{t('fireDepNote.personnel.active.col50')}</th>
					<th className="vert">{t('fireDepNote.personnel.active.col51')}</th>
					<th className="vert">{t('fireDepNote.personnel.active.col52')}</th>
					
					<th className="vert">{t('fireDepNote.personnel.missing.col55')}</th>
					<th className="vert">{t('fireDepNote.personnel.missing.col56')}</th>
					<th className="vert">{t('fireDepNote.personnel.missing.col57')}</th>
					<th className="vert">{t('fireDepNote.personnel.missing.col58')}</th>
					<th className="vert">{t('fireDepNote.personnel.missing.col59')}</th>
					<th className="vert">{t('fireDepNote.personnel.missing.col60')}</th>

					<th className="vert">{t('fireDepNote.foams.active.col61')}</th>
					<th className="vert">{t('fireDepNote.foams.active.col62')}</th>
					
					<th className="vert">{t('fireDepNote.foams.reserve.col63')}</th>
					<th className="vert">{t('fireDepNote.foams.reserve.col64')}</th>
					
					<th className="vert">{t('fireDepNote.foams.store.col65')}</th>
					<th className="vert">{t('fireDepNote.foams.store.col66')}</th>
				</tr>
				</thead>
				<tbody>
					{this.numberedRow()}
					{this.renderHeadOrgs()}
				</tbody>
			</table>
		</div>;
	}

}
