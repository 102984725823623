import { observable } from 'mobx';
import store from 'client/store';
import { EMERGENCY_SITUATION_PLANS_INCLUDE, LOCAL_STORAGE_FIELDS } from './constants';
import debounce from 'lodash/debounce';

export default class PlansStore {
	@observable search = '';
	@observable querySearch = '';
	@observable order = 'id desc';
	@observable showFilters = false;
	@observable page = 1;
	@observable totalCount = 0;
	@observable perPage;
	path;


	constructor() {
		this.localStorageData = store.local.emergencySituationPlans;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.init();
	}
	init = async () => this.loadLocalStorage();

	doSearch = () => (this.querySearch = this.search);

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
		this.saveToLocalStorage();
	};
	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
		this.saveToLocalStorage();
	};

	onQueryUpdate = async (query) => {
		if (Number.isInteger(query._totalCount)) this.totalCount = query._totalCount;
		this.order = query.order;
	};

	get query() {
		const { querySearch } = this;
		const filter = {
			where: { and: [] },
			order: this.order,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			...EMERGENCY_SITUATION_PLANS_INCLUDE,
		};

		if (querySearch.length) filter.search = querySearch;
		if (!filter.where.and.length) delete filter.where;
		return filter;
	}

	create = () => store.route.push({ path: `${this.path}/create` });

	onShowFiltersChange = () => {
		this.showFilters = !this.showFilters;
		this.saveToLocalStorage();
	};

	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (store.local.emergencySituationPlans[field] = this[field]));
		store.local.save();
	};

	loadLocalStorage = async () => {
		const keys = Object.keys(this.localStorageData) || [];
		keys.forEach((key) => {
			if (this.localStorageData[key]) {
				this[key] = this.localStorageData[key];
			}
			if (key === 'search') {
				this.querySearch = this.localStorageData[key];
			}
		});
	};

	onRowClick = (record) => store.route.push({ path: `${this.path}/${record.id}` });
}
