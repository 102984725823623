import { observable } from 'mobx';
import store from 'client/store';
import { LOCAL_STORAGE_FIELDS, RESERVES_INCLUDE, RESERVES_PATH } from './constants';
import { PER_PAGE } from 'client/constants';
import debounce from 'lodash/debounce';

export default class StorageControlStore {
	@observable search = '';
	@observable querySearch = '';
	@observable showFilters = false;
	@observable isLoading = true;

	@observable selectedStorehouse = null;

	@observable selectedCategories = [];
	@observable selectedSubCategories = [];
	@observable openedCategories = [];

	//list
	@observable order = 'id desc';
	@observable page = 1;
	@observable totalCount = 0;

	constructor() {
		this.localStorageData = store.local.delivery.reserves || {};
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	init = async () => {
		this.loadLocalStorage();
		await this.fetchCategories();
		await this.fetchStorehouses();
		this.isLoading = false;
	};
	fetchCategories = async () =>
		(this.categories = await store.model.SupplyCategory.find({ include: ['children'], fields: ['id', 'name'], where: { parentId: null } }));

	fetchStorehouses = async () => (this.storehouses = await store.model.Storehouse.find());

	doSearch = () => (this.querySearch = this.search);

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
		this.saveToLocalStorage();
	};
	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
		this.saveToLocalStorage();
	};

	create = () => store.route.push({ path: `${store.route.path}/create` });

	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (store.local.delivery.reserves[field] = this[field]));
		store.local.save();
	};

	loadLocalStorage = async () => {
		const keys = Object.keys(this.localStorageData) || [];
		keys.forEach((key) => {
			if (this.localStorageData[key]) {
				this[key] = this.localStorageData[key];
			}
		});
	};

	onQueryUpdate = async (query) => {
		if (query._totalCount) this.totalCount = query._totalCount;
		this.order = query.order;
	};

	get filter() {
		const { querySearch, selectedSubCategories, selectedStorehouse } = this;
		const filter = {
			where: { and: [] },
			order: this.order,
			limit: PER_PAGE,
			skip: (this.page - 1) * PER_PAGE,
			...RESERVES_INCLUDE,
		};
		if (selectedSubCategories.length) filter.where.and.push({ categoryId: { inq: [...selectedSubCategories] } });
		if (typeof selectedStorehouse === 'number') filter.where.and.push({ storehouseId: { inq: [selectedStorehouse] } });
		if (querySearch.length) filter.search = querySearch;
		if (!filter.where.and.length) delete filter.where;
		return filter;
	}

	onRowClick = (record) => store.route.push({ path: `${RESERVES_PATH}/${record.id}` });
	onShowFiltersChange = () => {
		this.showFilters = !this.showFilters;
		this.saveToLocalStorage();
	};

	onSelect = (category, array) => {
		const index = this[array].findIndex((value) => value === category.id);
		index !== -1 ? this[array].splice(index, 1) : this[array].push(category.id);
		if (array === 'selectedCategories') {
			this.onSelectParentCategory({ category, isSelectedParent: index === -1 });
		}
		this.saveToLocalStorage();
	};

	onSelectByStoreHouse = (storehouse) => {
		this.selectedStorehouse = storehouse?.id ?? null;
		this.saveToLocalStorage();
	};

	onSelectParentCategory = ({ category, isSelectedParent }) => {
		category.children().forEach((child) => {
			const index = this.selectedSubCategories.findIndex((value) => value === child.id);
			const isSubSelected = index !== -1;
			if (isSelectedParent) {
				!isSubSelected && this.selectedSubCategories.push(child.id);
			} else {
				isSubSelected && this.selectedSubCategories.splice(index, 1);
			}
		});
	};
}
