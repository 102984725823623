const ICON_NAMES = {
	ZIK: 'grey',
	localized: 'green',
	extinguished: 'black',
	abandoned: 'yellow',
	default: 'red'
};

export const getFireIconName = ({ zone, status }) => {
	const code = zone?.code === 'ZIK' ? zone.code : status?.code;
	return ICON_NAMES[code] || ICON_NAMES.default;
}