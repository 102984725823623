import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from 'client/store';

@observer
export default class Count extends React.Component {
	
	@observable total = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.type !== this.props.type) {
			this.init();
		}
	}
	
	init = async () => {
		this.total = null;
		this.type = this.props.type;
		const { error, result }  = await store.model.Case.totalByType({ type: this.type });
		if (!error) {
			this.total = result;
		}
		else {
			console.error(error);
		}
		// setTimeout(this.init, PERIOD_IN_SECONDS * 1000)
	};
	
	render() {
		return <strong>{this.total}</strong>;
	}
	
}
