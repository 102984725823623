import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import { Loader, Tabs, Tab } from '@smartplatform/ui';
import t from 'i18n';

const DEFAULT_PRIORITY = 3;

@observer
export default class MainPopup extends React.Component {

	@observable tab = null;
	@observable tabs = [];

	constructor(props) {
		super(props);
		const { objects } = this.props;

		console.log('[MainPopup]', objects);
		let i = 0;
		for (const object of objects) {
			const { record, render, title, icon } = object;
			if (!record) continue;
			let type = 'unknown' + i;
			if (record.type) type = record.type;
			if (record.MODEL) type = record.MODEL.INFO.name;
			if (render) {
				const tab = {
					title,
					icon,
					record,
					render,
					type,
					priority: record.order || 0,
				};
				this.tabs.push(tab);
				i++;
			}
		}
		this.tabs = this.tabs.sort((a, b) => (a.priority || DEFAULT_PRIORITY) - (b.priority || DEFAULT_PRIORITY));
		if (this.tabs.length > 0) this.tab = this.tabs[0];
	}

	componentDidMount() {
		document.addEventListener('mousemove', this.onMouseMove);
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.onMouseMove);
	}

	onMouseMove = e => e.stopImmediatePropagation();

	setTab = tab => {
		this.tab = tab;
	};

	render() {
		return <div className="main-popup">
			<Tabs type="manual" className="fires-popup-tabs">
				{this.tabs.map((tab, i) => {
					const title = <div className='tab'>{tab.icon} {tab.title}</div>;
					return <Tab
						key={tab.type + '-' + i}
						onClick={() => this.setTab(tab)}
						title={title}
					/>;
				})}
			</Tabs>
			<div className="fires-popup-content">
				{this.tabs.map((tab, i) => {
					const { render, type, record } = tab;
					const isActive = this.tab.type === type && this.tab.record.id === record.id;
					return <div key={type + '-' + i} className={'tab-content' + (isActive ? ' active' : '')}>
						{render(tab.record)}
					</div>;
				})}
			</div>
		</div>;
	}

}
