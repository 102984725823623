import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import { Select } from '@smartplatform/ui';
import store from 'client/store';
import MODULES from 'client/modules';
import { getCurrentModule } from 'client/tools';
import './style.scss';

const homeLabel = (
	<span className='module-item'>
		<FontAwesomeIcon icon={faHome} /> Главная
	</span>
);

@withRouter
@observer
export default class ModuleSelect extends React.Component {
	onChange = (module) => {
		this.props.history.push(module ? module.path : '/');
	};

	label = (module) => (
		<div className='module-item'>
			{module.icon} <span>{module.name}</span>
		</div>
	);

	render() {
		const modules = store.config.modules.map((moduleName) => MODULES[moduleName]);
		// const modules = Object.values(MODULES);

		const items = modules.filter((module) => module.access()).map((module) => ({ label: this.label(module), value: module }));
		const currentModule = getCurrentModule();

		return (
			<Select
				noTotal
				maxItems={10}
				itemsPerPage={1000}
				items={items}
				value={currentModule}
				showValue={currentModule ? this.label(currentModule) : homeLabel}
				onChange={this.onChange}
				className='module-select'
				empty={homeLabel}
				noSearch
				isRequired
				itemFontSize={15}
			/>
		);
	}
}
