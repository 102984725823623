import React from 'react';
import { Column, Pager, Table } from '@smartplatform/ui';
import { Toolbar } from 'components';
import t from 'i18n';
import { observer } from 'mobx-react';
import MunicipalitiesStore from './store';
import { MODEL_NAME } from './constants';
import store from 'client/store';
import { renderMunicipalityStatus } from '../helpers';
import { renderStatus, getPerPage } from 'client/tools';
import './style.scss';
@observer
export class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new MunicipalitiesStore();
		this.store.path = props.match.path;
		this.store.init();
	}

	componentDidUpdate() {
		if (!this.store.isLoading && !this.store.perPage) {
			this.store.perPage = getPerPage();
		}
	}
	renderRisk = (record, id) => {
		const { risks } = record;
		if (risks.length) {
			const currentCategoryRisk = risks.find((risk) => risk.categoryId === id);

			if (currentCategoryRisk) {
				const { citiesCount, peopleCount, teenagersCount } = currentCategoryRisk;
				return (
					<div>
						{t('municipalFormation.citiesCount')}: {citiesCount}, {t('municipalFormation.peopleCount')}: {peopleCount}, {t('municipalFormation.teenagersCount')}
						: {teenagersCount}
					</div>
				);
			}
		}
		return '-';
	};

	render() {
		const { page, perPage, totalCount, onRowClick, onQueryUpdate, onChange, filter, isLoading, create } = this.store;
		const { search, onSearch, riskCategories, municipalityStatuses } = this.store;
		let model;
		if (!isLoading) {
			model = perPage ? store.model[MODEL_NAME] : undefined;
		}
		let tableProps = model ? { model } : { rows: [] };

		return (
			<div className='municipalities'>
				<h2>{t('municipalFormation.plural')}</h2>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<Toolbar.SearchIconInput value={search} onChange={onSearch} />
					<Toolbar.FilterButton />
				</Toolbar>
				<div id='table'>
					<Pager itemsPerPage={perPage} current={page} onChange={onChange('page')} totalCount={totalCount || 0} onRowClick={onRowClick} />
					<Table query={filter} onQueryUpdate={onQueryUpdate} {...tableProps} key={model} onRowClick={onRowClick} >
						<Column property='id' label={t('number')} width={70} />
						<Column property='name' label={t('municipalFormation.name')} width={300} />
						<Column property='countyName' label={t('municipalFormation.county')} width={200} />
						<Column property='citiesCount' computed={({ citiesCount }) => citiesCount || '-'} label={t('municipalFormation.cities')} width={200} />
						<Column property='population' computed={({ population }) => population || '-'} label={t('municipalFormation.population')} width={200} />
						<Column property='area' label={t('municipalFormation.area')} width={200} />
						<Column property='statusName' computed={({ status }) => renderStatus(status)} width={150} label={t('status')} />
						<Column
							relation='cities'
							computed={(cities) => renderMunicipalityStatus(cities, municipalityStatuses)}
							width={200}
							label={t('municipalFormation.sufficiencyStatusFull')}
						/>
						{riskCategories.map(({ name, id }) => (
							<Column key={name} computed={(record) => this.renderRisk(record, id)} width={300} label={name} />
						))}
					</Table>
				</div>
			</div>
		);
	}
}
