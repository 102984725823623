import { observable } from 'mobx';
import { PER_PAGE } from './constant';
import debounce from 'lodash/debounce';
import { MODEL_NAME, OPER_INFO_INCLUDE } from './constant';
import store from 'client/store';
import {PLAN_CODE, REQUEST_CODE} from "delivery/constants";
import {isHozSubject, isMinArct, isMO, isYakutOptTorg} from "delivery/helpers/roles";

export default class OperInfoStore {
	@observable records = [];
	@observable search = '';
	@observable querySearch = '';
	@observable startDate = null;
	@observable endDate = null;
	@observable river = null;
	@observable period = null;
	@observable county = null;
	@observable city = null;
	@observable organization = null;
	@observable category = null;
	//list
	@observable order = 'id desc';
	@observable page = 1;
	@observable totalCount = 0;
	@observable isLoading = true;
	@observable showFilters = false;

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	init = async () => {
		this.isLoading = true;
		await this.fetchRecord();
		this.isLoading = false;
	};

	fetchRecord = async () => {
		const { querySearch, startDate, endDate, river, period, county, city, organization, category } = this;
		const filter = { where: { or: [], and: [] }, ...OPER_INFO_INCLUDE };
		if (isMinArct() || isYakutOptTorg()) filter.where.and.push({ typeOnCreate: REQUEST_CODE });
		if (isHozSubject()) filter.where.and.push({ typeOnCreate: PLAN_CODE, organizationId: store.model.user.organizationId });
		if (isMO()) filter.where.and.push({ organizationId: store.model.user.organizationId });
		if (querySearch.length) filter.where.or = [{ plan: querySearch }, { ship: querySearch }, { deliver: querySearch }];
		if (startDate) filter.where.and.push({ date: { gte: startDate } });
		if (endDate) filter.where.and.push({ date: { lte: endDate } });
		if (river) filter.where.and.push({ riverId: river.id });
		if (period) filter.where.and.push({ periodId: period.id });
		if (organization) filter.where.and.push({ organizationId: organization.id });
		if (county) filter.where.and.push({ countyId: county.id });
		if (city) filter.where.and.push({ cityId: city.id });
		if (category) filter.where.and.push({ categoryId: category.id });

		filter.order = this.order;
		filter.limit = PER_PAGE;
		filter.skip = (this.page - 1) * PER_PAGE;
		this.records = await store.model[MODEL_NAME].find(filter);
		this.totalCount = this.records.totalCount;
	};

	get recordsSum() {
		let sum = { plan: 0, ship: 0, deliver: 0 };
		for (const record of this.records) {
			sum.plan += record.plan;
			sum.ship += record.ship;
			sum.deliver += record.deliver;
		}
		return sum;
	}

	doSearch = () => {
		this.querySearch = this.search;
		this.fetchRecord();
	};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
	};

	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
		this.fetchRecord();
	};

	onShowFiltersChange = () => {
		this.showFilters = !this.showFilters;
	};
}
