import React from 'react';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Column, FormContext, Table } from '@smartplatform/ui';
import { AddRowButton } from 'delivery/components';
import { renderStatus } from 'client/tools';
import { TrackEditPopup } from './TrackEditPopup';

@inject('store')
@observer
export class Track extends React.Component {
	@observable showPopup = false;
	@observable selectedRecord = null;
	static contextType = FormContext;

	constructor(props) {
		super(props);
		this.store = this.props.store;
	}

	openPopup = () => (this.showPopup = true);
	closePopup = async () => {
		this.showPopup = false;
		this.selectedRecord = null;
	};
	onRowClick = (row) => {
		this.selectedRecord = row;
		this.openPopup();
	};

	addTrack = (track) => {
		this.store.tracks.push(track);
		this.context.form.addHasMany('tracks', track);
	};
	deleteTrack = (track, index) => {
		this.store.tracks.splice(index, 1);
		track.id && this.context.form.removeHasMany('tracks', track);
	};

	render() {
		const { tracks, disableEdit } = this.props.store;

		return (
			<div className='request-track'>
				<h2>{t('requestTrack.title')}</h2>
				<Table rows={tracks} onRowClick={this.onRowClick}>
					<Column property='date' label={t('date_short')} />
					<Column relation='status' computed={renderStatus} label={t('requestTrack.status')} />
					<Column relation='city' property='name' label={t('city.title')} />
				</Table>
				{!disableEdit && <AddRowButton onClick={this.openPopup} />}
				{this.showPopup && (
					<TrackEditPopup addTrack={this.addTrack} deleteTrack={this.deleteTrack} onClose={this.closePopup} selectedRecord={this.selectedRecord} />
				)}
			</div>
		);
	}
}
