import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Popup } from '@smartplatform/ui';
import { Attachments } from 'client/components';

@observer
export default class MemberFile extends React.Component {
	static propTypes = {
		member: PropTypes.object,
		onClose: PropTypes.func,
	};

	static defaultProps = {};

	render() {
		const { member } = this.props;
		return (
			<Popup onClose={this.props.onClose} width={450} className='request-member' header='Прикрепить файл'>
				<Attachments record={member} canUpload />
			</Popup>
		);
	}
}
