import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import { CardUpdate } from 'delivery/tracking/single-cargo/cargo-tracking/CardUpdate';
import { CardTransporter } from 'delivery/tracking/single-cargo/cargo-tracking/CardTransporter';
import { CardStatus } from 'delivery/tracking/single-cargo/cargo-tracking/CardStatus';

@observer
export class CargoTracking extends React.Component {
	render() {
		return (
			<div>
				<CardStatus />
				<div className='bottom-cards'>
					<CardUpdate />
					<CardTransporter />
				</div>
			</div>
		);
	}
}
