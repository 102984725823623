import React from 'react';
import { observer } from 'mobx-react';
import { Column, Table } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';

@observer
export class Production extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { supplies } = this.props;

		return (
			<div className='tracking-production'>
				<Table rows={supplies}>
					<Column property='nomenclatureCode' label={t('trackingProduction.code')} width={130} />
					<Column property='price' label={t('trackingProduction.price')} />
					<Column property='name' label={t('trackingProduction.name')} />
					<Column relation='measure' property='name' label={t('trackingProduction.unit')} />
					<Column property='packageType' label={t('trackingProduction.containerType')} />
					<Column property='placeQuantity' label={t('trackingProduction.seatsNumber')} />
				</Table>
			</div>
		);
	}
}
