import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import './style.scss';
import { observable } from 'mobx';
import store from 'client/store';
import { COUNTIES_SCOPE, RESOURCES_SCOPE } from '../constants';
import { Column, Table } from '@smartplatform/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { renderStatus } from 'client/tools';

@inject('store')
@observer
export default class DataOnMap extends React.Component {
	@observable municipalities = [];
	@observable resources = [];
	@observable layers = [];
	@observable isLoading = true;
	countyId = null;

	constructor(props) {
		super(props);
		this.countyId = this.props.store.record.countyId;
		this.planId = this.props.store.record.id;
		this.init();
	}

	componentDidMount() {
		console.log('componentDidMount', this.el);
	}

	onMount = (el) => {
		this.el = el;
		if (el) {
			if (this.props.onRender) this.props.onRender(el);
		}
	};

	init = async () => {
		try {
			const [municipalities, resources, layers] = await Promise.all([
				store.model['ViewMunicipality'].find({ where: { countyId: this.countyId }, ...COUNTIES_SCOPE }),
				store.model['EmergencySituationPlanResource'].find({ where: { planId: this.planId }, ...RESOURCES_SCOPE }),
				store.model['MapLayer'].find({
					where: {
						name: { inq: store.local.emergency.planDynamicLayers },
						// icon: { neq: null },
					},
				}),
			]);
			this.layers = layers;
			this.municipalities = municipalities;
			this.resources = resources;
		} catch (e) {}
		this.isLoading = false;
	};

	renderRisks = ({ risks = [] }) =>
		risks.map(({ categoryName, peopleCount, teenagersCount }, i) => (
			<div key={i}>
				{categoryName} ({peopleCount || 0}/{teenagersCount || 0})
			</div>
		));

	renderVehicleCount = (vehicles) => vehicles.reduce((acc, value) => acc + value.count, 0);

	renderRisksSum = ({ risks = [] }) => {
		const { peopleCount, teenagersCount } = risks.reduce((acc, value) => {
			acc.peopleCount = (acc.peopleCount || 0) + (value.peopleCount || 0);
			acc.teenagersCount = (acc.teenagersCount || 0) + (value.teenagersCount || 0);
			return acc;
		}, {});
		return (
			<div>
				{peopleCount} / {teenagersCount}
			</div>
		);
	};

	render() {
		if (this.isLoading) return null;
		const { record } = this.props.store;
		const { explanatoryNote } = record;
		const { resources, layers } = this;

		return (
			<div className='sp-map-overlay data-on-map' ref={this.onMount}>
				{!!resources.length && (
					<div className='block' style={{ left: 10, top: 30, width: '35%' }}>
						<div className='block-hdr'>{t('emergencySituationPlanResource.title_short')} </div>
						<div className='content'>
							<Table rows={resources} clickable={false}>
								<Column relation='division' property='name' label={t('emergencyDivision.title')} />
								<Column property='personnelCount' label={t('emergencySituationPlanResource.personnelCount')} width={240} className='text-center' />
								<Column
									relation='vehicles'
									computed={this.renderVehicleCount}
									label={t('emergencySituationPlanResource.vehicleCount')}
									width={240}
									className='text-center'
								/>
								<Column relation='tier' property='name' label={t('emergencySituationPlanResourceTier.title_short')} width={300} />
							</Table>
						</div>
					</div>
				)}

				{record.county && (
					<div className='block' style={{ left: '45%', top: 35 }}>
						<div className='content'>
							{t('emergencySituationPlan.name')} {record.county.name}
						</div>
					</div>
				)}

				<div className='block' style={{ right: 10, bottom: 10, width: '30%' }}>
					<div className='block-hdr'>{t('municipality.explanatoryNote')} </div>
					<div className='content'>{explanatoryNote || '-'}</div>
				</div>

				{!!this.municipalities.length && (
					<div className='block' style={{ left: 10, bottom: 10, width: '35%' }}>
						<div className='block-hdr'>{t('municipality.plural')} </div>
						<div className='content'>
							<Table rows={this.municipalities} clickable={false}>
								<Column property='name' label={t('municipalFormation.name')} />
								<Column relation='cities' computed={(r) => <div>{r.map((i) => i.name).join(', ')}</div>} label={t('city.plural')} />
								<Column property='population' label={t('population')} width={100} />
								<Column computed={this.renderRisks} label={t('municipalFormation.risks')} />
								<Column computed={this.renderRisksSum} label={t('overall')} width={100} />
							</Table>
						</div>
					</div>
				)}

				{!!layers.length && (
					<div className='block' style={{ right: 10, top: 30 }}>
						<div className='block-hdr'>{t('emergencySituationPlan.legend')} </div>
						<div className='content'>
							{layers.map((layer) => (
								<div key={layer.id} className='layer-item'>
									{layer.icon ? <img src={layer.downloadFile('icon')} /> : <FontAwesomeIcon icon={faLocationDot} />}
									{layer.description}
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		);
	}
}
