import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Avatar, Popover } from '@smartplatform/ui';
import { fio } from 'client/tools';
import './style.scss';

@observer
export class MemberAvatar extends React.Component {
	static propTypes = {
		member: PropTypes.object.isRequired,
		size: PropTypes.number,
	};

	static defaultProps = {
		size: 18,
	};

	@observable popup = '';

	loadMember = async () => {
		const { member, size } = this.props;
		const organization = member.organization;
		this.popup = (
			<>
				<div className='avatar'>
					<Avatar user={member} size={size} />
				</div>
				<div className='info'>
					<div className='fio'>{fio(member)}</div>
					{organization && <div className='role'>{organization.name}</div>}
				</div>
			</>
		);
	};

	onClose = () => (this.popup = '');

	render() {
		const { member, size, style } = this.props;

		return (
			<Popover
				className='member-avatar'
				contentClassName='member-avatar-popup'
				// trigger="click"
				content={this.popup}
				onActivate={() => this.loadMember(member)}
				onClose={this.onClose}
				style={style}
			>
				<Avatar user={member} size={size} />
			</Popover>
		);
	}
}
