import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { TextField } from 'fires/components';

@observer
export default class AirfieldPopup extends React.Component {

	@observable record = null;
	@observable isShowMore = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.Airfield.findById(this.props.record.id);
	};

	toggleIsShowMore = () => this.isShowMore = !this.isShowMore;
	gotoAirfield = (e) => {
		e.preventDefault();
		store.route.push({ path: `/fires/dictionaries/airfield/${this.record.id}` });
	};

	render() {
		if (!this.record) return <Loader size={18} />;

		return <div className='airfield'>
			<div className='main-info'>
				<div className="title">
					<h2>{t('airfield.title')}: <span className='id' onClick={this.gotoAirfield} >#{this.record.id}</span></h2>
				</div>
				<Row>
					<TextField label={t('airfield.name')} value={this.record.name} />
					<TextField label={t('airfield.latitude')} value={this.record.latitude} />
					<TextField label={t('airfield.longitude')} value={this.record.longitude} />

				</Row>
				<Row>
					<TextField label={t('airfield.icaoCode')} value={this.record.icaoCode} />
					<TextField label={t('airfield.icaoLandingCategory')} value={this.record.icaoLandingCategory} />
					<TextField label={t('airfield.iataCode')} value={this.record.iataCode} />
				</Row>
				<Row>
					<TextField label={t('airfield.localTime')} value={this.record.localTime} />
					<TextField label={t('airfield.exceeding')} value={this.record.exceeding} />
					<TextField label={t('airfield.magneticDeclination')} value={this.record.magneticDeclination} />
				</Row>
				{!this.isShowMore && <div className='underline'></div>}
			</div>
			{/*TODO пока нет информации о ВПП*/}
			{this.isShowMore && <div className="vpp">
				<h2>{t('fire.popup.vpp')}:</h2>
			</div>}
			<div className="show-more">
				<FontAwesomeIcon icon={this.isShowMore ? faAngleUp : faAngleDown} onClick={this.toggleIsShowMore} />
			</div>
		</div>;
	}

}
