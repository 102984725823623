import { observable } from 'mobx';

export default class FireReportStore {
	@observable forestry = null;
	@observable ownership = null;
	@observable year = new Date().getFullYear();
	@observable fire = null;

	get storeParams() {
		return {
			reportCode: 'FireCard',
			reportParams: { fireId: this.fire?.id },
			isHTML: true,
		};
	}

	onYearChange = (year) => {
		this.fire = null;
		this.year = year;
	};
	onForestryChange = (forestry) => {
		this.fire = null;
		this.forestry = forestry;
	};
	onOwnershipChange = (ownership) => {
		this.fire = null;
		this.ownership = ownership;
	};
	onFireChange = (fire) => {
		this.fire = fire;
		this.isLoading = !fire;
	};
}
