import React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Kanban } from '@smartplatform/ui';
import Card from './Card';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import PlusIcon from '!!svg-react-loader!img/icons/plus.svg';
import { REQUEST_PATH } from '../constants';
import { DEFAULT_PRIORITY } from 'client/constants';

@inject('store')
@observer
export class RequestsKanban extends React.Component {
	@observable lists = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.reload = this.fetchLists;
		this.fetchLists();
	}

	componentWillUnmount() {
		this.store.reload = null;
	}

	fetchLists = async () => {
		this.lists = await store.model.RequestStatus.find(this.listFilter);
		if (this.kanban) this.kanban.reload();
		this.isLoading = false;
	};

	get listFilter() {
		const { statuses } = this.store;
		const filter = {
			where: { and: [] },
			include: [{ relation: 'requests', scope: { ...this.store.requestFilter, order: 'priority desc' } }],
		};
		if (statuses.length) filter.where.and.push({ id: { inq: this.store.statuses.map((i) => i.id) } });
		if (!filter.where.and.length) delete filter.where;
		return filter;
	}

	renderCard = (project, list, options) => <Card request={project} list={list} {...options} />;

	addItem = (list) => {
		this.kanban && this.kanban.addItem(list);
	};

	renderListHeader = (list) => (
		<div className='hdr' style={{ background: list.color }}>
			<div className='name'>{list.name}</div>
			<div className='actions'>
				<div className='count'>{list.totalCount > 0 ? list.totalCount : null}</div>
				<div className='kb-btn' onClick={() => this.addItem(list)}>
					<PlusIcon />
				</div>
			</div>
		</div>
	);

	@action onChange = async ({ item, text, prev, next, list, index }) => {
		const req = new store.model.Request(item);

		req.statusId = list.id;
		if (!item) {
			req.name = text;
			req.date = new Date();
		}

		if (!prev) {
			req.priority = next ? next.priority * 2 : DEFAULT_PRIORITY;
		} else if (!next) {
			req.priority = prev ? prev.priority / 2 : DEFAULT_PRIORITY;
		} else {
			req.priority = (prev.priority + next.priority) / 2;
		}

		try {
			await req.save();
		} catch (e) {
			throw e;
		}

		return {
			id: req.id,
			name: req.name,
			path: `${REQUEST_PATH}/${req.id}`,
			data: item ?? req,
		};
	};

	gotoItem = (path) => store.route.push({ path });

	getInstance = (instance) => {
		this.kanban = instance;
	};

	render() {
		if (this.isLoading) return null;
		const { path } = this.store;

		const requests = {
			name: t('request.plural'),
			lists: this.lists.map((list) => {
				return {
					id: list.id,
					name: list.name,
					color: list.color,
					data: list,
					items: list.requests().map((request) => {
						return {
							id: request.id,
							name: request.name,
							listId: request.statusId,
							path: `${path}/${request.id}`,
							data: request,
						};
					}),
					totalCount: list.requests().totalCount,
				};
			}),
		};

		return (
			<div className='requests-kanban-container'>
				<div className='requests-kanban'>
					{this.lists.length > 0 ? (
						<Kanban
							project={requests}
							renderItem={this.renderCard}
							renderListHeader={this.renderListHeader}
							itemClassName='kanban-card'
							onChange={this.onChange}
							getInstance={this.getInstance}
							loadMore={this.loadMore}
							onError={this.onError}
							gotoItem={this.gotoItem}
							itemsLimit={20}
						/>
					) : (
						<>{t('request.noStatuses')}</>
					)}
				</div>
			</div>
		);
	}
}
