import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { Button, Column, RecordSelect, RelationSelect, Table } from '@smartplatform/ui';
import store from 'client/store';
import { fireDepVehicleItem } from 'techfires/helpers';
import t from 'i18n';
import './style.scss';

@inject('fireDepStore')
@observer
export default class Personnel extends React.Component {
	@observable personnel = [];
	@observable filter = {};
	@observable rank = null;
	@observable position = null;
	@observable status = null;
	@observable person = null;

	constructor(props) {
		super(props);
		this.fireDepStore = props.fireDepStore;
		this.load();
	}

	load = async () => {
		const where = {
			fireDepId: this.fireDepStore.fireDep.id,
		};

		if (this.rank) {
			where.rankId = this.rank.id;
		}
		if (this.position) {
			where.positionId = this.position.id;
		}
		if (this.status) {
			where.statusId = this.status.id;
		}
		if (this.person) {
			where.lastName = this.person.lastName;
			where.firstName = this.person.firstName;
			where.middleName = this.person.middleName;
		}

		this.personnel = await store.model.FireDepPersonnel.find({
			where,
			include: [{ relation: 'vehicle', scope: { include: ['callSign'] } }, { relation: 'gasMask' }],
		});
	};

	addPerson = () => {
		const { params } = this.props.match;
		store.route.push({ path: `/techfires/firedep/${params.fireDepId}/personnel/create` });
	};

	editPerson = (record) => {
		const { params } = this.props.match;
		store.route.push({ path: `/techfires/firedep/${params.fireDepId}/personnel/${record.id}` });
	};

	onQueryUpdate = (query) => (this.filter = query);

	onChange = (property) => (value) => {
		this[property] = value;
		this.load();
	};

	render() {
		return (
			<div className='personnel'>
				<div className='personnel-filters'>
					<RecordSelect
						model={store.model.FireDepRank}
						value={this.rank}
						onChange={this.onChange('rank')}
						property='name'
						itemsPerPage={100}
						noTotal
						noSearch
						placeholder={t('fireDepRank.chooseRank')}
					/>
					<RecordSelect
						model={store.model.FireDepPosition}
						value={this.position}
						onChange={this.onChange('position')}
						property='name'
						itemsPerPage={100}
						noTotal
						noSearch
						placeholder={t('fireDepPosition.choosePosition')}
					/>
					<RecordSelect
						model={store.model.FireDepPersonnelStatus}
						value={this.status}
						onChange={this.onChange('status')}
						property='name'
						itemsPerPage={100}
						noTotal
						noSearch
						placeholder={t('fireDepPersonnelStatus.chooseStatus')}
					/>
					<RecordSelect
						model={store.model.FireDepPersonnel}
						value={this.person}
						onChange={this.onChange('person')}
						itemsPerPage={100}
						computed={({ lastName, firstName, middleName }) => <>{`${lastName || ''} ${firstName || ''} ${middleName || ''}`}</>}
						noTotal
						noSearch
						placeholder={t('fireDepPersonnel.choseName')}
					/>
					<Button onClick={this.addPerson} variant='primary'>
						{t('create')}
					</Button>
				</div>
				<Table rows={this.personnel} onRowClick={this.editPerson}>
					<Column property='lastName' label={t('person.lastName')} />
					<Column property='firstName' label={t('person.firstName')} />
					<Column property='middleName' label={t('person.middleName')} />
					<Column relation='position' property='name' label={t('fireDepPosition.title')} />
					<Column relation='rank' property='name' label={t('fireDepRank.title')} />
					<Column relation='gasMask' property='name' label={t('gasMask.title')} width={100} saveOnChange>
						<RelationSelect />
					</Column>
					<Column relation='vehicle' computed={fireDepVehicleItem} label={t('fireDepVehicle.title')} width={240} saveOnChange>
						<RelationSelect />
					</Column>
					<Column relation='status' property='name' label={t('person.status')} saveOnChange>
						<RelationSelect />
					</Column>
					<Column property='dayShift' label={t('fireDepPersonnel.dayShift')} />
				</Table>
			</div>
		);
	}
}
