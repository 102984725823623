import React from 'react';
import { List112, Statistic112, Fuel, LS5OFPS, LSOGPS40, TechFaulty, SummaryList, Appg } from './list';

export const REPORTS = [
	{ name: '112list', component: () => <List112 /> },
	{ name: '112statistic', component: () => <Statistic112 /> },
	{ name: 'fuel', component: () => <Fuel /> },
	{ name: 'ls5pso', component: () => <LS5OFPS /> },
	{ name: 'lsogps40', component: () => <LSOGPS40 /> },
	{ name: 'techfaulty', component: () => <TechFaulty /> },
	{ name: 'summarylist', component: () => <SummaryList /> },
	{ name: 'appg', component: () => <Appg /> },
];
