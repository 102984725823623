import { REQUESTS_INCLUDE } from 'delivery/requests/constants';

export const CONTRAGENTS_INCLUDE = { include: { relation: 'type', scope: { fields: ['id', 'name'] } } };
export const CONTRAGENT_INCLUDE = {
	include: [
		{ relation: 'type', scope: { fields: ['id', 'name'] } },
		{ relation: 'users', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'requests', scope: { ...REQUESTS_INCLUDE, order: 'updatedAt desc' } },
		'bankAccounts',
	],
};
export const AGREEMENTS_INCLUDE = {
	include: [
		{ relation: 'request', scope: { fields: ['id', 'name'] } },
		{ relation: 'status', scope: { fields: ['id', 'color', 'name'] } },
		{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
	],
};

export const MODEL_NAME = 'Organization';
export const LOCAL_STORAGE_FIELDS = ['showFilters', 'page', 'search'];
