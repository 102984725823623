import React from 'react';
import { observer } from 'mobx-react';
import Toolbar from './toolbar/Toolbar';
import t from 'i18n';
import { List } from './list';
import { KANBAN, LIST } from 'client/constants';

import './style.scss';
import TrackingStore from './store';

@observer
export default class Tracking extends React.Component {
	constructor(props) {
		super(props);
		this.store = new TrackingStore();
		this.store.init();
	}

	render() {
		let content;
		switch (this.store.mode) {
			case LIST:
				content = <List store={this.store} />;
				break;
			case KANBAN:
				//content = <Kanban store={this.store} />;
				break;
			default:
				content = <List store={this.store} />;
		}

		const title = t('tracking.title');
		return (
			<div className='delivery-page'>
				<h2>{title}</h2>
				<Toolbar store={this.store} />
				{content}
			</div>
		);
	}
}
