import React from 'react';
import { inject, observer } from 'mobx-react';
import { RecordSelect, Button, NumberInput, Input } from '@smartplatform/ui';
import { RequiredLabel } from 'fires/components';
import { Hint } from '../../map/filters';
import store from 'client/store';
import t from 'i18n';

@inject('card')
@observer
export default class MainInfo extends React.Component {
	onForestryChange = async (forestry) => {
		this.props.card.onChange('forestry')(forestry);
		this.props.card.onChange('forestryPrecinct')(null);
	};
	render() {
		const { card } = this.props;
		const className = 'form-field';
		const disabled = !store.model.Fire.INFO.WRITE;
		return (
			<div>
				<h2>{t('mainInfo')}</h2>

				<div className={className}>
					<RequiredLabel label={t('aviaDep.title')} />
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000} isRequired model={store.model.AviaDep} property='name' value={card.fire.aviaDep} onChange={card.onAviaDepChange} disabled={disabled} />
				</div>
				<div className={className}>
					<RequiredLabel label={t('forestry.title')} />
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						isRequired
						model={store.model.Forestry}
						property='name'
						value={card.fire.forestry}
						onChange={this.onForestryChange}
						disabled={disabled}
					/>
				</div>
				<div className={className}>
					<label>{t('forestryPrecinct.title')}</label>
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						model={store.model.ForestryPrecinct}
						property='name'
						value={card.fire.forestryPrecinct}
						onChange={card.onChange('forestryPrecinct')}
						disabled={disabled || !card.fire.forestryId}
						filter={{ where: { forestryId: card.fire.forestryId } }}
					/>
				</div>
				<div className={className}>
					<RequiredLabel label={t('forestOwnership.plural')} />
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						isRequired
						model={store.model.ForestOwnership}
						property='name'
						value={card.fire.forestOwnership}
						onChange={card.onChange('forestOwnership')}
						disabled={disabled}
					/>
				</div>
				<div className={className}>
					<RequiredLabel label={t('monitoringZone.title')} />
					<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
						isRequired
						model={store.model.MonitoringZone}
						property='name'
						value={card.fire.zone}
						onChange={card.onChange('zone')}
						disabled={disabled}
					/>
				</div>
				<div className={className}>
					<label>{t('forestryQuarter.short')}</label>
					<Input style={{ marginRight: '10px' }} value={card.forestryQuarters.map((i) => i.name).join(', ')} disabled />
				</div>
				<div className={className}>
					<label>{t('fire.allotmentNumber')}</label>
					<NumberInput value={card.fire.allotmentNumber} onChange={card.onChange('allotmentNumber')} />
				</div>
				<div>
					<Button onClick={card.updateMainInfo} size='sm' variant='info'>
						{t('fire.updateMainInfo')}
					</Button>
					<Hint text={t('fire.updateMainInfoHint')} trigger='hover' />
				</div>
			</div>
		);
	}
}
