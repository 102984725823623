import { Button, Input, Row, Select } from '@smartplatform/ui';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'client/store';

const ITEMS = ['ERROR', 'SUCCESS', 'ALARM', 'WARNING'];
@observer
export class Notification extends React.Component {
	@observable status = 'ERROR';
	@observable text = 'Тестовый текст';

	onChange = (prop) => (value) => (this[prop] = value);
	render() {
		return (
			<div style={{ marginBottom: 20 }}>
				<h2>Оповещения</h2>
				<div className='d-flex'>
					<div className='form-field'>
						<label>Статус оповещения</label>
						<Select items={ITEMS.map((i) => ({ value: i, label: i }))} onChange={this.onChange('status')} value={this.status} width={200} />
					</div>
					<div className='form-field'>
						<label>Текст оповещения</label>
						<Input value={this.text} onChange={this.onChange('text')} width={200} />
					</div>
					<div className='form-field'>
						<label>/</label>
						<Button
							style={{ marginLeft: 20 }}
							variant='primary'
							onClick={() => store.ui.setNotification({ message: this.text, type: this.status })}
							disabled={!this.text.length || !this.status}
						>
							Тест
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
