import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Loader } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class CountyPopup extends React.Component {

	render() {
		const { county } = this.props;

		return <div className="county-popup">
			{county.name}
		</div>;
	}

}
