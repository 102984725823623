import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { isAfter, startOfDay, endOfDay, addDays, addMonths, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import { DatePicker, RecordSelect, Button, Select } from '@smartplatform/ui';
import SystemSelect from '../SystemSelect';
import store from 'client/store';
import aStore, { MODES } from '../store';
import t from 'i18n';
import './style.scss';

export const UI_SIZE = 42;
const YEARS = [...new Array(new Date().getFullYear() - 2018 + 1)].map((v, i) => i + 2018);

@observer
export default class TopBar extends React.Component {

	onCategoryChange = category => {
		aStore.category = category;
		aStore.reloadCategories();
	};

	onStartDateChange = async (date) => {
		aStore.startDate = date ? startOfDay(new Date(date)) : null;
		aStore.dateMode = null;
		aStore.reload();
	};

	onEndDateChange = async (date) => {
		aStore.endDate = date ? endOfDay(new Date(date)) : null;
		aStore.currentDate = date ? new Date(date) : new Date();
		aStore.dateMode = null;
		aStore.reload();
	};

	onModeChange = (mode) => {
		aStore.dateMode = mode;
		this.calcPeriod();
		aStore.reload();
	};

	prev = () => this.go(-1);
	next = () => this.go(1);

	@action go = (direction = 1) => {
		if (aStore.dateMode === MODES.WEEK) {
			aStore.currentDate = addDays(aStore.currentDate, 7 * direction);
		}
		else if (aStore.dateMode === MODES.MONTH) {
			aStore.currentDate = addMonths(aStore.currentDate, direction);
		}
		else if (aStore.dateMode === MODES.YEAR) {
			aStore.currentDate = addMonths(aStore.currentDate, 12 * direction);
		}
		this.calcPeriod();
		aStore.reload();
	};

	@action calcPeriod = () => {
		if (aStore.dateMode === MODES.WEEK) {
			aStore.startDate = startOfDay(startOfWeek(aStore.currentDate, { weekStartsOn: 1 }));
			aStore.endDate = endOfDay(endOfWeek(aStore.currentDate, { weekStartsOn: 1 }));
		}
		else if (aStore.dateMode === MODES.MONTH) {
			aStore.startDate = startOfDay(startOfMonth(aStore.currentDate));
			aStore.endDate = endOfDay(endOfMonth(aStore.currentDate));
			// if (isAfter(aStore.endDate, new Date())) aStore.endDate = endOfDay(new Date());
		}
		else if (aStore.dateMode === MODES.YEAR) {
			aStore.startDate = startOfDay(startOfYear(aStore.currentDate));
			aStore.endDate = endOfDay(endOfYear(aStore.currentDate));
			// if (isAfter(aStore.endDate, new Date())) aStore.endDate = endOfDay(new Date());
		}
	};

	render() {
		const prevDisabled = aStore.dateMode === null;
		const nextDisabled = aStore.dateMode === null;

		const filter = {
			where: {
				parentId: null,
				totalCount: { gt: 0 },
				systemId: aStore.system.id,
			},
			order: 'totalCount desc, name asc'
		};

		return <div className="appeals-topbar">
			<RecordSelect noTotal maxItems={10} itemsPerPage={1000}
				model={store.model.ViewAppealCategory}
				property="name"
				filter={filter}
				value={aStore.category}
				onChange={this.onCategoryChange}
				className="category-select dark"
				position="bottomLeft"
				showValue={aStore.category ? aStore.category.name : 'Выберите категорию'}
				empty="Все категории"
				searchFields={['name']}
				size={UI_SIZE}
				fontSize={16}
				itemFontSize={14}
				// directChange
			/>
			<RecordSelect maxItems={10} itemsPerPage={1000}
				model={store.model.AppealSystem}
				property="name"
				value={aStore.system}
				onChange={aStore.onSystemChange}
				className="dark"
				filter={{ where: { id: { inq: [1, 2, 3, 5] }}, order: 'id asc' }}
				isRequired
				noSearch
				size={UI_SIZE}
				fontSize={16}
				itemFontSize={14}
				width={300}
				noTotal
			/>
			<DatePicker
				value={aStore.startDate}
				onChange={this.onStartDateChange}
				className="dark"
				size={UI_SIZE}
				fontSize={16}
				years={YEARS}
			/>
			<DatePicker
				value={aStore.endDate}
				onChange={this.onEndDateChange}
				className="dark"
				years={YEARS}
				size={UI_SIZE}
				fontSize={16}
			/>
			<Button disabled={prevDisabled} className="dark" variant="primary" onClick={this.prev} size={UI_SIZE}>
				<FontAwesomeIcon icon={faCaretLeft} />
			</Button>
			<Select noTotal maxItems={10} itemsPerPage={1000}
				items={Object.values(MODES).map(mode => ({ label: t(`period.${mode}`), value: mode }))}
				value={aStore.dateMode}
				onChange={this.onModeChange}
				position="bottomLeft"
				showValue={aStore.dateMode ? t(`period.${aStore.dateMode}`) : <span style={{color: '#aaa'}}>{t('period.select')}</span>}
				className="dark"
				isRequired
				noSearch
				size={UI_SIZE}
				fontSize={16}
				itemFontSize={14}
			/>
			<Button disabled={nextDisabled} className="dark" variant="primary" onClick={this.next} size={UI_SIZE}>
				<FontAwesomeIcon icon={faCaretRight} />
			</Button>
		</div>;
	}

}
