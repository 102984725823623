import React from 'react';
import { AddButton, ExportButton, FilterButton, SearchIconInput } from 'components';
import store from 'client/store';
import './style.scss';

export class Toolbar extends React.Component {
	render() {
		return <div className='composite-toolbar'>{this.props.children}</div>;
	}
}

Toolbar.AddButton = ({ onClick }) => {
	if (!onClick) {
		onClick = () => store.route.push({ path: store.route.path + '/create' });
	}
	return <AddButton onClick={onClick} />;
};

Toolbar.ExportButton = ({ exportUrl, onClick, text }) => {
	const Component = <ExportButton onClick={onClick} text={text} />;
	if (!exportUrl) return Component;
	return (
		<a href={exportUrl} rel='noopener noreferrer' download>
			{Component}
		</a>
	);
};

Toolbar.FilterButton = ({ onClick, showFilters }) => <FilterButton onClick={onClick} className={showFilters ? 'filter-btn active' : 'filter-btn'} />;

Toolbar.SearchIconInput = ({ value, onChange }) => <SearchIconInput onChange={onChange} value={value} />;
