import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Form, Row, Field, Button, Popconfirm, FileInput } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class ObjectEdit extends React.Component {
	@observable isDeleting = false;

	save = async () => {
		await this.props.object.save();
		this.props.onSave && this.props.onSave(this.props.object);
	};

	startDelete = () => (this.isDeleting = true);
	cancelDelete = () => (this.isDeleting = false);

	delete = async () => {
		this.isDeleting = false;
		await this.props.object.delete();
		this.props.onDelete && this.props.onDelete();
	};

	render() {
		const { object, onSave, onCancel } = this.props;

		const canSave = !!object.name && object.name.trim().length > 0;

		return (
			<div className='address-object-edit'>
				<div className='object-controls'>
					<Button variant='primary' onClick={this.save} size='sm' disabled={!object.name}>
						{t('save')}
					</Button>
					<Button onClick={onCancel} size='sm'>
						{t('cancel')}
					</Button>
					{!!object.id && (
						<div className='right'>
							{this.isDeleting ? (
								<>
									{t('confirmDelete')}
									<Button size='sm' variant='danger' onClick={this.delete}>
										{t('yes')}
									</Button>
									<Button size='sm' onClick={this.cancelDelete}>
										{t('no')}
									</Button>
								</>
							) : (
								<Button size='sm' variant='danger' onClick={this.startDelete}>
									{t('delete')}
								</Button>
							)}
						</div>
					)}
				</div>
				<Form record={object} writeAllowed={canSave} noSave noDelete onSave={onSave}>
					<Field property='name' label={t('techFireAddress.name')} isRequired />
					<Field property='description' label={t('techFireAddress.description')}>
						<textarea rows={3} />
					</Field>
					<Row>
						<Field relation='rank' property='name' label={t('techFireRank.title')} />
						<Field relation='fireDep' property='name' label={t('fireDep.title')} disabled />
					</Row>
					<Row>
						<Field property='ptp' label={t('techFireAddress.ptp')} />
						<Field property='ptpFile' label={t('techFireAddress.ptp')}>
							<FileInput accept='application/pdf,image/jpeg,image/png' />
						</Field>
					</Row>
					<Row>
						<Field property='ktp' label={t('techFireAddress.ktp')} />
						<Field property='ktpFile' label={t('techFireAddress.ktp')}>
							<FileInput accept='application/pdf,image/jpeg,image/png' />
						</Field>
					</Row>
				</Form>
			</div>
		);
	}
}
