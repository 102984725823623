import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { TextField } from 'fires/components';

@observer
export default class ForestryQuarterPopup extends React.Component {

	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.ForestryQuarter.findById(this.props.record.id, {
			include: [
				'forestry',
				'forestryPrecinct',
				'forestCategory',
				'aviaDep',
				'monitoringZone',
			],
		});
	};

	gotoRecord = () => {
		store.route.push({ path: `/fires/dictionaries/forestryQuarter/${this.record.id}` });
	};

	render() {
		if (!this.record) return <Loader size={18} />;

		return <div className='forestry-quarter'>
			<div className="title">
				<h2>{t('forestryQuarter.short')}: <span className='id' onClick={this.gotoRecord} >#{this.record.id}</span></h2>
			</div>
			<Row>
				<TextField label={t('forestryQuarter.code')} value={this.record.code} />
				<TextField label={t('forestryQuarter.area')} value={this.record.area} />
				<TextField label={t('forestryQuarter.forestry')} value={this.record.forestry?.name} />
				<TextField label={t('forestryQuarter.forestryPrecinct')} value={this.record.forestryPrecinct?.name} />
			</Row>
			<Row>
				<TextField label={t('forestryQuarter.forestCategory')} value={this.record.forestCategory?.name} />
				<TextField label={t('forestryQuarter.aviaDep')} value={this.record.aviaDep?.name} />
				<TextField label={t('forestryQuarter.monitoringZone')} value={this.record.monitoringZone?.name} />
			</Row>
		</div>;
	}

}
