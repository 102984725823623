import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Weather from './Weather';

const PATH = '/techfires/weather';

const TransportData = () => {
	return (
		<Switch>
			<Route path={PATH} children={() => <Weather />} />
		</Switch>
	);
};
export default TransportData;
