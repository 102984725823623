import React from 'react';
import { observer } from 'mobx-react';
import { Toolbar as BaseToolbar } from 'components';
import './style.scss';

@observer
export class Toolbar extends React.Component {
	render() {
		const { create, onSearch, search, showFilters, onShowFiltersChange } = this.props.store;
		return (
			<div className='briefcase-toolbar'>
				<div className='top'>
					<BaseToolbar>
						<BaseToolbar.AddButton onClick={create} />
						<BaseToolbar.SearchIconInput value={search} onChange={onSearch} />
						<BaseToolbar.FilterButton showFilters={showFilters} onClick={onShowFiltersChange} />
					</BaseToolbar>
				</div>
			</div>
		);
	}
}
