import React from 'react';
import { formatDate, fio } from 'client/tools';
import './style.scss';
import t from 'i18n';

export const History = ({ items, manualFields }) => {
	return (
		<div className='history-log'>
			{items.map((record) => (
				<div className='item' key={record.memberId + record.ownerId + record.createdAt}>
					<span className='date'>{formatDate(record.createdAt, 'dd.MM.yyyy HH:mm')}</span>
					{record.owner && <span className='user'>{fio(record.owner)}</span>}
					<div className='actions'>
						{record.actions.map((action, index) => (
							<span className='action' key={index}>
								<span className='title'>{action.title} </span>
								{action.subject && <span className='subject'>{action.subject}</span>}
								{action.newValue && (
									<span className='new-value'>
										{t('history.to')} {action.newValue}
									</span>
								)}

								<span className='new-value'>
									<b>{action.newFields?.map((field) => manualFields[field] || field).join(', ')}</b>
								</span>
							</span>
						))}
					</div>
				</div>
			))}
		</div>
	);
};
