import { observable } from 'mobx';
import store from 'client/store';
import { CONTRAGENT_INCLUDE, CONTRAGENTS_PATH, MODEL_NAME, AGREEMENTS_INCLUDE } from '../constants';

export default class ContragentStore {
	@observable record = null;
	@observable bankAccounts = [];
	@observable agreements = [];
	@observable isLoading = true;
	id;
	path;
	returnToPath;

	constructor() {
		this.model = store.model[MODEL_NAME];
	}
	get isNew() {
		return !this.id;
	}

	init() {
		this.fetchRecord();
	}

	fetchRecord = async () => {
		this.isLoading = true;
		if (this.id) {
			this.record = await this.model.findById(this.id, CONTRAGENT_INCLUDE);
			this.bankAccounts = this.record.bankAccounts();
		} else this.createNewRecord();
		this.isLoading = false;
	};

	fetchAgreements = async () => {
		const staffIds = this.record.users().map((i) => i.id);
		this.agreements = await store.model.RequestMember.find({
			where: { userId: { inq: staffIds }, requestId: { neq: null } },
			...AGREEMENTS_INCLUDE,
			order: 'updatedAt desc',
		});
	};

	onChange = (prop) => (value) => (this.record[prop] = value);
	createNewRecord = () => (this.record = new this.model({ date: new Date() }));

	onSave = () => {
		let path = this.returnToPath;
		if (this.isNew) {
			this.id = this.record.id;
			path += `/${this.id}`;
			this.fetchRecord();
		}
		store.route.push({ path });
	};
	back = () => store.route.push({ path: this.returnToPath });

	onDelete = async () => {
		await this.record.delete();
		this.back();
	};
}
