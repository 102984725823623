import React from 'react';
import { observer } from 'mobx-react';
import { Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import t from 'i18n';
import { observable } from 'mobx';
import store from 'client/store';
import { toLowerCamelCase, renderStatus } from 'client/tools';

const MODEL_NAME = 'AdpiDevice';
@observer
export default class FirePopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		// this.init();
	}

	init = async () => {
		this.record = await store.model.Fire.findById(this.props.record.id, {
			include: ['status'],
		});
		this.isLoading = false;
	};

	go = () => store.route.push({ path: `/fires/registry/${this.props.record.id}` });

	render() {
		// if (this.isLoading) return null;
		const { record } = this.props;

		return (
			<div className='map-fire-alarm-object-popup'>
				<div className='title'>
					<h2>
						{t(`fire.title`)}:{' '}
						<a style={{ cursor: 'pointer' }} onClick={this.go}>
							#{record.id}
						</a>
					</h2>
				</div>
				<Row>
					<TextField label={t('fire.status')} value={record.status?.name || '-'} />
					<TextField label={t('fire.forestryFireNumber')} value={record.forestryFireNumber || '-'} />
				</Row>
				<Row>
					<TextField label={t('fire.zone')} value={record.zone?.shortName || '-'} />
					<TextField label={t('fire.forestOwnership')} value={record.forestOwnership?.name || '-'} />
				</Row>
			</div>
		);
	}
}
