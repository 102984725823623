import React from 'react';
import { inject, observer } from 'mobx-react';
import { Mode } from 'delivery/components';
import { Filters } from './Filters';
import { Toolbar as BaseToolbar } from 'components';

@inject('store')
@observer
export default class Toolbar extends React.Component {
	render() {
		const { onSearch, onModeChange, onShowFiltersChange, showFilters, search, mode } = this.props.store;
		return (
			<div className='tracking-toolbar'>
				<div className='top'>
					<BaseToolbar>
						<BaseToolbar.SearchIconInput value={search} onChange={onSearch} />
						<BaseToolbar.FilterButton showFilters={showFilters} onClick={onShowFiltersChange} />
					</BaseToolbar>

					<div className='right'>
						<Mode mode={mode} onChange={onModeChange}>
							<Mode.List />
							<Mode.Kanban />
						</Mode>
					</div>
				</div>
				{showFilters && <Filters store={this.props.store} />}
			</div>
		);
	}
}
