import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { subDays, subMonths, subYears } from 'date-fns';

import { Loader, DatePicker, Select } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

const DATA_SETS = {
	open: { title: 'В работе без продлений', color: '#0ac' },
	late: { title: 'В работе без продлений c опозданием', color: '#d00' },
	closedInTime: { title: 'Исполнено в срок', color: '#8c0' },
	closedWithDelays: { title: 'Исполнено с нарушением срока', color: '#f80' },
	openWithDelays: { title: 'В работе с многократным продлением', color: '#f0c' },
}

const MODES = {
	WEEK: 'week',
	MONTH: 'month',
	HALFYEAR: 'halfYear',
	YEAR: 'year',
};

@observer
export default class Dashboard extends React.Component {

	@observable isLoading = true;
	@observable stats = null;
	@observable error = null;
	@observable startDate = null;
	@observable endDate = new Date();
	@observable changed = false;
	@observable mode = null;//MODES.YEAR;

	constructor(props) {
		super(props);
		this.setStartDate();
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		const res = await store.model.Errand.getStats({
			startDate: this.startDate,
			endDate: this.endDate,
		});
		this.error = res.error;
		this.stats = res.result;
		this.isLoading = false;
		this.changed = false;
	};

	getData = () => {
		const sets = Object.keys(DATA_SETS);

		let sum = 0;
		sets.forEach(set => sum += this.stats[set]);

		console.log('sum', sum);

		const labels = sets.map(set => DATA_SETS[set].title);

		const datasets = [
			{
				data: sets.map(set => this.stats[set]),
				label: 'Статистика поручений',
				borderWidth: 1,
				borderColor: '#fff',
				backgroundColor: sets.map(set => DATA_SETS[set].color),
			},
		];

		return { datasets, labels };
	};

	onMount = el => {
		this.el = el;
		if (el) {
			const { datasets, labels } = this.getData();

			this.chart = new Chart(el, {
				type: 'doughnut',
				data: { labels, datasets },
				options: {
					title: false,
					responsive: false,
					legend: false,
					cutoutPercentage: 33,
					animation: {
						duration: 0
					},
				},
			});
		}
	};

	update = async () => {
		await this.init();
		if (this.chart && this.stats) {
			const { datasets, labels } = this.getData();
			this.chart.data.datasets = datasets;
			this.chart.data.labels = labels;
			this.chart.update();
		}
	};

	onStartDateChange = (date) => {
		this.startDate = date;
		this.changed = true;
		this.mode = null;
		this.update();
	};

	onEndDateChange = (date) => {
		this.endDate = date;
		this.changed = true;
		this.mode = null;
		this.update();
	};

	onModeChange = async (mode) => {
		this.mode = mode;
		this.setStartDate();
		this.update();
	};

	setStartDate = () => {
		if (this.mode === MODES.WEEK) {
			this.startDate = subDays(this.endDate, 7);
		}
		else if (this.mode === MODES.MONTH) {
			this.startDate = subMonths(this.endDate, 1);
		}
		else if (this.mode === MODES.HALFYEAR) {
			this.startDate = subMonths(this.endDate, 6);
		}
		else if (this.mode === MODES.YEAR) {
			this.startDate = subYears(this.endDate, 1);
		}
		else {
			this.startDate = null;
		}
	};

	render() {
		if (!this.stats && this.isLoading) return <Loader size={18} />;

		return <div className="errands-dashboard">
			<h1>{t('errand.dashboard')}</h1>
			<div className="stats-control">
				<DatePicker value={this.startDate} onChange={this.onStartDateChange} />
				<DatePicker value={this.endDate} onChange={this.onEndDateChange} showClearButton={false} />
				{/*<Button onClick={this.update} variant="primary" disabled={!this.changed}>Обновить</Button>*/}
				<Select noTotal maxItems={10} itemsPerPage={1000}
					items={Object.values(MODES).map(mode => ({ value: mode, label: t(`errand.${mode}`) }))}
					value={this.mode}
					onChange={this.onModeChange}
					showValue={this.mode ? t(`errand.${this.mode}`) : <span className="hint">{t('errand.freeMode')}</span>}
					width={180}
				/>
			</div>
			<div>
				{this.error && <div>Ошибка: {this.error}</div>}
				{this.stats && <div>
					<div className="errands-chart">
						<canvas style={{ margin: '10px 0' }} width={200} height={200} ref={this.onMount} />
						<div className="legend">
							{Object.keys(DATA_SETS).map(set => <div key={set}>
								<span className="set-color" style={{ background: DATA_SETS[set].color }}/>
								{DATA_SETS[set].title}: <strong>{this.stats[set]}</strong>
							</div>)}
						</div>
					</div>
					<div className="errand-orgs">
						<table>
							<thead>
							<tr>
								<th>{t('errand.org')}</th>
								{Object.keys(DATA_SETS).map(set => <th key={set} style={{ borderBottomColor: DATA_SETS[set].color }}>
									{DATA_SETS[set].title}
								</th>)}
							</tr>
							</thead>
							<tbody>
								{this.stats.orgs
									.sort((a, b) => {
										let sumA = 0, sumB = 0;
										Object.keys(DATA_SETS).forEach(set => {
											sumA += a[set] * 1;
											sumB += b[set] * 1;
										});
										return sumB - sumA;
									})
									.map(org => <tr key={org.id}>
										<td className="name">{org.name} </td>
										{Object.keys(DATA_SETS).map(set => <td key={set}>{parseInt(org[set]) || '-'}</td>)}
									</tr>)}
							</tbody>
						</table>
					</div>
				</div>}
			</div>
			{/*<div><pre>{JSON.stringify(this.stats, null, 2)}</pre></div>*/}
		</div>;
	}

}
