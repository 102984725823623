import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, Loader } from '@smartplatform/ui';
import store from 'client/store';

@observer
export default class Sync extends React.Component {

	@observable isLoading = false;
	@observable response = null;

	sync = async () => {
		this.isLoading = true;
		this.response = null;
		this.response = await store.model.Errand.esedSync();
		this.isLoading = false;
	};

	render() {
		return <div>
			<div>
				<Button onClick={this.sync} disabled={this.isLoading} variant="primary">Синхронизировать</Button>{' '}
				{this.isLoading && <><Loader size={18} /></>}
			</div>
			{this.response && <div className="mt-2">
				<div>Новых: {this.response.newCount}</div>
				<div>Обновлено: {this.response.updatedCount}</div>
				<div>Всего: {this.response.total}</div>
			</div>}
		</div>;
	}

}
