import React from 'react';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import AviaDepPopup from './AviaDepPopup';
import icon from 'client/img/icons/aviadep.svg';
import t from 'i18n';

const color = '#0af';

export default {
	modelName: 'AviaDep',
	title: t('aviaDep.title'),
	filterTitle: t('aviaDep.plural'),
	showInFilters: true,
	style: new Style({
		fill: new Fill({ color: asArray([...ColorUtils.hexToArray(color).slice(0, 3), 0.5]) }),
		stroke: new Stroke({ color, width: 1 }),
		image: new Icon({ src: icon, anchor: [9, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
	}),
	icon,
	priority: 2,
	renderPopup: record => <AviaDepPopup key={record.id} record={record} />,
};
