import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Button, Row, Field } from '@smartplatform/ui';
import ObjectCard from './ObjectCard';
import ObjectEdit from './ObjectEdit';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Object extends React.Component {
	
	@observable editing = false;
	
	constructor(props) {
		super(props);
		this.isNew = !this.props.object.id;
	}
	
	onEdit = () => {
		this.editing = true;
		this.props.onEdit && this.props.onEdit(this.props.object);
	};
	
	onSave = (record) => {
		this.editing = false;
		this.props.onSave && this.props.onSave(record, this.isNew);
	};
	
	onCancel = () => {
		this.editing = false;
		this.props.onCancel && this.props.onCancel(this.isNew);
	};
	
	render() {
		const { value, object, onObjectSelect, onDelete } = this.props;
		
		return this.editing || !object.id ?
			<ObjectEdit object={object} onSave={this.onSave} value={value} onCancel={this.onCancel} onDelete={onDelete} />
			:
			<ObjectCard object={object} onEdit={this.onEdit} value={value} onObjectSelect={onObjectSelect} />;
	}
}
